import React, {useContext, useEffect, useState, useMemo} from 'react';
import {getTrend, KpisSituation, PricingImpact} from '@cip/tiles';
import {ThemeProvider} from '@material-ui/core';
import tileTheme from '../../theme/tileTheme';
import Box from '@material-ui/core/Box';
import PeerComparison from '../PriceSimulation/components/RevenueSimulation/PeerComparison';
import {PriceRequestStore} from '../PriceSimulation/PriceSimulationStore';
import TileContainer from '@cip/tiles/common/TileContainer';
import {loadRevenue_upd} from '../PriceSimulation/components/RevenueSimulation/revenueSimulationService';
import {getErrorMessage, getGmisErrorMessage} from '../../common/getErrorMessage';
import {SET_GROUPING_DATA, SET_RM_PDB_DATA} from '../../common/actionTypes';
import {useNotification} from '../Common/components/Notification';
import {useParams} from 'react-router-dom';
import {Store} from '../../Store';
import LoadingSpinner from '../Common/components/LoadingSpinner';
import api from '../../api/api';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  buttonGroup: {
    padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  pageHeader: {
    fontSize: '28px',
    lineHeight: '43px',
    color: '#141e55',
    fontFamily: 'Verlag Office,sans-serif',
    fontWeight: '300',
    textTransform: 'uppercase'
  },
  rowAlign: {
    verticalAlign: 'baseline'
  },
  revenueImpactPadding: {
    padding: '0 5px 0 0'
  }
}));

const KpiDashboard = () => {
  const {state, dispatch} = useContext(PriceRequestStore);
  const notification = useNotification();
  const {pricingRequestId} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const {parentState,parentDispatch} = useContext(Store);
  const [data, setData] = useState();
  const classes = useStyles();

  const waterfallAccount = {
    currentPci: null,
    revenueImpact: null,
    pAndSCharge: null
  };

  const [waterfallData, setWaterfallData] = useState(waterfallAccount);
  const [waterfallBpsList, setWaterfallBpsList] = useState([]);
  const [revenueImpactBps, setRevenueImpactBps] = useState();
  const ACCOUNT_IMPACT = 0;

  useEffect(() => {
    console.log('pricingRequestId : ', pricingRequestId);
    console.log('state : ',state);
    if (pricingRequestId) {
      setIsLoading(true);
      const accountNumber = parentState?.customer?.accountNumber;
      const pricingRequestIds = parentState.portfolios?.map(req=>req.pricingRequestId);
      console.log('accountNumber : ', accountNumber);
      const revenueReq = { 'currentReqId': pricingRequestId, 'selectedReqIds': pricingRequestIds, 'accountNumber': accountNumber };

      loadRevenue_upd(pricingRequestId,revenueReq ,dispatch)
        .then((response) => {
          console.log('updated revenue', response);
          if (response.data.currentRevenue?.gmisError?.length > 0) {
            const warningMessage = getGmisErrorMessage({
              response,
              message: response.data.currentRevenue.gmisError.join('\n')
            })
            notification.warning(warningMessage);
            console.warn('GMIS Warning ', warningMessage);
          }
          parentDispatch({type: SET_RM_PDB_DATA, payload: response?.data?.pdbData?.rmDiscMarker});
          setIsLoading(false);
        })
        .catch(err => {
          notification.error('Could not load revenue\n' + getGmisErrorMessage(err));
          setIsLoading(false);
        });

      api.customer
        .getDetails(accountNumber,pricingRequestId)
        .then(response => {
          if (response.data.gmisError?.length > 0) {
            const warningMessage = getErrorMessage({response, message: response.data.gmisError.join('\n')});
            notification.warning(warningMessage);
            console.warn('GMIS Warning ', warningMessage);
          }
          console.log('gmis getGroupingData response : ',response);
          console.debug('response.data : ',response.data);
          setData(response.data);
          parentDispatch({type: SET_GROUPING_DATA, payload: response?.data});
          setIsLoading(false);
        })
        .catch(err => {
          notification.error('Unable to get kpi dashboard data, please reload\n' + getErrorMessage(err))
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingRequestId]);

  const pricingImpactInfo = useMemo(() => {
    console.debug('Changes in pricingImpactInfo groupingdata', parentState.groupingdata);
    return parentState.groupingdata ? {
      revenueImpact: parentState.groupingdata.currentKpiAccGrp?.revenueImpact || 0,
      waterfallAccount: {
        currentPci: Math.round(parentState.groupingdata.currentKpiAccLevel?.pc1CurrentYear) || 0,
        pAndSCharge: (Math.round(parentState.groupingdata.currentKpiAccLevel?.psChargeAccount) || 0),
        revenueImpact: parentState.groupingdata.currentKpiAccGrp?.revenueImpact || 0
      },
      waterfallClient: {
        currentPci: Math.round(parentState.groupingdata.currentKpiAccGrp?.pc1CurrentYear) || 0,
        pAndSCharge: (Math.round(parentState.groupingdata.currentKpiAccGrp?.psChargeClient) || 0),
        revenueImpact: parentState.groupingdata.currentKpiAccGrp?.revenueImpact || 0
      },
      revenueImpactAccountBps: parentState.groupingdata.currentKpiAccLevel !== null ?
        '('+(parentState.groupingdata.currentKpiAccLevel?.revenueImpactAccountBps || '') +' bps)' : '',
      waterfallAccountBps : parentState.groupingdata.currentKpiAccLevel !== null ? [
        '('+(parentState.groupingdata.currentKpiAccLevel?.pc1CurrentYearBps !== null
          ? parentState.groupingdata.currentKpiAccLevel?.pc1CurrentYearBps : '')+' bps)',
        '('+(parentState.groupingdata.currentKpiAccLevel?.revenueImpactAccountBps !== null
          ? parentState.groupingdata.currentKpiAccLevel?.revenueImpactAccountBps : '')+' bps)',
        '('+(parentState.groupingdata.currentKpiAccLevel?.newPCIAccountBps !== null
          ? parentState.groupingdata.currentKpiAccLevel?.newPCIAccountBps : '')+' bps)',
        '('+(parentState.groupingdata.currentKpiAccLevel?.psChargeAccountBps !== null
          ? parentState.groupingdata.currentKpiAccLevel?.psChargeAccountBps : '')+' bps)',
        '('+(parentState.groupingdata.currentKpiAccLevel?.newPCIIAccountBps !== null
          ? parentState.groupingdata.currentKpiAccLevel?.newPCIIAccountBps : '')+' bps)'
      ] : [],
      revenueImpactClientBps: parentState.groupingdata.currentKpiAccGrp !== null ?
        '('+(parentState.groupingdata.currentKpiAccGrp?.revenueImpactClientBps || '') +' bps)' : '',
      waterfallClientBps : parentState.groupingdata.currentKpiAccGrp !== null ? [
        '('+(parentState.groupingdata.currentKpiAccGrp?.pc1CurrentYearBps !== null
          ? parentState.groupingdata.currentKpiAccGrp?.pc1CurrentYearBps : '')+' bps)',
        '('+(parentState.groupingdata.currentKpiAccGrp?.revenueImpactClientBps !==null
          ? parentState.groupingdata.currentKpiAccGrp?.revenueImpactClientBps : '')+' bps)',
        '('+(parentState.groupingdata.currentKpiAccGrp?.newPCIClientBps !==null
          ? parentState.groupingdata.currentKpiAccGrp?.newPCIClientBps : '')+' bps)',
        '('+(parentState.groupingdata.currentKpiAccGrp?.psChargeClientBps !==null
          ? parentState.groupingdata.currentKpiAccGrp?.psChargeClientBps : '')+' bps)',
        '('+(parentState.groupingdata.currentKpiAccGrp?.newPCIIClientBps !==null
          ? parentState.groupingdata.currentKpiAccGrp?.newPCIIClientBps : '')+' bps)'
      ] : [],
      onBoardingDate : 'ONBOARDING DATE: '+(parentState.groupingdata.onBoardingDate !== null ? parentState.groupingdata.onBoardingDate : ''),
      accGrpNbr : 'CLIENT HOUSEHOLD SITUATION - '+(parentState.groupingdata.accountGrpNbr !== null ? parentState.groupingdata.accountGrpNbr : ''),
      noOfPortfolois : 'No. of portfolios: '+(parentState.groupingdata.noOfPortfolios !== null ? parentState.groupingdata.noOfPortfolios : ''),
      currentYearDate : 'As per ' + (parentState.groupingdata.currentKpiAccGrp !== null && parentState.groupingdata.currentKpiAccGrp?.currentYearDate ?  parentState.groupingdata.currentKpiAccGrp?.currentYearDate : ''),
      previousYearDate : 'As per ' + (parentState.groupingdata.currentKpiAccGrp !== null && parentState.groupingdata.currentKpiAccGrp?.previousYearDate ? parentState.groupingdata.currentKpiAccGrp?.previousYearDate : ''),
      dateAsOf : 'DATE AS OF: ' + (parentState.groupingdata.currentKpiAccGrp?.pdbKeyDate !== null ? parentState.groupingdata.currentKpiAccGrp?.pdbKeyDate : ''),
      flag: true
    } : null;
  }, [parentState.groupingdata])

  useEffect(() => {
    if (pricingImpactInfo?.flag) {
      setWaterfallData(pricingImpactInfo?.waterfallAccount);
      setWaterfallBpsList(pricingImpactInfo?.waterfallAccountBps);
      setRevenueImpactBps(pricingImpactInfo?.revenueImpactAccountBps);
    }
  }, [pricingImpactInfo]);

  const tabChangeHandler = (index) => {
    if (index === ACCOUNT_IMPACT) {
      setWaterfallData(pricingImpactInfo?.waterfallAccount);
      setWaterfallBpsList(pricingImpactInfo?.waterfallAccountBps);
      setRevenueImpactBps(pricingImpactInfo?.revenueImpactAccountBps);
    } else {
      setWaterfallData(pricingImpactInfo?.waterfallClient);
      setWaterfallBpsList(pricingImpactInfo?.waterfallClientBps);
      setRevenueImpactBps(pricingImpactInfo?.revenueImpactClientBps);
    }
  };

  if (isLoading) {
    return (
      <Box alignItems="center" display="flex" minHeight="230px">
        <LoadingSpinner/>
      </Box>
    );
  }

  function getFinalValue(data,prop1,prop2, prop3){
    if (data === null || data === undefined){
      return '';
    }else if(prop1 === null || prop1 === undefined) {
      return '';
    }else if(prop2 === null || prop2 === undefined) {
      return '';
    }else {
      return prop3+Math.round(prop2).toLocaleString();
    }
  }

  function getFinalValueWithBps(data,prop1,prop2, prop3, prop4){
    if (data === null || data === undefined){
      return '';
    }else if(prop1 === null || prop1 === undefined) {
      return '';
    }else if(prop2 === null || prop2 === undefined || prop2 === '') {
      return '';
    }else {
      return prop3+Math.round(prop2).toLocaleString()+prop4;
    }
  }

  return (
    <Box
      py={0.5}
      style={{
        display: '-webkit-box',
        overflow: 'auto'
      }}
    >
      <ThemeProvider theme={tileTheme}>
        <Box px={0.5}>
          <KpisSituation
            TableProps={{
              data: [
                {
                  currentYear: getFinalValue(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.aumCurrentYear, ''),
                  id: 'aum',
                  label: 'AuM',
                  previousYear: getFinalValue(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.aumPreviousYear, '')
                },
                {
                  currentYear: getFinalValue(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.nnmCurrentYear, ''),
                  id: 'nnm',
                  label: 'NNM',
                  previousYear: getFinalValue(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.nnmPreviousYear, '')
                },
                {
                  currentYear: getFinalValue(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.aucCurrentYear, ''),
                  id: 'auc',
                  label: 'AuC',
                  previousYear: getFinalValue(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.aucPreviousYear, '')
                },
                {
                  currentYear: getFinalValue(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.pc1CurrentYear, '')
                    + getFinalValueWithBps(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.roaCurrentYear, ' (', ' bps)'),
                  id: 'pci',
                  label: 'PC I (RoA)',
                  previousYear: getFinalValue(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.pc1PreviousYear, '')
                    + getFinalValueWithBps(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.roaPreviousYear, ' (',' bps)')
                },
                {
                  currentYear: getFinalValue(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.pc2CurrentYear, '')
                    + getFinalValueWithBps(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.marginCurrentYear, ' (',' bps)'),
                  id: 'pcii',
                  label: 'PC II (Margin)',
                  previousYear: getFinalValue(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.pc2PreviousYear, '')
                    + getFinalValueWithBps(data, data?.currentKpiAccGrp, data?.currentKpiAccGrp?.marginPreviousYear, ' (',' bps)')
                },
                {
                  currentYear: getFinalValue(data, data?.currentKpiAccGrpDb, data?.currentKpiAccGrpDb?.nirBankCurrentYear,'')
                    + getFinalValue(data, data?.currentKpiAccGrpDb, data?.currentKpiAccGrpDb?.nirClientCurrentYear, ' / '),
                  id: 'nir',
                  label: 'NIR: Bank/Client',
                  previousYear: getFinalValue(data, data?.currentKpiAccGrpDb, data?.currentKpiAccGrpDb?.nirBankPreviousYear, '')
                    + getFinalValue(data, data?.currentKpiAccGrpDb, data?.currentKpiAccGrpDb?.nirClientPreviousYear, ' / ')
                }
              ],
              headers: {
                currentYear: {
                  subTitle: pricingImpactInfo?.currentYearDate,
                  title: 'Current Year'
                },
                kpis: {
                  subTitle: '',
                  title: 'KPIs'
                },
                previousYear: {
                  subTitle: pricingImpactInfo?.previousYearDate,
                  title: 'Previous Year'
                }
              },
              width: '436px',
              rowClassName: classes.rowAlign
            }}
            pieData={[
              {
                name: 'PSR',
                y: data?.currentKpiAccGrpDb?.psr
              },
              {
                name: 'IA',
                y: data?.currentKpiAccGrpDb?.ia
              },
              {
                name: 'DPM',
                y: data?.currentKpiAccGrpDb?.dpm
              },
              {
                name: 'Other',
                y: data?.currentKpiAccGrpDb?.others
              }
            ]}
            pieFooterPrimary= {data?.currentKpiAccGrp !== null ? (data?.currentKpiAccGrp?.aumAucSumCurrentYear || '').toLocaleString() + ' M' : ''}
            pieSize={150}
            pieSubtitle= {pricingImpactInfo?.noOfPortfolois}
            pieTitle="Service Model Breakdown"
            pieTooltipFormat="<b>{point.name} : {point.y:.1f} M</b>"
            subtitle={pricingImpactInfo?.onBoardingDate}
            title={pricingImpactInfo?.accGrpNbr}
          />
        </Box>

        <Box px={0.5}>
          <TileContainer
            tileWidth={500}
            title="HOUSEHOLD PRICING GUIDANCE"
          >
            <PeerComparison revenue= {state.selectedPricingRequest?.revenue}/>
          </TileContainer>
        </Box>

        <Box px={0.5}>
          { pricingImpactInfo ?
            <PricingImpact
              onTabChange={tabChangeHandler}
              revenueBps= {revenueImpactBps}
              revenueClassName={classes.revenueImpactPadding}
              revenuePrimary= {pricingImpactInfo?.revenueImpact.toLocaleString()}
              revenueSubTitle="Annualized"
              revenueTitle="Revenue Impact"
              revenueTooltipMessage="The calculation is based on banking service fees, recurring fees and transaction fees exchange-traded products only"
              selectedTabClassName={classes.pageHeader}
              tabs={[
                'Account Impact',
                'Client Impact'
              ]}
              title="SIMULATED PRICING IMPACT"
              trend= {getTrend(pricingImpactInfo?.revenueImpact)}
              waterfallBpsList={waterfallBpsList}
              waterfallCategories={[
                'Current PC I',
                'Revenue Impact',
                'New PC I',
                'P&S + CoC',
                'New PC II'
              ]}
              waterfallData={waterfallData}
              waterfallTitle="Current Year"
              waterfallWidth={477}
            /> : null
          }
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default KpiDashboard;
