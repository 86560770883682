import React from 'react';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {CHF, PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {FIXED_AMOUNT} from '../../../../../common/enums/recurringFeeCondition';
import {getDiscountFromEffectivePrice} from '../../../../../common/getDiscountFromEffectivePrice';

const LABEL = 'Minimum Fee per quarter';
const KEY_PREFIX = 'recurring-fees-minimum-fee-per-quarter';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const minimumFeePerQuarter = ({handleValidationResultChange, handleValueChange, recurringFees, readOnly, specialRequest, showDiff}) => {

  let result = {
    label: LABEL
  };
  let isNewValueDifferent = !!readOnly && recurringFees?.currentCondition &&
    recurringFees?.currentCondition?.minimumFeePerQuarter?.effectivePrice !==
    recurringFees?.newCondition?.minimumFeePerQuarter?.effectivePrice;

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (recurringFees?.currentCondition?.minimumFeePerQuarter) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(recurringFees?.currentCondition?.minimumFeePerQuarter)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(recurringFees?.newCondition?.minimumFeePerQuarter, recurringFees?.newCondition?.condition?.code, handleValidationResultChange, handleValueChange, readOnly, specialRequest, isNewValueDifferent),
    discount: getMappedDiscount(recurringFees?.newCondition?.minimumFeePerQuarter)
  }

};


const getMappedOldCondition = (minimumFeePerQuarter) => {
  if (!minimumFeePerQuarter) {
    return null;
  }

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={minimumFeePerQuarter?.effectivePrice}
    />);

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (minimumFeePerQuarter, conditionCode, handleValidationResultChange, handleValueChange, readOnly, specialRequest, isNewValueDifferent) => {
  if (!minimumFeePerQuarter) {
    return {
      effectivePrice: null,
      isHighlighted: isNewValueDifferent,
    };
  }

  const isEditable = conditionCode !== FIXED_AMOUNT.code && !readOnly;

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      key={`${KEY_PREFIX}-list-price`}
      type={CHF}
      value={minimumFeePerQuarter?.listPrice}
    />);

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={minimumFeePerQuarter?.effectivePrice}
    />);

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-editable`}
      isEditable
      key={`${KEY_PREFIX}-effective-price-editable`}
      max={specialRequest ? null : minimumFeePerQuarter?.listPrice}
      onValidationResultChange={(vr) => handleValidationResultChange && handleValidationResultChange({
        ...vr,
        selectors: ['minimumFeePerQuarter', 'effectivePrice']
      })}
      onValueChange={(vr) => handleValidationResultChange && handleValueChange({
        ...vr,
        selectors: ['minimumFeePerQuarter', 'effectivePrice']
      })}
      type={CHF}
      value={minimumFeePerQuarter?.effectivePrice}
    />);

  return {
    effectivePrice: isEditable ? effectivePriceEditable : effectivePriceReadonly,
    isEditable: isEditable,
    listPrice: listPrice,
    isHighlighted: isNewValueDifferent,
  };
};

const getMappedDiscount = (newCondition) => {
  const discount = parseFloat(getDiscountFromEffectivePrice(newCondition));

  return (
    !isNaN(discount) && (
      <PriceNumberFormat
        data-testid={`${TEST_KEY_PREFIX}-discount`}
        key={`${KEY_PREFIX}-discount`}
        type={PERCENTAGE}
        value={discount}
      />
    )
  );
};
