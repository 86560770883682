import React from 'react';
import PriceConditionTableColumn from './PriceConditionTableColumn';
import {LABEL, NEW_CONDITIONS, OLD_CONDITIONS} from './PriceConditionType';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../common/constants';
import PriceConditionTableRowSection from './PriceConditionTableRowSection';
import PropTypes from 'prop-types';

const PriceConditionHeaderRow = ({newConditionLabel, oldConditionLabel, showOldCondition, showNewCondition}) => {
  return <PriceConditionTableRowSection data={[]}>
    <PriceConditionTableColumn field="label" type={LABEL}/>
    {showOldCondition &&
    <PriceConditionTableColumn
      customStyles={OLD_CONDITION_CUSTOM_STYLE}
      field="currentCondition.value"
      label={oldConditionLabel}
      type={OLD_CONDITIONS}
    />}
    {showNewCondition &&
    <PriceConditionTableColumn
      colSpan={2}
      field="newCondition.value"
      isEditableFieldValueAccessor="newCondition.isEditable"
      label={newConditionLabel}
      type={NEW_CONDITIONS}
    />}
  </PriceConditionTableRowSection>;
}

PriceConditionHeaderRow.propTypes = {
  newConditionLabel: PropTypes.string,
  oldConditionLabel: PropTypes.string,
  showNewCondition: PropTypes.bool,
  showOldCondition: PropTypes.bool
};

export default PriceConditionHeaderRow;
