export default {
  root: {
    display: 'inlineBlock',
    fontFamily: '\'Verlag SSm 7r\',Arial,sans-serif',
    fontSize: '13px',
    fontWeight: 'normal',
    lineHeight: 1,
    textDecoration: 'none',
    outline: 'none',
    borderRadius: '0px',
    textAlign: 'center',
    verticalAlign: 'middle',
    backgroundColor: '#fff',
    color: '#000',
    border: '1px solid #ccc',
    transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    '&:focus': {
      outline: '0px auto -webkit-focus-ring-color'
    },
    '&:not(:disabled)': {
      cursor: 'pointer'
    },
    '&[disabled="disabled"],&.disabled': {
      backgroundColor: '#ccc',
      border: '1px solid #ccc'
    },
    '&:hover': {
      backgroundColor: '#fff',
      color: '#141e55',
      border: '1px solid #141e55',
      textDecoration: 'none'
    }
  },
  textPrimary: {
    fontFamily: '\'Verlag SSm 7r\'',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#141e55',
      color: '#fff',
      border: '1px solid #141e55',
      textDecoration: 'none',
      cursor: 'pointer'
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: '#141e55',
      color: '#fff'
    }
  }
};
