import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Card, Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {Store} from '../../../../../../../../Store';
import {useLocation} from 'react-router-dom';
import {PORTFOLIO_NUMBER_QUERY} from '../../../../../../../../common/queryParams';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '13px !important'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px !important'
    }
  },
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableCellHeader: {
    fontSize: '12px',
    fontFamily: 'Verlag SSm 5r',
    backgroundColor: '#efefef',
    fontWeight: 'normal'
  },
  tableCell: {
    fontFamily: 'Verlag SSm 5r',
    fontSize: '12px',
    fontWeight: 'normal'
  }
}));

const filterPortfolio = portfolios => {
  if (!portfolios) {
    return [];
  }
  return portfolios.filter(portfolio => !portfolio.requestInProgress);
};

const PortfolioListTable = ({portfolios}) => {
  const classes = useStyles();

  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const {parentDispatch} = useContext(Store);
  const queryParams = new URLSearchParams(useLocation().search);
  const portfolioNumber = queryParams.get(PORTFOLIO_NUMBER_QUERY);

  useEffect(() => {
    if (selected) {
      parentDispatch({type: 'SELECT_PORTFOLIOS', payload: selected});
      if (selected.length > 0) {
        setSelectAll(selected.length === filterPortfolio(portfolios).length);
      } else {
        setSelectAll(false);
      }
    }
    // eslint-disable-next-line
  }, [selected, parentDispatch]);

  useEffect(() => {
    setSelectAll(false);
    if (portfolios && portfolioNumber) {
      const selectedPortfolio = portfolios.find(
        portfolio => portfolio.portfolioNumber === portfolioNumber
      );
      if (selectedPortfolio) {
        setSelected([selectedPortfolio]);
      } else {
        setSelected([]);
      }
    } else {
      setSelected([]);
    }
  }, [portfolios, portfolioNumber]);

  const handleSelectAll = event => {
    if (event.target.checked) {
      setSelected(filterPortfolio(portfolios).map(porfolio => porfolio));
    } else {
      setSelected([]);
    }
  };

  const handleSelectOne = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelectedPortfolios = [];

    if (selectedIndex === -1) {
      newSelectedPortfolios = newSelectedPortfolios.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelectedPortfolios = newSelectedPortfolios.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelectedPortfolios = newSelectedPortfolios.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedPortfolios = newSelectedPortfolios.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelectedPortfolios);
  };
  return (
    <Card className={classes.root}>
      <div className={classes.inner}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHeader} padding="checkbox">
                <Checkbox
                  checked={selectAll}
                  color="primary"
                  inputProps={{'data-testid': 'test-select-all'}}
                  onChange={(e) => handleSelectAll(e)}
                />
              </TableCell>
              <TableCell className={classes.tableCellHeader}>Portfolio Number</TableCell>
              <TableCell className={classes.tableCellHeader}>Portfolio Name</TableCell>
              <TableCell className={classes.tableCellHeader}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {portfolios.map((portfolio, index) => (
              <TableRow
                className={classes.tableRow}
                hover
                key={'portfolio' + portfolio.portfolioNumber + index}
                selected={selected && selected.indexOf(portfolio) !== -1}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected && selected.indexOf(portfolio) !== -1}
                    color="primary"
                    disabled={portfolio.requestInProgress}
                    inputProps={{'data-testid': portfolio.portfolioNumber}}
                    onChange={() => handleSelectOne(portfolio)}
                    value="true"
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>{portfolio.portfolioNumber}</TableCell>
                <TableCell className={classes.tableCell}>{portfolio.portfolioName}</TableCell>
                <TableCell className={classes.tableCell}>{portfolio.pricingRequestStatus}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
};

PortfolioListTable.propTypes = {
  portfolios: PropTypes.array.isRequired
};

export default PortfolioListTable;
