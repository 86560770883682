import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import CommonDialog from '../../../Common/components/Dialog/CommonDialog';
import FxGridRemove from './components/FxGridRemove';
import FxGridAdd from './components/FxGridAdd';

const FxGridManagement = () => {
  const [toggleReset, setToggleReset] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({
    content: <></>,
    onContinue: () => {},
    onClose: () => {}
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography style={{ paddingLeft: 21 }} variant="h1">FX Grid Management</Typography>
      </Grid>
      <FxGridAdd
        resetFxGridAddStates={toggleReset}
        setFxGridAddConfirmationDialogProps={setDialogProps}
        setFxGridAddIsConfirmationDialogOpen={setIsDialogOpen}
        setResetFxGridStates={setToggleReset}
      />
      <FxGridRemove
        resetFxGridRemoveStates={toggleReset}
        setFxGridRemoveConfirmationDialogProps={setDialogProps}
        setFxGridRemoveIsConfirmationDialogOpen={setIsDialogOpen}
        setResetFxGridStates={setToggleReset}
      />
      <CommonDialog
        content={dialogProps.content}
        onClose={dialogProps.onClose}
        onContinue={dialogProps.onContinue}
        open={isDialogOpen}
      />
    </Grid>
  );
};

export default FxGridManagement;
