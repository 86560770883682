import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import LabelDropdown from '../../../../Common/components/tableFilter/LabelDropdown';
import LabelTextField from '../../../../Common/components/tableFilter/LabelTextField';

const ClientAgreementGenStatusFilter = ({handleFilterClientAgreementStatus,handleFilterLetterType,handleFind,handleReferenceFilter,handleRunDateFilter,selectedClientAgreementStatusFilter,selectedLetterTypeFilter,selectedReferenceFilter,selectedRunDateFilter}) => {

  const CLIENT_AGREEMENT_SUBMISSION_STATUS = {
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    ERROR: 'ERROR',
    SKIPPED: 'SKIPPED'
  };

  const LETTER_TYPE = {
    CONFIRMATION: 'CONFIRMATION',
    CANCELLATION: 'CANCELLATION',
    SUMMARY: 'SUMMARY'
  };

  const clientAgreementStatusFilterOptions = Object.entries(CLIENT_AGREEMENT_SUBMISSION_STATUS).map(([key, value]) => ({
    key,
    label: value
  }));

  const letterTypeFilterOptions = Object.entries(LETTER_TYPE).map(([key, value]) => ({
    key,
    label: value
  }));

  return (
    <Box alignItems="center" display="flex" marginBottom={2}>
      <LabelTextField
        label="Run Date"
        onChange={handleRunDateFilter}
        onClear={handleFind}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFind(e);
          }
        }}
        placeholder="yyyy-MM-dd"
        style={{width: '25ch'}}
        value={selectedRunDateFilter}
      />
      <LabelDropdown
        label="Letter Type"
        onChange={handleFilterLetterType}
        options={letterTypeFilterOptions}
        value={selectedLetterTypeFilter}
      />
      &nbsp;
      <LabelDropdown
        label="Status"
        onChange={handleFilterClientAgreementStatus}
        options={clientAgreementStatusFilterOptions}
        value={selectedClientAgreementStatusFilter}
      />
      &nbsp;
      <LabelTextField
        label="Portfolio Number"
        onChange={handleReferenceFilter}
        onClear={handleFind}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFind(e);
          }
        }}
        style={{width: '25ch'}}
        value={selectedReferenceFilter}
      />
    </Box>
  );
};

ClientAgreementGenStatusFilter.propTypes = {
  handleFilterClientAgreementStatus: PropTypes.func,
  handleFilterLetterType: PropTypes.func,
  handleFilterPriceCheckStatus: PropTypes.func,
  handleFind: PropTypes.func,
  handleReferenceFilter: PropTypes.func,
  handleRunDateFilter: PropTypes.func,
  selectedClientAgreementStatusFilter: PropTypes.string,
  selectedLetterTypeFilter: PropTypes.string,
  selectedReferenceFilter: PropTypes.string,
  selectedRunDateFilter: PropTypes.string
};

export default ClientAgreementGenStatusFilter;
