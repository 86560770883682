import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {UPDATE_PAYMENT_FEE_CONDITION} from './paymentFeesActions';
import {PriceRequestStore} from '../../PriceSimulationStore';
import PriceConditionTable from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import PriceConditionTableRowSection from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {
  LABEL,
  NEW_CONDITION_READONLY,
  NEW_CONDITIONS,
  OLD_CONDITION_READONLY,
  OLD_CONDITIONS
} from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import {rowEntry} from './objectmapper/paymentFeesRowEntryObjectMapper';
import {paymentFeeCondition} from './objectmapper/paymentFeesConditionObjectMapper';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../common/constants';
import { getOldConditionLabelAgreementType } from '../../../../common/getOldConditionLabelAgreementType';


export const PaymentFeeConditionTable = ({allPaymentFees, readOnly}) => {
  const {dispatch} = useContext(PriceRequestStore);
  const oldConditionLabel = getOldConditionLabelAgreementType(allPaymentFees?.oldPricingAgreedWithClient?.code);

  const handlePaymentFeeConditionChange = (vc) => {
    dispatch({type: UPDATE_PAYMENT_FEE_CONDITION, data: vc.value});
  };

  const mappedCondition = [
    paymentFeeCondition({
      currentCondition: allPaymentFees.currentPaymentFeeCondition,
      newCondition: allPaymentFees.newPaymentFeeCondition,
      onValueChange: handlePaymentFeeConditionChange,
      readOnly
    })
  ];

  const mappedFees = [
    rowEntry({fee: allPaymentFees, feeType: 'CASH_DEPOSIT', label: 'Cash Deposit', isFlatFee: false}),
    rowEntry({fee: allPaymentFees, feeType: 'CASH_WITHDRAWAL', label: 'Cash Withdrawal', isFlatFee: false}),
    rowEntry({fee: allPaymentFees, feeType: 'CHEQUE_COLLECTION', label: 'Cheque Collection', isFlatFee: false}),
    rowEntry({fee: allPaymentFees, feeType: 'CHEQUE_ISSUE', label: 'Cheque Issue', isFlatFee: true}),
    rowEntry({fee: allPaymentFees, feeType: 'OUTGOING_PAYMENT', label: 'Outgoing Payment', isFlatFee: true})
  ];

  return (
    <PriceConditionTable width={allPaymentFees.currentConditionCodeFees ? '100%' : '70%'}>
      <PriceConditionTableRowSection data={mappedCondition}>
        <PriceConditionTableColumn field="label" type={LABEL} width="120px"/>
        {allPaymentFees.currentConditionCodeFees &&
        <PriceConditionTableColumn
          colSpan={3}
          customStyles={OLD_CONDITION_CUSTOM_STYLE}
          data-testid="test-old-condition-label"
          field="oldCondition.value"
          label={oldConditionLabel}
          type={OLD_CONDITIONS}
        />}
        <PriceConditionTableColumn
          colSpan={6}
          field="newCondition.value"
          isEditableFieldValueAccessor="newCondition.isEditable"
          label="NEW CONDITIONS"
          type={NEW_CONDITIONS}
        />
      </PriceConditionTableRowSection>
      <PriceConditionTableRowSection>
        <PriceConditionTableColumn type={LABEL}/>
        {allPaymentFees.currentConditionCodeFees &&
        <PriceConditionTableColumn
          colSpan={3}
          customStyles={OLD_CONDITION_CUSTOM_STYLE}
          label="Effective Price"
          type={OLD_CONDITION_READONLY}
        />}
        <PriceConditionTableColumn colSpan={3} label="List Price" type={NEW_CONDITION_READONLY}/>
        <PriceConditionTableColumn colSpan={3} label="Effective Price" type={NEW_CONDITION_READONLY}/>
      </PriceConditionTableRowSection>
      <PriceConditionTableRowSection data={mappedFees}>
        <PriceConditionTableColumn field="label" type={LABEL}/>
        {allPaymentFees.currentConditionCodeFees &&
        <PriceConditionTableColumn field="oldCondition.effectivePrice" label="Fees" type={OLD_CONDITION_READONLY}/>}
        {allPaymentFees.currentConditionCodeFees &&
        <PriceConditionTableColumn
          field="oldCondition.effectiveMinimumFee"
          label="Minimum"
          type={OLD_CONDITION_READONLY}
        />}
        {allPaymentFees.currentConditionCodeFees &&
        <PriceConditionTableColumn
          customStyles={OLD_CONDITION_CUSTOM_STYLE}
          field="oldCondition.effectiveMaximumFee"
          label="Maximum"
          type={OLD_CONDITION_READONLY}
        />}
        <PriceConditionTableColumn field="newCondition.listPrice" label="Fees" type={NEW_CONDITION_READONLY}/>
        <PriceConditionTableColumn field="newCondition.listMinimumFee" label="Minimum" type={NEW_CONDITION_READONLY}/>
        <PriceConditionTableColumn field="newCondition.listMaximumFee" label="Maximum" type={NEW_CONDITION_READONLY}/>
        <PriceConditionTableColumn field="newCondition.effectivePrice" label="Fees" type={NEW_CONDITION_READONLY}/>
        <PriceConditionTableColumn
          field="newCondition.effectiveMinimumFee"
          label="Minimum"
          type={NEW_CONDITION_READONLY}
        />
        <PriceConditionTableColumn
          field="newCondition.effectiveMaximumFee"
          label="Maximum"
          type={NEW_CONDITION_READONLY}
        />
      </PriceConditionTableRowSection>
    </PriceConditionTable>
  );
};


// --- PropTypes

const feePropType = PropTypes.shape({
  listPrice: PropTypes.number.isRequired,
  effectivePrice: PropTypes.number.isRequired,
  minimumFee: PropTypes.number,
  maximumFee: PropTypes.number,
});

const feeTypes = PropTypes.shape({
  CASH_DEPOSIT: feePropType,
  CASH_WITHDRAWAL: feePropType,
  CHEQUE_COLLECTION: feePropType,
  CHEQUE_ISSUE: feePropType,
  OUTGOING_PAYMENT: feePropType
});

PaymentFeeConditionTable.propTypes = {
  allPaymentFees: PropTypes.shape({
    currentConditionCodeFees: feeTypes,
    currentPaymentFeeCondition: PropTypes.string,
    newConditionCodeFees: feeTypes.isRequired,
    newPaymentFeeCondition: PropTypes.string,
    oldPricingAgreedWithClient: PropTypes.object
  }).isRequired,
  readOnly: PropTypes.bool,
};


