import api from '../api/api';
import {BILA} from './enums/agreementType';

const checkDiscountReasonValidity = discountReasons => {
  return discountReasons?.reduce((prev, curr) => prev || curr.selected, false);
};

const checkContactReportMandatoryForBILA = (summary) => {
  return (summary?.pricingAgreement?.desc === BILA.desc && !summary?.contactReport?.contactReportNumber);
};

/**
 *
 * Validates summary fields for pricing request.
 * @param {array} pricingRequestIds
 *
 */
export const validateSummary = async pricingRequestIds => {
  return new Promise((resolve, reject) => {
    Promise.all(pricingRequestIds.map(id => api.pricingRequest.get(id)))
      .then(responses => {
        let invalidPricingRequestIds = [];

        responses.forEach(response => {
          const pricingDetails = response.data;
          if (!checkDiscountReasonValidity(pricingDetails?.summary?.discountReasons)) {
            invalidPricingRequestIds.push({ pricingRequestId : pricingDetails.pricingRequestId, errorMessage : 'Please select at least one discount reason'});
          }
          if (checkContactReportMandatoryForBILA(pricingDetails?.summary)) {
            invalidPricingRequestIds.push({ pricingRequestId : pricingDetails.pricingRequestId, errorMessage : 'Please take note that you are required to submit the proposal discussion via a contact report for all Bilateral Agreements.'});
          }
        });

        resolve({invalidPricingRequestIds});
      })
      .catch(err => {
        reject(err);
      });
  });
};
