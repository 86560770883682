import React from 'react';
import PropTypes from 'prop-types';
import {Paper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {HEADER_COLOR, SIDEBAR_TITLE_COLOR} from '../../../../theme/common/color';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    borderRadius: theme.spacing(0.5),
    backgroundColor: HEADER_COLOR
  },
  header: {
    backgroundColor: HEADER_COLOR,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Verlag SSm 7r',
    color: SIDEBAR_TITLE_COLOR
  }
}));

const Sidebar = ({children, title}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={3} variant="elevation">
      <div className={classes.header}>
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
      </div>
      <div>{children}</div>
    </Paper>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}

export default Sidebar;
