import React from 'react';
import PropTypes from 'prop-types';
import {TableCell} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(() => ({
  tableCellBody: {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '25px'
  },
  tableCellBodyGroup: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const StandardCell = ({cell, row}) => {
  const classes = useStyles();

  return (
    <TableCell {...cell.getCellProps()} className={classes.tableCellBody}>
      {cell.isGrouped ? (
        <div className={cell.isGrouped ? classes.tableCellBodyGroup : null}>
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
          </span>
          {cell.render('Cell')}
        </div>
      ) : (
        cell.render('Cell')
      )}
    </TableCell>
  );
};

StandardCell.propTypes = {
  cell: PropTypes.object,
  row: PropTypes.object
};

export default StandardCell;
