import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';

import PropTypes from 'prop-types';
import {createSecondLevelLabel} from '../../priceSummaryCommonUtil';
import {hasDifference} from '../../../../../common/compareUtil';

const NA = '-';
const additionalInstructionsObjectMapper = ({label, currentAdditionalInstructions,  newAdditionalInstructions, showOld, secondLevel, showDiff, testId}) => {
  let result = {
    label: secondLevel? createSecondLevelLabel(label): label
  };

  let isNewValueDifferent = hasDifference(currentAdditionalInstructions, newAdditionalInstructions, showOld);
  if(!isNewValueDifferent && showDiff){
    return null;
  }

  return {
    ...result,
    currentAdditionalInstructions: getMappedAdditionalInstructions(currentAdditionalInstructions, `old-${testId}-add-inst`),
    newAdditionalInstructions: getMappedAdditionalInstructions(newAdditionalInstructions, `new-${testId}-add-inst`),
    isHighlighted: isNewValueDifferent
  }
}

const getMappedAdditionalInstructions = (additionalInstructions, testId) => {

  const exist = (
    <AdditionalInstructionsTooltip additionalInstructions={additionalInstructions} testId={testId}/>
  );

  const nonExisting = (
    <Box data-testid={`${testId}-NA-column`} textAlign="right">{NA}</Box>
  );

  return {
    value: additionalInstructions ? exist: nonExisting
  };
};

// eslint-disable-next-line react/prop-types
const AdditionalInstructionsTooltip = ({additionalInstructions, testId}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (<Box data-testid={`${testId}`} textAlign="right">
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={handleTooltipClose}
        open={open}
        title={additionalInstructions}
      >
        <Typography
          noWrap
          onClick={handleTooltipOpen}
          style={{
            fontFamily: '"Verlag SSm 3r"',
            textAlign: 'right',
            fontSize: '0.8125rem'
          }}
        >{additionalInstructions}
        </Typography>
      </Tooltip>
    </ClickAwayListener>
  </Box>)
}

additionalInstructionsObjectMapper.propTypes = {
  currentAdditionalInstructions: PropTypes.object,
  label: PropTypes.string,
  newAdditionalInstructions: PropTypes.object,
  oldExists: PropTypes.bool,
  secondLevel: PropTypes.bool,
  showOld: PropTypes.bool,
  testId: PropTypes.string
};

export default additionalInstructionsObjectMapper;
