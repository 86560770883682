import React, {createContext, useReducer} from 'react';
import PropTypes from 'prop-types';
import {useCombinedReducer} from '../../common/useCombinedReducer';
import {bankingServiceFeeReducer} from './components/BankingAndOtherServices/bankingServiceFeeReducer';
import {summaryReducer} from './components/Summary/summaryReducer';
import {paymentFeesReducer} from './components/PaymentFees/paymentFeesReducer';
import {selectedTabIndexReducer} from './components/ScrollableTab/selectedTabIndexReducer';
import {commonReducer} from './components/commonReducer';
import {recurringFeesReducer} from './components/RecurringFees/recurringFeesReducer';
import {transactionFeesReducer} from './components/TransactionFees/transactionFeesReducer';
import {overdraftReducer} from './components/Overdraft/overdraftReducer';
import {revenueReducer} from './components/RevenueSimulation/revenueReducer';
import {fxPreciousMetalsReducer} from './components/FxPreciousMetals/fxPreciousMetalsReducer';
import {nmbFeesReducer} from './components/NMBFees/nmbFeesReducer';

export const PriceRequestStore = createContext();

const initialState = {
  selectedPricingRequest: {
    common: undefined,
    summary: undefined,
    bankingFees: undefined,
    paymentFees: undefined,
    recurringFees: undefined,
    transactionFees: undefined,
    nmbTransactionFees: undefined,
    nmbFXProducts: undefined,
    overdraft: undefined,
    revenue: undefined
  },
  selectedTabIndex: 0
};

export function PriceRequestProvider({customState, ...props}) {
  const initializerArg = customState ? customState : initialState;

  const [state, dispatch] = useCombinedReducer({
    selectedPricingRequest: useCombinedReducer({
      common: useReducer(commonReducer, initializerArg.selectedPricingRequest.common, undefined),
      summary: useReducer(summaryReducer, initializerArg.selectedPricingRequest.summary, undefined),
      bankingFees: useReducer(bankingServiceFeeReducer, initializerArg.selectedPricingRequest.bankingFees, undefined),
      paymentFees: useReducer(paymentFeesReducer, initializerArg.selectedPricingRequest.paymentFees, undefined),
      recurringFees: useReducer(recurringFeesReducer, initializerArg.selectedPricingRequest.recurringFees, undefined),
      transactionFees: useReducer(transactionFeesReducer, initializerArg.selectedPricingRequest.transactionFees, undefined),
      nmbTransactionFees: useReducer(nmbFeesReducer, initializerArg.selectedPricingRequest.nmbTransactionFees, undefined),
      nmbFXProducts: useReducer(fxPreciousMetalsReducer, initializerArg.selectedPricingRequest.nmbFXProducts, undefined),
      overdraft: useReducer(overdraftReducer, initializerArg.selectedPricingRequest.overdraft, undefined),
      revenue: useReducer(revenueReducer, initializerArg.selectedPricingRequest.revenue, undefined)
    }),
    selectedTabIndex: useReducer(selectedTabIndexReducer, initializerArg.selectedTabIndex, undefined)
  });

  const value = {state, dispatch};
  return <PriceRequestStore.Provider value={value}>{props.children}</PriceRequestStore.Provider>;
}


// --- PropTypes

PriceRequestProvider.propTypes = {
  children: PropTypes.any.isRequired,
  customState: PropTypes.object
};

