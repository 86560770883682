import React, {useContext, useEffect, useState} from 'react';
import {Grid, makeStyles, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import {getFormLabel} from '../../../../Common/components/form/formLabel';
import {getFormText} from '../../../../Common/components/form/formText';
import PriceSelect from '../../../../Common/components/PriceSelect';
import {getPricingTypeFromCode, getPricingTypeSelectValues} from '../../../../../common/enums/pricingType';
import {getDefaultFontSize} from '../../../../../theme/common/font';
import {PriceRequestStore} from '../../../PriceSimulationStore';
import {useNotification} from '../../../../Common/components/Notification';

const useStyles = makeStyles((theme) => ({
  label: {
    ...getFormLabel()
  },
  text: {
    width: '100%',
    ...getFormText()
  },
  textReadOnly: {
    ...getDefaultFontSize(theme),
    textAlign: 'right'
  }
}));

const NMBFeePricingType = ({pricingType, onPricingTypeChange, readOnly}) => {
  const classes = useStyles();
  const {state} = useContext(PriceRequestStore);
  const [options, setOptions] = useState();
  const notification = useNotification();

  useEffect(() => {
    const availableOptionsBasedOnOwner = getPricingTypeSelectValues(state.selectedPricingRequest.common?.owner?.location, state.selectedPricingRequest.common?.bookingCenter);
    if((!availableOptionsBasedOnOwner || availableOptionsBasedOnOwner.length === 0) && !readOnly){
      notification.error('Unable to identify RM Owner location or portfolio booking center.\nPlease try raising a new pricing request.\nif the issue still persists please contact IT to sync the portfolio owner.');
    }
    setOptions(availableOptionsBasedOnOwner);
    // eslint-disable-next-line
  }, [state.selectedPricingRequest.common]);


  return (
    <Grid
      container
      spacing={1}
    >
      <Grid item xs={12}>
        {!readOnly && <PriceSelect data-testid={'test-pricing-type-select'} inputProps={{name: 'pricingType', id: 'uncontrolled-native', 'data-testid': 'test-pricing-type-input'}} key={'pricing-type-select'} onValueChange={(pt) => {const value = getPricingTypeFromCode(pt.value); onPricingTypeChange({value: value, selectors: ['newCondition', 'pricingType']})}} options={options} readOnly={readOnly} value={pricingType}/>}
        {readOnly &&
        <Typography className={classes.textReadOnly}>{getPricingTypeFromCode(pricingType)?.desc}</Typography>}
      </Grid>
    </Grid>
  );
};

NMBFeePricingType.propTypes = {
  onPricingTypeChange: PropTypes.func,
  pricingType: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default React.memo(NMBFeePricingType);
