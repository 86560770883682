import {Box, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import api from '../../../../../../../../../../api/api';
import {getErrorMessage} from '../../../../../../../../../../common/getErrorMessage';
import CommonDialog from '../../../../../../../../../Common/components/Dialog/CommonDialog';
import {useNotification} from '../../../../../../../../../Common/components/Notification';

const AcmApprovalActionCell = ({row, fetchData}) => {
  const {pricingRequestId, portfolioNumber, tenantId} = row.original;
  const notification = useNotification();
  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
  const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
  const [isRequestOngoing, setIsRequestOngoing] = useState(false);

  const approve = async () => {
    setIsRequestOngoing(true);
    try {
      await api.pricingRequest.submit(pricingRequestId, {},tenantId, {isPendingACM: true});
      setApprovalDialogOpen(false);
      setIsRequestOngoing(false);
      fetchData();
      notification.success('Successfully submitted pricing request');
    } catch (err) {
      notification.error('Failed submitting pricing request\n' + getErrorMessage(err));
      setIsRequestOngoing(false);
      throw new Error();
    }
  };

  const reject = async () => {
    setIsRequestOngoing(true);
    try {
      await api.pricingRequest.reject({pricingRequestId}, tenantId, {isPendingACM: true});
      setRejectionDialogOpen(false);
      setIsRequestOngoing(false);
      fetchData();
      notification.success('Successfully rejected pricing request');
    } catch (err) {
      notification.error('Failed submitting pricing request\n' + getErrorMessage(err));
      setIsRequestOngoing(false);
      throw new Error();
    }
  };

  return (
    <Box display="flex" gap={1}>
      <CommonDialog
        isCancelDisabled={isRequestOngoing}
        onClose={() => setApprovalDialogOpen(false)}
        onContinue={approve}
        open={approvalDialogOpen}
        text={`You are going to submit a pricing request for ${portfolioNumber}.
                Do you want to continue?.`}
      />
      <CommonDialog
        isCancelDisabled={isRequestOngoing}
        onClose={() => setRejectionDialogOpen(false)}
        onContinue={reject}
        open={rejectionDialogOpen}
        text={`You are going to reject a pricing request for ${portfolioNumber}.
                Do you want to continue?.`}
      />
      <Button onClick={() => setApprovalDialogOpen(true)} style={{marginRight: 2}}>
        Approve
      </Button>
      <Button onClick={() => setRejectionDialogOpen(true)}>Reject</Button>
    </Box>
  );
};

AcmApprovalActionCell.propTypes = {
  fetchData: PropTypes.any,
  row: PropTypes.any
};

export default AcmApprovalActionCell;
