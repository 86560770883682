import React, {useContext} from 'react';
import {AppBar, Box, Toolbar, Typography} from '@material-ui/core';
import UserInfo from './components/UserInfo';
import logo from '../../../../assets/images/logo.svg';
import {Store} from '../../../../Store';
import {useHistory} from 'react-router-dom';

const TopBar = () => {
  const {parentDispatch} = useContext(Store);
  const history = useHistory();
  const showHome = () => {
    parentDispatch({type: 'RESET'});
    history.push('/');
  };
  return (
    <div>
      <AppBar position="static">
        <Toolbar disableGutters style={{height: 'auto'}}>
          <Box alignItems="center" display="flex" justifyContent="space-between" paddingX={2} width="100%">
            <Typography variant="h3">
              <img alt="Julius Baer" height="35px" onClick={showHome} src={logo} style={{cursor: 'pointer'}} />
            </Typography>
            <UserInfo/>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopBar;
