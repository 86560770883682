export const FX_PRODUCTS = Object.freeze({
  code: 'FX_PRODUCTS',
  desc: 'FX Products',
  sortOrder: 0
});
export const PRECIOUS_METALS = Object.freeze({
  code: 'PRECIOUS_METALS',
  desc: 'Precious Metals',
  sortOrder: 1
});

export const getFXProductTypeFromCode = code => {
  return getAllFXProductTypes().filter(product => product.code === code)[0];
};

export const getFXProductTypeFromDescription = description => {
  const filtered = getAllFXProductTypes().filter(condition => condition.desc === description);
  if(filtered){
    return filtered[0];
  }
  return {};
}

export const getFXProductTypeDescriptionFromCode = code => {
  return getAllFXProductTypes()
    .filter(condition => condition.code === code)
    .map(condition => condition.desc)[0];
};

export const getAllFXProductTypes = () => {
  return [
    FX_PRODUCTS,
    PRECIOUS_METALS
  ];
};
