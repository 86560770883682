import {isNullOrUndefined} from './isNullOrUndefined';
import {BILA, UNILA} from './enums/agreementType';

export const getOldConditionLabelAgreementType = (pricingAgreedWithClient) => {
  let agreementType = null;

  if (!isNullOrUndefined(pricingAgreedWithClient)) {
    if (pricingAgreedWithClient === BILA.code) {
      agreementType = '(BILA)';
    } else if (pricingAgreedWithClient === UNILA.code) {
      agreementType = '(UNILA)';
    }
  }
  return agreementType ? `OLD CONDITIONS ${agreementType}` : 'OLD CONDITIONS';
};
