import React from 'react';
import {ActionCell, ApproverCell, DateCell, PricingRequestIdCell, BubbleCell} from './components';
import {
  ACM_APPROVAL_ACTION,
  ACM_DEVIATION_ACTION,
  ACTION,
  APPROVER,
  AUM_AC_GROUP,
  AUM_ACCOUNT,
  PCI_AC_GROUP,
  PCI_ACCOUNT,
  PRICING_REQUEST_ID,
  REPORTING_FEE,
  REQUEST_DATE,
  RETAINED_MAIL_FEE,
  REVENUE_IMPACT_CHF,
  ROA_AC_GROUP,
  ROA_ACCOUNT,
  ROA_IMPACT,
  SERVICE_MODEL,
  SPECIAL_ACTION,
  SPECIAL_MAILING_FEE,
  BANKING_SERVICE_PACKAGE_FEE,
  CODED_ACCOUNT_FEE,
  OLD_PEER_COMPARISON,
  NEW_PEER_COMPARISON
} from '../../../columnData';
import PriceNumberFormat from '../../../../../../../Common/components/PriceNumberFormat';
import {BPS, CHF} from '../../../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import SpecialActionCell from './components/SpecialActionCell';
import {EAM_EFA_MANAGED_DESC} from '../../../../../../../../common/enums/serviceModel';
import AcmAndPeriodicActionCell from './components/AcmActionCell';
import AcmApprovalActionCell from './components/AcmApprovalActionCell';
import {isNullOrUndefined} from '../../../../../../../../common/isNullOrUndefined';

function CustomCell({column, deviationRetainedMailEnabled, fetchData, row, setPriceRequests, value}) {
  switch (column.id) {
    case REQUEST_DATE.key:
      return value ? <DateCell value={value}/> : null;

    case APPROVER.key:
      return value ? <ApproverCell value={value}/> : null;

    case ACTION.key:
      return !row?.isGrouped ? <ActionCell row={row} setPriceRequests={setPriceRequests}/> : null;

    case SPECIAL_ACTION.key:
      return !row?.isGrouped ? <SpecialActionCell row={row}/> : null;

    case AUM_AC_GROUP.key:
    case PCI_AC_GROUP.key:
    case AUM_ACCOUNT.key:
    case PCI_ACCOUNT.key:
    case REVENUE_IMPACT_CHF.key:
      return !isNullOrUndefined(value) ? (
        <PriceNumberFormat data-testid={`test-${column.id}-cell`} type={CHF} value={value}/>
      ) : null;

    case SPECIAL_MAILING_FEE.key:
    case REPORTING_FEE.key:
    case RETAINED_MAIL_FEE.key:
    case BANKING_SERVICE_PACKAGE_FEE.key:
    case CODED_ACCOUNT_FEE.key:     
      return !isNullOrUndefined(value) ? (
        <PriceNumberFormat data-testid={`test-${column.id}-cell`} type={CHF} value={value}/>
      ) : <div style={{ textAlign: 'right'}}>N.A.</div>;
      
    case ROA_AC_GROUP.key:
    case ROA_ACCOUNT.key:
    case ROA_IMPACT.key:
      return !isNullOrUndefined(value) ? (
        <PriceNumberFormat data-testid={`test-${column.id}-cell`} type={BPS} value={value}/>
      ) : null;
      
    case PRICING_REQUEST_ID.key:
      return value ? <PricingRequestIdCell tenantId={row.original.tenantId} value={value}/> : null;

    case SERVICE_MODEL.key:
      return value === EAM_EFA_MANAGED_DESC ? '' : value ? value : null;

    case ACM_DEVIATION_ACTION.key:
      return !row?.isGrouped && (row.original.deviationEditEnabled || row.original.periodicReviewEnabled ) ? (
        <AcmAndPeriodicActionCell deviationRetainedMailEnabled={deviationRetainedMailEnabled} fetchData={fetchData} row={row}/>
      ) : null;

    case ACM_APPROVAL_ACTION.key:
      return !row?.isGrouped ? <AcmApprovalActionCell fetchData={fetchData} row={row}/> : null;

    case OLD_PEER_COMPARISON.key:
      return !row?.isGrouped ? <BubbleCell markerData={value}/> : null;

    case NEW_PEER_COMPARISON.key:
      return !row?.isGrouped ? <BubbleCell markerData={value}/> : null;

    default:
      return value ? value : null;
  }
}

export default CustomCell;
