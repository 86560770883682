export default {
  toolbarBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      border: 'none',
    }
  }
};
  