import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Box, Button, Checkbox, Divider, Grid, Typography} from '@material-ui/core';
import {Store} from '../../../../Store';
import {useNotification} from '../../../../views/Common/components/Notification';
import {getSelectedPriceRequestErrors} from '../../../../common/getSelectedPriceRequestErrors';
import {PriceRequestStore} from '../../../../views/PriceSimulation/PriceSimulationStore';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {SELECT_PORTFOLIO_NUMBERS_FOR_SUBMISSION,CURR_PRICING_REQUEST} from '../../../../common/actionTypes';
import {CLIPP_WHITE} from '../../../../theme/common/bjbColor';
import {DRAFT} from '../../../../common/statusTypes';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import {setIsLoading} from '../../../../Services';
import api from '../../../../api/api';
import {PRIMARY_BACKUP_RM_ROLE} from '../../../../common/enums/accessRole';
import {getRolesPortfolioChcBxEnable} from '../../../../common/enums/userRole';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: ' '
    }
  },
  icon: {
    fontWeight: 'normal',
    color: '#546e7a',
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  active: {
    paddingLeft: '20px',
    justifyContent: 'left',
    marginBottom: '0px',
    backgroundColor: '#727899',
    borderColor: '#efefef',
    pointerEvents: 'none',
    color: '#fff',
    width: '100%',
    '&:focus': {
      outline: '0 !important'
    }
  },
  inactive: {
    paddingLeft: '20px',
    justifyContent: 'left',
    marginBottom: '0px',
    backgroundColor: CLIPP_WHITE,
    opacity: '0.8',
    borderColor: CLIPP_WHITE,
    width: '100%',
    '&:hover': {
      borderColor: CLIPP_WHITE,
      backgroundColor: '#d0d2dd'
    },
    '&:focus': {
      outline: '0 !important;'
    }
  },
  menu: {
    fontWeight: 'normal',
    textAlign: 'left',
    marginBottom: '3px',
    borderColor: CLIPP_WHITE,
    width: '100%',
    pointerEvents: 'inherit',
    justifyContent: 'left',
    '&:hover': {
      borderColor: CLIPP_WHITE,
      color: '#6672B8'
    },
    '&:focus': {
      outline: '0 !important;'
    }
  },
  container: {
    backgroundColor: '#141E55',
    textAlign: 'center',
    paddingBottom: '10px',
    paddingLeft: '5px',
    paddingRight: '5px'
  }
}));

const TEST_PREFIX = 'test-sidebar-nav';

const SidebarNav = () => {
  const SUMMARY_PATH = '/summary';

  const classes = useStyles();
  const {parentState, parentDispatch} = useContext(Store);
  const {state, dispatch} = useContext(PriceRequestStore);
  const notification = useNotification();
  const history = useHistory();
  const location = useLocation();
  const portfolioDisabled = location.pathname.endsWith(SUMMARY_PATH);
  const {pricingRequestId} = useParams();

  const selectedPortfolioNumbersForSubmission =
    state.selectedPricingRequest.common?.portfolioNumbersForSubmission || [];

  const handleSelectedPortfolio = portfolio => {
    if (getSelectedPriceRequestErrors(state).length > 0) {
      notification.error('Resolve validation errors before changing portfolios');
    } else {
      if (state.selectedPricingRequest.common?.pricingRequestStatus === DRAFT) {
        setIsLoading(true, parentDispatch);
        api.pricingRequest.updatePricingRequest({...state.selectedPricingRequest, ...state.selectedPricingRequest.common})
          .then(() => {
            history.push(`/simulation/${portfolio.pricingRequestId}`);
          })
          .catch((err) => {
            setIsLoading(false, parentDispatch);
            notification.error('Save failed\n' + getErrorMessage(err));
          });
      } else {
        history.push(`/simulation/${portfolio.pricingRequestId}`);
      }
      parentDispatch({type: CURR_PRICING_REQUEST, payload: portfolio.pricingRequestId});
    }
  };
  const handleSelectPortfolioForSubmission = e => {
    let portfolioNumbersForSubmission;
    if (e.target.checked) {
      portfolioNumbersForSubmission = [...selectedPortfolioNumbersForSubmission, e.target.value];
    } else {
      portfolioNumbersForSubmission = selectedPortfolioNumbersForSubmission?.filter(
        portfolioNumber => portfolioNumber !== e.target.value
      );
    }

    if (portfolioNumbersForSubmission) {
      const sortedPortfolioNumbersForSubmission = portfolioNumbersForSubmission.sort((a, b) =>
        a > b ? 1 : a < b ? -1 : 0
      );
      dispatch({type: SELECT_PORTFOLIO_NUMBERS_FOR_SUBMISSION, payload: sortedPortfolioNumbersForSubmission});
    }
  };

  const customerLocation = parentState.customer?.accountNumber?.slice(0,2);
  const readOnly = state.selectedPricingRequest.common?.pricingRequestStatus !== DRAFT;
  const isRoleChkBxEnable = !!parentState.user?.userRoles.
    find(userRole => (getRolesPortfolioChcBxEnable().includes(userRole.role)
                                                                           && userRole.location === customerLocation));

  return (
    <div className={classes.container}>
      <Divider style={{backgroundColor: '#dfdfdf', marginLeft: '0px', marginRight: '0px'}} variant="middle"/>
      <div style={{padding: '10px', paddingBottom: '0px'}}>
        <Typography style={{fontSize: '15px', paddingTop: '15px', paddingBottom: '15px', color: CLIPP_WHITE, fontWeight: 'bold', textAlign:'left'}}>Client {parentState.customer?.accountNumber}</Typography>
      </div>
      <div
        style={{
          paddingLeft: '0px',
          paddingBottom: '10px',
          padding: '0px',
          border: '1px',
          backgroundColor: '#fff'
        }}
      >
        {parentState.portfolios &&
        parentState.portfolios.map(portfolio => {
          return portfolio.pricingRequestId?.toString() === pricingRequestId ? (
            <Grid
              item
              key={portfolio.portfolioNumber}
              style={{paddingRight: '0px', paddingLeft: '0px', backgroundColor: '#CCD0E7'}}
              xs={12}
            >
              <Box display="flex">
                {!readOnly && (
                  <Checkbox
                    checked={
                      selectedPortfolioNumbersForSubmission?.some(
                        portfolioNumber => portfolioNumber === portfolio.portfolioNumber
                      ) || false
                    }
                    color="primary"
                    disabled={portfolioDisabled || ((!isRoleChkBxEnable) && portfolio.accessRoleType !== PRIMARY_BACKUP_RM_ROLE)}
                    inputProps={{'data-testid': `${TEST_PREFIX}-checkbox-${portfolio.portfolioNumber}`}}
                    onChange={handleSelectPortfolioForSubmission}
                    style={{padding: 0}}
                    value={portfolio.portfolioNumber}
                  />
                )}
                <Button className={classes.active}>{portfolio.portfolioNumber}</Button>
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              key={portfolio.portfolioNumber}
              style={{paddingRight: '0px', paddingLeft: '0px', backgroundColor: '#CCD0E7'}}
              xs={12}
            >
              <Box display="flex">
                {!readOnly && (
                  <Checkbox
                    checked={
                      selectedPortfolioNumbersForSubmission?.some(
                        portfolioNumber => portfolioNumber === portfolio.portfolioNumber
                      ) || false
                    }
                    color="primary"
                    disabled={portfolioDisabled || ((!isRoleChkBxEnable) && portfolio.accessRoleType !== PRIMARY_BACKUP_RM_ROLE)}
                    inputProps={{'data-testid': `${TEST_PREFIX}-checkbox-${portfolio.portfolioNumber}`}}
                    onChange={handleSelectPortfolioForSubmission}
                    style={{padding: 0}}
                    value={portfolio.portfolioNumber}
                  />
                )}
                <Button
                  className={classes.inactive}
                  disabled={portfolioDisabled}
                  onClick={() => handleSelectedPortfolio(portfolio)}
                >
                  {portfolio.portfolioNumber}
                </Button>
              </Box>
            </Grid>
          );
        })}
      </div>
    </div>
  );
};

export default SidebarNav;
