export const getApplicableStandardsSelectValues = (options) => {
  if(options){
    return options.map(p => ({
      'key': p.applicableStandardId,
      'value': p.applicableStandard
    }));
  }
  return [];
}

export const getApplicableStandardDescriptionById = (options, applicableStandardId) => {
  if(applicableStandardId && options && options.length > 0){
    const filtered = options.filter(option => option.applicableStandardId === applicableStandardId);
    if(filtered && filtered.length > 0){
      return filtered[0].applicableStandard;
    }
  }
  return '-';
}