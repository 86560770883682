import React, {useRef,useContext} from 'react';
import PropTypes from 'prop-types';
import {LabelInput} from '../../../../Common/components';
import {Grid} from '@material-ui/core';
import {REGULAR} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {makeStyles} from '@material-ui/styles';
import {TRANSACTION_NO_OF_TRADE_INFO_TEXT, TRANSACTION_VOLUME_INFO_TEXT} from '../../../../../common/enums/infoTexts';
import api from '../../../../../api/api';
import {PriceRequestStore} from '../../../PriceSimulationStore';
import {useNotification} from '../../../../Common/components/Notification';
import {getErrorMessage} from '../../../../../common/getErrorMessage';


const useStyles = makeStyles(() => ({
  outerGrid: {
    marginBottom: '4px'
  }
}));

const TransactionFeeSubFundVolume = ({transactionFeeVolume, handleValueChange, readOnly, testIdSuffix, assetSubLevel}) => {
  const classes = useStyles();
  const defaultNumberOfTrades = useRef(transactionFeeVolume?.numberOfTrades);
  const defaultTransactionVolume = useRef(transactionFeeVolume?.transactionVolume);
  const notification = useNotification();
  const {state} = useContext(PriceRequestStore);
  const portfolioNumber = state.selectedPricingRequest?.common?.portfolioNumber;
  const resetNoOfTrades = async () => {
    try {
      const response = await api.pricingRequest.resetNumberTrades(portfolioNumber,assetSubLevel.code);
      const transactionVolume = response.data;
      handleValueChange({
        value: transactionVolume,
        selectors: ['transactionFeeVolume', 'numberOfTrades']
      });
    } catch (err) {
      notification.error('Error resetting numberOfTrades \n' + getErrorMessage(err));
      throw new Error();
    }
  };

  const resetTransactionVolume = async () => {
    try {
      const response = await api.pricingRequest.resetTransactionVolume(portfolioNumber,assetSubLevel.code);
      const transactionVolume = response.data;
      handleValueChange({
        value: transactionVolume,
        selectors: ['transactionFeeVolume', 'transactionVolume']
      });
    } catch (err) {
      notification.error('Error resetting transactionVolume \n' + getErrorMessage(err));
      throw new Error();
    }
  };

  return (
    <Grid className={classes.outerGrid} container spacing={1}>
      <Grid item md={6} xs={12}>
        <LabelInput
          data-testid="test-transaction-fees-number-of-trades"
          defaultValue={defaultNumberOfTrades.current}
          editIcon={!readOnly}
          editProps={{'data-testid': `test-transaction-fees-edit-number-of-trades-${testIdSuffix}`}}
          infoText={TRANSACTION_NO_OF_TRADE_INFO_TEXT}
          label="Number of Trades"
          onReset={resetNoOfTrades}
          onValueChange={vr =>
            handleValueChange({...vr, selectors: ['transactionFeeVolume', 'numberOfTrades']})
          }
          readOnly={readOnly}
          resetIcon={!readOnly}
          resetProps={{'data-testid': `test-transaction-fees-reset-number-of-trades-${testIdSuffix}`}}
          type={REGULAR}
          value={transactionFeeVolume?.numberOfTrades}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <LabelInput
          data-testid="test-transaction-fees-transaction-volume"
          defaultValue={defaultTransactionVolume.current}
          disableMax
          editIcon={!readOnly}
          editProps={{'data-testid': `test-transaction-fees-edit-transaction-volume-${testIdSuffix}`}}
          infoText={TRANSACTION_VOLUME_INFO_TEXT}
          label="Transaction Volume"
          onReset={resetTransactionVolume}
          onValueChange={vr =>
            handleValueChange({...vr, selectors: ['transactionFeeVolume', 'transactionVolume']})
          }
          readOnly={readOnly}
          resetIcon={!readOnly}
          resetProps={{'data-testid': `test-transaction-fees-reset-transaction-volume-${testIdSuffix}`}}
          value={transactionFeeVolume?.transactionVolume}
        />
      </Grid>
    </Grid>
  );
};

TransactionFeeSubFundVolume.propTypes = {
  assetSubLevel: PropTypes.object,
  handleValueChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  testIdSuffix: PropTypes.string,
  transactionFeeVolume: PropTypes.shape({
    numberOfTrades: PropTypes.number,
    transactionVolume: PropTypes.number
  })
};

export default TransactionFeeSubFundVolume;
