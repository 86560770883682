import React from 'react';
import PropTypes from 'prop-types';
import agreementTypeObjectMapper from '../../PriceSimulation/components/Common/AgreementTypeIndicator/agreementTypeObjectMapper';
import {acknowledgeSpecialStructureRow} from './objectmapper/AcknowledgeSpecialStructure/acknowledgeSpecialStructureObjectMapper';
import {isNullOrUndefined} from '../../../common/isNullOrUndefined';
import additionalInstructionsObjectMapper from './objectmapper/AdditionalInstructions/additionalInstructionsObjectMapper';
import PriceConditionTableRowSection from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {
  LABEL_SUMMARY,
  NEW_CONDITIONS_SUMMARY,
  OLD_CONDITIONS_SUMMARY,
  TEXT
} from '../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import {SUMMARY_TABLE_CUSTOM_STYLE} from '../../../common/constants';
import {isEmpty} from 'lodash';

const CommonBILARows = ({
  addedColumn,
  oldAgreementType,
  newAgreementType,
  oldAcknowledge,
  newAcknowledge,
  oldAdditionalInstructions,
  newAdditionalInstructions,
  secondLevel,
  hasDisjunctTiering,
  hideAcknowledgement,
  hideBILA,
  showDiff,
  showOld,
  testId}) => {
  const agreementType = {
    ...agreementTypeObjectMapper({
      currentAgreementType: oldAgreementType,
      label: 'Agreement Type',
      newAgreementType: newAgreementType,
      secondLevel,
      showDiff,
      showOld,
      testId
    })
  }
  const emptyFieldComponent = <div style={{textAlign: 'right'}}>-</div>;

  const mappedAgreementType = [{
    ...agreementType,
    addedColumn: addedColumn ? emptyFieldComponent: null
  }];

  const acknowledgements = {
    ...acknowledgeSpecialStructureRow({
      label: 'Acknowledged Special Structures',
      oldAcknowledged: isNullOrUndefined(oldAcknowledge) ? null: oldAcknowledge,
      newAcknowledged: isNullOrUndefined(newAcknowledge) ? null: newAcknowledge,
      secondLevel,
      showDiff,
      showOld,
      testId
    })
  }

  const mappedAcknowledged = [{
    ...acknowledgements,
    addedColumn: addedColumn ? emptyFieldComponent: null
  }];

  const additionalInstructions = {
    ...additionalInstructionsObjectMapper({
      currentAdditionalInstructions: !isNullOrUndefined(oldAdditionalInstructions) ? oldAdditionalInstructions: null,
      label: 'Additional Instructions',
      newAdditionalInstructions: !isNullOrUndefined(newAdditionalInstructions) ? newAdditionalInstructions: null,
      oldExists: !isNullOrUndefined(oldAcknowledge),
      secondLevel,
      showDiff,
      showOld,
      testId
    })
  }

  const mappedAdditionalInstructions = [{
    ...additionalInstructions,
    addedColumn: addedColumn ? emptyFieldComponent: null
  }];

  const output = [];

  if(!hideBILA){
    if(!isEmpty(agreementType)) {
      const agreementTypeComponent = createAgreementType(mappedAgreementType, showOld, addedColumn, `${testId}-agreement-type`, hasDisjunctTiering);
      if (agreementTypeComponent) {
        output.push(agreementTypeComponent);
      }
    }
  }

  if(!hideAcknowledgement) {
    if(!isEmpty(acknowledgements)) {
      const acknowledgeSpecialStructureComponent = createAcknowledgeSpecialStructure(mappedAcknowledged, showOld, addedColumn, `${testId}-acknowledge`, hasDisjunctTiering);
      if (acknowledgeSpecialStructureComponent) {
        output.push(acknowledgeSpecialStructureComponent);
      }
    }

    if(!isEmpty(additionalInstructions)) {
      const additionalInstructionsComponent = createAdditionalInstructions(mappedAdditionalInstructions, showOld, addedColumn, `${testId}-add-inst`, hasDisjunctTiering);
      if (additionalInstructionsComponent) {
        output.push(additionalInstructionsComponent);
      }
    }
  }

  if(!output || output.length === 0){
    return null;
  }
  return (<>{ output }</>)
}

// eslint-disable-next-line react/no-multi-comp
const createAgreementType = (mappedAgreementType, showOld, addedColumn, testId, hasDisjunctTiering) => ( <><PriceConditionTableRowSection data={mappedAgreementType}>
  <PriceConditionTableColumn field="label" type={LABEL_SUMMARY}/>
  {showOld && (
    <PriceConditionTableColumn
      customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
      field="currentAgreementType.value"
      type={OLD_CONDITIONS_SUMMARY}
    />
  )}
  <PriceConditionTableColumn
    colSpan={hasDisjunctTiering ? 2 : 1}
    customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
    field="newAgreementType.value"
    isHighlightedFieldValueAccessor="isHighlighted"
    type={NEW_CONDITIONS_SUMMARY}
  />
  {addedColumn && <PriceConditionTableColumn data-testid={`${testId}-added-column`} field="addedColumn" type={TEXT}/>}
</PriceConditionTableRowSection></>);

// eslint-disable-next-line react/no-multi-comp
const createAcknowledgeSpecialStructure = (mappedAcknowledged, showOld, addedColumn, testId, hasDisjunctTiering) =>  (<>
  <PriceConditionTableRowSection data={mappedAcknowledged}>
    <PriceConditionTableColumn field="label" type={LABEL_SUMMARY}/>
    {showOld && (
      <PriceConditionTableColumn
        customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
        field="currentCondition.acknowledged"
        type={OLD_CONDITIONS_SUMMARY}
      />
    )}
    <PriceConditionTableColumn
      colSpan={hasDisjunctTiering ? 2 : 1}
      customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
      field="newCondition.acknowledged"
      isHighlightedFieldValueAccessor="isHighlighted"
      type={NEW_CONDITIONS_SUMMARY}
    />
    {addedColumn && <PriceConditionTableColumn data-testid={`${testId}-added-column`} field="addedColumn" type={TEXT}/>}
  </PriceConditionTableRowSection>
</>)

// eslint-disable-next-line react/no-multi-comp
const createAdditionalInstructions = (mappedAdditionalInstructions, showOld, addedColumn, testId, hasDisjunctTiering) =>
  (<>
    <PriceConditionTableRowSection data={mappedAdditionalInstructions}>
      <PriceConditionTableColumn field="label" type={LABEL_SUMMARY}/>
      {showOld &&
      <PriceConditionTableColumn
        customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
        field="currentAdditionalInstructions.value"
        type={OLD_CONDITIONS_SUMMARY}
      />}
      <PriceConditionTableColumn
        colSpan={hasDisjunctTiering ? 2 : 1}
        customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
        field="newAdditionalInstructions.value"
        isHighlightedFieldValueAccessor="isHighlighted"
        type={NEW_CONDITIONS_SUMMARY}
      />
      {addedColumn && <PriceConditionTableColumn data-testid={`${testId}-added-column`} field="addedColumn" type={TEXT}/>}
    </PriceConditionTableRowSection>
  </>)

CommonBILARows.propTypes = {
  addedColumn: PropTypes.bool,
  hasDisjunctTiering: PropTypes.bool,
  hideAcknowledgement: PropTypes.bool,
  hideBILA: PropTypes.bool,
  newAcknowledge: PropTypes.bool,
  newAdditionalInstructions: PropTypes.string,
  newAgreementType: PropTypes.string,
  oldAcknowledge: PropTypes.bool,
  oldAdditionalInstructions: PropTypes.string,
  oldAgreementType: PropTypes.string,
  secondLevel: PropTypes.bool,
  showDiff: PropTypes.bool,
  showOld: PropTypes.bool.isRequired,
  testId: PropTypes.string
};

export default CommonBILARows;
