import React from 'react';
import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    width: '100%'
  }
}));

const LoadingSpinner = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loadingSpinner} data-testid="test-loading-spinner">
      <CircularProgress/>
    </Box>
  );
};

LoadingSpinner.propTypes = {};

export default LoadingSpinner;
