import React, {useEffect, useState} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {getFormLabel} from '../formLabel';
import {getFormText} from '../formText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {FORM_BACKGROUND_COLOR} from '../../../../../theme/common/color';

const useStyles = makeStyles(() => ({
  rootForEditableField: {
    backgroundColor: FORM_BACKGROUND_COLOR
  },
  label: {
    ...getFormLabel()
  },
  text: {
    width: '100%',
    ...getFormText()
  }
}));

const LabelRadioGroup = ({name, label, options, value, onChange, ...others}) => {
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    if (value) {
      setDefaultValue(value);
    }
  }, [value]);

  let radioElements = [];
  for (let option of (options || [])) {
    radioElements.push(
      <FormControlLabel
        control={<Radio color={'primary'}/>}
        key={'key_' + option.value}
        label={option.label}
        value={option.value}
        {...others}
      />
    );
  }

  return (
    <Grid
      className={classes.rootForEditableField}
      container
      spacing={1}
    >
      {
        label && (
          <Grid
            className={classes.label}
            item
            xs={4}
          >
            {label}
          </Grid>
        )
      }
      <Grid
        item
        xs={8}
      >
        <RadioGroup
          aria-label={'label_' + name}
          name={name}
          onChange={e => onChange(e)}
          row
          value={defaultValue}
        >
          {radioElements}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

LabelRadioGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string
  })).isRequired,
  value: PropTypes.string
};

export default LabelRadioGroup;
