import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {IconButton, Typography} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(theme => ({
  paginationControl: {
    display: 'flex',
    alignItems: 'center'
  },
  pageNumber: {
    marginLeft: theme.spacing(2)
  }
}));

const PaginationControl = ({goToPage, paginationInfo}) => {
  const classes = useStyles();
  const {page, totalPages} = paginationInfo;
  const canNextPage = page + 1 < totalPages,
    canPreviousPage = page > 0;

  return (
    <div className={classes.paginationControl}>
      <IconButton disabled={!canPreviousPage} onClick={() => goToPage(0)} size="small">
        <FirstPageIcon/>
      </IconButton>
      <IconButton disabled={!canPreviousPage} onClick={() => goToPage(page - 1)} size="small">
        <ChevronLeftIcon/>
      </IconButton>
      <IconButton
        disabled={totalPages === 0 || (totalPages && !canNextPage)}
        onClick={() => goToPage(page + 1)}
        size="small"
      >
        <ChevronRightIcon/>
      </IconButton>
      <IconButton disabled={!canNextPage} onClick={() => goToPage(totalPages - 1)} size="small">
        <LastPageIcon/>
      </IconButton>

      <Typography className={classes.pageNumber} variant="caption">
        Page {paginationInfo.page + 1}{' '}
        {`of ${paginationInfo.totalPages || 1}`}
      </Typography>
      {paginationInfo.numberOfElements && paginationInfo.totalElements && (
        <Typography className={classes.pageNumber} variant="caption">
          Showing {paginationInfo.numberOfElements} out of {paginationInfo.totalElements} items
        </Typography>
      )}
    </div>
  );
};

PaginationControl.propTypes = {
  goToPage: PropTypes.func,
  paginationInfo: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
    numberOfElements: PropTypes.number
  })
};

export default PaginationControl;
