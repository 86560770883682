export const getPortfoliosWithPortfolioDetails = (initiatedPortfolios, portfolios) => {
  const initiatedPortfoliosWithDetails = [];

  for (const initiatedPortfolio of initiatedPortfolios) {
    const portfolioDetails = (portfolios || []).find(
      p => p.portfolioNumber === initiatedPortfolio.portfolioNumber
    );

    const {accountOpenDate, accessRoleType, accountOwnerUId} = portfolioDetails;

    initiatedPortfoliosWithDetails.push({...initiatedPortfolio, accountOpenDate, accessRoleType, accountOwnerUId});
  }
  return initiatedPortfoliosWithDetails;
};
