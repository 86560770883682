import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Grid, Tab, Tabs} from '@material-ui/core';
import {useHistory, useParams} from 'react-router-dom';
import api from '../../api/api';
import {Store} from '../../Store';
import {useNotification} from '../Common/components/Notification';
import {getSelectedPriceRequestErrors} from '../../common/getSelectedPriceRequestErrors';
import {PriceRequestStore} from '../PriceSimulation/PriceSimulationStore';
import {SELECT_PORTFOLIO, SELECT_PORTFOLIO_FOR_SUBMISSION, SELECT_PORTFOLIOS} from '../../common/actionTypes';
import AsyncButton from '../Common/components/AsyncButton';
import SubmissionSummaryTable from './components/SubmissionSummaryTable';
import {LOAD_PRICING_REQUEST_RESPONSE} from '../PriceSimulation/components/pricingRequestActions';
import {DRAFT} from '../../common/statusTypes';
import TabPanel from '../Common/components/TabPanel';
import {validatePricingRequest} from '../../common/validatePricingRequest';
import ServiceModelChangeDialog from '../Common/components/ServiceModelChangeDialog';
import {getPortfoliosWithPortfolioDetails} from '../../common/getPortfoliosWithPortfolioDetails';
import {getErrorMessage} from '../../common/getErrorMessage';
import {returnToHome, setIsLoading} from '../../Services';
import {Alert} from '@material-ui/lab';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  statementRadioGroup: {
    flexDirection: 'row',
    marginLeft: theme.spacing(1)
  }
}));

const PriceSimulationSummary = ({rowPricingRequestId, openApproveDialog, openReturnDialog, 
  closeRequestDetailsDialog, tenantId}) => {
  const classes = useStyles();
  const {state, dispatch} = useContext(PriceRequestStore);
  const {parentState, parentDispatch} = useContext(Store);
  let {pricingRequestId} = useParams();
  pricingRequestId = pricingRequestId ? pricingRequestId : rowPricingRequestId;
  const [summaryTabValue, setSummaryTabValue] = useState(parseInt(pricingRequestId));
  const [portfoliosForSubmission, setPortfoliosForSubmission] = useState();
  const history = useHistory();
  const notification = useNotification();
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [openServiceModelDialog, setOpenServiceModelDialog] = useState(false);
  const [invalidPortfolios, setInvalidPortfolios] = useState([]);
  const [showDiff, setShowDiff] = useState(false);
  const [showPendingRequestActionButtons, setShowPendingRequestActionButtons] = useState(false);

  

  const handleTabChange = (event, newValue) => {
    if (!parentState.isFromPendingRequestApproval) {
      const link = `/simulation/${newValue}/summary`;
      history.push(link);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true, parentDispatch);
    // TO-DO: check if the person is authorized to see the pricing request
    api.pricingRequest
      .getWithTenantId(pricingRequestId, tenantId)
      .then(res => {
        if (res.data.portfolioNumber) {
          dispatch({type: LOAD_PRICING_REQUEST_RESPONSE, payload: res});
          dispatch({type: SELECT_PORTFOLIO, selected: res.data});
          const portfoliosForSubmissionInState = state.selectedPricingRequest.common?.portfoliosForSubmission;

          if (portfoliosForSubmissionInState?.length > 0) {
            setPortfoliosForSubmission(portfoliosForSubmissionInState, 'portfolioNumber');
            setSummaryTabValue(parseInt(pricingRequestId));
          } else {
            setPortfoliosForSubmission([res.data]);
            setSummaryTabValue(parseInt(pricingRequestId));
          }

          if (!parentState.portfolios) {
            parentDispatch({type: SELECT_PORTFOLIOS, payload: [res.data]});
          }

          // Only show action button if status is Draft and user comes from dashboard
          if (parentState.isFromDashboard && res.data?.pricingRequestStatus === DRAFT) {
            setShowActionButtons(true);
          } else if (parentState.isFromPendingRequestApproval) { // Show when above cond. not true ...to avoid three extra buttons in case when RM also has TH access
            setShowPendingRequestActionButtons(true);
          }

          setIsLoading(false, parentDispatch);
        } else {
          returnToHome(history, parentDispatch);
        }
      })
      .catch(err => {
        notification.error('Failed getting pricing request\n' + getErrorMessage(err));
        returnToHome(history, parentDispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingRequestId]);

  const handleSubmitConfirm = () => {
    return new Promise((asyncBtnResolve, asyncBtnReject) => {
      setIsLoading(true, parentDispatch);
      if (getSelectedPriceRequestErrors(state).length > 0) {
        notification.error('Resolve validation errors before submitting');
        asyncBtnReject();
      } else {
        let pricingRequestIds = [],
          portfolioNumbers = [];
        portfoliosForSubmission.forEach(portfolio => {
          pricingRequestIds.push(portfolio.pricingRequestId);
          portfolioNumbers.push(portfolio.portfolioNumber);
        });

        if (pricingRequestIds) {
          //Validate service model
          api.pricingRequest
            .validateServiceModel(portfoliosForSubmission)
            .then(response => {
              if (response.data) {
                const invalidPortfolios = response.data
                  ?.filter(data => data.serviceModelChanged)
                  .map(filteredData => ({
                    portfolioNumber: filteredData.portfolioNumber,
                    newServiceModel: filteredData.newServiceModel,
                    newMandateType: filteredData.newMandateType
                  }));

                if (invalidPortfolios?.length > 0) {
                  setInvalidPortfolios(invalidPortfolios);
                  setOpenServiceModelDialog(true);
                  return Promise.reject({response: {data: {message: 'Service model changed'}}});
                } else {
                  const pricingRequestIds = portfoliosForSubmission.map(
                    portfolio => portfolio.pricingRequestId
                  );
                  //Validate pricing request
                  return validatePricingRequest(pricingRequestIds, portfolioNumbers);
                }
              } else {
                return Promise.reject({response: {data: {message: 'No data received'}}});
              }
            })
            .then(async () => {
              const successfulSubmission = [];
              const errorMessages = [];
              const pdbData = state.selectedPricingRequest?.revenue?.pdbData;
              console.debug('parentState --->',parentState);
              const groupingData = parentState?.groupingdata;
              console.debug('groupingData --->',groupingData);
              for (let i = 0; i < pricingRequestIds.length; i++) {
                try {
                  await api.pricingRequest.submit(pricingRequestIds[i], {
                    'pdbData' : pdbData,
                    'groupingData' : groupingData
                  });
                  successfulSubmission.push(pricingRequestIds[i]);
                } catch (err) {
                  errorMessages.push(
                    `Failed submitting ${portfolioNumbers[i]}\n${getErrorMessage(err)}`
                  );
                }
              }

              return {successfulSubmission, errorMessages};
            })
            .then(({successfulSubmission, errorMessages}) => {
              if (errorMessages.length > 0) {
                notification.error(errorMessages.join('\n'));
              } else {
                notification.success('Successfully submitted new pricing request');
              }

              //Update the available portfolios
              const availablePortfolios = parentState.portfolios.filter(
                portfolio =>
                  !successfulSubmission.some(
                    successfulPricingRequestId => successfulPricingRequestId === portfolio.pricingRequestId
                  )
              );

              if (availablePortfolios.length > 0) {
                parentDispatch({type: SELECT_PORTFOLIOS, payload: availablePortfolios});
                dispatch({type: SELECT_PORTFOLIO_FOR_SUBMISSION, payload: []});
                history.push(`/simulation/${availablePortfolios[0].pricingRequestId}`);
              } else {
                returnToHome(history, parentDispatch);
              }
              setIsLoading(false, parentDispatch);
              asyncBtnResolve();
            })
            .catch(err => {
              notification.error(getErrorMessage(err));
              setIsLoading(false, parentDispatch);
              asyncBtnReject();
            });
        }
      }
    });
  };


  const handleContinueProceed = () => {
    return new Promise((asyncBtnResolve, asyncBtnReject) => {
      // Reinitiate pricing request
      const portfoliosToInitiate = parentState.portfolios.map(portfolio => {
        const invalidPortfolio = invalidPortfolios.find(d => d.portfolioNumber === portfolio.portfolioNumber);
        if (invalidPortfolio) {
          return {
            ...portfolio,
            serviceModelChanged: true,
            serviceModel: invalidPortfolio.newServiceModel,
            mandateType: invalidPortfolio.newMandateType
          };
        }
        return portfolio;
      });

      api.pricingRequest
        .initiate(portfoliosToInitiate)
        .then(response => {
          const portfoliosWithAccountOpenDate = getPortfoliosWithPortfolioDetails(
            response.data,
            parentState.portfolios
          );
          parentDispatch({type: SELECT_PORTFOLIOS, payload: portfoliosWithAccountOpenDate});
          history.push('/simulation/' + portfoliosWithAccountOpenDate[0].pricingRequestId);
          setOpenServiceModelDialog(false);
          asyncBtnResolve();
        })
        .catch(err => {
          notification.error('Internal Server Error: Unable to Proceed.\n' + getErrorMessage(err));
          asyncBtnReject();
        });
    });
  };

  const handleShowDifference = () =>{
    setShowDiff(!showDiff);
  }


  const shouldEnableDifference = !!(state?.selectedPricingRequest?.bankingFees?.currentConditionCodeFees ||
    state?.selectedPricingRequest?.transactionFees?.oldPricingExists ||
    state?.selectedPricingRequest?.transactionFees?.currentConditionCodeFees);

  useEffect(() => {
    if (shouldEnableDifference && parentState.isFromPendingRequestApproval) {
      setShowDiff(true);
    }
  }, [shouldEnableDifference]);

  return (
    <Grid container>
      <ServiceModelChangeDialog
        onClose={() => setOpenServiceModelDialog(false)}
        onContinue={handleContinueProceed}
        open={openServiceModelDialog}
        text={`The pricing request for ${invalidPortfolios.map(d => d.portfolioNumber).join(
          ', '
        )} is not valid anymore due to a service model / mandate type changes in CRM. CliPP tool has defaulted this request to list price of the latest update. Please verify all fees before submission.`}
      />
      <Grid item xs={12}>
        <Box padding={4}>

          <Box mb={12} style={{margin : 0 }}>
            <Alert severity="info" style={{boxShadow: 'none', fontSize: 14}}>
              Please note that after successful submission and approval, the special conditions you have requested will be instantly applied in T24.
              For recurring fees, the latest approval date determines the first full month for the new special conditions to become effective for the client(any
              changes to Banking Service Fees become effective for the entire quarter). For Brokerage fees, the special conditions becomes effective on a trade basis
              immediately. In case of bilaterally agreed special conditions, the client will receive a communication confirming these conditions.
              For additional information, please refer to the <Link href="https://intranet.juliusbaer.com/Pricingandfees_ap/price-lists-nmb/Pages/default.aspx" target="_blank">Pricing FAQs</Link>.
            </Alert>
          </Box>

          <Box className={classes.buttonGroup} display="flex" justifyContent="flex-end" mb={12}>
            <Button color="primary" disabled={!shouldEnableDifference} onClick={handleShowDifference}>
              {showDiff? 'Show All': 'Show Difference'}
            </Button>
            <span style={{flexGrow: 1}}/>
            {showActionButtons && (<>
              <AsyncButton color="primary" onClick={handleSubmitConfirm} text="Confirm" />
              <Button color="primary" onClick={() => history.push(`/simulation/${pricingRequestId}`)}>
                Cancel
              </Button> </>)}
            {showPendingRequestActionButtons && (<>
              <Button onClick={closeRequestDetailsDialog}>Back to dashboard</Button>
              <Button onClick={openApproveDialog}>Approve</Button>
              <Button onClick={openReturnDialog}>Return</Button>
            </>) 
            }
          </Box>

          <Tabs onChange={handleTabChange} scrollButtons="auto" value={summaryTabValue} variant="scrollable">
            {portfoliosForSubmission?.map(portfolio => {
              return (
                <Tab
                  key={portfolio.portfolioNumber}
                  label={portfolio.portfolioNumber}
                  value={portfolio.pricingRequestId}
                />
              );
            })}
          </Tabs>
          {portfoliosForSubmission?.map(portfolio => (
            <TabPanel
              index={portfolio.pricingRequestId}
              key={portfolio.portfolioNumber}
              value={summaryTabValue}
            >
              <SubmissionSummaryTable showDiff={showDiff}/>
            </TabPanel>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PriceSimulationSummary;
