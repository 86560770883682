import React from 'react';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {DPM} from '../../../../../common/enums/serviceModel';
import {getDiscountFromEffectivePrice} from '../../../../../common/getDiscountFromEffectivePrice';

const LABEL = 'Surcharge on restriction';
const KEY_PREFIX = 'recurring-fees-surcharge-on-restriction';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const surchargeOnRestriction = ({recurringFees, serviceModelCode, showDiff}) => {

  if (serviceModelCode !== DPM) {
    return null;
  }

  let result = {
    label: LABEL
  };
  let isNewValueDifferent =
    recurringFees?.currentCondition &&
    recurringFees?.currentCondition?.surchargeOnRestriction?.effectivePrice !==
    recurringFees?.newCondition?.surchargeOnRestriction?.effectivePrice;

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (recurringFees?.currentCondition?.surchargeOnRestriction) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(recurringFees?.currentCondition?.surchargeOnRestriction)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(recurringFees?.newCondition?.surchargeOnRestriction, isNewValueDifferent),
    discount: getMappedDiscount(recurringFees?.newCondition?.surchargeOnRestriction)
  }

};


const getMappedOldCondition = (surchargeOnRestriction) => {
  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      decimal={0}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={PERCENTAGE}
      value={surchargeOnRestriction?.effectivePrice}
    />);

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (surchargeOnRestriction, isNewValueDifferent) => {

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      decimal={0}
      key={`${KEY_PREFIX}-list-price`}
      type={PERCENTAGE}
      value={surchargeOnRestriction?.listPrice}
    />);

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      decimal={0}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={PERCENTAGE}
      value={surchargeOnRestriction?.effectivePrice}
    />);


  return {
    effectivePrice: effectivePriceReadonly,
    isEditable: false,
    isHighlighted: isNewValueDifferent,
    listPrice: listPrice
  };
};

const getMappedDiscount = (newCondition) => {
  const discount = parseFloat(getDiscountFromEffectivePrice(newCondition));

  return (
    !isNaN(discount) && (
      <PriceNumberFormat
        data-testid={`${TEST_KEY_PREFIX}-discount`}
        key={`${KEY_PREFIX}-discount`}
        type={PERCENTAGE}
        value={discount}
      />
    )
  );
};
