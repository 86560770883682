import {isEAMorEFA} from './serviceModel';
import {NMB_FEE_EAM_EFA_DECLARATION_TEXT, NMB_FEE_NON_EAM_DECLARATION_TEXT, NMB_FEE_NON_EAM_RECURRING_TRANSACTION_TAB_DECLARATION_TEXT,NMB_FEE_EAM_EFA_DECLARATION_OTC_FX_TAB_TEXT} from './infoTexts';

export const getNMBDeclaration = (serviceModel, agentType, isRecurringOrTransactionTab) =>{
  let declarationText;
  if(isEAMorEFA(serviceModel,agentType)){
    declarationText = isRecurringOrTransactionTab ? NMB_FEE_EAM_EFA_DECLARATION_TEXT : NMB_FEE_EAM_EFA_DECLARATION_OTC_FX_TAB_TEXT;
  }else {
    declarationText = isRecurringOrTransactionTab ? NMB_FEE_NON_EAM_RECURRING_TRANSACTION_TAB_DECLARATION_TEXT : NMB_FEE_NON_EAM_DECLARATION_TEXT;
  }
  return declarationText;
}
