import React from 'react';
import {PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {PERCENTAGE_DISCOUNT} from '../../../../../common/enums/recurringFeeCondition';
import {isNullOrUndefined} from '../../../../../common/isNullOrUndefined';
import {isNewRecurringFeeDifferent} from '../isNewRecurringFeeDifferent';

const LABEL = '% Discount on list price';
const KEY_PREFIX = 'recurring-fees-discount-on-list-price';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const discountOnListPrice = ({handleValidationResultChange, handleValueChange, readOnly, recurringFees, specialRequest, showDiff}) => {

  let result = {
    label: LABEL
  };
  let isNewValueDifferent = !!readOnly && isNewRecurringFeeDifferent(recurringFees, 'discountOnListPrice');

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (!isNullOrUndefined(recurringFees?.currentCondition?.discountOnListPrice)) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(recurringFees?.currentCondition?.discountOnListPrice, recurringFees?.currentCondition?.condition.code)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(recurringFees?.newCondition?.discountOnListPrice, recurringFees?.newCondition?.condition.code, handleValidationResultChange, handleValueChange, readOnly, specialRequest, isNewValueDifferent)
  }

};

const getMappedOldCondition = (discountOnListPrice, conditionCode) => {
  if (conditionCode !== PERCENTAGE_DISCOUNT.code) {
    return null;
  }

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={PERCENTAGE}
      value={discountOnListPrice}
    />);
  return {
    value: readonlyField
  };
};

const getMappedNewCondition = (discountOnListPrice, conditionCode, handleValidationResultChange, handleValueChange, readOnly, specialRequest, isNewValueDifferent) => {
  if (conditionCode !== PERCENTAGE_DISCOUNT.code) {
    return null;
  }

  const editableField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      disableMin={specialRequest}
      isEditable
      key={`${KEY_PREFIX}-editable`}
      onValidationResultChange={(vr) => handleValidationResultChange({...vr, selectors: ['discountOnListPrice']})}
      onValueChange={(vr) => handleValueChange({...vr, selectors: ['discountOnListPrice']})}
      type={PERCENTAGE}
      value={discountOnListPrice}
    />);

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={PERCENTAGE}
      value={discountOnListPrice}
    />);

  return {
    value: readOnly ? readonlyField : editableField,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
  };
};
