import {LOAD_PRICING_REQUEST_RESPONSE} from '../pricingRequestActions';
import {
  LOAD_NMB_APPLICABLE_FEES_RESPONSE,
  LOAD_NMB_CALCULATION_BASIS_APPLICABLE_FEES_RESPONSE,
  UPDATE_NMB_FEES,
  UPDATE_NMB_FEES_VALIDATION_RESULT
} from './nmbFeesActions';

export const nmbFeesReducer = (state, action) => {
  switch (action.type) {
    case LOAD_NMB_APPLICABLE_FEES_RESPONSE: {
      return {
        ...state,
        applicableFees: action.payload.data
      };
    }
    case LOAD_PRICING_REQUEST_RESPONSE: {
      if (!action.payload.data.nmbTransactionFees) {
        return null;
      }

      return {
        ...action.payload.data.nmbTransactionFees,
        applicableFees: {...state?.applicableFees},
        errors: []
      };
    }
    case UPDATE_NMB_FEES_VALIDATION_RESULT: {
      let newErrors = (state.errors || []).filter((e) => e !== action.selector);
      if (!action.isValid) {
        newErrors = [...state.errors, action.selector];
      }
      return {
        ...state,
        errors: newErrors
      };
    }
    case UPDATE_NMB_FEES: {
      return {
        ...state,
        ...action.data,
        errors: action.errors
      };
    }
    case LOAD_NMB_CALCULATION_BASIS_APPLICABLE_FEES_RESPONSE: {
      return {
        ...state,
        applicableFees: action.payload.data
      };
    }
    default:
      return state;
  }
};
