import {ERROR_BACKGROUND_PRIMARY_COLOR, ERROR_BACKGROUND_SECONDARY_COLOR} from '../common/color';

export default {
  root: {
    width: '100%'
  },
  underline: {
    '&.Mui-error': {
      backgroundColor: ERROR_BACKGROUND_SECONDARY_COLOR,
      '&:after': {
        borderBottomColor: ERROR_BACKGROUND_PRIMARY_COLOR
      }
    }
  },
};

