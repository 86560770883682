import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Summary from '../Summary/Summary';
import PaymentFees from '../PaymentFees';
import {BankingAndOtherServices} from '../index';
import TabPanel from '../../../Common/components/TabPanel/TabPanel';
import {useNotification} from '../../../Common/components/Notification';
import {SELECT_TAB_INDEX} from './selectedTabActions';
import {usePrevious} from '../../../../common/usePrevious';
import {getSelectedPriceRequestErrors} from '../../../../common/getSelectedPriceRequestErrors';
import {PriceRequestStore} from '../../PriceSimulationStore';
import {updatePricingRequest_upd, loadPricingRequest} from '../../pricingRequestService';
import RecurringFees from '../RecurringFees';
import TransactionFees from '../TransactionFees';
import {DPM} from '../../../../common/enums/serviceModel';
import Overdraft from '../Overdraft/Overdraft';
import FxPreciousMetals from '../FxPreciousMetals';
import {getPricingModelFromSummary} from '../../../../helpers/pricingModelHelper';
import {BJB_ADVISORY_MANDATE_FLAT_FEE_MODEL, BJB_DPM_ASIA, BJB_DPM_GLOBAL} from '../../../../common/enums/pricingModel';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import {setIsLoading} from '../../../../Services';
import {Store} from '../../../../Store';
import NMBFees from '../NMBFees';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabPanel: {
    height: '100%',
    backgroundColor: '#fff',
    padding: '0px',
    borderBottom: '0px solid #e3e5e5',
    borderRight: '0px solid #e3e5e5',
    borderLeft: '0px solid #e3e5e5'
  },
  tabLabel: {
    backgroundColor: '#f5f5f5',
    fontFamily: 'Verlag Office,sans-serif',
    textTransform: 'uppercase'
  },
  error: {
    backgroundColor: '#971b2ff5 !important'
  }
}));

const tabProps = index => {
  return {
    id: `simple-tab-${index}`,
    'arial-controls': `simple-tabpanel-${index}`
  };
};

const ScrollableTab = ({readOnly, specialRequest, customTieringEnabled, specialRequestPm}) => {
  const TEST_PREFIX = 'test-scrollable-tab';
  const classes = useStyles();
  const {parentState, parentDispatch} = useContext(Store);
  const {state, dispatch} = useContext(PriceRequestStore);
  const notification = useNotification();

  const previousPortfolioNumber = usePrevious(state.selectedPricingRequest.common?.portfolioNumber);

  const handleChange = (event, newValue) => {
    setIsLoading(true, parentDispatch);
    if (readOnly) {
      dispatch({type: SELECT_TAB_INDEX, payload: newValue});
      setIsLoading(false, parentDispatch);
    } else {
      if (getSelectedPriceRequestErrors(state).length > 0) {
        notification.error('Resolve validation errors before switching tabs.');

        setIsLoading(false, parentDispatch);
      } else {
        const pricingRequestIds = parentState.portfolios?.map(req=>req.pricingRequestId);
        const save = async () => {
          updatePricingRequest_upd(state.selectedPricingRequest, dispatch, pricingRequestIds, parentDispatch, parentState)
            .then(() => {
              dispatch({type: SELECT_TAB_INDEX, payload: newValue});
              loadPricingRequest(state.selectedPricingRequest.common.pricingRequestId, dispatch);
              setIsLoading(false, parentDispatch);
            })
            .catch(err => {
              notification.error('Save failed\n' + getErrorMessage(err));
              setIsLoading(false, parentDispatch);
            });
        };
        save();
      }
    }
  };

  useEffect(() => {
    if (previousPortfolioNumber !== state.selectedPricingRequest.common?.portfolioNumber) {
      dispatch({type: SELECT_TAB_INDEX, payload: 0});
    }
  }, [state.selectedPricingRequest.common, previousPortfolioNumber, dispatch]);

  const hasError = errors => {
    if (errors && errors.length > 0) {
      return classes.error;
    }
    return '';
  };

  const hideTransactionFees = [BJB_DPM_ASIA, BJB_DPM_GLOBAL, BJB_ADVISORY_MANDATE_FLAT_FEE_MODEL].some(
    x => x === getPricingModelFromSummary(state.selectedPricingRequest.summary)
  );

  const hideNMBFees = [BJB_DPM_ASIA, BJB_DPM_GLOBAL].some(
    x => x === getPricingModelFromSummary(state.selectedPricingRequest.summary)
  );
  return (
    <div>
      <AppBar color="default" elevation={0} position="static">
        <Tabs
          aria-label="tabs for portfolio simulation"
          className={classes.tabLabel}
          onChange={handleChange}
          scrollButtons="auto"
          value={state.selectedTabIndex}
          variant="scrollable"
        >
          <Tab
            className={`${hasError(state.selectedPricingRequest.summary?.errors)}`}
            label="SUMMARY"
            value={0}
            {...tabProps(0)}
          />
          {state.selectedPricingRequest.bankingFees && (
            <Tab
              className={`${hasError(state.selectedPricingRequest.bankingFees?.errors)}`}
              data-testid={`${TEST_PREFIX}-banking-fees`}
              label="BANKING SERVICE FEES"
              value={1}
              {...tabProps(1)}
            />
          )}
          {state.selectedPricingRequest.paymentFees && (
            <Tab
              className={`${hasError(state.selectedPricingRequest.paymentFees?.errors)}`}
              data-testid={`${TEST_PREFIX}-payment-fees`}
              label="PAYMENT FEES"
              value={2}
              {...tabProps(2)}
            />
          )}
          {state.selectedPricingRequest.recurringFees && (
            <Tab
              className={`${hasError(state.selectedPricingRequest.recurringFees?.errors)}`}
              data-testid={`${TEST_PREFIX}-recurring-fees`}
              label="RECURRING FEES"
              value={3}
              {...tabProps(3)}
            />
          )}
          {!hideTransactionFees && state.selectedPricingRequest.transactionFees && (
            <Tab
              className={`${hasError(state.selectedPricingRequest.transactionFees?.errors)}`}
              data-testid={`${TEST_PREFIX}-transaction-fees`}
              label="TRANSACTION FEES EXCHANGE-TRADED PRODUCTS"
              style={{maxWidth: '370px'}}
              value={4}
              {...tabProps(4)}
            />
          )}
          {!hideNMBFees && state.selectedPricingRequest.nmbTransactionFees && (
            <Tab
              className={`${hasError(state.selectedPricingRequest.nmbTransactionFees?.errors)}`}
              data-testid={`${TEST_PREFIX}-nmb-fees`}
              label="TRANSACTION FEES OTC PRODUCTS"
              value={5}
              {...tabProps(5)}
            />
          )}
          {state.selectedPricingRequest.nmbFXProducts && (
            <Tab
              className={`${hasError(state.selectedPricingRequest.nmbFXProducts?.errors)}`}
              data-testid={`${TEST_PREFIX}-fx-margin`}
              label={'Transaction Fees FX & Precious Metals Products'}
              style={{maxWidth: '500px'}}
              value={6}
              wrapped
              {...tabProps(6)}
            />
          )}
          {state.selectedPricingRequest.summary?.serviceModel?.code !== DPM && state.selectedPricingRequest.overdraft && (
            <Tab
              className={`${hasError(state.selectedPricingRequest.overdraft?.errors)}`}
              data-testid={`${TEST_PREFIX}-overdraft`}
              label="OVERDRAFT"
              value={7}
              {...tabProps(7)}
            />
          )}
        </Tabs>
      </AppBar>
      <TabPanel className={classes.tabPanel} index={0} value={state.selectedTabIndex}>
        {state.selectedPricingRequest && <Summary readOnly={readOnly} specialRequest={specialRequest}/>}
      </TabPanel>
      <TabPanel className={classes.tabPanel} index={1} value={state.selectedTabIndex}>
        {state.selectedPricingRequest && <BankingAndOtherServices readOnly={readOnly} specialRequest={specialRequest}/>}
      </TabPanel>
      <TabPanel className={classes.tabPanel} index={2} value={state.selectedTabIndex}>
        {state.selectedPricingRequest && <PaymentFees readOnly={readOnly} specialRequest={specialRequest}/>}
      </TabPanel>
      <TabPanel className={classes.tabPanel} index={3} value={state.selectedTabIndex}>
        {state.selectedPricingRequest &&
        <RecurringFees
          customTieringEnabled={customTieringEnabled}
          readOnly={readOnly}
          specialRequest={specialRequest}
          specialRequestPm={specialRequestPm}
        />
        }
      </TabPanel>
      <TabPanel className={classes.tabPanel} index={4} value={state.selectedTabIndex}>
        {state.selectedPricingRequest &&
        <TransactionFees
          customTieringEnabled={customTieringEnabled}
          readOnly={readOnly}
          specialRequest={specialRequest}
          specialRequestPm={specialRequestPm}
        />
        }
      </TabPanel>
      <TabPanel className={classes.tabPanel} index={5} value={state.selectedTabIndex}>
        {state.selectedPricingRequest &&
        <NMBFees readOnly={readOnly}/>}
      </TabPanel>
      <TabPanel className={classes.tabPanel} index={6} value={state.selectedTabIndex}>
        {state.selectedPricingRequest && <FxPreciousMetals readOnly={readOnly} specialRequest={specialRequest}/>}
      </TabPanel>
      <TabPanel className={classes.tabPanel} index={7} value={state.selectedTabIndex}>
        {state.selectedPricingRequest && <Overdraft readOnly={readOnly} specialRequest={specialRequest}/>}
      </TabPanel>
    </div>
  );
};

ScrollableTab.propTypes = {
  customTieringEnabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  specialRequest: PropTypes.bool,
  specialRequestPm: PropTypes.bool
};

export default ScrollableTab;
