import React, {useState} from 'react';
import AsyncButton from '../../../../Common/components/AsyncButton';
import {useNotification} from '../../../../Common/components/Notification';
import adminApi from '../../../../../api/adminApi';
import {ACTION_COLUMN} from '../columnsData';
import {getErrorMessage} from '../../../../../common/getErrorMessage';
import CommonDialog from '../../../../Common/components/Dialog/CommonDialog';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import LabelTextField from '../../../../Common/components/tableFilter/LabelTextField';

const CacheCustomCell = ({column, fetchData, filterByPricingRequestStatus, filterByT24Status, row, value}) => {
  const notification = useNotification();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isRemoveKeyDialogOpen, setIsRemoveKeyDialogOpen] = useState(false);
  const [currentKeyName, setCurrentKeyName] = useState('');

  const removeKeyDialog = (cacheName, key) => {
    return adminApi.cache
      .removeKey(cacheName, key)
      .then(() => {
        notification.success(
          `Successfully removed ${key} from cache ${cacheName}`
        );

        fetchData();
      })
      .catch((err) => {
        notification.error(`Could not remove key ${key} from ${cacheName}\n` + getErrorMessage(err));
      });
  };

  const clearCache = cacheName => {
    return adminApi.cache
      .clear(cacheName)
      .then(() => {
        notification.success(
          `Successfully Cleared cache ${cacheName}`
        );

        fetchData({
          pricingRequestStatus: filterByPricingRequestStatus,
          t24SyncStatus: filterByT24Status
        });
      })
      .catch((err) => {
        notification.error(`Could not clear cache ${cacheName}\n` + getErrorMessage(err));
      });
  };

  const handleCurrentKeyChange = e => {
    setCurrentKeyName(e.target.value);
  }

  if (column.id === ACTION_COLUMN.key) {
    const cacheName = row?.original?.name;

    return (
      <React.Fragment>

        <CommonDialog
          onClose={() => setIsCancelDialogOpen(false)}
          onContinue={() => clearCache(cacheName)}
          open={isCancelDialogOpen}
          text={`You are about clear the whole cache '${cacheName}'. This could have negative impact in performance.
                Do you want to continue?.`}
        />

        <Dialog onClose={() => setIsRemoveKeyDialogOpen(false)} open={isRemoveKeyDialogOpen}>
          <DialogTitle>Remove Entry from {cacheName}</DialogTitle>
          <DialogContent>
            <p>Please specify the element you would like to remove</p>
            <LabelTextField
              label="Key"
              onChange={handleCurrentKeyChange}
              value={currentKeyName}
            />
            &nbsp;
          </DialogContent>
          <DialogActions>
            <AsyncButton
              color="primary"
              onClick={async () => {
                return removeKeyDialog(cacheName, currentKeyName);
              }}
              text="Continue"
            />
            <Button onClick={() => setIsRemoveKeyDialogOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>


        <div>
          <AsyncButton
            onClick={async () => {
              setIsCancelDialogOpen(true);
            }}
            text="Clear"
          />
          &nbsp;
          <AsyncButton
            onClick={async () => {
              setIsRemoveKeyDialogOpen(true);
            }}
            text="Remove Entry"
          />
        </div>
      </React.Fragment>
    )
  } else {
    return value;
  }
};

export default CacheCustomCell;
