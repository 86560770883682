import {makeStyles} from '@material-ui/styles';
import {getDefaultFontSize} from '../../../../theme/common/font';
import {TABLE_BODY_HIGHLIGHTED_CELL_BACKGROUND_COLOR} from '../../../../theme/common/color';

export const useTransactionFeesStyles = makeStyles(() => ({
  sectionTitle: {
    fontFamily: '"Verlag SSm 7r"'
  },
  normalText: {
    fontFamily: '"Verlag SSm 3r"',
    textAlign: 'right',
    fontSize: '0.8125rem'
  },
  rowTitle: {
    fontFamily: '"Verlag SSm 3r"',
    textAlign: 'left',
    fontSize: '0.8125rem'
  },
  highlightedTableBodyCell: {
    ...getDefaultFontSize(),
    '&.MuiTableCell-body': {
      backgroundColor: TABLE_BODY_HIGHLIGHTED_CELL_BACKGROUND_COLOR
    }
  }
}));
