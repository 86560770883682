import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Grid, Paper} from '@material-ui/core';
import BackgroundImage from '../../assets/images/bg.jpg';
import {ScrollableTab} from './components';
import ActionButtons from './components/ActionButtons/ActionButtons';
import {useHistory, useParams} from 'react-router-dom';
import {Store} from '../../Store';
import {SELECT_PORTFOLIO, SELECT_PORTFOLIOS} from '../../common/actionTypes';
import {PriceRequestStore} from './PriceSimulationStore';
import {DRAFT, PENDING_PM} from '../../common/statusTypes';
import {loadSupportingDocuments} from './components/Summary/summaryService';
import {LOAD_PRICING_REQUEST_RESPONSE} from './components/pricingRequestActions';
import {useNotification} from '../Common/components/Notification';
import {loadPricingRequest, updateCustomerDetails} from './pricingRequestService';
import {PRICING_MANAGER} from '../../common/enums/userRole';
import SpecialRequestPmActionButtons from './components/SpecialRequestPmActionButtons';
import SpecialRequestRmActionButtons from './components/SpecialRequestRmActionButtons';
import {getGmisErrorMessage} from '../../common/getErrorMessage';
import {returnToHome, setIsLoading} from '../../Services';
import {getBookingCenterDescriptionFromCode} from '../../common/enums/bookingCenter';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  buttonGroup: {
    padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  pageHeader: {
    fontSize: '28px',
    lineHeight: '43px',
    color: '#141e55',
    fontFamily: 'Verlag Office,sans-serif',
    fontWeight: '300',
    textTransform: 'uppercase'
  },
  pageSubHeader: {
    fontSize: '18px',
    lineHeight: '43px',
    color: '#141e55',
    fontFamily: 'Verlag Office,sans-serif',
    fontWeight: '300'
  },
  paper: {
    paddingTop: '0px'
  },
  backgroundImg: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%'
  }
}));

const PriceSimulation = () => {
  const classes = useStyles();
  const {pricingRequestId} = useParams();
  const {parentState, parentDispatch} = useContext(Store);
  const {dispatch} = useContext(PriceRequestStore);
  const [readOnly, setReadOnly] = useState(true);
  const [specialRequestPm, setSpecialRequestPm] = useState(false);
  const [specialRequestRm, setSpecialRequestRm] = useState(false);
  const [customTieringEnabled, setCustomTieringEnabled] = useState(false);
  const history = useHistory();
  const notification = useNotification();

  useEffect(() => {
    if(parentState.isFromDashboard) {
      window.onbeforeunload = (event) => {
        event.preventDefault();
        event.returnValue = false;
        return true;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    // TO-DO: check if the person is authorized to see the pricing request
    if (parentState.user?.id) {
      setIsLoading(true, parentDispatch);
      loadPricingRequest(pricingRequestId, dispatch)
        .then(res => {
          const portfolioNumber = res.data.portfolioNumber;
          if (portfolioNumber) {
            if (parentState.isFromDashboard) {
              // User comes from landing page, make it editable if the status in DRAFT
              if (res.data?.pricingRequestStatus === DRAFT) {
                setReadOnly(false);
              }

              const portfolioDetails = parentState.portfolios.find(
                portfolio => portfolio.portfolioNumber === portfolioNumber
              )
              const {accountOpenDate, accessRoleType, accountOwnerUId} = portfolioDetails;
              dispatch({
                type: SELECT_PORTFOLIO,
                selected: {
                  ...res.data,
                  accountOpenDate,
                  accessRoleType,
                  accountOwnerUId,
                  eamCode: parentState.customer.agentCode
                }
              });
            } else {
              dispatch({type: SELECT_PORTFOLIO, selected: res.data});
              parentDispatch({type: SELECT_PORTFOLIOS, payload: [res.data]});
            }

            setViewForSpecialRequest({
              status: res.data?.pricingRequestStatus,
              userRoles: parentState.user?.userRoles,
              specialPricingRequest: res.data?.specialPricingRequest
            });

            if (res.data?.customTieringEnabled) {
              setCustomTieringEnabled(true);
            }

            const customerDetails = {
              agentCode: parentState.customer?.agentCode,
              accountNumber: portfolioNumber.slice(0, portfolioNumber.indexOf('-')),
              bookingCenter: parentState.customer?.bookingCenter || getBookingCenterDescriptionFromCode(res.data.tenantId)
            }
            updateCustomerDetails(customerDetails, parentDispatch).then(response => {
              if (response.data.gmisError?.length > 0) {
                const warningMessage = getGmisErrorMessage({response, message: response.data.gmisError.join('\n')})
                notification.warning(warningMessage);
                console.warn('GMIS Warning ', warningMessage);
              }
            });
            dispatch({type: LOAD_PRICING_REQUEST_RESPONSE, payload: res});
            loadSupportingDocuments(pricingRequestId, dispatch);
            setIsLoading(false, parentDispatch);
          } else {
            notification.error('Failed getting pricing request');
            returnToHome(history, parentDispatch);
            setIsLoading(false, parentDispatch);
          }
        })
        .catch(err => {
          notification.error('Failed getting pricing request\n' + getGmisErrorMessage(err));
          returnToHome(history, parentDispatch);
          setIsLoading(false, parentDispatch);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingRequestId, parentState.user?.id]);

  const setSpecialRequestPmView = () => {
    setReadOnly(false);
    setSpecialRequestPm(true);
  };

  const setSpecialRequestRmView = () => {
    setReadOnly(true);
    setSpecialRequestRm(true);
  };

  const setViewForSpecialRequest = ({status, userRoles, specialPricingRequest}) => {
    setSpecialRequestRm(false);
    setSpecialRequestPm(false);
    if (status === PENDING_PM && userRoles?.some((userRole) => userRole.role === PRICING_MANAGER.code)) {
      setSpecialRequestPmView();
    } else if (status === DRAFT && specialPricingRequest) {
      setSpecialRequestRmView();
    }
  };

  return (
    <div className={classes.root}>
      <Grid container padding={20} spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            <ScrollableTab
              customTieringEnabled={customTieringEnabled}
              readOnly={readOnly}
              specialRequest={specialRequestPm || specialRequestRm}
              specialRequestPm={specialRequestPm}
            />
            {specialRequestPm ? (
              <SpecialRequestPmActionButtons/>
            ) : specialRequestRm ? (
              <SpecialRequestRmActionButtons/>
            ) : !readOnly ? (
              <ActionButtons/>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const shouldRerender = (prev, next) => {
  return prev.match.params.id === next.match.params.id;
};

export default React.memo(PriceSimulation, shouldRerender);
