import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import NMBFeeSubFund from './NMBFeeSubFund';


const NMBFeeSubFunds = ({expandedPanels, fundTypeCode, handlePanelExpansionToggle, handleValueChange, handleValidationResultChange, readOnly, showAgreementIndicator, subFunds}) => {
  let subFundElements = [];
  let currentParent = null;
  for (const subFund of subFunds) {
    if (subFund.nmbParentAssetType) {
      if(currentParent === subFund.nmbParentAssetType?.code){
        continue;
      }
      currentParent = subFund.nmbParentAssetType?.code;
      const childSubTypes = subFunds.filter(s=>s.nmbParentAssetType?.code === subFund.nmbParentAssetType?.code);
      subFundElements.push(
        <NMBFeeSubFund
          childSubTypes={childSubTypes}
          fundTypeCode={fundTypeCode}
          handleValidationResultChange={({ selectors, transactionAssetSubLevel, ...others }) => handleValidationResultChange({
            ...others,
            selectors: [transactionAssetSubLevel?.code, ...selectors]
          })}
          handleValueChange={({ selectors, transactionAssetSubLevel, ...others }) => {
            handleValueChange({
              ...others,
              selectors: [transactionAssetSubLevel?.code, ...selectors]
            })
          }}
          isExpanded={expandedPanels.includes(subFund?.transactionAssetSubLevel?.code)}
          key={`nmb-fee-fund-${fundTypeCode}-sub-fund-${subFund?.transactionAssetSubLevel?.code}`}
          label={subFund.nmbParentAssetType?.desc}
          onExpansionToggle={() => handlePanelExpansionToggle(subFund?.transactionAssetSubLevel?.code)}
          readOnly={readOnly}
          showAgreementIndicator={showAgreementIndicator}
          subFund={subFund}
        />
      )
    } else {
      subFundElements.push(
        <NMBFeeSubFund
          fundTypeCode={fundTypeCode}
          handleValidationResultChange={({ selectors, ...others }) => handleValidationResultChange({
            ...others,
            selectors: [subFund?.transactionAssetSubLevel?.code, ...selectors]
          })}
          handleValueChange={({ selectors, ...others }) => {
            handleValueChange({
              ...others,
              selectors: [subFund?.transactionAssetSubLevel?.code, ...selectors]
            })
          }}
          isExpanded={expandedPanels.includes(subFund?.transactionAssetSubLevel?.code)}
          key={`nmb-fee-fund-${fundTypeCode}-sub-fund-${subFund?.transactionAssetSubLevel?.code}`}
          onExpansionToggle={() => handlePanelExpansionToggle(subFund?.transactionAssetSubLevel?.code)}
          readOnly={readOnly}
          showAgreementIndicator={showAgreementIndicator}
          subFund={subFund}
        />
      )
    }
  }

  return (
    <Box width={1}>
      {subFundElements}
    </Box>
  );


};

NMBFeeSubFunds.propTypes = {
  expandedPanels: PropTypes.array.isRequired,
  fundTypeCode: PropTypes.string.isRequired,
  handleAddTier: PropTypes.func,
  handlePanelExpansionToggle: PropTypes.func.isRequired,
  handleRangeEndChange: PropTypes.func,
  handleRangeStartChange: PropTypes.func,
  handleRemoveTier: PropTypes.func,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showAgreementIndicator: PropTypes.bool,
  subFunds: PropTypes.array.isRequired,
};

export default React.memo(NMBFeeSubFunds);
