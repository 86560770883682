import { jbColors } from '../jbColors';

const MuiTableRow = {
  root: {
    '&$selected, &$selected:hover': {
      backgroundColor: jbColors.greenSmoke[20],
    },
  },
};

export default MuiTableRow;
