import React from 'react';
import Box from '@material-ui/core/Box';
import {createSecondLevelLabel} from '../../priceSummaryCommonUtil';
import {hasDifference} from '../../../../../common/compareUtil';

const NA = '-';

export const acknowledgeSpecialStructureRow = ({label, oldAcknowledged,  newAcknowledged , showDiff, showOld, secondLevel, testId}) => {
  let result = {
    label: secondLevel? createSecondLevelLabel(label): label
  };

  let newCondition = getMappedAcknowledged(newAcknowledged, `new-${testId}`);

  let isNewValueDifferent = hasDifference(oldAcknowledged, newAcknowledged, showOld);

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  newCondition = {...newCondition, isHighlighted: isNewValueDifferent};

  return {
    ...result,
    currentCondition: getMappedAcknowledged(oldAcknowledged, `old-${testId}`),
    newCondition: newCondition,
    isHighlighted: isNewValueDifferent
  };
};

const getMappedAcknowledged = (acknowledged, testId) => {
  const acknowledgedChecked = (
    <Box data-testid={`${testId}-acknowledged`} textAlign="right">Yes</Box>
  );

  const acknowledgedNA = (
    <Box data-testid={`${testId}-acknowledged-NA-column`} textAlign="right">{NA}</Box>
  );

  return {
    acknowledged: acknowledged ? acknowledgedChecked: acknowledgedNA
  };
};
