import React from 'react';
import PropTypes from 'prop-types';
import {
  LABEL_SUMMARY,
  NEW_CONDITIONS_SUMMARY,
  OLD_CONDITIONS_SUMMARY,
  TEXT_LEFT
} from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import PriceConditionTable from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import {OLD_CONDITION_CUSTOM_STYLE, SUMMARY_TABLE_CUSTOM_STYLE} from '../../../../common/constants';
import {
  getTransactionFeeFundTypeDescriptionFromCode,
  getTransactionFeeFundTypeFromDescription,
  INVESTMENT_FUNDS
} from '../../../../common/enums/transactionFeeFundType';
import CommonBILARows from '../CommonBILARows';
import {BPS, CHF} from '../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {isNullOrUndefined} from '../../../../common/isNullOrUndefined';
import {TICKET_FEE} from '../../../../common/enums/transactionFeeCondition';
import {getUnequalFieldsInTransactionFees} from './transactionFeesEqualityUtil';
import {useTransactionFeesStyles} from './useTransactionFeesStyles';
import PriceConditionTableRowSection from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {createSectionTitle, createSubLevelTitle} from '../priceSummaryCommonUtil';
import TransactionFeeTextRow from './components/TransactionFeeTextRow';
import TransactionFeeRow from './components/TransactionFeeRow';
import TransactionFeeTieringRow from './components/TransactionFeeTieringRow';
import {isEAMorEFA} from '../../../../common/enums/serviceModel';

const TransactionFeesExchangeTradedTable = ({ transactionFees, serviceModel, agentType, showDiff }) => {
  const classes = useTransactionFeesStyles();
  const transactionAssetTypes = transactionFees?.transactionAssetTypes;

  const transactionAssetTypesComponents = [];
  // eslint-disable-next-line
  Object.entries(transactionAssetTypes).map(([key, value]) => {
    const subAssetTypesComponents = [];
    for (let props of value) {
      if(showDiff){
        // eslint-disable-next-line react/prop-types
        const unequalFields = getUnequalFieldsInTransactionFees(props.currentCondition, props.newCondition, transactionFees?.oldPricingExists);
        if(unequalFields.length === 0){
          continue;
        }
      }
      subAssetTypesComponents.push({
        // eslint-disable-next-line react/prop-types
        assetSubLevel: props.assetSubLevel.desc,
        // eslint-disable-next-line react/prop-types
        newCondition: props.newCondition,
        // eslint-disable-next-line react/prop-types
        currentCondition: props.currentCondition
      });
    }
    if (subAssetTypesComponents.length > 0) {
      transactionAssetTypesComponents.push({
        assetType: getTransactionFeeFundTypeDescriptionFromCode(key),
        assetSublevels: [...subAssetTypesComponents]
      });
    }
  });

  if (transactionAssetTypesComponents.length > 0) {
    transactionAssetTypesComponents.sort((firstEl, secondEl) => {
      const order1 = getTransactionFeeFundTypeFromDescription(firstEl?.assetType).sortOrder;
      const order2 = getTransactionFeeFundTypeFromDescription(secondEl?.assetType).sortOrder;
      if (order1 < order2) {
        return -1;
      } else if (order1 > order2) {
        return 1;
      }
      return 0;
    });
  }
  return (<>
    <PriceConditionTable>
      <PriceConditionTableRowSection data={[]}>
        <PriceConditionTableColumn field="label" label="Transaction Fees Exchange Traded Products" type={LABEL_SUMMARY}/>
        {transactionFees?.oldPricingExists && (
          <PriceConditionTableColumn
            customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
            label="Old Conditions"
            type={OLD_CONDITIONS_SUMMARY}
          />
        )}
        <PriceConditionTableColumn
          customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
          isHighlightedFieldValueAccessor="newCondition.isHighlighted"
          label="New Conditions"
          type={NEW_CONDITIONS_SUMMARY}
        />
      </PriceConditionTableRowSection>
      {transactionAssetTypesComponents.map(parent => (<>
        <PriceConditionTableRowSection data={[]}>
          <PriceConditionTableColumn
            customStyles={OLD_CONDITION_CUSTOM_STYLE}
            field="label"
            label={createSectionTitle(parent.assetType, classes.sectionTitle)}
            type={TEXT_LEFT}
          />
          {transactionFees?.oldPricingExists && (
            <PriceConditionTableColumn
              customStyles={OLD_CONDITION_CUSTOM_STYLE}
              type={TEXT_LEFT}
            />
          )}
          <PriceConditionTableColumn
            customStyles={OLD_CONDITION_CUSTOM_STYLE}
            type={TEXT_LEFT}
          />
        </PriceConditionTableRowSection>
        {parent.assetSublevels?.map(child => (<>
          <PriceConditionTableRowSection data={[]}>
            <PriceConditionTableColumn
              customStyles={OLD_CONDITION_CUSTOM_STYLE}
              data-testid={`test-${child.assetSubLevel}-label`}
              field="label"
              label={createSubLevelTitle(child.assetSubLevel)}
              type={TEXT_LEFT}
            />
            {transactionFees?.oldPricingExists && (
              <PriceConditionTableColumn
                customStyles={OLD_CONDITION_CUSTOM_STYLE}
                type={TEXT_LEFT}
              />
            )}
            <PriceConditionTableColumn
              customStyles={OLD_CONDITION_CUSTOM_STYLE}
              type={TEXT_LEFT}
            />
          </PriceConditionTableRowSection>
          <CommonBILARows
            hideBILA={isEAMorEFA(serviceModel, agentType)}
            newAcknowledge={child?.newCondition ? child.newCondition?.acknowledged : null}
            newAdditionalInstructions={child?.newCondition ? child.newCondition?.additionalInstructions : null}
            newAgreementType={child?.newCondition.agreementEnum ? child?.newCondition.agreementEnum : null}
            oldAcknowledge={child?.currentCondition ? child.currentCondition?.acknowledged : null}
            oldAdditionalInstructions={child?.currentCondition ? child?.currentCondition?.additionalInstructions : null}
            oldAgreementType={child?.currentCondition?.agreementEnum ? child?.currentCondition?.agreementEnum : null}
            secondLevel
            showDiff={showDiff}
            showOld={!!transactionFees.oldPricingExists}
            testId={'test-transaction-fees-summary'}
          />
          {(child?.newCondition?.transactionFeeCondition || child?.currentCondition?.transactionFeeCondition) && child &&
              <TransactionFeeTextRow
                label={'Fee Condition'}
                newValue={child?.newCondition?.transactionFeeCondition?.desc}
                oldValue={child?.currentCondition?.transactionFeeCondition?.desc}
                secondLevel
                showDiff={showDiff}
                showOld={transactionFees.oldPricingExists}
                testId={`test-${child.assetSubLevel}-condition`}
              />
          }
          {(child?.newCondition?.transactionFeeCondition?.code === TICKET_FEE.code || child?.currentCondition?.transactionFeeCondition?.code === TICKET_FEE.code)
            && child &&
            <TransactionFeeRow
              label={'Ticket Fee'}
              newValue={isNullOrUndefined(child?.newCondition) ? null: child?.newCondition?.ticketFee}
              oldValue={isNullOrUndefined(child?.currentCondition) ? null: child?.currentCondition?.ticketFee}
              secondLevel
              showDiff={showDiff}
              showOld={transactionFees.oldPricingExists}
              testId={`test-${child.assetSubLevel}-ticket-fee`}
              type={CHF}
            />
          }
          {(child?.newCondition?.transactionFeeCondition?.code !== TICKET_FEE.code || child?.currentCondition?.transactionFeeCondition?.code !== TICKET_FEE.code)
          && (child?.newCondition?.feePerTransaction || child?.currentCondition?.feePerTransaction) &&
              <TransactionFeeRow
                label={'Fee per transaction'}
                newValue={child?.newCondition?.feePerTransaction?.effectivePrice}
                oldValue={child?.currentCondition?.feePerTransaction?.effectivePrice}
                secondLevel
                showDiff={showDiff}
                showOld={transactionFees.oldPricingExists}
                testId={`test-${child.assetSubLevel}-feePerTransaction`}
                type={BPS}
              />}
          {(child?.newCondition?.transactionFeeCondition?.code !== TICKET_FEE.code || child?.currentCondition?.transactionFeeCondition?.code !== TICKET_FEE.code)
          && (child?.newCondition?.minFeePerTransaction || child?.currentCondition?.minFeePerTransaction) &&
            <TransactionFeeRow
              label={'Min fee per transaction'}
              newValue={child?.newCondition?.minFeePerTransaction?.effectivePrice}
              oldValue={child?.currentCondition?.minFeePerTransaction?.effectivePrice}
              secondLevel
              showDiff={showDiff}
              showOld={transactionFees.oldPricingExists}
              testId={`test-${child.assetSubLevel}-minFeePerTransaction`}
              type={CHF}
            />}
          {(child?.newCondition?.tierPricing || child?.currentCondition?.tierPricing) &&
          <TransactionFeeTieringRow
            label={'Custom Tier'}
            newTier={child?.newCondition?.tierPricing}
            oldTier={child?.currentCondition?.tierPricing}
            secondLevel
            showDiff={showDiff}
            showOld={transactionFees.oldPricingExists}
            testId={`test-${child.assetSubLevel}-tier`}
          />
          }
          {(child?.newCondition?.amendmentFee || child?.currentCondition?.amendmentFee) &&
              <TransactionFeeRow
                label={'Amendment Fee'}
                newValue={child?.newCondition?.amendmentFee?.effectivePrice}
                oldValue={child?.currentCondition?.amendmentFee?.effectivePrice}
                secondLevel
                showDiff={showDiff}
                showOld={transactionFees.oldPricingExists}
                testId={`test-${child.assetSubLevel}-amendmentFee`}
                type={CHF}
              />}
          {(child?.newCondition?.liquidationFee || child?.currentCondition?.liquidationFee) &&
              <TransactionFeeRow
                label={'Liquidation Fee'}
                newValue={child?.newCondition?.liquidationFee?.effectivePrice}
                oldValue={child?.currentCondition?.liquidationFee?.effectivePrice}
                secondLevel
                showDiff={showDiff}
                showOld={transactionFees.oldPricingExists}
                testId={`test-${child.assetSubLevel}-liquidationFee`}
                type={CHF}
              />}
          {(!isNullOrUndefined(child?.newCondition?.waiveChargesOnRedemption) || !isNullOrUndefined(child?.currentCondition?.waiveChargesOnRedemption) )
            && getTransactionFeeFundTypeFromDescription(parent.assetType) === INVESTMENT_FUNDS
            && child &&
              <TransactionFeeTextRow
                label={'Waiver on redemption'}
                newValue={isNullOrUndefined(child?.newCondition) ? null: child?.newCondition?.waiveChargesOnRedemption ? 'Yes' : '-'}
                oldValue={isNullOrUndefined(child?.currentCondition) ? null: child?.currentCondition?.waiveChargesOnRedemption ? 'Yes' : '-'}
                secondLevel
                showDiff={showDiff}
                showOld={transactionFees.oldPricingExists}
                testId={`test-${child.assetSubLevel}-waiveCharges`}
              />
          }
        </>
        ))}</>
      ))}
    </PriceConditionTable>
  </>);
};

TransactionFeesExchangeTradedTable.propTypes = {
  agentType: PropTypes.string,
  serviceModel: PropTypes.string,
  showDiff: PropTypes.bool,
  transactionFees: PropTypes.object
};

export default TransactionFeesExchangeTradedTable;
