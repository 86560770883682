import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from '@material-ui/styles';
import {HEADER_COLOR} from '../../../../../../../../../../../theme/common/color';
import {CLIPP_WHITE} from '../../../../../../../../../../../theme/common/bjbColor';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import {Store} from '../../../../../../../../../../../Store';
import AsyncButton from '../../../../../../../../../../Common/components/AsyncButton';

const DialogTitle = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: HEADER_COLOR,
    color: CLIPP_WHITE,
    display: 'flex',
    alignItems: 'center'
  }
}))(MuiDialogTitle);

const ReturnDialog = ({onClose, onSubmit, open, pricingRequestId}) => {
  const [comment, setComment] = useState();
  const [hasMandatoryError, setHasMandatoryError] = useState();
  const {parentState} = useContext(Store);

  const handleComment = event => setComment(event.target.value);
  const handleSubmit = () => {
    return new Promise((asyncBtnResolve, asyncBtnReject) => {
      if (comment) {
        const approvalDecisionData = {
          pricingRequestId: pricingRequestId,
          deciderId: parentState.user.id,
          decisionDate: new Date(),
          returnReason: comment
        };

        onSubmit(approvalDecisionData)
          .then(() => asyncBtnResolve())
          .catch(() => asyncBtnReject());
      } else {
        setHasMandatoryError(true);
        asyncBtnReject();
      }
    });
  };

  return (
    <Dialog disableBackdropClick fullWidth onClose={onClose} open={open}>
      <DialogTitle disableTypography>
        <ThumbDownIcon fontSize="inherit"/> <Box marginLeft={1}>RETURN</Box>
      </DialogTitle>
      <DialogContent>
        <Box marginBottom={1}>
          <Typography variant="h2">Approver's Comment</Typography>
        </Box>
        <FormControl error={hasMandatoryError}>
          {hasMandatoryError && <FormHelperText>*You should put a comment.</FormHelperText>}
          <TextField
            fullWidth
            helperText="(Limited to 1000 characters)"
            inputProps={{
              maxLength: 1000
            }}
            multiline
            name="contigencyTerm"
            onChange={handleComment}
            rows={4}
            variant="outlined"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <AsyncButton color="primary" onClick={handleSubmit} text="Submit"/>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReturnDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  pricingRequestId: PropTypes.number
};

export default ReturnDialog;
