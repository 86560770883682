import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {makeStyles} from '@material-ui/core/styles';
import {useNotification} from '../../../../../../../Common/components/Notification';
import {HONGKONG, SINGAPORE} from '../../../../../../../../common/enums/bookingCenter';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    marginRight: theme.spacing(1)
  }
}));

const ReportButton = ({bookingCenter, customerAccountNumber, disabled, reportUrls}) => {
  const notification = useNotification();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleReportRm = () => {
    const urlSG = reportUrls?.pricingSummaryRmSG;
    const urlHK = reportUrls?.pricingSummaryRmHK;
    let url = '';
    if (bookingCenter === SINGAPORE.desc) {
      url = urlSG;
    } else if (bookingCenter === HONGKONG.desc) {
      url = urlHK;
    }
    if (url) {
      window.open(url + customerAccountNumber, '_blank', 'width=1026, height=800');
    } else {
      notification.error('Report URL is not valid');
    }
  };

  const handleReportClient = () => {
    const urlHK = reportUrls?.pricingSummaryClientHK;
    const urlSG = reportUrls?.pricingSummaryClientSG;
    let url = '';
    if (bookingCenter === SINGAPORE.desc) {
      url = urlSG;
    } else if (bookingCenter === HONGKONG.desc) {
      url = urlHK;
    }
    if (url) {
      window.open(url + customerAccountNumber, '_blank', 'width=1026, height=800');
    } else {
      notification.error('Report URL is not valid');
    }
  };

  return (
    <div className={classes.root}>
      <Button
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        disabled={disabled}
        onClick={handleToggle}
        ref={anchorRef}
      >
        Pricing Summary
      </Button>
      <Popper anchorEl={anchorRef.current} disablePortal open={open} role={undefined} transition>
        {({TransitionProps}) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem
                    disabled={!reportUrls?.pricingSummaryRmSG && !reportUrls?.pricingSummaryRmHK}
                    onClick={handleReportRm}
                  >
                    Pricing Summary Report - with KPI
                  </MenuItem>
                  <MenuItem
                    disabled={!reportUrls?.pricingSummaryClientSG && !reportUrls?.pricingSummaryClientHK}
                    onClick={handleReportClient}
                  >
                    Pricing Summary Report - without KPI
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

ReportButton.propTypes = {
  bookingCenter: PropTypes.string,
  customerAccountNumber: PropTypes.string,
  disabled: PropTypes.bool,
  reportUrls: PropTypes.shape({
    pricingSummaryClientSG: PropTypes.string,
    pricingSummaryClientHK: PropTypes.string,
    pricingSummaryRmSG: PropTypes.string,
    pricingSummaryRmHK: PropTypes.string,
  })
};

export default ReportButton;
