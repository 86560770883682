import {X_TENANT_ID} from '../common/headers';
import {api, AUTHENTICATION_URL, BASE_URL} from './baseApi';

const PRICING_REQUEST_BASE_URL = `${BASE_URL}/pricing-request`;

const authentication = {
  getToken: (params) => api.get(AUTHENTICATION_URL, {params}),
}

const user = {
  getCurrent: () => api.get(`${BASE_URL}/user/me`),
  get: (userId) => api.get(`${BASE_URL}/user/` + userId)
};

const customer = {
  getDetails: (accountNumber,pricingRequestId) => api.post(`${BASE_URL}/gmis/${accountNumber}/${pricingRequestId}/getGroupingData`),
  get: () => api.get(`${BASE_URL}/portfolios`),
  getEam: () => api.get(`${BASE_URL}/eamPortfolios`),
  getOverview: (accountNumber, pricingRequestIds) => api.post(`${BASE_URL}/gmis/${accountNumber}/getDataForOverview`, pricingRequestIds),
  getPeerDetails: pricingRequestId => api.post(`${BASE_URL}/gmis/${pricingRequestId}/peerComparisonData`),
  getUpdatedDetails: (groupingDataRequest) => api.post(`${BASE_URL}/gmis/getUpdatedGroupingData`,groupingDataRequest),
};

const pricingRequest = {
  create: request => api.post(`${PRICING_REQUEST_BASE_URL}`, request),
  get: pricingRequestId => api.get(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}`),
  getWithTenantId: (pricingRequestId, tenantId) => api.get(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}`, {
    headers: {
      [X_TENANT_ID]: tenantId
    }
  }),
  list: (params) => api.get(`${PRICING_REQUEST_BASE_URL}/list`, {params}),
  listPendingPm: (params) => api.get(`${PRICING_REQUEST_BASE_URL}/listPendingPM`, {params}),
  listPendingAcm: (params) => api.get(`${PRICING_REQUEST_BASE_URL}/listPendingACM`, {params}),
  latest: (portfolios) => api.post(`${PRICING_REQUEST_BASE_URL}/latest`, portfolios),
  initiate: (portfolios) => api.post(`${PRICING_REQUEST_BASE_URL}/initiate`, portfolios),
  updatePricingRequest: (request) => api.put(`${PRICING_REQUEST_BASE_URL}/${request.pricingRequestId}`, request),
  updatePricingModel: (pricingRequestId, request) => api.put(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/pricingModel`, request),
  resetToStandard: (pricingRequestId) => api.put(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/reset-standard`),
  validateServiceModel: (portfolios) => api.post(`${PRICING_REQUEST_BASE_URL}/validate-service-model`, portfolios.map(p => ({...p, ...p.summary}))),
  copy: (pricingRequestId, portfolioNumberToCopy) => api.post(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/copy`, portfolioNumberToCopy, {
    headers: {'Content-Type': 'text/plain'}
  }),
  bankAndServiceFee: {
    simulateFee: (pricingRequestId, bankingFeeDto) => api.post(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/banking-service-fee/fee-simulation`, bankingFeeDto)
  },
  paymentFees: {
    getApplicableFees: (pricingRequestId) => api.get(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/payment-fee/applicable-fee`)
  },
  recurringFees: {
    simulateFee: (pricingRequestId, recurringFeeDto) => api.post(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/recurring-fee/fee-simulation`, recurringFeeDto),
    getApplicableFees: (pricingRequestId) => api.get(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/recurring-fee/applicable-fee`)
  },
  transactionFees: {
    simulateFee: (pricingRequestId, transactionFeeDto) => api.post(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/transaction-fee/fee-simulation`, transactionFeeDto),
    getApplicableFees: (pricingRequestId) => api.get(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/transaction-fee/applicable-fee`)
  },
  nmbFees:{
    getCalculationBasis: () => api.get(`${PRICING_REQUEST_BASE_URL}/nmb-fee/calculation-basis`),
    getApplicableFees:  (pricingRequestId) => api.get(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/nmb-transaction-fee/applicable-fee`)
  },
  revenue: {
    get: (pricingRequestId) => api.get(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/revenue`),
    getCurrent: (pricingRequestId) => api.get(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/revenue/current`),
    getFuture: (pricingRequestId) => api.get(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/revenue/future`),
    postFuture: (pricingRequestId, request) => api.post(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/revenue/future`, request),
    postRevenue: (pricingRequestId, request) => api.post(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/revenue`, request),
    postPdb: (pricingRequestId, request) => api.post(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/revenue/pdb`, request)
  },
  getReasons: () => api.get(`${BASE_URL}/approval-reason/list`),
  reject: (approvalDecisionData, tenantId, params) => api.post(`${PRICING_REQUEST_BASE_URL}/workflow/reject`, approvalDecisionData, {
    headers: {
      [X_TENANT_ID]: tenantId
    },
    params
  }),
  approve: (approvalDecisionData, tenantId) => api.post(`${PRICING_REQUEST_BASE_URL}/workflow/approve`, approvalDecisionData, {
    headers: {
      [X_TENANT_ID]: tenantId
    }
  }),
  validate: (pricingRequestId) => api.get(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/validation`),
  submit: (pricingRequestId, tilesData, tenantId, params) => api.post(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/workflow/submit`,tilesData, {
    headers: {
      [X_TENANT_ID]: tenantId
    },
    params
  }),
  submitDeviation: (pricingRequestId, request, tenantId) => api.post(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/editByACM`, request, {
    headers: {
      [X_TENANT_ID]: tenantId
    }
  }),
  submitPeriodicReviewDate: (pricingRequestId, tenantId) => api.post(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/periodic-review-date`, null, {
    headers: {
      [X_TENANT_ID]: tenantId
    }
  }),
  cancel: (pricingRequestIds) => api.post(`${PRICING_REQUEST_BASE_URL}/workflow/cancel`, pricingRequestIds),
  supportingDocuments: {
    get: (pricingRequestId) => api.post(`${BASE_URL}/doxter/${pricingRequestId}/list`),
    upload: (pricingRequestId, request, progressCallback) => api.post(`${BASE_URL}/doxter/${pricingRequestId}/save-document`, request, {onUploadProgress: progressCallback}),
    download: (request) => api.post(`${BASE_URL}/doxter/download`, request, {responseType: 'arraybuffer'}),
    delete: (request) => api.post(`${BASE_URL}/doxter/delete`, request),
  },
  resetInvestedAssets: (portfolioNumber) => api.post(`${BASE_URL}/gmis/${portfolioNumber}/resetInvestedAssets`),
  resetCash: (portfolioNumber) => api.post(`${BASE_URL}/gmis/${portfolioNumber}/resetCash`),
  resetTransactionVolume: (portfolioNumber,assetSubLevel) => api.post(`${BASE_URL}/gmis/${portfolioNumber}/resetTransactionVolume/${assetSubLevel}`),
  resetNumberTrades: (portfolioNumber,assetSubLevel) => api.post(`${BASE_URL}/gmis/${portfolioNumber}/resetNumberTrades/${assetSubLevel}`),
};

const specialPricingRequest = {
  submit: (pricingRequestId, request) => {
    return api.put(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/special-pricing-request`, request)
  },
  sendBackToRm: (pricingRequestId) => api.post(`${PRICING_REQUEST_BASE_URL}/${pricingRequestId}/submitToRM`),
}

const portfolio = {
  getLatestId: (portfolioNumber, tenantId) => api.get(`${PRICING_REQUEST_BASE_URL}/latestPricingRequestId`, {
    params: {portfolioNumber},
    headers: {
      [X_TENANT_ID]: tenantId
    }
  }),
}

const report = {
  getUrl: () => api.get(`${BASE_URL}/report`)
}

export default {
  authentication,
  user,
  customer,
  portfolio,
  pricingRequest,
  specialPricingRequest,
  report
}
