import {X_CORRELATION_ID, X_HANDLED_BY} from './headers';

export const getErrorMessage = (err) => {
  const correlationId = err?.response?.headers && err.response.headers[X_CORRELATION_ID.toLowerCase()];
  const handledBy = err?.response?.headers && err.response.headers[X_HANDLED_BY.toLowerCase()];
  const status = err?.response?.status;
  let errorMessage = status ? `Error ${status}: ` : '';
  errorMessage += err?.response?.data?.message || err?.message || '';
  errorMessage += correlationId ? `\nCorrelation ID: ${correlationId}` : '';
  errorMessage += handledBy ? `\nHandled by: ${handledBy}` : '';
  return errorMessage;
}

export const getGmisErrorMessage = (err) => {
  const correlationId = err?.response?.headers && err.response.headers[X_CORRELATION_ID.toLowerCase()];
  const handledBy = err?.response?.headers && err.response.headers[X_HANDLED_BY.toLowerCase()];
  let errorMessage = err?.response?.data?.message || err?.message || '';
  errorMessage += correlationId ? `\nCorrelation ID: ${correlationId}` : '';
  errorMessage += handledBy ? `\nHandled by: ${handledBy}` : '';
  return errorMessage;
}