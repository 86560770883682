import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import {makeStyles} from '@material-ui/core/styles';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import {Store} from '../../../../../../Store';
import {Button, List, ListItem, ListItemText, Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Authorized from '../../../../../../components/Authorized';
import {getAdminRoles, getCombinedRoleString, getPdbRoles, getCombinedRoles} from '../../../../../../common/enums/userRole';
import {AUTHORIZATION} from '../../../../../../common/headers';
import {deleteCookie, JSESSIONID_COOKIE} from '../../../../../../common/cookie';
import {BubbleCell} from '../../../../../../views/Home/components/HomeTab/components/StatusTable/components/CustomCell/components';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1)
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    fontSize: '12px',
    '& li': {
      textAlign: 'center'
    },
    '& .MuiAvatar-colorDefault': {
      backgroundColor: '#001489'
    }
  },
  container: {
    paddingLeft: '125px'
  }
}));

const UserInfo = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const {parentState} = useContext(Store);
  const [user, setUser] = useState();
  const history = useHistory();

  useEffect(() => {
    setUser(parentState.user);
  }, [parentState]);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const combinedRoles = getCombinedRoleString(user?.userRoles);

  const userRoles = getCombinedRoles(user?.userRoles);
  const allowedRoles = getPdbRoles();
  const hasAccess = userRoles?.some((userRole) =>
    allowedRoles.some((allowedRole) => allowedRole.code === userRole.code)
  );

  const clearImpersonation = () => {
    localStorage.removeItem(AUTHORIZATION);
    deleteCookie(JSESSIONID_COOKIE);
    window.location.href = '/';
  }

  return (
    <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      {user && (
        <Box textAlign="right">
          <Box>
            <Box alignItems="center" color="#fff" display="flex" justifyContent="flex-end">
              <Authorized allowedRoles={getAdminRoles()}>
                <SettingsIcon
                  data-testid="test-admin-access"
                  onClick={() => history.push('/admin/')}
                />
              </Authorized>
              <PermIdentityIcon/>
              <Typography
                style={{
                  color: '#fff',
                  textTransform: 'uppercase',
                  padding: 0
                }}
              >
                {user.firstName + ' ' + user.lastName}
              </Typography>
              {
                user.impersonator && (
                  <React.Fragment>
                    <Box component="span" marginX={1}>impersonated
                      by {user.impersonator.firstName} {user.impersonator.lastName}</Box>
                    <Button onClick={clearImpersonation} size="small">Clear</Button>
                  </React.Fragment>
                )
              }
            </Box>
            { hasAccess && parentState?.isFromDashboard && (<Box className={classes.container}>
              <BubbleCell markerData={parentState?.pdbMarker}/>
            </Box>) }
            <Typography
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{
                color: '#ccc',
                fontSize: '10px',
                paddingRight: '0px',
                textTransform: 'uppercase',
                display: 'block'
              }}
              variant="caption"
            >
              {combinedRoles}
            </Typography>
          </Box>
        </Box>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        id="mouse-over-popover"
        onClose={handlePopoverClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div>
          {user && user.teamHeads && (
            <List className={classes.root} style={{padding: '0px'}}>
              {user.teams &&
              user.teams.map((team, index) => (
                <ListItem divider key={'teamhead' + index} style={{padding: '0px'}}>
                  <PermIdentityIcon/>
                  <ListItemText
                    primary={team.data.lastName + ', ' + team.data.firstName}
                    secondary="Team Head"
                  />
                </ListItem>
              ))}
              {user.marketHead && (
                <ListItem divider style={{padding: '0px'}}>
                  <PermIdentityIcon/>
                  <ListItemText primary={user.marketHead.name} secondary="Group Head"/>
                </ListItem>
              )}
              {user.subRegionHead?.name && (
                <ListItem divider style={{padding: '0px'}}>
                  <PermIdentityIcon/>
                  <ListItemText primary={user.subRegionHead.name} secondary="Sub Region Head"/>
                </ListItem>
              )}
              <ListItem style={{padding: '0px'}}>
                <LocationOnOutlinedIcon/>
                <ListItemText primary={user.advisorLocation} secondary={'Advisor Location'}/>
              </ListItem>
            </List>
          )}
        </div>
      </Popover>
    </Box>
  );
};

export default UserInfo;
