export const getDiscountFromEffectivePrice = (newCondition) => {
  if (!newCondition) {
    return false;
  }

  const ZERO = 0;
  const validListPrice = newCondition.listPrice && !isNaN(newCondition.listPrice);
  const validEffectivePrice =
    (newCondition.effectivePrice && !isNaN(newCondition.effectivePrice)) ||
    newCondition.effectivePrice === ZERO;

  return (
    newCondition &&
    !!validListPrice &&
    !!validEffectivePrice &&
    parseFloat(
      parseFloat((newCondition.listPrice - newCondition.effectivePrice) * 100).toFixed(2) /
      newCondition.listPrice
    ).toFixed(2)
  );
};
