import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {PriceRequestStore} from '../../PriceSimulationStore';
import {usePrevious} from '../../../../common/usePrevious';
import {loadRecurringApplicableFees} from './recurringFeesService';
import Paper from '@material-ui/core/Paper';
import BoxHeader from '../../../Common/components/BoxHeader';
import api from '../../../../api/api';
import {
  UPDATE_RECURRING_FEES, UPDATE_RECURRING_FEES_AGREEMENT_TYPE,
  UPDATE_RECURRING_FEES_CONDITION,
  UPDATE_RECURRING_FEES_IMPLEMENTATION,
  UPDATE_RECURRING_FEES_VALIDATION_RESULT
} from './recurringFeesActions';
import {useNotification} from '../../../Common/components/Notification';
import Box from '@material-ui/core/Box';
import OverchargeAdjustedDialog from '../../../Common/components/Dialog/OverchargeAdjustedDialog';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import RecurringFeesConditionTable from './RecurringFeesConditionTable';
import {Typography, ThemeProvider, createTheme} from '@material-ui/core';
import {LOAD_FUTURE_REVENUE_RESPONSE, LOAD_PDB_RESPONSE} from '../RevenueSimulation/revenueActions';
import {getFeeTypeDescription} from '../../../../common/enums/transactionFeeFundType';
import {cloneDeep} from 'lodash';
import {
  getRecurringFeeConditionDescriptionFromCode,
  isNonStandardRecurringFeeCondition
} from '../../../../common/enums/recurringFeeCondition';
import CommonDialog from '../../../Common/components/Dialog/CommonDialog';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import { PENDING_PM } from '../../../../common/statusTypes';
import { isEAMorEFA } from '../../../../common/enums/serviceModel';
import AgreementTypeIndicator from '../Common/AgreementTypeIndicator/AgreementTypeIndicator';
import { BILA } from '../../../../common/enums/agreementType';
import {Store} from '../../../../Store';
import {Alert} from '@material-ui/lab';
import {SET_GROUPING_DATA} from '../../../../common/actionTypes';

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: 'Verlag SSm 3r'
        },
      },
    },
  },
});

const RecurringFees = ({readOnly, specialRequest, customTieringEnabled,specialRequestPm}) => {
  const [overchargeAdjustedDialogValue, setOverchargeAdjustedDialogValue] = useState(null);
  const [isConditionDialogOpen, setIsConditionDialogOpen] = useState(false);
  const [condition, setCondition] = useState(null);
  const {state, dispatch} = useContext(PriceRequestStore);
  const notification = useNotification();
  const {parentState, parentDispatch} = useContext(Store);

  const previousPortfolioNumber = usePrevious(state.selectedPricingRequest.common?.portfolioNumber);
  const previousNewCondition = usePrevious(state.selectedPricingRequest.recurringFees?.newCondition);
  const recurringFees = state.selectedPricingRequest.recurringFees;
  const selectableImplementationCodes = Object.keys(
    recurringFees?.applicableFees?.flatRate?.managementMinFeesPerQuarter || []
  );
  const newCondition = recurringFees?.newCondition;

  useEffect(() => {
    if (previousPortfolioNumber !== state.selectedPricingRequest.common.portfolioNumber) {
      loadRecurringApplicableFees(state.selectedPricingRequest.common.pricingRequestId, dispatch)
        .then(() => {
          const condition = state.selectedPricingRequest.recurringFees?.condition?.code;
          if (condition) {
            dispatch({type: UPDATE_RECURRING_FEES_CONDITION, conditionCode: condition});
          }
        })
        .catch((err) => {
          notification.error('Could not load recurring fees\n' + getErrorMessage(err));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.selectedPricingRequest.common.portfolioNumber,
    state.selectedPricingRequest.common.pricingRequestId,
    previousPortfolioNumber
  ]);

  useEffect(() => {
    if(!readOnly){
      triggerSpecialStructureValidation();
    }
    const loadFutureRevenue = async () => {
      const pricingRequestId = state.selectedPricingRequest.common.pricingRequestId;
      const portfolioNumber = state.selectedPricingRequest.common.portfolioNumber;
      let revenuePayload = await api.pricingRequest.revenue.postFuture(pricingRequestId, {
        ...state.selectedPricingRequest,
        portfolioNumber
      });
      dispatch({type: LOAD_FUTURE_REVENUE_RESPONSE, data: revenuePayload.data});
      const currentRevenue = state.selectedPricingRequest?.revenue?.currentRevenue;
      const futureRevenue = revenuePayload?.data;
      const pdbData = state.selectedPricingRequest?.revenue?.pdbData;
      const pricingRequestIds = parentState.portfolios?.map(req=>req.pricingRequestId);
      const currPricingRequestId = (parentState?.pricingRequestId) ?  parentState.pricingRequestId :  pricingRequestId;
      let pdbPayload = await api.pricingRequest.revenue.postPdb(pricingRequestId,
        {
          'currentReqId' : currPricingRequestId,
          'selectedReqIds' : pricingRequestIds,
          'currentRevenue' : currentRevenue,
          'futureRevenue' : futureRevenue,
          'pdbData' : pdbData
        }
      );
      dispatch({type: LOAD_PDB_RESPONSE, data: pdbPayload.data});

      const groupingData = parentState.groupingdata;
      parentDispatch({type: SET_GROUPING_DATA, payload: null});
      let updatedGroupingPayload = await api.customer.getUpdatedDetails(
        {
          'groupingData' : groupingData,
          'currentRevenue' : currentRevenue,
          'futureRevenue' : futureRevenue
        }
      );
      //dispatch({type: LOAD_UPDATED_GROUPING_DATA_RESPONSE, data: updatedGroupingPayload.data?.groupingData});
      console.log('updatedGroupingPayload.data from recurring fee tab -->', updatedGroupingPayload.data);
      parentDispatch({type: SET_GROUPING_DATA, payload: updatedGroupingPayload.data});
    };

    if (previousNewCondition) {
      loadFutureRevenue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCondition]);

  const NON_SIMULATED_FIELDS = ['acknowledged', 'additionalInstructions','agreementEnum']
  const handleValueChange = async ({selectors, value, isAdditionalInstructionsChange}) => {
    const fieldToUpdate = selectors[selectors.length-1];
    if (selectors[0] === 'newCondition') {
      recurringFees.newCondition[selectors[selectors.length - 1]] = value;
      if (selectors[1] === 'acknowledged' && value === false) {
        recurringFees.newCondition['additionalInstructions'] = '';
      }
    }else {
      // initialize field with empty object if and only if field node not exists
      let node = (recurringFees.newCondition = recurringFees.newCondition || {});
      for (let i = 0; i < selectors.length - 1; i++) {
        node = node[selectors[i]] = node[selectors[i]] || {};
      }
      node[selectors[selectors.length - 1]] = value;
    }
    const pricingRequestId = state.selectedPricingRequest.common.pricingRequestId;
    dispatch({type: UPDATE_RECURRING_FEES, data: recurringFees.newCondition});

    if(!isAdditionalInstructionsChange && !NON_SIMULATED_FIELDS.includes(fieldToUpdate)) {
      let payload = await api.pricingRequest.recurringFees.simulateFee(
        pricingRequestId,
        recurringFees.newCondition
      );
      dispatch({type: UPDATE_RECURRING_FEES, data: payload.data});
      setOverchargeAdjustedDialogValue(payload.data);
    }
  };

  const handleValidationResultChange = ({selectors, isValid}) => {
    dispatch({type: UPDATE_RECURRING_FEES_VALIDATION_RESULT, selector: selectors.join(), isValid: isValid});
  };

  const updateImplementation = (implementationCode) => {
    dispatch({type: UPDATE_RECURRING_FEES_IMPLEMENTATION, implementationCode: implementationCode});
  };

  const updateCondition = (conditionCode) => {
    // If condition is non_standard, remove listPrice and standardFeeId from portfolio assets and fee per annum
    if (isNonStandardRecurringFeeCondition(conditionCode)) {
      const newCondition = cloneDeep(recurringFees?.newCondition);
      const currentCondition = cloneDeep(recurringFees?.currentCondition);
      if(!specialRequestPm){
        recurringFees.pricingAgreedWithClient = cloneDeep(recurringFees?.oldPricingAgreedWithClient);
        newCondition.additionalInstructions = currentCondition?.additionalInstructions;
        newCondition.calculationBasis = currentCondition?.calculationBasis;
        newCondition.acknowledged = currentCondition?.acknowledged;
        newCondition.minimumFeePerQuarter = {standardFeeId: currentCondition?.minimumFeePerQuarter?.standardFeeId, effectivePrice: getMinFeeQuarterEffectivePrice(currentCondition?.minimumFeePerQuarter), listPrice: currentCondition?.minimumFeePerQuarter?.listPrice};
        let newTiers = [];
        for(const tierPrice of currentCondition?.portfolioAssets){
          newTiers.push({effectivePrice: tierPrice?.effectivePrice, rangeStart: tierPrice?.rangeStart, rangeEnd: tierPrice?.rangeEnd});
        }
        if(newTiers.length >0){
          newCondition.portfolioAssets = newTiers;
        }
      } else {
        newCondition.portfolioAssets = [{effectivePrice: 0, rangeStart: 0}];
      }

      newCondition.condition = {
        code: conditionCode,
        desc: getRecurringFeeConditionDescriptionFromCode(conditionCode)
      };

      newCondition.feePerAnnum = null;
      dispatch({type: UPDATE_RECURRING_FEES, data: newCondition});
    } else {
      dispatch({type: UPDATE_RECURRING_FEES_CONDITION, conditionCode: conditionCode});
    }
  };

  const handleAddTier = () => {
    const newCondition = cloneDeep(recurringFees.newCondition);
    newCondition.portfolioAssets.push({effectivePrice: 0});
    dispatch({type: UPDATE_RECURRING_FEES, data: newCondition});
  };

  const handleRemoveTier = (index) => {
    const newCondition = cloneDeep(recurringFees.newCondition);
    newCondition.portfolioAssets.splice(index, 1);
    newCondition.portfolioAssets[0].rangeStart = 0;
    dispatch({type: UPDATE_RECURRING_FEES, data: newCondition});
  };

  const handleRangeStartChange = (index, value) => {
    const newCondition = cloneDeep(recurringFees.newCondition);
    newCondition.portfolioAssets[index].rangeStart = value;
    dispatch({type: UPDATE_RECURRING_FEES, data: newCondition});
  };

  const handleRangeEndChange = (index, value) => {
    const newCondition = cloneDeep(recurringFees.newCondition);
    newCondition.portfolioAssets[index].rangeEnd = value;
    dispatch({type: UPDATE_RECURRING_FEES, data: newCondition});
  };

  const handleAgreementTypeIndicator = (checked) => {
    const pricingAgreedWithClient = state.selectedPricingRequest.recurringFees.pricingAgreedWithClient = checked ? BILA : null;
    if(!pricingAgreedWithClient || pricingAgreedWithClient.code !== BILA.code){
      const newCondition = cloneDeep(recurringFees.newCondition);
      newCondition.acknowledged = false;
      newCondition.additionalInstructions = '';
      dispatch({type: UPDATE_RECURRING_FEES, data: newCondition});
    }
    dispatch({type: UPDATE_RECURRING_FEES_AGREEMENT_TYPE, data: pricingAgreedWithClient});
    triggerSpecialStructureValidation();
  }

  const triggerSpecialStructureValidation = () => {
    const isEAM = isEAMorEFA(state.selectedPricingRequest.common?.serviceModel?.code, state.selectedPricingRequest.common?.agentType);
    const hasBila = (recurringFees?.pricingAgreedWithClient?.code === BILA.code);
    const isAcknowledged = recurringFees?.newCondition?.acknowledged;
    const isNonStandard = isNonStandardRecurringFeeCondition(recurringFees?.newCondition?.condition?.code);
    let selectors = ['newCondition', 'acknowledged'];
    const hasError = isEAM ? !isAcknowledged : !(hasBila && isAcknowledged);
    if(isNonStandard && hasError){
      if (!recurringFees.errors.includes(selectors.join())) {
        handleValidationResultChange({selectors: selectors, isValid: false});
      }
    }else{
      handleValidationResultChange({selectors: selectors, isValid: true});
    }
  }

  const getMinFeeQuarterEffectivePrice = (minFeeQuar) => {
    if(parseFloat(minFeeQuar?.effectivePrice) > parseFloat(minFeeQuar?.listPrice)){
      return minFeeQuar?.listPrice;
    }else{
      return minFeeQuar?.effectivePrice;
    }
  }

  const checkConditionDialog = (value) => {
    if (
      isNonStandardRecurringFeeCondition(
        recurringFees.newCondition.condition.code
      ) &&
      !isNonStandardRecurringFeeCondition(value)
    ) {
      setCondition(value);
      setIsConditionDialogOpen(true);
    } else {
      updateCondition(value);
    }
  };

  if (!recurringFees) {
    return <LoadingSpinner/>;
  }

  return (
    <Box>
      <CommonDialog
        onClose={() => setIsConditionDialogOpen(false)}
        onContinue={() => {
          updateCondition(condition);
          setIsConditionDialogOpen(false);
        }}
        open={isConditionDialogOpen}
        text="Changing the condition will remove all your custom changes. Do you want to proceed?"
      />
      <OverchargeAdjustedDialog
        onClose={() => setOverchargeAdjustedDialogValue(null)}
        value={overchargeAdjustedDialogValue}
      />
      <Paper elevation={3} style={{padding: '8px'}}>
        <BoxHeader text={'Recurring Fees'}/>
        <Box data-testid={'test-fee-type-title'}>
          <Typography gutterBottom variant="h3">
            {getFeeTypeDescription(state.selectedPricingRequest.summary.serviceModel.code)}
          </Typography>
        </Box>
        <AgreementTypeIndicator
          agreedWithClient={state.selectedPricingRequest.recurringFees?.pricingAgreedWithClient?.code}
          handleChange={handleAgreementTypeIndicator}
          readOnly={readOnly || state.selectedPricingRequest?.common?.pricingRequestStatus === PENDING_PM}
          show={!isEAMorEFA(state.selectedPricingRequest.common?.serviceModel?.code, state.selectedPricingRequest.common?.agentType)}
        />
        <RecurringFeesConditionTable
          agentType={state.selectedPricingRequest.common?.agentType}
          customTieringEnabled={customTieringEnabled}
          handleAddTier={handleAddTier}
          handleConditionChange={({value}) => checkConditionDialog(value)}
          handleImplementationChange={({value}) => updateImplementation(value)}
          handleRangeEndChange={handleRangeEndChange}
          handleRangeStartChange={handleRangeStartChange}
          handleRemoveTier={handleRemoveTier}
          handleValidationResultChange={handleValidationResultChange}
          handleValueChange={handleValueChange}
          pricingRequestStatus={state.selectedPricingRequest?.common?.pricingRequestStatus}
          readOnly={readOnly}
          recurringFees={recurringFees}
          selectableImplementationCodes={selectableImplementationCodes}
          serviceModelCode={state.selectedPricingRequest.summary.serviceModel.code}
          specialRequest={specialRequest}
          specialRequestPm={specialRequestPm}
        />
        <Box mb={12} style={{margin : 0 }}>
          <ThemeProvider theme={theme}>
            <Alert severity="info" style={{boxShadow: 'none', fontSize: 16}}>
              Recurring fees will be charged once the account is funded or securities are purchased after which minimum fees might apply irrespective of the asset value. Any changes in recurring fees will start to take effect for the entire <b>month</b> upon approval
            </Alert>
          </ThemeProvider>
        </Box>
      </Paper>
    </Box>
  );
};

RecurringFees.propTypes = {
  customTieringEnabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  specialRequest: PropTypes.bool,
  specialRequestPm: PropTypes.bool
};

export default RecurringFees;
