import React from 'react';
import PriceNumberFormat from '../../../../../Common/components/PriceNumberFormat';
import {BPS, CHF, REGULAR} from '../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import Box from '@material-ui/core/Box';

const LABEL = 'Transaction Values';

export const tierPricing = ({tiers, testIdSuffix}) => {

  let resultList = [];

  resultList.push({
    label: LABEL
  });

  for (const tier of (tiers || [])) {

    const label = (
      <Box data-testid={`test-transaction-fees-transaction-values-${testIdSuffix}`} style={{display: 'flex', alignItems: 'center', paddingLeft: '5px'}}>
        <PriceNumberFormat
          placeHolderForZero="0"
          type={REGULAR}
          value={tier.rangeStart}
        />
        <Box>
          <span>&nbsp;{tier.rangeEnd ? 'to' : 'CHF and'}&nbsp;</span>
        </Box>
        <PriceNumberFormat
          placeHolderForUndefined="above"
          type={CHF}
          value={tier.rangeEnd}
        />
      </Box>
    );

    const value = (
      <PriceNumberFormat
        data-testid={`test-transaction-fees-transaction-values-${testIdSuffix}-effective-price-readonly`}
        key={`test-transaction-fees-transaction-values-${testIdSuffix}-effective-price-readonly`}
        type={BPS}
        value={tier.effectivePrice}
      />
    );

    resultList.push({
      label,
      value
    });
  }


  return resultList;

};
