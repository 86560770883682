import React from 'react';
import PropTypes from 'prop-types';
import TransactionFeeFund from './TransactionFeeFund';
import {getTransactionFeeFundTypeFromCode} from '../../../../../common/enums/transactionFeeFundType';


const TransactionFeeFunds = ({customTieringEnabled, funds, handleAddTier, handleRemoveTier, handleRangeStartChange, handleRangeEndChange, handleValueChange,
  handleValidationResultChange, readOnly, specialRequest, specialRequestPm }) => {

  const getSortedFunds = (funds) => {
    return Object.entries(funds).sort(([iCode], [jCode]) => {
      return getTransactionFeeFundTypeFromCode(iCode).sortOrder - getTransactionFeeFundTypeFromCode(jCode).sortOrder
    });
  }

  let fundElements = [];

  for (const [fundTypeCode, subFunds] of getSortedFunds(funds)) {
    fundElements.push(
      <TransactionFeeFund
        customTieringEnabled={customTieringEnabled}
        fundTypeCode={fundTypeCode}
        handleAddTier={({selectors, ...others}) => handleAddTier({...others, selectors: [fundTypeCode, ...selectors]})}
        handleRangeEndChange={({selectors, ...others}) => handleRangeEndChange({
          ...others,
          selectors: [fundTypeCode, ...selectors]
        })}
        handleRangeStartChange={({selectors, ...others}) => handleRangeStartChange({
          ...others,
          selectors: [fundTypeCode, ...selectors]
        })}
        handleRemoveTier={({selectors, ...others}) => handleRemoveTier({
          ...others,
          selectors: [fundTypeCode, ...selectors]
        })}
        handleValidationResultChange={({selectors, ...others}) => handleValidationResultChange({
          ...others,
          selectors: [fundTypeCode, ...selectors]
        })}
        handleValueChange={({selectors, ...others}) => handleValueChange({
          ...others,
          selectors: [fundTypeCode, ...selectors]
        })}
        key={`transaction-fee-fund-${fundTypeCode}`}
        readOnly={readOnly}
        specialRequest={specialRequest}
        specialRequestPm={specialRequestPm}
        subFunds={subFunds}
      />
    )
  }

  return (
    <>
      {fundElements}
    </>
  );

};

TransactionFeeFunds.propTypes = {
  customTieringEnabled: PropTypes.bool,
  funds: PropTypes.object.isRequired,
  handleAddTier: PropTypes.func,
  handleRangeEndChange: PropTypes.func,
  handleRangeStartChange: PropTypes.func,
  handleRemoveTier: PropTypes.func,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  specialRequest: PropTypes.bool,
  specialRequestPm: PropTypes.bool
}

export default TransactionFeeFunds;
