import React from 'react';
import PropTypes from 'prop-types';
import {Box, Table, TableBody, TableCell as MuiTableCell, TableRow, Typography} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/styles';
import {
  TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_BACKGROUND_COLOR,
  TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_COLOR,
  TABLE_SUB_HEADER_CURRENT_CONDITION_BACKGROUND_COLOR,
  TABLE_SUB_HEADER_CURRENT_CONDITION_COLOR
} from '../../../../theme/common/color';
import PriceNumberFormat, {BPS, CHF} from '../../../Common/components/PriceNumberFormat/PriceNumberFormat';

const TableCell = withStyles(theme => ({
  root: {
    fontSize: '0.8125rem',
    padding: theme.spacing(1)
  }
}))(MuiTableCell);

const TableCellSubheader = withStyles(theme => ({
  root: {
    textAlign: 'center',
    fontWeight: 'normal',
    fontFamily: '\'Verlag SSm 7r\'',
    padding: theme.spacing(1),
    fontSize: '0.8125rem'
  }
}))(MuiTableCell);

const useStyles = makeStyles(() => ({
  noBorder: {
    borderBottom: 'none'
  },
  revenueHeader: {
    border: 'none',
    textDecoration: 'underline',
    textAlign: 'center',
    fontFamily: '\'Verlag SSm 7r\'',
    textTransform: 'uppercase'
  },
  currentRevenueSubHeader: {
    color: TABLE_SUB_HEADER_CURRENT_CONDITION_COLOR,
    backgroundColor: TABLE_SUB_HEADER_CURRENT_CONDITION_BACKGROUND_COLOR,
    borderBottom: '1px solid #fff'
  },
  futureRevenueSubHeader: {
    color: TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_COLOR,
    backgroundColor: TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_BACKGROUND_COLOR,
    borderBottom: '1px solid #fff'
  }
}));

const AccountGroupSimulationImpact = ({data}) => {
  const classes = useStyles();

  const accountGroupCurrentRevenue = data?.accountGroupCurrentRevenue;
  const accountGroupFutureRevenue = data?.accountGroupFutureRevenue;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography variant="h3">Simulation Impact</Typography>
      <Box border="solid 1px black" flexGrow="1" padding={2}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Invested assets</TableCell>
              <TableCell><PriceNumberFormat type={CHF} value={data?.investedAsset}/></TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Cash</TableCell>
              <TableCell><PriceNumberFormat type={CHF} value={data?.cash}/></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.noBorder}/>
              <TableCell
                className={classes.revenueHeader}
                colSpan={
                  accountGroupCurrentRevenue ? Object.keys(accountGroupCurrentRevenue).length : 1
                }
              >
                Current Revenue
              </TableCell>
              <TableCell
                className={classes.revenueHeader}
                colSpan={
                  accountGroupCurrentRevenue ? Object.keys(accountGroupFutureRevenue).length : 1
                }
              >
                Future Revenue
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.noBorder}/>
              <TableCellSubheader className={classes.currentRevenueSubHeader}>
                Effective Price
              </TableCellSubheader>
              <TableCellSubheader className={classes.currentRevenueSubHeader}>ROA</TableCellSubheader>
              <TableCellSubheader className={classes.futureRevenueSubHeader}>
                Effective Price
              </TableCellSubheader>
              <TableCellSubheader className={classes.futureRevenueSubHeader}>ROA</TableCellSubheader>
            </TableRow>
            <TableRow>
              <TableCell>PC I</TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={accountGroupCurrentRevenue?.effectivePrices}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={BPS} value={accountGroupCurrentRevenue?.roa}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={accountGroupFutureRevenue?.effectivePrices}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={BPS} value={accountGroupFutureRevenue?.roa}/>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

AccountGroupSimulationImpact.propTypes = {
  data: PropTypes.any
};

export default AccountGroupSimulationImpact;
