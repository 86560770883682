import React, {useEffect, useMemo, useState} from 'react';
import {
  TABLE_APPROVAL_STATUS_BORDER_COLOR,
  TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR
} from '../../../../theme/common/color';
import {CLIPP_WHITE} from '../../../../theme/common/bjbColor';
import {makeStyles} from '@material-ui/styles';
import {useNotification} from '../../../Common/components/Notification';
import adminApi from '../../../../api/adminApi';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import {columnsData} from './columnsData';
import WorkflowCustomCell from './components/WorkflowCustomCell';
import {useSortBy, useTable} from 'react-table';
import {Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {DESC} from '../../../../common/sortDirection';
import PaginationControl from '../../../Common/components/PaginationControl';
import AsyncButton from '../../../Common/components/AsyncButton';
import WorkflowPendingFilter from './components/WorkflowPendingFilter';

const useStyles = makeStyles(theme => ({
  inner: {
    maxWidth: '1500px',
    overflowX: 'auto'
  },
  table: {
    border: '1px solid',
    borderColor: TABLE_APPROVAL_STATUS_BORDER_COLOR,
    marginTop: theme.spacing(2)
  },
  tableCellHeader: {
    backgroundColor: TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR,
    color: CLIPP_WHITE,
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '20px',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  tableCellBody: {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '25px',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

const tableColumns = columnsData.map(colData => {
  return {accessor: colData.key, Header: colData.label, Cell: WorkflowCustomCell};
});

const WorkflowApproval = () => {
  const classes = useStyles();
  const notification = useNotification();
  const [pricingRequests, setPricingRequests] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [requiresRefresh, setRequiresRefresh] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState();
  const [pricingRequestId, setPricingRequestId] = useState();
  const [portfolioNumber, setPortfolioNumber] = useState();
  const memoizedData = useMemo(() => pricingRequests, [pricingRequests]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedColumns = useMemo(() => tableColumns, []);
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData
    },
    useSortBy
  );
  const CREATED_DATE_COLUMN = 'createdDate';
  const fetchData = async (params) => {
    setIsLoading(true);
    try {
      const res = await adminApi.pricingAdmin.getPendingWorkflow(
        {...params,
          pricingRequestId: pricingRequestId,
          portfolioNumber: portfolioNumber,
          sortBy: params?.sortBy || CREATED_DATE_COLUMN,
          sortDirection: params?.sortDirection || DESC});
      setPricingRequests(res.data?.elements);
      setPaginationInfo(res.data?.pagingMetaData);
      setIsLoading(false);
    } catch (err) {
      notification.error('Could not load data.\n' + getErrorMessage(err));
    }
  };

  const handlePricingRequestIdFilter = e => {
    setPricingRequestId(e.target.value);
  };

  const handlePortfolioNumberFilter = e => {
    setPortfolioNumber(e.target.value);
  };

  const handleFind = e => {
    if(pricingRequestId && isNaN(pricingRequestId)) {
      notification.error('Pricing Request Id should be a number');
      return;
    }
    setRequiresRefresh(e.target.value + Math.random());
  };

  const refresh = () => {
    return fetchData();
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiresRefresh]);

  const goToPage = page => {
    fetchData({page});
  };

  return (
    <Grid container>
      <Grid className={classes.title} item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h1">
            Pending Workflows
          </Typography>
          <AsyncButton onClick={refresh} text="Refresh"/>
        </Box>

        <WorkflowPendingFilter
          handleFind={handleFind}
          handlePortfolioFilter={handlePortfolioNumberFilter}
          handlePricingRequestIdFilter={handlePricingRequestIdFilter}
          selectedPortfolioFilter={portfolioNumber}
          selectedPricingRequestIdFilter={pricingRequestId}
        />

      </Grid>

      <Grid item xs={12}>
        {isLoading ? <LoadingSpinner/> : (
          <React.Fragment>
            <div className={classes.inner}>
              <Table size="small" {...getTableProps()} className={classes.table}>
                <TableHead>
                  {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <TableCell
                          {...column.getHeaderProps()}
                          className={classes.tableCellHeader}
                        >
                          {column.render('Header')}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <TableRow key={i}>
                          {row.cells.map(cell => (
                            <TableCell
                              {...cell.getCellProps()}
                              className={classes.tableCellBody}
                            >
                              {cell.render('Cell')}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={tableColumns.length}>
                  No entry found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            {paginationInfo && (
              <PaginationControl goToPage={goToPage} paginationInfo={paginationInfo}/>
            )}
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}

export default WorkflowApproval;
