import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

// eslint-disable-next-line react/no-multi-comp
const Bold = ({children}) => {
  return (
    <Box component="span" fontWeight="bold">{children}</Box>
  )
}

const TEST_PREFIX = 'test-exception-request';

// eslint-disable-next-line react/no-multi-comp
const SpecialRequestDialog = ({onClose, onContinue, open, specialRequestPortfolioNumbers}) => {
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown onClose={onClose} open={open}>
      <DialogTitle>ALERT</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Exception request for {specialRequestPortfolioNumbers?.join(', ')}.
        </DialogContentText>
        <DialogContentText>
          By clicking "<Bold>Confirm</Bold>", you acknowledge that this is an exceptional pricing request pertaining to
          Special Structures (see Asia Competence Matrix for Special Conditions) which are subject to enhanced
          governance and controls. Please ensure that there is a strong business justification documented to
          support this pricing request.
        </DialogContentText>
        <DialogContentText>
          Agreed fees and charges must be disclosed to the client and explicit client consent should be
          obtained for fees above list price. Same should be attached as evidence for this pricing request.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" data-testid={`${TEST_PREFIX}-confirm-button`} onClick={onContinue}>
          Confirm
        </Button>
        <Button color="primary" data-testid={`${TEST_PREFIX}-cancel-button`} onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Bold.propTypes = {
  children: PropTypes.any
}

SpecialRequestDialog.propTypes = {
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  open: PropTypes.bool,
  specialRequestPortfolioNumbers: PropTypes.array
};

export default SpecialRequestDialog;
