import React from 'react';
import {BPS} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {FLAT_RATE} from '../../../../../common/enums/recurringFeeCondition';
import {DPM, IA_DIRECT, IA_INDIRECT} from '../../../../../common/enums/serviceModel';
import {isNullOrUndefined} from '../../../../../common/isNullOrUndefined';
import {isNewRecurringFeeDifferent} from '../isNewRecurringFeeDifferent';

const LABEL = 'Flat rate in bps';
const KEY_PREFIX = 'recurring-fees-flat-rate-in-bps';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const flatRateInBps = ({handleValidationResultChange, handleValueChange, readOnly, recurringFees, serviceModelCode, showDiff}) => {

  if (![DPM, IA_DIRECT, IA_INDIRECT].includes(serviceModelCode)) {
    return null;
  }

  let result = {
    label: LABEL
  };
  let isNewValueDifferent = !!readOnly && isNewRecurringFeeDifferent(recurringFees, 'flatRateInBps');

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (!isNullOrUndefined(recurringFees?.currentCondition?.flatRateInBps)) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(recurringFees?.currentCondition?.flatRateInBps, recurringFees?.currentCondition?.condition.code)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(recurringFees?.newCondition?.flatRateInBps, recurringFees?.newCondition?.condition.code, handleValidationResultChange, handleValueChange, readOnly, isNewValueDifferent)
  }

};

const getMappedOldCondition = (flatRateInBps, conditionCode) => {
  if (conditionCode !== FLAT_RATE.code) {
    return null;
  }

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={BPS}
      value={flatRateInBps}
    />);
  return {
    value: readonlyField
  };
};

const getMappedNewCondition = (flatRateInBps, conditionCode, handleValidationResultChange, handleValueChange, readOnly, isNewValueDifferent) => {
  if (conditionCode !== FLAT_RATE.code) {
    return null;
  }

  const editableField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      isEditable
      key={`${KEY_PREFIX}-editable`}
      onValidationResultChange={(vr) => handleValidationResultChange({...vr, selectors: ['flatRateInBps']})}
      onValueChange={(vr) => handleValueChange({...vr, selectors: ['flatRateInBps']})}
      type={BPS}
      value={flatRateInBps}
    />);

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={BPS}
      value={flatRateInBps}
    />);

  return {
    value: readOnly ? readonlyField : editableField,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
  };
};
