import React from 'react';
import PropTypes from 'prop-types';
import {IconButton, InputAdornment, InputLabel, TextField} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';
import {BJB_GREEN_SMOKE_80, CLIPP_BLACK} from '../../../../../theme/common/bjbColor';
import {getDefaultFontSize} from '../../../../../theme/common/font';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  textInput: {
    width: '15ch'
  },
  label: {
    ...getDefaultFontSize(theme),
    marginRight: theme.spacing(1),
    color: CLIPP_BLACK
  }
}));

const LabelTextField = ({label, value, clearable, onChange, onClear, onKeyDown, ...other}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <InputLabel className={classes.label} id="filter-criteria-label">
        {label}
      </InputLabel>
      <TextField
        InputProps={
          clearable && value
            ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onClear} size="small">
                    <CloseIcon fontSize="small" style={{color: BJB_GREEN_SMOKE_80}}/>
                  </IconButton>
                </InputAdornment>
              )
            }
            : null
        }
        className={classes.textInput}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
        {...other}
      />
    </div>
  );
};

LabelTextField.propTypes = {
  clearable: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.string
};

export default LabelTextField;
