import React, {useEffect, useState} from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextareaAutosize,
  Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import {ERROR_BACKGROUND_SECONDARY_COLOR} from '../../../../../theme/common/color';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import validator from '../../../../../common/validator';
import {TEXT_AREA_MAX_LENGTH} from '../../../../../common/inputConstants';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
  },
  discountReasonError: {
    backgroundColor: ERROR_BACKGROUND_SECONDARY_COLOR,
  }
}));

const chunk = (arr, size) =>
  Array.from({length: Math.ceil(arr.length / size)}, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

const DiscountReason = ({discountReasons, max, name, onChange, onValidationResultChange, readOnly}) => {
  const OTHER_REASON_MIN_LENGTH = 20;
  const classes = useStyles();
  const [discounts, setDiscounts] = useState([]);
  const [splittedReasons, setSplittedReasons] = useState([]);
  const [otherReasonError, setOtherReasonError] = useState();
  const [reasonsError, setReasonsError] = useState();

  useEffect(() => {
    if (discountReasons) {
      setDiscounts(discountReasons);
    }
    return () => {
      setDiscounts([]);
    };
  }, [discountReasons]);

  useEffect(() => {
    if (discounts) {
      if(!readOnly){
        validateReasons(discounts);
        validateOtherReason(discounts);
      }
      setSplittedReasons(chunk(discounts, discounts.length / 2));
    }
    return () => {
      setSplittedReasons([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discounts, max, readOnly]);

  useEffect(() => {
    if (onValidationResultChange && !readOnly) {
      const isValid = !reasonsError && !otherReasonError;
      onValidationResultChange({isValid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasonsError, otherReasonError, readOnly])

  const clearTextAreaForOtherReasonsUnchecked = (item) => {
    if (!item.selected && item.otherReasonEnabled) {
      item.otherReason = '';
    }
  };

  const handleSelectOne = (selectedDiscount) => {
    let item = discounts.find(item => item.standardDiscountReasonId === selectedDiscount.standardDiscountReasonId);
    item.selected = !item.selected;
    clearTextAreaForOtherReasonsUnchecked(item);
    setDiscounts([...discounts]);
    onChange({target: {name: name, value: discounts}});
  };

  const handleOtherReason = (selectedDiscount, otherReason) => {
    let item = discounts.find(discount => discount.standardDiscountReasonId === selectedDiscount.standardDiscountReasonId);
    if (item.otherReasonEnabled) {
      item.otherReason = otherReason;
    }
    const validationResult = validator.hasMinLength(OTHER_REASON_MIN_LENGTH)(otherReason);
    setOtherReasonError(validationResult.errorMessage);
    setDiscounts([...discounts]);
  };

  const validateReasons = (discounts) => {
    let selectedDiscounts = discounts.filter(discount => discount.selected);
    setReasonsError(validator.hasMaxItems(max)(selectedDiscounts).errorMessage);
  };

  const validateOtherReason = (discounts) => {
    const errorMessages = discounts
      .filter(discount => discount.otherReasonEnabled && discount.selected)
      .map(discount => {
        const maxLengthValidator = validator.hasMinLength(OTHER_REASON_MIN_LENGTH)(discount.otherReason);
        const notEmptyValidator = validator.isNotEmpty(discount.otherReason);
        return {
          isValid: maxLengthValidator.isValid && notEmptyValidator.isValid,
          errorMessage: maxLengthValidator.errorMessage || notEmptyValidator.errorMessage
        }
      })
      .filter(validationResult => !validationResult.isValid)
      .map(validationResult => validationResult.errorMessage);

    setOtherReasonError(errorMessages[0]);
  };

  const handleOtherReasonBlur = () => {
    onChange({target: {name: name, value: discounts}});
  };


  return (
    <Grid
      className={`${reasonsError && classes.discountReasonError}`}
      container
      data-testid={'test-discount-reason-container'}
      spacing={1}
    >
      {
        discounts && discounts.length > 0 &&
        <Grid
          item
          xs={12}
        >
          <Typography
            display="block"
            variant="h6"
          >I requested a special discount on the selected portfolio due to the following reasons:</Typography>
          <Typography
            display="block"
            variant="h6"
          >(please select at least 1 and up to 3 reasons)</Typography>
          <FormControl error={!!reasonsError}>
            <Box display="flex">
              {splittedReasons.map((reasons, index) => (
                <FormControl
                  className={classes.formControl}
                  key={'reasons' + index}
                >
                  <FormGroup>
                    {reasons.map((discount) => {
                      return (
                        <div key={'div' + discount.standardDiscountReasonId}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={discount.selected}
                                color="primary"
                                disabled={readOnly}
                                inputProps={{'data-testid': 'test-' + discount.standardDiscountReasonId}}
                                key={discount.standardDiscountReasonId}
                                name={discount.standardDiscountReasonDesc}
                                onChange={() => handleSelectOne(discount)}
                                value={discount.standardDiscountReasonId}
                              />
                            }
                            label={discount.standardDiscountReasonDesc}
                          />
                          {
                            discount.otherReasonEnabled &&
                            <FormControl error={!!otherReasonError}>
                              <TextareaAutosize
                                cols={25}
                                data-testid={`test-other-${discount.standardDiscountReasonId}`}
                                disabled={!discount.selected || readOnly}
                                maxLength={TEXT_AREA_MAX_LENGTH}
                                onBlur={() => handleOtherReasonBlur()}
                                onChange={(e) => handleOtherReason(discount, e.target.value)}
                                rowsMax={5}
                                rowsMin={5}
                                value={discount.otherReason || ''}
                              />
                              {!!otherReasonError && <FormHelperText>{otherReasonError}</FormHelperText>}
                            </FormControl>
                          }
                        </div>);
                    })}
                  </FormGroup>
                </FormControl>
              ))}
            </Box>
            {!!reasonsError && <FormHelperText>{reasonsError}</FormHelperText>}
          </FormControl>
        </Grid>
      }
    </Grid>
  );
};

export default DiscountReason;

DiscountReason.propTypes = {
  discountReasons: PropTypes.array,
  max: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onValidationResultChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};
