export const CACHE_NAME_COLUMN = {
  label: 'Name',
  key: 'name'
};

export const ESTIMATED_ELEMENTS_COLUMN = {
  label: 'Elements',
  key: 'estimatedElements'
};

export const ESTIMATED_SIZE_COLUMN = {
  label: 'Size (Bytes)',
  key: 'estimatedBytes'
};

export const HIT_COUNT_COLUMN = {
  label: 'Hits',
  key: 'hitCount'
};

export const MISS_COUNT_COLUMN = {
  label: 'Misses',
  key: 'missCount'
};

export const REQUEST_COUNT_COLUMN = {
  label: 'Requests',
  key: 'requestCount'
};

export const LOAD_SUCCESS_COUNT_COLUMN = {
  label: '# Load Success',
  key: 'loadSuccessCount'
};

export const LOAD_FAILURE_COUNT_COLUMN = {
  label: '# Load Failure',
  key: 'loadFailureCount'
};

export const LOAD_COUNT_COLUMN = {
  label: 'Load Count',
  key: 'loadCount'
};

export const TOTAL_LOAD_TIME_COLUMN = {
  label: 'Total load time',
  key: 'totalLoadTime'
};

export const EVICTION_COUNT_COLUMN = {
  label: 'Eviction Count',
  key: 'evictionCount'
};

export const EVICTION_WEIGHT_COLUMN = {
  label: 'Eviction Weight',
  key: 'evictionWeight'
};

export const ACTION_COLUMN = {
  label: 'Action',
  key: 'actionColumn'
};

export const columnsData = [
  CACHE_NAME_COLUMN,
  ESTIMATED_ELEMENTS_COLUMN,
  ESTIMATED_SIZE_COLUMN,
  REQUEST_COUNT_COLUMN,
  HIT_COUNT_COLUMN,
  MISS_COUNT_COLUMN,
  // LOAD_COUNT_COLUMN,
  // LOAD_SUCCESS_COUNT_COLUMN,
  // LOAD_FAILURE_COUNT_COLUMN,
  // TOTAL_LOAD_TIME_COLUMN,
  EVICTION_COUNT_COLUMN,
  EVICTION_WEIGHT_COLUMN,
  ACTION_COLUMN
];
