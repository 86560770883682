import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {PriceRequestStore} from '../../PriceSimulationStore';
import OverchargeAdjustedDialog from '../../../Common/components/Dialog/OverchargeAdjustedDialog';
import BoxHeader from '../../../Common/components/BoxHeader';
import NMBFeeFunds from './panel/NMBFeeFunds';
import {isEAMorEFA} from '../../../../common/enums/serviceModel';
import {cloneDeep} from 'lodash';
import {UPDATE_NMB_FEES, UPDATE_NMB_FEES_VALIDATION_RESULT} from './nmbFeesActions';
import {BILA} from '../../../../common/enums/agreementType';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import {loadNMBCalculationApplicableFees} from './nmbFeesService';
import {useNotification} from '../../../Common/components/Notification';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '0.8125rem',
    '& .error': {
      backgroundColor: '#971b2f59'
    }
  }
}));

const NMBFees = ({readOnly})=> {
  const classes = useStyles();
  const {state, dispatch} = useContext(PriceRequestStore);
  const nmbTransactionFees = state.selectedPricingRequest.nmbTransactionFees;
  const [overchargeAdjustedDialogValue, setOverchargeAdjustedDialogValue] = useState(null);
  const notification = useNotification();
  const {serviceModel, agentType} = state.selectedPricingRequest.common;

  useEffect(() => {
    loadNMBCalculationApplicableFees(state.selectedPricingRequest.common.pricingRequestId,dispatch).catch(
      (err) => {
        notification.error('Could not load calculation basis fees\n' + getErrorMessage(err));
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValueChange = async ({selectors, value, isFeeChanged}) => {
    // initialize field with empty object if and only if field node not exists
    let tempTransactionFees = cloneDeep(nmbTransactionFees);
    let node = tempTransactionFees.transactionAssetTypes || {};
    let assetType = selectors[0];
    let subLevel = selectors[1];

    for (let i = 0; i < selectors.length - 1; i++) {
      if (i === 1) {
        node = node.filter((subFund) => subFund.transactionAssetSubLevel.code === selectors[i])[0] || {};
      } else {
        node = node[selectors[i]] = node[selectors[i]] || {};
      }
    }

    if(isFeeChanged){
      const {feePerTransaction, transactionFeeCondition} = tempTransactionFees.applicableFees[value.code];
      node.feePerTransaction.listPrice = feePerTransaction.listPrice;
      node.feePerTransaction.effectivePrice = feePerTransaction.effectivePrice;
      node.feePerTransaction.standardFeeId = feePerTransaction.standardFeeId;
      node.transactionFeeCondition = {...node.transactionFeeCondition, ...transactionFeeCondition};
      node[selectors[selectors.length - 1]] = value;
    }else{
      node[selectors[selectors.length - 1]] = value;
    }
    let errors = tempTransactionFees.errors || [];
    if(!isEAMorEFA(serviceModel?.code, agentType) && assetType && subLevel) {
      if (node) {
        if (!node.agreementEnum || node.agreementEnum.code !== BILA.code) {
          node.additionalInstructions = '';
          node.acknowledged = false;
          const updateSelector = [assetType, subLevel,'newCondition', 'additionalInstructions'];
          errors = (errors || []).filter((e) => e !== updateSelector.join());
        }
      }
    }

    if(node){
      if(!node.acknowledged){
        node.additionalInstructions = '';
      }
    }



    dispatch({type: UPDATE_NMB_FEES, data: tempTransactionFees, errors: errors});
  };

  const handleValidationResultChange = ({selectors, isValid}) => {
    dispatch({type: UPDATE_NMB_FEES_VALIDATION_RESULT, selector: selectors.join(), isValid: isValid});
  };
  return (<div className={classes.root}>
    <OverchargeAdjustedDialog
      onClose={() => setOverchargeAdjustedDialogValue(null)}
      value={overchargeAdjustedDialogValue}
    />
    <Paper className={classes.root} elevation={3} style={{padding: '10px'}}>
      <BoxHeader text={'Transaction Fees OTC Products'}/>
      {nmbTransactionFees?.transactionAssetTypes && <NMBFeeFunds funds={nmbTransactionFees.transactionAssetTypes} handleValidationResultChange={handleValidationResultChange} handleValueChange={handleValueChange} readOnly={readOnly} showAgreementIndicator={!isEAMorEFA(serviceModel?.code, agentType)}/>}
    </Paper>
  </div>)
}

NMBFees.propTypes = {
  readOnly: PropTypes.bool,
};

export default NMBFees;
