import React from 'react';
import PriceNumberFormat from '../../../../../Common/components/PriceNumberFormat';
import {CHF} from '../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {FIDUCIARY_INVESTMENTS} from '../../../../../../common/enums/transactionFeeFundType';

const LABEL = 'Initial Charge';
const KEY_PREFIX = 'transaction-fees-initial-charge';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const initialCharge = ({fundTypeCode, subFund}) => {

  if (fundTypeCode !== FIDUCIARY_INVESTMENTS.code) {
    return null;
  }

  let result = {
    label: LABEL
  };

  if (subFund.currentCondition?.initialCharge) {
    result = {
      ...result,
      currentCondition: getMappedOldCondition(subFund.currentCondition?.initialCharge)
    }
  }

  return result;

};

const getMappedOldCondition = (initialCharge) => {

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={initialCharge?.effectivePrice}
    />);

  return {
    effectivePrice: effectivePriceReadonly,
    isEditable: false
  };
};
