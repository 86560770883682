import { jbColors } from '../jbColors';

const MuiTab = {
  root: {
    padding: '6px 0',
    textTransform: 'uppercase',
    fontSize: 13,
    lineHeight: 1.62,
    '&$selected': {
      fontWeight: 'bold',
    },
    '& ~ &': {
      marginLeft: 30,
    },
    '@media  (min-width: 600px)': {
      minWidth: 'inherit',
    },
  },
  wrapper: {
    color: jbColors.royalBlue[100],
  },
  textColorInherit: {
    opacity: 1,
  },
};

export default MuiTab;
