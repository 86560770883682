export const FEE_COMMISSION_TYPE_ID_COLUMN = {
  label: 'Fee Commission Type ID',
  key: 't24FeeCommissionTypeId'
};

export const SHORT_DESCRIPTION_COLUMN = {
  label: 'Description',
  key: 'shortDescription'
};

export const USER_TYPE_COLUMN = {
  label: 'User Type',
  key: 'userType'
};

export const GENERAL_TYPE_COLUMN = {
  label: 'General Type',
  key: 'calcType'
};

export const T24_FEE_TYPE_COLUMN = {
  label: 'T24 Fee Type',
  key: 't24FeeType'
};

export const T24_SYNC_STATUS_COLUMN = {
  label: 'T24 Sync Status',
  key: 't24SyncStatus'
};

export const CREATED_INFO_COLUMN = {
  label: 'Creation Info',
  key: 'createdDate'
};

export const ACTION_COLUMN = {
  label: 'Action',
  key: 'actionColumn'
};

export const columnsData = [
  FEE_COMMISSION_TYPE_ID_COLUMN,
  USER_TYPE_COLUMN,
  SHORT_DESCRIPTION_COLUMN,
  GENERAL_TYPE_COLUMN,
  T24_FEE_TYPE_COLUMN,
  T24_SYNC_STATUS_COLUMN,
  CREATED_INFO_COLUMN,
  ACTION_COLUMN
];
