import React from 'react';
import PropTypes from 'prop-types';
import {Box, Table, TableBody, TableCell as MuiTableCell, TableRow, Typography} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/styles';
import {
  TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_BACKGROUND_COLOR,
  TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_COLOR,
  TABLE_SUB_HEADER_CURRENT_CONDITION_BACKGROUND_COLOR,
  TABLE_SUB_HEADER_CURRENT_CONDITION_COLOR
} from '../../../../theme/common/color';
import PriceNumberFormat from '../../../Common/components/PriceNumberFormat';
import {CHF, PERCENTAGE} from '../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {TRANSACTION_FEE_FOOT_NOTE_TEXT} from '../../../../common/enums/infoTexts';

const TableCell = withStyles(theme => ({
  root: {
    fontSize: '0.8125rem',
    padding: theme.spacing(1),
    textAlign: 'right'
  }
}))(MuiTableCell);

const TableCellLabel = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    fontSize: '0.8125rem'
    // textAlign: 'center'
  }
}))(MuiTableCell);

const TableCellSubheader = withStyles(theme => ({
  root: {
    textAlign: 'center',
    fontWeight: 'normal',
    fontFamily: '\'Verlag SSm 7r\'',
    padding: theme.spacing(1),
    fontSize: '0.8125rem'
  }
}))(MuiTableCell);

const useStyles = makeStyles(() => ({
  noBorder: {
    borderBottom: 'none'
  },
  revenueHeader: {
    border: 'none',
    textDecoration: 'underline',
    textAlign: 'center',
    fontFamily: '\'Verlag SSm 7r\'',
    textTransform: 'uppercase'
  },
  currentRevenueSubHeader: {
    color: TABLE_SUB_HEADER_CURRENT_CONDITION_COLOR,
    backgroundColor: TABLE_SUB_HEADER_CURRENT_CONDITION_BACKGROUND_COLOR,
    borderBottom: '1px solid #fff'
  },
  futureRevenueSubHeader: {
    color: TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_COLOR,
    backgroundColor: TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_BACKGROUND_COLOR,
    borderBottom: '1px solid #fff'
  },
  footNote: {
    fontSize: '0.65rem'
  }
}));

const AccountLevelSimulationImpact = ({data}) => {
  const classes = useStyles();

  const current = data?.accountLevelCurrentRevenue;
  const future = data?.accountLevelFutureRevenue;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography variant="h3">Simulation Impact</Typography>
      <Box border="solid 1px black" flexGrow="1" padding={2}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCellLabel>Invested assets</TableCellLabel>
              <TableCell>
                <PriceNumberFormat type={CHF} value={data?.investedAsset}/>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellLabel>Cash</TableCellLabel>
              <TableCell>
                <PriceNumberFormat type={CHF} value={data?.cash}/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.noBorder}/>
              <TableCell className={classes.revenueHeader} colSpan={3}>
                Current Revenue
              </TableCell>
              <TableCell className={classes.revenueHeader} colSpan={3}>
                Future Revenue
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.noBorder}/>
              <TableCellSubheader className={classes.currentRevenueSubHeader}>
                List Price
              </TableCellSubheader>
              <TableCellSubheader className={classes.currentRevenueSubHeader}>
                Effective Price
              </TableCellSubheader>
              <TableCellSubheader className={classes.currentRevenueSubHeader}>
                Discount
              </TableCellSubheader>
              <TableCellSubheader className={classes.futureRevenueSubHeader}>
                List Price
              </TableCellSubheader>
              <TableCellSubheader className={classes.futureRevenueSubHeader}>
                Effective Price
              </TableCellSubheader>
              <TableCellSubheader className={classes.futureRevenueSubHeader}>
                Discount
              </TableCellSubheader>
            </TableRow>
            {future?.transactionFees &&
            <TableRow>
              <TableCellLabel>Transaction fees*</TableCellLabel>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.transactionFees?.listPrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.transactionFees?.effectivePrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={current?.transactionFees?.discount}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.transactionFees?.listPrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.transactionFees?.effectivePrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={future?.transactionFees?.discount}/>
              </TableCell>
            </TableRow>
            }

            {future?.custodyFees &&
            <TableRow>
              <TableCellLabel>Custody fees</TableCellLabel>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.custodyFees?.listPrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.custodyFees?.effectivePrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={current?.custodyFees?.discount}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.custodyFees?.listPrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.custodyFees?.effectivePrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={future?.custodyFees?.discount}/>
              </TableCell>
            </TableRow>
            }
            {future?.advisoryFees &&
            <TableRow>
              <TableCellLabel>Advisory fees</TableCellLabel>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.advisoryFees?.listPrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.advisoryFees?.effectivePrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={current?.advisoryFees?.discount}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.advisoryFees?.listPrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.advisoryFees?.effectivePrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={future?.advisoryFees?.discount}/>
              </TableCell>
            </TableRow>
            }
            {future?.managementFees &&
            <TableRow>
              <TableCellLabel>Management fees</TableCellLabel>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.managementFees?.listPrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.managementFees?.effectivePrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={current?.managementFees?.discount}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.managementFees?.listPrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.managementFees?.effectivePrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={future?.managementFees?.discount}/>
              </TableCell>
            </TableRow>
            }
            {future?.bankingServiceFees &&
            <TableRow>
              <TableCellLabel>Banking Service fees</TableCellLabel>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.bankingServiceFees?.listPrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.bankingServiceFees?.effectivePrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={current?.bankingServiceFees?.discount}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.bankingServiceFees?.listPrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.bankingServiceFees?.effectivePrice}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={future?.bankingServiceFees?.discount}/>
              </TableCell>
            </TableRow>
            }
            <TableRow>
              <TableCellLabel>Total Annual fees</TableCellLabel>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.totalAnnualFeesList}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={current?.totalAnnualFeesEffective}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={current?.totalAnnualFeesDiscount}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.totalAnnualFeesList}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={CHF} value={future?.totalAnnualFeesEffective}/>
              </TableCell>
              <TableCell>
                <PriceNumberFormat type={PERCENTAGE} value={future?.totalAnnualFeesDiscount}/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.footNote} ${classes.noBorder}`} colSpan={7}>
                {TRANSACTION_FEE_FOOT_NOTE_TEXT}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

AccountLevelSimulationImpact.propTypes = {
  data: PropTypes.any
};

export default AccountLevelSimulationImpact;
