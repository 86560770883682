import React from 'react';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {BPS, PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {DPM, IA_DIRECT, IA_INDIRECT} from '../../../../../common/enums/serviceModel';
import {
  FLAT_RATE,
  NON_STANDARD_CUMULATIVE,
  NON_STANDARD_NON_CUMULATIVE,
  PERCENTAGE_DISCOUNT
} from '../../../../../common/enums/recurringFeeCondition';
import {getDiscountFromEffectivePrice} from '../../../../../common/getDiscountFromEffectivePrice';

const LABEL = 'Fee p.a.';
const KEY_PREFIX = 'recurring-fees-fee-per-annum';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const feePerAnnum = ({handleValidationResultChange, handleValueChange, readOnly, recurringFees, serviceModelCode, specialRequest, showDiff}) => {
  if ([DPM, IA_DIRECT, IA_INDIRECT].includes(serviceModelCode) || [NON_STANDARD_NON_CUMULATIVE.code, NON_STANDARD_CUMULATIVE.code].includes(recurringFees?.newCondition?.condition?.code)) {
    return null;
  }

  let result = {
    label: LABEL
  };
  let isNewValueDifferent = !!readOnly && recurringFees?.currentCondition && recurringFees?.currentCondition?.feePerAnnum?.effectivePrice !== recurringFees?.newCondition?.feePerAnnum?.effectivePrice;

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  // Take discount value from discountOnListPrice if the condition is % discount
  const isPercentageDiscountCondition = recurringFees?.newCondition?.condition?.code === PERCENTAGE_DISCOUNT.code
  const fixedDiscountValue = recurringFees?.newCondition?.discountOnListPrice;

  if (recurringFees?.currentCondition?.feePerAnnum) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(recurringFees?.currentCondition?.feePerAnnum)
    }
  }

  const fixedDiscountCell =
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-discount`}
      key={`${KEY_PREFIX}-discount`}
      type={PERCENTAGE}
      value={fixedDiscountValue}
    />

  return {
    ...result,
    newCondition: getMappedNewCondition(recurringFees?.newCondition?.feePerAnnum, recurringFees?.newCondition?.condition?.code, handleValidationResultChange, handleValueChange, readOnly, specialRequest, isNewValueDifferent),
    discount: isPercentageDiscountCondition ? fixedDiscountCell : getMappedDiscount(recurringFees?.newCondition?.feePerAnnum)
  }

};


const getMappedOldCondition = (feePerAnnum) => {
  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={BPS}
      value={feePerAnnum?.effectivePrice}
    />);

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (feePerAnnum, conditionCode, handleValidationResultChange, handleValueChange, readOnly, specialRequest, isNewValueDifferent) => {

  const isEditable = conditionCode === FLAT_RATE.code && !readOnly;

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      key={`${KEY_PREFIX}-list-price`}
      type={BPS}
      value={feePerAnnum?.listPrice}
    />);

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={BPS}
      value={feePerAnnum?.effectivePrice}
    />);

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-editable`}
      isEditable
      key={`${KEY_PREFIX}-effective-price-editable`}
      max={specialRequest ? null : feePerAnnum?.listPrice}
      onValidationResultChange={(vr) => handleValidationResultChange({
        ...vr,
        selectors: ['feePerAnnum', 'effectivePrice']
      })}
      onValueChange={(vr) => handleValueChange({...vr, selectors: ['feePerAnnum', 'effectivePrice']})}
      type={BPS}
      value={feePerAnnum?.effectivePrice}
    />);

  return {
    effectivePrice: isEditable ? effectivePriceEditable : effectivePriceReadonly,
    isEditable: isEditable,
    listPrice: listPrice,
    isHighlighted: isNewValueDifferent,
  };
};

const getMappedDiscount = (newCondition) => {
  const discount = parseFloat(getDiscountFromEffectivePrice(newCondition));

  return (
    !isNaN(discount) && (
      <PriceNumberFormat
        data-testid={`${TEST_KEY_PREFIX}-discount`}
        key={`${KEY_PREFIX}-discount`}
        type={PERCENTAGE}
        value={discount}
      />
    )
  );
};
