import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import PriceConditionTable from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import {nmbFeePerTransaction} from '../../NMBFees/panel/objectmapper/nmbFeesFeePerTransactionObjectMapper';
import {
  getConditionSelectValues,
  getNMBFeeConditionDescriptionFromCode
} from '../../../../../common/enums/nmbFeeCondition';
import {getOldConditionLabelAgreementType} from '../../../../../common/getOldConditionLabelAgreementType';
import {
  FX_PRECIOUS_METALS_ACCUMULATOR_DISCLAIMER,
  FX_PRECIOUS_METALS_DISCLAIMER
} from '../../../../../common/enums/infoTexts';
import {getDiscountTypeFromCode, getDiscountTypeSelectValues} from '../../../../../common/enums/discountType';
import {
  getApplicableStandardDescriptionById,
  getApplicableStandardsSelectValues
} from '../../../../../common/enums/applicableStandards';
import {
  getDiscountLevelDescriptionById,
  getDiscountLevelsSelectValues
} from '../../../../../common/enums/discountLevels';
import PriceConditionHeaderRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionHeaderRow';
import PriceConditionSelectRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionSelectRow';
import PriceConditionFeeRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionFeeRow';
import PriceConditionDisplayTextRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionDisplayTextRow';
import PriceConditionDisclaimerTextRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionDisclaimerTextRow';
import PriceConditionAcknowledgementRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionAcknowledgementRow';
import {
  FXP_ACCU_DECU_NEW, FXP_ACCU_DECU_TARF,
  FXP_TARF_NEW,
  getDisplayBasedOnSubType
} from '../../../../../common/enums/fxPreciousMetalsSubTypes';
import {getNMBDeclaration} from '../../../../../common/enums/declaration';

const FxPreciousMetalsSubAssetConditionTable = ({agentType, fxProductTypeCode, handleValidationResultChange, handleValueChange, newConditionCode, oldConditionCode, pricingRequestStatus, readOnly, serviceModel, fxSubType}) => {
  const properties = {
    fxProductTypeCode,
    handleValidationResultChange,
    handleValueChange,
    newConditionCode,
    oldConditionCode,
    readOnly,
    subFund: fxSubType
  }

  const feePerTransactionDeps = [fxProductTypeCode, newConditionCode, fxSubType.newCondition?.feePerTransaction?.listPrice, fxSubType.newCondition?.feePerTransaction?.effectivePrice];

  const oldConditionLabel = getOldConditionLabelAgreementType(fxSubType.currentCondition?.agreementEnum?.code);

  const nonEmptyEntries = obj => obj?.currentCondition || obj?.newCondition;

  const nmbFeePerTransactionEffectivePriceSection =  [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => nmbFeePerTransaction({...properties, testPrefixId:`test-${fxSubType.transactionAssetSubLevel.code}-fee-per-transaction`}), [feePerTransactionDeps]),
  ].filter(nonEmptyEntries);

  const columnProps = {
    borderRight: !fxSubType.currentCondition
  }

  const disclaimer = [];
  disclaimer.push(FX_PRECIOUS_METALS_DISCLAIMER);
  if(fxSubType.transactionAssetSubLevel.code === FXP_ACCU_DECU_NEW.code
      || fxSubType.transactionAssetSubLevel.code === FXP_TARF_NEW.code
      || fxSubType.transactionAssetSubLevel.code === FXP_ACCU_DECU_TARF.code){
    disclaimer.push(FX_PRECIOUS_METALS_ACCUMULATOR_DISCLAIMER);
  }

  const getTable = () => {
    return (
      <PriceConditionTable testId={`test-${fxSubType.transactionAssetSubLevel.code}-table`} width={fxSubType.currentCondition ? '100%' : '70%'}>
        <PriceConditionHeaderRow newConditionLabel={'NEW CONDITIONS'} oldConditionLabel={oldConditionLabel} showNewCondition={!!fxSubType.newCondition} showOldCondition={!!fxSubType.currentCondition}/>
        <PriceConditionSelectRow
          columnProps={columnProps}
          newConditionLabel={'Choose Discount Type'}
          newConditionValue={fxSubType.newCondition?.fxProductDiscountCondition?.code}
          oldConditionValue={getDiscountTypeFromCode(fxSubType.currentCondition?.fxProductDiscountCondition?.code)?.desc}
          onChange={handleValueChange}
          options={getDiscountTypeSelectValues()}
          readOnly={readOnly}
          readOnlyLabel={'Discount Type:'}
          selectProps={{name: 'discountType', id: 'uncontrolled-native'}}
          showNewCondition={!!fxSubType.newCondition?.fxProductDiscountCondition}
          showOldCondition={!!fxSubType.currentCondition?.fxProductDiscountCondition}
          testId={`test-discount-type-${fxSubType.transactionAssetSubLevel.code}-input`}
        />
        <PriceConditionSelectRow
          columnProps={columnProps}
          newConditionLabel={'Applicable Standard'}
          newConditionValue={fxSubType.newCondition?.applicableStandardId}
          oldConditionValue={getApplicableStandardDescriptionById(fxSubType.currentCondition?.fxMarginApplicableStandardDtos, fxSubType.currentCondition?.applicableStandardId)}
          onChange={(e)=>handleValueChange({value: e.value, selectors: ['newCondition', 'applicableStandardId']})}
          options={getApplicableStandardsSelectValues(fxSubType.newCondition?.fxMarginApplicableStandardDtos)}
          readOnly={readOnly}
          readOnlyLabel={'Applicable Standard:'}
          selectProps={{name: 'applicableStandard', id: 'uncontrolled-native'}}
          showNewCondition={!!fxSubType.newCondition?.fxMarginApplicableStandardDtos}
          showOldCondition={!!fxSubType.currentCondition?.fxMarginApplicableStandardDtos}
          testId={`test-applicable-standard-${fxSubType.transactionAssetSubLevel.code}-input`}
        />
        <PriceConditionSelectRow
          columnProps={columnProps}
          newConditionLabel={'Discount Level'}
          newConditionValue={fxSubType.newCondition?.discountLevelId}
          oldConditionValue={getDiscountLevelDescriptionById(fxSubType.currentCondition?.fxMarginDiscountLevelDtos, fxSubType.currentCondition?.discountLevelId)}
          onChange={(e)=>handleValueChange({value: e.value, selectors: ['newCondition', 'discountLevelId']})}
          options={getDiscountLevelsSelectValues(fxSubType.newCondition?.fxMarginDiscountLevelDtos)}
          readOnly={readOnly}
          readOnlyLabel={'Discount Level:'}
          selectProps={{name: 'discountLevel', id: 'uncontrolled-native'}}
          showNewCondition={!!fxSubType.newCondition?.fxMarginDiscountLevelDtos}
          showOldCondition={!!fxSubType.currentCondition?.fxMarginDiscountLevelDtos}
          testId={`test-discount-level-${fxSubType.transactionAssetSubLevel.code}-input`}
        />
        <PriceConditionSelectRow
          columnProps={columnProps}
          newConditionLabel={'Fee Condition'}
          newConditionValue={fxSubType.newCondition?.transactionFeeCondition?.code}
          oldConditionValue={getNMBFeeConditionDescriptionFromCode(fxSubType.currentCondition?.transactionFeeCondition?.code)}
          onChange={handleValueChange}
          options={getConditionSelectValues(fxSubType.newCondition?.transactionFeeCondition?.code)}
          readOnly={readOnly}
          readOnlyLabel={'Fee Condition:'}
          selectProps={{name: 'feeCondition', id: 'uncontrolled-native'}}
          showNewCondition={!!fxSubType.newCondition?.transactionFeeCondition}
          showOldCondition={!!fxSubType.currentCondition?.transactionFeeCondition}
          testId={`test-fee-condition-${fxSubType.transactionAssetSubLevel.code}-input`}
        />
        <PriceConditionFeeRow
          columnProps={columnProps}
          data={nmbFeePerTransactionEffectivePriceSection}
          displayRow={!!(fxSubType.currentCondition?.feePerTransaction || fxSubType.newCondition?.feePerTransaction)}
          showNewCondition={!!(fxSubType.newCondition && fxSubType.newCondition?.feePerTransaction)}
          showOldCondition={!!(fxSubType.currentCondition && fxSubType.currentCondition?.feePerTransaction)}
        />
        <PriceConditionDisplayTextRow
          columnProps={columnProps}
          newConditionLabel={getDisplayBasedOnSubType(fxSubType.transactionAssetSubLevel?.code)}
          oldConditionLabel={getDisplayBasedOnSubType(fxSubType.transactionAssetSubLevel?.code)}
          showNewCondition={!!(fxSubType.newCondition && fxSubType.newCondition?.feePerTransaction)}
          showOldCondition={!!(fxSubType.currentCondition && fxSubType.currentCondition?.feePerTransaction)}
          standardFeeConditionLabel={getDisplayBasedOnSubType(fxSubType.transactionAssetSubLevel?.code)}
          testIdSuffix={fxSubType.transactionAssetSubLevel.code}
        />
        <PriceConditionDisclaimerTextRow
          columnProps={columnProps}
          newConditionDisclaimer={disclaimer}
          oldConditionDisclaimer={disclaimer}
          showNewCondition={!!(fxSubType.newCondition && fxSubType.newCondition?.feePerTransaction)}
          showOldCondition={!!(fxSubType.currentCondition && fxSubType.currentCondition?.feePerTransaction)}
          testIdSuffix={fxSubType.transactionAssetSubLevel.code}
        />
        <PriceConditionAcknowledgementRow
          agentType={agentType}
          columnProps={columnProps}
          currentCondition={fxSubType.currentCondition}
          declarationTxt={getNMBDeclaration(serviceModel, agentType)}
          handleValidationResultChange={handleValidationResultChange}
          handleValueChange={handleValueChange}
          newCondition={fxSubType.newCondition}
          pricingRequestStatus={pricingRequestStatus}
          readOnly={readOnly}
          serviceModel={serviceModel}
          showNewCondition={!!fxSubType.newCondition}
          showOldCondition={!!fxSubType.currentCondition}
          testIdSuffix={fxSubType.transactionAssetSubLevel.code}
        />
      </PriceConditionTable>
    )
  }

  return getTable();

};

FxPreciousMetalsSubAssetConditionTable.propTypes = {
  agentType: PropTypes.string,
  fxProductTypeCode: PropTypes.string.isRequired,
  fxSubType: PropTypes.object.isRequired,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  newConditionCode: PropTypes.string,
  oldConditionCode: PropTypes.string,
  pricingRequestStatus: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  serviceModel: PropTypes.string.isRequired
};

export default React.memo(FxPreciousMetalsSubAssetConditionTable);
