import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import api from '../../../../../../../../../../../api/api';
import {getErrorMessage} from '../../../../../../../../../../../common/getErrorMessage';
import AsyncButton from '../../../../../../../../../../Common/components/AsyncButton';
import {useNotification} from '../../../../../../../../../../Common/components/Notification';
import {
  TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR,
  TABLE_BODY_READONLY_CELL_BACKGROUND_COLOR,
  TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_BACKGROUND_COLOR,
  TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_COLOR
} from '../../../../../../../../../../../theme/common/color';

const TEST_PREFIX = 'test-periodic-review-dialog';

const useStyles = makeStyles(() => ({
  content: {},
  cancel: {},
  header: {
    backgroundColor: TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_BACKGROUND_COLOR,
    color: TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_COLOR,
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    fontFamily: '\'Verlag SSm 7r\'',
    textAlign: 'center',
  },
  readonlyTableBodyCell: {
    fontSize: '0.8125rem',
    '&.MuiTableCell-body': {
      backgroundColor: TABLE_BODY_READONLY_CELL_BACKGROUND_COLOR
    }
  },
  editableTableBodyCell: {
    fontSize: '0.8125rem',
    '&.MuiTableCell-body': {
      backgroundColor: TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR
    }
  }
}));

const PeriodicReviewDialog = ({ open, onClose, onSubmit, portfolioNumber, pricingRequestId, tenantId}) => {
  const classes = useStyles();
  const notification = useNotification();


  const [ongoing, setOngoing] = useState();


  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = async () => {
    try {
      setOngoing(true);
      await api.pricingRequest.submitPeriodicReviewDate(pricingRequestId, tenantId);
      setOngoing(false);
      notification.success('Successfully updated pricing request periodic review date');
      onSubmit();
    } catch (err) {
      notification.error('Failed updating pricing request periodic review date \n' + getErrorMessage(err));
      setOngoing(false);
    }
  };

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={open}>
      <DialogTitle>
        <Typography>{portfolioNumber}</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div data-testid={`${TEST_PREFIX}-text`}>
          By Clicking on "Agree and Proceed", I acknowledge that I have reviewed the pricing conditions of
          this portfolio and they remain valid.
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancel}
          color="primary"
          data-testid={`${TEST_PREFIX}-cancel-button`}
          disabled={ongoing}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <AsyncButton
          data-testid={`${TEST_PREFIX}-submit-button`}
          onClick={handleSubmit}
          text="Agree and Proceed"
        />
      </DialogActions>
    </Dialog>
  );
};

PeriodicReviewDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  portfolioNumber: PropTypes.string,
  pricingRequestId: PropTypes.number,
  tenantId: PropTypes.string
};

export default PeriodicReviewDialog;
