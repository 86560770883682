import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {createSecondLevelLabel} from '../../../../PriceSimulationSummary/components/priceSummaryCommonUtil';
import {hasDifference} from '../../../../../common/compareUtil';

const agreementTypeObjectMapper = ({label, currentAgreementType,  newAgreementType, secondLevel, showDiff, showOld, testId}) => {
  let result = {
    label: secondLevel? createSecondLevelLabel(label): label
  };
  let isNewValueDifferent = hasDifference(currentAgreementType, newAgreementType, showOld);
  if (showOld) {

    result = {
      ...result,
      currentAgreementType: getMappedAgreementType(currentAgreementType, `old-${testId}-agreement-type`)
    }
  }

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  return {
    ...result,
    newAgreementType: getMappedAgreementType(newAgreementType, `new-${testId}-agreement-type`),
    isHighlighted: isNewValueDifferent
  }
}

const getMappedAgreementType = (agreementType, testId) => {

  const readonlyField = (
    <Box data-testid={`${testId}`} textAlign="right">{agreementType?.desc}</Box>
  );

  const emptyField = (
    <Box data-testid={`${testId}-NA-column`} textAlign="right">-</Box>
  );

  return {
    value: agreementType ? readonlyField: emptyField
  };
};

agreementTypeObjectMapper.propTypes = {
  currentAgreementType: PropTypes.object,
  label: PropTypes.string,
  newAgreementType: PropTypes.object,
  showDiff: PropTypes.bool,
  showOld: PropTypes.bool,
  testId: PropTypes.string
};

export default agreementTypeObjectMapper;
