import React from 'react';
import {ACTION_COLUMN, APPROVAL_INFO_COLUMN} from '../columnsData';
import ApprovalLogCell from './ApprovalLogCell';
import AsyncButton from '../../../../Common/components/AsyncButton';
import adminApi from '../../../../../api/adminApi';
import {getErrorMessage} from '../../../../../common/getErrorMessage';
import {useNotification} from '../../../../Common/components/Notification';

const WorkflowCustomCell = ({column, row, value}) => {
  const notification = useNotification();
  if (column.id === APPROVAL_INFO_COLUMN.key) {
    return <ApprovalLogCell pricingRequestId={row.original.pricingRequestId} title={`PR-${row.original.pricingRequestId}-${row.original.portfolioNumber}`}/>;
  }

  const refreshApprovers = async (pricingRequestId) => {
    if (window.confirm('Are you sure you want to refresh approvers for pricing request id ' + pricingRequestId + '? No email notifications will be resent.')) {
      try {
        await adminApi.pricingAdmin.refresh(pricingRequestId);
        notification.success('Approvers were refreshed successfully');
      } catch (err) {
        notification.error('Could not restart workflow.\n' + getErrorMessage(err));
      }
    }
  }

  const restartWorkflow = async (pricingRequestId) => {
    if (window.confirm('Are you sure you want to restart the workflow for pricing request id '
      + pricingRequestId + '? ' +
      'Have you informed the approvers that emails will be resent?')) {
      try {
        await adminApi.pricingAdmin.restart(pricingRequestId);
        notification.success('Workflow was restarted successfully');
      } catch (err) {
        notification.error('Could not restart workflow.\n' + getErrorMessage(err));
      }
    }
  }

  if (column.id === ACTION_COLUMN.key) {
    return <div>
      <AsyncButton
        onClick={() => refreshApprovers(row?.original?.pricingRequestId)}
        text="Refresh Approvers"
      />
      &nbsp;
      <AsyncButton
        onClick={() => restartWorkflow(row?.original?.pricingRequestId)}
        text="Restart Workflow"
      />
    </div>;
  }
  return value ? value : null;
};

export default WorkflowCustomCell;
