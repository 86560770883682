import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TransactionFeeSubFundTiering from './TransactionFeeSubFundTiering';
import TransactionFeeSubFundVolume from './TransactionFeeSubFundVolume';
import TransactionFeeSubAssetConditionTable from './TransactionFeeSubAssetConditionTable';
import {getDefaultFontSize} from '../../../../../theme/common/font';
import {
  EXPANSION_PANEL_SECONDARY_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_COLOR,
  EXPANSION_PANEL_SECONDARY_FOCUS_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_FOCUS_COLOR,
  EXPANSION_PANEL_SECONDARY_HOVER_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_HOVER_COLOR
} from '../../../../../theme/common/color';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {BILA} from '../../../../../common/enums/agreementType';
import {PENDING_PM} from '../../../../../common/statusTypes';
import AgreementTypeIndicator from '../../Common/AgreementTypeIndicator/AgreementTypeIndicator';

const useStyles = makeStyles((theme) => ({
  expansionPanel: {
    width: '100%',
    marginTop: '18px'
  },
  expansionPanelSummary: {
    backgroundColor: EXPANSION_PANEL_SECONDARY_BACKGROUND_COLOR,
    color: EXPANSION_PANEL_SECONDARY_COLOR,
    fontWeight: 'normal',
    fontFamily: '\'Verlag SSm 7r\'',
    height: '36px',
    minHeight: '36px!important',
    ...getDefaultFontSize(theme),
    '&:focus': {
      backgroundColor: EXPANSION_PANEL_SECONDARY_FOCUS_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_SECONDARY_FOCUS_COLOR
    },
    '&:hover': {
      backgroundColor: EXPANSION_PANEL_SECONDARY_HOVER_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_SECONDARY_HOVER_COLOR
    }
  }
}));
const TransactionFeePanel = ({customTieringEnabled, fundTypeCode, handleAddTier, handleRemoveTier, handleRangeStartChange, handleRangeEndChange,
  handleValidationResultChange, handleValueChange, readOnly, specialRequest, subFund,
  transactionFees, pricingRequestStatus, showAgreementIndicator, serviceModel, agentType, specialRequestPm }) => {

  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);

  return (
    <Box>
      <TransactionFeeSubFundVolume
        assetSubLevel={subFund?.assetSubLevel}
        handleValueChange={handleValueChange}
        readOnly={readOnly}
        testIdSuffix={subFund.assetSubLevel?.code}
        transactionFeeVolume={subFund.transactionFeeVolume}
      />
      <AgreementTypeIndicator
        agreedWithClient={subFund?.newCondition?.agreementEnum?.code}
        handleChange={(val) => {
          handleValueChange({value: val ? {...BILA} : null, selectors: ['newCondition', 'agreementEnum']})
        }}
        other={{inputProps:{'data-testid': `test-agreement-type-indicator-${subFund.assetSubLevel.code}-checkbox`}}}
        readOnly={readOnly || pricingRequestStatus === PENDING_PM}
        show={showAgreementIndicator}
        style={{marginBottom:'5px'}}
      />
      <TransactionFeeSubAssetConditionTable
        agentType={agentType}
        customTieringEnabled={customTieringEnabled}
        fundTypeCode={fundTypeCode}
        handleAddTier={handleAddTier}
        handleRangeEndChange={handleRangeEndChange}
        handleRangeStartChange={handleRangeStartChange}
        handleRemoveTier={handleRemoveTier}
        handleValidationResultChange={handleValidationResultChange}
        handleValueChange={handleValueChange}
        pricingRequestStatus={pricingRequestStatus}
        readOnly={readOnly}
        serviceModel={serviceModel}
        specialRequest={specialRequest}
        specialRequestPm={specialRequestPm}
        subFund={subFund}
        transactionFees={transactionFees}
      />
      {
        subFund.currentCondition?.tierPricing &&
        <Accordion className={classes.expansionPanel} expanded={isExpanded} onChange={() => setExpanded(!isExpanded)}>
          <AccordionSummary className={classes.expansionPanelSummary} data-testid={`test-new-tier-pricing-details-${subFund.assetSubLevel?.code}`} expandIcon={<ExpandMoreIcon/>}>Details</AccordionSummary>
          <AccordionDetails>
            <TransactionFeeSubFundTiering oldTierPricing={subFund.currentCondition.tierPricing}/>
          </AccordionDetails>
        </Accordion>
      }
      {
        !subFund.currentCondition && subFund.newCondition?.tierPricing &&
        <Accordion className={classes.expansionPanel} data-testid={`test-old-tier-pricing-details-${subFund.assetSubLevel?.code}`} expanded={isExpanded} onChange={() => setExpanded(!isExpanded)}>
          <AccordionSummary
            className={classes.expansionPanelSummary}
            expandIcon={<ExpandMoreIcon/>}
          >Details</AccordionSummary>
          <AccordionDetails>
            <TransactionFeeSubFundTiering oldTierPricing={subFund.newCondition.tierPricing}/>
          </AccordionDetails>
        </Accordion>
      }
    </Box>
  )
};

TransactionFeePanel.propTypes = {
  agentType: PropTypes.string,
  customTieringEnabled: PropTypes.bool,
  fundTypeCode: PropTypes.string.isRequired,
  handleAddTier: PropTypes.func,
  handleRangeEndChange: PropTypes.func,
  handleRangeStartChange: PropTypes.func,
  handleRemoveTier: PropTypes.func,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  pricingRequestStatus: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  serviceModel: PropTypes.string.isRequired,
  showAgreementIndicator: PropTypes.bool,
  specialRequest: PropTypes.bool,
  specialRequestPm: PropTypes.bool,
  subFund: PropTypes.object.isRequired,
  transactionFees: PropTypes.object.isRequired
};

export default TransactionFeePanel;
