import React from 'react';
import PropTypes from 'prop-types';
import {
  DISCOUNT_SUMMARY,
  LABEL_SUMMARY,
  NEW_CONDITIONS_SUMMARY,
  OLD_CONDITIONS_SUMMARY
} from '../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import PriceConditionTable from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import PriceConditionTableRowSection from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import {bankingServicePackage} from '../../PriceSimulation/components/BankingAndOtherServices/objectmapper/bankingFeesServicePackageObjectMapper';
import {specialMailing} from '../../PriceSimulation/components/BankingAndOtherServices/objectmapper/bankingFeesSpecialMailingObjectMapper';
import {reportingFee} from '../../PriceSimulation/components/BankingAndOtherServices/objectmapper/bankingFeesReportingFeeObjectMapper';
import PriceConditionTableColumn from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {codedAccount} from '../../PriceSimulation/components/BankingAndOtherServices/objectmapper/bankingFeesCodedAccountObjectMapper';
import {retainedMail} from '../../PriceSimulation/components/BankingAndOtherServices/objectmapper/bankingFeesRetainedMailObjectMapper';
import {SUMMARY_TABLE_CUSTOM_STYLE} from '../../../common/constants';
import CommonBILARows from './CommonBILARows';
import {isNullOrUndefined} from '../../../common/isNullOrUndefined';
import {isEAMorEFA} from '../../../common/enums/serviceModel';

const BankingServiceFeeTable = ({bankingFees, serviceModel, agentType, showDiff}) => {
  const fee = {
    currentConditionCodeFees: bankingFees?.currentConditionCodeFees,
    newConditionCodeFees: bankingFees?.newConditionCodeFees
  };
  const readOnly = true;
  const nonEmptyEntries = obj => obj?.oldCondition || obj?.newCondition;

  const bankingFeeData = [
    bankingServicePackage({fee, serviceModel, readOnly, showDiff}),
    specialMailing({fee, readOnly, showDiff}),
    reportingFee({fee, readOnly, showDiff}),
    codedAccount({fee, readOnly, showDiff}),
    retainedMail({fee, readOnly, showDiff}),
  ].filter(nonEmptyEntries);
  return (
    <>{bankingFeeData.length > 0 && <PriceConditionTable>
      <PriceConditionTableRowSection data={[]}>
        <PriceConditionTableColumn field="label" label="Banking Service Fees" type={LABEL_SUMMARY}/>
        {fee.currentConditionCodeFees && (
          <PriceConditionTableColumn
            customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
            label="Old Condition"
            type={OLD_CONDITIONS_SUMMARY}
          />
        )}
        <PriceConditionTableColumn
          customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
          isHighlightedFieldValueAccessor="newCondition.isHighlighted"
          label="New Condition"
          type={NEW_CONDITIONS_SUMMARY}
        />
        {fee.currentConditionCodeFees && <PriceConditionTableColumn label="% Discount" type={DISCOUNT_SUMMARY}/>}
      </PriceConditionTableRowSection>
      <CommonBILARows
        addedColumn={!isNullOrUndefined(fee.currentConditionCodeFees)}
        hideAcknowledgement
        hideBILA={isEAMorEFA(serviceModel, agentType)}
        newAgreementType={bankingFees?.pricingAgreedWithClient ? bankingFees?.pricingAgreedWithClient: null}
        oldAgreementType={bankingFees?.oldPricingAgreedWithClient ? bankingFees?.oldPricingAgreedWithClient: null}
        showDiff={showDiff}
        showOld={!isNullOrUndefined(fee.currentConditionCodeFees)}
        testId={'test-banking-service-summary'}
      />
      <PriceConditionTableRowSection data={bankingFeeData}>
        <PriceConditionTableColumn field="label" type={LABEL_SUMMARY}/>
        {fee.currentConditionCodeFees &&
        <PriceConditionTableColumn
          customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
          field="oldCondition.effectivePrice"
          type={OLD_CONDITIONS_SUMMARY}
        />}
        <PriceConditionTableColumn
          customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
          field="newCondition.effectivePrice"
          isHighlightedFieldValueAccessor="newCondition.isHighlighted"
          type={NEW_CONDITIONS_SUMMARY}
        />
        {fee.currentConditionCodeFees && <PriceConditionTableColumn field="newCondition.discount" type={DISCOUNT_SUMMARY}/>}
      </PriceConditionTableRowSection>
    </PriceConditionTable>}</>
  );
};

BankingServiceFeeTable.propTypes = {
  agentType: PropTypes.string,
  bankingFees: PropTypes.any,
  serviceModel: PropTypes.string,
  showDiff: PropTypes.bool
}

export default BankingServiceFeeTable;
