import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import {Box} from '@material-ui/core';
import {BPS, CHF, REGULAR} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {createEmpty, createSecondLevelLabel} from '../../priceSummaryCommonUtil';
import {useTransactionFeesStyles} from '../useTransactionFeesStyles';

const TransactionFeeTieringRow = ({ label, oldTier, newTier, secondLevel, testId, showDiff, showOld }) => {
  const classes = useTransactionFeesStyles();

  let isSame = oldTier && newTier && oldTier.length === newTier.length;
  if(isSame){
    for(let i=0; i<newTier.length; i++){
      const a = oldTier[i];
      const b = newTier[i];
      if(a.rangeStart !== b.rangeStart || a.rangeEnd !== b.rangeEnd || a.effectivePrice !== b.effectivePrice){
        isSame = false;
        break;
      }
    }
  }

  if(showDiff && oldTier && isSame){
    return null;
  }

  return (<TableRow className={classes.normalText}>
    <TableCell
      className={classes.normalText}
      colSpan={1}
      style={{
        borderBottom: '1px solid rgba(224,224,224,1)'
      }}
    >{secondLevel ? createSecondLevelLabel(label,classes.rowTitle) : label}</TableCell>
    {showOld && oldTier && <TieringRows className={classes.normalText} testId={`old-${testId}`} tier={oldTier}/>}
    {showOld && oldTier && !newTier &&
    <TableCell
      className={`${classes.normalText} ${oldTier && !isSame? classes.highlightedTableBodyCell: ''}`}
      colSpan={1}
      style={{
        borderBottom: '1px solid rgba(224,224,224,1)'
      }}
    >
      {createEmpty(classes.normalText)}
    </TableCell>
    }
    {showOld && !oldTier && newTier &&
    <TableCell
      className={`${classes.normalText} ${oldTier && !isSame? classes.highlightedTableBodyCell: ''}`}
      colSpan={1}
      style={{
        borderBottom: '1px solid rgba(224,224,224,1)'
      }}
    >
      {createEmpty(classes.normalText)}
    </TableCell>
    }
    {newTier && <TieringRows  className={`${classes.normalText} ${showOld && !isSame ? classes.highlightedTableBodyCell: ''}`} testId={`new-${testId}`} tier={newTier}/>}
  </TableRow>);
};

// eslint-disable-next-line react/no-multi-comp
const TieringRows = ({tier, className, testId}) => {
  return (
    <><TableCell className={className} colSpan={1}>
      <Table size="small">
        <TableBody>{tier.map(tier=>(<TableRow>
          <TableCell colSpan={1} style={{border: 'none'}}>
            <Box data-testid={testId} style={{display: 'flex', alignItems: 'center', paddingLeft: '5px'}}>
              <PriceNumberFormat
                placeHolderForZero="0"
                type={REGULAR}
                value={tier.rangeStart}
              />
              <Box>
                <span className={className}>&nbsp;{tier.rangeEnd ? 'to' : 'CHF and'}&nbsp;</span>
              </Box>
              <PriceNumberFormat
                placeHolderForUndefined="above"
                type={CHF}
                value={tier.rangeEnd}
              />
            </Box>
          </TableCell>
          <TableCell colSpan={1} style={{border: 'none'}}>
            <PriceNumberFormat
              type={BPS}
              value={tier.effectivePrice}
            />
          </TableCell>
        </TableRow>))}
        </TableBody>
      </Table>
    </TableCell>
    </>)
}

TieringRows.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string,
  tier: PropTypes.object
};

TransactionFeeTieringRow.propTypes = {
  label: PropTypes.string,
  newTier: PropTypes.object,
  oldTier: PropTypes.object,
  secondLevel: PropTypes.bool,
  showDiff: PropTypes.bool,
  showOld: PropTypes.bool,
  testId: PropTypes.string
};

export default TransactionFeeTieringRow;
