import React from 'react';
import PropTypes from 'prop-types';
import {useTransactionFeesStyles} from '../useTransactionFeesStyles';
import {createEmpty, createNormalText, createSecondLevelLabel} from '../../priceSummaryCommonUtil';
import PriceConditionTableRowSection from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {LABEL} from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionType';

const TransactionFeeTextRow = ({ label, oldValue, newValue, secondLevel, showDiff, showOld, testId }) => {
  const classes = useTransactionFeesStyles();

  if(showDiff && oldValue && oldValue === newValue){
    return null;
  }

  return (<PriceConditionTableRowSection data={[]}>
    <PriceConditionTableColumn field="label" label={secondLevel ? createSecondLevelLabel(label, classes.rowTitle) : label} type={LABEL}/>
    {oldValue &&
    <PriceConditionTableColumn
      data-testid={`old-${testId}`}
      field="label"
      label={createNormalText(oldValue, classes.normalText)}
    />}
    {!oldValue && showOld &&
    <PriceConditionTableColumn
      data-testid={testId}
      field="label"
      label={createEmpty(classes.normalText)}
    />}
    {oldValue && !newValue &&
    <PriceConditionTableColumn
      data-testid={testId}
      defaultClassName={oldValue && oldValue !== newValue? classes.highlightedTableBodyCell:''}
      field="label"
      label={createEmpty(classes.normalText)}
    />}
    {newValue &&
    <PriceConditionTableColumn
      data-testid={`new-${testId}`}
      defaultClassName={(oldValue && oldValue !== newValue) || (!oldValue && showOld && newValue) ? classes.highlightedTableBodyCell:''}
      field="label"
      label={createNormalText(newValue, classes.normalText)}
    />}
  </PriceConditionTableRowSection>);
};

TransactionFeeTextRow.propTypes = {
  label: PropTypes.string,
  newValue: PropTypes.string,
  oldValue: PropTypes.string,
  secondLevel: PropTypes.bool,
  showDiff: PropTypes.bool,
  showOld: PropTypes.bool,
  testId: PropTypes.string
};

export default TransactionFeeTextRow;

