import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/styles';
import {PRICING_MANAGER} from '../../../../common/enums/userRole';
import Authorized from '../../../../components/Authorized';
import AsyncButton from '../../../Common/components/AsyncButton';
import {saveAndValidatePricingRequest} from '../../pricingRequestService';
import {useNotification} from '../../../Common/components/Notification';
import {PriceRequestStore} from '../../PriceSimulationStore';
import {Store} from '../../../../Store';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import {useHistory} from 'react-router-dom';
import api from '../../../../api/api';
import {getSelectedPriceRequestErrors} from '../../../../common/getSelectedPriceRequestErrors';
import {returnToHome} from '../../../../Services';
import SaveButton from '../../../Common/components/ActionButtons/SaveButton';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0.5)
    },
    float: 'right',
    paddingBottom: '10px'
  }
}));

const SpecialRequestPmActionButtons = () => {
  const classes = useStyles();
  const history = useHistory();
  const {state, dispatch} = useContext(PriceRequestStore);
  const {parentState, parentDispatch} = useContext(Store);
  const notification = useNotification();

  const handleSendingSpecialRequest = async () => {
    const selectedPriceRequestErrors = getSelectedPriceRequestErrors(state);
    if (selectedPriceRequestErrors.length > 0) {
      notification.error('Resolve validation errors before submitting');
      throw new Error();
    }

    try {
      await saveAndValidatePricingRequest(parentState, state, dispatch, parentState.portfolios);
      await api.specialPricingRequest.sendBackToRm(state.selectedPricingRequest.common.pricingRequestId);
      notification.success('Successfully sending back pricing request to RM');
      returnToHome(history, parentDispatch);

    } catch (err) {
      notification.error('Failed sending to RM\n' + getErrorMessage(err));
      throw err;
    }
  };

  return (
    <div className={classes.buttonGroup}>
      <Authorized allowedRoles={[PRICING_MANAGER]}>
        <SaveButton data-testid="test-action-button-save"/>
        <AsyncButton
          color="primary"
          data-testid="test-action-button-submit-to-rm"
          onClick={handleSendingSpecialRequest}
          text="Submit to RM"
          type="button"
        />
      </Authorized>
    </div>
  );
};

export default SpecialRequestPmActionButtons;
