import React, {useContext, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  LinearProgress,
  Link,
  Typography
} from '@material-ui/core';
import api from '../../../../../api/api';
import {makeStyles} from '@material-ui/styles';
import {CLIPP_BLACK, CLIPP_WHITE} from '../../../../../theme/common/bjbColor';
import {useNotification} from '../../../../Common/components/Notification';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {deleteSupportingDocument} from '../summaryService';
import {PriceRequestStore} from '../../../PriceSimulationStore';
import AsyncButton from '../../../../Common/components/AsyncButton';
import {getErrorMessage} from '../../../../../common/getErrorMessage';

const useStyles = makeStyles(theme => ({
  documentsUpload: {
    backgroundColor: CLIPP_WHITE,
    border: 'solid 1px',
    borderColor: CLIPP_BLACK
  },
  fileSelectButton: {
    marginRight: theme.spacing(1)
  },
  deleteIcon: {
    cursor: 'pointer',
    marginRight: theme.spacing(1)
  },
}));

const DocumentUpload = ({disabled, onFilesUploaded, portfolioNumber, pricingRequestId, supportingDocuments}) => {
  const MAX_SIZE = 7340032;

  const classes = useStyles();
  const notification = useNotification();
  const {dispatch} = useContext(PriceRequestStore);
  const fileInputRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [inProgress, setInProgress] = useState();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState();

  const checkFileSize = files => {
    let errMessage = '';
    for (const file of files) {
      if (file.size > MAX_SIZE) {
        errMessage += file.name + ' exceeds the maximum file size of 7MB.\n';
      }
    }
    if (errMessage) {
      setErrorMessage(errMessage);
      setSelectedFiles(null);
      return false;
    } else {
      return true;
    }
  };

  const handleFileSelection = event => {
    setInProgress(false);
    if (validateSelection(event.target.files)) {
      setSelectedFiles(event.target.files);
    }
  };

  const validateSelection = files => {
    setErrorMessage('');
    return checkFileSize(files);
  };

  const uploadFiles = () => {
    return new Promise((asyncBtnResolve, asyncBtnReject) => {
      if (selectedFiles?.length > 0) {
        const data = new FormData();
        data.append('portfolioNumber', portfolioNumber);
        for (const file of selectedFiles) {
          data.append('file', file);
        }

        setInProgress(true);

        api.pricingRequest.supportingDocuments
          .upload(pricingRequestId, data)
          .then(() => {
            notification.success('Documents uploaded');
            setSelectedFiles([]);
            setInProgress(false);
            fileInputRef.current.value = null;
            onFilesUploaded();
            asyncBtnResolve();
          })
          .catch((err) => {
            notification.error('Failed uploading documents\n' + getErrorMessage(err));
            setInProgress(false);
            asyncBtnReject();
          });
      }
    })
  };

  const promptDeleteWarning = (document) => {
    setDocumentToDelete(document);
    setIsDeleteDialogOpen(true);
  }

  const deleteFile = () => {
    return new Promise((asyncBtnResolve, asyncBtnReject) => {
      deleteSupportingDocument(documentToDelete, dispatch)
        .then(() => {
          notification.success('Document deleted');
          setIsDeleteDialogOpen(false);
          asyncBtnResolve();
        })
        .catch(err => {
          notification.error('Failed deleting document\n' + getErrorMessage(err));
          asyncBtnReject();
        });
    })
  };


  const download = file => {
    api.pricingRequest.supportingDocuments.download(file)
      .then(res => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = file.documentName;

        // Release Object URL after download
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            link.removeEventListener('click', clickHandler);
          }, 150);
        };

        link.addEventListener('click', clickHandler);
        link.click();
      })
      .catch((err) => {
        notification.error('Failed downloading document\n' + getErrorMessage(err));
      });
  };

  const hasUploadError = errorMessage !== '';

  return (
    <React.Fragment>
      <Typography display="block" variant="h6">
        Upload Document
      </Typography>

      {inProgress && (
        <Box alignItems="center" display="flex" mb={1}>
          <Box mr={1} width="100%">
            <LinearProgress/>
          </Box>
        </Box>
      )}

      <FormControl error={hasUploadError}>
        {hasUploadError && <FormHelperText>{errorMessage}</FormHelperText>}
        <Box className={classes.documentsUpload} padding={1}>
          <Button
            className={classes.fileSelectButton}
            component="label"
            disabled={inProgress || disabled}
          >
            Choose file
            <input
              multiple
              onChange={handleFileSelection}
              ref={fileInputRef}
              style={{display: 'none'}}
              type="file"
            />
          </Button>
          {selectedFiles?.length > 0 && (
            <Typography variant="caption">
              {selectedFiles.length === 1
                ? selectedFiles[0].name
                : `${selectedFiles.length} files.`}
            </Typography>
          )}
        </Box>
      </FormControl>

      <Box display="flex" justifyContent="space-between" marginTop={1}>
        <Typography variant="caption">(Maximum file size: 7 MB)</Typography>
        <AsyncButton
          disabled={!(selectedFiles?.length > 0) || inProgress || disabled}
          onClick={uploadFiles}
          text="Upload"
        />
      </Box>
      <Box marginY={1}>
        <Dialog open={isDeleteDialogOpen}>
          <DialogContent>Are you sure you want to delete "{documentToDelete?.documentName}"?</DialogContent>
          <DialogActions>
            <AsyncButton onClick={deleteFile} text="Delete"/>
            <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {supportingDocuments?.length > 0 &&
        supportingDocuments.map((document, index) => (
          <React.Fragment key={index}>
            <Box alignItems="center" display="flex" key={index}>
              {!disabled && (
                <DeleteForeverIcon
                  className={classes.deleteIcon}
                  onClick={() => promptDeleteWarning(document)}
                />
              )}
              <Typography variant="caption">
                {index + 1}.{' '}
                <Link component="button" onClick={() => download(document)}>
                  {document.documentName}
                </Link>
              </Typography>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </React.Fragment>
  );
};

DocumentUpload.propTypes = {
  disabled: PropTypes.bool,
  onFilesUploaded: PropTypes.func,
  portfolioNumber: PropTypes.string,
  pricingRequestId: PropTypes.number,
  supportingDocuments: PropTypes.array
};

export default DocumentUpload;
