import {createMuiTheme} from '@material-ui/core';
import {
  MuiAutocompleteOverrides,
  MuiButtonOverrides,
  MuiCheckboxOverrides,
  MuiDividerOverrides,
  MuiFormControlLabelOverrides,
  MuiFormControlOverrides,
  MuiFormHelperTextOverrides,
  MuiIconButtonOverrides,
  MuiInputBaseOverrides,
  MuiInputLabelOverrides,
  MuiInputOverrides,
  MuiListItemTextOverrides,
  MuiMenuItemOverrides,
  MuiPaperOverrides,
  MuiPickersToolbarButtonOverrides,
  MuiPickersToolbarOverrides,
  MuiRadioOverrides,
  MuiSelectOverrides,
  MuiSvgItemOverrides,
  MuiTableCellOverrides,
  MuiTableRowOverrides,
  MuiTabOverrides,
  MuiTabsOverrides,
  MuiToolbarOverrides,
  MuiTypographyOverrides
} from './overrides';
import palette from './palette';

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: MuiAutocompleteOverrides,
    MuiButton: MuiButtonOverrides,
    MuiCheckbox: MuiCheckboxOverrides,
    MuiDivider: MuiDividerOverrides,
    MuiFormControl: MuiFormControlOverrides,
    MuiFormControlLabel: MuiFormControlLabelOverrides,
    MuiFormHelperText: MuiFormHelperTextOverrides,
    MuiIconButton: MuiIconButtonOverrides,
    MuiInput: MuiInputOverrides,
    MuiInputBase: MuiInputBaseOverrides,
    MuiInputLabel: MuiInputLabelOverrides,
    MuiListItemText: MuiListItemTextOverrides,
    MuiMenuItem: MuiMenuItemOverrides,
    MuiPaper: MuiPaperOverrides,
    MuiPickersToolbar: MuiPickersToolbarOverrides,
    MuiPickersToolbarButton: MuiPickersToolbarButtonOverrides,
    MuiSvgIcon: MuiSvgItemOverrides,
    MuiSelect: MuiSelectOverrides,
    MuiTab: MuiTabOverrides,
    MuiTableCell: MuiTableCellOverrides,
    MuiRadio: MuiRadioOverrides,
    MuiTableRow: MuiTableRowOverrides,
    MuiTabs: MuiTabsOverrides,
    MuiToolbar: MuiToolbarOverrides,
    MuiTypography: MuiTypographyOverrides
  },
  palette: palette,
  typography: {
    fontFamily: '\'Verlag SSm 3r\'',
    fontSize: 16,
    lineHeight: '1.4'
  }
});

export default theme;
