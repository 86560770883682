import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {
  DISCOUNT_SUMMARY,
  LABEL_SUMMARY,
  NEW_CONDITIONS_SUMMARY,
  OLD_CONDITIONS_SUMMARY
} from '../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import PriceConditionTable from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import PriceConditionTableRowSection from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import {PriceRequestStore} from '../../PriceSimulation/PriceSimulationStore';
import {recurringFeeCondition} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesConditionObjectMapper';
import {minimumFeePerQuarter} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesMinimumFeePerQuarterObjectMapper';
import {surchargeOnRestriction} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesSurchargeOnRestrictionObjectMapper';
import {portfolioAssets} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesPortfolioAssetsObjectMapper';
import PriceConditionTableColumn from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {getFeeTypeDescription} from '../../../common/enums/transactionFeeFundType';
import {recurringFeeImplementation} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesImplementationObjectMapper';
import {calculationBasis} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesCalculationBasisObjectMapper';
import {annualFee} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesAnnualFeeObjectMapper';
import {flatRateInBps} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesFlatRateInBpsObjectMapper';
import {discountOnListPrice} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesDiscountOnListPriceObjectMapper';
import {discountOnSurcharge} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesDiscountOnSurchargeObjectMapper';
import {feePerAnnum} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesFeePerAnnumObjectMapper';
import {portfolioAssetsSpecialRequest} from '../../PriceSimulation/components/RecurringFees/objectmapper/recurringFeesPortfolioAssetsSpecialRequestObjectMapper';
import {SUMMARY_TABLE_CUSTOM_STYLE} from '../../../common/constants';
import CommonBILARows from './CommonBILARows';
import {isNullOrUndefined} from '../../../common/isNullOrUndefined';
import {createEmpty} from './priceSummaryCommonUtil';
import {useTransactionFeesStyles} from './TransactionFeeTables/useTransactionFeesStyles';
import {isEAMorEFA} from '../../../common/enums/serviceModel';

const RecurringFeeTable = ({recurringFees, serviceModel, agentType, showDiff}) => {
  const classes = useTransactionFeesStyles();
  const {state} = useContext(PriceRequestStore);
  const serviceModelCode = state.selectedPricingRequest.summary?.serviceModel?.code;
  const selectableImplementationCodes = Object.keys(recurringFees?.applicableFees?.surchargeOnRestrictions || []);
  const properties = {
    recurringFees,
    selectableImplementationCodes,
    serviceModelCode,
    readOnly: true,
    showDiff
  };

  const nonEmptyEntries = obj => obj?.oldCondition || obj?.newCondition;

  const emptyFieldComponent = <div style={{textAlign: 'right'}}>-</div>;

  const labelValueSection = [
    {...recurringFeeImplementation(properties), discount: emptyFieldComponent},
    {...recurringFeeCondition(properties), discount: emptyFieldComponent},
    {...calculationBasis(properties), discount: emptyFieldComponent},
    {...annualFee(properties), discount: emptyFieldComponent},
    {...flatRateInBps(properties), discount: emptyFieldComponent},
    {...discountOnListPrice(properties), discount: emptyFieldComponent},
    {...discountOnSurcharge(properties), discount: emptyFieldComponent}
  ].filter(nonEmptyEntries);

  const labelListEffectivePriceSection = [
    feePerAnnum(properties),
    ...portfolioAssets(properties),
    ...portfolioAssetsSpecialRequest(properties),
    minimumFeePerQuarter(properties),
    {...surchargeOnRestriction(properties), discount: emptyFieldComponent}
  ].filter(nonEmptyEntries);


  const hasDisjunctTiering = labelListEffectivePriceSection.some(entry => !!entry.newConditionTiering);

  const bilaRow =
    <CommonBILARows
      addedColumn
      hasDisjunctTiering={hasDisjunctTiering}
      hideBILA={isEAMorEFA(serviceModel, agentType)}
      newAcknowledge={recurringFees?.newCondition ? recurringFees.newCondition?.acknowledged: null}
      newAdditionalInstructions={recurringFees?.newCondition ? recurringFees.newCondition?.additionalInstructions: null}
      newAgreementType={recurringFees?.newCondition && !isNullOrUndefined(recurringFees?.pricingAgreedWithClient) ? recurringFees?.pricingAgreedWithClient: null}
      oldAcknowledge={recurringFees.currentCondition ? recurringFees.currentCondition?.acknowledged: null}
      oldAdditionalInstructions={recurringFees?.currentCondition ? recurringFees?.currentCondition?.additionalInstructions: null}
      oldAgreementType={recurringFees?.currentCondition && !isNullOrUndefined(recurringFees?.oldPricingAgreedWithClient) ? recurringFees?.oldPricingAgreedWithClient: null}
      showDiff={showDiff}
      showOld={!!recurringFees?.currentCondition}
      testId={'test-recurring-fees-summary'}
    />;

  return (
    <> {(labelListEffectivePriceSection.length > 0 || bilaRow) && <PriceConditionTable>
      <PriceConditionTableRowSection data={[]}>
        <PriceConditionTableColumn
          field="label"
          label={getFeeTypeDescription(state.selectedPricingRequest.summary?.serviceModel?.code)}
          type={LABEL_SUMMARY}
        />
        {recurringFees?.currentCondition && (
          <PriceConditionTableColumn
            customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
            label="Old Condition"
            type={OLD_CONDITIONS_SUMMARY}
          />
        )}
        <PriceConditionTableColumn
          colSpan={hasDisjunctTiering ? 2 : 1}
          customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
          isHighlightedFieldValueAccessor="newCondition.isHighlighted"
          label="New Condition"
          type={NEW_CONDITIONS_SUMMARY}
        />
        <PriceConditionTableColumn field="discount" label="% Discount" type={DISCOUNT_SUMMARY}/>
      </PriceConditionTableRowSection>
      {bilaRow}
      <PriceConditionTableRowSection data={labelValueSection} defaultValue={createEmpty(classes.normalText)}>
        <PriceConditionTableColumn field="label" type={LABEL_SUMMARY}/>
        {recurringFees?.currentCondition &&
        <PriceConditionTableColumn
          customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
          field="oldCondition.value"
          type={OLD_CONDITIONS_SUMMARY}
        />}
        <PriceConditionTableColumn
          colSpan={hasDisjunctTiering ? 2 : 1}
          customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
          field="newCondition.value"
          isHighlightedFieldValueAccessor="newCondition.isHighlighted"
          type={NEW_CONDITIONS_SUMMARY}
        />
        <PriceConditionTableColumn field="discount" type={DISCOUNT_SUMMARY}/>
      </PriceConditionTableRowSection>
      <PriceConditionTableRowSection data={labelListEffectivePriceSection} defaultValue={createEmpty(classes.normalText)}>
        <PriceConditionTableColumn field="label" type={LABEL_SUMMARY}/>
        {recurringFees?.currentCondition &&
        <PriceConditionTableColumn
          customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
          field="oldCondition.effectivePrice"
          type={OLD_CONDITIONS_SUMMARY}
        />}
        {hasDisjunctTiering &&
        <PriceConditionTableColumn field="newConditionTiering" isHighlightedFieldValueAccessor="newCondition.isHighlighted" type={LABEL_SUMMARY}/>}
        <PriceConditionTableColumn
          customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
          field="newCondition.effectivePrice"
          isHighlightedFieldValueAccessor="newCondition.isHighlighted"
          type={NEW_CONDITIONS_SUMMARY}
        />
        <PriceConditionTableColumn field="discount" type={DISCOUNT_SUMMARY}/>
      </PriceConditionTableRowSection>
    </PriceConditionTable>}</>
  );
};

RecurringFeeTable.propTypes = {
  agentType: PropTypes.string,
  recurringFees: PropTypes.any,
  serviceModel: PropTypes.string,
  showDiff: PropTypes.bool
};

export default RecurringFeeTable;
