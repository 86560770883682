export const BND_OTC_OVER_THE_COUNTER = Object.freeze({
  code: 'BND_OTC_OVER_THE_COUNTER',
  display: '% of notional amount'
});
export const BND_OTC_CERT_OF_DEPOSIT = Object.freeze({
  code: 'BND_OTC_CERT_OF_DEPOSIT',
  display: '% of notional amount'
});
export const BND_NEW_BOND_ISSUE = Object.freeze({
  code: 'BND_NEW_BOND_ISSUE',
  display: '% of notional amount'
});
export const INF_SM_INTEREST_RATE_SWAP_RATE = Object.freeze({
  code: 'INF_SM_INTEREST_RATE_SWAP_RATE',
  display: '% p.a of notional amount'
});
export const INF_SM_MONEY_MARKETS = Object.freeze({
  code: 'INF_SM_MONEY_MARKETS',
  display: '% of notional amount'
});
export const INF_SM_BONDS_FUNDS = Object.freeze({
  code: 'INF_SM_BONDS_FUNDS',
  display: '% of notional amount'
});
export const INF_SM_EQUITY_OTHER_FUNDS = Object.freeze({
  code: 'INF_SM_EQUITY_OTHER_FUNDS',
  display: '% of notional amount'
});
export const INF_SM_NON_TRADITIONAL_FUNDS = Object.freeze({
  code: 'INF_SM_NON_TRADITIONAL_FUNDS',
  display: '% of notional amount'
});
export const INF_SM_PRIVATE_EQUITY = Object.freeze({
  code: 'INF_SM_PRIVATE_EQUITY',
  display: '% of notional amount'
});
export const STP_ACCUMULATOR_EQUITY_UNDERLYING = Object.freeze({
  code: 'STP_ACCUMULATOR_EQUITY_UNDERLYING',
  display: '% of notional amount'
});
export const STP_EQUITY_OTC_PRODUCTS = Object.freeze({
  code: 'STP_EQUITY_OTC_PRODUCTS',
  display: '% of option premium'
});
export const STP_ELN_UPTO_1_YEAR = Object.freeze({
  code: 'STP_ELN_UPTO_1_YEAR',
  display: 'bps on notional'
});
export const STP_ELN_BETWEEN_1_TO_3_YEAR = Object.freeze({
  code: 'STP_ELN_BETWEEN_1_TO_3_YEAR',
  display: 'bps on notional'
});
export const STP_ELN_ABOVE_3_YEAR = Object.freeze({
  code: 'STP_ELN_ABOVE_3_YEAR',
  display: 'bps on notional'
});
export const STP_FCN_UPTO_1_YEAR = Object.freeze({
  code: 'STP_FCN_UPTO_1_YEAR',
  display: 'bps on notional'
});
export const STP_FCN_BETWEEN_1_TO_3_YEAR = Object.freeze({
  code: 'STP_FCN_BETWEEN_1_TO_3_YEAR',
  display: 'bps on notional'
});
export const STP_FCN_ABOVE_3_YEAR = Object.freeze({
  code: 'STP_FCN_ABOVE_3_YEAR',
  display: 'bps on notional'
});
export const STP_DAC_UPTO_1_YEAR = Object.freeze({
  code: 'STP_DAC_UPTO_1_YEAR',
  display: 'bps on notional'
});
export const STP_DAC_BETWEEN_1_TO_3_YEAR = Object.freeze({
  code: 'STP_DAC_BETWEEN_1_TO_3_YEAR',
  display: 'bps on notional'
});
export const STP_DAC_ABOVE_3_YEAR = Object.freeze({
  code: 'STP_DAC_ABOVE_3_YEAR',
  display: 'bps on notional'
});
export const STP_OTHER_CERT_NFP_UPTO_1_YEAR = Object.freeze({
  code: 'STP_OTHER_CERT_NFP_UPTO_1_YEAR',
  display: 'bps on notional'
});
export const STP_OTHER_CERT_NFP_BETWEEN_1_TO_3_YEAR = Object.freeze({
  code: 'STP_OTHER_CERT_NFP_BETWEEN_1_TO_3_YEAR',
  display: 'bps on notional'
});
export const STP_OTHER_CERT_NFP_ABOVE_3_YEAR = Object.freeze({
  code: 'STP_OTHER_CERT_NFP_ABOVE_3_YEAR',
  display: 'bps on notional'
});
export const STP_DUAL_CCY_INVESTMENT = Object.freeze({
  code: 'STP_DUAL_CCY_INVESTMENT',
  display: '% of notional amount'
});
export const STP_TRACKER_CERTIFICATE_NOTES = Object.freeze({
  code: 'STP_TRACKER_CERTIFICATE_NOTES',
  display: '% p.a of notional amount'
});

const getAllSubTypes = [BND_OTC_OVER_THE_COUNTER, BND_NEW_BOND_ISSUE, BND_OTC_CERT_OF_DEPOSIT, BND_OTC_OVER_THE_COUNTER,
  INF_SM_BONDS_FUNDS, INF_SM_EQUITY_OTHER_FUNDS,INF_SM_INTEREST_RATE_SWAP_RATE,INF_SM_MONEY_MARKETS,INF_SM_NON_TRADITIONAL_FUNDS,INF_SM_PRIVATE_EQUITY,
  STP_ACCUMULATOR_EQUITY_UNDERLYING,STP_DAC_ABOVE_3_YEAR,STP_DAC_BETWEEN_1_TO_3_YEAR,STP_DAC_UPTO_1_YEAR,
  STP_DUAL_CCY_INVESTMENT,STP_ELN_ABOVE_3_YEAR,STP_ELN_BETWEEN_1_TO_3_YEAR,STP_ELN_UPTO_1_YEAR,
  STP_EQUITY_OTC_PRODUCTS,STP_FCN_ABOVE_3_YEAR,STP_FCN_BETWEEN_1_TO_3_YEAR,STP_FCN_UPTO_1_YEAR,
  STP_OTHER_CERT_NFP_ABOVE_3_YEAR,STP_OTHER_CERT_NFP_BETWEEN_1_TO_3_YEAR,STP_OTHER_CERT_NFP_UPTO_1_YEAR,
  STP_TRACKER_CERTIFICATE_NOTES] ;

export const getDisplayTextBasedOnSubType = (subtype) => {
  let filtered = getAllSubTypes.filter(s => s.code === subtype);
  if(filtered){
    return filtered[0].display;
  }
  return '';
}

export const getChildTypesByParentType = (parentType) => {
  switch (parentType){
    case 'ELN_STRUCTURED_PRODUCTS':
      return [
        STP_ELN_UPTO_1_YEAR.code,
        STP_ELN_BETWEEN_1_TO_3_YEAR.code,
        STP_ELN_ABOVE_3_YEAR.code
      ];
    case 'FCN_STRUCTURED_PRODUCTS':
      return [
        STP_FCN_UPTO_1_YEAR.code,
        STP_FCN_BETWEEN_1_TO_3_YEAR.code,
        STP_FCN_ABOVE_3_YEAR.code
      ];
    case 'DAC_STRUCTURED_PRODUCTS':
      return [
        STP_DAC_UPTO_1_YEAR.code,
        STP_DAC_BETWEEN_1_TO_3_YEAR.code,
        STP_DAC_ABOVE_3_YEAR.code
      ];
    case 'NFP_STRUCTURED_PRODUCTS':
      return [
        STP_OTHER_CERT_NFP_UPTO_1_YEAR.code,
        STP_OTHER_CERT_NFP_BETWEEN_1_TO_3_YEAR.code,
        STP_OTHER_CERT_NFP_ABOVE_3_YEAR.code
      ];
    default:
      return [];
  }
}

export const checkSubFundErrorExists = (parentType, subFundErrors) => {
  let result = getChildTypesByParentType(parentType).filter(e1 => subFundErrors.some(e2 => e1 === e2));
  return result.length > 0;
}
