import React from 'react';
import Box from '@material-ui/core/Box';
import PriceSelect from '../../../../Common/components/PriceSelect';

const LABEL = 'Condition';
const KEY_PREFIX = 'payment-fees-condition';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const paymentFeeCondition = ({currentCondition,  newCondition, onValueChange, readOnly, showDiff}) => {

  let result = {
    label: LABEL
  };

  let isNewValueDifferent = false;
  if (currentCondition) {
    if (readOnly && currentCondition !== newCondition) {
      isNewValueDifferent = true;
    }

    result = {
      ...result,
      oldCondition: getMappedOldCondition(currentCondition)
    }
  }

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(newCondition, onValueChange, readOnly, isNewValueDifferent)
  }

};

const getMappedOldCondition = (condition) => {

  const readonlyField = (
    <Box data-testid={`${TEST_KEY_PREFIX}-readonly`} textAlign="right">{condition}</Box>
  );

  return {
    value: readonlyField
  };
};

const getMappedNewCondition = (condition, onValueChange, readOnly, isNewValueDifferent) => {

  const options = [
    {key: 'Standard', value: 'Standard'},
    {key: 'Exempt', value: 'Exempt'}
  ];

  const editableField = (
    <PriceSelect
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      inputProps={{id: 'uncontrolled-native'}}
      key={`${KEY_PREFIX}-editable`}
      onValueChange={onValueChange || function(){}}
      options={options}
      value={condition}
    />
  );

  const readonlyField = (
    <Box data-testid={`${TEST_KEY_PREFIX}-readonly`} textAlign="right">{condition}</Box>
  )

  return {
    value: readOnly ? readonlyField : editableField,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
  };
};
