import {Popover} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: '500px'
  },
  text: {
    marginBottom: '-4px'
  }
}));

const HoverInfo = ({children, text}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverRef = useRef();

  const handleMouseEnter = () => {
    setAnchorEl(popoverRef.current);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  if (!text) {
    return children;
  }

  return (
    <React.Fragment>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        disableRestoreFocus
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {text}
      </Popover>
      <span className={classes.text} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={popoverRef}>
        {children}
      </span>
    </React.Fragment>
  );
};

HoverInfo.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string
}

export default HoverInfo;
