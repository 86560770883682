import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import {getTransactionFeeFundTypeDescriptionFromCode} from '../../../../../common/enums/transactionFeeFundType';
import TransactionFeeSubFunds from './TransactionFeeSubFunds';
import {makeStyles} from '@material-ui/styles';
import {
  EXPANSION_PANEL_PRIMARY_BACKGROUND_COLOR,
  EXPANSION_PANEL_PRIMARY_COLOR,
  EXPANSION_PANEL_PRIMARY_FOCUS_BACKGROUND_COLOR,
  EXPANSION_PANEL_PRIMARY_FOCUS_COLOR,
  EXPANSION_PANEL_PRIMARY_HOVER_BACKGROUND_COLOR,
  EXPANSION_PANEL_PRIMARY_HOVER_COLOR
} from '../../../../../theme/common/color';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';


const useStyles = makeStyles(() => ({
  outerExpansionPanelDetails: {
    padding: 0
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  outerExpansionPanelSummary: {
    backgroundColor: EXPANSION_PANEL_PRIMARY_BACKGROUND_COLOR,
    color: EXPANSION_PANEL_PRIMARY_COLOR,
    fontSize: '0.8125rem',
    height: '36px',
    minHeight: '36px!important',
    '&:focus': {
      backgroundColor: EXPANSION_PANEL_PRIMARY_FOCUS_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_PRIMARY_FOCUS_COLOR
    },
    '&:hover': {
      backgroundColor: EXPANSION_PANEL_PRIMARY_HOVER_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_PRIMARY_HOVER_COLOR
    }
  },
  outerExpansionPanelSummaryTitle: {
    fontFamily: '\'Verlag SSm 7r\''
  }
}));
const TransactionFeeFund = ({customTieringEnabled, fundTypeCode, handleAddTier, handleRemoveTier, handleRangeStartChange, handleRangeEndChange,
  handleValueChange, handleValidationResultChange, readOnly, specialRequest,
  subFunds, specialRequestPm }) => {

  const classes = useStyles();

  const [expandedPanels, setExpandedPanels] = useState([]);

  const handlePanelExpansionToggle = (panelId) => {
    const currentlyExpanded = expandedPanels.some(pId => pId === panelId);
    if (currentlyExpanded) {
      setExpandedPanels(expandedPanels.filter(pId => pId !== panelId));
    } else {
      setExpandedPanels([...expandedPanels, panelId]);
    }
  };

  const currentlyAllSubCategoryPanelsExpanded = subFunds.length === expandedPanels.length;

  const toggleAllExpandedPanels = () => {
    if (currentlyAllSubCategoryPanelsExpanded) {
      setExpandedPanels([]);
    } else {
      setExpandedPanels(subFunds.map(subFund => subFund.assetSubLevel.code));
    }
  };

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} expanded key={`expansion-${fundTypeCode}`} onChange={toggleAllExpandedPanels}>
      <AccordionSummary className={classes.outerExpansionPanelSummary}>
        <Box className={classes.displayFlex}>
          <Box className={classes.outerExpansionPanelSummaryTitle}>{getTransactionFeeFundTypeDescriptionFromCode(fundTypeCode)}</Box>
          <Box>{currentlyAllSubCategoryPanelsExpanded ? 'Hide Details' : 'Show Details'}</Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.outerExpansionPanelDetails}>
        <TransactionFeeSubFunds
          customTieringEnabled={customTieringEnabled}
          expandedPanels={expandedPanels}
          fundTypeCode={fundTypeCode}
          handleAddTier={handleAddTier}
          handlePanelExpansionToggle={handlePanelExpansionToggle}
          handleRangeEndChange={handleRangeEndChange}
          handleRangeStartChange={handleRangeStartChange}
          handleRemoveTier={handleRemoveTier}
          handleValidationResultChange={handleValidationResultChange}
          handleValueChange={handleValueChange}
          readOnly={readOnly}
          specialRequest={specialRequest}
          specialRequestPm={specialRequestPm}
          subFunds={subFunds}
        />
      </AccordionDetails>
    </Accordion>
  );
};

TransactionFeeFund.propTypes = {
  customTieringEnabled: PropTypes.bool,
  fundTypeCode: PropTypes.string.isRequired,
  handleAddTier: PropTypes.func,
  handleRangeEndChange: PropTypes.func,
  handleRangeStartChange: PropTypes.func,
  handleRemoveTier: PropTypes.func,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  specialRequest: PropTypes.bool,
  specialRequestPm: PropTypes.bool,
  subFunds: PropTypes.array.isRequired
};

export default TransactionFeeFund;
