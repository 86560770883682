import {
  LOAD_RECURRING_APPLICABLE_FEES_RESPONSE,
  UPDATE_RECURRING_FEES, UPDATE_RECURRING_FEES_AGREEMENT_TYPE,
  UPDATE_RECURRING_FEES_CONDITION,
  UPDATE_RECURRING_FEES_IMPLEMENTATION,
  UPDATE_RECURRING_FEES_VALIDATION_RESULT
} from './recurringFeesActions';
import {
  FIXED_AMOUNT,
  FLAT_RATE,
  getRecurringFeeConditionDescriptionFromCode,
  NON_STANDARD_CUMULATIVE,
  NON_STANDARD_NON_CUMULATIVE,
  PERCENTAGE_DISCOUNT,
  TIERED_FEE_CUMULATIVE,
  TIERED_FEE_NON_CUMULATIVE
} from '../../../../common/enums/recurringFeeCondition';
import {LOAD_PRICING_REQUEST_RESPONSE} from '../pricingRequestActions';
import {getRecurringFeeImplementationDescriptionFromCode} from '../../../../common/enums/recurringFeeImplementation';

export const recurringFeesReducer = (state, action) => {
  switch (action.type) {
    case LOAD_RECURRING_APPLICABLE_FEES_RESPONSE: {
      return {
        ...state,
        applicableFees: action.payload.data,
      };
    }
    case LOAD_PRICING_REQUEST_RESPONSE: {
      if (!action.payload.data.recurringFees) {
        return null;
      }

      return {
        ...action.payload.data.recurringFees,
        applicableFees: {...state?.applicableFees},
        errors: []
      };
    }
    case UPDATE_RECURRING_FEES: {
      return {
        ...state,
        newCondition: action.data
      };
    }
    case UPDATE_RECURRING_FEES_VALIDATION_RESULT: {
      let newErrors = (state?.errors || []).filter(e => e !== action.selector);
      if (!action.isValid) {
        newErrors = [...state.errors, action.selector];
      }
      return {
        ...state,
        errors: newErrors
      };
    }
    case UPDATE_RECURRING_FEES_IMPLEMENTATION: {
      if (![NON_STANDARD_CUMULATIVE.code, NON_STANDARD_NON_CUMULATIVE.code].includes(state.newCondition.condition.code)) {
        const applicableFees = getApplicableFeesForSelectedCondition({
          applicableFees: state.applicableFees,
          conditionCode: state.newCondition.condition.code
        });
        const newRecurringFeeConditions = {
          ...state.newCondition,
          discountOnSurcharge: null,
          surchargeOnRestriction: applicableFees.surchargeOnRestrictions[action.implementationCode],
          minimumFeePerQuarter: applicableFees.managementMinFeesPerQuarter[action.implementationCode],
          implementation: {
            code: action.implementationCode,
            desc: getRecurringFeeImplementationDescriptionFromCode(action.implementationCode)
          }
        };

        return {
          ...state,
          newCondition: newRecurringFeeConditions,
          errors: []
        };
      }

      return {
        ...state,
        newCondition: {
          ...state.newCondition,
          implementation: {
            code: action.implementationCode,
            desc: getRecurringFeeImplementationDescriptionFromCode(action.implementationCode)
          }
        },
        errors: []
      };
    }
    case UPDATE_RECURRING_FEES_CONDITION: {
      let applicableFeesForSelectedCondition = {};
      if (![NON_STANDARD_CUMULATIVE.code, NON_STANDARD_NON_CUMULATIVE.code].includes(action.conditionCode)) {
        applicableFeesForSelectedCondition = getApplicableFeesForSelectedCondition({
          applicableFees: state.applicableFees,
          conditionCode: action.conditionCode
        });
      }

      const newRecurringFeeConditions = {
        ...state.newCondition,
        portfolioAssets: undefined,
        annualFee: undefined,
        flatRateInBps: undefined,
        discountOnListPrice: undefined,
        discountOnSurcharge: null,
        surchargeOnRestriction: (applicableFeesForSelectedCondition.surchargeOnRestrictions || {})[state.newCondition.implementation?.code],
        minimumFeePerQuarter: (applicableFeesForSelectedCondition.managementMinFeesPerQuarter || {})[state.newCondition.implementation?.code],
        ...applicableFeesForSelectedCondition,
        condition: {
          code: action.conditionCode,
          desc: getRecurringFeeConditionDescriptionFromCode(action.conditionCode)
        }
      };

      return {
        ...state,
        newCondition: newRecurringFeeConditions,
        errors: []
      };

    }
    case UPDATE_RECURRING_FEES_AGREEMENT_TYPE: {
      const pricingAgreedWithClient = action.data;
      return {
        ...state,
        pricingAgreedWithClient
      }
    }
    default:
      return state;
  }
}

const getApplicableFeesForSelectedCondition = ({applicableFees, conditionCode}) => {
  switch (conditionCode) {
    case TIERED_FEE_CUMULATIVE.code:
      return applicableFees.tieredFeeCumulative;
    case TIERED_FEE_NON_CUMULATIVE.code:
      return applicableFees.tieredFeeNonCumulative;
    case FLAT_RATE.code:
      return applicableFees.flatRate;
    case FIXED_AMOUNT.code:
      return applicableFees.fixedAmount;
    case PERCENTAGE_DISCOUNT.code:
      return applicableFees.percentageDiscount;
    default:
      throw new Error('Unknown conditionCode - Cannot update list prices');
  }
}
