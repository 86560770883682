import React from 'react';
import {Checkbox, FormControlLabel, Grid, makeStyles} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';
import {BILA} from '../../../../../common/enums/agreementType';
import {TABLE_HEADER_COLOR} from '../../../../../theme/common/color';
import {CLIPP_LIGHT_GREY} from '../../../../../theme/common/bjbColor';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: CLIPP_LIGHT_GREY,
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  label: {
    color: TABLE_HEADER_COLOR,
    fontSize: '0.9rem',
    fontWeight: 'strong',
    fontFamily: '\'Verlag SSm 7r\''
  },
  labelPlacementStart: {
    marginRight: '0px'
  }
}));

const AgreementTypeIndicator = ({agreedWithClient, handleChange, other, readOnly, show, style}) => {
  const classes = useStyles();
  return (
    <>
      {show &&
      <Grid container justify="flex-end" style={style}>
        <FormControlLabel
          classes={{root: classes.root, label: classes.label, labelPlacementStart: classes.labelPlacementStart}}
          control={
            <Checkbox
              checked={agreedWithClient === BILA.code}
              color="primary"
              disabled={readOnly}
              icon={<CheckBoxOutlineBlankIcon style={{backgroundColor: '#f5f8fa'}}/>}
              inputProps={{'data-testid': 'test-agreement-type-indicator-checkbox'}}
              name="agreedWithClient"
              onChange={e => handleChange(e.target.checked)}
              {...other}
            />
          }
          key="agreedWithClient"
          label="This pricing has been discussed with the client"
          labelPlacement={'start'}
        />
      </Grid>
      }
    </>);
};

AgreementTypeIndicator.propTypes = {
  agreedWithClient: PropTypes.string,
  handleChange: PropTypes.func,
  other: PropTypes.object,
  readOnly: PropTypes.bool,
  show: PropTypes.bool,
  style: PropTypes.object
};

export default React.memo(AgreementTypeIndicator);
