import MuiAlert from './MuiAlert';
import MuiAutocomplete from './MuiAutocomplete';
import MuiButton from './MuiButton';
import MuiCssBaseline from './MuiCssBaseline';
import MuiDropzoneArea from './MuiDropzoneArea';
import MuiFilledInput from './MuiFilledInput';
import MuiIconButton from './MuiIconButton';
import MuiInputAdornment from './MuiInputAdornment';
import MuiInputBase from './MuiInputBase';
import MuiPaper from './MuiPaper';
import MuiSelect from './MuiSelect';
import MuiTab from './MuiTab';
import MuiTable from './MuiTable';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableRow from './MuiTableRow';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiTabs from './MuiTabs';
import MuiTypography from './MuiTypography';

const overrides = {
  spacing: '55%',
  padding: '3%',
  minWidth: '1000px',
  MuiAlert,
  MuiButton,
  MuiCssBaseline,
  MuiDropzoneArea,
  MuiFilledInput,
  MuiIconButton,
  MuiAutocomplete,
  MuiTableSortLabel,
  MuiInputBase,
  MuiInputAdornment,
  MuiPaper,
  MuiSelect,
  MuiTab,
  MuiTabs,
  MuiTable,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiTypography,
};

export default overrides;
