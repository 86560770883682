import {FORM_RADIO_COLOR} from '../common/color';

export default {
  root: {
    radio: {
      '&.checked': {
        color: FORM_RADIO_COLOR
      }
    }
  }
};
