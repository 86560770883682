export default {
  secondary:{
    fontFamily: '\'Verlag SSm 3r\'',
    fontSize:'11px',
  },
  primary:{
    fontFamily: '\'Verlag SSm 3r\'',
    fontSize:'13px',
    paddingLeft:'10px'
  }
};
  
