import React, {useReducer} from 'react'
import {
  ACCOUNT_SEARCH_TYPE,
  FETCH_APPROVAL_REASONS,
  FETCH_USER,
  RESET,
  SELECT_CUSTOMER,
  SELECT_CUSTOMERS,
  SELECT_EAM_CUSTOMERS,
  SELECT_EAM_PORTFOLIOS_COPY,
  SELECT_PORTFOLIOS,
  SET_DASHBOARD_FLAG,
  SET_LOADING_INDICATOR,
  SET_RM_PDB_DATA,
  CURR_PRICING_REQUEST,
  SET_PENDING_REQUEST_FLAG,
  SET_GROUPING_DATA
} from './common/actionTypes';
import PropTypes from 'prop-types';
import {DEFAULT_BOOKING_CENTER, USER_ID, X_TENANT_ID} from './common/headers';
import {getBookingCenterDescriptionFromCode} from './common/enums/bookingCenter';

export const Store = React.createContext();

const initialState = {
  user: null,
  customer: null,
  customers: null,
  eamCustomers: null,
  portfolios: null,
  approvalReasons: null,
  accountSearchType: null,
  eamPortfoliosToCopy: null,
  isLoading: false,
  isFromDashboard: false,
  isFromPendingRequestApproval: false,
  pdbMarker: null,
  groupingdata: null
};

function reducer(state, action) {
  switch (action.type) {
    case FETCH_USER:
      sessionStorage.setItem(USER_ID, action.payload.id);
      return {...state, user: action.payload};
    case SELECT_CUSTOMER: {
      const customer = action.payload;
      sessionStorage.setItem(X_TENANT_ID, customer?.bookingCenter ? customer.bookingCenter : getBookingCenterDescriptionFromCode(DEFAULT_BOOKING_CENTER));
      return {...state, customer: customer};
    }
    case SELECT_CUSTOMERS: {
      return {...state, customers: action.payload}
    }
    case SELECT_EAM_CUSTOMERS: {
      return {...state, eamCustomers: action.payload}
    }
    case SELECT_PORTFOLIOS:
      return {...state, portfolios: action.payload};
    case RESET:
      sessionStorage.removeItem(X_TENANT_ID);
      return {...state, portfolios: null, customer: null, isFromDashboard: false, isFromPendingRequestApproval: false};
    case FETCH_APPROVAL_REASONS:
      return {...state, approvalReasons: action.payload};
    case ACCOUNT_SEARCH_TYPE:
      return {...state, accountSearchType: action.payload}
    case SELECT_EAM_PORTFOLIOS_COPY:
      return {...state, eamPortfoliosToCopy: action.payload}
    case SET_LOADING_INDICATOR:
      return {...state, isLoading: action.payload}
    case SET_DASHBOARD_FLAG:
      return {...state, isFromDashboard: action.payload}  
    case SET_RM_PDB_DATA:
      return {...state, pdbMarker: action.payload}
    case CURR_PRICING_REQUEST:
      return {...state, pricingRequestId: action.payload}
    case SET_PENDING_REQUEST_FLAG:
      return {...state, isFromPendingRequestApproval: action.payload}
    case SET_GROUPING_DATA:
      return {...state, groupingdata: action.payload}
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [parentState, parentDispatch] = useReducer(reducer, props.customState ? props.customState : initialState, undefined);
  const value = {parentState, parentDispatch};
  return <Store.Provider value={value}>{props.children}</Store.Provider>
}


// --- PropTypes

StoreProvider.propTypes = {
  children: PropTypes.any.isRequired,
  customState: PropTypes.object
};
