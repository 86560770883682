import React from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {withTheme} from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(0.5),
    marginBottom: '10px',
    borderWidth: '1px 1px'
  },
  icon: {
    position: 'relative',
    top: '6px',
    left: '3px',
    fontSize: '8px',
    marginRight: '8px'
  },
  text: {
    textTransform: 'uppercase',
    fontWeight: 'normal',
    display: 'inline',
    marginRight: '10px',
    fontFamily: '\'Verlag SSm 7r\'',
    fontSize: '0.8125rem'
  },
  textAfter: {
    fontWeight: 'normal',
    fontFamily: '\'Verlag SSm 7r\'',
    fontSize: '0.8125rem'
  }
}));

const DefaultBoxHeader = ({children, icon, text, textAfter}) => {
  const classes = useStyles();
  return (
    <Box
      borderBottom={1}
      borderLeft={1}
      borderwidth={2}
      className={classes.box}
    >
      <div className={classes.text}>
        {icon && <span className={classes.icon}>{icon}</span>}
        {text}
      </div>
      <span className={classes.textAfter}>{textAfter && `(${textAfter})`}</span>
      {children}
    </Box>
  );
};

DefaultBoxHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.arrayOf(PropTypes.object).isRequired
  ]),
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  textAfter: PropTypes.string
};

const BoxHeader = withTheme(DefaultBoxHeader);

export default BoxHeader;

