import React from 'react';
import {makeStyles} from '@material-ui/styles';
import PeerComparisonHeatMap from './PeerComparisonHeatMap';
import {
  SIDEBAR_BACKGROUND_COLOR,
  TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_BACKGROUND_COLOR
} from '../../../../../theme/common/color';
import PropTypes from 'prop-types';


const useStyles = makeStyles(() => ({
  title: {
    color: SIDEBAR_BACKGROUND_COLOR,
    fontSize: '0.9rem',
    fontWeight: 'bold',
    paddingTop: '5px'
  },
  peerHeader: {
    color: SIDEBAR_BACKGROUND_COLOR,
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    paddingLeft:'0px',
  },
  subTitle: {
    color: TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_BACKGROUND_COLOR,
    fontSize: '0.7125rem',
    fontWeight: 'normal',
    variant: 'subtitle1',
    borderBottom: '0px',
  },
  subTitleRight: {
    color: TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_BACKGROUND_COLOR,
    fontSize: '0.7125rem',
    fontWeight: 'normal',
    borderBottom: '0px',
    float:'right'
  },
  noBorders: {
    borderBottom: '0px'
  },
  noPeerPricing: {
    padding: '4px',
    whiteSpace: 'pre',
    color: 'grey',
    textAlign: 'center'
  }
}));

const PeerComparison = ({revenue}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.title}>Peer Comparison of Average Discount
        <span className={classes.subTitleRight}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Data as of: {revenue?.pdbData?.keyDateStr}</span>
        <div className={classes.subTitle}>Discount Compared to List Price</div>
      </div>
      <div className={classes.peerHeader}>
        { (revenue?.pdbData !== undefined && revenue?.pdbData && revenue?.pdbData?.errors?.length === 0) && <PeerComparisonHeatMap peerData={revenue?.pdbData}/>}
        {(revenue?.pdbData === undefined || revenue?.pdbData?.errors?.length > 0 ) && <div className={classes.noPeerPricing}>
          No Peer Pricing
          {'\n'}information available</div> }
      </div>
    </div>
  );
};

const pdbDataPropTypes = PropTypes.shape({
  keyDateStr: PropTypes.string,
  KeyDate: PropTypes.object,
  rangePoints: PropTypes.arrayOf(PropTypes.number),
  errors: PropTypes.arrayOf(PropTypes.string),
  oldDiscMarker: PropTypes.object,
  newDiscMarker: PropTypes.object
});

PeerComparison.propTypes = {
  revenue: PropTypes.shape({
    pdbData: pdbDataPropTypes,
  }),
};

export default PeerComparison;