import React from 'react';
import PropTypes from 'prop-types';
import {
  LABEL_SUMMARY,
  NEW_CONDITIONS_SUMMARY,
  OLD_CONDITIONS_SUMMARY,
  TEXT_LEFT
} from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import PriceConditionTable from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import {OLD_CONDITION_CUSTOM_STYLE, SUMMARY_TABLE_CUSTOM_STYLE} from '../../../../common/constants';
import CommonBILARows from '../CommonBILARows';
import {getPriceTypeFromNMBFeeCondition} from '../../../../common/enums/nmbFeeCondition';
import {getUnequalFieldsInTransactionFees} from './transactionFeesEqualityUtil';
import {useTransactionFeesStyles} from './useTransactionFeesStyles';
import PriceConditionTableRowSection from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {createSectionTitle, createSubLevelTitle} from '../priceSummaryCommonUtil';
import TransactionFeeTextRow from './components/TransactionFeeTextRow';
import TransactionFeeRow from './components/TransactionFeeRow';
import {
  getNMBFeeFundTypeDescriptionFromCode,
  getNMBFeeFundTypeFromDescription
} from '../../../../common/enums/nmbFeeFundType';
import {CLIPP_BLACK} from '../../../../theme/common/bjbColor';
import {isEAMorEFA} from '../../../../common/enums/serviceModel';

/* eslint-disable */

const TransactionFeesOTCBondsTable = ({ transactionFees, serviceModel, agentType, showDiff }) => {
  const classes = useTransactionFeesStyles();
  const transactionAssetTypes = transactionFees?.transactionAssetTypes;

  const transactionAssetTypesComponents = [];
  Object.entries(transactionAssetTypes).map(([key, value]) => {
    const subAssetTypesComponents = [];
    for (let props of value) {
      if(showDiff){
        const unequalFields = getUnequalFieldsInTransactionFees(props.currentCondition, props.newCondition, transactionFees?.oldPricingExists);
        if(unequalFields.length === 0){
          continue;
        }
      }

      if(props.nmbParentAssetType){
        const parent = subAssetTypesComponents.find(subAsset=>subAsset.parentSubLevel === props.nmbParentAssetType?.code);
        if(parent){
          parent.children?.push({
            assetSubLevel: props.transactionAssetSubLevel.desc,
            newCondition: props.newCondition,
            currentCondition: props.currentCondition
          });
        } else {
          subAssetTypesComponents.push({
            parentSubLevel: props.nmbParentAssetType.code,
            parentDesc: props.nmbParentAssetType.desc,
            children: [{
              assetSubLevel: props.transactionAssetSubLevel.desc,
              newCondition: props.newCondition,
              currentCondition: props.currentCondition
            }]
          });
        }
      } else {
        subAssetTypesComponents.push({
          // eslint-disable-next-line react/prop-types
          assetSubLevel: props.transactionAssetSubLevel.desc,
          // eslint-disable-next-line react/prop-types
          newCondition: props.newCondition,
          // eslint-disable-next-line react/prop-types
          currentCondition: props.currentCondition
        });
      }
    }
    if (subAssetTypesComponents.length > 0) {
      transactionAssetTypesComponents.push({
        assetType: getNMBFeeFundTypeDescriptionFromCode(key),
        assetSublevels: [...subAssetTypesComponents]
      });
    }
  });

  if (transactionAssetTypesComponents.length > 0) {
    transactionAssetTypesComponents.sort((firstEl, secondEl) => {
      const order1 = getNMBFeeFundTypeFromDescription(firstEl?.assetType).sortOrder;
      const order2 = getNMBFeeFundTypeFromDescription(secondEl?.assetType).sortOrder;
      if (order1 < order2) {
        return -1;
      } else if (order1 > order2) {
        return 1;
      }
      return 0;
    });
  }
  return (<>
    <PriceConditionTable>
      <PriceConditionTableRowSection data={[]}>
        <PriceConditionTableColumn field="label" label="Transaction Fees OTC Products" type={LABEL_SUMMARY}/>
        {transactionFees?.oldPricingExists && (
          <PriceConditionTableColumn
            customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
            label="Old Conditions"
            type={OLD_CONDITIONS_SUMMARY}
          />
        )}
        <PriceConditionTableColumn
          customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
          isHighlightedFieldValueAccessor="newCondition.isHighlighted"
          label="New Conditions"
          type={NEW_CONDITIONS_SUMMARY}
        />
      </PriceConditionTableRowSection>
      {transactionAssetTypesComponents.map(parent => (<>
        <PriceConditionTableRowSection data={[]}>
          <PriceConditionTableColumn
            customStyles={OLD_CONDITION_CUSTOM_STYLE}
            field="label"
            label={createSectionTitle(parent.assetType, classes.sectionTitle)}
            type={TEXT_LEFT}
          />
          {transactionFees?.oldPricingExists && (
            <PriceConditionTableColumn
              customStyles={OLD_CONDITION_CUSTOM_STYLE}
              type={TEXT_LEFT}
            />
          )}
          <PriceConditionTableColumn
            customStyles={OLD_CONDITION_CUSTOM_STYLE}
            type={TEXT_LEFT}
          />
        </PriceConditionTableRowSection>
        {parent.assetSublevels?.map(child => (
          <>{!child.parentSubLevel && <SubLevel child={child} showDiff={showDiff} serviceModel={serviceModel} agentType={agentType} transactionFees={transactionFees}/>}
            {child.parentSubLevel && <>
              <PriceConditionTableRowSection data={[]}>
                <PriceConditionTableColumn
                  customStyles={OLD_CONDITION_CUSTOM_STYLE}
                  field="label"
                  label={createSubLevelTitle(child.parentDesc)}
                  type={TEXT_LEFT}
                />
                {transactionFees?.oldPricingExists && (
                  <PriceConditionTableColumn
                    customStyles={OLD_CONDITION_CUSTOM_STYLE}
                    type={TEXT_LEFT}
                  />
                )}
                <PriceConditionTableColumn
                  customStyles={OLD_CONDITION_CUSTOM_STYLE}
                  type={TEXT_LEFT}
                />
              </PriceConditionTableRowSection>
              {child.children?.map(subLevelChild => <SubLevel child={subLevelChild} showDiff={showDiff} italic transactionFees={transactionFees}/>)}
            </>
            }
          </>
        ))}</>
      ))}
    </PriceConditionTable>
  </>);
};

const SubLevel = ({agentType, serviceModel, transactionFees, child, italic, showDiff}) => {
  return (
    <>
      <PriceConditionTableRowSection data={[]}>
        <PriceConditionTableColumn
          customStyles={OLD_CONDITION_CUSTOM_STYLE}
          data-testid={`test-${child?.assetSubLevel}-label`}
          field="label"
          label={createSubLevelTitle(child.assetSubLevel, italic? CLIPP_BLACK: null)}
          style={{fontStyle: italic? 'italic': 'none'}}
          type={TEXT_LEFT}
        />
        {transactionFees?.oldPricingExists && (
          <PriceConditionTableColumn
            customStyles={OLD_CONDITION_CUSTOM_STYLE}
            type={TEXT_LEFT}
          />
        )}
        <PriceConditionTableColumn
          customStyles={OLD_CONDITION_CUSTOM_STYLE}
          type={TEXT_LEFT}
        />
      </PriceConditionTableRowSection>
      <CommonBILARows
        hideBILA={isEAMorEFA(serviceModel, agentType)}
        newAcknowledge={child?.newCondition ? child.newCondition?.acknowledged : null}
        newAdditionalInstructions={child?.newCondition ? child.newCondition?.additionalInstructions : null}
        newAgreementType={child?.newCondition?.agreementEnum ? child?.newCondition?.agreementEnum : null}
        oldAcknowledge={child?.currentCondition ? child.currentCondition?.acknowledged : null}
        oldAdditionalInstructions={child?.currentCondition ? child?.currentCondition?.additionalInstructions : null}
        oldAgreementType={child?.currentCondition?.agreementEnum ? child?.currentCondition?.agreementEnum : null}
        secondLevel
        showDiff={showDiff}
        showOld={!!transactionFees?.oldPricingExists}
        testId={'test-otc-bonds-products-fees-summary'}
      />
      {(child?.newCondition?.transactionFeeCondition || child?.currentCondition?.transactionFeeCondition) && child &&
      <TransactionFeeTextRow
        label={'Fee Condition'}
        newValue={child?.newCondition?.transactionFeeCondition?.desc}
        oldValue={child?.currentCondition?.transactionFeeCondition?.desc}
        secondLevel
        showDiff={showDiff}
        showOld={transactionFees.oldPricingExists}
        testId={`test-${child.assetSubLevel}-condition`}
      />
      }
      {(child?.newCondition?.pricingType || child?.currentCondition?.pricingType) && child &&
      <TransactionFeeTextRow
        label={'Pricing Type'}
        newValue={child?.newCondition?.pricingType?.desc}
        oldValue={child?.currentCondition?.pricingType?.desc}
        secondLevel
        showDiff={showDiff}
        showOld={transactionFees.oldPricingExists}
        testId={`test-${child.assetSubLevel}-condition`}
      />
      }
      {(child?.newCondition?.calculationBasis || child?.currentCondition?.calculationBasis) && child &&
      <TransactionFeeTextRow
        label={'Calculation Basis'}
        newValue={child?.newCondition?.calculationBasis?.desc}
        oldValue={child?.currentCondition?.calculationBasis?.desc}
        secondLevel
        showDiff={showDiff}
        showOld={transactionFees.oldPricingExists}
        testId={`test-${child.assetSubLevel}-condition`}
      />
      }
      {(child?.newCondition?.feePerTransaction || child?.currentCondition?.feePerTransaction) &&
      <TransactionFeeRow
        label={'Fee per transaction'}
        newValue={child?.newCondition?.feePerTransaction?.effectivePrice}
        oldValue={child?.currentCondition?.feePerTransaction?.effectivePrice}
        secondLevel
        showDiff={showDiff}
        showOld={transactionFees.oldPricingExists}
        testId={`test-${child.assetSubLevel}-feePerTransaction`}
        type={getPriceTypeFromNMBFeeCondition(child?.newCondition?.transactionFeeCondition?.code)}
      />}
    </>
  )
}

TransactionFeesOTCBondsTable.propTypes = {
  agentType: PropTypes.string,
  serviceModel: PropTypes.string,
  showDiff: PropTypes.bool,
  transactionFees: PropTypes.object
};

export default TransactionFeesOTCBondsTable;
