import React from 'react';
import {CHF} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {FIXED_AMOUNT} from '../../../../../common/enums/recurringFeeCondition';
import {isNullOrUndefined} from '../../../../../common/isNullOrUndefined';
import {isNewRecurringFeeDifferent} from '../isNewRecurringFeeDifferent';

const LABEL = 'Annual Fee';
const KEY_PREFIX = 'recurring-fees-annual-fee';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const annualFee = ({handleValidationResultChange, handleValueChange, readOnly, recurringFees, showDiff}) => {

  let result = {
    label: LABEL
  };
  let isNewValueDifferent = !!readOnly && isNewRecurringFeeDifferent(recurringFees, 'annualFee');

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (!isNullOrUndefined(recurringFees?.currentCondition?.annualFee)) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(recurringFees?.currentCondition?.annualFee, recurringFees?.currentCondition?.condition?.code)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(recurringFees?.newCondition?.annualFee, recurringFees?.newCondition?.condition?.code, handleValidationResultChange, handleValueChange, readOnly, isNewValueDifferent)
  }

};

const getMappedOldCondition = (annualFee, conditionCode) => {
  if (conditionCode !== FIXED_AMOUNT.code) {
    return null;
  }

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={CHF}
      value={annualFee}
    />);
  return {
    value: readonlyField
  };
};

const getMappedNewCondition = (annualFee, conditionCode, handleValidationResultChange, handleValueChange, readOnly, isNewValueDifferent) => {
  if (conditionCode !== FIXED_AMOUNT.code) {
    return null;
  }

  const editableField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      isEditable
      key={`${KEY_PREFIX}-editable`}
      onValidationResultChange={(vr) => handleValidationResultChange({...vr, selectors: ['annualFee']})}
      onValueChange={(vr) => handleValueChange({...vr, selectors: ['annualFee']})}
      type={CHF}
      value={annualFee}
    />);

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={CHF}
      value={annualFee}
    />);

  return {
    value: readOnly ? readonlyField : editableField,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
  };
};
