import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const ExpandingCell = ({cell, row, strikeThrough}) => {
  return (

    <span {...row?.getToggleRowExpandedProps()} style={{textDecoration: strikeThrough ? 'line-through' : null}}>
      {row.isExpanded ? (
        <React.Fragment>
          <ExpandLessIcon/>
          {cell?.value}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ExpandMoreIcon/>
          {cell?.value}
        </React.Fragment>
      )}
    </span>
  );
};

ExpandingCell.propTypes = {
  cell: PropTypes.object,
  row: PropTypes.object,
  strikeThrough: PropTypes.bool
}

export default ExpandingCell;
