import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Card, Checkbox, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {BoxHeader} from '../../../../../Common/components';
import {AccountSearch, PortfolioListTable} from '../index';
import {Store} from '../../../../../../Store';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {useNotification} from '../../../../../Common/components/Notification';
import api from '../../../../../../api/api';
import {
  SELECT_CUSTOMER,
  SELECT_CUSTOMERS,
  SELECT_EAM_CUSTOMERS,
  SELECT_EAM_PORTFOLIOS_COPY,
  SELECT_PORTFOLIOS,
  SET_DASHBOARD_FLAG
} from '../../../../../../common/actionTypes';
import {DEFAULT_COLOR} from '../../../../../../theme/common/color';
import {CLIPP_WHITE} from '../../../../../../theme/common/bjbColor';
import Authorized from '../../../../../../components/Authorized';
import {getRequesterRoles} from '../../../../../../common/enums/userRole';
import ServiceModelChangeDialog from '../../../../../Common/components/ServiceModelChangeDialog';
import {getPortfoliosWithPortfolioDetails} from '../../../../../../common/getPortfoliosWithPortfolioDetails';
import AsyncButton from '../../../../../Common/components/AsyncButton';
import AccountTypeRadioGroup from './components/AccountTypeRadioGroup';
import EamAccountSearch from './components/EamAccountSearch';
import {sortBy} from 'underscore';
import {ACCOUNT_NUMBER, EAM_AGENT_CODE} from '../../../../../../common/enums/accountSearchType';
import {getErrorMessage} from '../../../../../../common/getErrorMessage';
import ReportButton from './components/ReportButton';
import {setIsLoading} from '../../../../../../Services';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  tabpanel: {
    height: '100%',
    backgroundColor: CLIPP_WHITE,
    padding: '0px',
    borderBottom: '0px solid #e3e5e5',
    borderRight: '0px solid #e3e5e5',
    borderLeft: '0px solid #e3e5e5'
  },
  pageHeader: {
    fontSize: '28px',
    lineHeight: '43px',
    color: '#141e55',
    fontFamily: 'Verlag Office,sans-serif',
    fontWeight: '300',
    textTransform: 'uppercase'
  },
  pageSubHeader: {
    fontSize: '14px',
    lineHeight: '43px',
    color: DEFAULT_COLOR,
    display: 'inline',
    fontFamily: '\'Verlag SSm 7r\'',
    textTransform: 'uppercase'
  },
  paperComponentTitle: {
    padding: theme.spacing(0.5),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    borderRadius: '0px',
    backgroundColor: '#6672b8'
  },
  componentTitle: {
    fontSize: '18px',
    lineHeight: '24px',
    color: '#3343a1',
    fontFamily: 'Verlag Office,sans-serif',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  table: {
    border: '1px solid #CCCCCC',
  },
  tableCell: {
    fontSize: '12px',
    fontFamily: 'Verlag SSm 5r',
    backgroundColor: '#efefef',
    fontWeight: 'normal'
  },
  tableHeader: {
    fontSize: '12px',
    fontFamily: 'Verlag SSm 5r',
    color: 'f00',
  },
  card: {}
}));

const AccountTab = () => {
  const classes = useStyles();
  const [canProceed, setCanProceed] = useState(false);
  const [portfolios, setPortfolios] = useState([]);
  const [openServiceModelDialog, setOpenServiceModelDialog] = useState(false);
  const [reportUrls, setReportUrls] = useState();
  const [invalidPortfolios, setInvalidPortfolios] = useState([]);
  const {parentState, parentDispatch} = useContext(Store);
  const history = useHistory();
  const notification = useNotification();

  const checkCanProceed = portfolios => {
    if (!portfolios) {
      return false;
    }
    const total = portfolios.filter(portfolio => !portfolio.requestInProgress);
    setCanProceed(total.length > 0);
  };

  useEffect(() => {
    api.report.getUrl().then(res => {
      setReportUrls(res.data);
    })
      .catch(err => {
        notification.error('Failed getting report URL\n' + getErrorMessage(err))
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Fetch data when search type is changed
    const getCustomers = async () => {
      try {
        if (parentState.accountSearchType === ACCOUNT_NUMBER) {
          if (!parentState.customers) {
            let payload = await api.customer.get();

            if (payload?.data) {
              let sortedAccounts = payload.data;
              payload.data.forEach(
                account => (account.portfolios = sortBy(account.portfolios, 'portfolioNumber'))
              );
              parentDispatch({type: SELECT_CUSTOMERS, payload: sortBy(payload.data, sortedAccounts)});
            }
          }
        } else if (parentState.accountSearchType === EAM_AGENT_CODE) {
          if (!parentState.eamCustomers) {
            let payload = await api.customer.getEam();

            if (payload?.data) {
              let sortedAccounts = payload.data;
              payload.data.forEach(
                account => (account.portfolios = sortBy(account.portfolios, 'portfolioNumber'))
              );

              parentDispatch({type: SELECT_EAM_CUSTOMERS, payload: sortBy(payload.data, sortedAccounts)});
            }
          }
        }
      } catch (err) {
        notification.error('Failed getting portfolios\n' + getErrorMessage(err));
      }
    };

    parentDispatch({type: SELECT_CUSTOMER, payload: null});
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentState.accountSearchType]);

  const fetchPricingRequest = useCallback(async () => {
    if (parentState.customer?.portfolios) {
      try {
        const response = await api.pricingRequest.latest(parentState.customer.portfolios.map(portfolio => portfolio.portfolioNumber));
        const accountPortfolios = parentState.customer.portfolios;
        const mergedPortfolios = accountPortfolios.map(portfolio => {
          const account = response.data.find(
            priceRequest => portfolio.portfolioNumber === priceRequest.portfolioNumber
          );
          return {...portfolio, ...account};
        });
        setPortfolios(mergedPortfolios);
        checkCanProceed(mergedPortfolios);
      } catch (err) {
        notification.error(
          'Internal Server Error: Unable to retrieve pricing requests.\n' + getErrorMessage(err)
        );
      }
    }
    // eslint-disable-next-line
  }, [parentState.customer, parentState.user]);

  useEffect(() => {
    // Fetch pricing request if customer is selected
    if (parentState.customer) {
      fetchPricingRequest();
    }
  }, [parentState.customer, parentState.accountSearchType, fetchPricingRequest]);

  const validate = () => {
    return new Promise((asyncBtnResolve, asyncBtnReject) => {
      if (!parentState.portfolios || parentState.portfolios.length === 0) {
        notification.error('Please select a portfolio');
        asyncBtnResolve();
      } else {
        api.pricingRequest
          .validateServiceModel(parentState.portfolios)
          .then(response => {
            if (response.data) {
              const invalidPortfolios = response.data
                ?.filter(data => data.serviceModelChanged)
                .map(filteredData => ({
                  portfolioNumber: filteredData.portfolioNumber,
                  newServiceModel: filteredData.newServiceModel,
                  newMandateType: filteredData.newMandateType
                }));

              if (invalidPortfolios?.length > 0) {
                setInvalidPortfolios(invalidPortfolios);
                setOpenServiceModelDialog(true);
                return Promise.resolve();
              } else {
                return handleContinueProceed(asyncBtnResolve);
              }
            } else {
              return Promise.reject({response: {data: {message: 'No data received'}}});
            }
          })
          .then(() => {
            asyncBtnResolve();
          })
          .catch(err => {
            notification.error(getErrorMessage(err));
            asyncBtnReject();
          });
      }
    });
  };

  const handleContinueProceed = () => {
    return new Promise((asyncBtnResolve, asyncBtnReject) => {
      const portfoliosToInitiate = parentState.portfolios.map(portfolio => {
        const portfolioWithEamCode = {...portfolio, eamCode: parentState.customer.agentCode};
        const invalidPortfolio = invalidPortfolios.find(d => d.portfolioNumber === portfolioWithEamCode.portfolioNumber);
        if (invalidPortfolio) {
          return {
            ...portfolioWithEamCode,
            serviceModelChanged: true,
            serviceModel: invalidPortfolio.newServiceModel,
            mandateType: invalidPortfolio.newMandateType
          };
        }
        return portfolioWithEamCode;
      });

      setIsLoading(true, parentDispatch);
      api.pricingRequest
        .initiate(portfoliosToInitiate)
        .then(response => {
          const responseDataWithPortfolioDetails = getPortfoliosWithPortfolioDetails(
            response.data,
            parentState.portfolios
          );
          parentDispatch({type: SELECT_PORTFOLIOS, payload: responseDataWithPortfolioDetails});
          if (parentState.accountSearchType === EAM_AGENT_CODE) {
            parentDispatch({type: SELECT_EAM_PORTFOLIOS_COPY, payload: portfolios});
          }
          parentDispatch({type: SET_DASHBOARD_FLAG, payload: true});
          history.push('/simulation/' + responseDataWithPortfolioDetails[0].pricingRequestId);
          asyncBtnResolve();
        })
        .catch(err => {
          notification.error(getErrorMessage(err));
          setIsLoading(false, parentDispatch);
          asyncBtnReject({response: {data: {message: getErrorMessage(err)}}});
        });
    });
  };

  return (
    <div>
      <ServiceModelChangeDialog
        onClose={() => setOpenServiceModelDialog(false)}
        onContinue={handleContinueProceed}
        open={openServiceModelDialog}
        text={`Your pricing request for ${invalidPortfolios.map(d => d.portfolioNumber).join(
          ', '
        )} is no longer valid, as service model / mandate type has been changed in CRM.`}
      />
      <Paper
        data-testid="test-tab-content-new"
        elevation={1}
        style={{
          padding: '10px'
        }}
        variant="elevation"
      >
        <BoxHeader text={'Account Search'}/>
        <AccountTypeRadioGroup/>
        {parentState.accountSearchType === ACCOUNT_NUMBER && (
          <AccountSearch/>
        )}
        {parentState.accountSearchType === EAM_AGENT_CODE && (
          <EamAccountSearch/>
        )}
        <div style={{lineHeight: '10px'}}>&nbsp;</div>
        <div className={classes.pageSubHeader}>Portfolios</div>
        {parentState.customer ? (
          <PortfolioListTable className={classes.card} portfolios={portfolios}/>
        ) : (
          <Card className={classes.root}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} padding="checkbox">
                    <Checkbox disabled/>
                  </TableCell>
                  <TableCell className={classes.tableCell}>Portfolio Number</TableCell>
                  <TableCell className={classes.tableCell}>Portfolio Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography style={{color: '#CCCCCC'}} variant="h6">
                      Please select a account number to view the portfolios
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        )}
        <div
          style={{
            paddingTop: '10px',
            textAlign: 'right'
          }}
        >
          <Authorized allowedRoles={getRequesterRoles()}>
            <ReportButton
              bookingCenter={parentState.customer?.bookingCenter}
              customerAccountNumber={parentState.customer?.accountNumber}
              disabled={!reportUrls || !parentState.customer?.accountNumber}
              reportUrls={reportUrls}
            />
            <AsyncButton
              color="primary"
              data-testid="test-proceed"
              disabled={!canProceed}
              onClick={validate}
              text="Proceed"
            />
          </Authorized>
        </div>
      </Paper>
    </div>
  );
};

export default AccountTab;
