import {BPS, PERCENTAGE, REGULAR} from '../../views/Common/components/PriceNumberFormat/PriceNumberFormat';

export const FLAT_RATE_PCT = Object.freeze({
  code: 'FLAT_RATE_PCT',
  desc: 'Flat rate in %'
});

export const FLAT_RATE_BPS = Object.freeze({
  code: 'FLAT_RATE_BPS',
  desc: 'Flat rate in bps'
});

export const getNMBFeeConditionDescriptionFromCode = (code) => {
  return getAllNMBFeeConditions()
    .filter(condition => condition.code === code)
    .map(condition => condition.desc)[0];
}

export const getAllNMBFeeConditions = () => {
  return [
    FLAT_RATE_PCT,
    FLAT_RATE_BPS
  ];
}

export const getConditionSelectValues = (condition) => {
  if(condition === FLAT_RATE_BPS.code){
    return [{'key': FLAT_RATE_BPS.code, 'value': FLAT_RATE_BPS.desc}]
  }
  if(condition === FLAT_RATE_PCT.code){
    return [{'key': FLAT_RATE_PCT.code, 'value': FLAT_RATE_PCT.desc}]
  }
  return getAllNMBFeeConditions().map(p => ({'key': p.code, 'value': p.desc}))
}

export const getPriceTypeFromNMBFeeCondition  = (condition) => {
  if(condition === FLAT_RATE_BPS.code){
    return BPS;
  }
  if(condition === FLAT_RATE_PCT.code) {
    return PERCENTAGE;
  }
  return  REGULAR;
}
