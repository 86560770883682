import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';

const TabPanel = (props) => {
  const {children, value, index, className, ...other} = props;
  return (
    <Typography
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className={className}
      component="div"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

