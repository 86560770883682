export const CLIENT_AGREEMENT_GEN_ID_COLUMN = {key: 'clientAgreementGenStatusId', label: 'ID'};
export const RUN_DATE_COLUMN = {key: 'runDate', label: 'Run Date'};
export const LETTER_TYPE_COLUMN = {key: 'letterType', label: 'Letter Type'};
export const PORTFOLIO_NUMBER_COLUMN = {key: 'portfolioNumber', label: 'Portfolio Number'};
export const STATUS_COLUMN = {key: 'status', label: 'Status'};
export const FILE_LOCATION_COLUMN = {key: 'fileLocation', label: 'File Location'};
export const LOG_INFO_COLUMN = {key: 'logInfo', label: 'Log Info'};
export const REQUEST_BODY_COLUMN = {key: 'requestBody', label: 'Request Body'};
export const CREATED_DATE_COLUMN = {key: 'createdDate', label: 'Created Date'};
export const UPDATED_DATE_COLUMN = {key: 'updatedDate', label: 'Update Date'};

export const columnsData = [
  CLIENT_AGREEMENT_GEN_ID_COLUMN,
  RUN_DATE_COLUMN,
  LETTER_TYPE_COLUMN,
  PORTFOLIO_NUMBER_COLUMN,
  STATUS_COLUMN,
  FILE_LOCATION_COLUMN,
  REQUEST_BODY_COLUMN,
  LOG_INFO_COLUMN,
  CREATED_DATE_COLUMN,
  UPDATED_DATE_COLUMN,
];
