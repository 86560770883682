import { jbColors } from './jbColors';

const typography = {
  fontFamily: 'Verlag SSm 3r',
  fontSize: 13,
  h1: {
    color: 'inherit',
    fontWeight: 'normal',
    fontSize: '21px',
    lineHeight: 1.48,
    letterSpacing: '0.8px',
    padding: '1%',
    textTransform: 'uppercase',
  },
  h2: {
    color: 'inherit',
    fontWeight: 300,
    fontSize: '27px',
    letterSpacing: '-0.24px',
    lineHeight: '32px',
  },
  h3: {
    color: 'inherit',
    fontWeight: 300,
    fontSize: '24px',
    letterSpacing: '-0.06px',
    lineHeight: '28px',
  },
  h4: {
    color: 'inherit',
    fontWeight: 300,
    fontSize: '16px',
    letterSpacing: '-0.06px',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  h5: {
    color: '#6672B8',
    fontWeight: 'bold',
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
  h6: {
    color: '#6672B8',
    fontWeight: 'bold',
    fontSize: '13px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
  subtitle1: {
    color: 'inherit',
    fontSize: '15px',
    letterSpacing: '-0.05px',
    lineHeight: '25px',
    textTransform: 'uppercase',
  },
  subtitle2: {
    color: jbColors.greenSmoke['80'],
    fontWeight: 400,
    fontSize: '13px',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
  },
  body1: {
    color: '#6672B8',
    fontSize: '13px',
    lineHeight: 1.62,
    letterSpacing: '0.4px',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
  body2: {
    color: 'inherit',
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: '18px',
  },
  button: {
    color: jbColors.reflexBlue[100],
    fontSize: '14px',
  },
  caption: {
    color: jbColors.greenSmoke[100],
    fontSize: '11px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
};

export default typography;
