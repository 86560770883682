import React from 'react';
import {BPS, CHF} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';

const KEY_PREFIX = 'payment-fees-row-entry';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;
const ZERO = 0;

export const rowEntry = ({fee, feeType, label, isFlatFee}) => {

  let result = {
    label: label
  };

  if (fee?.currentConditionCodeFees && fee.currentConditionCodeFees[feeType]) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(fee?.currentConditionCodeFees[feeType], isFlatFee)
    };
  }

  const newFeeTypeFees = fee?.newConditionCodeFees ? fee.newConditionCodeFees[feeType] : null;

  return {
    ...result,
    newCondition: getMappedNewCondition(newFeeTypeFees, isFlatFee)
  }

};

const getMappedOldCondition = (fee, isFlatFee) => {

  const effectivePrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price`}
      type={isFlatFee ? CHF : BPS}
      value={fee?.effectivePrice}
    />
  );

  const effectiveMinimumFee = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-minimum-fee`}
      type={CHF}
      value={fee?.effectivePrice === ZERO ? 0 : fee?.minimumFee}
    />
  );
  const effectiveMaximumFee = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-maximum-fee`}
      type={CHF}
      value={fee?.effectivePrice === ZERO ? 0 : fee?.maximumFee}
    />
  );


  return {
    effectivePrice,
    effectiveMinimumFee,
    effectiveMaximumFee
  };
};

const getMappedNewCondition = (fee, isFlatFee) => {

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      type={isFlatFee ? CHF : BPS}
      value={fee?.listPrice}
    />
  );
  const listMinimumFee = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-minimum-fee`}
      type={CHF}
      value={fee?.minimumFee}
    />
  );
  const listMaximumFee = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-maximum-fee`}
      type={CHF}
      value={fee?.maximumFee}
    />
  );

  const effectivePrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price`}
      type={isFlatFee ? CHF : BPS}
      value={fee?.effectivePrice}
    />
  );

  const effectiveMinimumFee = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-minimum-fee`}
      type={CHF}
      value={fee?.effectivePrice === ZERO ? 0 : fee?.minimumFee}
    />
  );
  const effectiveMaximumFee = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-maximum-fee`}
      type={CHF}
      value={fee?.effectivePrice === ZERO ? 0 : fee?.maximumFee}
    />
  );

  return {
    effectivePrice,
    effectiveMinimumFee,
    effectiveMaximumFee,
    listPrice,
    listMinimumFee,
    listMaximumFee
  };
};
