import React from 'react';
import {PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {DPM} from '../../../../../common/enums/serviceModel';
import {NON_STANDARD_RESTRICTION, WITHOUT_RESTRICTION} from '../../../../../common/enums/recurringFeeImplementation';
import PriceSelect from '../../../../Common/components/PriceSelect';

const LABEL = '% Discount on Surcharge';
const KEY_PREFIX = 'recurring-fees-overridden-discount-on-surcharge';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const overriddenDiscountOnSurcharge = ({handleValueChange, readOnly, recurringFees, serviceModelCode}) => {
  if (serviceModelCode !== DPM) {
    return null;
  }

  let result = {
    label: LABEL
  };

  if (recurringFees?.currentCondition?.discountOnSurchargeOverridden) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(
        recurringFees?.currentCondition?.discountOnSurchargeOverridden,
        recurringFees?.currentCondition?.implementation?.code
      )
    };
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(
      recurringFees?.newCondition?.discountOnSurchargeOverridden,
      recurringFees?.newCondition?.implementation?.code,
      handleValueChange,
      readOnly
    )
  };
};

const getMappedOldCondition = (discountOnSurchargeOverridden, implementationCode) => {
  if (implementationCode === WITHOUT_RESTRICTION.code) {
    return null;
  }

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={PERCENTAGE}
      value={discountOnSurchargeOverridden}
    />
  );
  return {
    value: readonlyField
  };
};

const getMappedNewCondition = (discountOnSurchargeOverridden, implementationCode, handleValueChange, readOnly) => {
  if (
    implementationCode === WITHOUT_RESTRICTION.code ||
    implementationCode === NON_STANDARD_RESTRICTION.code
  ) {
    return null;
  }

  let overrideDiscountLevelOptions = [];
  for (let i = 0; i <= 100; i++) {
    overrideDiscountLevelOptions.push({
      key: `${i}`,
      value: `${i}%`
    });
  }

  const editableField = (
    <PriceSelect
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      inputProps={{id: 'uncontrolled-native'}}
      key={`${KEY_PREFIX}-editable`}
      onValueChange={vr => handleValueChange({...vr, selectors: ['discountOnSurchargeOverridden']})}
      options={overrideDiscountLevelOptions}
      value={isNaN(discountOnSurchargeOverridden) ? null : discountOnSurchargeOverridden}
    />
  );

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={PERCENTAGE}
      value={isNaN(discountOnSurchargeOverridden) ? null : parseInt(discountOnSurchargeOverridden)}
    />
  );

  return {
    value: readOnly ? readonlyField : editableField,
    isEditable: !readOnly
  };
};
