import React from 'react';
import {useTransactionFeesStyles} from '../useTransactionFeesStyles';
import PropTypes from 'prop-types';
import {createEmpty, createFee, createSecondLevelLabel} from '../../priceSummaryCommonUtil';
import PriceConditionTableRowSection from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {LABEL} from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionType';

const TransactionFeeRow = ({ label, oldValue, newValue, type, secondLevel, testId, showDiff, showOld }) => {
  const classes = useTransactionFeesStyles();

  if(showDiff && oldValue && oldValue === newValue){
    return null;
  }

  return (<PriceConditionTableRowSection data={[]}>
    <PriceConditionTableColumn field="label" label={secondLevel ? createSecondLevelLabel(label,classes.rowTitle) : label} type={LABEL}/>
    {showOld &&
    <PriceConditionTableColumn
      data-testid={`old-${testId}`}
      field="label"
      label={(oldValue || oldValue === 0)? createFee(oldValue, type): createEmpty(classes.normalText)}
    />}
    {!oldValue && !newValue && newValue !== 0 &&
    <PriceConditionTableColumn
      data-testid={testId}
      field="label"
      label={createEmpty(classes.normalText)}
    />}
    {oldValue && !newValue && newValue !== 0 &&
    <PriceConditionTableColumn
      data-testid={testId}
      defaultClassName={showOld && oldValue !== newValue? classes.highlightedTableBodyCell:''}
      field="label"
      label={createEmpty(classes.normalText)}
    />}
    {(newValue || newValue === 0) &&
    <PriceConditionTableColumn
      data-testid={`new-${testId}`}
      defaultClassName={showOld && oldValue !== newValue? classes.highlightedTableBodyCell:''}
      field="label"
      label={createFee(newValue, type)}
    />}
  </PriceConditionTableRowSection>);
};

TransactionFeeRow.propTypes = {
  label: PropTypes.string,
  newValue: PropTypes.string,
  oldValue: PropTypes.string,
  secondLevel: PropTypes.bool,
  showDiff: PropTypes.bool,
  showOld: PropTypes.bool,
  testId: PropTypes.string,
  type: PropTypes.string
};

export default TransactionFeeRow;
