import {isEqual} from 'underscore';

export const isNewBankingFeeDifferent = (bankingFees, key) => {
  if (bankingFees?.currentConditionCodeFees) {
    if (
      !bankingFees.newConditionCodeFees ||
      !isEqual(
        bankingFees.currentConditionCodeFees[key]?.effectivePrice,
        bankingFees.newConditionCodeFees[key]?.effectivePrice
      )
    ) {
      return true;
    }
    return false;
  }
  return false;
};
