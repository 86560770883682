import React from 'react';
import PropTypes from 'prop-types';
import PriceConditionTableColumn from './PriceConditionTableColumn';
import {DISCLAIMER} from './PriceConditionType';
import PriceConditionTableRowSection from './PriceConditionTableRowSection';

const PriceConditionDisclaimerTextRow = ({newConditionDisclaimer, oldConditionDisclaimer, showOldCondition, showNewCondition, testIdSuffix, columnProps}) => {
  if(showNewCondition || showOldCondition) {
    const newConditionDisclaimerJoin = newConditionDisclaimer ? newConditionDisclaimer.join('') : '';
    const oldConditionDisclaimerJoin = oldConditionDisclaimer ? oldConditionDisclaimer.join('') : '';
    return <PriceConditionTableRowSection data={[]}>
      {!showOldCondition && <PriceConditionTableColumn field="label"/>}
      {showOldCondition && <PriceConditionTableColumn colSpan={2} field="label" label={oldConditionDisclaimerJoin} other={{ 'data-testid': `old-condition-${testIdSuffix}` }} type={DISCLAIMER}/>}
      {showNewCondition &&
      <PriceConditionTableColumn
        borderLeft
        colSpan={2}
        field="label"
        label={newConditionDisclaimerJoin}
        other={{ 'data-testid': `old-condition-${testIdSuffix}` }}
        type={DISCLAIMER}
        {...columnProps}
      />}
    </PriceConditionTableRowSection>
  }
  return null;
}

PriceConditionDisclaimerTextRow.propTypes = {
  columnProps: PropTypes.object,
  newConditionDisclaimer: PropTypes.arrayOf(PropTypes.string),
  oldConditionDisclaimer: PropTypes.arrayOf(PropTypes.string),
  showNewCondition: PropTypes.bool,
  showOldCondition: PropTypes.bool,
  testIdSuffix: PropTypes.string
};

export default PriceConditionDisclaimerTextRow;
