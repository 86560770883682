import {UPDATE_FX_PRECIOUS_METALS, UPDATE_FX_PRECIOUS_METALS_VALIDATION_RESULT} from './fxPreciousMetalsAction';
import {LOAD_PRICING_REQUEST_RESPONSE} from '../pricingRequestActions';

export const fxPreciousMetalsReducer = (state, action) => {
  switch (action.type) {
    case LOAD_PRICING_REQUEST_RESPONSE: {
      if (!action.payload.data.nmbFXProducts) {
        return null;
      }

      return {
        ...action.payload.data.nmbFXProducts,
        errors: []
      };
    }
    case UPDATE_FX_PRECIOUS_METALS: {
      return {
        ...state,
        ...action.data,
        errors: action.errors
      };
    }
    case UPDATE_FX_PRECIOUS_METALS_VALIDATION_RESULT: {
      let newErrors = (state.errors || []).filter((e) => e !== action.selector);
      if (!action.isValid) {
        newErrors = [...state.errors, action.selector];
      }
      return {
        ...state,
        errors: newErrors
      };
    }
    default:
      return state;
  }
};
