import api from '../../../../api/api';
import {LOAD_REVENUE_RESPONSE} from './revenueActions';

export const loadRevenue = async (pricingRequestId, dispatch) => {
  const combinedRevenueResponse = await api.pricingRequest.revenue.get(pricingRequestId);

  dispatch({type: LOAD_REVENUE_RESPONSE, data: combinedRevenueResponse.data});

  return combinedRevenueResponse;
};

export const loadRevenue_upd = async (pricingRequestId,revenueRequest,dispatch) => {
  const combinedRevenueResponse = await api.pricingRequest.revenue.postRevenue(pricingRequestId,revenueRequest);

  dispatch({type: LOAD_REVENUE_RESPONSE, data: combinedRevenueResponse.data});

  return combinedRevenueResponse;
};


