import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  table: {
    tableLayout: 'fixed'
  }
}));
const SimpleTaskTable = ({data}) => {
  const classes = useStyles();
  return (<div>
    {data ? data.map(task => <div>
      <h3>Pending {task.pendingRole} approval</h3>
      <div><span><strong>Task ID</strong></span>&nbsp;-&nbsp;{task.taskId}</div>
      <h5>Note: {task.submitterDescription}</h5>
      <Table className={classes.table} size="small">
        <TableBody>
          <TableRow key={'header-main-approver'}>
            <TableCell
              key={'cell-main-approver-key'}
            >
                Main Approver
            </TableCell>
            <TableCell
              key={'cell-main-approver-value'}
            >
              {task.approvers?.MAIN_APPROVER?.map(approver=>approver.userId+(approver.hasACL?'(hasACL)':'(noACL)')).join(', ')}
            </TableCell>
          </TableRow>
          <TableRow key={'header-delegates'}>
            <TableCell
              key={'cell-delegates-key'}
            >
                Delegates
            </TableCell>
            <TableCell
              key={'cell-delegates-value'}
            >
              {task.approvers?.DELEGATES?.map(approver=>approver.userId+(approver.hasACL?'(hasACL)':'(noACL)')).join(', ')}
            </TableCell>
          </TableRow>
          <TableRow key={'header-approval-group'}>
            <TableCell
              key={'cell-approval-group-key'}
            >
                Approval Group
            </TableCell>
            <TableCell
              key={'cell-approval-group-value'}
            >
              {task.approvers?.APPROVAL_GROUP?.map(approver=>approver.userId+(approver.hasACL?'(hasACL)':'(noACL)')).join(', ')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table></div>) :
      <span>No task found for pricing request, refresh this page it might have been already actioned.</span>}
  </div>);
};

SimpleTaskTable.propTypes = {
  data: PropTypes.any
};

export default SimpleTaskTable;
