import React from 'react';
import {PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {DPM} from '../../../../../common/enums/serviceModel';
import {NON_STANDARD_RESTRICTION, WITHOUT_RESTRICTION} from '../../../../../common/enums/recurringFeeImplementation';
import PriceSelect from '../../../../Common/components/PriceSelect';
import {isNullOrUndefined} from '../../../../../common/isNullOrUndefined';
import {isNewRecurringFeeDifferent} from '../isNewRecurringFeeDifferent';

const LABEL = '% Discount on Surcharge';
const KEY_PREFIX = 'recurring-fees-discount-on-surcharge';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const discountOnSurcharge = ({handleValueChange, readOnly, recurringFees, serviceModelCode, showDiff}) => {

  if (serviceModelCode !== DPM) {
    return null;
  }

  let result = {
    label: LABEL
  };
  let isNewValueDifferent = !!readOnly && isNewRecurringFeeDifferent(recurringFees, 'discountOnSurcharge');

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (!isNullOrUndefined(recurringFees?.currentCondition?.discountOnSurcharge)) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(recurringFees?.currentCondition?.discountOnSurcharge, recurringFees?.currentCondition?.implementation?.code)
    };
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(recurringFees?.newCondition?.discountOnSurcharge, recurringFees?.newCondition?.implementation?.code, handleValueChange, readOnly, isNewValueDifferent)
  };

};

const getMappedOldCondition = (discountOnSurcharge, implementationCode) => {
  if (implementationCode === WITHOUT_RESTRICTION.code) {
    return null;
  }

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={PERCENTAGE}
      value={discountOnSurcharge}
    />);
  return {
    value: readonlyField
  };
};

const getMappedNewCondition = (discountOnSurcharge, implementationCode, handleValueChange, readOnly, isNewValueDifferent) => {
  if (implementationCode === WITHOUT_RESTRICTION.code
    || implementationCode === NON_STANDARD_RESTRICTION.code) {
    return {
      value: null,
      isHighlighted: isNewValueDifferent,
    };
  }

  const options = [
    {key: 0, value: '0 %'},
    {key: 25, value: '25 %'},
    {key: 50, value: '50 %'},
    {key: 75, value: '75 %'},
    {key: 100, value: '100 %'}
  ];

  const editableField = (
    <PriceSelect
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      inputProps={{id: 'uncontrolled-native'}}
      key={`${KEY_PREFIX}-editable`}
      onValueChange={(vr) => handleValueChange({...vr, selectors: ['discountOnSurcharge']})}
      options={options}
      value={discountOnSurcharge || 0}
    />
  );

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={PERCENTAGE}
      value={discountOnSurcharge}
    />);

  return {
    value: readOnly ? readonlyField : editableField,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
  };
};
