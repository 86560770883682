import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {BUTTON_PRIMARY_BACKGROUND_COLOR, BUTTON_PRIMARY_COLOR, WARNING_COLOR} from '../../../../../theme/common/color';
import AsyncButton from '../../../../Common/components/AsyncButton';

const useStyles = makeStyles({
  warning: {
    backgroundColor: WARNING_COLOR,
    fontFamily: '\'Verlag SSm 7r\''
  },
  cancel: {
    backgroundColor: BUTTON_PRIMARY_BACKGROUND_COLOR,
    color: BUTTON_PRIMARY_COLOR
  }
});

const Confirmation = ({onClose, onSubmit, open, ...other}) => {
  const classes = useStyles();
  const [ongoing, setOngoing] = useState(false);

  const handleCancel = () => onClose();

  const handleOk = () => {
    return new Promise((asyncBtnResolve, asyncBtnReject) => {
      setOngoing(true);
      onSubmit()
        .then(() => {
          setOngoing(false);
          asyncBtnResolve();
        })
        .catch(() => {
          setOngoing(false);
          asyncBtnReject();
        });
    });
  };

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      className={classes.root}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle
        className={classes.warning}
        disableTypography
        id="confirmation-dialog-title"
      >
        Warning: Are you sure you want to change the Pricing Model?
      </DialogTitle>
      <DialogContent
        className={classes.content}
        dividers
      >
        Changing the pricing model will lose all your changes in all tabs.
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancel}
          color="primary"
          disabled={ongoing}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <AsyncButton color="primary" onClick={handleOk} text="Yes"/>
      </DialogActions>
    </Dialog>
  );
};

Confirmation.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool
};

export default Confirmation;
