import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import PriceConditionTable from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import {nmbFeePerTransaction} from './objectmapper/nmbFeesFeePerTransactionObjectMapper';
import {getPricingTypeDisplayFromCode, getPricingTypeFromCode} from '../../../../../common/enums/pricingType';
import {
  getConditionSelectValues,
  getNMBFeeConditionDescriptionFromCode
} from '../../../../../common/enums/nmbFeeCondition';
import {getOldConditionLabelAgreementType} from '../../../../../common/getOldConditionLabelAgreementType';
import {NMB_FEE_DISCLAIMER} from '../../../../../common/enums/infoTexts';
import {getDisplayTextBasedOnSubType} from '../../../../../common/enums/nmbFeeSubTypes';
import PriceConditionHeaderRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionHeaderRow';
import PriceConditionSelectRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionSelectRow';
import PriceConditionPricingTypeRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionPricingTypeRow';
import PriceConditionCalculationBasisRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionCalculationBasisRow';
import PriceConditionFeeRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionFeeRow';
import PriceConditionDisplayTextRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionDisplayTextRow';
import PriceConditionDisclaimerTextRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionDisclaimerTextRow';
import PriceConditionAcknowledgementRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionAcknowledgementRow';
import {getNMBDeclaration} from '../../../../../common/enums/declaration';

const NMBFeeSubAssetConditionTable = ({agentType, fundTypeCode, handleValidationResultChange, handleValueChange, newConditionCode, oldConditionCode, pricingRequestStatus, readOnly, serviceModel, subFund}) => {
  const properties = {
    fundTypeCode,
    handleValidationResultChange,
    handleValueChange,
    newConditionCode,
    oldConditionCode,
    readOnly,
    subFund
  }


  const feePerTransactionDeps = [fundTypeCode, newConditionCode, subFund.newCondition?.feePerTransaction?.listPrice, subFund.newCondition?.feePerTransaction?.effectivePrice ];

  const oldConditionLabel = getOldConditionLabelAgreementType(subFund.currentCondition?.agreementEnum?.code);

  const nonEmptyEntries = obj => obj?.currentCondition || obj?.newCondition;

  const nmbFeePerTransactionEffectivePriceSection =  [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => nmbFeePerTransaction({...properties, testPrefixId:`test-${subFund.transactionAssetSubLevel.code}-fee-per-transaction`}), [feePerTransactionDeps]),
  ].filter(nonEmptyEntries);

  const disclaimer = NMB_FEE_DISCLAIMER;

  const columnProps = {
    borderRight: !subFund.currentCondition
  }

  const getDisplayText = (condition, isStandard) => {
    if(!condition){
      return '';
    }
    if(condition.pricingType && !isStandard){
      return getPricingTypeDisplayFromCode(condition.pricingType.code);
    }
    if(condition.calculationBasis){
      return condition.calculationBasis.desc;
    }
    return getDisplayTextBasedOnSubType(subFund.transactionAssetSubLevel.code);
  }

  const getTable = () => {
    return (
      <PriceConditionTable width={subFund.currentCondition ? '100%' : '70%'}>
        <PriceConditionHeaderRow newConditionLabel={'NEW CONDITIONS'} oldConditionLabel={oldConditionLabel} showNewCondition={!!subFund.newCondition} showOldCondition={!!subFund.currentCondition}/>
        <PriceConditionPricingTypeRow
          columnProps={columnProps}
          newConditionLabel={'Choose Pricing Type:'}
          newConditionValue={subFund.newCondition?.pricingType?.code}
          oldConditionValue={getPricingTypeFromCode(subFund.currentCondition?.pricingType?.code)?.desc}
          onChange={handleValueChange}
          readOnly={readOnly}
          showNewCondition={!!subFund.newCondition?.pricingType}
          showOldCondition={!!(subFund.currentCondition && subFund.currentCondition.pricingType)}
        />
        <PriceConditionSelectRow
          columnProps={columnProps}
          newConditionLabel={'Fee Condition'}
          newConditionValue={subFund.newCondition?.transactionFeeCondition?.code}
          noBorder={!!subFund.newCondition?.calculationBasis}
          oldConditionValue={getNMBFeeConditionDescriptionFromCode(subFund.currentCondition?.transactionFeeCondition?.code)}
          onChange={handleValueChange}
          options={getConditionSelectValues(subFund.newCondition?.transactionFeeCondition?.code)}
          readOnly={readOnly}
          readOnlyLabel={'Fee Condition:'}
          selectProps={{name: 'feeCondition', id: 'uncontrolled-native'}}
          showNewCondition={!!subFund.newCondition?.transactionFeeCondition}
          showOldCondition={!!subFund.currentCondition?.transactionFeeCondition}
          testId={`test-fee-condition-${subFund.transactionAssetSubLevel.code}-input`}
        />
        <PriceConditionCalculationBasisRow
          columnProps={columnProps}
          newConditionLabel={'Calculation basis:'}
          newConditionValue={subFund.newCondition?.calculationBasis}
          oldConditionValue={subFund.currentCondition?.calculationBasis?.desc}
          onChange={handleValueChange}
          readOnly={readOnly}
          showNewCondition={!!subFund.newCondition?.calculationBasis}
          showOldCondition={!!(subFund.currentCondition && subFund.currentCondition.calculationBasis)}
          testIdSuffix={subFund.transactionAssetSubLevel.code}
        />
        <PriceConditionFeeRow
          columnProps={columnProps}
          data={nmbFeePerTransactionEffectivePriceSection}
          displayRow={!!(subFund.currentCondition?.feePerTransaction || subFund.newCondition?.feePerTransaction)}
          showNewCondition={!!(subFund.newCondition && subFund.newCondition?.feePerTransaction)}
          showOldCondition={!!(subFund.currentCondition && subFund.currentCondition?.feePerTransaction)}
        />
        <PriceConditionDisplayTextRow
          columnProps={columnProps}
          newConditionLabel={getDisplayText(subFund.newCondition, false)}
          oldConditionLabel={getDisplayText(subFund.currentCondition, false)}
          showNewCondition={!!(subFund.newCondition && subFund.newCondition?.feePerTransaction)}
          showOldCondition={!!(subFund.currentCondition && subFund.currentCondition?.feePerTransaction)}
          standardFeeConditionLabel={getDisplayText(subFund.newCondition, true)}
          testIdSuffix={subFund.transactionAssetSubLevel.code}
        />
        <PriceConditionDisclaimerTextRow
          columnProps={columnProps}
          newConditionDisclaimer={[disclaimer]}
          oldConditionDisclaimer={[disclaimer]}
          showNewCondition={!!(subFund.newCondition && subFund.newCondition?.feePerTransaction)}
          showOldCondition={!!(subFund.currentCondition && subFund.currentCondition?.feePerTransaction)}
          testIdSuffix={subFund.transactionAssetSubLevel.code}
        />
        <PriceConditionAcknowledgementRow
          agentType={agentType}
          columnProps={columnProps}
          currentCondition={subFund.currentCondition}
          declarationTxt={getNMBDeclaration(serviceModel, agentType)}
          handleValidationResultChange={handleValidationResultChange}
          handleValueChange={handleValueChange}
          newCondition={subFund.newCondition}
          pricingRequestStatus={pricingRequestStatus}
          readOnly={readOnly}
          serviceModel={serviceModel}
          showNewCondition={!!subFund.newCondition}
          showOldCondition={!!subFund.currentCondition}
          testIdSuffix={subFund.transactionAssetSubLevel.code}
        />
      </PriceConditionTable>
    )
  }

  return getTable();

};

NMBFeeSubAssetConditionTable.propTypes = {
  agentType: PropTypes.string,
  fundTypeCode: PropTypes.string.isRequired,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  newConditionCode: PropTypes.string,
  oldConditionCode: PropTypes.string,
  pricingRequestStatus: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  serviceModel: PropTypes.string.isRequired,
  subFund: PropTypes.object.isRequired
};

export default React.memo(NMBFeeSubAssetConditionTable);
