import React from 'react';
import PropTypes from 'prop-types';
import {Box, Switch} from '@material-ui/core';
import LabelDropdown from '../../../../../Common/components/tableFilter/LabelDropdown';
import LabelTextField from '../../../../../Common/components/tableFilter/LabelTextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FeeComissionTypeFilter = ({feeCommissionTypeIdFilter, handleCommissionTypeIdFilter, handleStatusFilter, handleIncludeInactiveFilter, handleTypeFilter, includeInactiveFilter, selectedStatusFilter, selectedTypeFilter, handleFind}) => {
  const T24_STATUS = {
    NEW: 'NEW',
    EXISTING: 'EXISTING',
    SUBMITTED: 'SUBMITTED',
    CONFIRMED: 'CONFIRMED',
    ERROR: 'ERROR'
  };
  const T24_FEE_TYPE = {
    REPORTING: 'REPORTING',
    ADVISORY: 'ADVISORY',
    MANAGEMENT: 'MANAGEMENT',
    SPMAIL: 'SPMAIL',
    CUSTODY: 'CUSTODY',
    SRVFEE: 'SRVFEE',
    CODEDAC: 'CODEDAC',
    CASHDEP: 'CASHDEP',
    FIDUCAL: 'FIDUCAL',
    CHQISSCOL: 'CHQISSCOL',
    OUTPAY: 'OUTPAY',
    CASHWITHD: 'CASHWITHD',
    BROKERAGE: 'BROKERAGE',
    RETMAIL: 'RETMAIL',
    FIDUFIX: 'FIDUFIX',
    FIDUCIARY: 'FIDUCIARY'
  };

  const t24StatusFilterOptions = Object.entries(T24_STATUS).map(([key, value]) => ({
    key,
    label: value
  }));

  const t24FeeType = Object.entries(T24_FEE_TYPE).map(([key, value]) => ({
    key,
    label: value
  }));

  return (
    <Box alignItems="center" display="flex" marginBottom={2}>
      <LabelTextField
        label="Commission type ID"
        onChange={handleCommissionTypeIdFilter}
        onClear={handleFind}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFind(e);
          }
        }}
        value={feeCommissionTypeIdFilter}
      />
      &nbsp;
      <LabelDropdown
        label="T24 type"
        onChange={handleTypeFilter}
        options={t24FeeType}
        value={selectedTypeFilter}
      />
      &nbsp;
      <LabelDropdown
        label="T24 status"
        onChange={handleStatusFilter}
        options={t24StatusFilterOptions}
        value={selectedStatusFilter}
      />
      <FormControlLabel
        control={
          <Switch
            checked={includeInactiveFilter}
            color="default"
            onChange={handleIncludeInactiveFilter}
          />
        }
        label="Include inactive codes"
      />
    </Box>
  );
};

FeeComissionTypeFilter.propTypes = {
  feeCommissionTypeIdFilter: PropTypes.string,
  handleCommissionTypeIdFilter: PropTypes.func,
  handleFind: PropTypes.func,
  handleIncludeInactiveFilter: PropTypes.func,
  handleStatusFilter: PropTypes.func,
  handleTypeFilter: PropTypes.func,
  includeInactiveFilter: PropTypes.bool,
  selectedStatusFilter: PropTypes.string,
  selectedTypeFilter: PropTypes.string
};

export default FeeComissionTypeFilter;
