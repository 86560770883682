import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {BoxHeader} from '../../../Common/components';
import {PriceRequestStore} from '../../PriceSimulationStore';
import {UPDATE_FX_PRECIOUS_METALS, UPDATE_FX_PRECIOUS_METALS_VALIDATION_RESULT} from './fxPreciousMetalsAction';
import {cloneDeep} from 'lodash';
import {isEAMorEFA} from '../../../../common/enums/serviceModel';
import {BILA} from '../../../../common/enums/agreementType';
import FxPreciousMetalsProductTypes from './panel/FxPreciousMetalsProductTypes';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1)
  }
}));

const FxPreciousMetals = ({readOnly}) => {
  const classes = useStyles();
  const {state, dispatch} = useContext(PriceRequestStore);
  const fxPreciousMetals = state.selectedPricingRequest.nmbFXProducts;
  const {serviceModel, agentType} = state.selectedPricingRequest.common;

  const handleValueChange = async ({selectors, value}) => {
    // initialize field with empty object if and only if field node not exists
    let tempFxPreciousMetals = cloneDeep(fxPreciousMetals);
    let node = tempFxPreciousMetals.transactionAssetTypes || {};
    let assetType = selectors[0];
    let subLevel = selectors[1];

    for (let i = 0; i < selectors.length - 1; i++) {
      if (i === 1) {
        node = node.filter((subType) => subType.transactionAssetSubLevel.code === selectors[i])[0] || {};
      } else {
        node = node[selectors[i]] = node[selectors[i]] || {};
      }
    }

    node[selectors[selectors.length - 1]] = value;
    let errors = tempFxPreciousMetals.errors || [];
    if(!isEAMorEFA(serviceModel?.code, agentType) && assetType && subLevel) {
      if (node) {
        if (!node.agreementEnum || node.agreementEnum.code !== BILA.code) {
          node.additionalInstructions = '';
          node.acknowledged = false;
          const updateSelector = [assetType, subLevel,'newCondition', 'additionalInstructions'];
          errors = (errors || []).filter((e) => e !== updateSelector.join());
        }
      }
    }

    if(node){
      if(!node.acknowledged){
        node.additionalInstructions = '';
      }
    }

    dispatch({type: UPDATE_FX_PRECIOUS_METALS, data: tempFxPreciousMetals, errors: errors});
  };

  const handleValidationResultChange = ({selectors, isValid}) => {
    dispatch({type: UPDATE_FX_PRECIOUS_METALS_VALIDATION_RESULT, selector: selectors.join(), isValid: isValid});
  };

  // todo: clarify if can still override the applicable standard and discount level
  // const handleDiscountLevelChange = e => {
  //   const updatedFxMargin = {...fxPreciousMetals, discountLevelId: e.value};
  //   dispatch({type: UPDATE_FX_PRECIOUS_METALS, data: updatedFxMargin});
  // };
  // const handleOverrideDiscountLevelChange = e => {
  //   const updatedFxMargin = {...fxPreciousMetals, fxMarginOverriddenDiscountLevel: parseInt(e.value)};
  //   dispatch({type: UPDATE_FX_PRECIOUS_METALS, data: updatedFxMargin});
  // };
  return (<div className={classes.root}>
    <Paper className={classes.root} elevation={3} style={{padding: '10px'}}>
      <BoxHeader text={'Transaction Fees FX & Precious Metals Products'}/>
      {fxPreciousMetals?.transactionAssetTypes && <FxPreciousMetalsProductTypes fxProductTypes={fxPreciousMetals.transactionAssetTypes}  handleValidationResultChange={handleValidationResultChange} handleValueChange={handleValueChange} readOnly={readOnly} showAgreementIndicator={!isEAMorEFA(serviceModel?.code, agentType)}/>}
    </Paper>
  </div>)
};

FxPreciousMetals.propTypes = {
  readOnly: PropTypes.bool
};

export default FxPreciousMetals;
