import React from 'react';
import PropTypes from 'prop-types';
import PriceConditionTable from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import PriceConditionTableRowSection from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {
  LABEL,
  OLD_CONDITION_READONLY
} from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import {tierPricing} from './objectmapper/transactionFeesTierPricingObjectMapper';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../../common/constants';

const TransactionFeeSubFundTiering = ({oldTierPricing}) => {

  const data = [
    ...tierPricing({tiers: oldTierPricing})
  ];

  return (
    <PriceConditionTable width="50%">
      <PriceConditionTableRowSection data={data}>
        <PriceConditionTableColumn field="label" type={LABEL}/>
        <PriceConditionTableColumn
          customStyles={OLD_CONDITION_CUSTOM_STYLE}
          field="value"
          label="Discounted Conditions"
          type={OLD_CONDITION_READONLY}
        />
      </PriceConditionTableRowSection>
    </PriceConditionTable>
  );
};

TransactionFeeSubFundTiering.propTypes = {
  oldTierPricing: PropTypes.array
};

export default TransactionFeeSubFundTiering;
