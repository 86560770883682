import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {PriceRequestStore} from '../../PriceSimulationStore';
import Paper from '@material-ui/core/Paper';
import BoxHeader from '../../../Common/components/BoxHeader';
import {useNotification} from '../../../Common/components/Notification';
import {UPDATE_TRANSACTION_FEES, UPDATE_TRANSACTION_FEES_VALIDATION_RESULT} from './transactionFeesActions';
import OverchargeAdjustedDialog from '../../../Common/components/Dialog/OverchargeAdjustedDialog';
import TransactionFeeFunds from './panel/TransactionFeeFunds';
import api from '../../../../api/api';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import {loadTransactionApplicableFees} from './transactionFeesService';
import {LOAD_FUTURE_REVENUE_RESPONSE, LOAD_PDB_RESPONSE} from '../RevenueSimulation/revenueActions';
import {cloneDeep} from 'lodash';
import {
  getTransactionFeeConditionFromCode,
  isNonStandardCondition
} from '../../../../common/enums/transactionFeeCondition';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import {BILA} from '../../../../common/enums/agreementType';
import {isEAMorEFA} from '../../../../common/enums/serviceModel';
import {Store} from '../../../../Store';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '0.8125rem',
    '& .error': {
      backgroundColor: '#971b2f59'
    }
  }
}));

const TransactionFees = ({ readOnly, specialRequest, customTieringEnabled, specialRequestPm }) => {
  const classes = useStyles();
  const [overchargeAdjustedDialogValue, setOverchargeAdjustedDialogValue] = useState(null);
  const { state, dispatch } = useContext(PriceRequestStore);
  const notification = useNotification();
  const {serviceModel, agentType} = state.selectedPricingRequest.common;
  const {parentState} = useContext(Store);
  const [initNewSubFunds, setInitNewSubFunds] = useState();

  const isEAM = isEAMorEFA(serviceModel?.code, agentType);

  const getInitialNewSubFunds = (tempTransactionFees) => {
    let subLevelNonStandard = [];
    for(const [, subFunds] of Object.entries(tempTransactionFees.transactionAssetTypes)){
      for (const subFund of subFunds) {
        if(isNonStandardCondition(subFund.currentCondition?.transactionFeeCondition?.code)){
          subLevelNonStandard.push(subFund);
        }
      }
    }
    return subLevelNonStandard;
  }

  useEffect(() => {
    loadTransactionApplicableFees(state.selectedPricingRequest.common.pricingRequestId, dispatch).catch(
      (err) => {
        notification.error('Could not load transaction fees\n' + getErrorMessage(err));
      }
    ).finally( () => {
      let tempTransactionFees = cloneDeep(state.selectedPricingRequest.transactionFees);
      setInitNewSubFunds([...getInitialNewSubFunds(tempTransactionFees)]);
      if(!readOnly){
        let errors = triggerSpecialStructuresValidationForAllSubTypes();
        if(errors.length >0){
          dispatch({ type: UPDATE_TRANSACTION_FEES, errors: errors });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedPricingRequest.common.pricingRequestId]);

  const transactionFees = state.selectedPricingRequest.transactionFees;

  const NON_SIMULATED_FIELDS = ['acknowledged', 'additionalInstructions','agreementEnum']

  const handleValueChange = async ({ selectors, value, isFeeChanged }) => {
    // initialize field with empty object if and only if field node not exists
    let tempTransactionFees = cloneDeep(transactionFees);
    let node = tempTransactionFees.transactionAssetTypes || {};
    const assetType = selectors[0];
    const subLevel = selectors[1];
    const fieldToUpdate = selectors[selectors.length-1];

    for (let i = 0; i < selectors.length - 1; i++) {
      if (i === 1) {
        node = node.filter((subFund) => subFund.assetSubLevel.code === selectors[i])[0] || {};
      } else {
        node = node[selectors[i]] = node[selectors[i]] || {};
      }
    }

    let errors = tempTransactionFees.errors || [];
    if (isFeeChanged) {
      if (isNonStandardCondition(value.key)) {
        node['feePerTransaction'] = null;
        node.transactionFeeCondition = getTransactionFeeConditionFromCode(value.key);
        node.tierPricing = [{effectivePrice: 0, rangeStart: 0}];
        if(!specialRequestPm){
          const initNonStandard = (initNewSubFunds || []).find((e) => e.assetSubLevel.code === subLevel);
          node.additionalInstructions = initNonStandard?.currentCondition?.additionalInstructions;
          node.acknowledged = initNonStandard?.currentCondition?.acknowledged;
          node.agreementEnum = initNonStandard?.currentCondition?.agreementEnum;
          node.minFeePerTransaction = {standardFeeId: initNonStandard?.currentCondition?.minFeePerTransaction?.standardFeeId, effectivePrice: getMinFeeTransactionEffectivePrice(initNonStandard?.currentCondition?.minFeePerTransaction), listPrice: initNonStandard?.currentCondition?.minFeePerTransaction?.listPrice};
          let newTiers = [];
          for(const tierPrice of initNonStandard?.currentCondition?.tierPricing){
            newTiers.push({effectivePrice: tierPrice?.effectivePrice, rangeStart: tierPrice?.rangeStart, rangeEnd: tierPrice?.rangeEnd});
          }
          if(newTiers.length >0){
            node.tierPricing = newTiers;
          }
          if(node.additionalInstructions){
            errors = (errors || []).filter((e) => e !== [assetType, subLevel, 'newCondition', 'additionalInstructions'].join());
          }
        }
      } else {
        const transactionLevel = tempTransactionFees.applicableFees[value.key]['transactionAssetTypes'][selectors[0]];
        const newFees = transactionLevel.filter(transaction => transaction.assetSubLevel.code === selectors[1])[0].newCondition;
        node.tierPricing = null;
        errors = (errors || []).filter((e) => e !== [assetType, subLevel, 'portfolioAssets', 'customTiering'].join());
        Object.assign(node, {
          ...newFees,
          acknowledged: node.acknowledged,
          additionalInstructions: node.additionalInstructions,
          agreementEnum: node.agreementEnum
        });
      }
    } else {
      const updatingField = selectors[selectors.length - 1];
      node[updatingField] = value;
    }


    if(!isEAMorEFA(serviceModel?.code, agentType) && assetType && subLevel) {
      if (node) {
        if (!node.agreementEnum || node.agreementEnum.code !== BILA.code) {
          node.additionalInstructions = '';
          node.acknowledged = false;
          const updateSelector = [assetType, subLevel,'newCondition', 'additionalInstructions'];
          errors = (errors || []).filter((e) => e !== updateSelector.join());
        }
      }
    }

    if(node){
      if(!node.acknowledged){
        node.additionalInstructions = '';
      }
      const hasBila = node?.agreementEnum?.code === BILA.code;
      const errorSelector = [assetType, subLevel,'newCondition', 'acknowledged'];
      const isNonStandard = isNonStandardCondition(node?.transactionFeeCondition?.code);
      const isAcknowledged = node.acknowledged;
      const hasError = isEAM ? !isAcknowledged : !(hasBila && isAcknowledged);
      errors = triggerSpecialStructuresValidation(hasError, isNonStandard, errorSelector, errors);
    }

    const pricingRequestId = state.selectedPricingRequest.common.pricingRequestId;

    dispatch({ type: UPDATE_TRANSACTION_FEES, data: tempTransactionFees, errors: errors });

    if(!NON_SIMULATED_FIELDS.includes(fieldToUpdate)) {
      await simulateFee(pricingRequestId, tempTransactionFees, errors);
    }
  };

  const triggerSpecialStructuresValidationForAllSubTypes = () => {
    let tempTransactionFees = cloneDeep(state.selectedPricingRequest.transactionFees);
    let errors = [];
    if(tempTransactionFees){
      for(const [fundType, subFunds] of Object.entries(tempTransactionFees.transactionAssetTypes)){
        for (const subFund of subFunds) {
          const errorSelector = [fundType, subFund.assetSubLevel.code,'newCondition', 'acknowledged'];
          const hasBila = subFund?.newCondition?.agreementEnum?.code === BILA.code;
          const isNonStandard = isNonStandardCondition(subFund?.newCondition?.transactionFeeCondition?.code);
          const isAcknowledged = subFund.newCondition.acknowledged;
          const hasError = isEAM ? !isAcknowledged : !(hasBila && isAcknowledged);
          errors = triggerSpecialStructuresValidation(hasError, isNonStandard, errorSelector, errors);
        }
      }
    }
    return errors;
  }

  const triggerSpecialStructuresValidation = (hasError, isNonStandard, errorSelector, errors) => {
    if(isNonStandard && hasError){
      errors.push(errorSelector.join());
    }else{
      errors = (errors || []).filter((e) => e !== errorSelector.join());
    }
    return errors;
  }

  const getMinFeeTransactionEffectivePrice = (minFeePerTrans) => {
    if(parseFloat(minFeePerTrans?.effectivePrice) > parseFloat(minFeePerTrans?.listPrice)){
      return minFeePerTrans?.listPrice;
    }else{
      return minFeePerTrans?.effectivePrice;
    }
  }

  const simulateFee = async (pricingRequestId, tempTransactionFees, errors) => {
    let payload = await api.pricingRequest.transactionFees.simulateFee(
      pricingRequestId,
      tempTransactionFees
    );
    dispatch({ type: UPDATE_TRANSACTION_FEES, data: { ...tempTransactionFees, ...payload.data}, errors: errors });
    setOverchargeAdjustedDialogValue(payload.data);

    const portfolioNumber = state.selectedPricingRequest.common.portfolioNumber;
    let revenuePayload = await api.pricingRequest.revenue.postFuture(pricingRequestId, {
      ...state.selectedPricingRequest,
      transactionFees: {...state.selectedPricingRequest.transactionFees, newConditionCodeFees: payload.data},
      portfolioNumber
    });
    dispatch({ type: LOAD_FUTURE_REVENUE_RESPONSE, data: revenuePayload.data });

    const futureRevenue = revenuePayload?.data;
    const currentRevenue = state.selectedPricingRequest?.revenue?.currentRevenue;
    const pdbData = state.selectedPricingRequest?.revenue?.pdbData;
    const pricingRequestIds = parentState.portfolios?.map(req=>req.pricingRequestId);
    const currPricingRequestId = (parentState?.pricingRequestId) ?  parentState.pricingRequestId :  pricingRequestId;
    let pdbPayload = await api.pricingRequest.revenue.postPdb(pricingRequestId,
      {
        'currentReqId' : currPricingRequestId,
        'selectedReqIds' : pricingRequestIds,
        'currentRevenue' : currentRevenue,
        'futureRevenue' : futureRevenue,
        'pdbData' : pdbData
      }
    );
    dispatch({type: LOAD_PDB_RESPONSE, data: pdbPayload.data});
  }

  const handleValidationResultChange = ({ selectors, isValid }) => {
    dispatch({ type: UPDATE_TRANSACTION_FEES_VALIDATION_RESULT, selector: selectors.join(), isValid: isValid });
  };

  const getNode = (tempTransactionFees, selectors) => {
    let node = tempTransactionFees.transactionAssetTypes || {};

    for (let i = 0; i < selectors.length - 1; i++) {
      if (i === 1) {
        node = node.filter((subFund) => subFund.assetSubLevel.code === selectors[i])[0] || {};
      } else if (selectors[i] === 'tierPricing') {
        node = node[selectors[i]] = node[selectors[i]] || [{ effectivePrice: 0 }];
      } else {
        node = node[selectors[i]] = node[selectors[i]] || {};
      }
    }
    return node;
  };

  const handleAddTier = ({ selectors }) => {
    let tempTransactionFees = cloneDeep(transactionFees);
    const node = getNode(tempTransactionFees, selectors);
    node.push({ effectivePrice: 0 });
    dispatch({ type: UPDATE_TRANSACTION_FEES, data: tempTransactionFees, errors: transactionFees.errors });
  };

  const handleRemoveTier = ({ selectors, index }) => {
    let tempTransactionFees = cloneDeep(transactionFees);
    const node = getNode(tempTransactionFees, selectors);
    node.splice(index, 1);
    node[0].rangeStart = 0;
    dispatch({ type: UPDATE_TRANSACTION_FEES, data: tempTransactionFees, errors: transactionFees.errors });
  };

  const handleRangeStartChange = ({ index, value, selectors }) => {
    let tempTransactionFees = cloneDeep(transactionFees);
    const node = getNode(tempTransactionFees, selectors);
    node[index].rangeStart = value;
    dispatch({ type: UPDATE_TRANSACTION_FEES, data: tempTransactionFees, errors: transactionFees.errors });
  };

  const handleRangeEndChange = ({ index, value, selectors }) => {
    let tempTransactionFees = cloneDeep(transactionFees);
    const node = getNode(tempTransactionFees, selectors);
    node[index].rangeEnd = value;
    dispatch({ type: UPDATE_TRANSACTION_FEES, data: tempTransactionFees, errors: transactionFees.errors });
  };

  if (!transactionFees?.transactionAssetTypes) {
    return <LoadingSpinner/>;
  }

  return (
    <div className={classes.root}>
      <OverchargeAdjustedDialog
        onClose={() => setOverchargeAdjustedDialogValue(null)}
        testId={'test-transaction-fees-overcharge'}
        value={overchargeAdjustedDialogValue}
      />
      <Paper className={classes.root} elevation={3} style={{ padding: '10px' }}>
        <BoxHeader text={'Transaction Fees Exchange-Traded Products'}/>
        <TransactionFeeFunds
          customTieringEnabled={customTieringEnabled}
          funds={transactionFees.transactionAssetTypes}
          handleAddTier={handleAddTier}
          handleRangeEndChange={handleRangeEndChange}
          handleRangeStartChange={handleRangeStartChange}
          handleRemoveTier={handleRemoveTier}
          handleValidationResultChange={handleValidationResultChange}
          handleValueChange={handleValueChange}
          readOnly={readOnly}
          specialRequest={specialRequest}
          specialRequestPm={specialRequestPm}
        />
      </Paper>
    </div>
  );
};

TransactionFees.propTypes = {
  customTieringEnabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  specialRequest: PropTypes.bool,
  specialRequestPm: PropTypes.bool
};

export default TransactionFees;
