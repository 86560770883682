import {LOAD_SUPPORTING_DOCUMENTS} from './summaryActions';
import api from '../../../../api/api';

export const loadSupportingDocuments = async (pricingRequestId, dispatch) => {
  return api.pricingRequest.supportingDocuments.get(pricingRequestId).then(res => {
    dispatch({type: LOAD_SUPPORTING_DOCUMENTS, payload: res.data});
  });
};

export const deleteSupportingDocument = async (document, dispatch) => {
  return api.pricingRequest.supportingDocuments.delete(document).then(res => {
    dispatch({type: LOAD_SUPPORTING_DOCUMENTS, payload: res.data});
  })
}