import MuiAlert from '@material-ui/lab/Alert';
import {withStyles} from '@material-ui/styles';
import React from 'react';

const AlertComponent = withStyles(() => ({
  message: {
    whiteSpace: 'break-spaces'
  }
}))(MuiAlert);

export function Alert(props) {
  return <AlertComponent elevation={6} style={{whiteSpace: 'break-spaces'}} variant="filled" {...props} />;
}
