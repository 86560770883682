import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Box, Grid, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {useNotification} from '../../../Common/components/Notification';
import adminApi from '../../../../api/adminApi';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import AsyncButton from '../../../Common/components/AsyncButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {columnsData} from './columnsData';
import CacheCustomCell from './components/CacheCustomCell';
import {useSortBy, useTable} from 'react-table';
import {
  TABLE_APPROVAL_STATUS_BORDER_COLOR,
  TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR
} from '../../../../theme/common/color';
import {CLIPP_WHITE} from '../../../../theme/common/bjbColor';

const useStyles = makeStyles(theme => ({
  inner: {
    maxWidth: '1500px',
    overflowX: 'auto'
  },
  table: {
    border: '1px solid',
    borderColor: TABLE_APPROVAL_STATUS_BORDER_COLOR,
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  tableCellHeader: {
    backgroundColor: TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR,
    color: CLIPP_WHITE,
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '20px',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  tableCellBody: {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '25px',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

const tableColumns = columnsData.map(colData => {
  return {accessor: colData.key, Header: colData.label, Cell: CacheCustomCell};
});


const Cache = () => {

  const classes = useStyles();
  const notification = useNotification();

  const [responseData, setResponseData] = useState([]);
  const [calculateMemoryUsage, setCalculateMemoryUsage] = useState(false);

  const [isLoading, setIsLoading] = useState();

  const fetchData = params => {
    setIsLoading(true);
    return adminApi.cache
      .getAll({
        calculateMemoryUsage: calculateMemoryUsage,
        ...params,
      })
      .then(res => {
        setResponseData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        notification.error('Could not load data.\n' + getErrorMessage(err));
      });
  };

  const memoizedData = useMemo(() => responseData, [responseData]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedColumns = useMemo(() => tableColumns, []);

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      fetchData
    },
    useSortBy
  );

  const refresh = () => {
    return fetchData();
  };

  const handleMemoryCalculationToggle = () => {
    setCalculateMemoryUsage(!calculateMemoryUsage);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateMemoryUsage]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h1">
            Caches
          </Typography>
          <AsyncButton onClick={refresh} text="Refresh"/>
        </Box>

        <Box alignItems="center" display="flex" marginBottom={2}>
          <FormControlLabel
            control={
              <Switch
                checked={calculateMemoryUsage}
                color="default"
                onChange={handleMemoryCalculationToggle}
              />
            }
            label="Calculate memory utilization"
          />
        </Box>

      </Grid>

      <Grid item xs={12}>
        {isLoading ? <LoadingSpinner/> : (

          <Grid item xs={12}>
            {isLoading ? <LoadingSpinner/> : (
              <React.Fragment>

                <div className={classes.inner}>

                  <Table size="small" {...getTableProps()} className={classes.table}>
                    <TableHead>
                      {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <TableCell
                              {...column.getHeaderProps()}
                              className={classes.tableCellHeader}
                            >
                              {column.render('Header')}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                      {rows.length > 0 ? (
                        rows.map((row, i) => {
                          prepareRow(row);
                          return (
                            <TableRow key={i}>
                              {row.cells.map(cell => (
                                <TableCell
                                  {...cell.getCellProps()}
                                  className={classes.tableCellBody}
                                >
                                  {cell.render('Cell')}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={tableColumns.length}>
                            No entry found.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>

                  </Table>
                </div>

              </React.Fragment>
            )}
          </Grid>

        )
        }
      </Grid>
    </Grid>
  );
};

export default Cache;
