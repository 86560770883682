export const BILA = Object.freeze({
  code: 'BILA',
  desc: 'Bilateral Agreement',
})
export const UNILA = Object.freeze({
  code: 'UNILA',
  desc: 'Unilateral Discount',
});
export const STANDARD = Object.freeze({
  code: 'STANDARD',
  desc: 'Standard Agreement',
});
export const NOT_APPLICABLE = Object.freeze({
  code: 'NOT_APPLICABLE',
  desc: 'Not Applicable',
});

export const getAgreementTypeDescriptionFromCode = (code) => {
  return getAllAgreementTypes()
    .filter(condition => condition.code === code)
    .map(condition => condition.desc)[0];
}

export const getAllAgreementTypes = () => {
  return [
    BILA,
    UNILA,
    STANDARD,
    NOT_APPLICABLE
  ];
}
