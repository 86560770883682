import React from 'react';
import PropTypes from 'prop-types';
import {DATE_FORMAT} from '../../../../../../../../../common/dateFormat';
import Moment from 'react-moment';
import {Box} from '@material-ui/core';

const displayRoleMapping = {
  MH: 'GH',
  SH: 'SRH',
  PM: 'IM HEAD',
  IA: 'IA HEAD'
};

const ApproverCell = ({value}) => {
  return (
    <div data-testid="test-approver-cell">
      {value.map((approval, index) => (
        <Box alignItems="center" display="flex" key={index} whiteSpace="nowrap">
          {displayRoleMapping[approval.role] || approval.role}: {approval.pricingRequestStatus}
          {approval.completionDate && (
            <Box color="green" component="span" marginLeft={1} whiteSpace="nowrap">
              (<Moment date={approval.completionDate} format={DATE_FORMAT}/>)
            </Box>
          )}
        </Box>
      ))}
    </div>
  );
};

ApproverCell.propTypes = {
  value: PropTypes.array
};

export default ApproverCell;
