import React, {useContext, useEffect, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Skeleton from '@material-ui/lab/Skeleton';
import {Box, Paper} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BoxHeader from '../../../../../Common/components/BoxHeader';
import {Store} from '../../../../../../Store';
import {approvalColumnData} from '../columnData';
import api from '../../../../../../api/api';
import StatusFilter from '../StatusFilter';
import StatusTable from '../StatusTable';
import {useDebounce} from '../../../../../../common/useDebounce';
import {FETCH_APPROVAL_REASONS} from '../../../../../../common/actionTypes';

const ApprovalTab = () => {
  const [priceRequests, setPriceRequests] = useState();
  const {parentState, parentDispatch} = useContext(Store);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const getRequests = async () => {
      const response = await api.pricingRequest.list({isPending: true});
      setPriceRequests(response.data.content);

      const approvalResponse = await api.pricingRequest.getReasons();
      parentDispatch({type: FETCH_APPROVAL_REASONS, payload: approvalResponse.data});

      setLoading(false);
    };

    if (parentState.user?.id) {
      getRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentState.user?.id]);

  const [columnsData, setColumnsData] = useState(approvalColumnData);
  const [groupBy, setGroupBy] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterCriteria, setFilterCriteria] = useState('');
  const debouncedFilterCriteria = useDebounce(filterCriteria, 500);

  const handleSelectGroup = (e) => setGroupBy(e.target.value);
  const handleSelectFilter = (e) => setFilterBy(e.target.value);
  const handleFilterCriteriaInput = (e) => setFilterCriteria(e.target.value);
  const handleSelectedColumns = (value) => {
    const newColumnsData = cloneDeep(columnsData);
    newColumnsData.forEach((column) => {
      if (value[column.key] !== undefined) {
        column.hidden = !value[column.key];
      }
    });
    setColumnsData(newColumnsData);
  };

  return (
    <Paper
      data-testid="test-tab-content-approval"
      elevation={1}
      style={{
        padding: '10px'
      }}
      variant="elevation"
    >
      <BoxHeader icon={<AssignmentIcon/>} text={'Approval Status'}/>
      {!isLoading ? (
        priceRequests ? (
          <>
            <StatusFilter
              columnsData={columnsData}
              filterCriteria={filterCriteria}
              handleFilterCriteriaInput={handleFilterCriteriaInput}
              handleSelectFilter={handleSelectFilter}
              handleSelectGroup={handleSelectGroup}
              handleSelectedColumns={handleSelectedColumns}
              selectedFilter={filterBy}
              selectedGroup={groupBy}
            />
            <StatusTable
              columnsData={columnsData}
              filterByKey={filterBy}
              filterCriteria={debouncedFilterCriteria}
              groupByKey={groupBy}
              priceRequests={priceRequests}
              setPriceRequests={setPriceRequests}
            />
          </>
        ) : (
          <Box>No entry found.</Box>
        )
      ) : (
        <Skeleton animation="wave"/>
      )}
    </Paper>
  );
};

export default ApprovalTab;
