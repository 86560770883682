import React from 'react';
import PriceConditionTableColumn from './PriceConditionTableColumn';
import {TEXT, TEXT_LEFT} from './PriceConditionType';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../common/constants';
import PriceConditionTableRowSection from './PriceConditionTableRowSection';
import PropTypes from 'prop-types';
import PriceTableSelect from './PriceTableSelect';

const PriceConditionSelectRow = ({ newConditionLabel, showOldCondition, showNewCondition, oldConditionValue, newConditionValue, onChange, options, readOnly, readOnlyLabel, testId, columnProps, selectProps }) => {
  if(showNewCondition || showOldCondition) {
    return <PriceConditionTableRowSection data={[]}>
      {showOldCondition && <>
        <PriceConditionTableColumn
          customStyles={OLD_CONDITION_CUSTOM_STYLE}
          field="label"
          label={readOnly ? readOnlyLabel : newConditionLabel}
          type={TEXT_LEFT}
        />
        <PriceConditionTableColumn
          borderRight
          customStyles={OLD_CONDITION_CUSTOM_STYLE}
          field="label"
          label={oldConditionValue}
          type={TEXT}
        /></>}
      {!showOldCondition && <PriceConditionTableColumn field="label"/>}
      {showNewCondition && <PriceConditionTableColumn colSpan={2} field="label" label={<PriceTableSelect inputProps={selectProps} label={showOldCondition ? '' : newConditionLabel} onChange={onChange} options={options} readOnly={readOnly} readOnlyLabel={showOldCondition ? '' : readOnlyLabel} testId={testId} value={newConditionValue}/>} padding={0}{...columnProps}/>}
    </PriceConditionTableRowSection>
  }
  return null;
};

PriceConditionSelectRow.propTypes = {
  columnProps: PropTypes.object,
  newConditionLabel: PropTypes.string,
  newConditionValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  oldConditionValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  readOnlyLabel: PropTypes.string,
  selectProps: PropTypes.object,
  showNewCondition: PropTypes.bool.isRequired,
  showOldCondition: PropTypes.bool.isRequired,
  testId: PropTypes.string
};

export default PriceConditionSelectRow;
