export const FETCH_USER = 'FETCH_USER';
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const SELECT_CUSTOMERS = 'SELECT_CUSTOMERS';
export const SELECT_EAM_CUSTOMERS = 'SELECT_EAM_CUSTOMERS';
export const SELECT_PORTFOLIOS = 'SELECT_PORTFOLIOS';
export const SELECT_PORTFOLIO = 'SELECT_PORTFOLIO';
export const SELECT_PORTFOLIO_FOR_SUBMISSION = 'SELECT_PORTFOLIO_FOR_SUBMISSION';
export const SELECT_PORTFOLIO_NUMBERS_FOR_SUBMISSION = 'SELECT_PORTFOLIO_NUMBERS_FOR_SUBMISSION';
export const RESET = 'RESET';
export const FETCH_APPROVAL_REASONS = 'FETCH_APPROVAL_REASONS';
export const ACCOUNT_SEARCH_TYPE = 'ACCOUNT_SEARCH_TYPE';
export const SELECT_EAM_PORTFOLIOS_COPY = 'SELECT_EAM_PORTFOLIOS_COPY';
export const SET_LOADING_INDICATOR = 'SET_LOADING_INDICATOR';
export const SET_DASHBOARD_FLAG = 'SET_DASHBOARD_FLAG';
export const SET_RM_PDB_DATA = 'SET_RM_PDB_DATA';
export const CURR_PRICING_REQUEST = 'CURR_PRICING_REQUEST';
export const SET_PENDING_REQUEST_FLAG = 'SET_PENDING_REQUEST_FLAG';
export const SET_GROUPING_DATA = 'SET_GROUPING_DATA';
