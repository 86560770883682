export const PRICING_REQUEST_ID = {key: 'pricingRequestId', label: 'Pricing Request ID'};
export const PRICING_REQUEST_PORTFOLIO_NUMBER = {key: 'portfolioNumber', label: 'Portfolio Number'};
export const PRICING_REQUEST_STATUS = {key: 'pricingRequestStatus', label: 'Status'};
export const WORKFLOW_ID = {key: 'workflowId', label: 'Workflow ID'};
export const APPROVAL_INFO_COLUMN = {key: 'approvalInfo', label: 'Approval Info'};
export const ACTION_COLUMN = {key: 'action', label: 'Action'};

export const columnsData = [
  PRICING_REQUEST_ID,
  PRICING_REQUEST_PORTFOLIO_NUMBER,
  PRICING_REQUEST_STATUS,
  WORKFLOW_ID,
  APPROVAL_INFO_COLUMN,
  ACTION_COLUMN
];
