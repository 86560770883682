import React, {useContext} from 'react';
import {getErrorMessage} from '../../../../../common/getErrorMessage';
import {getSelectedPriceRequestErrors} from '../../../../../common/getSelectedPriceRequestErrors';
import {setIsLoading} from '../../../../../Services';
import {Store} from '../../../../../Store';
import {PriceRequestStore} from '../../../../PriceSimulation/PriceSimulationStore';
import {loadPricingRequest, updatePricingRequest_upd} from '../../../../PriceSimulation/pricingRequestService';
import AsyncButton from '../../AsyncButton';
import {useNotification} from '../../Notification';

const SaveButton = ({...other}) => {
  const notification = useNotification();
  const {state, dispatch} = useContext(PriceRequestStore);
  const {parentState, parentDispatch} = useContext(Store);

  const handleSave = async () => {
    setIsLoading(true, parentDispatch);
    let selectedPriceRequestErrors = getSelectedPriceRequestErrors(state);
    if (selectedPriceRequestErrors.length > 0) {
      notification.error('Resolve validation errors before saving');
      setIsLoading(false, parentDispatch);
      throw new Error();
    } else {
      try {
        const pricingRequestIds = parentState.portfolios?.map(req=>req.pricingRequestId);
        await updatePricingRequest_upd(state.selectedPricingRequest, dispatch, pricingRequestIds, parentDispatch, parentState);
        notification.success('Saved successfully');
        await loadPricingRequest(state.selectedPricingRequest.common.pricingRequestId, dispatch);
        setIsLoading(false, parentDispatch);

      } catch (err) {
        notification.error('Save failed\n' + getErrorMessage(err));
        setIsLoading(false, parentDispatch);
        throw err;
      }
    }
  };

  return (
    <AsyncButton
      color="primary"
      onClick={handleSave}
      text="Save"
      title="Save As Draft"
      type="button"
      {...other}
    />
  );
};

export default SaveButton;
