import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import AsyncButton from '../AsyncButton';

const CommonDialog = ({isCancelDisabled, onClose, onContinue, open, content, text, testId, title, link}) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onClose={onClose}
      open={open}
    >
      <DialogTitle>{ title ? title : 'ALERT' }</DialogTitle>
      <DialogContent>
        {
          content ? content : (
            <DialogContentText>{text}
              {<br/>}
              { link ? <Link href={link.hyperLink} target="_blank">{link.displayText}</Link>:'' }
            </DialogContentText>
          )
        }
      </DialogContent>
      <DialogActions>
        {onContinue && (
          <AsyncButton color="primary" data-testid={`${testId}-continue`} onClick={onContinue} text="Continue"/>
        )}
        <Button data-testid={`${testId}-cancel`} disabled={isCancelDisabled} onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CommonDialog.propTypes = {
  content: PropTypes.object,
  isCancelDisabled: PropTypes.bool,
  link: PropTypes.shape({
    hyperLink: PropTypes.string,
    displayText: PropTypes.string,
    altText: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  open: PropTypes.bool,
  testId: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
}

export default CommonDialog;
