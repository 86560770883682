import React, {useState} from 'react';
import AsyncButton from '../../../../../../../Common/components/AsyncButton';
import {useNotification} from '../../../../../../../Common/components/Notification';
import adminApi from '../../../../../../../../api/adminApi';
import Moment from 'react-moment';
import PRICING_REQUEST_STATUS from '../../../../../../../../common/enums/pricingRequestStatus';
import T24_SYNC_STATUS from '../../../../../../../../common/enums/t24SyncStatus';
import {DATE_TIME_FORMAT} from '../../../../../../../../common/dateFormat';
import {ACTION_COLUMN, CODES_COLUMN, CREATED_DATE_COLUMN, UPDATED_DATE_COLUMN} from '../../columnsData';
import {getErrorMessage} from '../../../../../../../../common/getErrorMessage';
import CommonDialog from '../../../../../../../Common/components/Dialog/CommonDialog';

const PricingCustomCell = ({column, fetchData, filterByPricingRequestStatus, filterByT24Status, row, value}) => {
  const notification = useNotification();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const submitPricingRequest = pricingRequestId => {
    return adminApi.t24Workflow
      .submitPricingRequestToBCOM(pricingRequestId)
      .then(() => {
        notification.success(
          `Successfully submitted Pricing Request ${pricingRequestId} to BCOM/T24`
        );

        fetchData({
          pricingRequestStatus: filterByPricingRequestStatus,
          t24SyncStatus: filterByT24Status
        });
      })
      .catch((err) => {
        notification.error('Could not submit new Pricing Request to BCOM/T24\n' + getErrorMessage(err));
      });
  };

  const submitPricingRequestToPriceCheck = pricingRequestId => {
    return adminApi.priceCheckWorkflow
      .submitPricingRequestToBCOM(pricingRequestId)
      .then(() => {
        notification.success(
          `Successfully submitted Pricing Request ${pricingRequestId} to BCOM/PriceCheck`
        );

        fetchData({
          pricingRequestStatus: filterByPricingRequestStatus,
          t24SyncStatus: filterByT24Status
        });
      })
      .catch((err) => {
        notification.error('Could not submit new Pricing Request to BCOM/PriceCheck\n' + getErrorMessage(err));
      });
  };

  const syncOwner = pricingRequestId => {
    return adminApi.pricingAdmin
      .syncOwner(pricingRequestId)
      .then(() => {
        notification.success(
          `Successfully synced owner for Pricing Request ${pricingRequestId}`
        );

        fetchData({
          pricingRequestStatus: filterByPricingRequestStatus,
          t24SyncStatus: filterByT24Status
        });
      })
      .catch((err) => {
        notification.error('Could not sync owner details, please check portfolio setup for this.\n' + getErrorMessage(err));
      });
  };

  const validateCodes = pricingRequestId => {
    return adminApi.pricingAdmin
      .validateCodes(pricingRequestId)
      .then((response) => {

        if (response?.status === 200) {
          notification.success(`All codes for pricing request with Id ${pricingRequestId} are available and confirmed`);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 424) {
          notification.warning(`Not all codes for pricing request with Id ${pricingRequestId} available or valid. Please see below\n${err.response.data.message}`);
        } else {
          notification.error('Error while validating codes for pricing request.\n' + getErrorMessage(err));
        }
      });
  };

  const deriveCodes = pricingRequestId => {
    return adminApi.pricingAdmin
      .deriveCodes('SYSTEM', pricingRequestId)
      .then(resp => {

        if (resp.status === 204) {
          notification.info('No new codes.');
        } else {
          notification.success('The following pricing request either just created or waiting to be submitted to T24: ' + resp.data);
        }

      })
      .catch((err) => {
        notification.error('Error while deriving codes for pricing request.\n' + getErrorMessage(err));
      });
  };

  const cancelPricingRequest = pricingRequestId => {
    return adminApi.pricingAdmin
      .cancelPricingRequest(pricingRequestId)
      .then(() => {
        notification.success(
          `Successfully cancelled Pricing Request ${pricingRequestId}`
        );

        fetchData({
          pricingRequestStatus: filterByPricingRequestStatus,
          t24SyncStatus: filterByT24Status
        });
      })
      .catch((err) => {
        notification.error(`Could not cancel Pricing Request ${pricingRequestId}\n` + getErrorMessage(err));
      });
  };

  if ([CREATED_DATE_COLUMN.key, UPDATED_DATE_COLUMN.key].includes(column.id)) {
    return value ? <Moment date={value} format={DATE_TIME_FORMAT}/> : null;
  } else if (column.id === CODES_COLUMN.key) {

    const t24SyncStatus = row?.original?.t24SyncStatus;
    const pricingRequestStatus = row?.original?.pricingRequestStatus;

    let canDeriveCodes = [PRICING_REQUEST_STATUS.SUBMITTED, PRICING_REQUEST_STATUS.APPROVED].includes(pricingRequestStatus) &&
      [T24_SYNC_STATUS.NEW, T24_SYNC_STATUS.SUBMITTED, T24_SYNC_STATUS.ERROR].includes(t24SyncStatus);

    return (
      <div>
        <AsyncButton
          onClick={() => validateCodes(row?.original?.pricingRequestId)}
          text="Validate"
        />
        &nbsp;
        <AsyncButton
          disabled={!canDeriveCodes}
          onClick={() => deriveCodes(row?.original?.pricingRequestId)}
          text="Derive"
        />
      </div>
    )
  } else if (column.id === ACTION_COLUMN.key) {
    const t24SyncStatus = row?.original?.t24SyncStatus;
    const pricingRequestStatus = row?.original?.pricingRequestStatus;

    let canCancel = pricingRequestStatus !== PRICING_REQUEST_STATUS.CANCELLED &&
      [T24_SYNC_STATUS.NEW, T24_SYNC_STATUS.SUBMITTED, T24_SYNC_STATUS.ERROR].includes(t24SyncStatus);

    let canSubmitToT24 = pricingRequestStatus === PRICING_REQUEST_STATUS.APPROVED &&
      [T24_SYNC_STATUS.NEW, T24_SYNC_STATUS.SUBMITTED, T24_SYNC_STATUS.ERROR].includes(t24SyncStatus)

    let canSubmitToPriceCheck = pricingRequestStatus === PRICING_REQUEST_STATUS.APPROVED &&
      [T24_SYNC_STATUS.CONFIRMED,T24_SYNC_STATUS.EXISTING].includes(t24SyncStatus)

    return (
      <React.Fragment>

        <CommonDialog
          onClose={() => setIsCancelDialogOpen(false)}
          onContinue={() => cancelPricingRequest(row?.original?.pricingRequestId)}
          open={isCancelDialogOpen}
          text={`You are about to change the status of pricing request  
                '${row?.original?.pricingRequestId}' for '${row?.original.portfolioNumber}' to cancel. This does not 
                cancel any workflows, but allows the user to start a new pricing request.
                Do you want to continue?.`}
        />
        <div>
          <AsyncButton
            onClick={() => syncOwner(row?.original?.pricingRequestId)}
            text="Sync Owner"
          />
          &nbsp;
          <AsyncButton
            disabled={!canCancel}
            onClick={async () => {
              setIsCancelDialogOpen(true);
              return;
            }}
            text="Cancel"
          />
          &nbsp;
          <AsyncButton
            disabled={!canSubmitToT24}
            onClick={() => submitPricingRequest(row?.original?.pricingRequestId)}
            text="Send to T24"
            ttitle="Send Pricing Request to T24"
          />
          &nbsp;
          <AsyncButton
            disabled={!canSubmitToPriceCheck}
            onClick={() => submitPricingRequestToPriceCheck(row?.original?.pricingRequestId)}
            text="Send to Price Check"
            ttitle="Send Pricing Request to Price Check Engine"
          />
        </div>
      </React.Fragment>
    )
  } else {
    return value ? value : null;
  }
};

export default PricingCustomCell;
