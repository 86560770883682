import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const OverchargeAdjustedDialog = ({onClose, value, testId}) => {

  const anyFieldOverchargeAdjusted = (obj, i) => {
    if (!obj) {
      return false;
    }
    const objEntries = Object.entries(obj);
    for (let [key, value] of objEntries) {
      if (key === 'overchargeAdjusted' && value) {
        return true;
      }
      if (typeof value === 'object' && anyFieldOverchargeAdjusted(value, i + 1)) {
        return true;
      }
    }
    return false;
  }

  const open = anyFieldOverchargeAdjusted(value, 0);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>ALERT</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Special condition entered is higher than the effective fee allowed for this portfolio. Please review your inputs to prevent potential overcharging.<br/><br/>
          CLiPP has default your higher input value to maximum permissible value (rounded off to nearest full number)
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" data-testid={testId} onClick={onClose}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};


OverchargeAdjustedDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  testId: PropTypes.string,
  value: PropTypes.any
};

export default OverchargeAdjustedDialog;
