import { jbColors } from '../jbColors';

const MuiDropzoneArea = {
  root: {
    maxHeight: '150px',
    minHeight: '100px',
    backgroundColor: jbColors.greenSmoke['10'],
    border: 'none',
  },
  text: {
    fontFamily: 'Verlag, Helvetica, Arial, sans-serif',
    fontSize: '12px',
    color: jbColors.greenSmoke[100],
    maxHeight: '5px',
  },
  textContainer: {
    maxHeight: '100px',
  },
};

export default MuiDropzoneArea;
