import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, CircularProgress, makeStyles} from '@material-ui/core';

let resetDefaultTimer;

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginRight: theme.spacing(0.5)
  }
}));

const AsyncButton = ({disabled, fulfilledText, onClick, pendingText, rejectedText, text, timeToDefault, ...other}) => {
  const PENDING_STATE = 'pending',
    FULFILLED_STATE = 'fulfilled',
    REJECTED_STATE = 'rejected';
  const PENDING_DELAY = 100;

  const classes = useStyles();
  const isUnmounted = useRef(false);
  const [buttonState, setButtonState] = useState();
  const isPending = buttonState === PENDING_STATE;
  const isFulfilled = buttonState === FULFILLED_STATE;
  const isRejected = buttonState === REJECTED_STATE;
  const isDisabled = disabled || isPending;

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    }
  }, [])

  const getButtonText = () => {
    if (isPending && pendingText) return pendingText;
    else if (isFulfilled && fulfilledText) return fulfilledText;
    else if (isRejected && rejectedText) return rejectedText;
    else return text;
  };

  const setStateIfMounted = (state) => {
    if (!isUnmounted.current) {
      setButtonState(state)
    }
  }

  const resetState = () => {
    setStateIfMounted(null);
  }

  const handleClick = () => {
    const clickHandler = onClick;
    if (resetDefaultTimer) {
      clearTimeout(resetDefaultTimer);
    }

    const pendingDelayTimer = setTimeout(() => {
      setStateIfMounted(PENDING_STATE);
    }, PENDING_DELAY);

    const returnFn = clickHandler();
    if (returnFn && typeof returnFn.then === 'function') {
      returnFn
        .then(() => {
          setStateIfMounted(FULFILLED_STATE);
          resetDefaultTimer = setTimeout(resetState, timeToDefault);
          clearTimeout(pendingDelayTimer);
        })
        .catch(() => {
          setStateIfMounted(REJECTED_STATE);
          resetDefaultTimer = setTimeout(resetState, timeToDefault);
          clearTimeout(pendingDelayTimer);
        });
    } else {
      resetState();
    }
  };

  return (
    <Button
      {...other}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {buttonState === PENDING_STATE && (
        <CircularProgress className={classes.spinner} size={10}/>
      )}
      {getButtonText()}
    </Button>
  );
};

AsyncButton.propTypes = {
  disabled: PropTypes.bool,
  fulfilledText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  pendingText: PropTypes.string,
  rejectedText: PropTypes.string,
  text: PropTypes.string,
  timeToDefault: PropTypes.number
};

AsyncButton.defaultProps = {
  timeToDefault: 5000
}

export default AsyncButton;
