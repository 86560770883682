import React, {useContext, useEffect, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Skeleton from '@material-ui/lab/Skeleton';
import {Box, Paper} from '@material-ui/core';
import BoxHeader from '../../../../../Common/components/BoxHeader';
import {Store} from '../../../../../../Store';
import StatusFilter from '../StatusFilter';
import StatusTable from '../StatusTable';
import {acmAllPriceRequestColumnData} from '../columnData';
import api from '../../../../../../api/api';
import {useDebounce} from '../../../../../../common/useDebounce';
import {SINGAPORE} from '../../../../../../common/enums/bookingCenter';
import adminApi from '../../../../../../api/adminApi';
import {PRIMARY_BACKUP_RM_ROLE} from '../../../../../../common/enums/accessRole';
import {PRIMARY_BOOKING_CENTER_OFFICER} from '../../../../../../common/enums/accessRole';
import {ARM_ROLE} from '../../../../../../common/enums/accessRole';

const AllPriceRequestTab = () => {
  const [priceRequests, setPriceRequests] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({page: 0});
  const [totalPages, setTotalPages] = useState();
  const {parentState} = useContext(Store);
  const [isLoading, setLoading] = useState(true);
  const [tenant, setTenant] = useState();
  const [columnsData, setColumnsData] = useState(acmAllPriceRequestColumnData);
  const [groupBy, setGroupBy] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterCriteria, setFilterCriteria] = useState('');
  const debouncedFilterCriteria = useDebounce(filterCriteria, 500);
  const [globalSearchPortfolioNo, setGlobalSearchPortfolioNo] = useState('');
  const [deviationRetainedMailEnabled, setDeviationRetainedMailEnabled] = useState(false);
  const [sort, setSort] = useState({direction:'', key: ''});

  const checkDeviationEnabled = data => {
    return data.map(pricingRequest => {
      if (pricingRequest.lastApproved  && pricingRequest.hasBankingFee ) {
        return {...pricingRequest, deviationEditEnabled: true};
      }else{
        return pricingRequest;
      }
    });
  };

  const checkPeriodicReviewEnabled = data => {
    return data.map(pricingRequest => {
      if (!pricingRequest.hasEAMorEFA && pricingRequest.lastApproved && isOwnerORPrimaryBackupRM(pricingRequest.accountNumber, pricingRequest.portfolioNumber)) {
        return {...pricingRequest, periodicReviewEnabled: true};
      }else{
        return pricingRequest;
      }
    });
  };

  const isOwnerORPrimaryBackupRM = (accountNumber, portfolioNumber) =>{
    const userId = parentState.user?.id;
    const customers = parentState.customers?.find(account =>account.accountNumber === accountNumber);
    const portfolio = customers?.portfolios?.find(portfolio => portfolio.portfolioNumber === portfolioNumber)
    const ownerId = portfolio?.accountOwnerUId;
    return (userId === ownerId) || (portfolio?.accessRoleType === PRIMARY_BACKUP_RM_ROLE)
      || (portfolio?.accessRoleType === ARM_ROLE) || (portfolio?.accessRoleType === PRIMARY_BOOKING_CENTER_OFFICER);
  }

  const fetchData = async params => {
    setLoading(true);
    const response = await api.pricingRequest.list({
      tenant,
      isPending: false,
      ...params,
    });
    const deviationEnableAdjustedPricingRequests = checkDeviationEnabled(response.data.content);
    const periodicReviewEnabledAdjustedPricingRequests = checkPeriodicReviewEnabled(deviationEnableAdjustedPricingRequests);
    setPriceRequests(periodicReviewEnabledAdjustedPricingRequests);
    setPaginationInfo({page: params?.page || 0});
    setTotalPages(response.data.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    if (parentState.user?.id) {
      fetchData();
      adminApi.featureFlag.retainedMail().then(res => {
        if (res.data.active) {
          setDeviationRetainedMailEnabled(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentState.user]);

  const handleSelectGroup = e => {
    const selectedGroup = e.target.value?.trim();
    setGroupBy(selectedGroup);
    const pageActionDTO = {groupBy: selectedGroup, filterBy, filterCriteria, sortBy: sort.key, sortDirection: sort.direction};
    fetchData(assignSearchPortfolioToPageAction(pageActionDTO));
  }

  const handleSelectFilter = e => {
    const filterKey = e.target.value?.trim();
    setFilterBy(filterKey);
    if (filterKey === undefined && filterCriteria) {
      const pageActionDTO = {groupBy, sortBy: sort.key, sortDirection: sort.direction};
      fetchData(assignSearchPortfolioToPageAction(pageActionDTO));
    }else if (filterKey && filterCriteria) {
      const pageActionDTO = {groupBy, filterBy: filterKey, filterCriteria, sortBy: sort.key, sortDirection: sort.direction};
      fetchData(assignSearchPortfolioToPageAction(pageActionDTO));
    }
  }

  const handleFilterCriteriaInput = e => {
    const filteredVal = e.target.value?.trim();
    setFilterCriteria(filteredVal);
    if (filterBy && filteredVal === '') {
      const pageActionDTO = {groupBy, filterBy, filterCriteria: filteredVal, sortBy: sort.key, sortDirection: sort.direction};
      fetchData(assignSearchPortfolioToPageAction(pageActionDTO));
    }
  }

  const handleSelectedColumns = value => {
    const newColumnsData = cloneDeep(columnsData);
    newColumnsData.forEach(column => {
      if (value[column.key] !== undefined) {
        column.hidden = !value[column.key];
      }
    });
    setColumnsData(newColumnsData);
  };
  const handleChangeTenant = tenant => {
    const tenantId = tenant || SINGAPORE.code;
    const pageActionDTO = {groupBy, filterBy, filterCriteria, sortBy: sort.key, sortDirection: sort.direction};
    let reqParams = {tenant: tenantId, pageActionDTO: JSON.stringify(pageActionDTO)};
    if (globalSearchPortfolioNo) {
      reqParams.portfolioNumber = globalSearchPortfolioNo;
    }
    setTenant(tenantId);
    fetchData(reqParams);
  };

  const goToPage = page => {
    const pageActionDTO = {groupBy, filterBy, filterCriteria, sortBy: sort.key, sortDirection: sort.direction};
    let reqParams = {page, pageActionDTO: JSON.stringify(pageActionDTO)};
    if (globalSearchPortfolioNo) {
      reqParams.portfolioNumber = globalSearchPortfolioNo;
    }
    fetchData(reqParams);
  };

  const handleGlobalSearchPortfolioNo = (portfolioNumber) => {
    if (portfolioNumber === null || portfolioNumber) {
      const pageActionDTO = {groupBy, filterBy, filterCriteria, sortBy: sort.key, sortDirection: sort.direction};
      fetchData({portfolioNumber, pageActionDTO: JSON.stringify(pageActionDTO)});
    }
  };

  const handleSort = (column) => {
    if (column) {
      const pageActionDTO = {groupBy, filterBy, filterCriteria, sortBy: column.id, sortDirection: sort.direction === 'ASC' ? 'DESC' : 'ASC'};
      fetchData(assignSearchPortfolioToPageAction(pageActionDTO))
      if (sort.key === column.id) {
        switch (sort.direction) {
          case 'ASC':
            setSort({direction: 'DESC', key: column.id});
            break;
          case 'DESC':
            setSort({direction: 'ASC', key: column.id});
            break;
        }
      } else {
        setSort({key: column.id, direction: 'ASC'});
      }
    }
  };

  const handleOnEnter = (e) => {
    if (filterBy && filterCriteria && e.key === 'Enter') {
      const pageActionDTO = {groupBy, filterBy, filterCriteria, sortBy: sort.key, sortDirection: sort.direction};
      fetchData(assignSearchPortfolioToPageAction(pageActionDTO));
    }
  };

  const assignSearchPortfolioToPageAction = (pageActionDTO) => {
    if (globalSearchPortfolioNo) {
      return {portfolioNumber: globalSearchPortfolioNo, pageActionDTO: JSON.stringify(pageActionDTO)};
    } else {
      return {pageActionDTO: JSON.stringify(pageActionDTO)};
    }
  };

  return (
    <Paper
      data-testid="test-tab-content-all"
      elevation={1}
      style={{
        padding: '10px'
      }}
      variant="elevation"
    >
      <BoxHeader text={'Approval Status'}/>
      {!isLoading ? (
        priceRequests ? (
          <>
            <StatusFilter
              actionOnCompleteResult
              columnsData={columnsData}
              filterCriteria={filterCriteria}
              globalSearchPortfolioNo={globalSearchPortfolioNo}
              handleChangeTenant={handleChangeTenant}
              handleFilterCriteriaInput={handleFilterCriteriaInput}
              handleGlobalSearchPortfolioNo={handleGlobalSearchPortfolioNo}
              handleOnEnter={handleOnEnter}
              handleSelectFilter={handleSelectFilter}
              handleSelectGroup={handleSelectGroup}
              handleSelectedColumns={handleSelectedColumns}
              selectedFilter={filterBy}
              selectedGroup={groupBy}
              setGlobalSearchPortfolioNo={setGlobalSearchPortfolioNo}
              tenant={tenant}
            />
            <StatusTable
              columnsData={columnsData}
              deviationRetainedMailEnabled={deviationRetainedMailEnabled}
              fetchData={fetchData}
              filterByKey={filterBy}
              filterCriteria={debouncedFilterCriteria}
              goToPage={goToPage}
              groupByKey={groupBy}
              handleSort={handleSort}
              paginationInfo={{...paginationInfo, totalPages}}
              priceRequests={priceRequests}
              setPriceRequests={setPriceRequests}
              sortByInfo={sort}
            />
          </>
        ) : (
          <Box>No entry found.</Box>
        )
      ) : (
        <Skeleton animation="wave"/>
      )}
    </Paper>
  );
};

export default AllPriceRequestTab;
