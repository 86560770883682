import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import {makeStyles} from '@material-ui/styles';
import {
  DISCLAIMER,
  DISCOUNT_SUMMARY,
  EDITABLE,
  LABEL,
  LABEL_SUMMARY,
  MINIMUM_FEE_SUMMARY,
  NEW_CONDITION_EDITABLE,
  NEW_CONDITION_READONLY,
  NEW_CONDITIONS,
  NEW_CONDITIONS_SUMMARY,
  OLD_CONDITION_READONLY,
  OLD_CONDITIONS,
  OLD_CONDITIONS_SUMMARY,
  TEXT,
  TEXT_LEFT
} from './PriceConditionType';
import {
  TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR,
  TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR,
  TABLE_HEADER_BACKGROUND_COLOR,
  TABLE_HEADER_COLOR,
  TABLE_SUB_HEADER_CURRENT_CONDITION_BACKGROUND_COLOR,
  TABLE_SUB_HEADER_CURRENT_CONDITION_COLOR,
  TABLE_SUB_HEADER_LABEL_BACKGROUND_COLOR,
  TABLE_SUB_HEADER_LABEL_COLOR,
  TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_BACKGROUND_COLOR,
  TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_COLOR,
  TABLE_SUB_HEADER_NEW_CONDITION_READONLY_BACKGROUND_COLOR,
  TABLE_SUB_HEADER_NEW_CONDITION_READONLY_COLOR
} from '../../../../theme/common/color';

import {CLIPP_WHITE} from '../../../../theme/common/bjbColor';
import {getDefaultFontSize} from '../../../../theme/common/font';

const useStyles = makeStyles((theme) => ({
  label: {
    color: TABLE_SUB_HEADER_LABEL_COLOR,
    backgroundColor: TABLE_SUB_HEADER_LABEL_BACKGROUND_COLOR,
    fontSize: '0.8125rem',
    borderBottom: '0px'
  },
  conditionsHeader: {
    backgroundColor: TABLE_HEADER_BACKGROUND_COLOR,
    color: TABLE_HEADER_COLOR,
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    fontFamily: '\'Verlag SSm 7r\'',
    textAlign: 'center',
  },
  oldConditionReadonlyHeader: {
    color: TABLE_SUB_HEADER_CURRENT_CONDITION_COLOR,
    backgroundColor: TABLE_SUB_HEADER_CURRENT_CONDITION_BACKGROUND_COLOR,
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    fontFamily: '\'Verlag SSm 7r\'',
    textAlign: 'center',
  },
  newConditionEditableHeader: {
    backgroundColor: TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_BACKGROUND_COLOR,
    color: TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_COLOR,
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    fontFamily: '\'Verlag SSm 7r\'',
    textAlign: 'center',
  },
  newConditionReadonlyHeader: {
    backgroundColor: TABLE_SUB_HEADER_NEW_CONDITION_READONLY_BACKGROUND_COLOR,
    color: TABLE_SUB_HEADER_NEW_CONDITION_READONLY_COLOR,
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    fontFamily: '\'Verlag SSm 7r\'',
    textAlign: 'center',
  },
  labelSummary: {
    backgroundColor: TABLE_HEADER_BACKGROUND_COLOR,
    color: TABLE_HEADER_COLOR,
    fontWeight: 'normal',
    fontFamily: '\'Verlag SSm 7r\'',
    textAlign: 'left',
  },
  headerSummary: {
    backgroundColor: TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR,
    color: CLIPP_WHITE,
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    fontFamily: '\'Verlag SSm 7r\'',
    textAlign: 'center'
  },
  text: {
    ...getDefaultFontSize(theme),
    textAlign: 'right'
  },
  textLeft: {
    ...getDefaultFontSize(theme),
    textAlign: 'left'
  },
  disclaimer: {
    padding: '6px',
    whiteSpace: 'pre-line',
    textAlign: 'justify',
    fontSize:'0.70rem',
    lineHeight: '1.7'
  },
  borderLeft: {
    borderLeft: '1px solid rgb(227, 229, 229)'
  },
  borderRight: {
    borderRight: '1px solid rgb(227, 229, 229)'
  },
  editableTableBodyCell: {
    fontSize: '0.8125rem',
    backgroundColor: TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR
  }
}));
const PriceConditionTableColumn = ({borderLeft, borderRight, colSpan, defaultClassName = '', label, type, width, noBorder, padding, style, ...other}) => {
  const classes = useStyles();

  let className;
  switch (type) {
    case LABEL:
      className = classes.label;
      break;
    case NEW_CONDITIONS:
    case OLD_CONDITIONS:
      className = classes.conditionsHeader;
      break;
    case OLD_CONDITION_READONLY:
      className = classes.oldConditionReadonlyHeader;
      break;
    case NEW_CONDITION_EDITABLE:
      className = classes.newConditionEditableHeader;
      break;
    case NEW_CONDITION_READONLY:
      className = classes.newConditionReadonlyHeader;
      break;
    case EDITABLE:
      className = classes.editableTableBodyCell;
      break;
    case LABEL_SUMMARY:
      className = classes.labelSummary;
      break;
    case OLD_CONDITIONS_SUMMARY:
    case NEW_CONDITIONS_SUMMARY:
    case DISCOUNT_SUMMARY:
    case MINIMUM_FEE_SUMMARY:
      className = classes.headerSummary;
      break;
    case TEXT:
      className = classes.text;
      break;
    case TEXT_LEFT:
      className = classes.textLeft;
      break;
    case DISCLAIMER:
      className = classes.disclaimer;
      break;
    default:
      className = defaultClassName;
  }

  return (
    <TableCell
      className={`${className} ${borderLeft ? classes.borderLeft : ''} ${borderRight ? classes.borderRight : ''}`}
      colSpan={colSpan || 1}
      data-testid={other['data-testid']}
      style={{
        width,
        padding,
        borderBottom: noBorder ? '0px' : '1px solid rgba(224,224,224,1)', ...style
      }}
    >{label}</TableCell>
  );
};

PriceConditionTableColumn.propTypes = {
  borderLeft: PropTypes.bool,
  borderRight: PropTypes.bool,
  colSpan: PropTypes.number,
  defaultClassName: PropTypes.string,
  field: PropTypes.string,
  isEditableFieldValueAccessor: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  noBorder: PropTypes.bool,
  padding: PropTypes.number,
  style: PropTypes.any,
  type: PropTypes.string,
  width: PropTypes.string
};

export default PriceConditionTableColumn;
