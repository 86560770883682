import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/styles';
import {createDataRow, getVisibleChildren} from './priceConditionTableService';
import {
  TABLE_BODY_DISABLED_CELL_BACKGROUND_COLOR,
  TABLE_BODY_DISABLED_TEXT_COLOR,
  TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR,
  TABLE_BODY_HIGHLIGHTED_CELL_BACKGROUND_COLOR,
  TABLE_BODY_READONLY_CELL_BACKGROUND_COLOR
} from '../../../../theme/common/color';
import {getDefaultFontSize} from '../../../../theme/common/font';

const useStyles = makeStyles(() => ({
  readonlyTableBodyCell: {
    fontSize: '0.8125rem',
    '&.MuiTableCell-body': {
      backgroundColor: TABLE_BODY_READONLY_CELL_BACKGROUND_COLOR
    }
  },
  editableTableBodyCell: {
    fontSize: '0.8125rem',
    '&.MuiTableCell-body': {
      backgroundColor: TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR
    }
  },
  disabledTableBodyCell: {
    ...getDefaultFontSize(),
    '&.MuiTableCell-body': {
      backgroundColor: TABLE_BODY_DISABLED_CELL_BACKGROUND_COLOR,
      color: TABLE_BODY_DISABLED_TEXT_COLOR
    }
  },
  highlightedTableBodyCell: {
    ...getDefaultFontSize(),
    '&.MuiTableCell-body': {
      backgroundColor: TABLE_BODY_HIGHLIGHTED_CELL_BACKGROUND_COLOR
    }
  }
}));

const PriceConditionTableRowSection = ({children, data, defaultValue}) => {
  const classes = useStyles();

  let applicableChildren = getVisibleChildren(children);

  if (applicableChildren.length === 0) {
    return (<></>);
  }

  const headerCells = applicableChildren;

  const cellMetaData = applicableChildren
    .map(child => {
      return {
        fieldValueAccessor: child.props.field,
        infoTextAccessor: child.props.infoTextAccessor,
        isEditableFieldValueAccessor: child.props.isEditableFieldValueAccessor,
        isDisabledAccessor: child.props.isDisabledAccessor,
        type: child.props.type,
        cellAttributes: {
          colSpan: child.props.colSpan || 1
        },
        isHighlightedFieldValueAccessor: child.props.isHighlightedFieldValueAccessor,
        customStyles: child.props.customStyles || null,
        borderRight: child.props.borderRight || null
      }
    });


  const dataRows = (data || []).map((record, rowIndex) => createDataRow(cellMetaData, record, classes, rowIndex, defaultValue));

  return (
    <>
      <TableRow key={`header-${cellMetaData.map(c => c.fieldValueAccessor).join('-')}`}>
        {headerCells}
      </TableRow>
      {dataRows}
    </>
  );
};

const priceConditionTableColumnDefinition = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.shape({
    props: PropTypes.shape({
      colSpan: PropTypes.number,
      field: PropTypes.string,
      label: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string
      ]),
      type: PropTypes.string
    })
  })
]);

PriceConditionTableRowSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(priceConditionTableColumnDefinition),
    priceConditionTableColumnDefinition
  ]),
  data: PropTypes.array,
  defaultValue: PropTypes.any
};

export default PriceConditionTableRowSection;
