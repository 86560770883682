export const WITHOUT_RESTRICTION = Object.freeze({
  code: 'WITHOUT_RESTRICTION',
  desc: 'Without Restriction'
});
export const WITH_SIMPLE_RESTRICTION = Object.freeze({
  code: 'WITH_SIMPLE_RESTRICTION',
  desc: 'With Simple Restriction'
});
export const WITH_COMPLEX_RESTRICTION = Object.freeze({
  code: 'WITH_COMPLEX_RESTRICTION',
  desc: 'With Complex Restriction'
});
export const NON_STANDARD_RESTRICTION = Object.freeze({
  code: 'NON_STANDARD_RESTRICTION',
  desc: 'Non Standard Restriction'
});

export const getRecurringFeeImplementationDescriptionFromCode = (code) => {
  return getAllRecurringFeeImplementations()
    .filter(condition => condition.code === code)
    .map(condition => condition.desc)[0];
}

export const getAllRecurringFeeImplementations = () => {
  return [
    WITHOUT_RESTRICTION,
    WITH_SIMPLE_RESTRICTION,
    WITH_COMPLEX_RESTRICTION,
    NON_STANDARD_RESTRICTION
  ];
}