import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { CLIPP_BLACK, CLIPP_WHITE } from '../../../../theme/common/bjbColor';
import { SIDEBAR_BACKGROUND_COLOR, SIDEBAR_NAVIGATION_HOVER_COLOR } from '../../../../theme/common/color';
import { APP_SUPPORT, PRICING_MANAGER } from '../../../../common/enums/userRole';
import Authorized from '../../../../components/Authorized';

const useStyles = makeStyles((theme) => ({
  navPanel: {
    padding: theme.spacing(1),
    border: '1px',
    backgroundColor: CLIPP_WHITE
  },
  navButton: {
    color: CLIPP_BLACK,
    display: 'inline-flex',
    fontFamily: '\'Verlag SSm 7r\'',
    fontWeight: 'normal',
    justifyContent: 'left',
    marginBottom: theme.spacing(0.5),
    width: '100%',
    '&:hover': {
      border: 'none',
      color: SIDEBAR_NAVIGATION_HOVER_COLOR
    },
    textTransform: 'uppercase',
    textDecoration: 'none',
    padding: theme.spacing(0.5)
  },
  container: {
    backgroundColor: SIDEBAR_BACKGROUND_COLOR,
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  }
}));

const AdminNav = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.navPanel}>
        <Grid item xs={12}>
          <Link className={classes.navButton} to="/">
            HOME
          </Link>
        </Grid>
        <Divider style={{ marginBottom: 5 }} />
        <Authorized allowedRoles={[APP_SUPPORT]}>
          <Grid item xs={12}>
            <NavLink
              activeStyle={{ color: SIDEBAR_NAVIGATION_HOVER_COLOR }}
              className={classes.navButton}
              to="feecommissiontype"
            >
              FT/FD Codes
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              activeStyle={{ color: SIDEBAR_NAVIGATION_HOVER_COLOR }}
              className={classes.navButton}
              to="pricingrequest"
            >
              Pricing Requests
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              activeStyle={{ color: SIDEBAR_NAVIGATION_HOVER_COLOR }}
              className={classes.navButton}
              to="t24RequestResponse"
            >
              All T24 Messages
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              activeStyle={{ color: SIDEBAR_NAVIGATION_HOVER_COLOR }}
              className={classes.navButton}
              to="priceCheckRequestResponse"
            >
              All Price Check Messages
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              activeStyle={{ color: SIDEBAR_NAVIGATION_HOVER_COLOR }}
              className={classes.navButton}
              to="clientAgreementGenStatus"
            >
              All Client Agreements
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              activeStyle={{ color: SIDEBAR_NAVIGATION_HOVER_COLOR }}
              className={classes.navButton}
              to="pendingWorkflow"
            >
              Pending Workflows
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              activeStyle={{ color: SIDEBAR_NAVIGATION_HOVER_COLOR }}
              className={classes.navButton}
              to="cache"
            >
              Caches
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              activeStyle={{ color: SIDEBAR_NAVIGATION_HOVER_COLOR }}
              className={classes.navButton}
              to="pricingRequestDataPatch"
            >
              Pricing Request Data Patch
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              activeStyle={{ color: SIDEBAR_NAVIGATION_HOVER_COLOR }}
              className={classes.navButton}
              to="priceCheckMigration"
            >
              Price Check Migration
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <NavLink
              activeStyle={{ color: SIDEBAR_NAVIGATION_HOVER_COLOR }}
              className={classes.navButton}
              to="pricingRequestPatch"
            >
              Pricing Request Patch
            </NavLink>
          </Grid>
        </Authorized>
        <Authorized allowedRoles={[PRICING_MANAGER]}>
          <Grid item xs={12}>
            <NavLink
              activeStyle={{ color: SIDEBAR_NAVIGATION_HOVER_COLOR }}
              className={classes.navButton}
              to="fxGridManagement"
            >
              FX Grid Management
            </NavLink>
          </Grid>
        </Authorized>
      </div>
    </div>
  );
};

export default AdminNav;
