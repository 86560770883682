import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputLabel,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import adminApi from '../../../../../api/adminApi';
import { rem } from '../../../../../theme/common/font';
import { FX_GRID_ADD_SERVICE_MODELS } from '../constants';
import { CLIPP_BLACK } from '../../../../../theme/common/bjbColor';
import { useNotification } from '../../../../Common/components/Notification';
import { getErrorMessage } from '../../../../../common/getErrorMessage';
import FxDropdownList from './FxDropdownList';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow:
      '0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(1)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  inputField: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  label: {
    color: CLIPP_BLACK,
    fontSize: rem(14),
    width: '180px'
  },
  input: {
    '& .MuiInputBase-root': {
      width: 'clamp(230px, 25vw, 300px)'
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingBlock: '12px'
    }
  },
  modelText: {
    fontFamily: '"Verlag SSm 7r"',
    paddingBlock: theme.spacing(1.5)
  }
}));

const FxGridAdd = ({
  resetFxGridAddStates,
  setResetFxGridStates,
  setFxGridAddConfirmationDialogProps,
  setFxGridAddIsConfirmationDialogOpen
}) => {
  const classes = useStyles();
  const notification = useNotification();
  const [clippDisplayName, setClippDisplayName] = useState('');
  const [toolboxGridName, setToolboxGridName] = useState('');
  const [serviceModel, setServiceModel] = useState([]);

  useEffect(() => {
    setClippDisplayName('');
    setToolboxGridName('');
    setServiceModel([]);
  }, [resetFxGridAddStates]);

  const generateFxAddConfirmationText = () => {
    return (
      <Box style={{ fontSize: rem(16) }}>
        Please note that the following FX Grid will be added:
        <Typography className={classes.modelText}>
          {`${toolboxGridName} - ${clippDisplayName}`}
        </Typography>
        Do you want to proceed?
      </Box>
    );
  };

  const confirmFxGridAdd = async () => {
    const selectedServiceModels = FX_GRID_ADD_SERVICE_MODELS.filter((model) =>
      serviceModel.includes(model.value)
    );

    try {
      await adminApi.fxGrid
        .addFxGrid({
          clippGridName: clippDisplayName.trim(),
          toolboxGridName: toolboxGridName.trim(),
          serviceModels: selectedServiceModels.map((model) => model.key)
        })
        .then(() => {
          notification.success('FX Grid has been successfully added.');
          setResetFxGridStates((prevState) => !prevState);
        });
    } catch (error) {
      notification.error(getErrorMessage(error));
    } finally {
      setFxGridAddIsConfirmationDialogOpen(false);
    }
  };

  const onFXGridAdd = () => {
    if (!clippDisplayName.trim() || !toolboxGridName.trim() || serviceModel.length === 0) {
      notification.error(
        'Unable to add FX Grid. Please ensure that CliPP display name, Toolbox grid name, and Service model are provided.'
      );
    } else {
      setFxGridAddIsConfirmationDialogOpen(true);
      setFxGridAddConfirmationDialogProps({
        content: generateFxAddConfirmationText(),
        onClose: () => setFxGridAddIsConfirmationDialogOpen(false),
        onContinue: confirmFxGridAdd
      });
    }
  };

  return (
    <Grid data-testid="fx-grid-add" item xs={12}>
      <Card className={classes.card}>
        <CardContent>
          <Box className={classes.header}>
            <Typography variant="subtitle1">Add New FX Grid</Typography>
            <Button onClick={onFXGridAdd}>ADD</Button>
          </Box>
          <Box py={3} style={{ overflowX: 'auto' }}>
            <Box className={classes.inputField}>
              <InputLabel
                className={classes.label}
                htmlFor="clipp-display-name"
              >
                CliPP display name:
              </InputLabel>
              <TextField
                className={classes.input}
                id="clipp-display-name"
                onChange={(e) => setClippDisplayName(e.target.value)}
                placeholder="Enter display name"
                required
                size="small"
                value={clippDisplayName}
                variant="outlined"
              />
            </Box>
            <Box className={classes.inputField} my={2.5}>
              <InputLabel className={classes.label} htmlFor="toolbox-grid-name">
                Toolbox grid name:
              </InputLabel>
              <TextField
                className={classes.input}
                id="toolbox-grid-name"
                onChange={(e) => setToolboxGridName(e.target.value)}
                placeholder="Enter grid name"
                required
                size="small"
                value={toolboxGridName}
                variant="outlined"
              />
            </Box>
            <FxDropdownList
              data-testid="fx-add-service-model"
              dimension={{ width: 'clamp(340px, 36vw, 500px)' }}
              dropdownId="fx-add-service-model"
              items={FX_GRID_ADD_SERVICE_MODELS}
              label="Service model:"
              labelStyle={{
                color: CLIPP_BLACK,
                fontSize: rem(14),
                width: '180px'
              }}
              placeholder="Select service model applicability"
              placeholderStyle={{
                width: 'clamp(230px, 32vw, 500px)',
                position: 'absolute',
                left: '12px',
                top: '7px'
              }}
              updateValue={setServiceModel}
              value={serviceModel}
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FxGridAdd;
