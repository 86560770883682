import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Box, Grid, Typography} from '@material-ui/core';
import {useNotification} from '../../../Common/components/Notification';
import adminApi from '../../../../api/adminApi';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import AsyncButton from '../../../Common/components/AsyncButton';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import CommonDialog from '../../../Common/components/Dialog/CommonDialog';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  inner: {
    maxWidth: '1500px',
    overflowX: 'auto'
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

const PriceCheckMigration = () => {

  const classes = useStyles();
  const notification = useNotification();
  const [migrateCount, setMigrateCount] = useState(0);
  const [responseData, setResponseData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [enteredPricingRequestId, setEnteredPricingRequestId] = useState();
  const [isCancelDialogOpenByPricingRequestId, setIsCancelDialogOpenByPricingRequestId] = useState(false);
  const [responseDataByPricingRequestId, setResponseDataByPricingRequestId] = useState([]);
  const [isLoadingByPricingRequestId, setIsLoadingByPricingRequestId] = useState();
  const [isCancelDialogOpenNonOtc, setIsCancelDialogOpenNonOtc] = useState(false);
  const [migrateNonOtcCount, setMigrateNonOtcCount] = useState(0);

  const getMigrateCount = () => {
    setIsLoading(true);
    return adminApi.priceCheckWorkflow
      .getMigrateCount()
      .then(res => {
        setMigrateCount(res.data);
        setIsLoading(false);
        setIsCancelDialogOpen(false);
      })
      .catch((err) => {
        notification.error('Could not get migrate count.\n' + getErrorMessage(err));
      });
  };

  const migrateAll = () => {
    setIsLoading(true);
    return adminApi.priceCheckWorkflow
      .migrateAll()
      .then(res => {
        setResponseData(res.data);
        setIsLoading(false);
        setIsCancelDialogOpen(false);
      })
      .catch((err) => {
        notification.error('Could not load data.\n' + getErrorMessage(err));
      });
  };

  const migrateAllNonOtc = () => {
    setIsLoading(true);
    return adminApi.priceCheckWorkflow
      .migrateNonOtcAll()
      .then(res => {
        setResponseData(res.data);
        setIsLoading(false);
        setIsCancelDialogOpenNonOtc(false);
      })
      .catch((err) => {
        notification.error('Could not load data.\n' + getErrorMessage(err));
      });
  };

  const getMigrateNonOtcCount = () => {
    setIsLoading(true);
    return adminApi.priceCheckWorkflow
      .getMigrateNonOtcCount()
      .then(res => {
        setMigrateNonOtcCount(res.data);
        setIsLoading(false);
        setIsCancelDialogOpenNonOtc(false);
      })
      .catch((err) => {
        notification.error('Could not get migrate count.\n' + getErrorMessage(err));
      });
  };

  const initiateMigration = () => {
    return migrateAll();
  };

  const initiateMigrationNonOtc = () => {
    return migrateAllNonOtc();
  };

  const initiateDataPatchByPricingRequestId = () => {
    return applyByPricingRequestId(enteredPricingRequestId);
  };

  const applyByPricingRequestId = (pricingRequestId) => {
    setIsLoadingByPricingRequestId(true);
    return adminApi.priceCheckWorkflow
      .submitPricingRequestToBCOM(pricingRequestId)
      .then(res => {
        setResponseDataByPricingRequestId(res.data);
        setIsLoadingByPricingRequestId(false);
        setIsCancelDialogOpenByPricingRequestId(false);
        setEnteredPricingRequestId('')
      })
      .catch((err) => {
        notification.error('Could not load data.\n' + getErrorMessage(err));
        setIsLoadingByPricingRequestId(false);
      });
  };

  useEffect(() => {
    getMigrateCount();
    getMigrateNonOtcCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Grid container>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h1">
            Price Check Migration
          </Typography>

          <CommonDialog
            onClose={() => setIsCancelDialogOpen(false)}
            onContinue={() => initiateMigration()}
            open={isCancelDialogOpen}
            text={`You are about to migrate all the approved pricing request to BCOM/PriceCheckEngine.
                Do you want to continue?.`}
          />

          <AsyncButton
            onClick={async () => {
              setIsCancelDialogOpen(true);
            }}
            text="Initiate Migration"
          />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h6">
            Total pricing request(s) available to migrate : <b>{migrateCount}</b>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {isLoading ? <LoadingSpinner/> :
            <Typography className={classes.title} variant="h6">
              {responseData}
            </Typography>}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h1">
            Price Check Migration Non OTC
          </Typography>
          <CommonDialog
            onClose={() => setIsCancelDialogOpenNonOtc(false)}
            onContinue={() => initiateMigrationNonOtc()}
            open={isCancelDialogOpenNonOtc}
            text={`You are about to migrate all the Non OTC Patched pricing request to BCOM/PriceCheckEngine.
              Do you want to continue?.`}
          />
          <AsyncButton
            onClick={async () => {
              setIsCancelDialogOpenNonOtc(true);
            }}
            text="Initiate Migration"
          />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h6">
            Total pricing request(s) available to migrate : <b>{migrateNonOtcCount}</b>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {isLoading ? <LoadingSpinner/> :
            <Typography className={classes.title} variant="h6">
              {responseData}
            </Typography>}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h1">
            Pricing Check Migration - By Pricing Request Id
          </Typography>

          <CommonDialog
            onClose={() => setIsCancelDialogOpenByPricingRequestId(false)}
            onContinue={() => initiateDataPatchByPricingRequestId()}
            open={isCancelDialogOpenByPricingRequestId}
            text={`You are about to migrate pricing request to BCOM/PriceCheckEngine.
              Do you want to continue?.`}
          />

          <AsyncButton
            onClick={async () => {
              if(enteredPricingRequestId && enteredPricingRequestId.trim().length > 0) {
                setIsCancelDialogOpenByPricingRequestId(true);
              }
            }}
            text="Initiate Data Patch"
          />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h6">
            <TextField
              onChange={function (event) {
                if (event.target.value) {
                  setEnteredPricingRequestId(event.target.value);
                }
              }}
              placeholder="Enter pricing request Id"
              variant="outlined"
            />
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {isLoadingByPricingRequestId ? <LoadingSpinner/> :
            <Typography className={classes.title} variant="h6">
              {responseDataByPricingRequestId}
            </Typography>}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PriceCheckMigration;
