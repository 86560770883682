import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import LabelTextField from '../../../../Common/components/tableFilter/LabelTextField';

const WorkflowPendingFilter = ({handleFind,
  handlePricingRequestIdFilter,
  handlePortfolioFilter,
  selectedPricingRequestIdFilter,
  selectedPortfolioFilter}) => {

  return (
    <Box alignItems="center" display="flex" marginBottom={2}>
      <LabelTextField
        label="Pricing Request Id"
        onChange={handlePricingRequestIdFilter}
        onClear={handleFind}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFind(e);
          }
        }}
        style={{width: '25ch'}}
        value={selectedPricingRequestIdFilter}
      />
      &nbsp;
      <LabelTextField
        label="Portfolio Number"
        onChange={handlePortfolioFilter}
        onClear={handleFind}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFind(e);
          }
        }}
        style={{width: '25ch'}}
        value={selectedPortfolioFilter}
      />
    </Box>
  );
};

WorkflowPendingFilter.propTypes = {
  handleFind: PropTypes.func,
  handlePortfolioFilter: PropTypes.func,
  handlePricingRequestIdFilter: PropTypes.func,
  selectedPortfolioFilter: PropTypes.string,
  selectedPricingRequestIdFilter: PropTypes.number
};

export default WorkflowPendingFilter;
