import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Grid, Paper} from '@material-ui/core';
import {SidebarNav} from './components';

import {Sidebar, TopBar} from '../Common/components';
import {IntlProvider} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {TENANT_ID_QUERY} from '../../common/queryParams';
import {X_TENANT_ID} from '../../common/headers';
import {getTenantDescriptionFromCode} from '../../common/enums/tenant';
import KpiDashboard from '../../views/KpiDashboard';
import BackgroundImage from '../../assets/images/bg.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 55
    }
  },
  shiftContent: {
    paddingLeft: 238
  },
  content: {
    height: '100%',
    minHeight: '80vh'
  },
  bjbContent: {
    minHeight: '500px',
    padding: '10px',
    boxShadow:
      'inset 0px 15px 15px -10px rgba(0,0,0,0.2), inset 0px -15px 15px -10px rgba(0,0,0,0.2)',
    backgroundColor: '#F3F3EC',
    '& .MuiGrid-container': {}
  },
  backgroundImg: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
  }
}));

const Main = props => {
  const {children} = props;

  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tenantId = queryParams.get(TENANT_ID_QUERY);

  useEffect(() => {
    if (tenantId) {
      let tenantDesc = getTenantDescriptionFromCode(tenantId);
      if (tenantDesc) {
        sessionStorage.setItem(X_TENANT_ID, tenantDesc);
      }
    }
  }, [tenantId])

  return (
    <div>
      <TopBar logo/>

      <div className={classes.bjbContent}>
        <div className={classes.backgroundImg}>
          <KpiDashboard />
          <Grid
            alignItems="stretch"
            container
            direction="row"
            justify="center"
            spacing={1}
          >
            <Grid
              item
              lg={2}
              md={3}
              xs={12}
            >
              <Sidebar title="PORTFOLIO SELECTION">
                <Paper elevation={0} variant={'elevation'}>
                  <SidebarNav/>
                </Paper>
              </Sidebar>
            </Grid>
            <Grid
              item
              lg={10}
              md={9}
              xs={12}
            >
              <Paper className={classes.paper}>
                <IntlProvider locale={'en'}>
                  <main className={classes.content}>{children}</main>
                </IntlProvider>
              </Paper>
            </Grid>
            <Grid
              item
              lg={1}
              md={false}
              xs={false}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
