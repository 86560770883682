import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import LabelDropdown from '../../../../../Common/components/tableFilter/LabelDropdown';
import PRICING_REQUEST_STATUS from '../../../../../../common/enums/pricingRequestStatus';
import T24_SYNC_STATUS from '../../../../../../common/enums/t24SyncStatus';
import LabelTextField from '../../../../../Common/components/tableFilter/LabelTextField';

const PricingRequestFilter = ({handleFilterPricingStatus, handleFilterT24Status, selectedPricingStatusFilter, selectedT24StatusFilter, currentPortfolioFilter, handlePortfolioFilter, handleFind, handlePricingRequestIdFilter, selectedPricingRequestIdFilter}) => {

  const t24StatusFilterOptions = Object.entries(T24_SYNC_STATUS).map(([key, value]) => ({
    key,
    label: value
  }));

  const pricingStatusFilterOptions = Object.entries(PRICING_REQUEST_STATUS).map(([key, value]) => ({
    key,
    label: value
  }));

  return (
    <Box alignItems="center" display="flex" marginBottom={2}>
      <LabelTextField
        label="Pricing Request Id"
        onChange={handlePricingRequestIdFilter}
        onClear={handleFind}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFind(e);
          }
        }}
        style={{width: '15ch'}}
        value={selectedPricingRequestIdFilter}
      />
      &nbsp;
      <LabelTextField
        label="Portfolio"
        onChange={handlePortfolioFilter}
        onClear={handleFind}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFind(e);
          }
        }}
        style={{width: '25ch'}}
        value={currentPortfolioFilter}
      />
      <LabelDropdown
        label="Filter Pricing Request status"
        onChange={handleFilterPricingStatus}
        options={pricingStatusFilterOptions}
        value={selectedPricingStatusFilter}
      />
      <LabelDropdown
        label="Filter T24 status"
        onChange={handleFilterT24Status}
        options={t24StatusFilterOptions}
        value={selectedT24StatusFilter}
      />
    </Box>
  );
};

PricingRequestFilter.propTypes = {
  currentPortfolioFilter: PropTypes.string,
  handleFilterPricingStatus: PropTypes.func,
  handleFilterT24Status: PropTypes.func,
  handleFind: PropTypes.func,
  handlePortfolioFilter: PropTypes.func,
  handlePricingRequestIdFilter: PropTypes.func,
  selectedPricingRequestIdFilter: PropTypes.string,
  selectedPricingStatusFilter: PropTypes.string,
  selectedT24StatusFilter: PropTypes.string
};

export default PricingRequestFilter;
