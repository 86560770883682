export const PRICING_REQUEST_ID_COLUMN = {
  label: 'ID',
  key: 'pricingRequestId'
};

export const PORTFOLIO_NUMBER_COLUMN = {
  label: 'Portfolio Number',
  key: 'portfolioNumber'
};

export const CREATED_BY_COLUMN = {
  label: 'Created by',
  key: 'createdBy'
};

export const CREATED_DATE_COLUMN = {
  label: 'Created date',
  key: 'createdDate'
};

export const UPDATED_DATE_COLUMN = {
  label: 'Updated date',
  key: 'updatedDate'
};

export const UPDATED_BY_COLUMN = {
  label: 'Updated by',
  key: 'updatedBy'
};

export const PRICING_REQUEST_STATUS_COLUMN = {
  label: 'Status',
  key: 'pricingRequestStatus'
};

export const T24_STATUS_COLUMN = {
  label: 'T24 Status',
  key: 't24SyncStatus'
};

export const CODES_COLUMN = {
  label: 'Pricing Codes',
  key: 'pricingCodesColumn'
};

export const ACTION_COLUMN = {
  label: 'Action',
  key: 'actionColumn'
};

export const columnsData = [
  PRICING_REQUEST_ID_COLUMN,
  PORTFOLIO_NUMBER_COLUMN,
  CREATED_DATE_COLUMN,
  CREATED_BY_COLUMN,
  UPDATED_DATE_COLUMN,
  UPDATED_BY_COLUMN,
  PRICING_REQUEST_STATUS_COLUMN,
  T24_STATUS_COLUMN,
  CODES_COLUMN,
  ACTION_COLUMN
];
