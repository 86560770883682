import {
  LOAD_TRANSACTION_APPLICABLE_FEES_RESPONSE,
  UPDATE_TRANSACTION_FEES,
  UPDATE_TRANSACTION_FEES_LIST_PRICES,
  UPDATE_TRANSACTION_FEES_VALIDATION_RESULT
} from './transactionFeesActions';
import {
  FLAT_RATE,
  getTransactionFeeConditionDescriptionFromCode,
  NON_STANDARD_CUMULATIVE,
  NON_STANDARD_NON_CUMULATIVE,
  PERCENTAGE_DISCOUNT,
  TICKET_FEE
} from '../../../../common/enums/transactionFeeCondition';
import {LOAD_PRICING_REQUEST_RESPONSE} from '../pricingRequestActions';

export const transactionFeesReducer = (state, action) => {
  switch (action.type) {
    case LOAD_TRANSACTION_APPLICABLE_FEES_RESPONSE: {
      return {
        ...state,
        applicableFees: action.payload.data
      };
    }
    case LOAD_PRICING_REQUEST_RESPONSE: {
      if (!action.payload.data.transactionFees) {
        return null;
      }

      return {
        ...action.payload.data.transactionFees,
        applicableFees: {...state?.applicableFees},
        errors: []
      };
    }
    case UPDATE_TRANSACTION_FEES_VALIDATION_RESULT: {
      let newErrors = (state.errors || []).filter((e) => e !== action.selector);
      if (!action.isValid) {
        newErrors = [...state.errors, action.selector];
      }
      return {
        ...state,
        errors: newErrors
      };
    }
    case UPDATE_TRANSACTION_FEES_LIST_PRICES: {
      let applicableFees = {};
      let updatedTransactionAssetTypes = state.transactionAssetTypes;
      if (![NON_STANDARD_CUMULATIVE.code, NON_STANDARD_NON_CUMULATIVE.code].includes(action.conditionCode)) {
        applicableFees = getApplicableFees({
          applicableFees: state.applicableFees,
          conditionCode: action.conditionCode
        });
        updatedTransactionAssetTypes = getUpdatedTransactionAssetTypes({
          transactionAssetTypes: state.transactionAssetTypes,
          applicableFees: applicableFees
        });
      }

      return {
        ...state,
        newCondition: {
          code: action.conditionCode,
          desc: getTransactionFeeConditionDescriptionFromCode(action.conditionCode)
        },
        transactionAssetTypes: updatedTransactionAssetTypes,
        errors: []
      };
    }
    case UPDATE_TRANSACTION_FEES: {
      return {
        ...state,
        ...action.data,
        errors: action.errors
      };
    }
    default:
      return state;
  }
};

const getApplicableFees = ({applicableFees, conditionCode}) => {
  switch (conditionCode) {
    case FLAT_RATE.code:
      return applicableFees.flatRate;
    case TICKET_FEE.code:
      return applicableFees.ticketFee;
    case PERCENTAGE_DISCOUNT.code:
      return applicableFees.percentageDiscount;
    default:
      throw new Error('Unknown conditionCode - Cannot update list prices');
  }
};

const getUpdatedTransactionAssetTypes = ({applicableFees, transactionAssetTypes}) => {
  let updatedFunds = {};
  for (const [fundTypeCode, subFunds] of Object.entries(transactionAssetTypes)) {
    let updatedSubFunds = [];
    for (const subFund of subFunds) {
      const assetSubLevelCode = subFund.assetSubLevel.code;
      const applicableSubFundFees = getApplicableSubFundFees({applicableFees, assetSubLevelCode});
      const updatedSubFund = {
        ...subFund,
        newCondition: applicableSubFundFees.newCondition
      };
      updatedSubFunds.push(updatedSubFund);
    }
    updatedFunds[fundTypeCode] = updatedSubFunds;
  }
  return updatedFunds;
};

const getApplicableSubFundFees = ({applicableFees, assetSubLevelCode}) => {
  for (const subFunds of Object.values(applicableFees.transactionAssetTypes)) {
    for (const subFund of subFunds) {
      if (assetSubLevelCode === subFund.assetSubLevel.code) {
        return subFund;
      }
    }
  }
  return null;
};
