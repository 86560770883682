import React from 'react';
import PropTypes from 'prop-types';
import {getFXProductTypeFromCode} from '../../../../../common/enums/fxProductTypes';
import FxPreciousMetalsProductType from './FxPreciousMetalsProductType';

const FxPreciousMetalsProductTypes = ({fxProductTypes, handleValueChange, handleValidationResultChange, readOnly, showAgreementIndicator}) => {
  const getSortedProductTypes = (fxProductTypes) => {
    return Object.entries(fxProductTypes).sort(([iCode], [jCode]) => {
      const prevCode = getFXProductTypeFromCode(iCode);
      const nextCode = getFXProductTypeFromCode(jCode);
      if (!prevCode || !nextCode) {
        return -1;
      }
      return prevCode.sortOrder - nextCode.sortOrder
    });
  }
  let productSubTypes = [];

  for (const [code, subLevels] of getSortedProductTypes(fxProductTypes)) {
    productSubTypes.push(
      <FxPreciousMetalsProductType
        fxProductTypeCode={code}
        fxSubTypes={subLevels}
        handleValidationResultChange={({selectors, ...others}) => handleValidationResultChange({
          ...others,
          selectors: [code, ...selectors]
        })}
        handleValueChange={({selectors, ...others}) => handleValueChange({
          ...others,
          selectors: [code, ...selectors]
        })}
        key={`fx-product-type-${code}`}
        readOnly={readOnly}
        showAgreementIndicator={showAgreementIndicator}
      />
    )
  }
  return (
    <>
      {productSubTypes}
    </>
  );

};

FxPreciousMetalsProductTypes.propTypes = {
  fxProductTypes: PropTypes.object.isRequired,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showAgreementIndicator: PropTypes.bool,
}

export default React.memo(FxPreciousMetalsProductTypes);
