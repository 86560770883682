import React from 'react';
import {INVESTMENT_FUNDS} from '../../../../../../common/enums/transactionFeeFundType';
import {PRIVATE_EQUITY} from '../../../../../../common/enums/transactionAssetSubLevel';
import Box from '@material-ui/core/Box';
import PriceSelect from '../../../../../Common/components/PriceSelect';

const LABEL = 'Waive Charges on Redemption';
const KEY_PREFIX = 'transaction-fees-waive-charges-on-redemption';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const waiveChargesOnRedemption = ({fundTypeCode, subFund, handleValueChange, readOnly}) => {

  if (
    fundTypeCode !== INVESTMENT_FUNDS.code || subFund?.assetSubLevel?.code === PRIVATE_EQUITY.code
  ) {
    return null;
  }

  let result = {
    label: LABEL
  };

  if (subFund.currentCondition && typeof subFund.currentCondition.waiveChargesOnRedemption !== 'undefined') {
    result = {
      ...result,
      currentCondition: getMappedOldCondition(subFund.currentCondition?.waiveChargesOnRedemption)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(subFund.newCondition?.waiveChargesOnRedemption, handleValueChange, readOnly)
  }

};

const getMappedOldCondition = (waiveChargesOnRedemption) => {

  const readonlyField = (
    <Box data-testid={`${TEST_KEY_PREFIX}-readonly`} textAlign="right">{waiveChargesOnRedemption ? 'Yes' : 'No'}</Box>
  );

  return {
    effectivePrice: readonlyField
  };
};

const getMappedNewCondition = (waiveChargesOnRedemption, handleValueChange, readOnly) => {

  const options = [
    {key: true, value: 'Yes'},
    {key: false, value: 'No'}
  ];

  const editableField = (
    <PriceSelect
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      inputProps={{id: 'uncontrolled-native'}}
      key={`${KEY_PREFIX}-editable`}
      onValueChange={(vc) => handleValueChange({...vc, selectors: ['newCondition', 'waiveChargesOnRedemption']})}
      options={options}
      style={{textAlign: 'right'}}
      value={waiveChargesOnRedemption}
    />
  );

  const readonlyField = (
    <Box data-testid={`${TEST_KEY_PREFIX}-readonly`} textAlign="right">{waiveChargesOnRedemption ? 'Yes' : 'No'}</Box>
  );

  return {
    effectivePrice: readOnly ? readonlyField : editableField,
    isEditable: !readOnly
  };
};
