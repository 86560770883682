import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import AgreementTypeIndicator from '../../Common/AgreementTypeIndicator';
import {BILA} from '../../../../../common/enums/agreementType';
import {PENDING_PM} from '../../../../../common/statusTypes';
import FxPreciousMetalsSubAssetConditionTable from './FxPreciousMetalsSubAssetConditionTable';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  }
}));

const FxPreciousMetalsPanel = ({
  agentType, fxProductTypeCode, handleValidationResultChange, handleValueChange, pricingRequestStatus,
  readOnly, showAgreementIndicator, serviceModel, fxSubType
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container >
        <Grid item xs={6}>
          {fxSubType?.pbUrl && <Box data-testid={`test-fx-${fxSubType.transactionAssetSubLevel.code}-pb-url`} display={'flex'}>
            <Link href={fxSubType?.pbUrl} rel="noopener noreferrer" target="_blank">
              PB GRID
            </Link>
          </Box>}
          {fxSubType?.eamUrl && <Box data-testid={`test-fx-${fxSubType.transactionAssetSubLevel.code}-eam-url`} display={'flex'}>
            <Link href={fxSubType?.eamUrl} rel="noopener noreferrer" target="_blank">
              EAM GRID
            </Link>
          </Box>}
        </Grid>
        <Grid item xs={6}>
          <AgreementTypeIndicator
            agreedWithClient={fxSubType?.newCondition?.agreementEnum?.code}
            handleChange={(val) => {
              handleValueChange({ value: val ? { ...BILA } : null, selectors: ['newCondition', 'agreementEnum'] });
            }}
            other={{ inputProps: { 'data-testid': `test-agreement-type-indicator-${fxSubType.transactionAssetSubLevel.code}-checkbox` } }}
            readOnly={readOnly || pricingRequestStatus === PENDING_PM}
            show={showAgreementIndicator}
          />
        </Grid>
        <FxPreciousMetalsSubAssetConditionTable
          agentType={agentType}
          fxProductTypeCode={fxProductTypeCode}
          fxSubType={fxSubType}
          handleValidationResultChange={handleValidationResultChange}
          handleValueChange={handleValueChange}
          newConditionCode={fxSubType?.newCondition?.transactionFeeCondition?.code}
          oldConditionCode={fxSubType?.currentCondition?.transactionFeeCondition?.code}
          pricingRequestStatus={pricingRequestStatus}
          readOnly={readOnly}
          serviceModel={serviceModel}
        />
      </Grid>
    </div>);
};

FxPreciousMetalsPanel.propTypes = {
  agentType: PropTypes.string,
  fxProductTypeCode: PropTypes.string.isRequired,
  fxSubType: PropTypes.object.isRequired,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  pricingRequestStatus: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  serviceModel: PropTypes.string.isRequired,
  showAgreementIndicator: PropTypes.bool
};

export default React.memo(FxPreciousMetalsPanel);
