import React from 'react';
import PriceNumberFormat from '../../../../../Common/components/PriceNumberFormat';
import {CHF} from '../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {FID_CALL_MONEY} from '../../../../../../common/enums/transactionAssetSubLevel';

const LABEL = 'Amendment Fee';
const KEY_PREFIX = 'transaction-fees-amendment-fee';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const amendmentFee = ({subFund, handleValidationResultChange, handleValueChange, readOnly, specialRequest}) => {

  if (FID_CALL_MONEY.code !== subFund.assetSubLevel.code) {
    return null;
  }

  let result = {
    label: LABEL
  };

  if (subFund.currentCondition?.amendmentFee) {
    result = {
      ...result,
      currentCondition: getMappedOldCondition(subFund)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(subFund, handleValidationResultChange, handleValueChange, readOnly, specialRequest)
  }

};

const getMappedOldCondition = (subFund) => {
  const oldAmendmentFee = subFund.currentCondition?.amendmentFee;

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={oldAmendmentFee?.effectivePrice}
    />
  );

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (subFund, handleValidationResultChange, handleValueChange, readOnly, specialRequest) => {
  const newAmendmentFee = subFund.newCondition?.amendmentFee;

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      key={`${KEY_PREFIX}-list-price`}
      type={CHF}
      value={newAmendmentFee?.listPrice}
    />
  );

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-editable`}
      isEditable
      key={`${KEY_PREFIX}-effective-price-editable`}
      max={specialRequest ? null : newAmendmentFee?.listPrice}
      onValidationResultChange={(vr) => handleValidationResultChange({
        ...vr,
        selectors: ['newCondition', 'amendmentFee', 'effectivePrice']
      })}
      onValueChange={(vr) => handleValueChange({...vr, selectors: ['newCondition', 'amendmentFee', 'effectivePrice']})}
      type={CHF}
      value={newAmendmentFee?.effectivePrice}
    />
  );

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={newAmendmentFee?.effectivePrice}
    />
  );

  return {
    effectivePrice: readOnly ? effectivePriceReadonly : effectivePriceEditable,
    isEditable: !readOnly,
    listPrice: listPrice
  };
};
