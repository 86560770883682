import React from 'react';
import PropTypes from 'prop-types';
import {InputLabel, MenuItem, Select} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CLIPP_BLACK} from '../../../../../theme/common/bjbColor';
import {getDefaultFontSize} from '../../../../../theme/common/font';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  selectBox: {
    width: '15ch',
  },
  label: {
    ...getDefaultFontSize(theme),
    marginRight: theme.spacing(1),
    color: CLIPP_BLACK
  }
}));

const LabelDropdown = ({label, onChange, options, value, ...other}) => {
  const classes = useStyles();
  const labelId = label.toLowerCase().split(' ').join('-');

  return (
    <div className={classes.root}>
      <InputLabel className={classes.label} id={labelId}>
        {label}
      </InputLabel>
      <Select
        className={classes.selectBox}
        data-testid={other['data-testid']}
        labelId={labelId}
        onChange={onChange}
        value={value || ''}
      >
        <MenuItem>--</MenuItem>
        {options &&
        options.map((data) => (
          <MenuItem key={data.key} value={data.key}>
            {data.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

LabelDropdown.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string
};

export default LabelDropdown;
