export const FX_GRID_ADD_SERVICE_MODELS = [
  { key: 'COMMERCIAL_ACT_CASH', value: 'Commercial Accounts - Cash' },
  { key: 'COMMERCIAL_ACT_STRATEGIC_HOLDINGS', value: 'Commercial Accounts - Strategic Holdings' },
  { key: 'EAM_EFA_MANAGED', value: 'EAM Managed / EFA Managed' },
  { key: 'EXECUTION_ONLY', value: 'Execution Only' },
  { key: 'GLOBAL_CUSTODY', value: 'Global Custody' },
  { key: 'IA_DIRECT', value: 'Investment Advisory Direct' },
  { key: 'IA_INDIRECT', value: 'Investment Advisory Indirect' },
  { key: 'PSR', value: 'Products & Securities Recommendation (PSR)' }
];
