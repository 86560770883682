import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import AllPriceRequestTab from './components/AllPriceRequestTab';
import AccountTab from './components/AccountTab/AccountTab';
import TabPanel from '../../../Common/components/TabPanel/TabPanel';
import ApprovalTab from './components/ApprovalTab';
import Authorized from '../../../../components/Authorized';
import {
  getAcmRoles,
  getAllPricingRequestRoles,
  getApproverRoles,
  getRequesterRoles,
  getSpecialRequestRoles
} from '../../../../common/enums/userRole';
import {Store} from '../../../../Store';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import {Typography} from '@material-ui/core';
import {useLocation} from 'react-router-dom';
import {APPROVAL_QUERY} from '../../../../common/queryParams';
import PricingManagerDashboardTab from './components/PricingManagerDashboardTab';
import AccountManagementTab from './components/AccountManagementTab';
import {withStyles} from '@material-ui/styles';
import { SET_PENDING_REQUEST_FLAG } from '../../../../common/actionTypes';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
    'data-testid': `test-home-tab-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  tabpanel: {
    height: '100%',
    backgroundColor: '#fff',
    padding: '0px',
    borderBottom: '0px solid #e3e5e5',
    borderRight: '0px solid #e3e5e5',
    borderLeft: '0px solid #e3e5e5'
  },
  pageHeader: {
    fontSize: '28px',
    lineHeight: '43px',
    color: '#141e55',
    fontFamily: 'Verlag Office,sans-serif',
    fontWeight: '300',
    textTransform: 'uppercase'
  },
  pageSubHeader: {
    fontSize: '18px',
    lineHeight: '43px',
    color: '#141e55',
    fontFamily: 'Verlag Office,sans-serif',
    fontWeight: '300'
  },
  paperComponentTitle: {
    padding: theme.spacing(0.5),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    borderRadius: '0px',
    backgroundColor: '#6672b8'
  },
  componentTitle: {
    fontSize: '18px',
    lineHeight: '24px',
    color: '#3343a1',
    fontFamily: 'Verlag Office,sans-serif',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  table: {
    border: '1px solid #CCCCCC'
  },
  infoText: {
    textAlign: 'center'
  }
}));

const Tab = withStyles({
  root: {
    whiteSpace: 'nowrap',
    maxWidth: 'none'
  }
})(MuiTab);

const HomeTab = () => {
  const NEW_REQUEST_TAB_INDEX = 'new';
  const APPROVAL_TAB_INDEX = 'approval';
  const SPECIAL_REQUEST_TAB_INDEX = 'pm-dashboard';
  const ACM_TAB_INDEX = 'acm-dashboard';
  const ALL_REQUEST_TAB_INDEX = 'all';
  const classes = useStyles();
  const [value, setValue] = useState();
  const [newRequestTabAccess, setNewRequestTabAccess] = useState(false);
  const [approvalTabAccess, setApprovalTabAccess] = useState(false);
  const [allRequestTabAccess, setAllRequestTabAccess] = useState(false);
  const [specialTabAccess, setSpecialTabAccess] = useState(false);
  const [acmTabAccess, setAcmTabAccess] = useState(false);
  const {parentState, parentDispatch} = useContext(Store);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const approval = queryParams.get(APPROVAL_QUERY);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (parentState.user?.userRoles) {
      const userRoles = parentState.user.userRoles.map(userRole => userRole.role);
      const newRequestTabAccess = getRequesterRoles().some(role => userRoles.includes(role.code));
      const approvalTabAccess = getApproverRoles().some(role => userRoles.includes(role.code));
      const allRequestTabAccess = getAllPricingRequestRoles().some(role => userRoles.includes(role.code));
      const specialTabAccess = getSpecialRequestRoles().some(role => userRoles.includes(role.code));
      const acmTabAccess = getAcmRoles().some(role => userRoles.includes(role.code));
      setNewRequestTabAccess(newRequestTabAccess);
      setAllRequestTabAccess(allRequestTabAccess);
      setApprovalTabAccess(approvalTabAccess);
      setSpecialTabAccess(specialTabAccess);
      setAcmTabAccess(acmTabAccess);

      if (approvalTabAccess && approval !== null) {
        // Set to approval tab if the link contains approval param (link is from email to approver)
        setValue(APPROVAL_TAB_INDEX);
      } else if (newRequestTabAccess) {
        setValue(NEW_REQUEST_TAB_INDEX);
      } else if (specialTabAccess) {
        setValue(SPECIAL_REQUEST_TAB_INDEX);
      } else if (acmTabAccess) {
        setValue(ACM_TAB_INDEX);
      } else if (approvalTabAccess) {
        setValue(APPROVAL_TAB_INDEX);
      } else if (allRequestTabAccess) {
        setValue(ALL_REQUEST_TAB_INDEX);
      }

      if (approvalTabAccess) {
        parentDispatch({type: SET_PENDING_REQUEST_FLAG, payload: true});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentState.user?.userRoles]);

  if (!parentState.user.userRoles || (!newRequestTabAccess && !approvalTabAccess && !allRequestTabAccess)) {
    return (
      <Typography className={classes.infoText} data-testid="test-invalid-role">
        You do not have access to this application
      </Typography>
    );
  } else if (!value) {
    return <LoadingSpinner/>;
  }

  return (
    <div style={{backgroundColor: '#fff'}}>
      <AppBar color="default" elevation={0} position="static">
        <Tabs
          indicatorColor="secondary"
          onChange={handleChange}
          scrollButtons="auto"
          value={value}
          variant="scrollable"
        >
          {newRequestTabAccess && (
            <Tab label="NEW PRICING REQUEST" value="new" {...a11yProps(NEW_REQUEST_TAB_INDEX)} />
          )}
          {approvalTabAccess && (
            <Tab label="PRICING REQUEST APPROVAL" value="approval" {...a11yProps(APPROVAL_TAB_INDEX)} />
          )}
          {specialTabAccess && (
            <Tab
              label="PRICING MANAGER DASHBOARD"
              value="pm-dashboard"
              {...a11yProps(SPECIAL_REQUEST_TAB_INDEX)}
            />
          )}
          {acmTabAccess && (
            <Tab label="ACCOUNT MANAGEMENT DASHBOARD" value="acm-dashboard" {...a11yProps(ACM_TAB_INDEX)} />
          )}
          {allRequestTabAccess && (
            <Tab label="ALL PRICING REQUESTS" value="all" {...a11yProps(ALL_REQUEST_TAB_INDEX)} />
          )}
        </Tabs>
      </AppBar>
      <Authorized allowedRoles={getRequesterRoles()}>
        <TabPanel className={classes.tabpanel} index="new" value={value}>
          <AccountTab/>
        </TabPanel>
      </Authorized>
      <Authorized allowedRoles={getApproverRoles()}>
        <TabPanel className={classes.tabpanel} index="approval" value={value}>
          <ApprovalTab/>
        </TabPanel>
      </Authorized>
      <Authorized allowedRoles={getSpecialRequestRoles()}>
        <TabPanel className={classes.tabpanel} index="pm-dashboard" value={value}>
          <PricingManagerDashboardTab/>
        </TabPanel>
      </Authorized>
      <Authorized allowedRoles={getAcmRoles()}>
        <TabPanel className={classes.tabpanel} index="acm-dashboard" value={value}>
          <AccountManagementTab/>
        </TabPanel>
      </Authorized>
      <Authorized allowedRoles={getAllPricingRequestRoles()}>
        <TabPanel className={classes.tabpanel} index="all" value={value}>
          <AllPriceRequestTab/>
        </TabPanel>
      </Authorized>
    </div>
  );
};

export default HomeTab;
