import {Button} from '@material-ui/core';
import React, {useContext, useState} from 'react';
import {PriceRequestStore} from '../../../../PriceSimulation/PriceSimulationStore';
import {useNotification} from '../../Notification';
import CancelConfirmationDialog from './components/CancelConfirmationDialog';
import api from '../../../../../api/api';
import {Store} from '../../../../../Store';
import {RESET, SELECT_PORTFOLIOS} from '../../../../../common/actionTypes';
import {useHistory} from 'react-router-dom';
import {getErrorMessage} from '../../../../../common/getErrorMessage';

const CancelRequestButton = ({...others}) => {
  const notification = useNotification();
  const history = useHistory();
  const {parentState, parentDispatch} = useContext(Store);
  const {state} = useContext(PriceRequestStore);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [ongoing, setOngoing] = useState(false);

  const handleCancelPricingRequest = async () => {
    try {
      setOngoing(true);
      await api.pricingRequest.cancel([state.selectedPricingRequest.common.pricingRequestId]);
      // If there is other portfolios, open the next portfolio, else go to homepage
      const newPortfolios = parentState.portfolios.filter(
        portfolio => portfolio.portfolioNumber !== state.selectedPricingRequest.common.portfolioNumber
      );
      let nextPage = '/';
      if (newPortfolios.length > 0) {
        parentDispatch({type: SELECT_PORTFOLIOS, payload: newPortfolios});
        setConfirmationDialogOpen(false);
        nextPage = `/simulation/${newPortfolios[0].pricingRequestId}`;
      } else {
        parentDispatch({type: RESET});
      }
      setConfirmationDialogOpen(false);
      notification.success('Pricing request is cancelled.');
      setOngoing(false);
      history.push(nextPage);

    } catch (err) {
      notification.error('Failed cancelling pricing request \n' + getErrorMessage(err));
      setOngoing(false);
      throw err;
    }
  };

  return (
    <React.Fragment>
      <CancelConfirmationDialog
        onClose={() => setConfirmationDialogOpen(false)}
        onContinueCancel={handleCancelPricingRequest}
        open={confirmationDialogOpen}
        portfolioNumber={state.selectedPricingRequest.common.portfolioNumber}
      />
      <Button color="primary" disabled={ongoing} onClick={() => setConfirmationDialogOpen(true)} {...others}>
        Cancel
      </Button>
    </React.Fragment>
  );
};

export default CancelRequestButton;
