import React from 'react'
import {Typography} from '@material-ui/core'

function AdminMain() {
  return (
    <div>
      <Typography variant="h1">Administrative Page</Typography>
    </div>
  )
}

export default AdminMain
