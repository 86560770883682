export const TIERED_FEE = Object.freeze({
  code: 'TIERED_FEE',
  desc: 'Tiered Fee'
});
export const FLAT_RATE = Object.freeze({
  code: 'FLAT_RATE',
  desc: 'Flat rate in bps'
});
export const TICKET_FEE = Object.freeze({
  code: 'TICKET_FEE',
  desc: 'Ticket fee'
});
export const PERCENTAGE_DISCOUNT = Object.freeze({
  code: 'PERCENTAGE_DISCOUNT',
  desc: '% Discount'
});

export const NON_STANDARD_CUMULATIVE = Object.freeze({
  code: 'NON_STANDARD_CUMULATIVE',
  desc: 'Apply Non-Standard Price (Cumulative)',
})

export const NON_STANDARD_NON_CUMULATIVE = Object.freeze({
  code: 'NON_STANDARD_NON_CUMULATIVE',
  desc: 'Apply Non-Standard Price (Non-Cumulative)',
})

export const getTransactionFeeConditionDescriptionFromCode = (code) => {
  return getAllTransactionFeeConditions
    .filter(condition => condition.code === code)
    .map(condition => condition.desc)[0];
}

export const getTransactionFeeConditionFromCode = (code) => {
  return getAllTransactionFeeConditions
    .filter(condition => condition.code === code)[0];
}

export const  getTransactionFeeConditionSelectValues = (isSpecialRequest, hasBila, isEAM, currentCondition, newCondition) => {

  if (isSpecialRequest && (hasBila || isEAM)) {
    return getAllTransactionFeeConditions.map(p => ({
      'key': p.code,
      'value': p.desc
    }));
  }

  if (NON_STANDARD_NON_CUMULATIVE.code === newCondition) {
    return getTransactionFeeConditionsExceptCumulative.map(p => ({
      'key': p.code,
      'value': p.desc
    }));
  }

  if (NON_STANDARD_CUMULATIVE.code === newCondition) {
    return getTransactionFeeConditionsExceptNonCumulative.map(p => ({
      'key': p.code,
      'value': p.desc
    }));
  }

  if (NON_STANDARD_NON_CUMULATIVE.code === currentCondition) {
    return getTransactionFeeConditionsExceptCumulative.map(p => ({
      'key': p.code,
      'value': p.desc
    }));
  }

  if (NON_STANDARD_CUMULATIVE.code === currentCondition) {
    return getTransactionFeeConditionsExceptNonCumulative.map(p => ({
      'key': p.code,
      'value': p.desc
    }));
  }

  return getAllTransactionFeeConditionsNonSpecial.map(p => ({
    'key': p.code,
    'value': p.desc
  }));
};

export const getAllTransactionFeeConditionsNonSpecial =  [
  FLAT_RATE,
  TICKET_FEE,
  PERCENTAGE_DISCOUNT
];

export const getAllTransactionFeeConditions =  [
  FLAT_RATE,
  TICKET_FEE,
  PERCENTAGE_DISCOUNT,
  NON_STANDARD_CUMULATIVE,
  NON_STANDARD_NON_CUMULATIVE
];

export const getTransactionFeeConditionsExceptCumulative =  [
  FLAT_RATE,
  TICKET_FEE,
  PERCENTAGE_DISCOUNT,
  NON_STANDARD_NON_CUMULATIVE
];

export const getTransactionFeeConditionsExceptNonCumulative =  [
  FLAT_RATE,
  TICKET_FEE,
  PERCENTAGE_DISCOUNT,
  NON_STANDARD_CUMULATIVE,
];

export const isNonStandardCondition = (condition) => [NON_STANDARD_CUMULATIVE.code, NON_STANDARD_NON_CUMULATIVE.code].includes(condition);


