export default {
  root: {
    padding: '0px 0px',
    maxHeight: '30px',
    minHeight: '30px',
    minWidth: '200px',
    fontFamily: '\'Verlag SSm 3r\'',
    fontWeight: 'normal',
    borderBottom: '1px solid #ccc',
    color: '#fff',
    opacity: 1,
    fontSize: '0.8125rem',
    '& .Mui-selected': {
      color: '#000',
      backgroundColor: '#fff'
    },
    '&:not(.Mui-selected)': {
      '& .MuiButtonBase-root': {
        '&:hover': {
          fontWeight: 'normal',
        }
      }
    },
    '& .MuiTabScrollButton-root': {
      backgroundColor: '#141e55',
    },
  },
  indicator:{
    backgroundColor: '#141e55'
  },
  flexContainer:{
    backgroundColor:'#fff',
    maxHeight:'30px',
    minHeight:'30px',
    opacity:1
  }
};
