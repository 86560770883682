import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box,Paper,ThemeProvider,createTheme} from '@material-ui/core';
import {PriceRequestStore} from '../../PriceSimulationStore';
import {BoxHeader} from '../../../Common/components';
import api from '../../../../api/api';
import {UPDATE_BANKING_SERVICE, UPDATE_BANKING_SERVICE_FEES_VALIDATION_RESULT} from './bankingServiceFeeActions';
import PriceConditionTable from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import PriceConditionTableRowSection from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {
  LABEL,
  NEW_CONDITION_EDITABLE,
  NEW_CONDITION_READONLY,
  NEW_CONDITIONS,
  OLD_CONDITION_READONLY,
  OLD_CONDITIONS
} from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import {bankingServicePackage} from './objectmapper/bankingFeesServicePackageObjectMapper';
import {specialMailing} from './objectmapper/bankingFeesSpecialMailingObjectMapper';
import {reportingFee} from './objectmapper/bankingFeesReportingFeeObjectMapper';
import {codedAccount} from './objectmapper/bankingFeesCodedAccountObjectMapper';
import {retainedMail} from './objectmapper/bankingFeesRetainedMailObjectMapper';
import {totalFees} from './objectmapper/bankingFeesTotalFeesObjectMapper';
import OverchargeAdjustedDialog from '../../../Common/components/Dialog/OverchargeAdjustedDialog';
import {
  LOAD_FUTURE_REVENUE_RESPONSE,
  LOAD_PDB_RESPONSE,
} from '../RevenueSimulation/revenueActions';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../common/constants';
import {getOldConditionLabelAgreementType} from '../../../../common/getOldConditionLabelAgreementType';
import AgreementTypeIndicator from '../Common/AgreementTypeIndicator';
import {isEAMorEFA} from '../../../../common/enums/serviceModel';
import {BILA} from '../../../../common/enums/agreementType';
import {PENDING_PM} from '../../../../common/statusTypes';
import {Store} from '../../../../Store';
import {Alert} from '@material-ui/lab';
import {SET_GROUPING_DATA} from '../../../../common/actionTypes';

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: 'Verlag SSm 3r'
        },
      },
    },
  },
});

const BankingAndOtherServices = ({readOnly, specialRequest}) => {
  const {state, dispatch} = useContext(PriceRequestStore);
  const [fee, setFee] = useState({});
  const [overchargeAdjustedDialogValue, setOverchargeAdjustedDialogValue] = useState(null);
  const serviceModelCode = state.selectedPricingRequest.summary?.serviceModel?.code;
  const oldConditionLabel = getOldConditionLabelAgreementType(state.selectedPricingRequest.bankingFees?.oldPricingAgreedWithClient?.code);
  const {parentState, parentDispatch} = useContext(Store);

  useEffect(() => {
    if (state.selectedPricingRequest.bankingFees) {
      const currentConditionCodeFees = state.selectedPricingRequest.bankingFees.currentConditionCodeFees;
      const newConditionCodeFees = state.selectedPricingRequest.bankingFees.newConditionCodeFees;
      setFee({currentConditionCodeFees, newConditionCodeFees});
    }
  }, [state.selectedPricingRequest.bankingFees]);

  const handleValidationResultChange = (selector, isValid) => {
    dispatch({type: UPDATE_BANKING_SERVICE_FEES_VALIDATION_RESULT, selector: selector, isValid: isValid});
  };

  const handleBankingServiceFee = (value, isValid) => {
    fee.newConditionCodeFees.BANKING_SERVICE_FEE.effectivePrice = value;
    dispatch({type: UPDATE_BANKING_SERVICE, data: fee});
    dispatch({
      type: UPDATE_BANKING_SERVICE_FEES_VALIDATION_RESULT,
      selector: 'BANKING_SERVICE_FEE',
      isValid: isValid
    });
    calculateBankingServiceFee();
  };

  const handleSpecialMailingServiceFee = (value, isValid) => {
    fee.newConditionCodeFees.SPECIAL_MAILING.effectivePrice = value;
    dispatch({type: UPDATE_BANKING_SERVICE, data: fee});
    dispatch({
      type: UPDATE_BANKING_SERVICE_FEES_VALIDATION_RESULT,
      selector: 'SPECIAL_MAILING',
      isValid: isValid
    });
    calculateBankingServiceFee();
  };

  const handleSpecialReportingFee = (value, isValid) => {
    fee.newConditionCodeFees.REPORTING_FEE.effectivePrice = value;
    dispatch({type: UPDATE_BANKING_SERVICE, data: fee});
    dispatch({
      type: UPDATE_BANKING_SERVICE_FEES_VALIDATION_RESULT,
      selector: 'REPORTING_FEE',
      isValid: isValid
    });
    calculateBankingServiceFee();
  };

  const handleCodedAccount = (value, isValid) => {
    fee.newConditionCodeFees.CODED_ACCOUNT.effectivePrice = value;
    dispatch({type: UPDATE_BANKING_SERVICE, data: fee});
    dispatch({
      type: UPDATE_BANKING_SERVICE_FEES_VALIDATION_RESULT,
      selector: 'CODED_ACCOUNT',
      isValid: isValid
    });
    calculateBankingServiceFee();
  };

  const handleRetainedMail = (value, isValid) => {
    fee.newConditionCodeFees.RETAINED_MAIL.effectivePrice = value;
    dispatch({type: UPDATE_BANKING_SERVICE, data: fee});
    dispatch({
      type: UPDATE_BANKING_SERVICE_FEES_VALIDATION_RESULT,
      selector: 'RETAINED_MAIL',
      isValid: isValid
    });
    calculateBankingServiceFee();
  };

  const handleAgreementTypeIndicator = (checked) => {
    fee.pricingAgreedWithClient = checked ? BILA : null;
    dispatch({type: UPDATE_BANKING_SERVICE, data: fee});
  }

  const calculateBankingServiceFee = async () => {
    let payload = await api.pricingRequest.bankAndServiceFee.simulateFee(
      state.selectedPricingRequest.common.pricingRequestId,
      fee.newConditionCodeFees
    );
    dispatch({type: UPDATE_BANKING_SERVICE, data: {...fee, newConditionCodeFees: payload.data}});

    setOverchargeAdjustedDialogValue(payload.data);
    const portfolioNumber = state.selectedPricingRequest.common.portfolioNumber;
    let revenuePayload = await api.pricingRequest.revenue.postFuture(state.selectedPricingRequest.common.pricingRequestId,
      {
        ...state.selectedPricingRequest,
        bankingFees: {...state.selectedPricingRequest.bankingFees, newConditionCodeFees: payload.data},
        portfolioNumber
      }
    );
    dispatch({type: LOAD_FUTURE_REVENUE_RESPONSE, data: revenuePayload.data});

    const pricingRequestId = state.selectedPricingRequest.common.pricingRequestId;
    const pricingRequestIds = parentState.portfolios?.map(req=>req.pricingRequestId);
    const currentRevenue = state.selectedPricingRequest?.revenue?.currentRevenue;
    const futureRevenue = revenuePayload?.data;
    const pdbData = state.selectedPricingRequest?.revenue?.pdbData;
    let pdbPayload = await api.pricingRequest.revenue.postPdb(state.selectedPricingRequest.common.pricingRequestId,
      {
        'currentReqId' : pricingRequestId,
        'selectedReqIds' : pricingRequestIds,
        'currentRevenue' : currentRevenue,
        'futureRevenue' : futureRevenue,
        'pdbData' : pdbData
      }
    );
    dispatch({type: LOAD_PDB_RESPONSE, data: pdbPayload.data});

    console.log('groupingdata request in bankingservicesfee js--> ',parentState.groupingdata);
    console.log('revenue request in bankingservicesfee js--> ',state.selectedPricingRequest?.revenue);
    const groupingData = parentState.groupingdata;
    parentDispatch({type: SET_GROUPING_DATA, payload: null});
    let updatedGroupingPayload = await api.customer.getUpdatedDetails(
      {
        'groupingData' : groupingData,
        'currentRevenue' : currentRevenue,
        'futureRevenue' : futureRevenue
      }
    );
    //dispatch({type: LOAD_UPDATED_GROUPING_DATA_RESPONSE, data: updatedGroupingPayload.data?.groupingData});
    console.log('updatedGroupingPayload.data-->', updatedGroupingPayload.data);
    parentDispatch({type: SET_GROUPING_DATA, payload: updatedGroupingPayload.data});
  };

  const nonEmptyEntries = obj => (obj?.oldCondition || obj?.newCondition);

  const mappedFee = [
    bankingServicePackage({
      fee,
      serviceModelCode,
      handleValidationResultChange,
      handleBankingServiceFee,
      readOnly,
      specialRequest
    }),
    specialMailing({
      fee,
      handleValidationResultChange,
      handleSpecialMailingServiceFee,
      readOnly,
      specialRequest
    }),
    reportingFee({fee, handleValidationResultChange, handleSpecialReportingFee, readOnly, specialRequest}),
    codedAccount({fee, handleValidationResultChange, handleCodedAccount, readOnly}),
    retainedMail({fee, handleValidationResultChange, handleRetainedMail, readOnly}),
    {...totalFees({fee}), variant: 'footer'}
  ].filter(nonEmptyEntries);

  if (!fee?.newConditionCodeFees) {
    return (
      <Box mb={12} style={{margin : 0 }}>
        <ThemeProvider theme={theme}>
          <Alert severity="info" style={{boxShadow: 'none', fontSize: 16}}>
            There is no applicable banking fee attached to the request!
          </Alert>
        </ThemeProvider>
      </Box>
    );
  }


  return (
    <Paper elevation={3} style={{padding: '8px'}}>
      <OverchargeAdjustedDialog
        onClose={() => setOverchargeAdjustedDialogValue(null)}
        value={overchargeAdjustedDialogValue}
      />
      <BoxHeader text="Banking Service Fees" textAfter="fees per annum"/>
      <AgreementTypeIndicator
        agreedWithClient={state.selectedPricingRequest.bankingFees?.pricingAgreedWithClient?.code}
        handleChange={handleAgreementTypeIndicator}
        readOnly={readOnly || state.selectedPricingRequest?.common?.pricingRequestStatus === PENDING_PM}
        show={!isEAMorEFA(state.selectedPricingRequest.common?.serviceModel?.code, state.selectedPricingRequest.common.agentType)}
      />
      <PriceConditionTable width={fee.currentConditionCodeFees ? '100%' : '70%'}>
        <PriceConditionTableRowSection>
          <PriceConditionTableColumn type={LABEL}/>
          {fee.currentConditionCodeFees && (
            <PriceConditionTableColumn
              customStyles={OLD_CONDITION_CUSTOM_STYLE}
              data-testid="test-old-condition-label"
              label={oldConditionLabel}
              type={OLD_CONDITIONS}
            />
          )}
          <PriceConditionTableColumn colSpan={3} label="NEW CONDITIONS" type={NEW_CONDITIONS}/>
        </PriceConditionTableRowSection>
        <PriceConditionTableRowSection data={mappedFee}>
          <PriceConditionTableColumn field="label" infoTextAccessor="infoText" type={LABEL}/>
          {fee.currentConditionCodeFees && (
            <PriceConditionTableColumn
              customStyles={OLD_CONDITION_CUSTOM_STYLE}
              field="oldCondition.effectivePrice"
              label="EffectivePrice"
              type={OLD_CONDITION_READONLY}
            />
          )}
          <PriceConditionTableColumn
            field="newCondition.listPrice"
            label="List Price"
            type={NEW_CONDITION_READONLY}
          />
          <PriceConditionTableColumn
            field="newCondition.effectivePrice"
            isEditableFieldValueAccessor="newCondition.isEditable"
            label="Effective Price"
            type={NEW_CONDITION_EDITABLE}
          />
          <PriceConditionTableColumn
            field="newCondition.discount"
            label="Discount"
            type={NEW_CONDITION_READONLY}
          />
        </PriceConditionTableRowSection>
      </PriceConditionTable>
      <Box mb={12} style={{margin : 0 }}>
        <ThemeProvider theme={theme}>
          <Alert severity="info" style={{boxShadow: 'none', fontSize: 16}}>
            Any pricing condition in Banking Service fees will start to take effect for the entire <b>quarter</b> upon approval
          </Alert>
        </ThemeProvider>
      </Box>
    </Paper>
  );
};

BankingAndOtherServices.propTypes = {
  readOnly: PropTypes.bool,
  specialRequest: PropTypes.bool
};

export default BankingAndOtherServices;
