import React from 'react';
import {Grid, makeStyles, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import {getFormLabel} from '../../../../views/Common/components/form/formLabel';
import {getDefaultFontSize} from '../../../../theme/common/font';
import PriceSelect from '../../../../views/Common/components/PriceSelect';
import {TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR} from '../../../../theme/common/color';


const useStyles = makeStyles((theme) => ({
  label: {
    ...getFormLabel(),
    textAlign: 'right',
    width: '100%'
  },
  text: {
    width: '100%',
    fontSize: '0.8125rem',
    textAlign: 'right'
  },
  textReadOnly: {
    ...getDefaultFontSize(theme),
    textAlign: 'right'
  },
  readOnlyLabel: {
    ...getDefaultFontSize(theme),
    textAlign: 'right'
  },
  editableTableBodyCell: {
    fontSize: '0.8125rem',
    backgroundColor: TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR
  }
}));

const PriceTableSelect = ({ inputProps, label, onChange, options, readOnly, readOnlyLabel, testId, value, ...others }) => {
  const classes = useStyles();

  const getDescription = () => {
    if (value) {
      let currentSelected = options.filter(val => val.key === value);
      if (currentSelected && currentSelected.length > 0) {
        return currentSelected[0].value;
      }else{
        return value;
      }
    }
    return '';
  };

  return (
    <Grid
      container
    >
      <Grid className={classes.text} item xs={6}>
        {!readOnly ? <Typography className={classes.text} component={'span'}>{label} &nbsp;</Typography> :
          <Typography className={classes.readOnlyLabel}>{readOnlyLabel}</Typography>}
      </Grid>
      <Grid className={!readOnly ? classes.editableTableBodyCell : ''} data-testid={`select-${testId}`} item xs={6}>
        {!readOnly &&
        <PriceSelect
          inputProps={{ 'data-testid': testId, ...inputProps }}
          key={'pricing-type-select'}
          onValueChange={onChange}
          options={options}
          readOnly={readOnly}
          value={value}
          {...others}
        />}
        {readOnly &&
        <Typography className={classes.textReadOnly}>{getDescription()} &nbsp;</Typography>}
      </Grid>
    </Grid>
  );
};

PriceTableSelect.propTypes = {
  inputProps: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  others: PropTypes.object,
  readOnly: PropTypes.bool,
  readOnlyLabel: PropTypes.string,
  testId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
};

export default React.memo(PriceTableSelect);
