import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { CLIPP_DARK_BLUE, CLIPP_LIGHT_GREY, CLIPP_WHITE } from '../../../../theme/common/bjbColor';


const AntSwitch = withStyles(() => ({
  root: {
    width: 42,
    height: 16,
    padding: 0,
    display: 'flex',
    overflow: 'unset',
    marginLeft: 16,
  },
  switchBase: {
    padding: 3,
    color: CLIPP_WHITE,
    '&$checked': {
      transform: 'translateX(24px)',
      color: CLIPP_WHITE,
      '& + $track': {
        opacity: 1,
        backgroundColor: CLIPP_DARK_BLUE,
        borderColor: CLIPP_DARK_BLUE,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${CLIPP_WHITE}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: CLIPP_LIGHT_GREY,
    '&$checked': {
      backgroundColor: CLIPP_WHITE,
    }
  },
  checked: {},
}))(Switch);

export default AntSwitch;