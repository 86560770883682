import React, {useContext} from 'react';
import {Store} from '../../../Store';
import {PriceRequestStore} from '../../PriceSimulation/PriceSimulationStore';
import PaymentFeesTable from './PaymentFeesTable';
import RecurringFeeTable from './RecurringFeeTable';
import BankingServiceFeeTable from './BankingServiceFeeTable';
import PortfolioInfoTable from './PortfolioInfoTable';
import OverdraftTable from './OverdraftTable';
import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';
import TransactionFeesExchangeTradedTable from './TransactionFeeTables/TransactionFeesExchangeTradedTable';
import TransactionFeesFXProductsTable from './TransactionFeeTables/TransactionFeesFXProductsTable';
import TransactionFeesOTCBondsTable from './TransactionFeeTables/TransactionFeesOTCBondsTable';

const SubmissionSummaryTable = ({showDiff}) => {
  const {state} = useContext(PriceRequestStore);
  const {parentState} = useContext(Store);

  const {summary, common} = state.selectedPricingRequest;
  const {customer} = parentState;

  return (
    <React.Fragment>
      <Box marginBottom={4}>
        <PortfolioInfoTable common={common} customer={customer} summary={summary}/>
      </Box>
      {state.selectedPricingRequest.paymentFees && (
        <Box marginBottom={2}>
          <PaymentFeesTable
            agentType={state.selectedPricingRequest.common.agentType}
            allPaymentFees={state.selectedPricingRequest.paymentFees}
            serviceModel={state.selectedPricingRequest.summary?.serviceModel?.code}
            showDiff={showDiff}
          />
        </Box>
      )}
      {state.selectedPricingRequest.recurringFees && (
        <Box marginBottom={2}>
          <RecurringFeeTable
            agentType={state.selectedPricingRequest.common.agentType}
            recurringFees={state.selectedPricingRequest.recurringFees}
            serviceModel={state.selectedPricingRequest.summary?.serviceModel?.code}
            showDiff={showDiff}
          />
        </Box>
      )}
      {state.selectedPricingRequest.bankingFees && (
        <Box marginBottom={2}>
          <BankingServiceFeeTable
            agentType={state.selectedPricingRequest.common.agentType}
            bankingFees={state.selectedPricingRequest.bankingFees}
            serviceModel={state.selectedPricingRequest.summary?.serviceModel?.code}
            showDiff={showDiff}
          />
        </Box>
      )}
      {state.selectedPricingRequest?.transactionFees?.transactionAssetTypes && (
        <Box marginBottom={2}>
          <TransactionFeesExchangeTradedTable agentType={state.selectedPricingRequest.common.agentType} serviceModel={state.selectedPricingRequest.summary?.serviceModel?.code} showDiff={showDiff} transactionFees={state.selectedPricingRequest.transactionFees}/>
        </Box>
      )}
      {state.selectedPricingRequest?.nmbTransactionFees?.transactionAssetTypes && (
        <Box marginBottom={2}>
          <TransactionFeesOTCBondsTable agentType={state.selectedPricingRequest.common.agentType} serviceModel={state.selectedPricingRequest.summary?.serviceModel?.code}  showDiff={showDiff} transactionFees={state.selectedPricingRequest.nmbTransactionFees}/>
        </Box>
      )}
      {state.selectedPricingRequest?.nmbFXProducts?.transactionAssetTypes && (
        <Box marginBottom={2}>
          <TransactionFeesFXProductsTable agentType={state.selectedPricingRequest.common.agentType} serviceModel={state.selectedPricingRequest.summary?.serviceModel?.code}  showDiff={showDiff} transactionFees={state.selectedPricingRequest.nmbFXProducts}/>
        </Box>
      )}
      {state.selectedPricingRequest?.overdraft && (
        <Box>
          <OverdraftTable
            agentType={state.selectedPricingRequest.common.agentType}
            overdraft={state.selectedPricingRequest.overdraft}
            serviceModel={state.selectedPricingRequest.summary?.serviceModel?.code}
            showDiff={showDiff}
          />
        </Box>
      )}
    </React.Fragment>
  );
};

SubmissionSummaryTable.propTypes = {
  showDiff: PropTypes.bool
};

export default SubmissionSummaryTable;
