import React from 'react';
import Box from '@material-ui/core/Box';
import PriceSelect from '../../../../Common/components/PriceSelect';
import {DPM, EAM_EFA_MANAGED, IA_DIRECT, IA_INDIRECT} from '../../../../../common/enums/serviceModel';
import {
  FIXED_AMOUNT,
  FLAT_RATE,
  NON_STANDARD_CUMULATIVE,
  NON_STANDARD_NON_CUMULATIVE,
  PERCENTAGE_DISCOUNT,
  TIERED_FEE_NON_CUMULATIVE
} from '../../../../../common/enums/recurringFeeCondition';
import {isNewRecurringFeeDifferent} from '../isNewRecurringFeeDifferent';
import {BILA} from '../../../../../common/enums/agreementType';

const LABEL = 'Condition';
const KEY_PREFIX = 'recurring-fees-condition';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const recurringFeeCondition = ({customTieringEnabled, handleConditionChange, readOnly, recurringFees, serviceModelCode, specialRequest, specialRequestPm, showDiff}) => {
  let result = {
    label: LABEL
  };
  let isNewValueDifferent = !!readOnly && isNewRecurringFeeDifferent(recurringFees, 'condition');
  let hasBila = (recurringFees.pricingAgreedWithClient?.code === BILA.code && recurringFees.newCondition?.acknowledged);
  let isEAM = serviceModelCode === EAM_EFA_MANAGED;
  let currentCondition = recurringFees?.currentCondition?.condition;

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (recurringFees?.currentCondition?.condition) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(recurringFees?.currentCondition?.condition)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(recurringFees?.newCondition?.condition, handleConditionChange, readOnly,serviceModelCode, specialRequest, specialRequestPm, customTieringEnabled, isNewValueDifferent, hasBila, isEAM, currentCondition)
  }

};

const getMappedOldCondition = (condition) => {

  const readonlyField = (
    <Box data-testid={`${TEST_KEY_PREFIX}-readonly`} textAlign="right">{condition?.desc}</Box>
  );

  return {
    value: readonlyField
  };
};

const getMappedNewCondition = (condition, handleConditionChange, readOnly, serviceModelCode, specialRequest, specialRequestPm, customTieringEnabled, isNewValueDifferent, hasBila, isEAM, currentCondition) => {

  let options = [];
  if ([DPM, IA_DIRECT, IA_INDIRECT].includes(serviceModelCode)) {
    options.push({key: TIERED_FEE_NON_CUMULATIVE.code, value: TIERED_FEE_NON_CUMULATIVE.desc});
  }
  if (specialRequest && customTieringEnabled && (isEAM || hasBila)) {
    options.push({key: NON_STANDARD_CUMULATIVE.code, value: NON_STANDARD_CUMULATIVE.desc});
    options.push({key: NON_STANDARD_NON_CUMULATIVE.code, value: NON_STANDARD_NON_CUMULATIVE.desc});
  } else if (customTieringEnabled && condition?.code === NON_STANDARD_CUMULATIVE.code) {
    options.push({key: NON_STANDARD_CUMULATIVE.code, value: NON_STANDARD_CUMULATIVE.desc});
  } else if (customTieringEnabled && condition?.code === NON_STANDARD_NON_CUMULATIVE.code) {
    options.push({key: NON_STANDARD_NON_CUMULATIVE.code, value: NON_STANDARD_NON_CUMULATIVE.desc});
  }else if (customTieringEnabled && currentCondition?.code === NON_STANDARD_CUMULATIVE.code) {
    options.push({key: NON_STANDARD_CUMULATIVE.code, value: NON_STANDARD_CUMULATIVE.desc});
  } else if (customTieringEnabled && currentCondition?.code === NON_STANDARD_NON_CUMULATIVE.code) {
    options.push({key: NON_STANDARD_NON_CUMULATIVE.code, value: NON_STANDARD_NON_CUMULATIVE.desc});
  }

  options.push(
    {key: FLAT_RATE.code, value: FLAT_RATE.desc},
    {key: FIXED_AMOUNT.code, value: FIXED_AMOUNT.desc},
    {key: PERCENTAGE_DISCOUNT.code, value: PERCENTAGE_DISCOUNT.desc}
  );

  const editableField = (
    <PriceSelect
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      inputProps={{id: 'uncontrolled-native'}}
      key={`${KEY_PREFIX}-editable`}
      onValueChange={handleConditionChange || function(){}}
      options={options}
      value={condition?.code}
    />
  );

  const readonlyField = (
    <Box data-testid={`${TEST_KEY_PREFIX}-readonly`} textAlign="right">{condition?.desc}</Box>
  )

  return {
    value: readOnly ? readonlyField : editableField,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
  };
};
