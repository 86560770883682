import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Box, Grid, Typography} from '@material-ui/core';
import {useNotification} from '../../../Common/components/Notification';
import adminApi from '../../../../api/adminApi';
import FeeCommissionGroupTable from './components/FeeCommissionGroupTable';
import FeeComissionTypeFilter from './components/FeeCommissionTypeFilter';
import {ASC} from '../../../../common/sortDirection';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import AsyncButton from '../../../Common/components/AsyncButton';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '500px',
    padding: theme.spacing(4)
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

const FeeCommissionType = () => {
  const FEE_COMMISSION_TYPE_ID_COLUMN = 't24FeeCommissionTypeId';

  const classes = useStyles();
  const notification = useNotification();

  const [t24StatusData, setT24StatusData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState();
  const [filterByStatus, setFilterByStatus] = useState();
  const [filterByType, setFilterByType] = useState();
  const [filterByIncludeInactive, setFilterIncludeInactive] = useState();
  const [filterByCommissionTypeId, setFilterByCommissionTypeId] = useState();

  const [requiresRefresh, setRequiresRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState();

  const fetchData = params => {
    setIsLoading(true);
    return adminApi.pricingAdmin
      .getFeeCommissionTypes({
        t24SyncStatus: filterByStatus,
        type: filterByType,
        includeInactive: filterByIncludeInactive,
        codeSearch: filterByCommissionTypeId,
        ...params,
        sortBy: params?.sortBy || FEE_COMMISSION_TYPE_ID_COLUMN,
        sortDirection: params?.sortDirection || ASC
      })
      .then(res => {
        setT24StatusData(res.data?.elements);
        setPaginationInfo(res.data?.pagingMetaData);
        setIsLoading(false);
      })
      .catch((err) => {
        notification.error('Could not load data.\n' + getErrorMessage(err));
      });
  };

  const handleCommissionTypeIdFilter = e => {
    setFilterByCommissionTypeId(e.target.value);
  };

  const handleFind = e => {
    setRequiresRefresh(e.target.value + Math.random())
  };

  const refresh = () => {
    return fetchData();
  };

  const handleStatusFilter = e => {
    setFilterByStatus(e.target.value);
  };

  const handleTypeFilter = e => {
    setFilterByType(e.target.value);
  };

  const handleIncludeInactiveFilter = () => {
    setFilterIncludeInactive(!filterByIncludeInactive);
  };

  const goToPage = page => {
    fetchData({page});
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByStatus, filterByType, filterByIncludeInactive, requiresRefresh]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h1">
            FT/FD Codes
          </Typography>
          <AsyncButton onClick={refresh} text="Refresh"/>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <FeeComissionTypeFilter
          filterByCommissionTypeId={filterByCommissionTypeId}
          handleCommissionTypeIdFilter={handleCommissionTypeIdFilter}
          handleFind={handleFind}
          handleIncludeInactiveFilter={handleIncludeInactiveFilter}
          handleStatusFilter={handleStatusFilter}
          handleTypeFilter={handleTypeFilter}
          includeInactiveFilter={filterByIncludeInactive}
          selectedStatusFilter={filterByStatus}
          selectedTypeFilter={filterByType}
        />
        {isLoading ? <LoadingSpinner/> : (
          <FeeCommissionGroupTable
            fetchData={fetchData}
            goToPage={goToPage}
            paginationInfo={paginationInfo}
            t24StatusData={t24StatusData}
          />)
        }
      </Grid>
    </Grid>
  );
};

export default FeeCommissionType;
