import React from 'react';
import PropTypes from 'prop-types';
import {getNMBFeeFundTypeFromCode} from '../../../../../common/enums/nmbFeeFundType';
import NMBFeeFund from './NMBFeeFund';


const NMBFeeFunds = ({funds, handleAddTier, handleRemoveTier, handleRangeStartChange, handleRangeEndChange, handleValueChange, handleValidationResultChange, readOnly, showAgreementIndicator}) => {
  const getSortedFunds = (funds) => {
    return Object.entries(funds).sort(([iCode], [jCode]) => {
      const prevCode = getNMBFeeFundTypeFromCode(iCode);
      const nextCode = getNMBFeeFundTypeFromCode(jCode);
      if (!prevCode || !nextCode) {
        return -1;
      }
      return prevCode.sortOrder - nextCode.sortOrder
    });
  }
  let fundElements = [];

  for (const [fundTypeCode, subFunds] of getSortedFunds(funds)) {
    fundElements.push(
      <NMBFeeFund
        fundTypeCode={fundTypeCode}
        handleAddTier={({selectors, ...others}) => handleAddTier({...others, selectors: [fundTypeCode, ...selectors]})}
        handleRangeEndChange={({selectors, ...others}) => handleRangeEndChange({
          ...others,
          selectors: [fundTypeCode, ...selectors]
        })}
        handleRangeStartChange={({selectors, ...others}) => handleRangeStartChange({
          ...others,
          selectors: [fundTypeCode, ...selectors]
        })}
        handleRemoveTier={({selectors, ...others}) => handleRemoveTier({
          ...others,
          selectors: [fundTypeCode, ...selectors]
        })}
        handleValidationResultChange={({selectors, ...others}) => handleValidationResultChange({
          ...others,
          selectors: [fundTypeCode, ...selectors]
        })}
        handleValueChange={({selectors, ...others}) => handleValueChange({
          ...others,
          selectors: [fundTypeCode, ...selectors]
        })}
        key={`transaction-fee-fund-${fundTypeCode}`}
        readOnly={readOnly}
        showAgreementIndicator={showAgreementIndicator}
        subFunds={subFunds}
      />
    )
  }

  return (
    <>
      {fundElements}
    </>
  );

};

NMBFeeFunds.propTypes = {
  funds: PropTypes.object.isRequired,
  handleAddTier: PropTypes.func,
  handleRangeEndChange: PropTypes.func,
  handleRangeStartChange: PropTypes.func,
  handleRemoveTier: PropTypes.func,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showAgreementIndicator: PropTypes.bool,
}

export default React.memo(NMBFeeFunds);
