import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {CLIPP_WHITE} from '../../../theme/common/bjbColor';
import {TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR} from '../../../theme/common/color';
import {getDefaultFontSize} from '../../../theme/common/font';
import {EAM_EFA_MANAGED} from '../../../common/enums/serviceModel';
import {getPricingModelFromSummary} from '../../../helpers/pricingModelHelper';

const useStyles = makeStyles(theme => ({
  table: {
    ...getDefaultFontSize(theme)
  },
  tableCellHeader: {
    backgroundColor: TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR,
    color: CLIPP_WHITE,
    minWidth: '100px',
    ...getDefaultFontSize(theme)
  },
  tableCell: {
    ...getDefaultFontSize(theme)
  }
}));

const PortfolioInfoTable = ({common, customer, summary}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHeader}>Customer ID</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHeader}>{customer?.accountNumber}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHeader}>Portfolio ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>Service Model</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Mandate Type</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Pricing Model</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Booking Center</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>Agreement Type</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHeader}>{common?.portfolioNumber}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell} data-testid="test-submission-summary-service-model">
                {(summary?.serviceModel?.code !== EAM_EFA_MANAGED && summary.serviceModel.desc) || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{summary?.mandateType || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{getPricingModelFromSummary(summary) || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{common?.bookingCenter || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{summary?.pricingAgreement?.desc || '-'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

PortfolioInfoTable.propTypes = {
  common: PropTypes.any,
  customer: PropTypes.any,
  summary: PropTypes.any
};

export default PortfolioInfoTable;
