import { jbColors } from '../jbColors';

const MuiButton = {
  root: {
    textTransform: 'none',
    fontSize: 13,
    minWidth: 100,
    boxShadow: 0,
    minHeight: 30,
    border: '1px solid',
    padding: '0px 25px',
    lineHeight: 1,
    borderRadius: 0,
    borderColor: jbColors.royalBlue[100],
    '&$disabled': {
      color: 'white',
      backgroundColor: '#E5E7F3',
      minWidth: 100,
      minHeight: 30,
      padding: '5px 25px',
      border: '1px',
    },
  },
  contained: {
    boxShadow: 0,
    '&:hover': {
      boxShadow: 0,
    },
  },
  containedPrimary: {
    backgroundColor: jbColors.royalBlue[100],
    boxShadow: 'initial',
    color: 'white',
    '&:hover': {
      color: jbColors.royalBlue[100],
      backgroundColor: 'white',
      border: '1px solid',
      boxShadow: 'initial',
      borderColor: jbColors.royalBlue[100],
    },
    '&$disabled': {
      color: 'white',
      backgroundColor: jbColors.royalBlue[60],
      minWidth: 100,
      minHeight: 30,
      padding: '5px 25px',
      border: '1px',
    },
  },
  containedSecondary: {
    backgroundColor: jbColors.greenSmoke[20],
    borderColor: jbColors.greenSmoke[20],
    color: jbColors.royalBlue[100],
    boxShadow: 'initial',
    '&:hover': {
      backgroundColor: jbColors.greenSmoke[20],
      color: jbColors.royalBlue[100],
    },
  },
};

export default MuiButton;
