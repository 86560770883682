import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import PriceConditionTableRowSection from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {
  LABEL,
  NEW_CONDITION_EDITABLE,
  NEW_CONDITION_READONLY,
  NEW_CONDITIONS,
  OLD_CONDITION_READONLY,
  OLD_CONDITIONS
} from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import PriceConditionTable from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import {recurringFeeImplementation} from './objectmapper/recurringFeesImplementationObjectMapper';
import {recurringFeeCondition} from './objectmapper/recurringFeesConditionObjectMapper';
import {calculationBasis} from './objectmapper/recurringFeesCalculationBasisObjectMapper';
import {annualFee} from './objectmapper/recurringFeesAnnualFeeObjectMapper';
import {flatRateInBps} from './objectmapper/recurringFeesFlatRateInBpsObjectMapper';
import {discountOnListPrice} from './objectmapper/recurringFeesDiscountOnListPriceObjectMapper';
import {discountOnSurcharge} from './objectmapper/recurringFeesDiscountOnSurchargeObjectMapper';
import {overriddenDiscountOnSurcharge} from './objectmapper/recurringFeesOverriddenDiscountOnSurchargeObjectMapper';
import {feePerAnnum} from './objectmapper/recurringFeesFeePerAnnumObjectMapper';
import {minimumFeePerQuarter} from './objectmapper/recurringFeesMinimumFeePerQuarterObjectMapper';
import {surchargeOnRestriction} from './objectmapper/recurringFeesSurchargeOnRestrictionObjectMapper';
import {portfolioAssets} from './objectmapper/recurringFeesPortfolioAssetsObjectMapper';
import {portfolioAssetsSpecialRequest} from './objectmapper/recurringFeesPortfolioAssetsSpecialRequestObjectMapper';
import {showOverridden} from './showOverridden';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../common/constants';
import {getOldConditionLabelAgreementType} from '../../../../common/getOldConditionLabelAgreementType';
import NMBAcknowledgement from '../NMBFees/panel/NMBAcknowledgement';
import {BILA} from '../../../../common/enums/agreementType';
import {isEAMorEFA,isEFA} from '../../../../common/enums/serviceModel';
import {PENDING_PM} from '../../../../common/statusTypes';
import {getNMBDeclaration} from '../../../../common/enums/declaration';
import {isNonStandardRecurringFeeCondition} from '../../../../common/enums/recurringFeeCondition';

const RecurringFeesConditionTable = ({customTieringEnabled, handleAddTier, handleRemoveTier, handleConditionChange,
  handleRangeStartChange, handleRangeEndChange, handleImplementationChange, handleValidationResultChange, handleValueChange,
  readOnly, recurringFees, selectableImplementationCodes, serviceModelCode, specialRequest, specialRequestPm, agentType, pricingRequestStatus}) => {

  const properties = {
    customTieringEnabled,
    handleAddTier,
    handleRemoveTier,
    handleConditionChange,
    handleRangeStartChange,
    handleRangeEndChange,
    handleImplementationChange,
    handleValidationResultChange,
    handleValueChange,
    readOnly,
    recurringFees,
    selectableImplementationCodes,
    serviceModelCode,
    specialRequest,
    specialRequestPm,
    agentType,
    pricingRequestStatus
  };

  const recurringFeeImplementationDeps = [serviceModelCode, recurringFees.newCondition?.implementation?.code, selectableImplementationCodes];
  const recurringFeeConditionDeps = [serviceModelCode, recurringFees.newCondition?.condition?.code];
  const calculationBasisDeps = [serviceModelCode, recurringFees.newCondition?.calculationBasis];
  const annualFeeDeps = [recurringFees.newCondition?.condition?.code, recurringFees.newCondition?.annualFee];
  const flatRateInBpsDeps = [serviceModelCode, recurringFees.newCondition?.condition?.code, recurringFees.newCondition?.flatRateInBps];
  const discountOnListPriceDeps = [recurringFees.newCondition?.condition?.code, recurringFees.newCondition?.discountOnListPrice];
  const discountOnSurchargeDeps = [serviceModelCode, recurringFees.newCondition?.implementation?.code, recurringFees.newCondition?.discountOnSurcharge];
  const overriddenDiscountOnSurchargeDeps = [serviceModelCode, recurringFees.newCondition?.implementation?.code, recurringFees.newCondition?.discountOnSurchargeOverridden];
  const feePerAnnumDeps = [serviceModelCode, recurringFees.newCondition?.condition?.code, recurringFees.newCondition?.feePerAnnum?.effectivePrice];
  const portfolioAssetsDeps = [serviceModelCode, recurringFees.newCondition?.condition?.code, recurringFees.newCondition?.portfolioAssets];
  const minimumFeePerQuarterDeps = [recurringFees.newCondition?.implementation?.code, recurringFees.newCondition?.minimumFeePerQuarter?.effectivePrice];
  const surchargeOnRestrictionDeps = [serviceModelCode, recurringFees.newCondition?.surchargeOnRestriction?.effectivePrice];
  const oldConditionLabel = getOldConditionLabelAgreementType(recurringFees?.oldPricingAgreedWithClient?.code);

  const nonEmptyEntries = obj => obj?.oldCondition || obj?.newCondition;
  const columnProps = {
    borderRight: !recurringFees.currentCondition
  }
  let labelValueSection = [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => recurringFeeImplementation(properties), [recurringFeeImplementationDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => recurringFeeCondition(properties), [recurringFeeConditionDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => calculationBasis(properties), [calculationBasisDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => annualFee(properties), [annualFeeDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => flatRateInBps(properties), [flatRateInBpsDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => discountOnListPrice(properties), [discountOnListPriceDeps])
  ];

  if (showOverridden({
    specialRequest,
    readOnly,
    overriddenValue: recurringFees?.newCondition?.discountOnSurchargeOverridden
  })) {
    labelValueSection.push(overriddenDiscountOnSurcharge(properties), [overriddenDiscountOnSurchargeDeps]);
  } else {
    labelValueSection.push(discountOnSurcharge(properties), [discountOnSurchargeDeps]);
  }

  labelValueSection = labelValueSection.filter(nonEmptyEntries);

  const labelListEffectivePriceSection = [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => feePerAnnum(properties), [feePerAnnumDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...useMemo(() => portfolioAssets(properties), [portfolioAssetsDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...useMemo(() => portfolioAssetsSpecialRequest(properties), [portfolioAssetsDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => minimumFeePerQuarter(properties), [minimumFeePerQuarterDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => surchargeOnRestriction(properties), [surchargeOnRestrictionDeps])
  ].filter(nonEmptyEntries);

  const hasDisjunctTiering = labelListEffectivePriceSection.some(entry => !!entry.newConditionTiering);

  const isNonStandardCondition = isNonStandardRecurringFeeCondition(recurringFees.newCondition?.condition.code);
  const mandatorySpecialCondition = !readOnly && isNonStandardCondition && !recurringFees?.newCondition?.acknowledged;

  const getTable = () => {
    return (
      <PriceConditionTable width={recurringFees.currentCondition ? '100%' : '70%'}>
        <PriceConditionTableRowSection data={labelValueSection}>
          <PriceConditionTableColumn field="label" type={LABEL}/>
          {recurringFees.currentCondition &&
          <PriceConditionTableColumn
            customStyles={OLD_CONDITION_CUSTOM_STYLE}
            data-testid="test-old-condition-label"
            field="oldCondition.value"
            label={oldConditionLabel}
            type={OLD_CONDITIONS}
          />}
          {hasDisjunctTiering &&
          <PriceConditionTableColumn
            field="newConditionTiering"
            type={LABEL}
            width={specialRequest && !readOnly ? '350px' : null}
          />}
          <PriceConditionTableColumn
            colSpan={2}
            field="newCondition.value"
            isEditableFieldValueAccessor="newCondition.isEditable"
            label="NEW CONDITIONS"
            type={NEW_CONDITIONS}
          />
        </PriceConditionTableRowSection>
        <PriceConditionTableRowSection data={labelListEffectivePriceSection}>
          <PriceConditionTableColumn field="label" type={LABEL}/>
          {recurringFees.currentCondition &&
          <PriceConditionTableColumn
            customStyles={OLD_CONDITION_CUSTOM_STYLE}
            field="oldCondition.effectivePrice"
            label="Effective Price"
            type={OLD_CONDITION_READONLY}
          />}
          {hasDisjunctTiering &&
          <PriceConditionTableColumn
            field="newConditionTiering"
            type={LABEL}
            width={specialRequest && !readOnly ? '350px' : null}
          />}
          <PriceConditionTableColumn field="newCondition.listPrice" label="List Price" type={NEW_CONDITION_READONLY}/>
          <PriceConditionTableColumn
            field="newCondition.effectivePrice"
            isDisabledAccessor="newCondition.isDisabled"
            isEditableFieldValueAccessor="newCondition.isEditable"
            label="Effective Price"
            type={NEW_CONDITION_EDITABLE}
          />
        </PriceConditionTableRowSection>
        <PriceConditionTableRowSection data={[]}>
          {recurringFees.currentCondition && <>
            <PriceConditionTableColumn
              borderRight
              colSpan={2}
              customStyles={OLD_CONDITION_CUSTOM_STYLE}
              field="label"
              label={
                <NMBAcknowledgement
                  ackProps={{inputProps:{'data-testid': 'current-condition-customized-structure-indicator-checkbox'}}}
                  acknowledged={recurringFees.currentCondition?.acknowledged}
                  addInsProps={{'data-testid':'current-condition-additional-instruction-text' }}
                  additionalInstruction={recurringFees.currentCondition?.additionalInstructions}
                  agentType={agentType}
                  agreementType={recurringFees.newCondition?.code}
                  declarationTxt={getNMBDeclaration(serviceModelCode, agentType, true)}
                  disabled
                  readOnly
                  serviceModel={serviceModelCode}
                  show
                />}
              style={{padding: '0px'}}
            /></>}
          {!recurringFees.currentCondition && <PriceConditionTableColumn field="label"/>}
          {hasDisjunctTiering && <PriceConditionTableColumn field="label"/>}
          <PriceConditionTableColumn
            colSpan={2}
            field="label"
            label={
              <NMBAcknowledgement
                ackProps={{inputProps:{'data-testid': 'new-condition-test-customized-structure-indicator-checkbox'}}}
                acknowledged={recurringFees.newCondition?.acknowledged}
                addInsProps={{'data-testid':'new-condition-additional-instruction-text' }}
                additionalInstruction={recurringFees.newCondition?.additionalInstructions}
                agentType={agentType}
                agreementType={recurringFees.pricingAgreedWithClient?.code}
                declarationTxt={getNMBDeclaration(serviceModelCode, agentType, true)}
                disabled={(recurringFees.pricingAgreedWithClient?.code !== BILA.code && !isEFA(agentType) &&!isEAMorEFA(serviceModelCode, agentType)) || pricingRequestStatus === PENDING_PM}
                handleChange={handleValueChange}
                handleValidationResultChange={handleValidationResultChange}
                isNonStandardCondition={isNonStandardCondition}
                readOnly={readOnly}
                serviceModel={serviceModelCode}
                show
              />}
            style={{padding: '0px', backgroundColor: (mandatorySpecialCondition) ? '#971b2f59' : ''}}
            {...columnProps}
          />
        </PriceConditionTableRowSection>
      </PriceConditionTable>
    )
  }

  return useMemo(getTable, [
    ...recurringFeeImplementationDeps,
    ...recurringFeeConditionDeps,
    ...calculationBasisDeps,
    ...annualFeeDeps,
    ...flatRateInBpsDeps,
    ...discountOnListPriceDeps,
    ...discountOnSurchargeDeps,
    ...feePerAnnumDeps,
    ...portfolioAssetsDeps,
    ...minimumFeePerQuarterDeps,
    ...surchargeOnRestrictionDeps
  ]);

};

RecurringFeesConditionTable.propTypes = {
  agentType: PropTypes.string,
  handleConditionChange: PropTypes.func.isRequired,
  handleImplementationChange: PropTypes.func.isRequired,
  handleRangeEndChange: PropTypes.func,
  handleRangeStartChange: PropTypes.func,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  oldPricingAgreedWithClient: PropTypes.object,
  pricingRequestStatus: PropTypes.object,
  recurringFees: PropTypes.object.isRequired,
  selectableImplementationCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  serviceModelCode: PropTypes.string.isRequired,
  specialRequest: PropTypes.bool,
  specialRequestPm: PropTypes.bool

};

export default RecurringFeesConditionTable;
