import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles(({
  footer: {
    width: '100%',
    backgroundColor: '#FFF',
    zIndex: -1,
    position: 'relative',
    padding: '20px 0',
    '& .MuiTypography-root': {
      fontSize: '0.8125rem',
      color: '#001489',
      textAlign: 'right',
      paddingRight: '20px'
    }
  }
}));

const Footer = () => {

  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Typography variant="h6">&copy; Bank Julius Baer 2020</Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
