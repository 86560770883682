import {TABLE_FOOTER_BACKGROUND_COLOR, TABLE_FOOTER_COLOR} from '../common/color';


export default {
  head: {
    fontFamily: '\'Verlag SSm 7r\'',
    fontWeight: 'bold',
  },
  root: {
    fontFamily: '\'Verlag SSm 3r\'',
  },
  sizeSmall: {
    lineHeight: '37px',
    padding: '0 8px 0 8px',
    '&& *': {
      verticalAlign: 'middle',
    },
    '&:last-child': {
      paddingRight: '8px'
    }
  },
  footer: {
    backgroundColor: TABLE_FOOTER_BACKGROUND_COLOR,
    color: TABLE_FOOTER_COLOR,
    fontWeight: 'bold'
  }
};
