import { jbColors } from '../jbColors';

const MuiInputBase = {
  root: {
    color: 'inherit',
  },
  input: {
    padding: '0.5em',
    border: `1px solid ${jbColors.royalBlue[100]}`,
    backgroundColor: jbColors.greenSmoke['10'],
  },
};

export default MuiInputBase;
