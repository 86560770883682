export const TOTAL_ASSETS = Object.freeze({
  code: 'TOTAL_ASSETS',
  desc: 'Total Assets'
});
export const INVESTED_ASSETS = Object.freeze({
  code: 'INVESTED_ASSETS',
  desc: 'Invested Assets'
});

export const getRecurringFeeCalculationBasisDescriptionFromCode = (code) => {
  return [
    TOTAL_ASSETS,
    INVESTED_ASSETS,
  ]
    .filter(condition => condition.code === code)
    .map(condition => condition.desc)[0];
}