import React from 'react';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import {
  HEADER_RM_PDB_INFO_TEXT
} from '../../../../../../../../../../common/enums/infoTexts';
import HoverInfo from '../../../../../../../../../../views/Common/components/HoverInfo';

const useStyles = makeStyles(() => ({
  bubble: {
    height: '15px',
    width: '15px',
    backgroundColor: '#ff0000',
    borderRadius: '50%',
    float:'left',
  },
  title: {
    paddingLeft: '8px',
    paddingRight: '10px',
    fontSize : 'medium',
    float:'left',
    paddingBottom: '0px',
  },
  infoIcon: {
    float:'left'
  }
}));

const BubbleCell = ({markerData}) => {
  const classes = useStyles();

  function getDonutColor(markerColor) {
    let donutColor;
    if (markerColor === 'G') {
      donutColor = '#1e9600'
    } else if (markerColor === 'Y') {
      donutColor = '#fff200'
    } else if (markerColor === 'O') {
      donutColor = '#ff8000'
    }else if (markerColor === 'R') {
      donutColor = '#ff0000'
    }else if (markerColor === 'N') {
      donutColor = '#eeeeee'
    } else {
      donutColor = '#eeeeee'
    }
    return donutColor;
  }
  const donutColor = {
    backgroundColor: getDonutColor(markerData?.color)
  };

  return (
    <React.Fragment>
      <div>
        <span className={classes.bubble} style= {donutColor}/>
        {(markerData === undefined || markerData?.color === 'N' ) && <span className={classes.title}> n/a </span>}
        {markerData && markerData?.color !== 'N' &&<span className={classes.title}>{markerData?.value} % </span> }
        {markerData?.fromDashboard && <HoverInfo text={HEADER_RM_PDB_INFO_TEXT}><InfoIcon className={classes.infoIcon} fontSize="small"/> </HoverInfo> }
      </div>
    </React.Fragment>
  )
};


BubbleCell.propTypes = {
  markerData: PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.number,
    title: PropTypes.string,
    fromDashboard: PropTypes.bool
  })
};

export default BubbleCell;