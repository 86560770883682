import React from 'react';
import PropTypes from 'prop-types';
import {TableCell} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {CLIPP_WHITE} from '../../../../../../../../theme/common/bjbColor';
import {TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR} from '../../../../../../../../theme/common/color';

const useStyles = makeStyles((theme) => ({
  sortIcon: {
    fontSize: '12px'
  },
  tableCellHeader: {
    backgroundColor: TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR,
    color: CLIPP_WHITE,
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '20px',
    minWidth: '100px',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    position: 'sticky',
    top: '-1px',
    zIndex: 1
  },
  tableCellHeaderContent: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const HeaderCell = ({column, handleSort, sortByInfo}) => {
  const classes = useStyles();
  const handleHeaderClick = e => {
    column
      .getHeaderProps(column.getSortByToggleProps())
      .onClick(e);
    handleSort && handleSort(column);
  }
  return (
    <TableCell
      {...column.getHeaderProps(column.getSortByToggleProps())}
      className={classes.tableCellHeader}
      onClick= {handleHeaderClick}
    >
      <div className={classes.tableCellHeaderContent}>
        {column.render('Header')}
        {column.canSort && (
          <span className={classes.sortIcon}>
            {handleSort === undefined ? (
              column.isSorted ? (
                column.isSortedDesc ? (
                  <ExpandLessIcon/>
                ) : (
                  <ExpandMoreIcon/>
                )) : (
                <UnfoldMoreIcon/>
              )) : (
              (column.id === sortByInfo.key) ? (
                (sortByInfo.direction === 'DESC') ? (
                  <ExpandLessIcon/>
                ) : (
                  <ExpandMoreIcon/>
                )) : (
                <UnfoldMoreIcon/>
              ))
            }
          </span>
        )
        }
      </div>
    </TableCell>
  );
};

HeaderCell.propTypes = {
  column: PropTypes.object
};

export default HeaderCell;
