import React from 'react';
import PropTypes from 'prop-types';
import PriceConditionTableColumn from './PriceConditionTableColumn';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../common/constants';
import {TEXT} from './PriceConditionType';
import PriceConditionTableRowSection from './PriceConditionTableRowSection';

const PriceConditionDisplayTextRow = ({standardFeeConditionLabel, newConditionLabel, oldConditionLabel, showOldCondition, showNewCondition, testIdSuffix, columnProps}) => {
  if(showNewCondition || showOldCondition) {
    return <PriceConditionTableRowSection data={[]}>
      <PriceConditionTableColumn field="label"/>

      {showOldCondition &&
      <PriceConditionTableColumn
        borderRight
        customStyles={OLD_CONDITION_CUSTOM_STYLE}
        field="label"
        label={oldConditionLabel}
        type={TEXT}
      />}
      {showNewCondition &&
      <PriceConditionTableColumn
        data-testid={`standard-condition-display-text-${testIdSuffix}`}
        field="label"
        label={standardFeeConditionLabel}
        type={TEXT}
        {...columnProps}
      />}
      {showNewCondition &&
      <PriceConditionTableColumn
        data-testid={`new-condition-display-text-${testIdSuffix}`}
        field="label"
        label={newConditionLabel}
        type={TEXT}
        {...columnProps}
      />}
    </PriceConditionTableRowSection>
  }

  return null;
}

PriceConditionDisplayTextRow.propTypes = {
  columnProps: PropTypes.object,
  newConditionLabel: PropTypes.string,
  oldConditionLabel: PropTypes.string,
  showNewCondition: PropTypes.bool,
  showOldCondition: PropTypes.bool,
  standardFeeConditionLabel: PropTypes.string,
  testIdSuffix: PropTypes.string
};

export default PriceConditionDisplayTextRow;
