import React from 'react';
import {PERCENTAGE} from '../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../../Common/components/PriceNumberFormat';
import {PERCENTAGE_DISCOUNT} from '../../../../../../common/enums/transactionFeeCondition';
import {isNullOrUndefined} from '../../../../../../common/isNullOrUndefined';

const LABEL = '% Discount on List Price';
const KEY_PREFIX = 'transaction-fees-percentage-discount';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const percentageDiscount = ({oldConditionCode, newConditionCode, subFund, handleValidationResultChange, handleValueChange, readOnly, specialRequest}) => {

  let result = {
    label: LABEL
  };

  if (!isNullOrUndefined(subFund.currentCondition?.discountOnListPrice)) {
    result = {
      ...result,
      currentCondition: getMappedOldCondition(subFund.currentCondition?.discountOnListPrice, oldConditionCode)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(subFund.newCondition?.discountOnListPrice, newConditionCode, handleValidationResultChange, handleValueChange, readOnly, specialRequest)
  }

};

const getMappedOldCondition = (discountOnListPrice, conditionCode) => {
  if (conditionCode !== PERCENTAGE_DISCOUNT.code) {
    return null;
  }

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={PERCENTAGE}
      value={discountOnListPrice}
    />);

  return {
    value: readonlyField
  };
};

const getMappedNewCondition = (discountOnListPrice, conditionCode, handleValidationResultChange, handleValueChange, readOnly, specialRequest) => {
  if (conditionCode !== PERCENTAGE_DISCOUNT.code) {
    return null;
  }

  const editableField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      disableMin={specialRequest}
      isEditable
      key={`${KEY_PREFIX}-editable`}
      onValidationResultChange={(vr) => handleValidationResultChange({
        ...vr,
        selectors: ['newCondition', 'discountOnListPrice']
      })}
      onValueChange={(vr) => handleValueChange({...vr, selectors: ['newCondition', 'discountOnListPrice']})}
      type={PERCENTAGE}
      value={discountOnListPrice}
    />);

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={PERCENTAGE}
      value={discountOnListPrice}
    />);

  return {
    value: readOnly ? readonlyField : editableField,
    isEditable: !readOnly
  };
};

