import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {PriceRequestStore} from '../../PriceSimulationStore';
import Paper from '@material-ui/core/Paper';
import {BoxHeader, LabelRadioGroup} from '../../../Common/components';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import {Grid} from '@material-ui/core';
import {UPDATE_OVERDRAFT, UPDATE_OVERDRAFT_VALIDATION_RESULT} from './overdraftActions';
import OverdraftPricing from './components/OverdraftPricing';
import LabelTextAreaAutosize from '../../../Common/components/form/LabelTextareaAutosize';
import {TEXT_AREA_MAX_LENGTH} from '../../../../common/inputConstants';
import {PENDING_PM} from '../../../../common/statusTypes';
import {isEAMorEFA} from '../../../../common/enums/serviceModel';
import AgreementTypeIndicator from '../Common/AgreementTypeIndicator';
import {BILA} from '../../../../common/enums/agreementType';

export const STANDARD_CONDITION = 'STANDARD';
export const SPECIAL_CONDITION = 'SPECIAL';
export const PLUS_OPERATOR = 'PLUS';
export const MINUS_OPERATOR = 'MINUS';

const Overdraft = ({readOnly}) => {
  const {state, dispatch} = useContext(PriceRequestStore);
  const [overdraftRemarks, setOverdraftRemarks] = useState('');

  const operatorOptions = [
    {key: PLUS_OPERATOR, value: '+'},
    {key: MINUS_OPERATOR, value: '-'}
  ];

  const radioOptions = [
    {label: 'Standard', value: STANDARD_CONDITION},
    {label: 'Special', value: SPECIAL_CONDITION}
  ]

  const overdraft = state.selectedPricingRequest.overdraft;

  useEffect(() => {
    setOverdraftRemarks(state.selectedPricingRequest.overdraft?.overdraftRemarks);
  }, [state.selectedPricingRequest.overdraft]);

  const handleValidationResultChange = (selector, isValid) => {
    dispatch({type: UPDATE_OVERDRAFT_VALIDATION_RESULT, selector: selector, isValid: isValid});
  };

  const handleOverdraftConditionChange = event => {
    const updatedOverdraft = {
      ...overdraft,
      overdraftPricingInPercentage: null,
      overdraftOperator: operatorOptions[0].key,
      overdraftRemarks: ''
    };
    const {name, value} = event.target;
    updatedOverdraft[name] = value;
    dispatch({type: UPDATE_OVERDRAFT, data: updatedOverdraft});
  };

  const handleOverdraftPricingInPercentage = (value, isValid) => {
    const newOverdraft = {...overdraft, overdraftPricingInPercentage: value};
    dispatch({type: UPDATE_OVERDRAFT, data: newOverdraft});
    dispatch({type: UPDATE_OVERDRAFT_VALIDATION_RESULT, selector: 'OVERDRAFT', isValid: isValid});
  };

  const handleOverdraftOperator = event => {
    const newOverdraft = {...overdraft};
    newOverdraft['overdraftOperator'] = event.value;
    dispatch({type: UPDATE_OVERDRAFT, data: newOverdraft});
  };

  const handleOverdraftRemarksOnChange = (event) => {
    if (overdraftRemarks !== event.target.value) {
      setOverdraftRemarks(event.target.value);
    }
  };


  const handleOverdraftRemarksOnBlur = event => {
    const newOverdraft = {...overdraft};
    newOverdraft['overdraftRemarks'] = event.target.value;
    dispatch({type: UPDATE_OVERDRAFT, data: newOverdraft});
  };

  const handleAgreementTypeIndicator = (checked) => {
    const updatedOverdraft = {
      ...overdraft,
      pricingAgreedWithClient:checked ? BILA : null
    };
    dispatch({type: UPDATE_OVERDRAFT, data: updatedOverdraft});
  }

  if (!overdraft) {
    return <LoadingSpinner/>;
  }

  return (
    <div>
      <Paper style={{padding: '10px'}}>
        <BoxHeader text={'Overdraft'}/>
        <AgreementTypeIndicator
          agreedWithClient={state.selectedPricingRequest.overdraft?.pricingAgreedWithClient?.code}
          handleChange={handleAgreementTypeIndicator}
          readOnly={readOnly || state.selectedPricingRequest?.common?.pricingRequestStatus === PENDING_PM}
          show={!isEAMorEFA(state.selectedPricingRequest.common?.serviceModel?.code,state.selectedPricingRequest.common?.agentType)}
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <LabelRadioGroup
              disabled={readOnly}
              label="Condition"
              name="overdraftCondition"
              onChange={handleOverdraftConditionChange}
              options={radioOptions}
              value={overdraft?.overdraftCondition}
            />
          </Grid>
          {overdraft.overdraftCondition && overdraft.overdraftCondition === SPECIAL_CONDITION &&
          <Grid item xs={12}>
            <OverdraftPricing
              label="Overdraft pricing in % COF"
              onOperatorChange={handleOverdraftOperator}
              onPricingPercentageChange={handleOverdraftPricingInPercentage}
              onValidationResultChange={handleValidationResultChange}
              operatorOptions={operatorOptions}
              overdraft={overdraft}
              readOnly={readOnly}
            />
          </Grid>
          }
          <Grid item xs={12}>
            <LabelTextAreaAutosize
              data-testid="test-remarks"
              label="Remarks"
              maxLength={TEXT_AREA_MAX_LENGTH}
              name="overdraftRemarks"
              onBlur={e => handleOverdraftRemarksOnBlur(e)}
              onChange={e => handleOverdraftRemarksOnChange(e)}
              readOnly={readOnly}
              rowsMax={10}
              rowsMin={10}
              style={{width: '98%'}}
              value={overdraftRemarks || ''}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>

  );
};

Overdraft.propTypes = {
  readOnly: PropTypes.bool,
}

export default Overdraft;
