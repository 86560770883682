import React, {useContext, useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import TransactionFeePanel from './TransactionFeePanel';
import PropTypes from 'prop-types';
import {
  ERROR_BACKGROUND_PRIMARY_COLOR,
  EXPANSION_PANEL_SECONDARY_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_COLOR,
  EXPANSION_PANEL_SECONDARY_FOCUS_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_FOCUS_COLOR,
  EXPANSION_PANEL_SECONDARY_HOVER_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_HOVER_COLOR
} from '../../../../../theme/common/color';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {PriceRequestStore} from '../../../PriceSimulationStore';
import {isEAMorEFA} from '../../../../../common/enums/serviceModel';
import EffectiveCondition from '../../Common/EffectiveCondition';

const useStyles = makeStyles(() => ({
  displayFlexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  headerEffectivePrice: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '200px'
  },
  innerExpansionPanel: {
    width: '100%'
  },
  innerExpansionPanelSummary: {
    backgroundColor: EXPANSION_PANEL_SECONDARY_BACKGROUND_COLOR,
    color: EXPANSION_PANEL_SECONDARY_COLOR,
    fontSize: '0.8125rem',
    height: '36px',
    minHeight: '36px!important',
    '&:focus': {
      backgroundColor: EXPANSION_PANEL_SECONDARY_FOCUS_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_SECONDARY_FOCUS_COLOR
    },
    '&:hover': {
      backgroundColor: EXPANSION_PANEL_SECONDARY_HOVER_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_SECONDARY_HOVER_COLOR
    }
  },
  innerExpansionPanelSummaryTitle: {
    fontFamily: '\'Verlag SSm 7r\''
  },
  error: {
    backgroundColor: ERROR_BACKGROUND_PRIMARY_COLOR
  }
}));

const TransactionFeeSubFund = ({customTieringEnabled, fundTypeCode, handleAddTier, handleRemoveTier, handleRangeStartChange, handleRangeEndChange,
  handleValueChange, handleValidationResultChange, isExpanded, onExpansionToggle, readOnly,
  specialRequest, subFund, specialRequestPm }) => {

  const classes = useStyles();
  const {state} = useContext(PriceRequestStore);
  const {agentType, serviceModel, pricingRequestStatus} = state.selectedPricingRequest?.common;

  // eslint-disable-next-line react/no-multi-comp
  const getTransactionFeePanelDetails = () => {
    return (
      <TransactionFeePanel
        agentType={agentType}
        customTieringEnabled={customTieringEnabled}
        fundTypeCode={fundTypeCode}
        handleAddTier={handleAddTier}
        handleRangeEndChange={handleRangeEndChange}
        handleRangeStartChange={handleRangeStartChange}
        handleRemoveTier={handleRemoveTier}
        handleValidationResultChange={handleValidationResultChange}
        handleValueChange={handleValueChange}
        key={`transaction-fee-panel-${fundTypeCode}-${subFund.assetSubLevel.code}`}
        pricingRequestStatus={pricingRequestStatus}
        readOnly={readOnly}
        serviceModel={serviceModel?.code}
        showAgreementIndicator={!isEAMorEFA(serviceModel?.code, agentType)}
        specialRequest={specialRequest}
        specialRequestPm={specialRequestPm}
        subFund={subFund}
        transactionFees={state.selectedPricingRequest.transactionFees}
      />
    );
  }

  const cachedTransactionFeePanelDetails = useMemo(getTransactionFeePanelDetails, [isExpanded ? Math.random() : false]);

  const hasError = (subFundTypeCode) => {
    const errorStrings = state.selectedPricingRequest.transactionFees?.errors;
    if (errorStrings?.length > 0) {
      const subFundErrors = errorStrings.map(errorString => errorString.split(',').map(x => x.trim())[1]);
      if (subFundErrors.includes(subFundTypeCode)) {
        return 'error'
      }
    }
    return '';
  }

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      className={classes.innerExpansionPanel}
      expanded={isExpanded}
      key={`expansion-${fundTypeCode}-${subFund.assetSubLevel.code}`}
      onChange={onExpansionToggle}
    >
      <AccordionSummary
        className={`${classes.innerExpansionPanelSummary} ${hasError(subFund.assetSubLevel.code)}`}
        data-testid={`transaction-fee-sub-funds-${subFund?.assetSubLevel?.code}-test-id`}
        expandIcon={<ExpandMoreIcon/>}
      >
        <Box
          className={classes.displayFlexSpaceBetween}
          data-testid={`expansion-${fundTypeCode}-${subFund.assetSubLevel.code}-header`}
        >
          <Box className={classes.innerExpansionPanelSummaryTitle}>{subFund.assetSubLevel.desc}</Box>
          <EffectiveCondition subFund={subFund} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {cachedTransactionFeePanelDetails}
      </AccordionDetails>
    </Accordion>
  );

};

TransactionFeeSubFund.propTypes = {
  customTieringEnabled: PropTypes.bool,
  fundTypeCode: PropTypes.string.isRequired,
  handleAddTier: PropTypes.func,
  handleRangeEndChange: PropTypes.func,
  handleRangeStartChange: PropTypes.func,
  handleRemoveTier: PropTypes.func,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onExpansionToggle: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  specialRequest: PropTypes.bool,
  specialRequestPm: PropTypes.bool,
  subFund: PropTypes.object.isRequired
};

export default TransactionFeeSubFund;
