import React, {useContext,useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {validateSummary} from '../../../../../common/validateSummary';
import {getErrorMessage} from '../../../../../common/getErrorMessage';
import {validatePricingRequest} from '../../../../../common/validatePricingRequest';
import {setIsLoading} from '../../../../../Services';
import {Store} from '../../../../../Store';
import {PriceRequestStore} from '../../../../PriceSimulation/PriceSimulationStore';
import {saveAndValidatePricingRequest} from '../../../../PriceSimulation/pricingRequestService';
import AsyncButton from '../../AsyncButton';
import {useNotification} from '../../Notification';
import CommonDialog from '../../Dialog/CommonDialog';
import {isAcknowledgementRequired} from '../../../../../common/isAcknowledgementRequired';
import {ACKNOWLEDGEMENT_LINK} from '../../../../../common/constants';

const SubmitButton = ({handleServiceModelChanged, ...other}) => {
  const {parentState, parentDispatch} = useContext(Store);
  const {state, dispatch} = useContext(PriceRequestStore);
  const notification = useNotification();
  const history = useHistory();
  const [isConditionDialogOpen, setIsConditionDialogOpen] = useState(false);

  const handleError = err => {
    notification.error('Failed trying to submit\n' + getErrorMessage(err));
    setIsLoading(false, parentDispatch);
  }

  const handleSubmit = async () => {
    try {
      const portfoliosForSubmission = parentState.portfolios.filter(portfolio =>
        state.selectedPricingRequest.common.portfolioNumbersForSubmission?.some(
          portfolioNumberForSubmission => portfolioNumberForSubmission === portfolio.portfolioNumber
        )
      );
      const pricingRequestIds = portfoliosForSubmission.map(portfolio => portfolio.pricingRequestId);

      setIsLoading(true, parentDispatch);
      const portfoliosWithInvalidServiceModel = await saveAndValidatePricingRequest(
        parentState,
        state,
        dispatch
      );

      if (portfoliosWithInvalidServiceModel?.length > 0) {
        handleServiceModelChanged(portfoliosWithInvalidServiceModel);
        handleError(new Error('Service model changed'));
        return;
      }

      const {invalidPricingRequestIds} = await validateSummary(pricingRequestIds);

      if (invalidPricingRequestIds.length > 0) {
        history.push(`/simulation/${invalidPricingRequestIds[0].pricingRequestId}`);
        handleError(new Error(invalidPricingRequestIds[0].errorMessage));
        return;
      }

      if(isAcknowledgementRequired(state)){
        setIsConditionDialogOpen(true);
      } else {
        await continueWithSubmission();
      }
      setIsLoading(false, parentDispatch);
    } catch (err) {
      handleError(err);
      throw err;
    }
  };

  const continueWithSubmission = async () => {
    try{
      setIsLoading(true, parentDispatch);
      setIsConditionDialogOpen(false);
      const portfoliosForSubmission = parentState.portfolios.filter(portfolio =>
        state.selectedPricingRequest.common.portfolioNumbersForSubmission?.some(
          portfolioNumberForSubmission => portfolioNumberForSubmission === portfolio.portfolioNumber
        )
      );
      const pricingRequestIds = portfoliosForSubmission.map(portfolio => portfolio.pricingRequestId);
      const portfolioNumbers = portfoliosForSubmission.map(portfolio => portfolio.portfolioNumber);

      const validatedPricingRequestIds = await validatePricingRequest(pricingRequestIds, portfolioNumbers);
      history.push(`/simulation/${validatedPricingRequestIds[0]}/summary`);
      setIsLoading(false, parentDispatch);
    } catch (err) {
      handleError(err);
      throw err;
    }
  };

  return (
    <React.Fragment>
      <CommonDialog
        link =  {ACKNOWLEDGEMENT_LINK}
        onClose={() => {
          setIsConditionDialogOpen(false);
          setIsLoading(false, parentDispatch);
        }}
        onContinue={continueWithSubmission}
        open={isConditionDialogOpen}
        text="Please note that the effective date of pricing conditions and fee charges are determined by the approval date in CliPP."
        title="ACKNOWLEDGEMENT"
      />
      <AsyncButton color="primary" onClick={handleSubmit} text="Submit" type="button" {...other} />
    </React.Fragment>);
};

SubmitButton.propTypes = {
  handleServiceModelChanged: PropTypes.func
}
export default SubmitButton;
