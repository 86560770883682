import React, {useEffect, useState} from 'react';
import {Box, Grid, IconButton, Link, Switch, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import {ColumnSelectionDialog} from './components';
import LabelDropdown from '../../../../../Common/components/tableFilter/LabelDropdown';
import LabelTextField from '../../../../../Common/components/tableFilter/LabelTextField';
import {HONGKONG, SINGAPORE} from '../../../../../../common/enums/bookingCenter';
import SearchIcon from '@material-ui/icons/Search';

const StatusFilter = ({actionOnCompleteResult, columnsData, selectedGroup, selectedFilter, filterCriteria, globalSearchPortfolioNo, handleChangeTenant, handleSelectFilter, handleSelectGroup, handleFilterCriteriaInput, handleSelectedColumns, handleGlobalSearchPortfolioNo, handleOnEnter, setGlobalSearchPortfolioNo, tenant}) => {
  const [columnSelectionOpened, setColumnSelectionOpened] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    const selectableColumns = columnsData.filter(column => column.canHide);
    setSelectedColumns(selectableColumns);
  }, [columnsData]);

  useEffect(() => {
    if (columnsData) {
      const groupList = columnsData.filter(column => column.canGroup && !column.hidden);
      const filterList = columnsData.filter(column => column.canFilter && !column.hidden);

      setGroupList(groupList);
      setFilterList(filterList);
    }
  }, [columnsData]);

  const closeColumnSelection = () => setColumnSelectionOpened(false);

  const applyColumnSelection = value => {
    handleSelectedColumns(value);
  };

  const switchTenant = e => {
    let tenant = SINGAPORE.code;
    if (e.target.checked) {
      tenant = HONGKONG.code;
    }
    handleChangeTenant(tenant);
  };

  return (
    <Box alignItems="center" display="flex" flexWrap="wrap" marginBottom={2}>
      <Grid container spacing={2}>
        <Grid item>
          <ColumnSelectionDialog
            applyColumnSelection={applyColumnSelection}
            onClose={closeColumnSelection}
            open={columnSelectionOpened}
            selectedColumns={selectedColumns}
          />
          <Link
            component="button"
            data-testid="test-column-link"
            onClick={() => setColumnSelectionOpened(true)}
          >
            Select columns
          </Link>
        </Grid>
        <Grid item>
          <LabelDropdown
            data-testid="test-group-by"
            label={actionOnCompleteResult ? 'Group By' : 'Group This Page By'}
            onChange={handleSelectGroup}
            options={groupList}
            value={selectedGroup}
          />
        </Grid>
        <Grid item>
          <LabelDropdown
            data-testid="test-filter-by"
            label={actionOnCompleteResult ? 'Filter By' : 'Filter This Page By'}
            onChange={handleSelectFilter}
            options={filterList}
            value={selectedFilter}
          />
        </Grid>
        <Grid item>
          <LabelTextField
            clearable
            data-testid="test-filter-criteria"
            label="Filter Criteria"
            onChange={handleFilterCriteriaInput}
            onClear={() => handleFilterCriteriaInput({target: {value: ''}})}
            onKeyDown={handleOnEnter && handleOnEnter}
            value={filterCriteria}
          />
        </Grid>
        {handleChangeTenant && (
          <Grid item>
            <Box alignItems="center" display="flex">
              <Typography variant="caption">SG</Typography>
              <Switch
                checked={tenant === HONGKONG.code}
                color="default"
                onChange={switchTenant}
                value="test"
              />
              <Typography variant="caption">HK</Typography>
            </Box>
          </Grid>
        )}
        {handleGlobalSearchPortfolioNo && (
          <Grid item>
            <Box display="flex">
              <LabelTextField
                clearable
                label="Search Portfolio"
                onChange={e => setGlobalSearchPortfolioNo(e.target.value.trim())}
                onClear={() => {
                  setGlobalSearchPortfolioNo('');
                  handleGlobalSearchPortfolioNo(null);
                }}
                value={globalSearchPortfolioNo}
              />
              <IconButton
                aria-label="search"
                onClick={() => handleGlobalSearchPortfolioNo(globalSearchPortfolioNo)}
                size="small"
              >
                <SearchIcon/>
              </IconButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

StatusFilter.propTypes = {
  columnsData: PropTypes.any,
  filterCriteria: PropTypes.string,
  globalSearchPortfolioNo: PropTypes.string,
  handleChangeTenant: PropTypes.func,
  handleFilterCriteriaInput: PropTypes.func,
  handleGlobalSearchPortfolioNo: PropTypes.func,
  handleSelectFilter: PropTypes.func,
  handleSelectGroup: PropTypes.func,
  handleSelectedColumns: PropTypes.func,
  selectedFilter: PropTypes.string,
  selectedGroup: PropTypes.string,
  setGlobalSearchPortfolioNo: PropTypes.func,
  tenant: PropTypes.string,
};

export default StatusFilter;
