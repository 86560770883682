import {SELECT_CUSTOMER, SELECT_PORTFOLIO_FOR_SUBMISSION, SET_GROUPING_DATA} from '../../common/actionTypes';
import api from '../../api/api';
import {LOAD_PRICING_REQUEST_RESPONSE} from './components/pricingRequestActions';
import {LOAD_FUTURE_REVENUE_RESPONSE, LOAD_PDB_RESPONSE} from './components/RevenueSimulation/revenueActions';
import {getErrorMessage} from '../../common/getErrorMessage';
import {getSelectedPriceRequestErrors} from '../../common/getSelectedPriceRequestErrors';
import {SELECT_TAB_INDEX} from './components/ScrollableTab/selectedTabActions';
import {isNonStandardRecurringFeeCondition} from '../../common/enums/recurringFeeCondition';
import {BILA, NOT_APPLICABLE} from '../../common/enums/agreementType';
import {isNonStandardCondition} from '../../common/enums/transactionFeeCondition';

export const loadPricingRequest = async (pricingRequestId, dispatch) => {
  return new Promise((resolve, reject) => {
    api.pricingRequest
      .get(pricingRequestId)
      .then(payload => {
        dispatch({type: LOAD_PRICING_REQUEST_RESPONSE, payload: payload});
        resolve(payload);
      })
      .catch(err => reject(err));
  });
};

export const updatePricingModel = async (pricingRequestId, pricingModelId) => {
  return new Promise((resolve, reject) => {
    api.pricingRequest
      .updatePricingModel(pricingRequestId, {pricingModelId})
      .then(payload => {
        resolve(payload.data);
      })
      .catch(err => reject(err));
  });
};

export const resetToStandard = async (pricingRequestId) => {
  return new Promise((resolve, reject) => {
    api.pricingRequest
      .resetToStandard(pricingRequestId)
      .then(payload => {
        resolve(payload.data);
      })
      .catch(err => reject(err));
  });
};

export const updatePricingRequest = async (selectedPricingRequest, dispatch) => {
  const awaitedSaveRequest = await api.pricingRequest.updatePricingRequest({
    ...selectedPricingRequest,
    ...selectedPricingRequest.common
  });

  api.pricingRequest.revenue
    .getFuture(selectedPricingRequest.common.pricingRequestId)
    .then(revenuePayload => {
      dispatch({type: LOAD_FUTURE_REVENUE_RESPONSE, data: revenuePayload.data});
    })
    .catch(err => {
      console.error('Revenue could not be recalculated\n' + getErrorMessage(err));
    });

  return awaitedSaveRequest;
};

export const updatePricingRequest_upd = async (selectedPricingRequest, dispatch, pricingRequestIds, parentDispatch, parentState) => {
  const awaitedSaveRequest = await api.pricingRequest.updatePricingRequest({
    ...selectedPricingRequest,
    ...selectedPricingRequest.common
  });

  const revenuePayload = await api.pricingRequest.revenue
    .getFuture(selectedPricingRequest.common.pricingRequestId)
    .catch(err => {
      console.error('Revenue could not be recalculated\n' + getErrorMessage(err));
    });
  dispatch({type: LOAD_FUTURE_REVENUE_RESPONSE, data: revenuePayload.data});

  const futureRevenue = revenuePayload?.data;
  const currentRevenue = selectedPricingRequest?.revenue?.currentRevenue;
  const pdbData = selectedPricingRequest?.revenue?.pdbData;
  const pdbPayload = await api.pricingRequest.revenue.postPdb(selectedPricingRequest.common.pricingRequestId,
    {
      'currentReqId' : selectedPricingRequest.common.pricingRequestId,
      'selectedReqIds' : pricingRequestIds,
      'currentRevenue' : currentRevenue,
      'futureRevenue' : futureRevenue,
      'pdbData' : pdbData
    }
  );
  dispatch({type: LOAD_PDB_RESPONSE, data: pdbPayload.data});

  const groupingData = parentState.groupingdata;
  console.log('Grouping Data from pricingRequestService -------------------OKOKOKOKOKOKKOKOKKKOK--------------------', groupingData);
  parentDispatch({type: SET_GROUPING_DATA, payload: null});
  let updatedGroupingPayload = await api.customer.getUpdatedDetails(
    {
      'groupingData' : groupingData,
      'currentRevenue' : currentRevenue,
      'futureRevenue' : futureRevenue
    }
  );
  //dispatch({type: LOAD_UPDATED_GROUPING_DATA_RESPONSE, data: updatedGroupingPayload.data?.groupingData});
  console.log('updatedGroupingPayload.data from recurring fee tab -->', updatedGroupingPayload.data);
  parentDispatch({type: SET_GROUPING_DATA, payload: updatedGroupingPayload.data});

  return awaitedSaveRequest;
};

export const updateCustomerDetails = async (customerDetails, parentDispatch) => {
  console.log('called updateCustomerDetails----------->');
  const response = await api.customer.getDetails(customerDetails.accountNumber, '');
  parentDispatch({
    type: SELECT_CUSTOMER,
    payload: {...customerDetails, ...response.data}
  });

  return response;
};

const checkAtLeastOnePortfolioSelected = (portfoliosForSubmission, dispatch) => {
  dispatch({type: SELECT_PORTFOLIO_FOR_SUBMISSION, payload: portfoliosForSubmission});
  return portfoliosForSubmission?.length > 0
};

const validateServiceModel = async portfoliosForSubmission => {
  const mapInvalidPortfolios = filteredData => ({
    portfolioNumber: filteredData.portfolioNumber,
    newServiceModel: filteredData.newServiceModel,
    newMandateType: filteredData.newMandateType
  });

  const response = await api.pricingRequest.validateServiceModel(portfoliosForSubmission);
  return response.data
    ?.filter(data => data.serviceModelChanged)
    .map(mapInvalidPortfolios);
};

const getPortfoliosForSubmissionInState = (parentState, state) => {
  return parentState.portfolios.filter(portfolio =>
    state.selectedPricingRequest.common.portfolioNumbersForSubmission?.some(
      portfolioNumberForSubmission => portfolioNumberForSubmission === portfolio.portfolioNumber
    )
  );
}

const checkSpecialStructureValid = (state, dispatch) => {
  const isEAM = state?.selectedPricingRequest?.summary?.pricingAgreement?.code === NOT_APPLICABLE.code;
  if(state?.selectedPricingRequest?.recurringFees){
    const hasBila = (state?.selectedPricingRequest?.recurringFees?.pricingAgreedWithClient?.code === BILA.code);
    const isRFNonStandardCondition = isNonStandardRecurringFeeCondition(state?.selectedPricingRequest?.recurringFees?.newCondition?.condition?.code);
    const isRFAcknowledged = state.selectedPricingRequest?.recurringFees?.newCondition?.acknowledged;
    const hasError = isEAM ? !isRFAcknowledged : !(hasBila && isRFAcknowledged);
    if(isRFNonStandardCondition && hasError){
      dispatch({type: SELECT_TAB_INDEX, payload: 3});
      return false;
    }
  }
  let tempTransactionFees = state.selectedPricingRequest?.transactionFees;
  if(tempTransactionFees){
    for(const [, subFunds] of Object.entries(tempTransactionFees?.transactionAssetTypes)){
      for (const subFund of subFunds) {
        const hasBila = subFund?.newCondition?.agreementEnum?.code === BILA.code;
        const isNonStandard = isNonStandardCondition(subFund?.newCondition?.transactionFeeCondition?.code);
        const isAcknowledged = subFund?.newCondition?.acknowledged;
        const hasError = isEAM ? !isAcknowledged : !(hasBila && isAcknowledged);
        if(isNonStandard && hasError){
          dispatch({type: SELECT_TAB_INDEX, payload: 4});
          return false
        }
      }
    }
  }
  return true;
};

export const saveAndValidatePricingRequest = async (parentState, state, dispatch, portfolioToSubmit) => {
  if (getSelectedPriceRequestErrors(state).length > 0) {
    throw new Error('Resolve validation errors before saving');
  }

  const portfoliosForSubmission = portfolioToSubmit || getPortfoliosForSubmissionInState(parentState, state);

  if (!checkAtLeastOnePortfolioSelected(portfoliosForSubmission, dispatch)) {
    // throw {
    //   response: {data: {message: 'Please select a portfolio to submit'}}
    // };
    throw new Error('Please select a portfolio to submit');
  }

  const atLeastOneReasonSelected = state.selectedPricingRequest.summary?.discountReasons?.reduce(
    (prev, curr) => prev || curr.selected,
    false
  );

  if (!atLeastOneReasonSelected) {
    dispatch({type: SELECT_TAB_INDEX, payload: 0});
    throw new Error('Please select at least one discount reason');
  }

  if(!checkSpecialStructureValid(state, dispatch)){
    throw new Error('Please check special structure validation errors');
  }

  await updatePricingRequest(state.selectedPricingRequest, dispatch);
  return await validateServiceModel(portfoliosForSubmission);
};
