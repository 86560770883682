import {CLIPP_BLACK, CLIPP_WHITE} from './common/bjbColor';

export default {
  common: {
    black: CLIPP_BLACK,
    white: CLIPP_WHITE
  },
  text: {
    primary: CLIPP_BLACK
  }
};
