import {Backdrop} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingSpinner from '../LoadingSpinner';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const BackdropProgress = ({open}) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <LoadingSpinner/>
    </Backdrop>
  );
};

BackdropProgress.propTypes = {
  open: PropTypes.bool
}

export default BackdropProgress;

