export const SINGAPORE = Object.freeze({
  code: 'SG',
  desc: 'Singapore'
});
export const HONGKONG = Object.freeze({
  code: 'HK',
  desc: 'Hong Kong'
});

export const getBookingCenterCodeFromDescription = description => {
  const filtered = [SINGAPORE, HONGKONG]
    .filter(bookingCenter => bookingCenter.desc === description)
    .map(bookingCenter => bookingCenter.code);

  if(filtered){
    return filtered[0];
  }

  return undefined;
};

export const getBookingCenterDescriptionFromCode = code => {
  return [SINGAPORE, HONGKONG]
    .filter(bookingCenter => bookingCenter.code === code)
    .map(bookingCenter => bookingCenter.desc)[0];
};
