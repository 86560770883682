import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import PriceConditionTableRowSection from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {
  LABEL,
  NEW_CONDITION_EDITABLE,
  NEW_CONDITION_READONLY,
  NEW_CONDITIONS,
  OLD_CONDITION_READONLY,
  OLD_CONDITIONS
} from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import PriceConditionTable from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import {feePerTransaction} from './objectmapper/transactionFeesFeePerTransactionObjectMapper';
import {minimumFeePerTransaction} from './objectmapper/transactionFeesMinimumFeePerTransactionObjectMapper';
import {ticketFee} from './objectmapper/transactionFeesTicketFeeObjectMapper';
import {percentageDiscount} from './objectmapper/transactionFeesPercentageDiscountObjectMapper';
import {waiveChargesOnRedemption} from './objectmapper/transactionFeesWaiveChargesOnRedemptionObjectMapper';
import {amendmentFee} from './objectmapper/transactionFeesAmendmentFeeObjectMapper';
import {liquidationFee} from './objectmapper/transactionFeesLiquidationFeeObjectMapper';
import {initialCharge} from './objectmapper/transactionFeesInitialChargeObjectMapper';
import {tierPricingSpecialRequest} from './objectmapper/transactionFeesTierPricingSpecialRequestObjectMapper';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../../common/constants';
import NMBAcknowledgement from '../../NMBFees/panel/NMBAcknowledgement';
import {BILA} from '../../../../../common/enums/agreementType';
import {isEAMorEFA, EAM_EFA_MANAGED} from '../../../../../common/enums/serviceModel';
import {PENDING_PM} from '../../../../../common/statusTypes';
import {getOldConditionLabelAgreementType} from '../../../../../common/getOldConditionLabelAgreementType';
import {getNMBDeclaration} from '../../../../../common/enums/declaration';
import PriceConditionSelectRow from '../../../../../layouts/Common/components/PriceConditionTable/PriceConditionSelectRow';
import {
  getTransactionFeeConditionDescriptionFromCode,
  getTransactionFeeConditionSelectValues,
  isNonStandardCondition
} from '../../../../../common/enums/transactionFeeCondition';
import CommonDialog from '../../../../Common/components/Dialog/CommonDialog';

const TransactionFeeSubAssetConditionTable = ({
  fundTypeCode, handleAddTier, handleRemoveTier, handleRangeStartChange,
  handleRangeEndChange, handleValidationResultChange, handleValueChange, readOnly,
  specialRequest, subFund, transactionFees, serviceModel, agentType, pricingRequestStatus,specialRequestPm
}) => {
  const [isConditionDialogOpen, setIsConditionDialogOpen] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState(false);
  const newConditionCode = subFund.newCondition?.transactionFeeCondition?.code;
  const oldConditionCode = subFund.currentCondition?.transactionFeeCondition?.code
  const properties = {
    fundTypeCode,
    handleAddTier,
    handleRemoveTier,
    handleRangeStartChange,
    handleRangeEndChange,
    handleValidationResultChange,
    handleValueChange,
    newConditionCode,
    oldConditionCode,
    readOnly,
    specialRequest,
    subFund,
    transactionFees,
    agentType,
    serviceModel,
    pricingRequestStatus,
    testIdSuffix: subFund.assetSubLevel?.code,
    specialRequestPm
  };

  const ticketFeeDeps = [fundTypeCode, newConditionCode, subFund.newCondition?.ticketFee];
  const percentageDiscountDeps = [fundTypeCode, newConditionCode, subFund.newCondition?.discountOnListPrice];

  const feePerTransactionDeps = [fundTypeCode, newConditionCode, subFund.newCondition?.feePerTransaction?.listPrice, subFund.newCondition?.feePerTransaction?.effectivePrice];
  const minimumFeePerTransactionDeps = [fundTypeCode, newConditionCode, subFund.newCondition?.minFeePerTransaction?.listPrice, subFund.newCondition?.minFeePerTransaction?.effectivePrice];
  const waiveChargesOnRedemptionDeps = [fundTypeCode, newConditionCode, subFund.newCondition?.waiveChargesOnRedemption];
  const amendmentFeeDeps = [fundTypeCode, newConditionCode, subFund.newCondition?.amendmentFee?.listPrice, subFund.newCondition?.amendmentFee?.effectivePrice];
  const liquidationFeeDeps = [fundTypeCode, newConditionCode, subFund.newCondition?.liquidationFee?.listPrice, subFund.newCondition?.liquidationFee?.effectivePrice];
  const initialChargeDeps = [fundTypeCode, newConditionCode, subFund.currentCondition?.initialCharge];
  const columnProps = {
    borderRight: !subFund.currentCondition
  };
  const nonEmptyEntries = obj => obj?.currentCondition || obj?.newCondition;
  const oldConditionLabel = getOldConditionLabelAgreementType(subFund.currentCondition?.agreementEnum?.code);
  const hasBila = (subFund.newCondition?.agreementEnum?.code === BILA.code && subFund.newCondition?.acknowledged);
  const isEAM = serviceModel === EAM_EFA_MANAGED;
  const nonStandardFlag = isNonStandardCondition(newConditionCode);
  const mandatorySpecialCondition = !readOnly && nonStandardFlag && !subFund.newCondition?.acknowledged;

  const labelValueSection = [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => ticketFee(properties), [ticketFeeDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => percentageDiscount(properties), [percentageDiscountDeps])
  ].filter(nonEmptyEntries);

  const labelListEffectivePriceSection = [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => feePerTransaction(properties), [feePerTransactionDeps]),
    ...tierPricingSpecialRequest(properties),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => minimumFeePerTransaction(properties), [minimumFeePerTransactionDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => waiveChargesOnRedemption(properties), [waiveChargesOnRedemptionDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => amendmentFee(properties), [amendmentFeeDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => liquidationFee(properties), [liquidationFeeDeps]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => initialCharge(properties), [initialChargeDeps])
  ].filter(nonEmptyEntries);

  const changeCondition = (value) => {
    setSelectedCondition(value);
    if(specialRequest && isNonStandardCondition(subFund.newCondition?.transactionFeeCondition?.code)){
      setIsConditionDialogOpen(!isNonStandardCondition(value));
      if(isNonStandardCondition(value)){
        updateCondition(value);
      }
    } else {
      updateCondition(value);
    }
  }

  const updateCondition = (value) => {
    handleValueChange(
      {
        value: getTransactionFeeConditionSelectValues(specialRequest, hasBila, isEAM,subFund.currentCondition?.transactionFeeCondition?.code, subFund.newCondition?.transactionFeeCondition?.code).filter(option => option.key === value)[0],
        selectors: ['newCondition', 'transactionFeeCondition'],
        isFeeChanged: true
      });
  }

  const getTable = () => {
    return (
      <PriceConditionTable width={subFund.currentCondition ? '100%' : '70%'}>
        <CommonDialog
          onClose={() => setIsConditionDialogOpen(false)}
          onContinue={() => {
            updateCondition(selectedCondition);
            setIsConditionDialogOpen(false);
          }}
          open={isConditionDialogOpen}
          testId={`test-update-condition-warning-${subFund.assetSubLevel?.code}`}
          text="Changing the condition will remove all your custom changes. Do you want to proceed?"
        />
        <PriceConditionSelectRow
          columnProps={columnProps}
          newConditionLabel={'Fee Condition'}
          newConditionValue={!readOnly ? subFund.newCondition?.transactionFeeCondition?.code: getTransactionFeeConditionDescriptionFromCode(subFund.newCondition?.transactionFeeCondition?.code)}
          oldConditionValue={getTransactionFeeConditionDescriptionFromCode(subFund.currentCondition?.transactionFeeCondition?.code)}
          onChange={(e) => {
            changeCondition(e.value)
          }}
          options={getTransactionFeeConditionSelectValues(specialRequest, hasBila, isEAM,subFund.currentCondition?.transactionFeeCondition?.code,subFund.newCondition?.transactionFeeCondition?.code)}
          readOnly={readOnly}
          readOnlyLabel={'Fee Condition:'}
          showNewCondition={!!subFund.newCondition?.transactionFeeCondition}
          showOldCondition={!!subFund.currentCondition?.transactionFeeCondition}
          testId={`test-condition-${subFund.assetSubLevel?.code}`}
        />
        <PriceConditionTableRowSection data={labelValueSection}>
          <PriceConditionTableColumn field="label" type={LABEL}/>
          {subFund.currentCondition &&
          <PriceConditionTableColumn
            customStyles={OLD_CONDITION_CUSTOM_STYLE}
            data-testid="test-old-condition-label"
            field="currentCondition.value"
            label={oldConditionLabel}
            type={OLD_CONDITIONS}
          />}
          <PriceConditionTableColumn
            colSpan={2}
            field="newCondition.value"
            isEditableFieldValueAccessor="newCondition.isEditable"
            label="NEW CONDITIONS"
            type={NEW_CONDITIONS}
          />
        </PriceConditionTableRowSection>
        <PriceConditionTableRowSection data={labelListEffectivePriceSection}>
          <PriceConditionTableColumn field="label" type={LABEL}/>
          {subFund.currentCondition &&
          <PriceConditionTableColumn
            customStyles={OLD_CONDITION_CUSTOM_STYLE}
            field="currentCondition.effectivePrice"
            label="Discounted Conditions"
            type={OLD_CONDITION_READONLY}
          />}
          <PriceConditionTableColumn field="newCondition.listPrice" label="List Price" type={NEW_CONDITION_READONLY}/>
          <PriceConditionTableColumn
            field="newCondition.effectivePrice"
            isDisabledAccessor="newCondition.isDisabled"
            isEditableFieldValueAccessor="newCondition.isEditable"
            label="Discounted Conditions"
            type={NEW_CONDITION_EDITABLE}
          />
        </PriceConditionTableRowSection>

        <PriceConditionTableRowSection data={[]}>
          {subFund.currentCondition && <>
            <PriceConditionTableColumn
              borderRight
              colSpan={2}
              customStyles={OLD_CONDITION_CUSTOM_STYLE}
              field="label"
              label={
                <NMBAcknowledgement
                  ackProps={{ inputProps: { 'data-testid': `current-condition-customized-structure-indicator-${subFund.assetSubLevel.code}-checkbox` } }}
                  acknowledged={subFund.currentCondition?.acknowledged}
                  addInsProps={{ 'data-testid': `current-condition-${subFund.assetSubLevel.code}-additional-instruction-text` }}
                  additionalInstruction={subFund.currentCondition?.additionalInstructions}
                  agentType={agentType}
                  agreementType={subFund.currentCondition?.agreementEnum?.code}
                  declarationTxt={getNMBDeclaration(serviceModel, agentType, true)}
                  disabled
                  readOnly
                  serviceModel={serviceModel}
                  show
                />}
              style={{ padding: '0px' }}
            /></>}
          {!subFund.currentCondition && <PriceConditionTableColumn field="label"/>}
          <PriceConditionTableColumn
            colSpan={2}
            field="label"
            label={
              <NMBAcknowledgement
                ackProps={{ inputProps: { 'data-testid': `new-condition-test-customized-structure-indicator-${subFund.assetSubLevel.code}-checkbox` } }}
                acknowledged={subFund.newCondition?.acknowledged}
                addInsProps={{ 'data-testid': `new-condition-${subFund.assetSubLevel.code}-additional-instruction-text` }}
                additionalInstruction={subFund.newCondition?.additionalInstructions}
                agentType={agentType}
                agreementType={subFund.newCondition?.agreementEnum?.code}
                declarationTxt={getNMBDeclaration(serviceModel, agentType, true)}
                disabled={(subFund.newCondition?.agreementEnum?.code !== BILA.code && !isEAMorEFA(serviceModel, agentType)) || pricingRequestStatus === PENDING_PM}
                handleChange={(val) => handleValueChange({ ...val })}
                handleValidationResultChange={handleValidationResultChange}
                isNonStandardCondition={isNonStandardCondition(selectedCondition ? selectedCondition : newConditionCode)}
                readOnly={readOnly}
                serviceModel={serviceModel}
                show
              />}
            style={{ padding: '0px', backgroundColor: (mandatorySpecialCondition) ? '#971b2f59' : '' }}
            {...columnProps}
          />
        </PriceConditionTableRowSection>

      </PriceConditionTable>
    );
  };

  return getTable();

};

TransactionFeeSubAssetConditionTable.propTypes = {
  agentType: PropTypes.string,
  fundTypeCode: PropTypes.string.isRequired,
  handleAddTier: PropTypes.func.isRequired,
  handleRangeEndChange: PropTypes.func.isRequired,
  handleRangeStartChange:  PropTypes.func.isRequired,
  handleRemoveTier: PropTypes.func.isRequired,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  pricingRequestStatus: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  serviceModel: PropTypes.string.isRequired,
  specialRequest: PropTypes.bool,
  specialRequestPm: PropTypes.bool,
  subFund: PropTypes.object.isRequired,
  transactionFees: PropTypes.object.isRequired
};

export default TransactionFeeSubAssetConditionTable;
