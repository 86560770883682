import React from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {CLIPP_BLACK} from '../../../../../../../../theme/common/bjbColor';

const useStyles = makeStyles(theme => ({
  tableCellHeader: {
    border: 'none',
    color: CLIPP_BLACK,
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '20px',
    minWidth: '100px',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0)
  },
  tableCellBody: {
    border: 'none',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '25px',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0)
  },
}));

const FeeCommissionTable = ({data}) => {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCellHeader}>ID</TableCell>
          <TableCell className={classes.tableCellHeader}>Description</TableCell>
          <TableCell className={classes.tableCellHeader}>Short description</TableCell>
          <TableCell className={classes.tableCellHeader}>Flat amount</TableCell>
          <TableCell className={classes.tableCellHeader}>Up to amount</TableCell>
          <TableCell className={classes.tableCellHeader}>Percentage</TableCell>
          <TableCell className={classes.tableCellHeader}>Overall min amount</TableCell>
          <TableCell className={classes.tableCellHeader}>Overall max amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map(feeCommissionType => (
          <TableRow key={feeCommissionType.id}>
            <TableCell className={classes.tableCellBody}>
              {feeCommissionType.id}
            </TableCell>
            <TableCell className={classes.tableCellBody}>
              {feeCommissionType.description}
            </TableCell>
            <TableCell className={classes.tableCellBody}>
              {feeCommissionType.shortDescription}
            </TableCell>
            <TableCell className={classes.tableCellBody}>
              {feeCommissionType.flatAmt}
            </TableCell>
            <TableCell className={classes.tableCellBody}>
              {feeCommissionType.upToAmt}
            </TableCell>
            <TableCell className={classes.tableCellBody}>
              {feeCommissionType.percentage}
            </TableCell>
            <TableCell className={classes.tableCellBody}>
              {feeCommissionType.overallMinAmt}
            </TableCell>
            <TableCell className={classes.tableCellBody}>
              {feeCommissionType.overallMaxAmt}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

FeeCommissionTable.propTypes = {
  data: PropTypes.any
}

export default FeeCommissionTable;
