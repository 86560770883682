import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import HoverInfo from '../../../../views/Common/components/HoverInfo';
import InfoIcon from '@material-ui/icons/InfoOutlined';

export const createDataRow = (rowMetaData, record, classes, rowIndex, defaultValue) => {
  const rowCells = [];
  const uniqueRowIdentifier = record.key || getFieldValue(rowMetaData[0].fieldValueAccessor, record);

  for (const cellMetaData of rowMetaData) {
    const uniqueCellIdentifier = cellMetaData.fieldValueAccessor;
    const fieldValue = getFieldValue(cellMetaData.fieldValueAccessor, record) || defaultValue;
    const isEditableField = getFieldValue(cellMetaData.isEditableFieldValueAccessor, record);
    const isDisabled = getFieldValue(cellMetaData.isDisabledAccessor, record);
    const isHighlightedField = getFieldValue(cellMetaData.isHighlightedFieldValueAccessor, record);
    const infoText = getFieldValue(cellMetaData.infoTextAccessor, record);
    const customStyles = cellMetaData.customStyles;
    const borderRight = cellMetaData.borderRight;

    let className = classes.readonlyTableBodyCell;
    if (isDisabled) {
      className = classes.disabledTableBodyCell;
    } else if (isEditableField) {
      className = classes.editableTableBodyCell;
    } else if (isHighlightedField) {
      className = classes.highlightedTableBodyCell;
    }

    rowCells.push((
      <TableCell
        className={className}
        key={`cell-${uniqueRowIdentifier}-${uniqueCellIdentifier}`}
        {...cellMetaData.cellAttributes}
        style={{...customStyles,...{borderRight: borderRight?'1px solid rgb(227, 229, 229)':'0px'}}}
        variant={record.variant || 'body'}
      >
        {fieldValue}
        {infoText &&
        <HoverInfo text={infoText}><InfoIcon fontSize="small" style={{marginLeft: '4px', paddingBottom: '4px'}}/>
        </HoverInfo>}
      </TableCell>
    ));
  }
  return (
    <TableRow key={`${uniqueRowIdentifier}-${rowIndex}`}>
      {rowCells}
    </TableRow>
  );
}

export const getVisibleChildren = (children) => {
  let applicableChildren;
  if (Array.isArray(children)) {
    applicableChildren = children
  } else {
    applicableChildren = [children]
  }
  applicableChildren = applicableChildren
    .filter(child => !!child);
  return applicableChildren;
};

const getFieldValue = (fieldValueAccessor, object) => {
  if (!fieldValueAccessor) {
    return null;
  }
  const valueAccessors = fieldValueAccessor.split('.');
  let fieldValue = object;
  for (const valueAccessor of valueAccessors) {
    if (fieldValue instanceof Object) {
      fieldValue = fieldValue[valueAccessor];
    }
  }
  return fieldValue;
};
