import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {DialogContentText} from '@material-ui/core';
import PropTypes from 'prop-types';


const TEST_PREFIX = 'test-reset-request';

const ResetConfirmationDialog = ({onClose, onContinueReset, open}) => {
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown onClose={onClose} open={open}>
      <DialogContent>
        <DialogContentText>
          Are you sure? The revocation of the special conditions to standard pricing cannot be reversed.
          The cancellation of any existing bilateral agreements for this portfolio will be communicated to
          the client in writing. No new request can be raised for this portfolio until the 30 days notice period has passed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" data-testid={`${TEST_PREFIX}-cancel-button`} onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" data-testid={`${TEST_PREFIX}-confirm-button`} onClick={onContinueReset}>
          I acknowledge and confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ResetConfirmationDialog.propTypes = {
  onClose: PropTypes.any,
  onContinueReset: PropTypes.any,
  open: PropTypes.any
};


export default ResetConfirmationDialog;