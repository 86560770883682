import React, {useEffect, useState} from 'react';
import {Checkbox, FormControlLabel, Grid, makeStyles, TextareaAutosize, Typography} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';
import {CLIPP_LIGHT_BLUE} from '../../../../../theme/common/bjbColor';
import {
  ERROR_BACKGROUND_PRIMARY_COLOR,
  TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR
} from '../../../../../theme/common/color';
import FormHelperText from '@material-ui/core/FormHelperText';
import {isEAMorEFA} from '../../../../../common/enums/serviceModel';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    padding: theme.spacing(1)
  },
  gridRoot: {
    backgroundColor: CLIPP_LIGHT_BLUE
  },
  label: {
    fontSize: '0.8125rem'
  },
  labelDisabled: {
    color: '#00000073',
    fontSize: '0.8125rem'
  },
  labelPlacementStart: {
    marginRight: '0px',
    alignItems: 'start'
  },
  editableTableBodyCell: {
    fontSize: '0.8125rem',
    backgroundColor: TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR
  }
}));

const NMBAcknowledgement = ({acknowledged, additionalInstruction, agentType, declarationTxt, handleChange, handleValidationResultChange, isNonStandardCondition, readOnly, show, serviceModel, disabled, ackProps, addInsProps, testIdSuffix}) => {
  const classes = useStyles();
  const [mandatoryReminder, setMandatoryReminder] = useState();
  const [sizeGreater, setSizeGreater] = useState();
  const [mandatorySpecialCondition, setMandatorySpecialCondition] = useState();

  useEffect(() => {
    if(isNonStandardCondition && !acknowledged && !disabled && !readOnly){
      setMandatorySpecialCondition(true);
    }
    if (acknowledged && !additionalInstruction) {
      setMandatoryReminder(true);
      handleValidationResultChange({isValid: false, selectors: ['newCondition', 'additionalInstructions']});
    } else if (sizeGreater){
      setSizeGreater(true);
      handleValidationResultChange({isValid: false, selectors: ['newCondition', 'additionalInstructions']});
    } else{
      setMandatoryReminder(false);
      setSizeGreater(false);
    }
    // eslint-disable-next-line
  }, [acknowledged, additionalInstruction]);

  const isEmpty = (str) => {
    return !str || str.trim().length === 0 || str.trim() === '';
  }

  const isSizeGreater = (str) => {
    return str.trim().length > 512;
  }

  const handleAdditionalInstructions = event => {
    setMandatoryReminder(isEmpty(event.target.value));
    setSizeGreater(isSizeGreater(event.target.value));
    handleChange({ value: event.target.value, selectors: ['newCondition', 'additionalInstructions'] ,isAdditionalInstructionsChange: true});
    handleValidationResultChange({isValid: !isEmpty(event.target.value), selectors: ['newCondition', 'additionalInstructions']});
  };

  const handleAcknowledge = (e) => {
    if (e.target.checked) {
      handleChange({value: true, selectors: ['newCondition', 'acknowledged']});
      if (isEmpty(additionalInstruction)) {
        setMandatoryReminder(true);
        handleValidationResultChange({isValid: false, selectors: ['newCondition', 'additionalInstructions']});
      } else if (isSizeGreater(additionalInstruction)){
        setSizeGreater(true);
        handleValidationResultChange({isValid: false, selectors: ['newCondition', 'additionalInstructions']});
      }
      setMandatorySpecialCondition(false);
      handleValidationResultChange({isValid: true, selectors: ['newCondition', 'acknowledged']});
    } else {
      if(isNonStandardCondition && !readOnly){
        setMandatorySpecialCondition(true);
        handleValidationResultChange({isValid: false, selectors: ['newCondition', 'acknowledged']});
      }
      handleChange({value: false, selectors: ['newCondition', 'acknowledged']});
      handleValidationResultChange({isValid: true, selectors: ['newCondition', 'additionalInstructions']});
      setMandatoryReminder(false);
      setSizeGreater(false);
    }
  }

  return (
    <>
      {show &&
      <Grid container justify="flex-end">
        <Grid className={`${!readOnly && !disabled ? classes.editableTableBodyCell : ''}`} item xs={12}>
          <FormControlLabel
            classes={{root: classes.root, label: classes.label, labelPlacementStart: classes.labelPlacementStart}}
            control={
              <Checkbox
                checked={acknowledged}
                color="primary"
                disabled={readOnly || disabled}
                icon={<CheckBoxOutlineBlankIcon style={{backgroundColor: '#f5f8fa'}}/>}
                inputProps={{'data-testid':`customized-structure-indicator-${testIdSuffix}-checkbox`}}
                name="acknowledge"
                onChange={handleAcknowledge}
                style={{ pointerEvents: 'auto' }}
                {...ackProps}
              />
            }
            data-testid={`test-declaration-txt-${isEAMorEFA(serviceModel, agentType)?'eam':'non-eam'}-${testIdSuffix}-id`}
            key="acknowledge"
            label={declarationTxt}
            labelPlacement={'start'}
            style={{whiteSpace: 'pre-line', pointerEvents: 'none'}}
          />
          {(mandatorySpecialCondition && <FormHelperText>
            <span data-testid={`test-validation-error-message-special-structures-${testIdSuffix}-id`} style={{whiteSpace: 'pre-line', pointerEvents: 'none',color: ERROR_BACKGROUND_PRIMARY_COLOR}}>
                Acknowledgment is mandatory for special structures.</span>
          </FormHelperText>)}
        </Grid>
        <Grid
          className={`${!readOnly && !disabled && acknowledged ? classes.editableTableBodyCell : ''}`}
          container
          justify="flex-end"
          style={{padding: '20px'}}
        >
          <Grid item xs={6}>
            <Typography className={readOnly ? classes.labelDisabled : classes.label} variant="h5">
              Additional Instructions
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextareaAutosize
              className={readOnly || disabled ? classes.labelDisabled : classes.label}
              data-testid={`additional-instruction-${testIdSuffix}-text`}
              disabled={!acknowledged || readOnly || disabled}
              maxLength={512}
              name="additionalInstructions"
              onChange={handleAdditionalInstructions}
              readOnly={readOnly}
              rowsMax={5}
              rowsMin={5}
              style={{width: '98%', backgroundColor: mandatoryReminder ? '#971b2f59' : ''}}
              value={additionalInstruction}
              {...addInsProps}
            />
            {mandatoryReminder && <FormHelperText>
              <span data-testid={`test-validation-error-message-${testIdSuffix}-id`} style={{color: ERROR_BACKGROUND_PRIMARY_COLOR}}>
                This field is mandatory.</span>
            </FormHelperText>}
            {sizeGreater && <FormHelperText>
              <span data-testid={`test-validation-error-message-${testIdSuffix}-id`} style={{color: ERROR_BACKGROUND_PRIMARY_COLOR}}>
                Size should be less than 512 chars.</span>
            </FormHelperText>}
          </Grid>
        </Grid>
      </Grid>
      }
    </>);
};

NMBAcknowledgement.propTypes = {
  ackProps: PropTypes.object,
  acknowledged: PropTypes.bool,
  addInsProps: PropTypes.object,
  additionalInstruction: PropTypes.string,
  agentType: PropTypes.string,
  declarationTxt: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  handleValidationResultChange: PropTypes.func,
  isNonStandardCondition: PropTypes.bool,
  readOnly: PropTypes.bool,
  serviceModel: PropTypes.string.isRequired,
  show: PropTypes.bool,
  testIdSuffix: PropTypes.string
};

export default NMBAcknowledgement;
