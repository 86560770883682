import { jbColors } from '../jbColors';

const MuiIconButton = {
  input: {
    backgroundColor: jbColors.greenSmoke[10],
    borderRadius: 0,
  },
  multiline: {
    padding: 0,
  },
};

export default MuiIconButton;
