import React from 'react';
import PropTypes from 'prop-types';
import TransactionFeeSubFund from './TransactionFeeSubFund';
import {Box} from '@material-ui/core';


const TransactionFeeSubFunds = ({customTieringEnabled, expandedPanels, fundTypeCode, handleAddTier, handleRemoveTier, handleRangeStartChange,
  handleRangeEndChange, handlePanelExpansionToggle, handleValueChange, handleValidationResultChange, readOnly, specialRequest, subFunds, specialRequestPm}) => {

  let subFundElements = [];
  for (const subFund of subFunds) {
    subFundElements.push(
      <TransactionFeeSubFund
        customTieringEnabled={customTieringEnabled}
        fundTypeCode={fundTypeCode}
        handleAddTier={({selectors, ...others}) => handleAddTier({
          ...others,
          selectors: [subFund.assetSubLevel.code, ...selectors]
        })}
        handleRangeEndChange={({selectors, ...others}) => handleRangeEndChange({
          ...others,
          selectors: [subFund.assetSubLevel.code, ...selectors]
        })}
        handleRangeStartChange={({selectors, ...others}) => handleRangeStartChange({
          ...others,
          selectors: [subFund.assetSubLevel.code, ...selectors]
        })}
        handleRemoveTier={({selectors, ...others}) => handleRemoveTier({
          ...others,
          selectors: [subFund.assetSubLevel.code, ...selectors]
        })}
        handleValidationResultChange={({selectors, ...others}) => handleValidationResultChange({
          ...others,
          selectors: [subFund.assetSubLevel.code, ...selectors]
        })}
        handleValueChange={({selectors, ...others}) => handleValueChange({
          ...others,
          selectors: [subFund.assetSubLevel.code, ...selectors]
        })}
        isExpanded={expandedPanels.includes(subFund.assetSubLevel.code)}
        key={`transaction-fee-fund-${fundTypeCode}-sub-fund-${subFund.assetSubLevel.code}`}
        onExpansionToggle={() => handlePanelExpansionToggle(subFund.assetSubLevel.code)}
        readOnly={readOnly}
        specialRequest={specialRequest}
        specialRequestPm={specialRequestPm}
        subFund={subFund}
      />
    )
  }

  return (
    <Box width={1}>
      {subFundElements}
    </Box>
  );


};

TransactionFeeSubFunds.propTypes = {
  customTieringEnabled: PropTypes.bool,
  expandedPanels: PropTypes.array.isRequired,
  fundTypeCode: PropTypes.string.isRequired,
  handleAddTier: PropTypes.func,
  handlePanelExpansionToggle: PropTypes.func.isRequired,
  handleRangeEndChange: PropTypes.func,
  handleRangeStartChange: PropTypes.func,
  handleRemoveTier: PropTypes.func,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  specialRequest: PropTypes.bool,
  specialRequestPm: PropTypes.bool,
  subFunds: PropTypes.array.isRequired
};

export default TransactionFeeSubFunds;
