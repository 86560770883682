import React from 'react';
import {Box} from '@material-ui/core';
import PriceNumberFormat from '../../Common/components/PriceNumberFormat';
import {BJB_BLUE_80} from '../../../theme/common/bjbColor';

/* eslint-disable */

const createSectionTitle = (value, className) => <Box className={className}>{value}</Box>;
const createFee = (value, type) => <PriceNumberFormat type={type} value={value}/>;
const createNormalText = (value, className) => <Box className={className}>{value}</Box>;
const createSecondLevelLabel = (value,className) => <Box className={className} paddingLeft={2}>{value}</Box>;
const createEmpty = (className) => <Box className={className}>-</Box>;
const createSubLevelTitle = (value, color) => <Box style={{color: color? color: BJB_BLUE_80}}><strong>{value}</strong></Box>;
const isElementNull = element => {
  return Boolean(element.type()=== null);
};

export {
  createSectionTitle,
  createFee,
  createNormalText,
  createSecondLevelLabel,
  createSubLevelTitle,
  createEmpty,
  isElementNull
}
