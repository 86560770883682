export default xml => {
  if (!xml) {
    return xml;
  }

  let formatted = '', indent = '';
  xml.split(/>\s*</).forEach(function (node) {
    if (node.match(/^\/\w/)) indent = indent.substring(1); // decrease indent
    formatted += indent + '<' + node + '>\r\n';
    if (node.match(/^<?\w[^>]*[^/]$/)) indent += '\t'; // increase indent
  });
  return formatted.substring(1, formatted.length - 3);
};
