import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../../../../../../../../../api/api';
import { getErrorMessage } from '../../../../../../../../../../../common/getErrorMessage';
import AsyncButton from '../../../../../../../../../../Common/components/AsyncButton';
import { useNotification } from '../../../../../../../../../../Common/components/Notification';
import PriceNumberFormat from '../../../../../../../../../../Common/components/PriceNumberFormat';
import { CHF } from '../../../../../../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {
  TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR,
  TABLE_BODY_READONLY_CELL_BACKGROUND_COLOR,
  TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_BACKGROUND_COLOR,
  TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_COLOR
} from '../../../../../../../../../../../theme/common/color';
import { CLIPP_LIGHT_GREY } from '../../../../../../../../../../../theme/common/bjbColor';
import AntSwitch from '../../../../../../../../../../Common/components/AntSwitch/AntSwitch';

const TEST_PREFIX = 'test-banking-fee-dialog';

const useStyles = makeStyles(() => ({
  content: {},
  cancel: {},
  header: {
    backgroundColor: TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_BACKGROUND_COLOR,
    color: TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_COLOR,
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    fontFamily: 'Verlag SSm 7r',
    textAlign: 'center'
  },
  readonlyTableBodyCell: {
    fontSize: '0.8125rem',
    '&.MuiTableCell-body': {
      backgroundColor: TABLE_BODY_READONLY_CELL_BACKGROUND_COLOR
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  editableTableBodyCell: {
    fontSize: '0.8125rem',
    '&.MuiTableCell-body': {
      backgroundColor: TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR
    }
  },
  notApplicableTableBodyCell: {
    fontSize: '0.8125rem',
    '&.MuiTableCell-body': {
      backgroundColor: CLIPP_LIGHT_GREY
    }
  },
  notApplicableTextField: {
    textAlign: 'right'
  },
  fitsInSingleLine: {
    fontSize: '0.8125rem',
    '&.MuiTableCell-body': {
      backgroundColor: TABLE_BODY_READONLY_CELL_BACKGROUND_COLOR
    },
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    whiteSpace: 'nowrap'
  }
}));

const BankingFeeDialog = ({
  currentBankingFees,
  deviationRetainedMailEnabled,
  open,
  onClose,
  onSubmit,
  portfolioNumber,
  pricingRequestId,
  serviceModal,
  tenantId
}) => {
  const RETAINED_MAIL_MAX_VALUE = 3000; // PFBCA-1863
  const CODED_ACCOUNT_MAX_VALUE = 1000; // 9075
  const BANKING_FEE_MAX_VALUE = 3000;
  const SPECIAL_MAILING_FEE_MAX_VALUE = 700;
  const REPORTING_FEE_MAX_VALUE = 240;
  const SPECIAL_MAILING = 'SPECIAL_MAILING';
  const REPORTING_FEE = 'REPORTING_FEE';
  const RETAINED_MAIL = 'RETAINED_MAIL';
  const BANKING_SERVICE_FEE = 'BANKING_SERVICE_FEE';
  const CODED_ACCOUNT = 'CODED_ACCOUNT';
  const classes = useStyles();
  const notification = useNotification();
  const showBankingServiceField = serviceModal === 'Discretionary Mandate';


  let initialState = {
    [SPECIAL_MAILING]:
      currentBankingFees && currentBankingFees[SPECIAL_MAILING]?.effectivePrice,
    [REPORTING_FEE]:
      currentBankingFees && currentBankingFees[REPORTING_FEE]?.effectivePrice,
    [CODED_ACCOUNT]:
      currentBankingFees && currentBankingFees[CODED_ACCOUNT]?.effectivePrice,
    [RETAINED_MAIL]:
      currentBankingFees && currentBankingFees[RETAINED_MAIL]?.effectivePrice
  };

  const [ongoing, setOngoing] = useState();
  const [bankingFeeValue, setBankingFeeValue] = useState(initialState);
  const [validationResult, setValidationResult] = useState({});
  let initialToggleState = {
    [SPECIAL_MAILING]: bankingFeeValue && bankingFeeValue[SPECIAL_MAILING] >= 0,
    [REPORTING_FEE]: bankingFeeValue && bankingFeeValue[REPORTING_FEE] >= 0,
    [CODED_ACCOUNT]: bankingFeeValue && bankingFeeValue[CODED_ACCOUNT] >= 0,
    [RETAINED_MAIL]: bankingFeeValue && bankingFeeValue[RETAINED_MAIL] >= 0
  };
  const [state, setState] = useState(initialToggleState);


  if (deviationRetainedMailEnabled) {
    // initialState[RETAINED_MAIL] =
    //   (currentBankingFees && currentBankingFees[RETAINED_MAIL]?.effectivePrice) || 0;
    //   initialToggleState[RETAINED_MAIL] = bankingFeeValue && bankingFeeValue[RETAINED_MAIL] >= 0;
  }

  if (!showBankingServiceField) {
    initialState[BANKING_SERVICE_FEE] = currentBankingFees &&
    currentBankingFees[BANKING_SERVICE_FEE]?.effectivePrice;
    initialToggleState[BANKING_SERVICE_FEE] = bankingFeeValue && bankingFeeValue[BANKING_SERVICE_FEE] >= 0;
  }

  useEffect(() => {
    if (open) {
      setBankingFeeValue(initialState);
      setState(initialToggleState);
    }
    // eslint-disable-next-line
  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = async () => {
    try {
      setOngoing(true);
      let bankingFeeDto = {
        newConditionCodeFees: {}
      };
      Object.entries(bankingFeeValue).forEach(([key, value]) => {
        if (state[key]) {
          bankingFeeDto.newConditionCodeFees[key] = {
            effectivePrice: value
          };
        }
      });
      await api.pricingRequest.submitDeviation(
        pricingRequestId,
        bankingFeeDto,
        tenantId
      );
      setOngoing(false);
      notification.success(
        'Successfully sent new pricing request for approval'
      );
      onSubmit();
    } catch (err) {
      notification.error(
        'Failed submitting banking fees \n' + getErrorMessage(err)
      );
      setOngoing(false);
    }
  };

  const updateValue = (key, value) => {
    setBankingFeeValue({ ...bankingFeeValue, [key]: value });
  };

  const handleValidationResultChange = (key, value) => {
    setValidationResult({ ...validationResult, [key]: value });
  };

  const isInputValid =
    Object.values(validationResult).filter((value) => !value).length === 0;

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };


  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={open}>
      <DialogTitle>
        <Typography>{portfolioNumber}</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.fitsInSingleLine}>Banking Service Fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell className={classes.header}>Effective Price</TableCell>
            </TableRow>
            {!showBankingServiceField && <TableRow>
              <TableCell className={classes.fitsInSingleLine}>
                Banking Service Package
                <AntSwitch
                  checked={state.BANKING_SERVICE_FEE}
                  name={BANKING_SERVICE_FEE}
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell
                className={
                  state.BANKING_SERVICE_FEE
                    ? classes.editableTableBodyCell
                    : classes.notApplicableTableBodyCell
                }
              >
                {state.BANKING_SERVICE_FEE ? (
                  <PriceNumberFormat
                    data-testid={`${TEST_PREFIX}-banking-service-package`}
                    decimal={0}
                    disableEmptyInput
                    isEditable
                    max={
                      currentBankingFees &&
                      currentBankingFees[BANKING_SERVICE_FEE]?.listPrice || BANKING_FEE_MAX_VALUE
                    }
                    onValidationResultChange={({ isValid }) =>
                      handleValidationResultChange(BANKING_SERVICE_FEE, isValid)
                    }
                    onValueChange={({ value }) =>
                      updateValue(BANKING_SERVICE_FEE, value)
                    }
                    type={CHF}
                    value={
                      (bankingFeeValue && bankingFeeValue[BANKING_SERVICE_FEE]) || 0
                    }
                  />
                ) : (
                  <TextField
                    defaultValue={'N.A.'}
                    disabled
                    inputProps={{
                      className: classes.notApplicableTextField
                    }}
                  />
                )}
              </TableCell>
            </TableRow>}
            <TableRow>
              <TableCell className={classes.readonlyTableBodyCell}>
                Special Mailing Fee
                <AntSwitch
                  checked={state.SPECIAL_MAILING}
                  name={SPECIAL_MAILING}
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell
                className={
                  state.SPECIAL_MAILING
                    ? classes.editableTableBodyCell
                    : classes.notApplicableTableBodyCell
                }
              >
                {state.SPECIAL_MAILING ? (
                  <PriceNumberFormat
                    data-testid={`${TEST_PREFIX}-special-mailing`}
                    decimal={0}
                    disableEmptyInput
                    isEditable
                    max={
                      currentBankingFees &&
                      currentBankingFees[SPECIAL_MAILING]?.listPrice || SPECIAL_MAILING_FEE_MAX_VALUE
                    }
                    onValidationResultChange={({ isValid }) =>
                      handleValidationResultChange(SPECIAL_MAILING, isValid)
                    }
                    onValueChange={({ value }) =>
                      updateValue(SPECIAL_MAILING, value)
                    }
                    type={CHF}
                    value={(bankingFeeValue && bankingFeeValue[SPECIAL_MAILING]) || 0}
                  />
                ) : (
                  <TextField
                    defaultValue={'N.A.'}
                    disabled
                    inputProps={{
                      className: classes.notApplicableTextField
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.readonlyTableBodyCell}>
                Reporting Fee
                <AntSwitch
                  checked={state.REPORTING_FEE}
                  name={REPORTING_FEE}
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell
                className={
                  state.REPORTING_FEE
                    ? classes.editableTableBodyCell
                    : classes.notApplicableTableBodyCell
                }
              >
                {state.REPORTING_FEE ? (
                  <PriceNumberFormat
                    data-testid={`${TEST_PREFIX}-reporting-fee`}
                    decimal={0}
                    disableEmptyInput
                    isEditable
                    max={
                      currentBankingFees &&
                      currentBankingFees[REPORTING_FEE]?.listPrice || REPORTING_FEE_MAX_VALUE
                    }
                    onValidationResultChange={({ isValid }) =>
                      handleValidationResultChange(REPORTING_FEE, isValid)
                    }
                    onValueChange={({ value }) =>
                      updateValue(REPORTING_FEE, value)
                    }
                    type={CHF}
                    value={(bankingFeeValue && bankingFeeValue[REPORTING_FEE]) || 0}
                  />
                ) : (
                  <TextField
                    defaultValue={'N.A.'}
                    disabled
                    inputProps={{
                      className: classes.notApplicableTextField
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.readonlyTableBodyCell}>
                Coded Account
                <AntSwitch
                  checked={state.CODED_ACCOUNT}
                  name={CODED_ACCOUNT}
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell
                className={
                  state.CODED_ACCOUNT
                    ? classes.editableTableBodyCell
                    : classes.notApplicableTableBodyCell
                }
              >
                {state.CODED_ACCOUNT ? (
                  <PriceNumberFormat
                    data-testid={`${TEST_PREFIX}-coded-account-fee`}
                    decimal={0}
                    disableEmptyInput
                    isEditable
                    max={CODED_ACCOUNT_MAX_VALUE}
                    onValidationResultChange={({ isValid }) =>
                      handleValidationResultChange(CODED_ACCOUNT, isValid)
                    }
                    onValueChange={({ value }) =>
                      updateValue(CODED_ACCOUNT, value)
                    }
                    type={CHF}
                    value={(bankingFeeValue && bankingFeeValue[CODED_ACCOUNT]) || 0}
                  />
                ) : (
                  <TextField
                    defaultValue={'N.A.'}
                    disabled
                    inputProps={{
                      className: classes.notApplicableTextField
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.readonlyTableBodyCell}>
                Retained Mail
                <AntSwitch
                  checked={state.RETAINED_MAIL}
                  name={RETAINED_MAIL}
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell
                className={
                  state.RETAINED_MAIL
                    ? classes.editableTableBodyCell
                    : classes.notApplicableTableBodyCell
                }
              >
                {state.RETAINED_MAIL ? (
                  <PriceNumberFormat
                    data-testid={`${TEST_PREFIX}-retained-mail`}
                    decimal={0}
                    disableEmptyInput
                    isEditable
                    max={RETAINED_MAIL_MAX_VALUE}
                    onValidationResultChange={({ isValid }) =>
                      handleValidationResultChange(RETAINED_MAIL, isValid)
                    }
                    onValueChange={({ value }) =>
                      updateValue(RETAINED_MAIL, value)
                    }
                    type={CHF}
                    value={(bankingFeeValue && bankingFeeValue[RETAINED_MAIL]) || 0}
                  />
                ) : (
                  <TextField
                    defaultValue={'N.A.'}
                    disabled
                    inputProps={{
                      className: classes.notApplicableTextField
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancel}
          color="primary"
          data-testid={`${TEST_PREFIX}-cancel-button`}
          disabled={ongoing}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <AsyncButton
          data-testid={`${TEST_PREFIX}-submit-button`}
          disabled={!isInputValid}
          onClick={handleSubmit}
          text="Submit"
        />
      </DialogActions>
    </Dialog>
  );
};

BankingFeeDialog.propTypes = {
  currentBankingFees: PropTypes.object,
  deviationRetainedMailEnabled: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  portfolioNumber: PropTypes.string,
  pricingRequestId: PropTypes.number,
  tenantId: PropTypes.string
};

export default BankingFeeDialog;
