import React from 'react';
import Box from '@material-ui/core/Box';
import PriceSelect from '../../../../Common/components/PriceSelect';
import {DPM} from '../../../../../common/enums/serviceModel';
import {
  getRecurringFeeCalculationBasisDescriptionFromCode,
  INVESTED_ASSETS,
  TOTAL_ASSETS
} from '../../../../../common/enums/recurringFeeCalculationBasis';
import {isNewRecurringFeeDifferent} from '../isNewRecurringFeeDifferent';

const LABEL = 'Calculation Basis';
const KEY_PREFIX = 'recurring-fees-calculation-basis';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const calculationBasis = ({handleValueChange, readOnly, recurringFees, serviceModelCode, showDiff}) => {

  if (serviceModelCode === DPM) {
    return null;
  }

  let result = {
    label: LABEL
  };
  let isNewValueDifferent = !!readOnly && isNewRecurringFeeDifferent(recurringFees, 'calculationBasis');

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (recurringFees?.currentCondition?.calculationBasis) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(recurringFees?.currentCondition?.calculationBasis)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(recurringFees?.newCondition?.calculationBasis, handleValueChange, readOnly, isNewValueDifferent)
  }

};

const getMappedOldCondition = (calculationBasis) => {

  const readonlyField = (
    <Box data-testid={`${TEST_KEY_PREFIX}-readonly`} textAlign="right">{calculationBasis?.desc}</Box>
  );

  return {
    value: readonlyField
  };
};

const getMappedNewCondition = (calculationBasis, handleValueChange, readOnly, isNewValueDifferent) => {

  const options = [
    {key: TOTAL_ASSETS.code, value: TOTAL_ASSETS.desc},
    {key: INVESTED_ASSETS.code, value: INVESTED_ASSETS.desc}
  ];

  const editableField = (
    <PriceSelect
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      inputProps={{id: 'uncontrolled-native'}}
      key={`${KEY_PREFIX}-editable`}
      onValueChange={(vr) => {
        handleValueChange({...vr, selectors: ['calculationBasis', 'code']})
        handleValueChange({
          ...vr,
          selectors: ['calculationBasis', 'desc'],
          value: getRecurringFeeCalculationBasisDescriptionFromCode(vr.value)
        })
      }}
      options={options}
      value={calculationBasis?.code}
    />
  );

  const readonlyField = (
    <Box data-testid={`${TEST_KEY_PREFIX}-readonly`} textAlign="right">{calculationBasis?.desc}</Box>
  );

  return {
    value: readOnly ? readonlyField : editableField,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
  };
};
