export const FXP_FX_CASH = Object.freeze({
  code: 'FXP_FX_CASH',
  display: null
});
export const FXP_SINGLE_OPTIONS = Object.freeze({
  code: 'FXP_SINGLE_OPTIONS',
  display: '% of option premium'
});
export const FXP_BINARY_OPTIONS = Object.freeze({
  code: 'FXP_BINARY_OPTIONS',
  display: '% of option premium'
});
export const FXP_COMBINATION_SINGLE_OPTIONS = Object.freeze({
  code: 'FXP_COMBINATION_SINGLE_OPTIONS',
  display: '% of option premium'
});
export const FXP_ACCU_DECU_NEW = Object.freeze({
  code: 'FXP_ACCU_DECU_NEW',
  display: '% of unleveraged notional amount'
});
export const FXP_TARF_NEW = Object.freeze({
  code: 'FXP_TARF_NEW',
  display: '% of unleveraged notional amount'
});
export const FXP_ACCU_DECU_TARF = Object.freeze({
  code: 'FXP_ACCU_DECU_TARF',
  display: 'Unleveraged Notional Amount'
});
export const PM_PHYSICAL_GOLD = Object.freeze({
  code: 'PM_PHYSICAL_GOLD',
  display: '% of investment amount'
});

export const getAllFxSubTypes = [FXP_FX_CASH, FXP_SINGLE_OPTIONS, FXP_BINARY_OPTIONS,
  FXP_COMBINATION_SINGLE_OPTIONS, FXP_ACCU_DECU_NEW, FXP_TARF_NEW, FXP_ACCU_DECU_TARF,
  PM_PHYSICAL_GOLD];

export const getDisplayBasedOnSubType = code => {
  const filtered = getAllFxSubTypes
    .filter(subType => subType.code === code);

  if(filtered){
    return filtered[0].display
  }

  return '% of option premium';
};
