import React, {useState} from 'react';
import {ExpandingCell} from './components';
import AsyncButton from '../../../../../../../Common/components/AsyncButton';
import {useNotification} from '../../../../../../../Common/components/Notification';
import adminApi from '../../../../../../../../api/adminApi';
import T24_SYNC_STATUS from '../../../../../../../../common/enums/t24SyncStatus';
import {
  ACTION_COLUMN,
  CREATED_INFO_COLUMN,
  FEE_COMMISSION_TYPE_ID_COLUMN,
  T24_SYNC_STATUS_COLUMN
} from '../../columnsData';
import {makeStyles} from '@material-ui/styles';
import {DEBUG_BACKGROUND} from '../../../../../../../../theme/common/color';
import {CLIPP_WHITE} from '../../../../../../../../theme/common/bjbColor';
import {getErrorMessage} from '../../../../../../../../common/getErrorMessage';
import CommonDialog from '../../../../../../../Common/components/Dialog/CommonDialog';
import Moment from 'react-moment';
import {DATE_TIME_FORMAT} from '../../../../../../../../common/dateFormat';

const useStyles = makeStyles(() => ({
  clickableElement: {
    cursor: 'pointer'
  },
  debugArea: {
    background: DEBUG_BACKGROUND,
    padding: '10px',
    color: CLIPP_WHITE
  }
})); 

function FeeCommissionCustomCell({cell, column, fetchData, row, value}) {
  const notification = useNotification();
  const [showDebug, setShowDebug] = useState(false);
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const classes = useStyles();

  const submitFeeCommissionTypeId = feeCommissionTypeId => {
    return new Promise((resolve, reject) => {
      adminApi.t24Workflow
        .submitFeeCommissionTypeToBCOM(feeCommissionTypeId)
        .then(() => {
          notification.success(`Successfully submitted ${feeCommissionTypeId}`);
          fetchData();
          resolve();
        })
        .catch((err) => {
          notification.error('Could not submit new FT/FD codes to BCOM/T24\n' + getErrorMessage(err));
          reject();
        });
    });
  };

  const deactivate = feeCommissionTypeId => {
    return new Promise((resolve, reject) => {


      adminApi.pricingAdmin
        .deactivate(feeCommissionTypeId)
        .then(() => {
          notification.success(`Successfully deactivated ${feeCommissionTypeId}`);
          fetchData();
          resolve();
        })
        .catch((err) => {
          notification.error('Could not deactivate the element!\n' + getErrorMessage(err));
          reject();
        });
    });
  };

  const updateFeeCommissionTypeT24SyncStatus = (feeCommissionTypeId, t24SyncStatus) => {
    return new Promise((resolve, reject) => {
      adminApi.pricingAdmin
        .updateFeeCommissionTypeT24SyncStatus(feeCommissionTypeId, t24SyncStatus)
        .then(() => updateFeeCommissionTypeT24SyncStatusSuccess(feeCommissionTypeId, resolve))
        .catch((err) => {
          notification.error('Could not update T24SyncStatus\n' + getErrorMessage(err));
          reject();
        });
    });
  };

  const updateFeeCommissionTypeT24SyncStatusSuccess = (feeCommissionTypeId, resolve) => {
    notification.success(`Successfully updated ${feeCommissionTypeId} T24SyncStatus`);
    fetchData();
    resolve();
  };

  const t24SyncStatus = row?.original?.t24SyncStatus;

  if (column.id === CREATED_INFO_COLUMN.key) {
    let createdBy = row.original.createdBy;
    let sourceId = row.original.sourcePricingRequestId;

    return <div>
      <Moment date={value} format={DATE_TIME_FORMAT}/>
      <span> by {createdBy}, for P.Req.: {sourceId}</span>
    </div>
  } else if (column.id === FEE_COMMISSION_TYPE_ID_COLUMN.key) {
    return <ExpandingCell cell={cell} row={row} strikeThrough={!row.original.active}/>;
  } else if (column.id === T24_SYNC_STATUS_COLUMN.key) {
    return (
      <React.Fragment>
        <div className={classes.clickableElement} onClick={() => setShowDebug(!showDebug)}>{value}</div>
        {showDebug && (
          <div className={classes.debugArea}>
            <div>***** DO NOT CONTINUE UNLESS YOU KNOW WHAT YOU ARE DOING *****</div>
            <AsyncButton
              onClick={() => updateFeeCommissionTypeT24SyncStatus(row?.original?.t24FeeCommissionTypeId, 'SUBMITTED')}
              text="SUBMITTED"
            />
            <AsyncButton
              onClick={() => updateFeeCommissionTypeT24SyncStatus(row?.original?.t24FeeCommissionTypeId, 'ERROR')}
              text="ERROR"
            />
            <AsyncButton
              onClick={() => updateFeeCommissionTypeT24SyncStatus(row?.original?.t24FeeCommissionTypeId, 'CONFIRMED')}
              text="CONFIRMED"
            />
          </div>)
        }
      </React.Fragment>
    );
  } else if (column.id === ACTION_COLUMN.key) {

    let canSubmitToT24 = row.original.active && [T24_SYNC_STATUS.NEW, T24_SYNC_STATUS.SUBMITTED, T24_SYNC_STATUS.ERROR].includes(t24SyncStatus)

    let canDeactivate = row.original.active;

    return (
      <React.Fragment>
        <CommonDialog
          onClose={() => setIsDeactivateDialogOpen(false)}
          onContinue={() => deactivate(row?.original?.t24FeeCommissionTypeId)}
          open={isDeactivateDialogOpen}
          text={`Please confirm that you want to deactivate the code 
                '${row?.original?.t24FeeCommissionTypeId}'. 
                This cannot be undone in and does not disable the code in T24.`}
        />
        <AsyncButton
          disabled={!canSubmitToT24}
          onClick={() => submitFeeCommissionTypeId(row?.original?.t24FeeCommissionTypeId)}
          text="Send"
          title="Send code to T24"
        />
        &nbsp;
        <AsyncButton
          disabled={!canDeactivate}
          onClick={async () => {
            setIsDeactivateDialogOpen(true);
            return;
          }}
          text="Deactivate"
        />
      </React.Fragment>);
  } else {
    return value ? value : null;
  }
}

export default FeeCommissionCustomCell;
