export const SG_TENANT = Object.freeze({
  code: 'SG',
  desc: 'Singapore'
});
export const HK_TENANT = Object.freeze({
  code: 'HK',
  desc: 'Hong Kong'
});

export const getTenantCodeFromDescription = description => {
  return [SG_TENANT, HK_TENANT]
    .filter(tenant => tenant.desc === description)
    .map(tenant => tenant.code)[0];
};

export const getTenantDescriptionFromCode = code => {
  return [SG_TENANT, HK_TENANT]
    .filter(tenant => tenant.code === code)
    .map(tenant => tenant.desc)[0];
};
  