import React, {useMemo, useState} from 'react';
import {Snackbar} from '@material-ui/core';
import Notify from '../../../../common/notificationTypes';
import NotificationContext from './NotificationContext';
import {createPortal} from 'react-dom';
import {Alert} from '../Alert/Alert';

const withNotificationProvider = (Component) => {
  return (props) => {
    const AUTO_HIDE_DURATION = 5000;
    const [notification, setNotification] = useState({});

    const providerValue = useMemo(() => {
      const error = message => {
        setNotification({type: 'error', message: message});
      };
      const success = message => {
        setNotification({type: 'success', message: message});
      };
      const warning = message => {
        setNotification({type: 'warning', message: message});
      };
      const info = message => {
        setNotification({type: 'info', message: message});
      };

      return {error, success, warning, info}
    }, [setNotification]);

    const handleClose = () => {
      setNotification({});
    };

    return (
      <NotificationContext.Provider value={providerValue}>
        <Component {...props}/>
        {createPortal(
          <>
            <Snackbar
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              autoHideDuration={AUTO_HIDE_DURATION}
              onClose={handleClose}
              open={notification.type === Notify.ERROR}
            >
              <Alert
                data-testid={'test-error-notification'}
                severity="error"
              >{notification.message}</Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              autoHideDuration={AUTO_HIDE_DURATION}
              onClose={handleClose}
              open={notification.type === Notify.SUCCESS}
            >
              <Alert
                data-testid={'test-success-notification'}
                severity="success"
              >{notification.message}</Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              autoHideDuration={AUTO_HIDE_DURATION}
              onClose={handleClose}
              open={notification.type === Notify.INFO}
            >
              <Alert
                data-testid={'test-info-notification'}
                severity="info"
              >{notification.message}</Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              autoHideDuration={AUTO_HIDE_DURATION}
              onClose={handleClose}
              open={notification.type === Notify.WARNING}
            >
              <Alert
                data-testid={'test-warning-notification'}
                severity="warning"
              >{notification.message}</Alert>
            </Snackbar>
          </>
          , document.body
        )

        }
      </NotificationContext.Provider>
    )
  };
};

export default withNotificationProvider;