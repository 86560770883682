import {UPDATE_OVERDRAFT, UPDATE_OVERDRAFT_VALIDATION_RESULT} from './overdraftActions';
import {LOAD_PRICING_REQUEST_RESPONSE} from '../pricingRequestActions';

export const overdraftReducer = (state, action) => {
  switch (action.type) {
    case LOAD_PRICING_REQUEST_RESPONSE: {
      if (!action.payload.data.overdraft) {
        return null;
      }

      return {
        ...action.payload.data.overdraft,
        errors: []
      }
    }
    case UPDATE_OVERDRAFT: {
      return {
        ...state,
        ...action.data
      }
    }
    case UPDATE_OVERDRAFT_VALIDATION_RESULT: {
      let newErrors = (state.errors || []).filter(e => e !== action.selector);
      if (!action.isValid) {
        newErrors = [...state.errors, action.selector]
      }
      return {
        ...state,
        errors: newErrors
      };
    }
    default:
      return state;
  }
}
