import React from 'react';
import PropTypes from 'prop-types';
import {Link} from '@material-ui/core';

const PricingRequestCell = ({tenantId, value}) => {
  const tenantIdQuery = tenantId ? `?tenantId=${tenantId}` : '';

  return (
    <Link
      data-testid="test-pricing-request-cell"
      href={`/simulation/${value}/${tenantIdQuery}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      {value}
    </Link>
  );
};

PricingRequestCell.propTypes = {
  tenantId: PropTypes.string,
  value: PropTypes.number
}

export default PricingRequestCell;
