import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import FxPreciousMetalsProductSubType from './FxPreciousMetalsProductSubType';

const FxPreciousMetalsProductSubTypes = ({expandedPanels, fxProductTypeCode, handlePanelExpansionToggle, handleValueChange, handleValidationResultChange, readOnly, showAgreementIndicator, fxSubTypes}) => {
  let subTypeElements = [];
  for (const subType of fxSubTypes) {
    subTypeElements.push(
      <FxPreciousMetalsProductSubType
        fxProductTypeCode={fxProductTypeCode}
        fxSubType={subType}
        handleValidationResultChange={({ selectors, ...others }) => handleValidationResultChange({
          ...others,
          selectors: [subType?.transactionAssetSubLevel?.code, ...selectors]
        })}
        handleValueChange={({ selectors, ...others }) => {
          handleValueChange({
            ...others,
            selectors: [subType?.transactionAssetSubLevel?.code, ...selectors]
          })
        }}
        isExpanded={expandedPanels.includes(subType?.transactionAssetSubLevel?.code)}
        key={`fx-precious-metals-${fxProductTypeCode}-sub-fund-${subType?.transactionAssetSubLevel?.code}`}
        onExpansionToggle={() => handlePanelExpansionToggle(subType?.transactionAssetSubLevel?.code)}
        readOnly={readOnly}
        showAgreementIndicator={showAgreementIndicator}
      />
    )
  }

  return (
    <Box width={1}>
      {subTypeElements}
    </Box>
  );


};

FxPreciousMetalsProductSubTypes.propTypes = {
  expandedPanels: PropTypes.array.isRequired,
  fxProductTypeCode: PropTypes.string.isRequired,
  fxSubTypes: PropTypes.array.isRequired,
  handlePanelExpansionToggle: PropTypes.func.isRequired,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showAgreementIndicator: PropTypes.bool
};

export default React.memo(FxPreciousMetalsProductSubTypes);
