import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';
import { CLIPP_WHITE } from '../../../../../../../../../../../theme/common/bjbColor';
import { PriceRequestProvider } from '../../../../../../../../../../PriceSimulation/PriceSimulationStore';
import  PriceSimulationSummary from '../../../../../../../../../../PriceSimulationSummary';

const DialogTitle = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: CLIPP_WHITE,
    color: CLIPP_WHITE,
    display: 'flex',
    alignItems: 'center'
  }
}))(MuiDialogTitle);

const RequestDetailsDialog = ({open, onClose, pricingRequestId, openApproveDialog, openReturnDialog, tenantId}) => {
  return (
    <Dialog disableBackdropClick fullScreen onClose={onClose} open={open}>
      <DialogTitle disableTypography /> 
      <DialogContent>
        <PriceRequestProvider>
          <PriceSimulationSummary
            closeRequestDetailsDialog={onClose}
            openApproveDialog={openApproveDialog} 
            openReturnDialog={openReturnDialog}
            rowPricingRequestId={pricingRequestId}
            tenantId={tenantId}
          />
        </PriceRequestProvider>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

RequestDetailsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  pricingRequestId: PropTypes.number,
}

export default RequestDetailsDialog;
