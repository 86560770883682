import {Button} from '@material-ui/core';
import React, {useContext, useState} from 'react';
import {useNotification} from '../../Notification';
import {getErrorMessage} from '../../../../../common/getErrorMessage';
import ResetAcknowledgeDialog from './components/ResetAcknowledgeDialog';
import ResetConfirmationDialog from './components/ResetConfirmationDialog';
import {resetToStandard} from '../../../../PriceSimulation/pricingRequestService';
import {
  SELECT_PORTFOLIO_FOR_SUBMISSION,
  SELECT_PORTFOLIO_NUMBERS_FOR_SUBMISSION,
  SELECT_PORTFOLIOS
} from '../../../../../common/actionTypes';
import {Store} from '../../../../../Store';
import {PriceRequestStore} from '../../../../PriceSimulation/PriceSimulationStore';
import {useHistory} from 'react-router-dom';

const ResetRequestButton = ({...others}) => {
  const {parentState, parentDispatch} = useContext(Store);
  const {state, dispatch} = useContext(PriceRequestStore);
  const notification = useNotification();
  const history = useHistory();
  const [acknowledgeDialogOpen, setAcknowledgeDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [ongoing, setOngoing] = useState(false);

  const handleAcknowledgePricingRequest = () => {
    setAcknowledgeDialogOpen(false);
    setConfirmationDialogOpen(true);
  }

  const handleResetPricingRequest = async () => {
    setConfirmationDialogOpen(false);
    const oldPricingRequestId = state.selectedPricingRequest.common.pricingRequestId;
    try {
      const newPortfolio = await resetToStandard(oldPricingRequestId);
      const oldPortfolioIndex = parentState.portfolios.findIndex(
        portfolio => portfolio.pricingRequestId === oldPricingRequestId
      );
      const newlySelectedPortfolios = [...parentState.portfolios];
      newlySelectedPortfolios[oldPortfolioIndex] = newPortfolio;

      parentDispatch({type: SELECT_PORTFOLIOS, payload: newlySelectedPortfolios});
      dispatch({type: SELECT_PORTFOLIO_FOR_SUBMISSION, payload: newlySelectedPortfolios[oldPortfolioIndex]});
      dispatch({type: SELECT_PORTFOLIO_NUMBERS_FOR_SUBMISSION, payload: []});
      history.push(`/simulation/${newPortfolio.pricingRequestId}/summary`);

      Promise.resolve();
      setOngoing(true);
    } catch (err) {
      notification.error('Could not reset to standard\n' + getErrorMessage(err));
      Promise.reject();
      setOngoing(false);
    }
  };

  return (
    <>
      <ResetAcknowledgeDialog
        onClose={() => setAcknowledgeDialogOpen(false)}
        onContinue={handleAcknowledgePricingRequest}
        open={acknowledgeDialogOpen}
      />
      <ResetConfirmationDialog
        onClose={() => setConfirmationDialogOpen(false)}
        onContinueReset={handleResetPricingRequest}
        open={confirmationDialogOpen}
      />
      <Button color="primary" disabled={ongoing} onClick={() => setAcknowledgeDialogOpen(true)} {...others}>
        Reset to Standard
      </Button>
    </>
  );
};

export default ResetRequestButton;