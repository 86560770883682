import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, TextareaAutosize, Typography} from '@material-ui/core';
import {DATE_FORMAT} from '../../../../../common/dateFormat';
import Moment from 'react-moment';
import {getCombinedApproverRoleString} from '../../../../../common/enums/userRole';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  reasonTextArea: {
    fontFamily: '\'Verlag SSm 3r\'',
    width: '100%'
  },
  reviewDate: {
    marginTop: theme.spacing(1)
  }
}));

const ApprovalReason = ({approvalData}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {approvalData?.map((data, index) => {
        const approverRole = getCombinedApproverRoleString(data.deciderDetail?.userRoles),
          approverName = `${data.deciderDetail?.firstName} ${data.deciderDetail?.lastName}`,
          returnReason = data.decisionUserInfoDto?.returnReason,
          reviewDate = data.decisionUserInfoDto?.reviewDate,
          contingentTerm = data.decisionUserInfoDto?.contingencyTerms,
          decisionDate = data.decisionUserInfoDto?.decisionDate;

        const approvalReasons = data.decisionUserInfoDto?.approvalReasons;

        return (
          <Grid item key={index} xs={6}>
            <Box marginBottom={2}>
              {approverName && (
                <Typography variant="h6">
                  Approver {index + 1} ({approverRole}): {approverName}
                </Typography>
              )}
              {decisionDate && (
                <Typography variant="h6">
                  Decision date: <Moment date={decisionDate} format={DATE_FORMAT}/>
                </Typography>
              )}
              {approvalReasons?.length > 0 && (
                <Typography variant="h6">
                  Approval Reason:
                  <ul style={{margin: 0}}>
                    {approvalReasons.map((decision, index) => (
                      <li key={index}>
                        <div>{decision.otherReason ? 'Others: ' : decision.approvalReasonDesc}</div>
                        {decision.otherReason && (
                          <TextareaAutosize
                            className={classes.reasonTextArea}
                            disabled
                            rowsMax={12}
                            value={decision.otherReason}
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </Typography>
              )}
              {returnReason && (
                <React.Fragment>
                  <Typography variant="h6">Return reason: </Typography>
                  <TextareaAutosize
                    className={classes.reasonTextArea}
                    disabled
                    rowsMax={12}
                    value={returnReason}
                  />
                </React.Fragment>
              )}
              {reviewDate && (
                <Typography className={classes.reviewDate} variant="h6">
                  Review date: <Moment date={reviewDate} format={DATE_FORMAT}/>
                </Typography>
              )}

              {contingentTerm && <Typography variant="h6">Contingent term: {contingentTerm} </Typography>}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

ApprovalReason.propTypes = {
  approvalData: PropTypes.array
};

export default ApprovalReason;
