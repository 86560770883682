import React, {useContext, useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {
  ERROR_BACKGROUND_PRIMARY_COLOR,
  EXPANSION_PANEL_SECONDARY_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_COLOR,
  EXPANSION_PANEL_SECONDARY_FOCUS_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_FOCUS_COLOR,
  EXPANSION_PANEL_SECONDARY_HOVER_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_HOVER_COLOR
} from '../../../../../theme/common/color';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {PriceRequestStore} from '../../../PriceSimulationStore';
import FxPreciousMetalsPanel from './FxPreciousMetalsPanel';
import EffectiveCondition from '../../Common/EffectiveCondition';

const useStyles = makeStyles(() => ({

  displayFlexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  headerEffectivePrice: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '200px'
  },
  innerExpansionPanel: {
    width: '100%'
  },
  innerExpansionPanelSummary: {
    backgroundColor: EXPANSION_PANEL_SECONDARY_BACKGROUND_COLOR,
    color: EXPANSION_PANEL_SECONDARY_COLOR,
    fontSize: '0.8125rem',
    height: '36px',
    minHeight: '36px!important',
    '&:focus': {
      backgroundColor: EXPANSION_PANEL_SECONDARY_FOCUS_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_SECONDARY_FOCUS_COLOR
    },
    '&:hover': {
      backgroundColor: EXPANSION_PANEL_SECONDARY_HOVER_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_SECONDARY_HOVER_COLOR
    }
  },
  innerExpansionPanelSummaryTitle: {
    fontFamily: '\'Verlag SSm 7r\''
  },
  error: {
    backgroundColor: ERROR_BACKGROUND_PRIMARY_COLOR
  }
}));

const FxPreciousMetalsProductSubType = ({fxProductTypeCode, handleValueChange, handleValidationResultChange, isExpanded, onExpansionToggle, readOnly, showAgreementIndicator, fxSubType}) => {

  const classes = useStyles();
  const {state} = useContext(PriceRequestStore);

  // eslint-disable-next-line react/no-multi-comp
  const getFxPreciousMetalPanelDetails = () => {
    const {agentType, serviceModel, pricingRequestStatus} = state.selectedPricingRequest?.common;
    return (
      <FxPreciousMetalsPanel
        agentType={agentType}
        fxProductTypeCode={fxProductTypeCode}
        fxSubType={fxSubType}
        handleValidationResultChange={handleValidationResultChange}
        handleValueChange={handleValueChange}
        key={`fx-fee-panel-${fxProductTypeCode}-${fxSubType.transactionAssetSubLevel.code}`}
        pricingRequestStatus={pricingRequestStatus}
        readOnly={readOnly}
        serviceModel={serviceModel?.code}
        showAgreementIndicator={showAgreementIndicator}
      />
    );
  }

  const cachedFxPreciousMetalPanelDetails = useMemo(getFxPreciousMetalPanelDetails, [isExpanded ? Math.random() : false]);

  const hasError = (subFundTypeCode) => {
    const errorStrings = state.selectedPricingRequest.nmbFXProducts?.errors;
    if (errorStrings?.length > 0) {
      const subFundErrors = errorStrings.map(errorString => errorString.split(',').map(x => x.trim())[1]);
      if (subFundErrors.includes(subFundTypeCode)) {
        return classes.error;
      }
    }
    return '';
  }

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.innerExpansionPanel} expanded={isExpanded} key={`expansion-${fxProductTypeCode}-${fxSubType?.transactionAssetSubLevel?.code}`} onChange={onExpansionToggle}>
      <AccordionSummary
        className={`${classes.innerExpansionPanelSummary} ${hasError(fxSubType?.transactionAssetSubLevel?.code)}`}
        data-testid={`fx-sub-type-${fxSubType?.transactionAssetSubLevel?.code}-test-id`}
        expandIcon={<ExpandMoreIcon/>}
      >
        <Box
          className={classes.displayFlexSpaceBetween}
          data-testid={`expansion-${fxProductTypeCode}-${fxSubType?.transactionAssetSubLevel?.code}-header`}
        >
          <Box className={classes.innerExpansionPanelSummaryTitle}>{fxSubType?.transactionAssetSubLevel?.desc}</Box>
          <EffectiveCondition subFund={fxSubType} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {cachedFxPreciousMetalPanelDetails}
      </AccordionDetails>
    </Accordion>
  );

};

FxPreciousMetalsProductSubType.propTypes = {
  fxProductTypeCode: PropTypes.string.isRequired,
  fxSubType: PropTypes.object.isRequired,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onExpansionToggle: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showAgreementIndicator: PropTypes.bool
};

export default React.memo(FxPreciousMetalsProductSubType);
