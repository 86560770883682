import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Grid} from '@material-ui/core';
import {Footer, TopBar} from '../Common/components';
import BackgroundImage from '../../assets/images/bg.jpg';


const useStyles = makeStyles(theme => ({
  bjbContent: {
    boxShadow: 'inset 0px 15px 15px -10px rgba(0,0,0,0.2), inset 0px -15px 15px -10px rgba(0,0,0,0.2)',
    backgroundColor: '#F3F3EC',
    [theme.breakpoints.down('sm')]: {
      minHeight: '500px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '600px',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '700px',
    },
    '& .MuiGrid-container': {},
    flexGrow: 1,
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
}));

const Landing = props => {
  const { children } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <TopBar logo/>
      <div className={classes.bjbContent}>
        <Grid
          alignItems="stretch"
          container
          direction="column"
          justify="center"
        >
          <Grid
            item
            xs={12}
          >
            {children}
          </Grid>
        </Grid>
      </div>
      <Footer/>
    </React.Fragment>
  );
};

Landing.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Landing;
