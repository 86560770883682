export default {
  root: {
    backgroundColor: '#99A1D0',
    '& .MuiDivider-light':{
      backgroundColor: '#99A1D0'
    }
  },
  light: {
    backgroundColor: '#99A1D0'
  }
};
  
