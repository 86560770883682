import React, {useContext, useEffect, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Skeleton from '@material-ui/lab/Skeleton';
import {Box, Paper} from '@material-ui/core';
import BoxHeader from '../../../../../Common/components/BoxHeader';
import {Store} from '../../../../../../Store';
import {pmDashboardColumnData} from '../columnData';
import api from '../../../../../../api/api';
import StatusFilter from '../StatusFilter';
import StatusTable from '../StatusTable';
import {useDebounce} from '../../../../../../common/useDebounce';
import {getErrorMessage} from '../../../../../../common/getErrorMessage';
import {sortBy} from 'underscore';
import {SELECT_CUSTOMERS} from '../../../../../../common/actionTypes';
import {useNotification} from '../../../../../Common/components/Notification';

const SpecialRequestTab = () => {
  const [priceRequests, setPriceRequests] = useState();
  const [paginationInfo, setPaginationInfo] = useState({page: 0});
  const [totalPages, setTotalPages] = useState();
  const {parentState, parentDispatch} = useContext(Store);
  const [isLoading, setLoading] = useState(true);
  const notification = useNotification();

  const fetchData = async params => {
    setLoading(true);
    const response = await api.pricingRequest.listPendingPm(params);
    setPriceRequests(response.data.content);
    setPaginationInfo({page: params?.page || 0});
    setTotalPages(response.data.totalPages);
    setLoading(false);
  };
  const getCustomers = async () => {
    try {
      if (!parentState.customers) {
        let payload = await api.customer.get();

        if (payload?.data) {
          let sortedAccounts = payload.data;
          payload.data.forEach(
            account => (account.portfolios = sortBy(account.portfolios, 'portfolioNumber'))
          );
          parentDispatch({type: SELECT_CUSTOMERS, payload: sortBy(payload.data, sortedAccounts)});
        }
      }
    } catch (err) {
      notification.error('Failed getting portfolios\n' + getErrorMessage(err));
    }
  }
  useEffect(() => {
    if (parentState.user?.id) {
      getCustomers();
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentState.user?.id]);

  const [columnsData, setColumnsData] = useState(pmDashboardColumnData);
  const [groupBy, setGroupBy] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterCriteria, setFilterCriteria] = useState('');
  const debouncedFilterCriteria = useDebounce(filterCriteria, 500);

  const handleSelectGroup = e => setGroupBy(e.target.value);
  const handleSelectFilter = e => setFilterBy(e.target.value);
  const handleFilterCriteriaInput = e => setFilterCriteria(e.target.value);
  const handleSelectedColumns = value => {
    const newColumnsData = cloneDeep(columnsData);
    newColumnsData.forEach(column => {
      if (value[column.key] !== undefined) {
        column.hidden = !value[column.key];
      }
    });
    setColumnsData(newColumnsData);
  };

  const goToPage = page => {
    fetchData({page});
  };

  return (
    <Paper
      elevation={1}
      style={{
        padding: '10px'
      }}
      variant="elevation"
    >
      <BoxHeader text={'Status'}/>
      {!isLoading ? (
        priceRequests ? (
          <>
            <StatusFilter
              columnsData={columnsData}
              filterCriteria={filterCriteria}
              handleFilterCriteriaInput={handleFilterCriteriaInput}
              handleSelectFilter={handleSelectFilter}
              handleSelectGroup={handleSelectGroup}
              handleSelectedColumns={handleSelectedColumns}
              selectedFilter={filterBy}
              selectedGroup={groupBy}
            />
            <StatusTable
              columnsData={columnsData}
              filterByKey={filterBy}
              filterCriteria={debouncedFilterCriteria}
              goToPage={goToPage}
              groupByKey={groupBy}
              paginationInfo={{...paginationInfo, totalPages}}
              priceRequests={priceRequests}
              setPriceRequests={setPriceRequests}
            />
          </>
        ) : (
          <Box>No entry found.</Box>
        )
      ) : (
        <Skeleton animation="wave"/>
      )}
    </Paper>
  );
};

export default SpecialRequestTab;
