const jbColors = {
  reflexBlue: {
    100: 'rgb(0,20,137)',
    90: 'rgb(26,44,149)',
    80: 'rgb(51,67,161)',
    70: 'rgb(77,91,173)',
    60: 'rgb(102,114,184)',
    50: 'rgb(128,138,196)',
    40: 'rgb(153,161,208)',
    30: 'rgb(179,185,220)',
    20: 'rgb(204,208,231)',
    10: 'rgb(230,232,243)',
  },
  royalBlue: {
    100: 'rgb(20,30,85)',
    90: 'rgb(44,53,102)',
    80: 'rgb(67,75,119)',
    70: 'rgb(91,97,136)',
    60: 'rgb(114,120,153)',
    50: 'rgb(137,143,170)',
    40: 'rgb(161,165,187)',
    30: 'rgb(185,187,204)',
    20: 'rgb(208,210,221)',
    10: 'rgb(232,233,238)',
  },
  persianBlue: {
    100: 'rgb(0,127,163)',
    90: 'rgb(25,140,172)',
    80: 'rgb(51,153,181)',
    70: 'rgb(76,165,191)',
    60: 'rgb(102,178,200)',
    50: 'rgb(128,191,209)',
    40: 'rgb(153,204,218)',
    30: 'rgb(178,217,227)',
    20: 'rgb(204,229,237)',
    10: 'rgb(229,242,246)',
  },
  stone: {
    100: 'rgb(176,170,126)',
    90: 'rgb(184,178,139)',
    80: 'rgb(192,187,152)',
    70: 'rgb(200,195,165)',
    60: 'rgb(208,204,178)',
    50: 'rgb(215,212,190)',
    40: 'rgb(223,221,203)',
    30: 'rgb(231,229,216)',
    20: 'rgb(239,238,229)',
    10: 'rgb(247,246,242)',
  },
  greenSmoke: {
    100: 'rgb(113,124,125)',
    90: 'rgb(127,137,138)',
    80: 'rgb(141,150,151)',
    70: 'rgb(156,163,164)',
    60: 'rgb(170,176,177)',
    50: 'rgb(184,189,190)',
    40: 'rgb(198,203,203)',
    30: 'rgb(212,216,216)',
    20: 'rgb(227,229,229)',
    10: 'rgb(241,242,242)',
  },
  violetGray: {
    100: 'rgb(123,100,105)',
    90: 'rgb(136,115,120)',
    80: 'rgb(149,131,135)',
    70: 'rgb(163,146,150)',
    60: 'rgb(176,162,165)',
    50: 'rgb(189,177,180)',
    40: 'rgb(202,193,195)',
    30: 'rgb(215,208,210)',
    20: 'rgb(229,224,225)',
    10: 'rgb(242,239,240)',
  },
  mulberryPurple: {
    100: 'rgb(110,43,98)',
    90: 'rgb(124,64,114)',
    80: 'rgb(139,85,129)',
    70: 'rgb(153,107,145)',
    60: 'rgb(168,128,161)',
    50: 'rgb(182,149,176)',
    40: 'rgb(197,170,192)',
    30: 'rgb(211,191,208)',
    20: 'rgb(226,213,224)',
    10: 'rgb(240,234,239)',
  },
  forestGreen: {
    100: 'rgb(0,134,117)',
    90: 'rgb(25,146,131)',
    80: 'rgb(51,158,145)',
    70: 'rgb(76,170,158)',
    60: 'rgb(102,182,172)',
    50: 'rgb(128,194,186)',
    40: 'rgb(153,207,200)',
    30: 'rgb(178,219,214)',
    20: 'rgb(204,231,227)',
    10: 'rgb(229,243,241)',
  },
  carmineRed: {
    100: 'rgb(151,27,47)',
    90: 'rgb(161,50,68)',
    80: 'rgb(172,73,89)',
    70: 'rgb(182,95,109)',
    60: 'rgb(193,118,130)',
    50: 'rgb(203,141,151)',
    40: 'rgb(213,164,172)',
    30: 'rgb(224,187,193)',
    20: 'rgb(234,209,213)',
    10: 'rgb(245,232,234)',
  },
  amberYellow: {
    100: 'rgb(204,138,0)',
    90: 'rgb(209,150,25)',
    80: 'rgb(214,161,51)',
    70: 'rgb(219,173,76)',
    60: 'rgb(224,185,102)',
    50: 'rgb(229,186,128)',
    40: 'rgb(235,208,153)',
    30: 'rgb(240,220,178)',
    20: 'rgb(245,232,204)',
    10: 'rgb(250,243,229)',
  },
  black: {
    100: 'rgb(0,0,0)',
    90: 'rgb(25,25,25)',
    80: 'rgb(51,51,51)',
    70: 'rgb(76,76,76)',
    60: 'rgb(102,102,102)',
    50: 'rgb(128,128,128)',
    40: 'rgb(153,153,153)',
    30: 'rgb(178,178,178)',
    20: 'rgb(204,204,204)',
    10: 'rgb(229,229,229)',
  },
  white: 'rgb(255,255,255)',
  shadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
};

export { jbColors };
