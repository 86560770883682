import React from 'react';
import PropTypes from 'prop-types';
import {
  LABEL_SUMMARY,
  NEW_CONDITIONS_SUMMARY,
  OLD_CONDITIONS_SUMMARY,
  TEXT_LEFT
} from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import PriceConditionTable from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import {OLD_CONDITION_CUSTOM_STYLE, SUMMARY_TABLE_CUSTOM_STYLE} from '../../../../common/constants';
import CommonBILARows from '../CommonBILARows';
import {getPriceTypeFromNMBFeeCondition} from '../../../../common/enums/nmbFeeCondition';
import {isNullOrUndefined} from '../../../../common/isNullOrUndefined';
import {getUnequalFieldsInTransactionFees} from './transactionFeesEqualityUtil';
import {useTransactionFeesStyles} from './useTransactionFeesStyles';
import PriceConditionTableRowSection from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {createSectionTitle, createSubLevelTitle} from '../priceSummaryCommonUtil';
import TransactionFeeTextRow from './components/TransactionFeeTextRow';
import TransactionFeeRow from './components/TransactionFeeRow';
import {getApplicableStandardDescriptionById} from '../../../../common/enums/applicableStandards';
import {getDiscountLevelDescriptionById} from '../../../../common/enums/discountLevels';
import {
  getFXProductTypeDescriptionFromCode,
  getFXProductTypeFromDescription
} from '../../../../common/enums/fxProductTypes';
import {isEAMorEFA} from '../../../../common/enums/serviceModel';

const TransactionFeesFXProductsTable = ({ transactionFees, serviceModel, agentType, showDiff }) => {
  const classes = useTransactionFeesStyles();
  const transactionAssetTypes = transactionFees?.transactionAssetTypes;

  const transactionAssetTypesComponents = [];
  // eslint-disable-next-line
  Object.entries(transactionAssetTypes).map(([key, value]) => {
    const subAssetTypesComponents = [];
    for (let props of value) {
      if(showDiff){
        // eslint-disable-next-line react/prop-types
        const unequalFields = getUnequalFieldsInTransactionFees(props.currentCondition, props.newCondition, transactionFees?.oldPricingExists);
        if(unequalFields.length === 0){
          continue;
        }
      }
      subAssetTypesComponents.push({
        // eslint-disable-next-line react/prop-types
        assetSubLevel: props.transactionAssetSubLevel.desc,
        // eslint-disable-next-line react/prop-types
        newCondition: props.newCondition,
        // eslint-disable-next-line react/prop-types
        currentCondition: props.currentCondition
      });
    }
    if (subAssetTypesComponents.length > 0) {
      transactionAssetTypesComponents.push({
        assetType: getFXProductTypeDescriptionFromCode(key),
        assetSublevels: [...subAssetTypesComponents]
      });
    }
  });

  if (transactionAssetTypesComponents.length > 0) {
    transactionAssetTypesComponents.sort((firstEl, secondEl) => {
      const order1 = getFXProductTypeFromDescription(firstEl?.assetType).sortOrder;
      const order2 = getFXProductTypeFromDescription(secondEl?.assetType).sortOrder;
      if (order1 < order2) {
        return -1;
      } else if (order1 > order2) {
        return 1;
      }
      return 0;
    });
  }
  return (<>
    <PriceConditionTable>
      <PriceConditionTableRowSection data={[]}>
        <PriceConditionTableColumn field="label" label="Transaction Fees FX & Precious Metals" type={LABEL_SUMMARY}/>
        {transactionFees?.oldPricingExists && (
          <PriceConditionTableColumn
            customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
            label="Old Conditions"
            type={OLD_CONDITIONS_SUMMARY}
          />
        )}
        <PriceConditionTableColumn
          customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
          isHighlightedFieldValueAccessor="newCondition.isHighlighted"
          label="New Conditions"
          type={NEW_CONDITIONS_SUMMARY}
        />
      </PriceConditionTableRowSection>
      {transactionAssetTypesComponents.map(parent => (<>
        <PriceConditionTableRowSection data={[]}>
          <PriceConditionTableColumn
            customStyles={OLD_CONDITION_CUSTOM_STYLE}
            field="label"
            label={createSectionTitle(parent.assetType, classes.sectionTitle)}
            type={TEXT_LEFT}
          />
          {transactionFees?.oldPricingExists && (
            <PriceConditionTableColumn
              customStyles={OLD_CONDITION_CUSTOM_STYLE}
              type={TEXT_LEFT}
            />
          )}
          <PriceConditionTableColumn
            customStyles={OLD_CONDITION_CUSTOM_STYLE}
            type={TEXT_LEFT}
          />
        </PriceConditionTableRowSection>
        {parent.assetSublevels?.map(child => (<>
          <PriceConditionTableRowSection data={[]}>
            <PriceConditionTableColumn
              customStyles={OLD_CONDITION_CUSTOM_STYLE}
              data-testid={`test-${child.assetSubLevel}-label`}
              field="label"
              label={createSubLevelTitle(child.assetSubLevel)}
              type={TEXT_LEFT}
            />
            {transactionFees?.oldPricingExists && (
              <PriceConditionTableColumn
                customStyles={OLD_CONDITION_CUSTOM_STYLE}
                type={TEXT_LEFT}
              />
            )}
            <PriceConditionTableColumn
              customStyles={OLD_CONDITION_CUSTOM_STYLE}
              type={TEXT_LEFT}
            />
          </PriceConditionTableRowSection>
          <CommonBILARows
            hideBILA={isEAMorEFA(serviceModel, agentType)}
            newAcknowledge={child?.newCondition ? child.newCondition?.acknowledged : null}
            newAdditionalInstructions={child?.newCondition ? child.newCondition?.additionalInstructions : null}
            newAgreementType={child?.newCondition?.agreementEnum ? child?.newCondition?.agreementEnum : null}
            oldAcknowledge={child?.currentCondition ? child.currentCondition?.acknowledged : null}
            oldAdditionalInstructions={child?.currentCondition ? child?.currentCondition?.additionalInstructions : null}
            oldAgreementType={child?.currentCondition?.agreementEnum ? child?.currentCondition?.agreementEnum : null}
            secondLevel
            showDiff={showDiff}
            showOld={!!transactionFees?.oldPricingExists}
            testId={'test-fx-products-fees-summary'}
          />
          {(child?.newCondition?.fxProductDiscountCondition || child?.currentCondition?.fxProductDiscountCondition) && child &&
            <TransactionFeeTextRow
              label={'Discount Type'}
              newValue={child?.newCondition?.fxProductDiscountCondition?.desc}
              oldValue={child?.currentCondition?.fxProductDiscountCondition?.desc}
              secondLevel
              showDiff={showDiff}
              showOld={transactionFees.oldPricingExists}
              testId={`test-${child.assetSubLevel}-condition`}
            />
          }
          {(!isNullOrUndefined(child?.newCondition?.applicableStandardId) || !isNullOrUndefined(child?.currentCondition?.applicableStandardId)) && child &&
            <TransactionFeeTextRow
              label={'Applicable Standard'}
              newValue={child?.newCondition?.applicableStandardId ?
                getApplicableStandardDescriptionById(child?.newCondition?.fxMarginApplicableStandardDtos, child?.newCondition?.applicableStandardId):
                null}
              oldValue={child?.currentCondition?.applicableStandardId ?
                getApplicableStandardDescriptionById(child?.newCondition?.fxMarginApplicableStandardDtos, child?.currentCondition?.applicableStandardId):
                null}
              secondLevel
              showDiff={showDiff}
              showOld={transactionFees.oldPricingExists}
              testId={`test-${child.assetSubLevel}-applicable-standard`}
            />
          }
          {(!isNullOrUndefined(child?.newCondition?.discountLevelId) || !isNullOrUndefined(child?.currentCondition?.discountLevelId)) && child &&
            <TransactionFeeTextRow
              label={'Discount Level'}
              newValue={child?.newCondition?.discountLevelId ?
                getDiscountLevelDescriptionById(child?.newCondition?.fxMarginDiscountLevelDtos, child?.newCondition?.discountLevelId):
                null}
              oldValue={child?.currentCondition?.discountLevelId ?
                getDiscountLevelDescriptionById(child?.currentCondition?.fxMarginDiscountLevelDtos, child?.currentCondition?.discountLevelId):
                null}
              secondLevel
              showDiff={showDiff}
              showOld={transactionFees.oldPricingExists}
              testId={`test-${child.assetSubLevel}-discount-level`}
            />
          }
          {(child?.newCondition?.transactionFeeCondition || child?.currentCondition?.transactionFeeCondition) && child &&
              <TransactionFeeTextRow
                label={'Fee Condition'}
                newValue={child?.newCondition?.transactionFeeCondition?.desc}
                oldValue={child?.currentCondition?.transactionFeeCondition?.desc}
                secondLevel
                showDiff={showDiff}
                showOld={transactionFees.oldPricingExists}
                testId={`test-${child.assetSubLevel}-condition`}
              />
          }
          {(child?.newCondition?.feePerTransaction || child?.currentCondition?.feePerTransaction) &&
              <TransactionFeeRow
                label={'Fee per transaction'}
                newValue={child?.newCondition?.feePerTransaction?.effectivePrice}
                oldValue={child?.currentCondition?.feePerTransaction?.effectivePrice}
                secondLevel
                showDiff={showDiff}
                showOld={transactionFees.oldPricingExists}
                testId={`test-${child.assetSubLevel}-feePerTransaction`}
                type={getPriceTypeFromNMBFeeCondition(child?.newCondition?.transactionFeeCondition?.code)}
              />}
        </>
        ))}</>
      ))}
    </PriceConditionTable>
  </>);
};

TransactionFeesFXProductsTable.propTypes = {
  agentType: PropTypes.string,
  serviceModel: PropTypes.string,
  showDiff: PropTypes.bool,
  transactionFees: PropTypes.object
};

export default TransactionFeesFXProductsTable;
