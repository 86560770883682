import {makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import Box from '@material-ui/core/Box';
import {
  isNonStandardCondition,
  TICKET_FEE
} from '../../../../../common/enums/transactionFeeCondition';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {BPS, CHF, PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {FLAT_RATE_PCT} from '../../../../../common/enums/nmbFeeCondition';
import {getChildTypesByParentType} from '../../../../../common/enums/nmbFeeSubTypes';
import {PriceRequestStore} from '../../../PriceSimulationStore';
import {FXP_FX_CASH, FXP_SINGLE_OPTIONS} from '../../../../../common/enums/fxPreciousMetalsSubTypes';
import {BILA} from '../../../../../common/enums/agreementType';
import {isEqual} from 'underscore';
import {isEAMorEFA} from '../../../../../common/enums/serviceModel';

const useStyles = makeStyles(() => ({
  headerEffectivePrice: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '260px'
  },
  agreementLabel : {
    fontWeight: 'bold'
  }
}));

const EffectiveCondition = ({subFund}) => {
  const classes = useStyles();
  const {state} = useContext(PriceRequestStore);
  const newConditionCode = subFund?.newCondition?.transactionFeeCondition?.code;
  const nmbAssetTypes = (state?.selectedPricingRequest?.nmbTransactionFees?.transactionAssetTypes || []);
  const subLevelCode = subFund.assetSubLevel?.code || subFund?.transactionAssetSubLevel?.code;
  const {serviceModel, agentType} = state?.selectedPricingRequest?.common || {};

  const getEffectivePrice = (subFund, conditionCode) => {
    if ([FXP_FX_CASH.code].includes(subFund?.transactionAssetSubLevel?.code)) {
      return subFund?.newCondition?.fxMarginDiscountLevelDtos?.filter(sf => sf?.discountLevelId === subFund?.newCondition?.discountLevelId).map(sf => {
        return sf?.discountLevel;
      });
    }
    if (subFund?.nmbParentAssetType) {
      const childTypes = getChildTypesByParentType(subFund?.nmbParentAssetType?.code);
      let prices = [];
      Object.values(nmbAssetTypes).flat()
        .filter(nmbSubFund => childTypes?.includes(nmbSubFund?.transactionAssetSubLevel?.code))
        .forEach(nmbSubFund => {
          prices.push(nmbSubFund?.newCondition?.feePerTransaction?.effectivePrice);
        });
      const delimiter = getTypeByConditionCode(conditionCode);
      return prices.join(', ') + delimiter;
    }
    if (conditionCode === TICKET_FEE.code) {
      return (<PriceNumberFormat type={CHF} value={subFund?.newCondition?.ticketFee}/>);
    } else if (isNonStandardCondition(conditionCode)) {
      return null;
    } else if (conditionCode === FLAT_RATE_PCT.code) {
      return (<PriceNumberFormat type={PERCENTAGE} value={subFund?.newCondition?.feePerTransaction?.effectivePrice}/>);
    } else {
      return (<PriceNumberFormat type={BPS} value={subFund?.newCondition?.feePerTransaction?.effectivePrice}/>);
    }
  }

  const getTypeByConditionCode = (conditionCode) => {
    switch (conditionCode) {
      case TICKET_FEE.code:
        return CHF;
      case FLAT_RATE_PCT:
        return PERCENTAGE
      default:
        return BPS;
    }
  }

  const isPriceDifferent = (subFund) => {
    let isDifferent = ['feePerTransaction', 'minFeePerTransaction','liquidationFee','amendmentFee'].some(key => {
      if(subFund?.newCondition[key] && !isEqual(subFund?.newCondition[key]['listPrice'], subFund?.newCondition[key]['effectivePrice']))
        return true;
    });
    if(!isDifferent){
      if (subFund?.nmbParentAssetType) {
        const childTypes = getChildTypesByParentType(subFund?.nmbParentAssetType?.code);
        isDifferent = Object.values(nmbAssetTypes).flat()
          .filter(nmbSubFund => childTypes?.includes(nmbSubFund?.transactionAssetSubLevel?.code))
          .some(nmbSubFund => {
            if(!isEqual(nmbSubFund?.newCondition['feePerTransaction']['listPrice'], nmbSubFund?.newCondition['feePerTransaction']['effectivePrice'])){
              return true;
            }
          });
      }
    }
    if(!isDifferent){
      if ([FXP_FX_CASH.code, FXP_SINGLE_OPTIONS.code].includes(subFund?.transactionAssetSubLevel?.code)) {
        isDifferent = subFund?.newCondition?.fxMarginDiscountLevelDtos[0]?.discountLevelId !== subFund?.newCondition?.discountLevelId
                  || subFund?.newCondition?.fxMarginApplicableStandardDtos[0]?.applicableStandardId !== subFund?.newCondition?.applicableStandardId;
      }
    }
    return isDifferent;
  }

  const checkAcknowledged = (subFund) => {
    let isAcknowledged = subFund?.newCondition?.acknowledged;
    if(!isAcknowledged){
      if (subFund?.nmbParentAssetType) {
        const childTypes = getChildTypesByParentType(subFund?.nmbParentAssetType?.code);
        isAcknowledged = Object.values(nmbAssetTypes).flat()
          .filter(nmbSubFund => childTypes?.includes(nmbSubFund?.transactionAssetSubLevel?.code))
          .some(nmbSubFund => {
            if(nmbSubFund?.newCondition?.acknowledged){
              return true;
            }
          });
      }
    }
    return isAcknowledged;
  }

  const getFlagByAgreementType = (agreementCode) => {
    if(agreementCode === BILA.code){
      return 'BILA';
    }else if(isPriceDifferent(subFund) || isNonStandardCondition(newConditionCode) || checkAcknowledged(subFund)){
      return isEAMorEFA(serviceModel?.code, agentType) ? 'Agreed Price' : 'Unila';
    }else{
      return 'Standard';
    }
  }

  return (
    <>
      <Box className={classes.headerEffectivePrice}>
        <Box
          className={classes.agreementLabel}
          data-testid={`test-discounted-label-${subLevelCode}`}
        >
          {getFlagByAgreementType(subFund?.newCondition?.agreementEnum?.code)}
        </Box>
        <Box
          data-testid={`test-discounted-price-${subLevelCode}`}
        >
          {getEffectivePrice(subFund, newConditionCode)}
        </Box>
      </Box>
    </>);
};

EffectiveCondition.propTypes = {
  subFund: PropTypes.object
};

export default React.memo(EffectiveCondition);