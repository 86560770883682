import React from 'react';
import PropTypes from 'prop-types';
import {paymentFeeCondition} from '../../PriceSimulation/components/PaymentFees/objectmapper/paymentFeesConditionObjectMapper';
import {
  LABEL_SUMMARY,
  NEW_CONDITIONS_SUMMARY,
  OLD_CONDITIONS_SUMMARY
} from '../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import PriceConditionTable from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import PriceConditionTableRowSection from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {SUMMARY_TABLE_CUSTOM_STYLE} from '../../../common/constants';
import CommonBILARows from './CommonBILARows';
import {isNullOrUndefined} from '../../../common/isNullOrUndefined';
import {isEAMorEFA} from '../../../common/enums/serviceModel';

const PaymentFeesTable = ({allPaymentFees, serviceModel, agentType, showDiff}) => {

  const paymentFees = {...paymentFeeCondition({
    currentCondition: allPaymentFees?.currentPaymentFeeCondition,
    newCondition: allPaymentFees?.newPaymentFeeCondition,
    readOnly: true,
    showDiff
  })
  };

  const mappedCondition = [
    {
      ...paymentFees,
      discount: null
    }
  ];

  return (
    <>
      {allPaymentFees &&  <PriceConditionTable>
        <PriceConditionTableRowSection data={[]}>
          <PriceConditionTableColumn field="label" label="Payment Fees" type={LABEL_SUMMARY}/>
          {allPaymentFees?.currentConditionCodeFees && (
            <PriceConditionTableColumn
              customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
              label="Old Condition"
              type={OLD_CONDITIONS_SUMMARY}
            />
          )}
          <PriceConditionTableColumn
            customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
            isHighlightedFieldValueAccessor="newCondition.isHighlighted"
            label="New Condition"
            type={NEW_CONDITIONS_SUMMARY}
          />
        </PriceConditionTableRowSection>
        <CommonBILARows
          hideAcknowledgement
          hideBILA={isEAMorEFA(serviceModel, agentType)}
          newAgreementType={!isNullOrUndefined(allPaymentFees?.pricingAgreedWithClient) ? allPaymentFees?.pricingAgreedWithClient: null}
          oldAgreementType={!isNullOrUndefined(allPaymentFees?.oldPricingAgreedWithClient) ? allPaymentFees?.oldPricingAgreedWithClient: null}
          showDiff={showDiff}
          showOld={!!allPaymentFees?.currentConditionCodeFees}
          testId={'test-payment-fees-summary'}
        />
        <PriceConditionTableRowSection data={mappedCondition}>
          <PriceConditionTableColumn field="label" type={LABEL_SUMMARY}/>
          {allPaymentFees?.currentConditionCodeFees && (
            <PriceConditionTableColumn
              customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
              field="oldCondition.value"
              type={OLD_CONDITIONS_SUMMARY}
            />
          )}
          <PriceConditionTableColumn
            customStyles={SUMMARY_TABLE_CUSTOM_STYLE}
            field="newCondition.value"
            isHighlightedFieldValueAccessor="newCondition.isHighlighted"
            type={NEW_CONDITIONS_SUMMARY}
          />
        </PriceConditionTableRowSection>
      </PriceConditionTable>}
    </>
  );
};

PaymentFeesTable.propTypes = {
  agentType: PropTypes.string,
  allPaymentFees: PropTypes.any,
  serviceModel: PropTypes.string,
  showDiff: PropTypes.string
};

export default PaymentFeesTable;
