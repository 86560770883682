import React from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core';

const TEST_PREFIX = 'test-reset-request-acknowledge';

// eslint-disable-next-line react/no-multi-comp
const ResetAcknowledgeDialog = ({onClose, onContinue, open}) => {
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown onClose={onClose} open={open}>
      <DialogContent>
        <DialogContentText>
          By proceeding, I acknowledge that all special conditions for this portfolio will be revoked and the
          pricing will be set back to standard conditions. The cancellation of any existing bilateral
          agreements for this portfolio will be communicated to the client (in writing). The special
          conditions under any existing bilateral agreements will remain valid for 30 days after completion
          of this request. No new request can be raised for this portfolio until the 30 days notice period has passed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" data-testid={`${TEST_PREFIX}-cancel-button`} onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" data-testid={`${TEST_PREFIX}-confirm-button`} onClick={onContinue}>
          Agree and Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ResetAcknowledgeDialog.propTypes = {
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  open: PropTypes.bool
};

export default ResetAcknowledgeDialog;