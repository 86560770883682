import {EFA} from './agentType';

export const PSR = 'PSR';
export const DPM = 'DPM';
export const IA_DIRECT = 'IA_DIRECT';
export const IA_INDIRECT = 'IA_INDIRECT';
export const GLOBAL_CUSTODY = 'GLOBAL_CUSTODY';
export const EXECUTION_ONLY = 'EXECUTION_ONLY';
export const EAM_EFA_MANAGED = 'EAM_EFA_MANAGED';
export const COMMERCIAL_ACT_CASH = 'COMMERCIAL_ACT_CASH';
export const COMMERCIAL_ACT_STRATEGIC_HOLDINGS = 'COMMERCIAL_ACT_STRATEGIC_HOLDINGS';

export const EAM_EFA_MANAGED_DESC = 'EAM Managed / EFA Managed';

const isEAM = serviceModel => {
  return serviceModel === EAM_EFA_MANAGED;
}

export const isEFA = agentType => {
  return agentType === EFA;
}

export const isEAMorEFA = (serviceModel, agentType) => {
  return isEAM(serviceModel) || agentType === EFA;
}

export const getAllServiceModels = () => {
  return [
    PSR,
    DPM,
    IA_DIRECT,
    IA_INDIRECT,
    GLOBAL_CUSTODY,
    EXECUTION_ONLY,
    EAM_EFA_MANAGED,
    COMMERCIAL_ACT_CASH,
    COMMERCIAL_ACT_STRATEGIC_HOLDINGS
  ];
}

