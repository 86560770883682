import React from 'react';
import PropTypes from 'prop-types';
import PriceConditionTableRowSection from './PriceConditionTableRowSection';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import {makeStyles} from '@material-ui/styles';


const useStyles = makeStyles(() => ({
  table: {
    tableLayout: 'fixed'
  }
}));

const PriceConditionTable = ({children, width, testId}) => {

  const classes = useStyles();

  return (
    <Table className={classes.table} data-testid={testId} size="small" style={{width: width}}>
      <TableBody>
        {children}
      </TableBody>
    </Table>
  );
};

PriceConditionTable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(PriceConditionTableRowSection.propTypes)),
    PropTypes.shape(PriceConditionTableRowSection.propTypes),
  ]),
  testId: PropTypes.string,
  width: PropTypes.string
};

export default PriceConditionTable;
