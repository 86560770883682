import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {AdminRoute, RouteWithLayout} from './components';
import {Admin as AdminLayout, Landing as LandingLayout, Main as MainLayout} from './layouts';
import {PriceRequestProvider} from './views/PriceSimulation/PriceSimulationStore';
import {
  Admin as AdminView,
  Home as HomeView,
  NotFound as NotFoundView,
  Overview as OverviewView,
  PriceSimulation as PriceSimulationView,
  PriceSimulationSummary as PriceSimulationSummaryView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={HomeView}
        exact
        layout={LandingLayout}
        path="/"
      />

      <Route path="/admin">
        <AdminRoute>
          <RouteWithLayout
            component={AdminView}
            layout={AdminLayout}
            path="/admin"
          />
        </AdminRoute>
      </Route>
      <Route path="/simulation/:pricingRequestId">
        <PriceRequestProvider>
          <RouteWithLayout
            component={PriceSimulationView}
            exact
            layout={MainLayout}
            path="/simulation/:pricingRequestId"
          />
          <RouteWithLayout
            component={PriceSimulationSummaryView}
            exact
            layout={MainLayout}
            path="/simulation/:pricingRequestId/summary"
          />
        </PriceRequestProvider>
      </Route>
      <Route path="/overview">
        <PriceRequestProvider>
          <RouteWithLayout
            component={OverviewView}
            exact
            layout={MainLayout}
            path="/overview"
          />
        </PriceRequestProvider>
      </Route>
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={LandingLayout}
        path="/not-found"
      />
      <Redirect to="/not-found"/>
    </Switch>
  );
};

export default Routes;
