import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Store} from '../../Store';
import {getAdminRoles} from '../../common/enums/userRole';
import BackdropProgress from '../../views/Common/components/BackdropProgress';
import {Box, Typography} from '@material-ui/core';
import {TopBar} from '../../layouts/Common/components';

export const ADMIN_ROLES = getAdminRoles();

const AdminRoute = ({children}) => {
  const {parentState} = useContext(Store);

  const isAdmin = parentState.user?.userRoles?.some(
    userRole => userRole && ADMIN_ROLES.some(role => role.code === userRole.role)
  );

  if (!parentState.user) {
    return <BackdropProgress open/>;
  }
  if (isAdmin) {
    return children || null;
  } else {
    return (
      <React.Fragment>
        <TopBar/>
        <Box data-testid="test-admin-route-error" marginTop={5} textAlign="center">
          <Typography>You do not have access to this page.</Typography>
        </Box>
      </React.Fragment>
    );
  }
};

AdminRoute.propTypes = {
  children: PropTypes.node
};

export default AdminRoute;
