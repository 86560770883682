import React, {useContext, useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import {makeStyles} from '@material-ui/styles';
import {Paper, Snackbar} from '@material-ui/core';
import {Store} from '../../../../../../../../Store.js';
import {Alert} from '../../../../../../../Common/components/Alert/Alert';
import {SELECT_CUSTOMER} from '../../../../../../../../common/actionTypes';
import {useLocation} from 'react-router-dom';
import {PORTFOLIO_NUMBER_QUERY} from '../../../../../../../../common/queryParams.js';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    '& .MuiAutocomplete-input': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px !important'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '12px !important'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '14px !important'
      }
    },
    '& .MuiAutocomplete-endAdornment': {
      display: 'none'
    }
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

const AccountSearch = () => {
  const [customer, setSelectedCustomer] = useState();
  const {parentState, parentDispatch} = useContext(Store);
  const [isDisabled, setDisabled] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [placeholder, setPlaceholder] = useState('Account Number & Name');
  const queryParams = new URLSearchParams(useLocation().search);
  const portfolioNumber = queryParams.get(PORTFOLIO_NUMBER_QUERY);

  useEffect(() => {
    if (parentState.customers) {
      if (parentState.customers.length > 0) {
        // Check if there's portfolio number in query parameter
        if (portfolioNumber) {
          const accountNumber = portfolioNumber.slice(0, portfolioNumber.indexOf('-'));
          const customer = parentState.customers.find(customer => customer.accountNumber === accountNumber);
          if (customer) {
            onChangeCustomer(customer);
          }
        } else {
          setPlaceholder('Search by Account number / Account Name');
        }
      } else {
        setDisabled(true);
        setError(true);
        setPlaceholder('Disabled - No Active Accounts Found.');
      }
      setLoading(false);
    }
  }, [parentState.customers, portfolioNumber]);

  useEffect(() => {
    if (customer) {
      parentDispatch({type: SELECT_CUSTOMER, payload: customer});
      setOpen(false);
    }
  }, [customer, parentDispatch]);

  const onChangeCustomer = customer => {
    setSelectedCustomer(customer);
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={6000}
        onClose={() => {
          setOpen(false);
        }}
        open={error}
      >
        <Alert
          data-testid="test-error"
          severity="error"
        >No active accounts found.</Alert>
      </Snackbar>
      <Paper
        elevation={0}
        style={{paddingTop: '10px'}}
        variant="elevation"
      >
        {!isLoading ? (
          <Autocomplete
            disabled={isDisabled}
            getOptionLabel={option =>
              option.accountNumber + '-' + option.accountName
            }
            id="account-search"
            onChange={(event, newValue) => {
              onChangeCustomer(newValue);
            }}
            open={isOpen}
            options={parentState.customers}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{shrink: true}}
                InputProps={{...params.InputProps, 'data-testid': 'test-account-search'}}
                autoComplete={'off'}
                onBlur={() => setOpen(false)}
                onChange={function (event) {
                  if (event.target.value && event.target.value.length > 2) {
                    setOpen(true);
                  } else {
                    setOpen(false);
                  }
                }}
                placeholder={placeholder}
                variant="outlined"
              />
            )}
            value={parentState.customer}
          />
        ) : (
          <Skeleton animation="wave"/>
        )}
      </Paper>
    </div>
  );
};

function customerPropsAreEqual(prevCustomer, nextCustomer) {
  return prevCustomer.state === nextCustomer.state;
}

export default React.memo(AccountSearch, customerPropsAreEqual);
