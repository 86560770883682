import React, {useContext, useEffect, useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Store} from '../../../../../../../../Store';
import {ACCOUNT_SEARCH_TYPE} from '../../../../../../../../common/actionTypes'
import {ACCOUNT_NUMBER, EAM_AGENT_CODE} from '../../../../../../../../common/enums/accountSearchType';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8125rem',
    marginRight: theme.spacing(2)
  },
  text: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8125rem',
    justifyContent: 'flex-end'
  }
}));

const AccountTypeRadioGroup = ({...others}) => {
  const classes = useStyles();
  const {parentState, parentDispatch} = useContext(Store);
  const [accountSearchType, setAccountSearchType] = useState();


  const onChange = e => {
    setAccountSearchType(e.target.value);
  };

  useEffect(() => {
    if (!accountSearchType) {
      parentDispatch({type: ACCOUNT_SEARCH_TYPE, payload: 'ACCOUNT_NUMBER'})
    } else {
      parentDispatch({type: ACCOUNT_SEARCH_TYPE, payload: accountSearchType})
    }
  }, [accountSearchType, parentDispatch])

  const options = [
    {label: 'Account number', value: ACCOUNT_NUMBER},
    {label: 'EAM/EFA Agent Code', value: EAM_AGENT_CODE}
  ];

  let radioElements = [];
  for (let option of options) {
    radioElements.push(
      <FormControlLabel
        control={<Radio color={'primary'}/>}
        data-testid={`test-search-type-${option.value}`}
        key={'key_' + option.value}
        label={option.label}
        value={option.value}
        {...others}
      />
    );
  }

  return (
    <Box display="flex">
      <Box className={classes.label}>Search by:</Box>
      <RadioGroup
        aria-label={'label_accountSearchType'}
        name="acccountSearchType"
        onChange={e => onChange(e)}
        row
        value={parentState.accountSearchType || 'ACCOUNT_NUMBER'}
      >
        {radioElements}
      </RadioGroup>
    </Box>
  );
};

export default AccountTypeRadioGroup;
