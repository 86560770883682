import {isNullOrUndefined} from '../../../../common/isNullOrUndefined';
import {isEqual} from 'underscore';

export const isNewRecurringFeeDifferent = (recurringFees, key) => {
  if (!recurringFees) {
    return false;
  }

  if (isNullOrUndefined(recurringFees.currentCondition)) {
    return false;
  }

  if (isNullOrUndefined(recurringFees.currentCondition[key])) {
    if (recurringFees.newCondition) {
      if (recurringFees.newCondition[key] === '') {
        return false; // let null & '' to be false
      } else if (isNullOrUndefined(recurringFees.newCondition[key])) {
        return false;
      }
      return true;
    }
  } else {
    if (isNullOrUndefined(recurringFees.newCondition) || isNullOrUndefined(recurringFees.newCondition[key]) ||
      (!isNullOrUndefined(recurringFees.currentCondition[key]) && isNullOrUndefined(recurringFees.newCondition[key])))  {
      return true;
    }
  }

  // Old condition is the same with new condition
  if (!isEqual(recurringFees.currentCondition[key], recurringFees.newCondition[key])) {
    return true;
  }

  return false;
};
