export default {
  root:{
    padding: '0px 4px',
    maxHeight: '30px',
    minHeight: '30px',
    fontFamily: '\'Verlag SSm 3r\'',
    fontWeight: 'normal',
    backgroundColor: '#141e55',
    borderRight: '1px solid #fff',
    color: '#fff',
    fontSize: '0.8125rem',
    '& .Mui-selected': {
      color: '#FFF !important'
    },
  },
  textColorPrimary:{
    color: '#fff',
    borderColor: '#FFF',
    '&:not(.Mui-selected)' : {
      '& .MuiButtonBase-root':{
        '&:hover':{
          fontWeight: 'bold',
          color: '#fff',
        }
      }
    },
    '&.disabled': {
      color: '#ccc',
      backgroundColor: '#ccc',
      borderColor: '#ccc',
    }
  }
};
