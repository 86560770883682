import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Link} from '@material-ui/core';
import api from '../../../../../../../../../../api/api';
import {SUBMITTED} from '../../../../../../../../../../common/statusTypes';
import ApproveDialog from './components/ApproveDialog';
import ReturnDialog from './components/ReturnDialog';
import {useNotification} from '../../../../../../../../../Common/components/Notification';
import ServiceModelChangeDialog from '../../../../../../../../../Common/components/ServiceModelChangeDialog';
import {getErrorMessage} from '../../../../../../../../../../common/getErrorMessage';
import RequestDetailsDialog from './components/RequestDetailsDialog';

const ActionCell = ({row, setPriceRequests}) => {
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isReturnDialogOpen, setIsReturnDialogOpen] = useState(false);
  const [isRequestDetailsDialogOpen, setIsRequestDetailsDialogOpen] = useState(false);
  const [openServiceModelDialog, setOpenServiceModelDialog] = useState(false);
  const [invalidPortfolioNumbers, setInvalidPortfolioNumbers] = useState([]);
  const {discountReasons, portfolioNumber, pricingRequestStatus, pricingRequestId, tenantId} = row.original;
  const notification = useNotification();
  const approveRequest = (approvalDecisionData) => {
    return api.pricingRequest
      .approve(approvalDecisionData, tenantId)
      .then(response => {
        setPriceRequests(response.data?.content);

        setIsApproveDialogOpen(false);
        setIsRequestDetailsDialogOpen(false);
        notification.success('Pricing request successfully approved');
        Promise.resolve();
      })
      .catch(err => {
        notification.error('Could not approve the pricing request\n' + getErrorMessage(err));
        Promise.reject(err);
      });
  };

  const returnRequest = (approvalDecisionData) => {
    return api.pricingRequest
      .reject(approvalDecisionData, tenantId)
      .then(response => {
        setPriceRequests(response.data?.content);
        setIsReturnDialogOpen(false);
        setIsRequestDetailsDialogOpen(false);
        notification.success('Pricing request successfully returned');
        Promise.resolve();
      })
      .catch(err => {
        notification.error('Could not return the pricing request\n' + getErrorMessage(err));
        Promise.reject(err);
      });
  };

  const openApproveDialog = () => {
    // Validate service model
    api.pricingRequest.getWithTenantId(pricingRequestId, row?.original?.tenantId).then(res => {
      api.pricingRequest.validateServiceModel([{portfolioNumber, ...res.data}]).then(response => {
        const invalidPortfolioNumbers = response.data
          .filter(data => data.serviceModelChanged)
          .map(filteredData => filteredData.portfolioNumber);

        if (invalidPortfolioNumbers.length > 0) {
          setInvalidPortfolioNumbers(invalidPortfolioNumbers);
          setOpenServiceModelDialog(true);
        } else {
          setIsApproveDialogOpen(true);
        }
      }).catch(err => {
        notification.error(getErrorMessage(err));
      });
    }).catch(err => {
      notification.error(getErrorMessage(err));
    });
  };

  const openReturnDialog = () => {
    setIsReturnDialogOpen(true);
  }

  return (
    <Box data-testid="test-action-cell" paddingBottom={1} paddingTop={1}>
      {pricingRequestStatus === SUBMITTED && (
        <React.Fragment>
          <ServiceModelChangeDialog
            onClose={() => setOpenServiceModelDialog(false)}
            open={openServiceModelDialog}
            text={`The pricing request for ${invalidPortfolioNumbers.join(
              ', '
            )} is no longer valid, as service model / mandate type has been changed in CRM. You have to return this pricing request.`}
          />
          <ApproveDialog
            discountReasons={discountReasons}
            onClose={() => setIsApproveDialogOpen(false)}
            onSubmit={approveRequest}
            open={isApproveDialogOpen}
            pricingRequestId={pricingRequestId}
          />
          <ReturnDialog
            onClose={() => setIsReturnDialogOpen(false)}
            onSubmit={returnRequest}
            open={isReturnDialogOpen}
            pricingRequestId={pricingRequestId}
          />
          <RequestDetailsDialog
            onClose={() => setIsRequestDetailsDialogOpen(false)}
            open={isRequestDetailsDialogOpen}
            openApproveDialog={openApproveDialog}
            openReturnDialog={openReturnDialog}
            pricingRequestId={pricingRequestId}
            tenantId={row?.original?.tenantId}
          />
          <Box display={'flex'}>
            <Button onClick={openApproveDialog}>Approve</Button>
            <Button onClick={openReturnDialog}>Return</Button>
          </Box>
          <Link
            component="button"
            data-testid="test-request-details-link"
            onClick={() => setIsRequestDetailsDialogOpen(true)}
            style={{width: '100%'}}
          >
            Request Details
          </Link>
        </React.Fragment>
      )}
    </Box>
  );
};

ActionCell.propTypes = {
  row: PropTypes.any,
  setPriceRequests: PropTypes.func
};

export default ActionCell;
