import {useContext} from 'react';
import NotificationContext from './NotificationContext';

const useNotification = () => {
  const context = useContext(NotificationContext);

  return {error: context.error, success: context.success, warning: context.warning, info: context.info};
};

export default useNotification;
