import {UPDATE_SUMMARY, UPDATE_SUMMARY_VALIDATION_RESULT} from './summaryActions';
import {getPricingModelIdFromSummary} from '../../../../helpers/pricingModelHelper';
import {LOAD_PRICING_REQUEST_RESPONSE} from '../pricingRequestActions';

export const summaryReducer = (state, action) => {
  switch (action.type) {
    case LOAD_PRICING_REQUEST_RESPONSE: {
      if (action.payload?.data?.summary) {
        const pricingModelId = getPricingModelIdFromSummary(action.payload.data.summary);
        return {...action.payload.data.summary, pricingModelId: pricingModelId, errors: []};
      }
      return state;
    }
    case UPDATE_SUMMARY: {
      return {...state, ...action.data};
    }
    case UPDATE_SUMMARY_VALIDATION_RESULT: {
      let newErrors = (state?.errors || []).filter(e => e !== action.selector);
      if (!action.isValid) {
        newErrors = [...state.errors, action.selector];
      }
      return {
        ...state,
        errors: newErrors
      };
    }

    default:
      return state;
  }
};
