import {isNullOrUndefined} from './isNullOrUndefined';

const hasDifference = (currentVal, newVal, showOld) => {
  if(!showOld){
    return false;
  }

  if(isNullOrUndefined(currentVal) && isNullOrUndefined(newVal)){
    return false;
  }

  if(!isNullOrUndefined(currentVal) && isNullOrUndefined(newVal)){
    return true;
  }

  if(showOld && isNullOrUndefined(currentVal) && !isNullOrUndefined(newVal)){
    return true;
  }

  const effectivePrice = 'effectivePrice';
  const code = 'code';
  if (!isNullOrUndefined(currentVal) && !isNullOrUndefined(newVal)) {
    const type = typeof currentVal;
    if (type === 'object'){
      if(effectivePrice in currentVal){
        return currentVal[effectivePrice] !== newVal[effectivePrice]
      }
      if(code in currentVal){
        return currentVal[code] !== newVal[code]
      }
    }
  }
  return currentVal !== newVal;
}

const hasNoDifference = (current, old, showOld) => {
  return !hasDifference(current, old, showOld);
}

export {
  hasDifference,
  hasNoDifference
}
