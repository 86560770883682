import React from 'react';
import Moment from 'react-moment';
import {DATE_TIME_FORMAT} from '../../../../../common/dateFormat';
import DialogCell from './DialogCell';
import {
  CREATED_DATE_COLUMN,
  ENQUEUED_DATE_COLUMN,
  ERROR_INFO_COLUMN,
  RECEIVED_DATE_COLUMN,
  REFERENCE_COLUMN,
  REQUEST_BODY_COLUMN,
  RESPONSE_BODY_COLUMN,
  UPDATED_DATE_COLUMN
} from '../columnsData';

const T24CustomCell = ({column, row, value}) => {
  if (
    [
      RECEIVED_DATE_COLUMN.key,
      UPDATED_DATE_COLUMN.key,
      CREATED_DATE_COLUMN.key,
      ENQUEUED_DATE_COLUMN.key
    ].includes(column.id)
  ) {
    return value ? <Moment date={value} format={DATE_TIME_FORMAT} /> : null;
  } else if (
    [REQUEST_BODY_COLUMN.key, RESPONSE_BODY_COLUMN.key, ERROR_INFO_COLUMN.key].includes(column.id)
  ) {
    return value ? <DialogCell title={row.original.primaryMessageId} value={value}/> : null;
  } else if (
    [REFERENCE_COLUMN.key].includes(column.id)
  ) {
    if (row.original.requestContentType === 'PricingRequest') {
      return row.original.relatedPortfolioNbr || '';
    }
    if (row.original.requestContentType === 'FeeCommissionTypes') {
      return row.original.relatedFeeCommissionCode || '';
    }
    return '';
  } else {
    return value ? value : null;
  }
};

export default T24CustomCell;
