import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';

import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import {makeStyles} from '@material-ui/styles';
import {CLIPP_WHITE} from '../../../../../../../theme/common/bjbColor';
import {HEADER_COLOR} from '../../../../../../../theme/common/color';

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: HEADER_COLOR,
    padding: theme.spacing(1),
    '& h2': {
      display: 'flex',
      alignItems: 'center',
      color: CLIPP_WHITE,
      textTransform: 'uppercase'
    }
  },
  checkbox: {
    padding: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ColumnSelectionDialog = ({open, onClose, applyColumnSelection, selectedColumns}) => {
  const classes = useStyles();
  const [state, setState] = useState();

  useEffect(() => {
    let newState = {};
    selectedColumns.forEach((col) => {
      if (col.key) {
        newState[col.key] = !col.hidden;
      }
    });
    setState(newState);
  }, [selectedColumns]);

  const handleChange = (event) => {
    setState({...state, [event.target.name]: event.target.checked});
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle className={classes.title}>
        <ViewColumnIcon/>
        Select Columns
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          {selectedColumns &&
          selectedColumns.map((column) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={state[column.key]}
                  className={classes.checkbox}
                  color="primary"
                  name={column.key}
                  onChange={handleChange}
                />
              }
              key={column.key}
              label={column.label}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            applyColumnSelection(state);
            onClose();
          }}
        >
          Apply
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

ColumnSelectionDialog.propTypes = {
  applyColumnSelection: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedColumns: PropTypes.any
};

export default ColumnSelectionDialog;
