import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Paper} from '@material-ui/core';
import {BoxHeader} from '../../../Common/components';
import {PaymentFeeConditionTable} from './PaymentFeeConditionTable';
import {PriceRequestStore} from '../../PriceSimulationStore';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import {useNotification} from '../../../Common/components/Notification';
import {loadPaymentApplicableFees} from './paymentFeesService';
import {UPDATE_PAYMENT_FEE_AGREEMENT_TYPE, UPDATE_PAYMENT_FEE_CONDITION} from './paymentFeesActions';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import {PENDING_PM} from '../../../../common/statusTypes';
import {isEAMorEFA} from '../../../../common/enums/serviceModel';
import AgreementTypeIndicator from '../Common/AgreementTypeIndicator';
import {BILA} from '../../../../common/enums/agreementType';


const PaymentFees = ({readOnly}) => {

  const {state, dispatch} = useContext(PriceRequestStore);
  const notification = useNotification();

  useEffect(() => {
    loadPaymentApplicableFees(state.selectedPricingRequest.common.pricingRequestId, dispatch)
      .then(() => {
        const condition = state.selectedPricingRequest.paymentFees?.newPaymentFeeCondition;
        if (condition) {
          dispatch({type: UPDATE_PAYMENT_FEE_CONDITION, data: condition});
        }
      })
      .catch((err) => {
        notification.error('Could not load payment fees\n' + getErrorMessage(err));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedPricingRequest.common.pricingRequestId]);

  if (!state.selectedPricingRequest?.paymentFees?.newConditionCodeFees) {
    return <LoadingSpinner/>;
  }

  const handleAgreementTypeIndicator = (checked) => {
    const pricingAgreedWithClient = state.selectedPricingRequest.paymentFees.pricingAgreedWithClient = checked ? BILA : null
    dispatch({type: UPDATE_PAYMENT_FEE_AGREEMENT_TYPE, data: pricingAgreedWithClient});
  }

  return (
    <Paper elevation={3} style={{padding: '8px'}}>
      <BoxHeader text={'Payment Fees'}/>
      <AgreementTypeIndicator
        agreedWithClient={state.selectedPricingRequest.paymentFees?.pricingAgreedWithClient?.code}
        handleChange={handleAgreementTypeIndicator}
        readOnly={readOnly || state.selectedPricingRequest?.common?.pricingRequestStatus === PENDING_PM}
        show={!isEAMorEFA(state.selectedPricingRequest.common?.serviceModel?.code, state.selectedPricingRequest.common?.agentType)}
      />
      <PaymentFeeConditionTable allPaymentFees={state.selectedPricingRequest.paymentFees} readOnly={readOnly}/>
    </Paper>
  );
};

PaymentFees.propTypes = {
  readOnly: PropTypes.bool,
}

export default PaymentFees;
