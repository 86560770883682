import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Dialog as MuiDialog, DialogContent, IconButton, Typography} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import {useNotification} from '../../../../Common/components/Notification';
import formatXml from '../../../../../common/formatXml';
import {withStyles} from '@material-ui/styles';

const Dialog = withStyles(() => ({
  paper: {
    height: '100%'
  }
}))(MuiDialog);

function DialogCell({title, value}) {
  const notification = useNotification();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState();

  const openDialog = text => {
    setDialogText(text);
    setIsDialogOpen(true);
  };

  const closeDialog = () => setIsDialogOpen(false);

  const copyDialogText = async () => {
    await navigator.clipboard.writeText(dialogText);
    notification.info('Copied to clipboard');
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="md" onClose={closeDialog} open={isDialogOpen}>
        <Box alignItems="center" display="flex" padding={1}>
          <Box flexGrow="1" paddingLeft={2}>
            <Typography variant="h1">{title}</Typography>
          </Box>
          <IconButton onClick={copyDialogText}>
            <FileCopyIcon/>
          </IconButton>
          <IconButton onClick={closeDialog}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <DialogContent>
          <textarea style={{height: '98%', width: '98%'}}>
            {formatXml(dialogText)}
          </textarea>
        </DialogContent>
      </Dialog>
      <Button onClick={() => openDialog(value)}>Open</Button>
    </div>
  );
}

DialogCell.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string
};

export default DialogCell;
