import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Dialog as MuiDialog, DialogContent, IconButton, Typography} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import {useNotification} from '../../../../Common/components/Notification';
import {withStyles} from '@material-ui/styles';
import adminApi from '../../../../../api/adminApi';
import {getErrorMessage} from '../../../../../common/getErrorMessage';
import LoadingSpinner from '../../../../Common/components/LoadingSpinner';
import SimpleTaskTable from './SimpleTaskTable';

const Dialog = withStyles(() => ({
  paper: {
    height: '100%'
  }
}))(MuiDialog);

function ApprovalLogCell({pricingRequestId, title}) {
  const notification = useNotification();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [summary, setSummary] = useState();
  const [isLoading, setIsLoading] = useState();

  const openDialog = pricingRequestId => {
    fetchData(pricingRequestId);
    setIsDialogOpen(true);
  };

  const closeDialog = () => setIsDialogOpen(false);

  const copyDialogText = async () => {
    if (!summary) {
      return;
    }
    await navigator.clipboard.writeText('Pricing Request ID:' + pricingRequestId + '\n' + JSON.stringify(summary));
    notification.info('Copied to clipboard');
  };

  const fetchData = async (pricingRequestId) => {
    setIsLoading(true);
    try {
      const res = await adminApi.pricingAdmin.viewWorkflowApprovers(pricingRequestId);
      setSummary(res.data);
      setIsLoading(false);
    } catch (err) {
      notification.error('Could not load data.\n' + getErrorMessage(err));
    }
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="md" onClose={closeDialog} open={isDialogOpen}>
        <Box alignItems="center" display="flex" padding={1}>
          <Box flexGrow="1" paddingLeft={2}>
            <Typography variant="h1">{title}</Typography>
          </Box>
          <IconButton onClick={copyDialogText}>
            <FileCopyIcon/>
          </IconButton>
          <IconButton onClick={closeDialog}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <DialogContent>
          {isLoading ? <LoadingSpinner/> :
            <SimpleTaskTable data={summary}/>
          }
        </DialogContent>
      </Dialog>
      <Button onClick={() => openDialog(pricingRequestId)}>Open</Button>
    </div>
  );
}

ApprovalLogCell.propTypes = {
  pricingRequestId: PropTypes.number,
  title: PropTypes.string
};

export default ApprovalLogCell;
