import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const PriceSelect = ({onValueChange, options, readOnly, value, ...other}) => {
  const MAX_MENU_HEIGHT = 300;
  let selectableOptions = [];
  for (const option of (options || [])) {
    selectableOptions.push((<MenuItem key={option.key} value={option.key}>{option.value}</MenuItem>));
  }

  return (
    <TextField
      data-testid={other['data-testid']}
      disabled={readOnly}
      fullWidth
      onChange={(event) => onValueChange({value: event.target.value, isValid: true})}
      select
      // eslint-disable-next-line react/jsx-sort-props
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: MAX_MENU_HEIGHT
            }
          }
        }
      }}
      style={{textAlign: 'right'}}
      value={value}
      {...other}
    >
      {selectableOptions}
    </TextField>
  );


}

export default PriceSelect;

PriceSelect.propTypes = {
  onValueChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    value: PropTypes.string
  })),
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
};

