import {CLIPP_WHITE} from '../common/bjbColor';

export default {
  root: {},
  inputRoot: {
    boxShadow: '0px 0px 0px 0.1rem rgba(0,20,137,0.1)',
    borderRadius: '5px',
    backgroundColor: CLIPP_WHITE,
    height: '32px',
    '& .MuiAutocomplete-input': {
      paddingTop: '0px !important',
      paddingBottom: '10px !important',
      fontFamily: '\'Verlag SSm 7r\'',
      fontWeight: 'normal',
      fontSize: '0.8125rem'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none'
    },
    '& input[type=text]::-ms-clear':{
      display: 'none',
      width: '0',
      height:'0',
    },
    '& input[type=text]::-ms-reveal':{
      display: 'none',
      width: '0',
      height:'0',
    }
  },
  option: {
    borderBottom: '1px solid rgba(204,204,204,0.5)',
    fontFamily: '\'Verlag SSm 7r\'',
    fontWeight: 'normal',
    fontSize: '0.8125rem'
  }
};
