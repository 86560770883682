import React from 'react';
import PriceNumberFormat from '../../../../../Common/components/PriceNumberFormat';
import {CHF} from '../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {TICKET_FEE} from '../../../../../../common/enums/transactionFeeCondition';

const LABEL = 'Minimum Fee per Transaction';
const KEY_PREFIX = 'transaction-fees-minimum-fee-per-transaction';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const minimumFeePerTransaction = ({newConditionCode, subFund, handleValidationResultChange, handleValueChange, readOnly, specialRequest}) => {

  let result = {
    label: LABEL
  };

  if (subFund.currentCondition?.minFeePerTransaction) {
    result = {
      ...result,
      currentCondition: getMappedOldCondition(subFund.currentCondition?.minFeePerTransaction)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(subFund.newCondition?.minFeePerTransaction, newConditionCode, handleValidationResultChange, handleValueChange, readOnly, specialRequest)
  }

};


const getMappedOldCondition = (minFeePerTransaction) => {
  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={minFeePerTransaction?.effectivePrice}
    />);

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (minFeePerTransaction, conditionCode, handleValidationResultChange, handleValueChange, readOnly, specialRequest) => {

  const isEditable = conditionCode !== TICKET_FEE.code && !readOnly;

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      key={`${KEY_PREFIX}-list-price`}
      type={CHF}
      value={minFeePerTransaction?.listPrice}
    />);

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={minFeePerTransaction?.effectivePrice}
    />);

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-editable`}
      disableEmptyInput
      isEditable
      key={`${KEY_PREFIX}-effective-price-editable`}
      max={specialRequest ? null : minFeePerTransaction?.listPrice}
      onValidationResultChange={(vr) => handleValidationResultChange({...vr, selectors: ['newCondition', 'minFeePerTransaction', 'effectivePrice']})}
      onValueChange={(vr) => handleValueChange({...vr, selectors: ['newCondition', 'minFeePerTransaction', 'effectivePrice']})}
      type={CHF}
      value={minFeePerTransaction?.effectivePrice}
    />);

  return {
    effectivePrice: isEditable ? effectivePriceEditable : effectivePriceReadonly,
    isEditable: isEditable,
    listPrice: listPrice
  };
};

