export const sortCellColumn = (cells, columnsData) => {
  const columnOrder = columnsData.map(col => col.key);

  return cells.sort((a, b) => {
    const indexA = columnOrder.indexOf(a.column.id),
      indexB = columnOrder.indexOf(b.column.id);
    return indexA > indexB ? 1 : -1;
  });
};

export const sortHeaderColumn = (headers, columnsData) => {
  const columnOrder = columnsData.map(col => col.key);

  return headers.sort((a, b) => {
    const indexA = columnOrder.indexOf(a.id),
      indexB = columnOrder.indexOf(b.id);
    return indexA > indexB ? 1 : -1;
  });
};
