import React from 'react';
import Box from '@material-ui/core/Box';
import PriceSelect from '../../../../Common/components/PriceSelect';
import {getRecurringFeeImplementationDescriptionFromCode} from '../../../../../common/enums/recurringFeeImplementation';
import {DPM} from '../../../../../common/enums/serviceModel';
import {isNewRecurringFeeDifferent} from '../isNewRecurringFeeDifferent';

const LABEL = 'Implementation';
const KEY_PREFIX = 'recurring-fees-implementation';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const recurringFeeImplementation = ({handleImplementationChange, readOnly, recurringFees, selectableImplementationCodes, serviceModelCode, showDiff}) => {

  if (serviceModelCode !== DPM) {
    return null;
  }

  let result = {
    label: LABEL
  };
  let isNewValueDifferent = !!readOnly && isNewRecurringFeeDifferent(recurringFees, 'implementation');

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (recurringFees?.currentCondition?.implementation) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(recurringFees?.currentCondition?.implementation)
    };
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(recurringFees?.newCondition?.implementation, selectableImplementationCodes, handleImplementationChange, readOnly, isNewValueDifferent)
  };

};

const getMappedOldCondition = (implementation) => {

  const readonlyField = (
    <Box data-testid={`${TEST_KEY_PREFIX}-readonly`} textAlign="right">{implementation?.desc}</Box>
  );

  return {
    value: readonlyField
  };
};

const getMappedNewCondition = (implementation, selectableImplementationCodes, handleImplementationChange, readOnly, isNewValueDifferent) => {
  const options = selectableImplementationCodes.map(i => {
    return {key: i, value: getRecurringFeeImplementationDescriptionFromCode(i)};
  });

  let value = implementation?.code;
  if (options.filter(o => o.key === implementation?.code).length === 0) {
    value = '';
  }

  const editableField = (
    <PriceSelect
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      inputProps={{id: 'uncontrolled-native'}}
      key={`${KEY_PREFIX}-editable`}
      onValueChange={handleImplementationChange || (() => {
      })}
      options={options}
      value={value}
    />
  );

  const readOnlyField = (
    <Box data-testid={`${TEST_KEY_PREFIX}-readonly`} textAlign="right">{implementation?.desc}</Box>
  )

  return {
    value: readOnly ? readOnlyField : editableField,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
  };
};
