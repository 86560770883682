import React from 'react';
import PropTypes from 'prop-types';
import {
  LABEL_SUMMARY,
  NEW_CONDITIONS_SUMMARY
} from '../../../layouts/Common/components/PriceConditionTable/PriceConditionType';
import PriceConditionTable from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTable';
import PriceConditionTableRowSection from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTableRowSection';
import PriceConditionTableColumn from '../../../layouts/Common/components/PriceConditionTable/PriceConditionTableColumn';
import {Box} from '@material-ui/core';
import PriceNumberFormat from '../../Common/components/PriceNumberFormat';
import {PERCENTAGE} from '../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {MINUS_OPERATOR, PLUS_OPERATOR, SPECIAL_CONDITION} from '../../PriceSimulation/components/Overdraft/Overdraft';
import CommonBILARows from './CommonBILARows';
import {isEAMorEFA} from '../../../common/enums/serviceModel';

const OverdraftTable = ({overdraft, serviceModel, agentType, showDiff}) => {

  const getOperator = stringOperator => {
    if (stringOperator === PLUS_OPERATOR) return '+';
    else if (stringOperator === MINUS_OPERATOR) return '-';
    else return '';
  };

  let overdraftData = [
    {
      label: 'Condition',
      newCondition: {
        value: <Box textAlign="right">{overdraft?.overdraftCondition}</Box>
      }
    }
  ];

  if (overdraft?.overdraftCondition === SPECIAL_CONDITION) {
    overdraftData.push({
      label: 'OD pricing in % CO',
      newCondition: {
        value: (
          <Box data-testid="test-overdraft-pricing" display="flex" justifyContent="flex-end">
            {getOperator(overdraft?.overdraftOperator)}
            <PriceNumberFormat type={PERCENTAGE} value={overdraft?.overdraftPricingInPercentage}/>
          </Box>
        )
      }
    });
  }

  return (
    <PriceConditionTable>
      <PriceConditionTableRowSection data={[]}>
        <PriceConditionTableColumn field="label" label="Overdraft" type={LABEL_SUMMARY}/>
        <PriceConditionTableColumn
          label="New Condition"
          type={NEW_CONDITIONS_SUMMARY}
        />
      </PriceConditionTableRowSection>
      {!showDiff &&  <>
        <CommonBILARows
          hideAcknowledgement
          hideBILA={isEAMorEFA(serviceModel, agentType)}
          newAgreementType={overdraft?.pricingAgreedWithClient ? overdraft?.pricingAgreedWithClient: null}
          showDiff={showDiff}
          showOld={false}
          testId={'test-overdraft-summary'}
        />
        <PriceConditionTableRowSection data={overdraftData}>
          <PriceConditionTableColumn field="label" type={LABEL_SUMMARY}/>
          <PriceConditionTableColumn
            field="newCondition.value"
            type={NEW_CONDITIONS_SUMMARY}
          />
        </PriceConditionTableRowSection>
      </>}
    </PriceConditionTable>
  );
};

OverdraftTable.propTypes = {
  agentType: PropTypes.string,
  overdraft: PropTypes.any,
  serviceModel: PropTypes.string,
  showDiff: PropTypes.string
};

export default OverdraftTable;
