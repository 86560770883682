import React, {useContext, useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {
  ERROR_BACKGROUND_PRIMARY_COLOR,
  EXPANSION_PANEL_SECONDARY_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_COLOR,
  EXPANSION_PANEL_SECONDARY_FOCUS_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_FOCUS_COLOR,
  EXPANSION_PANEL_SECONDARY_HOVER_BACKGROUND_COLOR,
  EXPANSION_PANEL_SECONDARY_HOVER_COLOR
} from '../../../../../theme/common/color';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {PriceRequestStore} from '../../../PriceSimulationStore';
import NMBFeePanel from './NMBFeePanel';
import NMBFeeTenorSubTypes from './NMBFeeTenorSubTypes';
import {checkSubFundErrorExists} from '../../../../../common/enums/nmbFeeSubTypes';
import EffectiveCondition from '../../Common/EffectiveCondition';

const useStyles = makeStyles(() => ({

  displayFlexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  headerEffectivePrice: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '200px'
  },
  innerExpansionPanel: {
    width: '100%'
  },
  innerExpansionPanelSummary: {
    backgroundColor: EXPANSION_PANEL_SECONDARY_BACKGROUND_COLOR,
    color: EXPANSION_PANEL_SECONDARY_COLOR,
    fontSize: '0.8125rem',
    height: '36px',
    minHeight: '36px!important',
    '&:focus': {
      backgroundColor: EXPANSION_PANEL_SECONDARY_FOCUS_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_SECONDARY_FOCUS_COLOR
    },
    '&:hover': {
      backgroundColor: EXPANSION_PANEL_SECONDARY_HOVER_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_SECONDARY_HOVER_COLOR
    }
  },
  innerExpansionPanelSummaryTitle: {
    fontFamily: '\'Verlag SSm 7r\''
  },
  error: {
    backgroundColor: ERROR_BACKGROUND_PRIMARY_COLOR
  }
}));

const NMBFeeSubFund = ({fundTypeCode, handleValueChange, handleValidationResultChange, isExpanded, onExpansionToggle, readOnly, showAgreementIndicator, subFund, childSubTypes, label}) => {

  const classes = useStyles();
  const {state} = useContext(PriceRequestStore);


  // eslint-disable-next-line react/no-multi-comp
  const getNMBFeePanelDetails = () => {
    const {agentType, serviceModel, pricingRequestStatus} = state.selectedPricingRequest?.common;
    return (
      <NMBFeePanel
        agentType={agentType}
        fundTypeCode={fundTypeCode}
        handleValidationResultChange={handleValidationResultChange}
        handleValueChange={handleValueChange}
        key={`nmb-fee-panel-${fundTypeCode}-${subFund.transactionAssetSubLevel.code}`}
        pricingRequestStatus={pricingRequestStatus}
        readOnly={readOnly}
        serviceModel={serviceModel?.code}
        showAgreementIndicator={showAgreementIndicator}
        subFund={subFund}
      />
    );
  }

  const cachedNMBFeePanelDetails = useMemo(getNMBFeePanelDetails, [isExpanded ? Math.random() : false]);

  const hasError = (subFundTypeCode) => {
    const errorStrings = state.selectedPricingRequest.nmbTransactionFees?.errors;
    if (errorStrings?.length > 0) {
      const subFundErrors = errorStrings.map(errorString => errorString.split(',').map(x => x.trim())[1]);
      if (subFundErrors.includes(subFundTypeCode)) {
        return `${classes.error} error`;
      }
    }
    return '';
  }

  const hasErrorForChildSubTypes = (subFundTypeCode) => {
    const errorStrings = state.selectedPricingRequest.nmbTransactionFees?.errors;
    if (errorStrings?.length > 0) {
      const subFundErrors = errorStrings.map(errorString => errorString.split(',').map(x => x.trim())[1]);
      if (checkSubFundErrorExists(subFundTypeCode, subFundErrors)) {
        return `${classes.error} error`;
      }
    }
    return '';
  }

  return (
    <> {subFund && !childSubTypes && <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.innerExpansionPanel} expanded={isExpanded} key={`expansion-${fundTypeCode}-${subFund?.transactionAssetSubLevel?.code}`} onChange={onExpansionToggle}>
      <AccordionSummary
        className={`${classes.innerExpansionPanelSummary} ${hasError(subFund?.transactionAssetSubLevel?.code)}`}
        data-testid={`nmb-sub-funds-${subFund?.transactionAssetSubLevel?.code}-test-id`}
        expandIcon={<ExpandMoreIcon/>}
      >
        <Box
          className={classes.displayFlexSpaceBetween}
          data-testid={`expansion-${fundTypeCode}-${subFund?.transactionAssetSubLevel?.code}-header`}
        >
          <Box className={classes.innerExpansionPanelSummaryTitle}>{subFund?.transactionAssetSubLevel?.desc}</Box>
          <EffectiveCondition subFund={subFund} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {cachedNMBFeePanelDetails}
      </AccordionDetails>
    </Accordion>}
    {subFund && childSubTypes && <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.innerExpansionPanel} expanded={isExpanded} key={`expansion-${fundTypeCode}-${subFund?.nmbParentAssetType?.code}`} onChange={onExpansionToggle}>
      <AccordionSummary
        className={`${classes.innerExpansionPanelSummary} ${hasErrorForChildSubTypes(subFund?.nmbParentAssetType?.code)}`}
        data-testid={`nmb-sub-funds-${subFund?.nmbParentAssetType?.code}-test-id`}
        expandIcon={<ExpandMoreIcon/>}
      >
        <Box
          className={classes.displayFlexSpaceBetween}
          data-testid={`expansion-${fundTypeCode}-${subFund?.nmbParentAssetType?.code}-header`}
        >
          <Box className={classes.innerExpansionPanelSummaryTitle}>{label}</Box>
          <EffectiveCondition subFund={subFund} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {<NMBFeeTenorSubTypes childSubTypes={childSubTypes} fundTypeCode={fundTypeCode} handleValidationResultChange={handleValidationResultChange} handleValueChange={handleValueChange} readOnly={readOnly} showAgreementIndicator={showAgreementIndicator} subFund={subFund} />}
      </AccordionDetails>
    </Accordion>}
    </>
  );

};

NMBFeeSubFund.propTypes = {
  childSubTypes: PropTypes.any,
  fundTypeCode: PropTypes.string.isRequired,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onExpansionToggle: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showAgreementIndicator: PropTypes.bool,
  subFund: PropTypes.object.isRequired
};

export default React.memo(NMBFeeSubFund);
