import React from 'react';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  ListItemText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';
import { rem } from '../../../../../theme/common/font';
import { BJB_GREEN_SMOKE_20 } from '../../../../../theme/common/bjbColor';

const useStyles = makeStyles((theme) => ({
  inputField: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  input: {
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px'
    },
    '& .MuiSelect-selectMenu': {
      height: 'auto'
    },
    zIndex: 2
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1)
  },
  chip: {
    backgroundColor: BJB_GREEN_SMOKE_20,
    fontSize: rem(12)
  }
}));

const FxDropdownList = ({
  label,
  labelStyle,
  dimension,
  dropdownId,
  placeholder,
  placeholderStyle,
  items,
  value,
  updateValue,
  ...rest
}) => {
  const classes = useStyles();
  const placeholderVisible = value.length > 0 ? false : true;

  return (
    <Box className={classes.inputField} {...rest}>
      <InputLabel
        id={dropdownId}
        style={{ ...labelStyle }}
      >
        {label}
      </InputLabel>
      <FormControl style={{ position: 'relative' }}>
        <Select
          className={classes.input}
          labelId={dropdownId}
          multiple
          onChange={(e) => updateValue(e.target.value)}
          renderValue={(selectedOptions) => (
            <Box className={classes.chips}>
              {selectedOptions.map((option) => (
                <Chip className={classes.chip} key={option} label={option} />
              ))}
            </Box>
          )}
          style={{ ...dimension }}
          value={value}
          variant="outlined"
        >
          {items.map((item) => (
            <MenuItem
              key={item.key}
              value={item.value}
            >
              <Checkbox
                checked={value.includes(item.value)}
                color="primary"
              />
              <ListItemText primary={item.value} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText
          style={{
            ...placeholderStyle,
            visibility: placeholderVisible ? 'visible' : 'hidden'
          }}
        >
          {placeholder}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default FxDropdownList;
