import React from 'react';
import PriceNumberFormat from '../../../../../Common/components/PriceNumberFormat';
import {BPS, CHF, PERCENTAGE, REGULAR} from '../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import Box from '@material-ui/core/Box';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {FormControl, FormHelperText, IconButton} from '@material-ui/core';
import {
  NON_STANDARD_CUMULATIVE,
  NON_STANDARD_NON_CUMULATIVE
} from '../../../../../../common/enums/transactionFeeCondition';
import {isTieringValid} from '../../../../../../common/isTieringValid';
import {getDiscountFromEffectivePrice} from '../../../../../../common/getDiscountFromEffectivePrice';

export const tierPricingSpecialRequest = ({subFund, transactionFees, handleAddTier, handleRemoveTier, handleRangeStartChange, handleRangeEndChange, handleValueChange, handleValidationResultChange, readOnly, testIdSuffix, specialRequestPm}) => {
  const validationSelectors = ['portfolioAssets', 'customTiering'];

  if (
    ![NON_STANDARD_NON_CUMULATIVE.code, NON_STANDARD_CUMULATIVE.code].includes(
      subFund?.newCondition?.transactionFeeCondition?.code
    )
  ) {
    return [];
  }

  let resultList = [];

  const tiers = subFund.newCondition?.tierPricing || [{effectivePrice: 0, rangeStart: 0}];
  for (let i = 0; i < tiers.length; i++) {
    const lastRow = i === tiers.length - 1;

    const label = readOnly || !specialRequestPm
      ? getRangeLabel(tiers[i], testIdSuffix)
      : getRangeLabelWithIcon(
        handleAddTier,
        handleRemoveTier,
        handleRangeStartChange,
        handleRangeEndChange,
        tiers[i],
        lastRow,
        i,
        testIdSuffix
      );

    let isNewValueDifferent = false;

    resultList.push({
      label,
      newCondition: getMappedNewCondition({
        tier: tiers[i],
        index: i,
        handleValueChange,
        readOnly,
        isNewValueDifferent,
        testIdSuffix,
        specialRequestPm
      }),
      discount: getMappedDiscount(tiers[i], testIdSuffix)
    });
  }

  // Validation
  let validationResult = isTieringValid(tiers);
  let validationAssetTypeSelector = [subFund.assetType, subFund.assetSubLevel?.code].concat(
    validationSelectors
  );
  if (!validationResult.isValid) {
    resultList.push({
      key: 'tier-error',
      label: (
        <FormControl error>
          <FormHelperText>{validationResult.errMsg}</FormHelperText>
        </FormControl>
      ),
      newCondition: {}
    });

    if (!transactionFees.errors.includes(validationAssetTypeSelector.join())) {
      handleValidationResultChange({isValid: false, selectors: validationSelectors});
    }
  } else if (transactionFees.errors.includes(validationAssetTypeSelector.join())) {
    handleValidationResultChange({isValid: true, selectors: validationSelectors});
  }
  return resultList;
};

// eslint-disable-next-line react/no-multi-comp
const getRangeLabelWithIcon = (
  handleAddTier,
  handleRemoveTier,
  handleRangeStartChange,
  handleRangeEndChange,
  tier,
  lastRow,
  i,
  testIdSuffix
) => {
  return (
    <Box
      data-testid={`test-range-${i}+${testIdSuffix}-label`}
      style={{display: 'flex', alignItems: 'center', paddingLeft: '5px', whiteSpace: 'nowrap'}}
    >
      {lastRow ? (
        <React.Fragment>
          <PriceNumberFormat
            disableMax
            isEditable
            onValueChange={({value}) =>
              handleRangeStartChange({index: i, value, selectors: ['newCondition', 'tierPricing', i]})
            }
            placeHolderForZero="0"
            type={CHF}
            value={tier.rangeStart}
          />
          <Box component="span" whiteSpace="nowrap">
            and above
          </Box>
          <IconButton onClick={() => handleAddTier({selectors: ['newCondition', 'tierPricing', i]})}>
            <AddCircleOutlineIcon style={{cursor: 'pointer'}}/>
          </IconButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PriceNumberFormat
            disableMax
            isEditable
            onValueChange={({value}) =>
              handleRangeStartChange({index: i, value, selectors: ['newCondition', 'tierPricing', i]})
            }
            placeHolderForZero="0"
            type={REGULAR}
            value={tier.rangeStart}
          />
          <Box component="span" whiteSpace="nowrap">
            to
          </Box>
          <PriceNumberFormat
            disableMax
            isEditable
            onValueChange={({value}) =>
              handleRangeEndChange({index: i, value, selectors: ['newCondition', 'tierPricing', i]})
            }
            placeHolderForUndefined="above"
            type={CHF}
            value={tier.rangeEnd}
          />
          <IconButton
            onClick={() => handleRemoveTier({index: i, selectors: ['newCondition', 'tierPricing', i]})}
          >
            <RemoveCircleOutlineIcon style={{cursor: 'pointer'}}/>
          </IconButton>
        </React.Fragment>
      )}
    </Box>
  );
};

// eslint-disable-next-line react/no-multi-comp
const getRangeLabel = (tier, testIdSuffix) => {
  return (
    <Box
      data-testid={`test-range-${testIdSuffix}-label`}
      style={{display: 'flex', alignItems: 'center', paddingLeft: '5px'}}
    >
      <PriceNumberFormat placeHolderForZero="0" type={REGULAR} value={tier?.rangeStart}/>
      <Box>
        <span>&nbsp;{tier.rangeEnd ? 'to' : 'CHF and'}&nbsp;</span>
      </Box>
      <PriceNumberFormat placeHolderForUndefined="above" type={CHF} value={tier?.rangeEnd}/>
    </Box>
  );
};

const getMappedNewCondition = ({tier, index, handleValueChange, readOnly, isNewValueDifferent, testIdSuffix,specialRequestPm}) => {
  const isEditable = specialRequestPm ? !readOnly :false;

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`test-${testIdSuffix}-effective-price-readonly`}
      key={`test-${testIdSuffix}-effective-price-readonly`}
      type={BPS}
      value={tier?.effectivePrice}
    />
  );

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`test-${testIdSuffix}-effective-price-readonly`}
      isEditable
      key={`test-${testIdSuffix}-effective-price-readonly`}
      onValueChange={vr =>
        handleValueChange({
          ...vr,
          selectors: ['newCondition', 'tierPricing', index, 'effectivePrice']
        })
      }
      type={BPS}
      value={tier.effectivePrice}
    />
  );

  return {
    effectivePrice: isEditable ? effectivePriceEditable : effectivePriceReadonly,
    isEditable: isEditable,
    isHighlighted: isNewValueDifferent
  };
};

// eslint-disable-next-line react/no-multi-comp
const getMappedDiscount = (newCondition, testIdSuffix) => {
  const discount = parseFloat(getDiscountFromEffectivePrice(newCondition));

  return (
    !isNaN(discount) && (
      <PriceNumberFormat
        data-testid={`test-${testIdSuffix}-discount`}
        key={`test-${testIdSuffix}-discount`}
        type={PERCENTAGE}
        value={discount}
      />
    )
  );
};
