import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {FormControl, FormHelperText, Grid, Paper, TextareaAutosize, TextField, Typography} from '@material-ui/core';
import {BoxHeader, LabelInput, LabelSelect, LabelText} from '../../../Common/components';
import {ConfirmPriceModel, CopyEamPortfolio, DiscountReason} from './components';
import {PriceRequestStore} from '../../PriceSimulationStore';
import Moment from 'react-moment';
import {
  ERROR_BACKGROUND_SECONDARY_COLOR,
  FORM_BACKGROUND_COLOR
} from '../../../../theme/common/color';
import {updatePricingModel} from '../../pricingRequestService';
import {UPDATE_SUMMARY, UPDATE_SUMMARY_VALIDATION_RESULT} from './summaryActions';
import {useNotification} from '../../../Common/components/Notification';
import {DATE_FORMAT} from '../../../../common/dateFormat';
import ApprovalReason from './components/ApprovalReason';
import DocumentUpload from './components/DocumentUpload';
import {loadSupportingDocuments} from './summaryService';
import {useHistory} from 'react-router-dom';
import {SELECT_PORTFOLIOS} from '../../../../common/actionTypes';
import {Store} from '../../../../Store';
import {TEXT_AREA_MAX_LENGTH} from '../../../../common/inputConstants';
import {EAM_EFA_MANAGED} from '../../../../common/enums/serviceModel';
import {EAM_AGENT_CODE} from '../../../../common/enums/accountSearchType';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import api from '../../../../api/api';
import {
  SUMMARY_PRICE_MODEL_INFO_TEXT,
  SUMMARY_TOTAL_ASSET_INFO_TEXT
} from '../../../../common/enums/infoTexts';
import {NOT_APPLICABLE} from '../../../../common/enums/agreementType';
import {CLIPP_WHITE} from '../../../../theme/common/bjbColor';
import moment from 'moment';

const Summary = ({readOnly}) => {
  const {parentState, parentDispatch} = useContext(Store);
  const {state, dispatch} = useContext(PriceRequestStore);
  const [additionalRemarks, setAdditionalRemarks] = useState();
  const [contactReportNumber, setContactReportNumber] = useState();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [inflightPricingModelId, setInflightPricingModelId] = useState();
  const [totalAsset, setTotalAsset] = useState();
  const [contactReportFormatErr, setContactReportFormatErr] = useState(false);
  const notification = useNotification();
  const history = useHistory();

  const portfolioNumber = state.selectedPricingRequest.common?.portfolioNumber;
  const summary = state.selectedPricingRequest.summary;
  const investedAsset = summary?.investedAsset || 0;
  const cash = summary?.cash || 0;
  const summaryTotalAsset = summary && investedAsset + cash;
  const summaryAdditionalRemarks = state.selectedPricingRequest.summary?.additionalRemarks;
  const contactReport = state.selectedPricingRequest.summary?.contactReport;

  useEffect(() => {
    setAdditionalRemarks(summaryAdditionalRemarks || '');
  }, [summaryAdditionalRemarks]);

  useEffect(() => {
    setContactReportNumber(contactReport ? contactReport.contactReportNumber : '');
  }, [contactReport]);

  useEffect(() => {
    setTotalAsset(summaryTotalAsset);
  }, [summaryTotalAsset]);

  const handleSummary = event => {
    const {name, value} = event.target;
    const ZERO = 0;
    if (value || value === ZERO) {
      dispatch({type: UPDATE_SUMMARY, data: {...summary, [name]: value}});
    }
  };

  const handleValidationResultChange = ({selectors, isValid}) => {
    dispatch({type: UPDATE_SUMMARY_VALIDATION_RESULT, selector: selectors.join(), isValid: isValid});
  };

  const handleAdditionalRemarks = event => {
    if (additionalRemarks !== event.target.value) {
      setAdditionalRemarks(event.target.value);
    }
  };

  const handleContactReportNumber = event => {
    if (contactReportNumber !== event.target.value) {
      setContactReportNumber(event.target.value);
      let cReport = {contactReportId: contactReport?.contactReportId, contactReportNumber: event.target.value}
      handleSummary({target:{name:'contactReport', value: cReport}})
    }
  };

  const handleClose = () => setConfirmationDialogOpen(false);

  const handleChangePricingModel = async () => {
    const oldPricingRequestId = state.selectedPricingRequest.common.pricingRequestId;

    try {
      const newPortfolio = await updatePricingModel(oldPricingRequestId, inflightPricingModelId);
      const oldPortfolioIndex = parentState.portfolios.findIndex(
        portfolio => portfolio.pricingRequestId === oldPricingRequestId
      );
      const newlySelectedPortfolios = [...parentState.portfolios];
      newlySelectedPortfolios[oldPortfolioIndex] = newPortfolio;

      parentDispatch({type: SELECT_PORTFOLIOS, payload: newlySelectedPortfolios});

      history.push(`/simulation/${newPortfolio.pricingRequestId}`);
      setConfirmationDialogOpen(false);
      Promise.resolve();
    } catch (err) {
      notification.error('Could not change pricing model\n' + getErrorMessage(err));
      Promise.reject();
    }
  };

  const reloadSupportingDocuments = () => {
    loadSupportingDocuments(state.selectedPricingRequest.common.pricingRequestId, dispatch);
  };

  const resetSummaryInvestedAsset = async () => {
    try {
      const response = await api.pricingRequest.resetInvestedAssets(portfolioNumber);
      const investedAsset = response.data;
      dispatch({type: UPDATE_SUMMARY, data: {...summary, investedAsset}});

    } catch (err) {
      notification.error('Error resetting invested assets \n' + getErrorMessage(err));
      throw new Error();
    }
  }

  const resetSummaryCash = async () => {
    try {
      const response = await api.pricingRequest.resetCash(portfolioNumber);
      const cash = response.data;
      dispatch({type: UPDATE_SUMMARY, data: {...summary, cash}});

    } catch (err) {
      notification.error('Error resetting cash \n' + getErrorMessage(err));
      throw new Error();
    }
  }

  if (!summary) {
    return null;
  }

  const checkContactReportFormat = event => {
    // let pattern = new RegExp('^(CRSG|CRHK)+[0-9]{6}$');
    let spaceBeginPattern = new RegExp('^[\\s].*'); // should not have a space at beginnning
    let finalPattern = new RegExp('^.{1,}$',); // should have at least one charcater
    let isError = false;
    isError =(event.target.value  && spaceBeginPattern.test(event.target.value) && finalPattern.test(event.target.value));
    setContactReportFormatErr(isError);
    handleValidationResultChange({selectors: [event.target.name], isValid: !isError});
  };

  return (
    <div>
      <ConfirmPriceModel
        onClose={handleClose}
        onSubmit={handleChangePricingModel}
        open={confirmationDialogOpen}
      />
      <Paper elevation={3} style={{padding: '8px'}}>
        <BoxHeader text={'Portfolio Details'}>
          {state.selectedPricingRequest.common?.accountOpenDate && (
            <Typography style={{fontSize: '11px'}} variant="h6">
              Opening Date :{' '}
              <Moment date={state.selectedPricingRequest.common.accountOpenDate} format={DATE_FORMAT}/>
            </Typography>
          )}
        </BoxHeader>
        {summary && (
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <LabelText
                data-testid="test-serviceModel"
                label="Service Model"
                name="serviceModel"
                text={summary.serviceModel?.code !== EAM_EFA_MANAGED && summary.serviceModel?.desc}
              />
              <LabelText
                data-testid="test-mandateType"
                label="Mandate Type"
                name="mandateType"
                text={summary.mandateType}
              />
              <LabelSelect
                data-testid="test-pricingModel"
                infoText={SUMMARY_PRICE_MODEL_INFO_TEXT}
                label="Price Model"
                name="pricingModelId"
                onChange={e => {
                  setInflightPricingModelId(e.target.value);
                  setConfirmationDialogOpen(true);
                }}
                options={summary.pricingModels?.map(items => ({
                  key: items.pricingModelId,
                  desc: items.pricingModel
                }))}
                readOnly={readOnly}
                value={summary.pricingModelId}
              />
              {summary.pricingAgreement && summary.pricingAgreement?.desc !== NOT_APPLICABLE.desc &&
                <LabelText
                  data-testid="test-pricingAgreement"
                  label="Pricing Convention"
                  name="pricingAgreement"
                  text={summary.pricingAgreement?.desc}
                />}
              <LabelInput
                indent={0}
                infoText={SUMMARY_TOTAL_ASSET_INFO_TEXT}
                label="Total Assets"
                readOnly
                value={totalAsset}
              />
              <LabelInput
                disableMax
                editIcon={!readOnly}
                indent={5}
                label="Invested Assets"
                onReset={resetSummaryInvestedAsset}
                onValueChange={e => handleSummary({target: {name: 'investedAsset', value: e.value}})}
                readOnly={readOnly}
                resetIcon={!readOnly}
                value={summary.investedAsset}
              />
              <LabelInput
                disableMax
                editIcon={!readOnly}
                indent={5}
                label="Cash"
                onReset={resetSummaryCash}
                onValueChange={e => handleSummary({target: {name: 'cash', value: e.value}})}
                readOnly={readOnly}
                resetIcon={!readOnly}
                value={summary.cash}
              />
              <LabelInput indent={0} label="Negative Positions" readOnly value={summary.negativePosition}/>

              {summary?.portfolioReversionDate &&
              <LabelText
                data-testid="test-portfolioReversionDate"
                label="Portfolio Reversion Date"
                name="portfolioReversionDate"
                text={summary?.portfolioReversionDate ? moment(summary?.portfolioReversionDate).format(DATE_FORMAT) : ''}
              />}
            </Grid>
            <Grid item md={6} xs={12}>
              {parentState.accountSearchType === EAM_AGENT_CODE && !readOnly && <CopyEamPortfolio/>}
            </Grid>
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={12}>
              <BoxHeader text={'Others'}/>
            </Grid>
            <Grid item md={6} style={{backgroundColor: FORM_BACKGROUND_COLOR}} xs={12}>
              <DiscountReason
                discountReasons={summary.discountReasons}
                max={3}
                name="discountReasons"
                onChange={handleSummary}
                onValidationResultChange={vr =>
                  handleValidationResultChange({...vr, selectors: ['discountReasons']})
                }
                readOnly={readOnly}
              />
              <ApprovalReason approvalData={state.selectedPricingRequest.common?.approvalStatus}/>
            </Grid>
            <Grid item md={6} style={{paddingLeft: '20px', backgroundColor: FORM_BACKGROUND_COLOR}} xs={12}>
              <Typography display="block" variant="h6">
                Remarks for approvers
              </Typography>
              <TextareaAutosize
                data-testid="test-additionalRemarks"
                maxLength={TEXT_AREA_MAX_LENGTH}
                name="additionalRemarks"
                onBlur={e => handleSummary(e)}
                onChange={handleAdditionalRemarks}
                readOnly={readOnly}
                rowsMax={10}
                rowsMin={10}
                style={{width: '98%'}}
                value={additionalRemarks}
              />

              <DocumentUpload
                disabled={readOnly}
                onFilesUploaded={reloadSupportingDocuments}
                portfolioNumber={state.selectedPricingRequest?.common?.portfolioNumber}
                pricingRequestId={state.selectedPricingRequest?.common?.pricingRequestId}
                supportingDocuments={state.selectedPricingRequest?.common?.supportingDocuments}
              />
              <Typography display="block" variant="h6">
                Contact Report
              </Typography>
              <FormControl error={contactReportFormatErr}>
                <TextField
                  data-testid="test-contactReportNumber"
                  disabled={readOnly}
                  fullWidth
                  name="contactReportNumber"
                  onBlur={checkContactReportFormat}
                  onChange={handleContactReportNumber}
                  style={{backgroundColor : `${contactReportFormatErr ? ERROR_BACKGROUND_SECONDARY_COLOR : CLIPP_WHITE}`}}
                  value={contactReportNumber}
                />
                {contactReportFormatErr && <FormHelperText>Invalid Contact Report. (Valid formats : File name should have at least one character and must not start with a space)</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Paper>
    </div>
  );
};

Summary.propTypes = {
  readOnly: PropTypes.bool
};

export default Summary;
