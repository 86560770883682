import {
  LOAD_PAYMENT_APPLICABLE_FEES_RESPONSE,
  UPDATE_PAYMENT_FEE_AGREEMENT_TYPE,
  UPDATE_PAYMENT_FEE_CONDITION
} from './paymentFeesActions';
import {LOAD_PRICING_REQUEST_RESPONSE} from '../pricingRequestActions';

export const paymentFeesReducer = (state, action) => {
  switch (action.type) {
    case LOAD_PAYMENT_APPLICABLE_FEES_RESPONSE: {
      return {
        ...state,
        applicableFees: action.payload.data
      };
    }
    case LOAD_PRICING_REQUEST_RESPONSE: {
      if (!action.payload.data.paymentFees) {
        return null;
      }

      return {
        newConditionCodeFees: null,
        currentConditionCodeFees: null,
        ...action.payload.data.paymentFees,
        applicableFees: {...state?.applicableFees},
        errors: []
      };
    }
    case UPDATE_PAYMENT_FEE_CONDITION: {
      const newPaymentFeeCondition = action.data;
      const newConditionCodeFees = newPaymentFeeCondition === 'Standard' ? state.applicableFees.standard : state.applicableFees.exempted;
      return {
        ...state,
        newConditionCodeFees: newConditionCodeFees,
        newPaymentFeeCondition: newPaymentFeeCondition
      }
    }
    case UPDATE_PAYMENT_FEE_AGREEMENT_TYPE: {
      const pricingAgreedWithClient = action.data;
      return {
        ...state,
        pricingAgreedWithClient
      }
    }
    default:
      return state;
  }
}
