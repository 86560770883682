import {Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import api from '../../../../../api/api';
import {SELECT_PORTFOLIOS} from '../../../../../common/actionTypes';
import {validateSummary} from '../../../../../common/validateSummary';
import {getErrorMessage} from '../../../../../common/getErrorMessage';
import {returnToHome, setIsLoading} from '../../../../../Services';
import {Store} from '../../../../../Store';
import {PriceRequestStore} from '../../../../PriceSimulation/PriceSimulationStore';
import {saveAndValidatePricingRequest} from '../../../../PriceSimulation/pricingRequestService';
import {useNotification} from '../../Notification';
import SpecialRequestDialog from '../../SpecialRequestDialog';
import CommonDialog from '../../Dialog/CommonDialog';
import {isAcknowledgementRequired} from '../../../../../common/isAcknowledgementRequired';
import {ACKNOWLEDGEMENT_LINK} from '../../../../../common/constants';

const ExceptionRequestButton = ({handleServiceModelChanged, ...other}) => {
  const notification = useNotification();
  const {state, dispatch} = useContext(PriceRequestStore);
  const {parentState, parentDispatch} = useContext(Store);
  const history = useHistory();
  const [isConditionDialogOpen, setIsConditionDialogOpen] = useState(false);

  const [specialRequestDialogOpen, setSpecialRequestDialogOpen] = useState(false);

  const handleError = err => {
    notification.error(getErrorMessage(err));
    setIsLoading(false, parentDispatch);
  }



  const handleExceptionRequestButton = async () => {
    try {
      const portfoliosWithInvalidServiceModel = await saveAndValidatePricingRequest(
        parentState,
        state,
        dispatch
      );

      if (portfoliosWithInvalidServiceModel?.length > 0) {
        handleServiceModelChanged(portfoliosWithInvalidServiceModel);
        handleError(new Error('Service model changed'));
      }

      const portfoliosForSubmission = parentState.portfolios.filter(portfolio =>
        state.selectedPricingRequest.common.portfolioNumbersForSubmission?.some(
          portfolioNumberForSubmission => portfolioNumberForSubmission === portfolio.portfolioNumber
        )
      );

      const pricingRequestIds = portfoliosForSubmission.map(portfolio => portfolio.pricingRequestId);
      const {invalidPricingRequestIds} = await validateSummary(pricingRequestIds);
      if (invalidPricingRequestIds.length > 0) {
        history.push(`/simulation/${invalidPricingRequestIds[0].pricingRequestId}`);
        notification.error(invalidPricingRequestIds[0].errorMessage);
      } else {
        if(isAcknowledgementRequired(state)){
          setIsConditionDialogOpen(true);
        }else{
          setSpecialRequestDialogOpen(true);
        }
      }
      setIsLoading(false, parentDispatch);
    } catch (err) {
      handleError(err);
    }
  };

  const continueWithSpecialRequest = async () => {
    const portfoliosForSpecialRequest = parentState.portfolios.filter(portfolio =>
      state.selectedPricingRequest.common.portfolioNumbersForSubmission?.some(
        portfolioNumberForSubmission => portfolioNumberForSubmission === portfolio.portfolioNumber
      )
    );

    try {
      await Promise.all(
        portfoliosForSpecialRequest.map(portfolioForSpecialRequest =>
          api.specialPricingRequest.submit(portfolioForSpecialRequest.pricingRequestId)
        )
      );

      const remainingPortfolios = parentState.portfolios.filter(
        portfolio =>
          !portfoliosForSpecialRequest.some(
            portfolioForSpecialRequest =>
              portfolioForSpecialRequest.pricingRequestId === portfolio.pricingRequestId
          )
      );
      parentDispatch({type: SELECT_PORTFOLIOS, payload: remainingPortfolios});

      setSpecialRequestDialogOpen(false);
      notification.success('Exception request submitted to PM');
      setIsLoading(false, parentDispatch);

      /**
      * - If there are remaining portfolios, go to main page.
      * - If it's the only one, go to home page.
      */
      if (remainingPortfolios.length > 0) {
        history.push(`/simulation/${remainingPortfolios[0].pricingRequestId}`);
      } else {
        returnToHome(history, parentDispatch);
      }
    } catch (err) {
      notification.error('Failed submitting exception request\n' + getErrorMessage(err));
      setIsLoading(false, parentDispatch);
    }
  };

  return (
    <React.Fragment>
      <CommonDialog
        link =  {ACKNOWLEDGEMENT_LINK}
        onClose={() => setIsConditionDialogOpen(false)}
        onContinue={() => {
          setSpecialRequestDialogOpen(true);
          setIsConditionDialogOpen(false);
        }}
        open={isConditionDialogOpen}
        text="Please note that the effective date of pricing conditions and fee charges are determined by the approval date in CliPP."
        title="ACKNOWLEDGEMENT"
      />
      <SpecialRequestDialog
        onClose={() => setSpecialRequestDialogOpen(false)}
        onContinue={continueWithSpecialRequest}
        open={specialRequestDialogOpen}
        specialRequestPortfolioNumbers={state.selectedPricingRequest.common?.portfolioNumbersForSubmission}
      />
      <Button color="primary" onClick={handleExceptionRequestButton} {...other}>
        Send to Pricing Team
      </Button>
    </React.Fragment>
  );
};

ExceptionRequestButton.propTypes = {
  handleServiceModelChanged: PropTypes.func
}

export default ExceptionRequestButton;
