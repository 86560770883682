import React from 'react';
import {
  FLAT_RATE,
  NON_STANDARD_CUMULATIVE,
  NON_STANDARD_NON_CUMULATIVE
} from '../../../../../../common/enums/transactionFeeCondition';
import PriceNumberFormat from '../../../../../Common/components/PriceNumberFormat';
import {BPS} from '../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';


const LABEL = 'Fee per Transaction';
const KEY_PREFIX = 'transaction-fees-fee-per-transaction';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const feePerTransaction = ({newConditionCode, subFund, handleValidationResultChange, handleValueChange, readOnly, specialRequest}) => {

  let result = {
    label: LABEL
  };

  if (subFund.currentCondition?.feePerTransaction) {
    result = {
      ...result,
      currentCondition: getMappedOldCondition(subFund.currentCondition?.feePerTransaction)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(subFund.newCondition?.feePerTransaction, newConditionCode, handleValidationResultChange, handleValueChange, readOnly, specialRequest)
  }

};

const getMappedOldCondition = (feePerTransaction) => {
  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={BPS}
      value={feePerTransaction?.effectivePrice}
    />);

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (feePerTransaction, conditionCode, handleValidationResultChange, handleValueChange, readOnly, specialRequest) => {
  const isEditable = conditionCode === FLAT_RATE.code && !readOnly;
  const isDisabled = [NON_STANDARD_CUMULATIVE.code, NON_STANDARD_NON_CUMULATIVE.code].includes(conditionCode);

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      key={`${KEY_PREFIX}-list-price`}
      type={BPS}
      value={feePerTransaction?.listPrice}
    />);

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={BPS}
      value={feePerTransaction?.effectivePrice}
    />);

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-editable`}
      disableEmptyInput
      isEditable
      key={`${KEY_PREFIX}-effective-price-editable`}
      max={specialRequest ? null : feePerTransaction?.listPrice}
      onValidationResultChange={(vr) => handleValidationResultChange({...vr, selectors: ['newCondition', 'feePerTransaction', 'effectivePrice']})}
      onValueChange={(vr) => handleValueChange({...vr, selectors: ['newCondition', 'feePerTransaction', 'effectivePrice']})}
      type={BPS}
      value={feePerTransaction?.effectivePrice}
    />);

  return {
    effectivePrice: isEditable ? effectivePriceEditable : effectivePriceReadonly,
    isEditable: isEditable,
    listPrice: listPrice,
    isDisabled: isDisabled,
  };
};



