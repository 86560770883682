import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';
import { StoreProvider } from './Store';

window.onbeforeunload = () => {
  sessionStorage.clear();
};

if (process.env.NODE_ENV === 'development') {
  require('./mocks/browser');
}

ReactDOM.render(
  <StoreProvider>
    <App />
  </StoreProvider>,
  document.getElementById('root')
);

if (process.env.NODE_ENV !== 'development') {
  serviceWorker.unregister();
}
