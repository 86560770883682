import React, {useState} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {CHF} from '../../PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../PriceNumberFormat';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';
import {getFormLabel} from '../formLabel';
import {getFormText} from '../formText';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import {FORM_BACKGROUND_COLOR, ICON_BUTTON_COLOR} from '../../../../../theme/common/color';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import HoverInfo from '../../HoverInfo';

const useStyles = makeStyles((theme) => ({
  rootForEditableField: {
    backgroundColor: FORM_BACKGROUND_COLOR
  },
  label: {
    textIndent: indent => (indent * 2) + 'px',
    ...getFormLabel()
  },
  text: {
    ...getFormText()
  },
  icon: {
    color: ICON_BUTTON_COLOR,
  },
  iconContainer: {
    margin: '-12px'
  },
  valueSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  infoIcon: {
    marginLeft: theme.spacing(0.5)
  }
}));

const LabelInput = ({editIcon, editProps, indent = 0, infoText, label, onReset, onValidationResultChange, onValueChange, readOnly, resetIcon, resetProps, type, value, ...props}) => {
  const classes = useStyles(indent);
  const [isEditable, setEditable] = useState(false);
  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(false);

  const handleReset = async () => {
    setIsResetButtonDisabled(true);
    try {
      await onReset();
      setIsResetButtonDisabled(false);
    } catch {
      setIsResetButtonDisabled(false);
    }
  }

  return (
    <Grid
      className={readOnly ? '' : classes.rootForEditableField}
      container
      spacing={1}
    >
      <Grid
        className={classes.label}
        item
        xs={4}
      >
        {label}
        {infoText && <HoverInfo text={infoText}><InfoIcon className={classes.infoIcon} fontSize="small"/> </HoverInfo>}
      </Grid>
      <Grid
        className={classes.valueSection}
        item
        xs={8}
      >
        <PriceNumberFormat
          className={classes.text}
          isEditable={!readOnly && isEditable}
          onValidationResultChange={onValidationResultChange}
          onValueChange={onValueChange}
          type={type || CHF}
          value={value}
          {...props}
        />
        {
          editIcon &&
          <Box className={classes.iconContainer}>
            <IconButton aria-label="edit" onClick={() => setEditable(!isEditable)} {...editProps}>
              <EditOutlinedIcon className={classes.icon}/>
            </IconButton>
          </Box>
        }
        {
          resetIcon &&
          <Box className={classes.iconContainer}>
            <IconButton aria-label="reset" disabled={isResetButtonDisabled} onClick={handleReset} {...resetProps}>
              <RotateLeftOutlinedIcon className={classes.icon}/>
            </IconButton>
          </Box>
        }
      </Grid>
    </Grid>
  )
};

LabelInput.propTypes = {
  defaultValue: PropTypes.number,
  editIcon: PropTypes.bool,
  editProps: PropTypes.object,
  indent: PropTypes.any,
  infoText: PropTypes.string,
  label: PropTypes.any,
  onReset: PropTypes.func,
  onValidationResultChange: PropTypes.func,
  onValueChange: PropTypes.func,
  readOnly: PropTypes.bool,
  resetIcon: PropTypes.bool,
  resetProps: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.number
};

export default LabelInput;
