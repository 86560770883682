import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {useExpanded, useFilters, useGroupBy, useTable} from 'react-table';
import {
  BJB_GREEN_SMOKE_20,
  CLIPP_LIGHT_BLUE,
  CLIPP_LIGHT_GREY,
  CLIPP_WHITE
} from '../../../../../../theme/common/bjbColor';
import {
  TABLE_APPROVAL_STATUS_BORDER_COLOR,
  TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR
} from '../../../../../../theme/common/color';
import PaginationControl from '../../../../../Common/components/PaginationControl';
import {columnsData} from './columnsData';
import PricingCustomCell from './components/PricingCustomCell';

const useStyles = makeStyles(theme => ({
  inner: {
    maxWidth: '1500px',
    overflowX: 'auto'
  },
  table: {
    border: '1px solid',
    borderColor: TABLE_APPROVAL_STATUS_BORDER_COLOR
  },
  tableRowBodyGroup: {
    backgroundColor: CLIPP_LIGHT_BLUE
  },
  tableRowEven: {
    backgroundColor: BJB_GREEN_SMOKE_20
  },
  sortIcon: {
    fontSize: '12px'
  },
  tableCellHeader: {
    backgroundColor: TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR,
    color: CLIPP_WHITE,
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '20px',
    minWidth: '100px',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  tableCellHeaderContent: {
    display: 'flex',
    alignItems: 'center'
  },
  tableCellBody: {
    border: 'none',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '25px',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  tableCellBodyGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  tableCellBodyExpanded: {
    padding: 0,
    borderBottom: 'solid 1px',
    borderBottomColor: CLIPP_LIGHT_GREY
  },
  selectedRow: {
    backgroundColor: CLIPP_LIGHT_BLUE,
    color: 'red'
  }
}));

const PricingRequestTable = ({fetchData, filterByPricingRequestStatus, filterByT24Status, goToPage, paginationInfo, pricingRequestData}) => {
  const tableColumns = columnsData.map(colData => {
    return {
      accessor: colData.key,
      Header: colData.label,
      Cell: PricingCustomCell
    };
  });

  const classes = useStyles();

  const memoizedData = useMemo(() => pricingRequestData, [pricingRequestData]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedColumns = useMemo(() => tableColumns, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    rows
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      fetchData,
      filterByPricingRequestStatus,
      filterByT24Status,
    },
    useFilters,
    useGroupBy,
    useExpanded
  );

  return (
    <React.Fragment>
      <div className={classes.inner}>
        <Table size="small" {...getTableProps()} className={classes.table}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...column.getHeaderProps()}
                    className={classes.tableCellHeader}
                  >
                    <div className={classes.tableCellHeaderContent}>
                      {column.render('Header')}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                const isEven = i % 2 !== 0;
                prepareRow(row);

                return (
                  <React.Fragment key={i}>
                    <TableRow
                      {...row.getRowProps()}
                      className={isEven ? classes.tableRowEven : null}
                    >
                      {row.cells.map(cell => (
                        <TableCell
                          {...cell.getCellProps()}
                          className={classes.tableCellBody}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={visibleColumns.length}>
                  No entry found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {paginationInfo && (
        <PaginationControl goToPage={goToPage} paginationInfo={paginationInfo}/>
      )}
    </React.Fragment>
  );
};

PricingRequestTable.propTypes = {
  fetchData: PropTypes.func,
  filterByPricingRequestStatus: PropTypes.string,
  filterByT24Status: PropTypes.string,
  goToPage: PropTypes.func,
  paginationInfo: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
    numberOfElements: PropTypes.number
  }),
  pricingRequestData: PropTypes.any
};

export default PricingRequestTable;
