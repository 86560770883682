import React from 'react';
import {CHF, PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {isNewBankingFeeDifferent} from '../isNewBankingFeeDifferent';

const LABEL = 'Total Fees';
const KEY_PREFIX = 'banking-fees-total-fees';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const totalFees = ({fee, showDiff}) => {

  let result = {
    label: LABEL
  };

  let isNewValueDifferent = isNewBankingFeeDifferent(fee, 'BANKING_SERVICE_TOTAL_FEE');

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (fee?.currentConditionCodeFees?.BANKING_SERVICE_TOTAL_FEE) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(fee?.currentConditionCodeFees?.BANKING_SERVICE_TOTAL_FEE)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(fee?.newConditionCodeFees?.BANKING_SERVICE_TOTAL_FEE, isNewValueDifferent)
  }

};

const getMappedOldCondition = (fee) => {

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (fee, isNewValueDifferent) => {

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      type={CHF}
      value={fee?.listPrice}
    />
  );

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      decimal={0}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  const discount = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-discount`}
      type={PERCENTAGE}
      value={fee?.discountPercentage}
    />
  );

  return {
    discount: discount,
    effectivePrice: effectivePriceReadonly,
    isHighlighted: isNewValueDifferent,
    listPrice: listPrice
  };
};
