const MuiSelect = {
  root: {
    color: 'inherit',
    backgroundColor: 'transparent',
    border: 'none',
  },
  select: {
    paddingLeft: '5px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
};

export default MuiSelect;
