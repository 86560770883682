import React from 'react';
import PriceConditionTableColumn from './PriceConditionTableColumn';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../common/constants';
import {EDITABLE, TEXT, TEXT_LEFT} from './PriceConditionType';
import NMBFeePricingType from '../../../../views/PriceSimulation/components/NMBFees/panel/NMBFeePricingType';
import PriceConditionTableRowSection from './PriceConditionTableRowSection';
import PropTypes from 'prop-types';

const PriceConditionPricingTypeRow = ({ newConditionLabel, showOldCondition, showNewCondition, oldConditionValue, newConditionValue, onChange, readOnly, columnProps }) => {
  if(showNewCondition || showOldCondition) {
    return <PriceConditionTableRowSection data={[]}>
      {showOldCondition && <>
        <PriceConditionTableColumn
          field="label"
          label={newConditionLabel}
          type={TEXT_LEFT}
        />
        <PriceConditionTableColumn
          borderRight
          customStyles={OLD_CONDITION_CUSTOM_STYLE}
          field="label"
          label={oldConditionValue}
          type={TEXT}
        /></>}
      {!showOldCondition && <PriceConditionTableColumn field="label"/>}
      {showNewCondition && <><PriceConditionTableColumn field="label" label={`${!readOnly && !showOldCondition ? newConditionLabel : ''}`} type={TEXT}/>
        <PriceConditionTableColumn
          field="label"
          label={<NMBFeePricingType onPricingTypeChange={onChange} pricingType={newConditionValue} readOnly={readOnly}/>}
          type={!readOnly ? EDITABLE : ''}
          {...columnProps}
        /></>}
    </PriceConditionTableRowSection>
  }

  return null;
}

PriceConditionPricingTypeRow.propTypes = {
  columnProps: PropTypes.object,
  newConditionLabel: PropTypes.string,
  newConditionValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  oldConditionValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showNewCondition: PropTypes.bool.isRequired,
  showOldCondition: PropTypes.bool.isRequired
};

export default PriceConditionPricingTypeRow;
