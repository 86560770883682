import { jbColors } from '../jbColors';

const MuiTabs = {
  root: {
    paddingBottom: '2px', // To align with the PageTitle component
    borderBottom: `0.2em solid ${jbColors.royalBlue[100]}`,
  },
  indicator: {
    display: 'none',
  },
};

export default MuiTabs;
