export const NMB_BONDS = Object.freeze({
  code: 'NMB_BONDS',
  desc: 'BONDS',
  sortOrder: 0
});
export const INVESTMENT_FUNDS_SECONDARY_MKT = Object.freeze({
  code: 'INVESTMENT_FUNDS_SECONDARY_MKT',
  desc: 'INVESTMENT FUNDS - SECONDARY MARKET',
  sortOrder: 1
});
export const STRUCTURED_PRODUCTS = Object.freeze({
  code: 'STRUCTURED_PRODUCTS',
  desc: 'STRUCTURED PRODUCTS',
  sortOrder: 2
});
export const INTEREST_RATE_SWAPS = Object.freeze({
  code: 'INTEREST_RATE_SWAPS',
  desc: 'INTEREST RATE SWAPS',
  sortOrder: 3
});

export const getNMBFeeFundTypeFromCode = code => {
  return getAllNMBFeeFundTypes().filter(condition => condition.code === code)[0];
};

export const getNMBFeeFundTypeDescriptionFromCode = code => {
  return getAllNMBFeeFundTypes()
    .filter(condition => condition.code === code)
    .map(condition => condition.desc)[0];
};

export const getNMBFeeFundTypeFromDescription = description => {
  const filtered = getAllNMBFeeFundTypes().filter(condition => condition.desc === description);
  if(filtered){
    return filtered[0];
  }
  return {};
}

export const getAllNMBFeeFundTypes = () => {
  return [
    NMB_BONDS,
    INVESTMENT_FUNDS_SECONDARY_MKT,
    STRUCTURED_PRODUCTS,
    INTEREST_RATE_SWAPS,
  ];
};
