import React from 'react';
import {CHF, PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {DPM} from '../../../../../common/enums/serviceModel';
import {hasDifference} from '../../../../../common/compareUtil';

const LABEL = 'Banking Service Package';
const KEY_PREFIX = 'banking-fees-service-package';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const bankingServicePackage = ({fee, serviceModelCode, handleValidationResultChange, handleBankingServiceFee, readOnly, specialRequest, showDiff}) => {
  if (serviceModelCode === DPM) {
    return null;
  }

  let result = {
    label: LABEL
  };

  let isNewValueDifferent = !!readOnly && hasDifference(fee?.currentConditionCodeFees?.BANKING_SERVICE_FEE,
    fee?.newConditionCodeFees?.BANKING_SERVICE_FEE,
    fee?.currentConditionCodeFees);

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (fee?.currentConditionCodeFees?.BANKING_SERVICE_FEE) {
    // console.log("Inside currentConditionCodeFees of BANKING_SERVICE_FEE -------------------");
    result = {
      ...result,
      oldCondition: getMappedOldCondition(fee?.currentConditionCodeFees?.BANKING_SERVICE_FEE)
    }
  }

  if (fee?.newConditionCodeFees?.BANKING_SERVICE_FEE) {
    // console.log("Inside newConditionCodeFees of BANKING_SERVICE_FEE -------------------");
    result = {
      ...result,
      newCondition: getMappedNewCondition(fee?.newConditionCodeFees?.BANKING_SERVICE_FEE, handleValidationResultChange, handleBankingServiceFee, readOnly, specialRequest, isNewValueDifferent)
    }
  }

  return{ ...result}

};

const getMappedOldCondition = (fee) => {

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (fee, handleValidationResultChange, handleBankingServiceFee, readOnly, specialRequest, isNewValueDifferent) => {

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      type={CHF}
      value={fee?.listPrice}
    />
  );

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-editable`}
      decimal={0}
      disableEmptyInput
      isEditable={!readOnly}
      max={specialRequest ? null : fee?.listPrice}
      onValidationResultChange={({isValid}) => handleValidationResultChange && handleValidationResultChange('BANKING_SERVICE_FEE', isValid)}
      onValueChange={({value, isValid}) => handleValidationResultChange && handleBankingServiceFee(value, isValid)}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      decimal={0}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  const discount = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-discount`}
      type={PERCENTAGE}
      value={fee?.discountPercentage}
    />
  );

  return {
    discount: discount,
    effectivePrice: readOnly ? effectivePriceReadonly : effectivePriceEditable,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
    listPrice: listPrice
  };
};
