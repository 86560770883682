import React from 'react';
import {CHF, PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {isNewBankingFeeDifferent} from '../isNewBankingFeeDifferent';
import {BANKING_REPORTING_FEE_INFO_TEXT} from '../../../../../common/enums/infoTexts';

const LABEL = 'Reporting Fee';
const KEY_PREFIX = 'banking-fees-reporting-fee';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const reportingFee = ({fee, handleValidationResultChange, handleSpecialReportingFee, readOnly, specialRequest, showDiff}) => {

  let result = {
    label: LABEL,
    infoText: BANKING_REPORTING_FEE_INFO_TEXT
  };

  let isNewValueDifferent = !!readOnly && isNewBankingFeeDifferent(fee, 'REPORTING_FEE');

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (fee?.currentConditionCodeFees?.REPORTING_FEE) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(fee?.currentConditionCodeFees?.REPORTING_FEE)
    }
  }
  if (fee?.newConditionCodeFees?.REPORTING_FEE) {
    result = {
      ...result,
      newCondition: getMappedNewCondition(fee?.newConditionCodeFees?.REPORTING_FEE, handleValidationResultChange, handleSpecialReportingFee, readOnly, specialRequest, isNewValueDifferent)
    }
  }

  return{ ...result}

};

const getMappedOldCondition = (fee) => {

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (fee, handleValidationResultChange, handleSpecialReportingFee, readOnly, specialRequest, isNewValueDifferent) => {

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      type={CHF}
      value={fee?.listPrice}
    />
  );

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-editable`}
      decimal={0}
      disableEmptyInput
      isEditable={!readOnly}
      max={specialRequest ? null : fee?.listPrice}
      onValidationResultChange={({isValid}) => handleValidationResultChange && handleValidationResultChange('REPORTING_FEE', isValid)}
      onValueChange={({value, isValid}) => handleValidationResultChange && handleSpecialReportingFee(value, isValid)}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      decimal={0}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  const discount = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-discount`}
      type={PERCENTAGE}
      value={fee?.discountPercentage}
    />
  );

  return {
    discount: discount,
    effectivePrice: readOnly ? effectivePriceReadonly : effectivePriceEditable,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
    listPrice: listPrice
  };
};
