import React, {useContext, useEffect, useState} from 'react';
import {AccountGroupSimulationImpact, AccountLevelSimulationImpact, CurrentKpi} from './components';
import {Box, Grid} from '@material-ui/core';
import {BoxHeader} from '../Common/components';
import api from '../../api/api';
import {Store} from '../../Store';
import {useNotification} from '../Common/components/Notification';
import LoadingSpinner from '../Common/components/LoadingSpinner';
import {useHistory} from 'react-router-dom';
import {getErrorMessage} from '../../common/getErrorMessage';
import {returnToHome} from '../../Services';

const Overview = () => {
  const history = useHistory();
  const {parentState, parentDispatch} = useContext(Store);
  const [data, setData] = useState();
  const notification = useNotification();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (parentState?.customer?.accountNumber) {
      const selectedPricingRequestIds = parentState.portfolios?.map(
        portfolio => portfolio.pricingRequestId
      );

      api.customer
        .getOverview(parentState.customer.accountNumber, selectedPricingRequestIds)
        .then(response => {
          if (response.data.gmisError?.length > 0) {
            const warningMessage = getErrorMessage({response, message: response.data.gmisError.join('\n')})
            notification.warning(warningMessage);
            console.warn('GMIS Warning ', warningMessage);
          }
          setData(response.data);
          setIsLoading(false);
        })
        .catch(err => {
          notification.error('Unable to get overview data\n' + getErrorMessage(err))
        });
    } else {
      returnToHome(history, parentDispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentState.customer?.accountNumber]);

  if (isLoading) {
    return <LoadingSpinner/>;
  }

  return (
    <Box padding={4}>
      <BoxHeader text={'Account Group Level Overview'}/>
      {data?.accountGroupOverview ? (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
          <Grid item lg={5} xs={12}>
            <CurrentKpi
              data={data?.accountGroupOverview?.currentKpiAccGrp}
              title="Account Group Level"
            />
          </Grid>
          <Grid item lg={7} xs={12}>
            <AccountGroupSimulationImpact data={data?.accountGroupOverview?.simulationImpactAccGrp}/>
          </Grid>
        </Grid>
      ) : (
        <Box marginBottom={4}>Data is not available</Box>
      )}
      <BoxHeader text={'Account Level Overview'}/>
      {data?.accountLevelOveriew ? (
        <Grid container spacing={1}>
          <Grid item lg={5} xs={12}>
            <CurrentKpi
              data={data?.accountLevelOveriew?.currentKpiAccLevel}
              style={{width: '100%'}}
              title="Account Level"
            />
          </Grid>
          <Grid item lg={7} xs={12}>
            <AccountLevelSimulationImpact data={data?.accountLevelOveriew?.simulationImpactAccLevel}/>
          </Grid>
        </Grid>
      ) : (
        <Box>Data is not available</Box>
      )}
    </Box>
  );
};

export default Overview;
