import {SELECT_TAB_INDEX} from './selectedTabActions';

export const selectedTabIndexReducer = (state, action) => {
  switch (action.type) {
    case SELECT_TAB_INDEX: {
      return action.payload;
    }
    default:
      return state;
  }
}
