import React from 'react';
import {Grid, makeStyles, TextareaAutosize} from '@material-ui/core';
import PropTypes from 'prop-types';
import {getFormLabel} from '../formLabel';
import {getFormText} from '../formText';
import {FORM_BACKGROUND_COLOR} from '../../../../../theme/common/color';

const useStyles = makeStyles(() => ({
  rootForEditableField: {
    backgroundColor: FORM_BACKGROUND_COLOR
  },
  label: {
    ...getFormLabel()
  },
  text: {
    ...getFormText()
  }
}));

const LabelTextAreaAutosize = ({name, label, text, rowsMax, rowsMin, onChange, onBlur, ...others}) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.rootForEditableField}
      container
      spacing={1}
    >
      <Grid
        className={classes.label}
        item
        xs={4}
      >{label}</Grid>

      <Grid
        className={classes.text}
        item
        xs={8}
      >
        <TextareaAutosize
          data-testid="test-textArea-autosize"
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          rowsMax={rowsMax}
          rowsMin={rowsMin}
          value={text || ''}
          {...others}
        />
      </Grid>
    </Grid>
  );
};

LabelTextAreaAutosize.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  rowsMax: PropTypes.number.isRequired,
  rowsMin: PropTypes.number.isRequired,
  text: PropTypes.string,
};

export default LabelTextAreaAutosize;
