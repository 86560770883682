import React from 'react';
import {CHF, PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {BANKING_SPECIAL_MAILING_INFO_TEXT} from '../../../../../common/enums/infoTexts';
import {hasDifference} from '../../../../../common/compareUtil';

const LABEL = 'Special Mailing';
const KEY_PREFIX = 'banking-fees-special-mailing';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const specialMailing = ({fee, handleValidationResultChange, handleSpecialMailingServiceFee, readOnly, specialRequest, showDiff}) => {

  let result = {
    label: LABEL,
    infoText: BANKING_SPECIAL_MAILING_INFO_TEXT
  };

  let isNewValueDifferent = !!readOnly && hasDifference(fee?.currentConditionCodeFees?.SPECIAL_MAILING,
    fee?.newConditionCodeFees?.SPECIAL_MAILING,
    fee?.currentConditionCodeFees);

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (fee?.currentConditionCodeFees?.SPECIAL_MAILING) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(fee?.currentConditionCodeFees?.SPECIAL_MAILING)
    }
  }

  if(fee?.newConditionCodeFees?.SPECIAL_MAILING) {
    result = {
      ...result,
      newCondition: getMappedNewCondition(fee?.newConditionCodeFees?.SPECIAL_MAILING, handleValidationResultChange, handleSpecialMailingServiceFee, readOnly, specialRequest, isNewValueDifferent)
    }
  }

  return{ ...result}
};

const getMappedOldCondition = (fee) => {

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (fee, handleValidationResultChange, handleSpecialMailingServiceFee, readOnly, specialRequest, isNewValueDifferent) => {

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      type={CHF}
      value={fee?.listPrice}
    />
  );

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={readOnly ? `${TEST_KEY_PREFIX}-effective-price-readonly` : `${TEST_KEY_PREFIX}-effective-price-editable`}
      decimal={0}
      disableEmptyInput
      isEditable={!readOnly}
      max={specialRequest ? null : fee?.listPrice}
      onValidationResultChange={({isValid}) => handleValidationResultChange && handleValidationResultChange('SPECIAL_MAILING', isValid)}
      onValueChange={({value, isValid}) => handleSpecialMailingServiceFee && handleSpecialMailingServiceFee(value, isValid)}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      decimal={0}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  const discount = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-discount`}
      type={PERCENTAGE}
      value={fee?.discountPercentage}
    />
  );

  return {
    discount: discount,
    effectivePrice: readOnly ? effectivePriceReadonly : effectivePriceEditable,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
    listPrice: listPrice
  };
};
