import transitions from '@material-ui/core/styles/transitions';
import { jbColors } from '../jbColors';

const MuiTableSortLabel = {
  root: {
    width: '100%',
    justifyContent: 'space-between',
    '&:focus': {
      color: 'inherit',
      fontWeight: 'bold',
    },
    '&:hover': {
      color: 'inherit',
      '& $icon': {
        opacity: 'initial',
      },
    },
    '&$active': {
      fontWeight: 'bold',
      color: jbColors.reflexBlue[100],
      '&& $icon': {
        opacity: 'initial',
        color: 'inherit',
      },
      '&& $icon .activeArrow': {
        fill: jbColors.reflexBlue[100],
      },
    },
  },
  icon: {
    opacity: 'initial',
    transition: transitions.create(['transform'], {
      duration: transitions.duration.shorter,
    }),
  },
};

export default MuiTableSortLabel;
