import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import {Store} from '../../../../Store';
import {useNotification} from '../../../Common/components/Notification';
import Authorized from '../../../../components/Authorized';
import {
  getRequesterRoles,
  getResetRequesterRoles,
  getRolesPortfolioSubmitEnable
} from '../../../../common/enums/userRole';
import api from '../../../../api/api';
import ServiceModelChangeDialog from '../../../Common/components/ServiceModelChangeDialog';
import {SELECT_PORTFOLIOS} from '../../../../common/actionTypes';
import CancelRequestButton from '../../../Common/components/ActionButtons/CancelRequestButton';
import {getPortfoliosWithPortfolioDetails} from '../../../../common/getPortfoliosWithPortfolioDetails';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import {setIsLoading} from '../../../../Services';
import SaveButton from '../../../Common/components/ActionButtons/SaveButton';
import SpecialRequestButton from '../../../Common/components/ActionButtons/SpecialRequestButton';
import SubmitButton from '../../../Common/components/ActionButtons/SubmitButton';
import {PriceRequestStore} from '../../PriceSimulationStore';
import {PRIMARY_BACKUP_RM_ROLE} from '../../../../common/enums/accessRole';
import ResetRequestButton from '../../../Common/components/ActionButtons/ResetRequestButton';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0.5)
    },
    float: 'right',
    paddingBottom: '10px'
  }
}));

const TEST_PREFIX = 'test-action-button';

const ActionButtons = () => {
  const classes = useStyles();
  const {parentState, parentDispatch} = useContext(Store);
  const {state} = useContext(PriceRequestStore);
  const history = useHistory();
  const notification = useNotification();
  const [openServiceModelDialog, setOpenServiceModelDialog] = useState(false);
  const [invalidPortfolios, setInvalidPortfolios] = useState([]);

  const handleServiceModelChanged = portfoliosWithInvalidServiceModel => {
    setInvalidPortfolios(portfoliosWithInvalidServiceModel);
    setOpenServiceModelDialog(true);
  };

  const getPortfoliosToInitiate = () => {
    return parentState.portfolios.map(portfolio => {
      const invalidPortfolio = invalidPortfolios.find(d => d.portfolioNumber === portfolio.portfolioNumber);
      if (invalidPortfolio) {
        return {
          ...portfolio,
          serviceModelChanged: true,
          serviceModel: invalidPortfolio.newServiceModel,
          mandateType: invalidPortfolio.newMandateType
        };
      }
      return portfolio;
    });
  };

  const handleProceedToReinitiate = async () => {
    setIsLoading(true, parentDispatch);

    try {
      // Reinitiate pricing request
      const portfoliosToInitiate = getPortfoliosToInitiate();
      const response = await api.pricingRequest.initiate(portfoliosToInitiate);

      const responseDataWithAccountOpenDate = getPortfoliosWithPortfolioDetails(
        response.data,
        parentState.portfolios
      );
      parentDispatch({type: SELECT_PORTFOLIOS, payload: responseDataWithAccountOpenDate});
      history.push('/simulation/' + responseDataWithAccountOpenDate[0].pricingRequestId);
      setOpenServiceModelDialog(false);
      setIsLoading(false, parentDispatch);
    } catch (err) {
      notification.error('Failed trying to reinitate pricing request\n' + getErrorMessage(err));
      setIsLoading(false, parentDispatch);
      throw err;
    }
  };

  const isPrimaryBackupRM = state.selectedPricingRequest.common?.accessRoleType === PRIMARY_BACKUP_RM_ROLE;
  const customerLocation = parentState.customer?.accountNumber?.slice(0,2);
  const hasUserRoleAccess = !!parentState.user?.userRoles.
    find(userRole => (getRolesPortfolioSubmitEnable().includes(userRole.role)
                                                                            && userRole.location === customerLocation));
  const hasResetRoleAccess = parentState.user?.userRoles?.some((userRole) => getResetRequesterRoles().some((allowedRole) => allowedRole.code === userRole.role));

  return (
    <div className={classes.buttonGroup}>
      <ServiceModelChangeDialog
        onClose={() => setOpenServiceModelDialog(false)}
        onContinue={handleProceedToReinitiate}
        open={openServiceModelDialog}
        text={`The pricing request for ${invalidPortfolios
          .map(d => d.portfolioNumber)
          .join(
            ', '
          )} is not valid anymore due to a service model / mandate type changes in CRM. CliPP tool has defaulted this request to list price of the latest update. Please verify all fees before submission.`}
      />
      {(isPrimaryBackupRM || hasResetRoleAccess) && (
        <ResetRequestButton data-testid={`${TEST_PREFIX}-reset-request`} />
      )}
      <Authorized allowedRoles={getRequesterRoles()}>
        <SpecialRequestButton
          data-testid={`${TEST_PREFIX}-exception-request`}
          handleServiceModelChanged={handleServiceModelChanged}
        />
        <SaveButton data-testid={`${TEST_PREFIX}-save`}/>
      </Authorized>
      {(isPrimaryBackupRM || hasUserRoleAccess) && (
        <SubmitButton
          data-testid={`${TEST_PREFIX}-submit`}
          handleServiceModelChanged={handleServiceModelChanged}
        />
      )}
      <Authorized allowedRoles={getRequesterRoles()}>
        <CancelRequestButton data-testid={`${TEST_PREFIX}-cancel`}/>
      </Authorized>
    </div>
  );
};

export default ActionButtons;
