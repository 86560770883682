// as defined by business
export const CLIPP_WHITE = '#FFFFFF';
export const CLIPP_LIGHT_GREY = '#C6CBCB';
export const CLIPP_LIGHT_BLUE = '#CCD0E7';
export const CLIPP_DARK_BLUE = '#000E90';
export const CLIPP_BLACK = '#000000';

// as defined by BJB core palette
export const BJB_BLUE_100 = '#001589';
export const BJB_BLUE_80 = '#3343A1';
export const BJB_BLUE_60 = '#6672B8';
export const BJB_BLUE_40 = '#99A1D0';

export const BJB_STONE_100 = '#B0AA7E';
export const BJB_STONE_80 = '#C0BB98';
export const BJB_STONE_60 = '#D0CCB2';
export const BJB_STONE_40 = '#DFDDCB';
export const BJB_STONE_20 = '#EFEEE5';

export const BJB_GREEN_SMOKE_100 = '#717c7d';
export const BJB_GREEN_SMOKE_80 = '#8d9697';
export const BJB_GREEN_SMOKE_60 = '#aab0b1';
export const BJB_GREEN_SMOKE_40 = '#c6cbcb';
export const BJB_GREEN_SMOKE_20 = '#e3e5e5';
