import api from '../api/api';

const generateErrorMessage = (pricingRequestId, portfolioNumber, errorMessages) => {
  return `-- Pricing Request ID ${pricingRequestId} (${portfolioNumber}): ${errorMessages.reduce(
    (acc, value) => acc + '\n' + value.message,
    ''
  )}`;
};

export const validatePricingRequest = (pricingRequestIds, portfolioNumbers) => {
  return new Promise((resolve, reject) => {
    if (!pricingRequestIds) {
      reject();
    }

    Promise.all(
      pricingRequestIds.map(pricingRequestId => api.pricingRequest.validate(pricingRequestId))
    )
      .then(responses => {
        if (responses.reduce((acc, value) => acc && value.data?.valid, true)) {
          resolve(pricingRequestIds);
        } else {
          // Validation completed but result is not valid
          let errorMessages = [];
          responses.forEach((response, index) => {
            if (response.data?.validationIssues?.length > 0) {
              errorMessages.push(
                generateErrorMessage(pricingRequestIds[index], portfolioNumbers[index], response.data.validationIssues)
              );
            }
          });

          let errMessage = 'Validation failed but there is no error message';
          if (errorMessages.length > 0) {
            errMessage = errorMessages.join('\n');
          }
          reject({message: errMessage});
        }
      })
      .catch(err => {
        // Validation is not completed
        let errMessage = err.message;
        if (err.response.data?.message) {
          errMessage = err.response.data.message;
        }
        reject({message: errMessage});
      });
  });
};
