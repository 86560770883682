import {useContext} from 'react';
import {Store} from '../../Store';

const Authorized = ({children, allowedRoles}) => {
  const {parentState} = useContext(Store);
  const userRoles = parentState.user?.userRoles;
  const hasAccess = userRoles?.some((userRole) =>
    allowedRoles.some((allowedRole) => allowedRole.code === userRole.role)
  );

  if (hasAccess) {
    return children;
  }
  return null;
};

export default Authorized;
