import { alpha } from '@material-ui/core';
import { jbColors } from '../jbColors';
import typography from '../typography';

const MuiTableCell = {
  root: {
    ...typography.body1,
    padding: '7px',
    fontWeight: 300,
    borderBottom: `1px solid ${alpha(jbColors.black[20], 0.5)}`,
    maxWidth: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
  },
  head: {
    ...typography.body1,
    fontWeight: 300,
    padding: '8px 16px',
  },
  stickyHeader: {
    ...typography.body1,
    fontWeight: 300,
    padding: '8px 16px',
  },
  sizeSmall: {
    padding: '7px',
  },
};

export default MuiTableCell;
