export const T24_REQUEST_RESPONSE_COLUMN = {key: 't24RequestResponseId', label: 'ID'};
export const PRIMARY_MESSAGE_ID_COLUMN = {key: 'primaryMessageId', label: 'Primary Message ID'};
export const REQUEST_CONTENT_TYPE_COLUMN = {key: 'requestContentType', label: 'Request type'};
export const STATUS_COLUMN = {key: 'status', label: 'Status'};
export const REQUEST_BODY_COLUMN = {key: 'requestBody', label: 'Request Body'};
export const ENQUEUED_DATE_COLUMN = {key: 'enqueuedDate', label: 'Enqueued Date'};
export const ERROR_INFO_COLUMN = {key: 'errorInfo', label: 'Error Info'};
export const RESPONSE_BODY_COLUMN = {key: 'responseBody', label: 'Response Body'};
export const RECEIVED_DATE_COLUMN = {key: 'receivedDate', label: 'Received Date'};
export const CREATED_DATE_COLUMN = {key: 'createdDate', label: 'Created Date'};
export const UPDATED_DATE_COLUMN = {key: 'updatedDate', label: 'Update Date'};
export const REFERENCE_COLUMN = {key: 'reference', label: 'Reference'};

export const columnsData = [
  T24_REQUEST_RESPONSE_COLUMN,
  PRIMARY_MESSAGE_ID_COLUMN,
  REQUEST_CONTENT_TYPE_COLUMN,
  REFERENCE_COLUMN,
  STATUS_COLUMN,
  REQUEST_BODY_COLUMN,
  ENQUEUED_DATE_COLUMN,
  ERROR_INFO_COLUMN,
  RESPONSE_BODY_COLUMN,
  RECEIVED_DATE_COLUMN,
  CREATED_DATE_COLUMN,
  UPDATED_DATE_COLUMN,

];
