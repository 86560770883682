import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/styles';
import validator from '../../../../common/validator';
import {FormControl, TextField} from '@material-ui/core';
import {getDefaultFontSize} from '../../../../theme/common/font';
import FormHelperText from '@material-ui/core/FormHelperText';

export const REGULAR = 'REGULAR';
export const CHF = 'CHF';
export const BPS = 'bps';
export const PERCENTAGE = '%';

const useStyles = makeStyles((theme) => ({
  text: {
    ...getDefaultFontSize(theme)
  },
  textAlignRight: {
    textAlign: 'right'
  }
}));
const PriceNumberFormat = ({isEditable, max, disableMax, disableMin, disableEmptyInput, onValidationResultChange, onValueChange, placeHolderForUndefined, type, value, decimal, fixedDecimal, ...other}) => {

  const MAX_DIGIT = 15;
  const DECIMAL_REGULAR = 4;
  const DECIMAL_PERCENTAGE = 2;
  const DECIMAL_CHF = 2;
  const MAX_CHF_VALUE = 1000000.00;
  const MAX_BPS_VALUE = 10000;
  const MAX_PERCENTAGE_VALUE = 100;

  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [inflightValue, setInflightValue] = useState(null);

  const allValid = errors.length === 0;

  let localMax = max;
  if (type === CHF && (!max || max > MAX_CHF_VALUE)) {
    localMax = MAX_CHF_VALUE;
  } else if (type === BPS && (!max || max > MAX_BPS_VALUE)) {
    localMax = MAX_BPS_VALUE;
  } else if (type === PERCENTAGE && (!max || max > MAX_PERCENTAGE_VALUE)) {
    localMax = MAX_PERCENTAGE_VALUE;
  }

  useEffect(() => {
    if (isEditable) {
      let defaultValidations = [];
      defaultValidations.push(validator.isNonNegative);
      if (!disableMax) {
        defaultValidations.push(validator.isMax(localMax));
      }
      if (disableEmptyInput) {
        defaultValidations.push(validator.isNotEmpty);
      }
      if (type === PERCENTAGE) {
        defaultValidations.push(validator.isMaxDecimalPlaces(2));
        if (!disableMin && !disableMax) {
          defaultValidations.push(validator.isPercentageValid);
        }
      } else {
        defaultValidations.push(validator.isNumber);
      }

      setErrors(defaultValidations.map(validateFn => validateFn(inflightValue).errorMessage).filter(errorMessages => !!errorMessages));
    }
  }, [inflightValue, isEditable, localMax, type, setErrors, disableMax, disableMin, disableEmptyInput]);

  useEffect(() => {
    if (value === 0) {
      setInflightValue(0);
    } else {
      setInflightValue(convertDecimal(value) || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, setInflightValue]);

  useEffect(() => {
    if (onValidationResultChange) {
      onValidationResultChange({isValid: allValid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValid]);

  const convertDecimal = value => {
    const getDecimalPlace = (num) => num?.toString().split('.')[1]?.length;

    const decimalScale = type === CHF ? DECIMAL_CHF : DECIMAL_REGULAR;

    // If more than 4 decimal, round up to 4 decimal
    if (getDecimalPlace(value) > decimalScale) {
      const result = parseFloat(value).toFixed(decimalScale);
      return parseFloat(result);
    }
    return value;
  };
  const checkIsAllowed = (values) => {
    const {value} = values;
    return value.replace('.', '').length <= MAX_DIGIT;
  }

  const suffix = type === REGULAR ? '' : `  ${type}`;

  const getAsNumber = (value) => {
    if (value === null || value === undefined || value === '') {
      return null;
    }
    return parseFloat(value);
  };

  let decimalScale = DECIMAL_REGULAR;
  if (decimal || decimal === 0) {
    decimalScale = decimal;
  } else if (type === PERCENTAGE) {
    decimalScale = DECIMAL_PERCENTAGE;
  } else if (type === CHF) {
    decimalScale = DECIMAL_CHF;
  }

  if (isEditable) {
    return (
      <FormControl error={!allValid}>
        <NumberFormat
          customInput={TextField}
          decimalScale={decimalScale}
          error={!allValid}
          fixedDecimalScale={fixedDecimal}
          inputProps={{'data-testid': other['data-testid'], style: {textAlign: 'right'}}}
          isAllowed={checkIsAllowed}
          onBlur={() => {
            if (value !== inflightValue) {
              onValueChange({value: inflightValue, isValid: allValid});
            }
          }}
          onValueChange={({value}) => setInflightValue(getAsNumber(value))}
          style={{width: '100%'}}
          suffix={suffix}
          thousandSeparator
          value={inflightValue}
        />
        {!allValid && (
          <FormHelperText>
            <span data-testid="test-validation-error-message">{errors[0]}</span>
          </FormHelperText>
        )}
      </FormControl>
    );
  }


  if (value === undefined || value === null) {
    return (
      <Box className={classes.textAlignRight}>
        <Box className={classes.text} component="span" data-testid={other['data-testid']}>
          {placeHolderForUndefined || ''}
        </Box>
      </Box>
    );
  } else if (isNaN(value)) {
    throw Error(`This component only accepts number, null and undefined but was '${value}'`);
  } else {
    return (
      <Box className={classes.textAlignRight}>
        <NumberFormat
          className={`${classes.text}`}
          data-testid={other['data-testid']}
          decimalScale={decimalScale}
          displayType={'text'}
          fixedDecimalScale={type === PERCENTAGE}
          suffix={suffix}
          thousandSeparator
          value={convertDecimal(value)}
        />
      </Box>
    );
  }
}

export default PriceNumberFormat;

PriceNumberFormat.propTypes = {
  decimal: PropTypes.number,
  disableEmptyInput: PropTypes.bool,
  disableMax: PropTypes.bool,
  disableMin: PropTypes.bool,
  fixedDecimal: PropTypes.bool,
  isEditable: PropTypes.bool,
  max: PropTypes.number,
  onValidationResultChange: PropTypes.func,
  onValueChange: PropTypes.func,
  placeHolderForUndefined: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.number
}
