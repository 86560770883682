import React from 'react';
import PriceConditionTableColumn from './PriceConditionTableColumn';
import {LABEL, NEW_CONDITION_EDITABLE, NEW_CONDITION_READONLY, OLD_CONDITION_READONLY} from './PriceConditionType';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../common/constants';
import PriceConditionTableRowSection from './PriceConditionTableRowSection';
import PropTypes from 'prop-types';

const PriceConditionFeeRow = ({ data, displayRow, showOldCondition, showNewCondition, columnProps }) => {
  if(displayRow) {
    return <PriceConditionTableRowSection data={data}>
      <PriceConditionTableColumn field="label" type={LABEL}/>
      {showOldCondition &&
      <PriceConditionTableColumn
        customStyles={OLD_CONDITION_CUSTOM_STYLE}
        field="currentCondition.effectivePrice"
        label="Effective fee*"
        type={OLD_CONDITION_READONLY}
      />}
      {showNewCondition &&
        <PriceConditionTableColumn
          field="newCondition.listPrice"
          label="Standard fee (up to)"
          type={NEW_CONDITION_READONLY}
        />}
      {showNewCondition &&
      <PriceConditionTableColumn
        field="newCondition.effectivePrice"
        isDisabledAccessor="newCondition.isDisabled"
        isEditableFieldValueAccessor="newCondition.isEditable"
        label="Effective fee*"
        type={NEW_CONDITION_EDITABLE}
        {...columnProps}
      />}
    </PriceConditionTableRowSection>;
  }

  return null;
};

PriceConditionFeeRow.propTypes = {
  columnProps: PropTypes.object,
  data: PropTypes.array.isRequired,
  displayRow: PropTypes.bool.isRequired,
  showNewCondition: PropTypes.bool.isRequired,
  showOldCondition: PropTypes.bool.isRequired
};

export default React.memo(PriceConditionFeeRow);
