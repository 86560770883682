import { jbColors } from '../jbColors';

const MuiAutocomplete = {
  inputRoot: {
    '&&[class*="MuiFilledInput-root"]': {
      backgroundColor: jbColors.greenSmoke[10],
      borderRadius: 0,
      padding: '4px',
    },
  },
};

export default MuiAutocomplete;
