import {api, BASE_URL} from './baseApi';

const ADMIN_BASE_URL = `${BASE_URL}/admin`;
const T24_WORKFLOW_BASE_URL = `${ADMIN_BASE_URL}/t24-synchronisation`;
const PRICE_CHECK_WORKFLOW_BASE_URL = `${ADMIN_BASE_URL}/price-check-synchronisation`;
const PRICING_REQUEST_DATA_PATCH_BASE_URL = `${ADMIN_BASE_URL}/pricing-request-data-patch`;
const CLIENT_AGREEMENT_WORKFLOW_BASE_URL = `${ADMIN_BASE_URL}/client-agreement`;
const FX_GRID_MANAGEMENT_BASE_URL = `${ADMIN_BASE_URL}/fxMarginApplicableStandard`;

const t24Workflow = {
  submitNewFeeCommissionTypesToBCOM: () =>
    api.post(`${T24_WORKFLOW_BASE_URL}/fee-commission-type`),
  submitFeeCommissionTypeToBCOM: feeCommissionTypeId =>
    api.post(`${T24_WORKFLOW_BASE_URL}/fee-commission-type/${feeCommissionTypeId}`),
  submitNewPricingRequestsToBCOM: () =>
    api.post(`${T24_WORKFLOW_BASE_URL}/pricing-request`),
  submitPricingRequestToBCOM: pricingRequestId =>
    api.post(`${T24_WORKFLOW_BASE_URL}/pricing-request/${pricingRequestId}`),
  getT24RequestResponses: params => api.get(`${T24_WORKFLOW_BASE_URL}/t24-request-response`, { params })
};

const priceCheckWorkflow = {
  getMigrateCount: params =>
    api.get(`${PRICE_CHECK_WORKFLOW_BASE_URL}/get-migrate-count`, { params }),
  migrateAll: () =>
    api.post(`${PRICE_CHECK_WORKFLOW_BASE_URL}/migrate-all`),
  submitPricingRequestToBCOM: pricingRequestId =>
    api.post(`${PRICE_CHECK_WORKFLOW_BASE_URL}/pricing-request/${pricingRequestId}`),
  getPriceCheckRequestResponses: params => api.get(`${PRICE_CHECK_WORKFLOW_BASE_URL}/price-check-request-response`, { params }),
  getMigrateNonOtcCount: params =>
    api.get(`${PRICE_CHECK_WORKFLOW_BASE_URL}/get-migrate-non-otc-count`, { params }),
  migrateNonOtcAll: () =>
    api.post(`${PRICE_CHECK_WORKFLOW_BASE_URL}/migrate-non-otc-all`),
};

const dataPatch = {
  getCount: params =>
    api.get(`${PRICING_REQUEST_DATA_PATCH_BASE_URL}/get-count`, { params }),
  applyAll: () =>
    api.post(`${PRICING_REQUEST_DATA_PATCH_BASE_URL}/apply-all`),
  applyByPricingRequestId: pricingRequestId =>
    api.post(`${PRICING_REQUEST_DATA_PATCH_BASE_URL}/apply/${pricingRequestId}`),
};

const dataNonOtcPatch = {
  getCount: params =>
    api.get(`${PRICING_REQUEST_DATA_PATCH_BASE_URL}/get-count-non-otc`, { params }),
  applyAll: () =>
    api.post(`${PRICING_REQUEST_DATA_PATCH_BASE_URL}/apply-all-non-otc`),
  applyByPricingRequestId: pricingRequestId =>
    api.post(`${PRICING_REQUEST_DATA_PATCH_BASE_URL}/apply-non-otc/${pricingRequestId}`),
  getSpecialConditionCount: params =>
    api.get(`${PRICING_REQUEST_DATA_PATCH_BASE_URL}/get-count-special-condition-t24-migrated`, { params }),
  applyAllSpecialCondition: () =>
    api.post(`${PRICING_REQUEST_DATA_PATCH_BASE_URL}/apply-all-special-condition-t24-migrated`),
};

const clientAgreementWorkflow = {
  getAllClientAgreementStatuses: params => api.get(`${CLIENT_AGREEMENT_WORKFLOW_BASE_URL}/generation-status`, { params })
};

const pricingAdmin = {
  getFeeCommissionTypes: params =>
    api.get(`${ADMIN_BASE_URL}/fee-commission-type`, { params }),
  updateFeeCommissionTypeT24SyncStatus: (feeCommissionTypeId, t24SyncStatus) =>
    api.post(`${ADMIN_BASE_URL}/fee-commission-type/${feeCommissionTypeId}/t24-sync-status/${t24SyncStatus}`),
  deactivate: (feeCommissionTypeId) =>
    api.delete(`${ADMIN_BASE_URL}/fee-commission-type/${feeCommissionTypeId}`),
  getPricingRequests: params =>
    api.get(`${ADMIN_BASE_URL}/pricing-request`, { params }),
  validateCodes: feeCommissionTypeId =>
    api.post(`${ADMIN_BASE_URL}/pricing-request/${feeCommissionTypeId}/fee-commission-types/validate`),
  deriveCodes: (userId, feeCommissionTypeId) =>
    api.post(`${ADMIN_BASE_URL}/pricing-request/${feeCommissionTypeId}/fee-commission-types/derive`, {}, { headers: { userId: userId } }),
  deletePricingRequest: (pricingRequestId) =>
    api.delete(`${ADMIN_BASE_URL}/pricing-request/${pricingRequestId}`),
  cancelPricingRequest: (pricingRequestId) =>
    api.post(`${ADMIN_BASE_URL}/pricing-request/${pricingRequestId}/cancel`),
  syncOwner: (pricingRequestId) =>
    api.post(`${ADMIN_BASE_URL}/pricing-request/${pricingRequestId}/syncOwner`),
  getPendingWorkflow: params => api.get(`${ADMIN_BASE_URL}/workflow/pending-workflow`, { params }),
  viewWorkflowApprovers: (pricingRequestId) => api.post(`${ADMIN_BASE_URL}/workflow/${pricingRequestId}/view-approvers`),
  restart: (pricingRequestId) => api.post(`${ADMIN_BASE_URL}/workflow/${pricingRequestId}/restart`),
  refresh: (pricingRequestId) => api.post(`${ADMIN_BASE_URL}/workflow/${pricingRequestId}/refresh`),
};

const featureFlag = {
  retainedMail: () => api.get(`${ADMIN_BASE_URL}/feature/RETAINED_MAIL_FEE`),
  nmbFeatures: () => api.get(`${ADMIN_BASE_URL}/feature/NMB_FEATURES`)
}

const cache = {
  getAll: params => api.get(`${ADMIN_BASE_URL}/cache/`, { params }),
  clear: (cacheName) => api.delete(`${ADMIN_BASE_URL}/cache/${cacheName}`),
  removeKey: (cacheName, key) => api.delete(`${ADMIN_BASE_URL}/cache/${cacheName}/${key}`)
}

const fxGrid = {
  addFxGrid: (fxGridInfo) => api.post(`${FX_GRID_MANAGEMENT_BASE_URL}/saveFXGridName`, fxGridInfo),
  removeFxGrid: (fxGridIds) => api.post(`${FX_GRID_MANAGEMENT_BASE_URL}/removeFXGridName`, fxGridIds),
  getAllActiveFxGrids: () => api.get(`${FX_GRID_MANAGEMENT_BASE_URL}/activeFXGridNames`)
}

export default {
  clientAgreementWorkflow,
  priceCheckWorkflow,
  dataPatch,
  dataNonOtcPatch,
  t24Workflow,
  pricingAdmin,
  featureFlag,
  cache,
  fxGrid
};
