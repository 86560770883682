import {isEqual} from 'lodash';
import {isNullOrUndefined} from '../../../../common/isNullOrUndefined';

export const getUnequalFieldsInTransactionFees = (currentCondition, newCondition, showOld) =>
{
  if(!currentCondition && !showOld){
    return [];
  }

  if(!currentCondition && showOld){
    return Object.keys(newCondition).map(key => key);
  }

  // eslint-disable-next-line
  const fieldLevelDiff =  Object.keys(newCondition).map(key => {
    switch (key){
      case 'feePerTransaction':
      case 'minFeePerTransaction':
        if(newCondition[key].effectivePrice !== currentCondition[key]?.effectivePrice){
          return key;
        }
        break;
      case 'ticketFee':
        if(newCondition[key] !== currentCondition[key]){
          return key;
        }
        break;
      case 'acknowledged':
      case 'waiveChargesOnRedemption':
      case 'additionalInstructions':
      case 'applicableStandardId':
      case 'discountLevelId':
        if(newCondition[key] !== currentCondition[key]){
          return key;
        }
        break;
      case 'agreementEnum':
      case 'transactionFeeCondition':
      case 'fxProductDiscountCondition':
      case 'pricingType':
      case 'calculationBasis':
        if(newCondition[key]?.code !== currentCondition[key]?.code){
          return key;
        }
        break;
      case 'tierPricing':
        if(!isEqual(newCondition[key], currentCondition[key])){
          return key;
        }
        break;
      default:
        return null;
    }
  });

  const keyLevelDiffNew =  Object.keys(newCondition).map(key => {
    if(isNullOrUndefined(currentCondition[key])){
      return key;
    }
    return null;
  });

  const keyLevelDiffCurrent =  Object.keys(currentCondition).map(key => {
    if(isNullOrUndefined(newCondition[key])){
      return key;
    }
    return null;
  });
  return [...fieldLevelDiff, ...keyLevelDiffNew, ...keyLevelDiffCurrent].filter(v=>!isNullOrUndefined(v));
};
