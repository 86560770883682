export const getPricingModelIdFromSummary = (summary) => {
  if (summary.pricingModelId) {
    return summary.pricingModelId;
  } else if (summary.pricingModels?.length > 0) {
    return summary.pricingModels[0].pricingModelId;
  } else {
    return '';
  }
};

export const getPricingModelFromSummary = (summary) => {
  if (!summary) {
    return null;
  }
  return summary?.pricingModels?.filter(pricingModel => pricingModel.pricingModelId === summary.pricingModelId)[0]?.pricingModel;
};


