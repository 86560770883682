import {DEFAULT_COLOR} from '../common/color';

export default {
  outlined: {
    fontFamily: '\'Verlag SSm 7r\'',
    fontWeight: 'bold',
    fontSize: '13px',
    color: DEFAULT_COLOR
  }
};
  
