import {LOAD_FUTURE_REVENUE_RESPONSE, LOAD_REVENUE_RESPONSE, LOAD_PDB_RESPONSE} from './revenueActions';

export const revenueReducer = (state, action) => {
  switch (action.type) {
    case LOAD_REVENUE_RESPONSE: {
      return {
        ...state,
        ...action.data,
        errors: []
      }
    }
    case LOAD_FUTURE_REVENUE_RESPONSE: {
      return {
        ...state,
        futureRevenue: {
          ...state?.futureRevenue,
          ...action.data
        },
        errors: []
      }
    }
    case LOAD_PDB_RESPONSE: {
      return {
        ...state,
        pdbData: {
          ...state?.pdbData,
          ...action.data
        },
        errors: []
      }
    }
    default:
      return state;
  }
}
