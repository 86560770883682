import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Grid,
  Select,
  Typography,
  makeStyles
} from '@material-ui/core';
import adminApi from '../../../../../api/adminApi';
import { useNotification } from '../../../../Common/components/Notification';
import { getErrorMessage } from '../../../../../common/getErrorMessage';
import { CLIPP_BLACK } from '../../../../../theme/common/bjbColor';
import { rem } from '../../../../../theme/common/font';
import FxDropdownList from './FxDropdownList';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow:
      '0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(1)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  inputField: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  label: {
    color: CLIPP_BLACK,
    fontSize: rem(14),
    width: '180px'
  },
  dropdown: {
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px'
    },
    '& .MuiSelect-selectMenu': {
      height: 'auto'
    },
    width: 'clamp(340px, 36vw, 500px)',
    zIndex: 2
  },
  listItem: {
    '& .MuiListItemText-primary': {
      paddingLeft: 0
    }
  },
  dialogGridModelText: {
    fontSize: rem(15),
    fontFamily: '"Verlag SSm 7r"',
    paddingBottom: theme.spacing(1.5)
  }
}));

const FxGridRemove = ({
  resetFxGridRemoveStates,
  setFxGridRemoveConfirmationDialogProps,
  setFxGridRemoveIsConfirmationDialogOpen,
  setResetFxGridStates
}) => {
  const classes = useStyles();
  const notification = useNotification();
  const [clippActiveFxGrids, setClippActiveFxGrids] = useState([]);
  const [fxGridServiceModels, setFxGridServiceModels] = useState([]);
  const [selectedServiceModels, setSelectedServiceModels] = useState([]);
  const [applicableServiceModels, setApplicableServiceModels] = useState([]);
  const [selectedGridName, setSelectedGridName] = useState('');

  const updateFxGridData = (activeFxGrids) => {
    const listOfClippFxGrids = activeFxGrids
      .map((fxGrid) => fxGrid.applicableStandard)
      .filter((value, index, fxGridArray) => {
        return fxGridArray.indexOf(value) === index;
      });

    const fxGridWithAssociatedServiceModels = listOfClippFxGrids.map(
      (fxGridName) => {
        return {
          fxGridName,
          associatedServiceModels: activeFxGrids
            .filter((fxGrid) => fxGrid.applicableStandard === fxGridName)
            .map((fxGrid) => {
              return {
                fxGridId: fxGrid.applicableStandardId,
                serviceModelInfo: fxGrid.serviceModel
              };
            })
        };
      }
    );

    setClippActiveFxGrids(listOfClippFxGrids);
    setFxGridServiceModels(fxGridWithAssociatedServiceModels);
  };

  const fetchData = async () => {
    try {
      await adminApi.fxGrid.getAllActiveFxGrids().then((res) => {
        updateFxGridData(res?.data?.fxMarginApplicableStandardsDto);
      });
    } catch (error) {
      notification.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    setSelectedGridName('');
    setSelectedServiceModels([]);
    fetchData();
  }, [resetFxGridRemoveStates]);

  const getFxGridData = (fxGridName) => {
    return fxGridServiceModels.find(
      (gridName) => gridName.fxGridName === fxGridName
    );
  };

  const handleGridNameChange = (e) => {
    const fxGridData = getFxGridData(e.target.value);
    const applicableServiceModels = fxGridData.associatedServiceModels.map(
      (serviceModel) => ({
        key: serviceModel.serviceModelInfo.code,
        value: serviceModel.serviceModelInfo.desc
      })
    );
    setApplicableServiceModels(applicableServiceModels);
    setSelectedGridName(e.target.value);
    setSelectedServiceModels([]);
  };

  const generateFxRemoveConfirmationText = () => {
    return (
      <Box style={{ fontSize: rem(16) }}>
        Please note that the following FX Grid(s) will be removed:
        <Typography
          className={classes.dialogGridModelText}
          style={{
            paddingTop: '12px',
            textDecoration: 'underline'
          }}
        >
          {selectedGridName}
        </Typography>
        {selectedServiceModels.map((serviceModel) => (
          <Typography
            className={classes.dialogGridModelText}
            key={serviceModel}
          >
            {serviceModel}
          </Typography>
        ))}
        Do you want to proceed?
      </Box>
    );
  };

  const confirmFxGridRemove = async () => {
    const fxGridData = getFxGridData(selectedGridName);
    const fxGridIdsToRemove = fxGridData.associatedServiceModels.flatMap(
      (serviceModel) => {
        return selectedServiceModels.includes(
          serviceModel.serviceModelInfo.desc
        )
          ? serviceModel.fxGridId
          : [];
      }
    );

    try {
      await adminApi.fxGrid
        .removeFxGrid({
          applicableStandardIds: fxGridIdsToRemove
        })
        .then(() => {
          notification.success('FX Grid has been successfully removed.');
          setResetFxGridStates((prevState) => !prevState);
        });
    } catch (error) {
      notification.error(getErrorMessage(error));
    } finally {
      setFxGridRemoveIsConfirmationDialogOpen(false);
    }
  };

  const onFxGridRemove = () => {
    if (selectedServiceModels.length === 0) {
      notification.error('Please select an FX Grid to be removed.');
    } else {
      setFxGridRemoveIsConfirmationDialogOpen(true);
      setFxGridRemoveConfirmationDialogProps({
        content: generateFxRemoveConfirmationText(),
        onClose: () => setFxGridRemoveIsConfirmationDialogOpen(false),
        onContinue: confirmFxGridRemove
      });
    }
  };

  return (
    <Grid data-testid="fx-grid-remove" item xs={12}>
      <Card className={classes.card}>
        <CardContent>
          <Box className={classes.header}>
            <Typography variant="subtitle1">Remove Existing FX Grid</Typography>
            <Button onClick={onFxGridRemove}>REMOVE</Button>
          </Box>
          <Box py={3} style={{ overflowX: 'auto' }}>
            <Box
              className={classes.inputField}
              data-testid="fx-remove-grid-name"
              mb={2.5}
            >
              <InputLabel className={classes.label} id="clipp-fx-grid">
                CliPP FX grid:
              </InputLabel>
              <FormControl style={{ position: 'relative' }}>
                <Select
                  className={classes.dropdown}
                  labelId="clipp-fx-grid"
                  onChange={handleGridNameChange}
                  value={selectedGridName}
                  variant="outlined"
                >
                  {clippActiveFxGrids.map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText
                        className={classes.listItem}
                        primary={name}
                      />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  style={{
                    visibility: selectedGridName ? 'hidden' : 'visible',
                    width: 'clamp(230px, 32vw, 500px)',
                    position: 'absolute',
                    left: '12px',
                    top: '7px'
                  }}
                >
                  Select FX grid
                </FormHelperText>
              </FormControl>
            </Box>
            {selectedGridName && (
              <FxDropdownList
                data-testid="fx-remove-service-model"
                dimension={{ width: 'clamp(340px, 36vw, 500px)' }}
                dropdownId="fx-remove-service-model"
                items={applicableServiceModels}
                label="Service model:"
                labelStyle={{
                  color: CLIPP_BLACK,
                  fontSize: rem(14),
                  width: '180px'
                }}
                placeholder="Select service model applicability"
                placeholderStyle={{
                  width: 'clamp(230px, 32vw, 500px)',
                  position: 'absolute',
                  left: '12px',
                  top: '7px'
                }}
                updateValue={setSelectedServiceModels}
                value={selectedServiceModels}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FxGridRemove;
