import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {useExpanded, useFilters, useGroupBy, useSortBy, useTable} from 'react-table';
import {CLIPP_LIGHT_BLUE} from '../../../../../../theme/common/bjbColor';
import {TABLE_APPROVAL_STATUS_BORDER_COLOR} from '../../../../../../theme/common/color'
import {sortCellColumn, sortHeaderColumn} from '../utils';
import HeaderCell from './components/HeaderCell';
import CustomCell from './components/CustomCell';
import StandardCell from './components/StandardCell';
import PaginationControl from '../../../../../Common/components/PaginationControl';

const useStyles = makeStyles(() => ({
  inner: {
    maxHeight: '80vh',
    // maxWidth: '1500px',
    overflow: 'auto'
  },
  table: {
    border: '1px solid',
    borderColor: TABLE_APPROVAL_STATUS_BORDER_COLOR
  },
  tableRowBodyGroup: {
    backgroundColor: CLIPP_LIGHT_BLUE
  }
}));

const StatusTable = ({columnsData, deviationRetainedMailEnabled, fetchData, filterByKey, filterCriteria, goToPage, handleSort, groupByKey, paginationInfo, priceRequests, setPriceRequests, sortByInfo}) => {
  const tableColumns = columnsData.map((colData) => {
    return {
      accessor: colData.key,
      disableSortBy: !colData.canSort,
      Header: colData.label,
      Cell: CustomCell
    };
  });

  const classes = useStyles();
  const memoizedData = useMemo(() => priceRequests, [priceRequests]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedColumns = useMemo(() => tableColumns, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleGroupBy,
    toggleAllRowsExpanded,
    setAllFilters,
    setHiddenColumns,
    visibleColumns,
    state: {groupBy}
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      disableSortRemove: true,
      deviationRetainedMailEnabled,
      fetchData,
      setPriceRequests
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded
  );

  useEffect(() => {
    if (filterByKey && filterCriteria && handleSort === undefined) {
      setAllFilters([{id: filterByKey, value: filterCriteria}]);
    } else {
      setAllFilters([]);
    }
  }, [filterByKey, filterCriteria, setAllFilters, priceRequests]);

  useEffect(() => {
    const clearGroups = () => {
      groupBy.forEach((group) => {
        toggleGroupBy(group, false);
      });
    };

    if (groupByKey) {
      clearGroups();
      toggleGroupBy(groupByKey);
    } else {
      clearGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupByKey, toggleGroupBy]);

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [groupBy, toggleAllRowsExpanded]);

  useEffect(() => {
    let hiddenColumns = [];
    columnsData.forEach((column) => {
      if (column.hidden) {
        hiddenColumns.push(column.key);
      }
    });
    setHiddenColumns(hiddenColumns);
  }, [columnsData, setHiddenColumns]);

  return (
    <React.Fragment>
      <div className={classes.inner}>
        <Table size="small" {...getTableProps()} className={classes.table}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {sortHeaderColumn(headerGroup.headers, columnsData).map((column, i) => (
                  <HeaderCell column={column} handleSort={handleSort} key={i} sortByInfo={sortByInfo}/>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow
                    {...row.getRowProps()}
                    className={row.isGrouped ? classes.tableRowBodyGroup : null}
                    key={i}
                  >
                    {sortCellColumn(row.cells, columnsData).map((cell, i) =>
                      <StandardCell cell={cell} key={i} row={row}/>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={visibleColumns.length}>
                  No result found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {paginationInfo && (
        <PaginationControl goToPage={goToPage} paginationInfo={paginationInfo}/>
      )}
    </React.Fragment>
  );
};

StatusTable.propTypes = {
  columnsData: PropTypes.any,
  deviationRetainedMailEnabled: PropTypes.bool,
  fetchData: PropTypes.func,
  filterByKey: PropTypes.string,
  filterCriteria: PropTypes.string,
  goToPage: PropTypes.func,
  groupByKey: PropTypes.string,
  paginationInfo: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
    numberOfElements: PropTypes.number
  }),
  priceRequests: PropTypes.any,
  setPriceRequests: PropTypes.func
};

export default StatusTable;
