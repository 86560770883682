import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles, withStyles} from '@material-ui/styles';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Event} from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import {ERROR_BACKGROUND_PRIMARY_COLOR, HEADER_COLOR} from '../../../../../../../../../../../theme/common/color';
import {CLIPP_WHITE} from '../../../../../../../../../../../theme/common/bjbColor';
import {getDefaultFontSize} from '../../../../../../../../../../../theme/common/font';
import {getFormText} from '../../../../../../../../../../Common/components/form/formText';
import {Store} from '../../../../../../../../../../../Store';
import AsyncButton from '../../../../../../../../../../Common/components/AsyncButton';

const useStyles = makeStyles(() => ({
  errorText: {
    ...getDefaultFontSize(),
    color: ERROR_BACKGROUND_PRIMARY_COLOR
  },
  text: {
    ...getFormText()
  }
}));

const DialogTitle = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: HEADER_COLOR,
    color: CLIPP_WHITE,
    display: 'flex',
    alignItems: 'center'
  }
}))(MuiDialogTitle);

const ApproveDialog = ({onClose, onSubmit, open, pricingRequestId}) => {
  const OTHER_REASON_ID = 99;
  const contingentTermsPlaceholder = `Example:
Client to bring in NNM of 5 mil CHF in next 3 months. Special condition to be reviewed then.`;
  const errorMessages = {
    mandatory: 'Please select at least one reason.',
    otherReason: 'Please indicate the reason in the box below.'
  };

  const classes = useStyles();
  const {parentState} = useContext(Store);

  const initialState = parentState.approvalReasons.map(reason => ({
    ...reason,
    selected: false
  }));
  const [reasons, setReasons] = useState(initialState);
  const [otherReasonSelected, setOtherReasonSelected] = useState(false);
  const [otherReasonText, setOtherReasonText] = useState();
  const [otherInputs, setOtherInputs] = useState({});
  const [hasMandatoryError, setMandatoryError] = useState();
  const [hasOtherReasonError, setOtherReasonError] = useState();

  const handleChange = event => setOtherInputs({...otherInputs, [event.target.name]: event.target.value});

  const handleReasonChange = (e, selectedReason) => {
    setMandatoryError(false);
    const updatedReasons = [...reasons];
    let item = updatedReasons.find(item => item.approvalReasonId === selectedReason.approvalReasonId);
    item.selected = e.target.checked;

    setReasons(updatedReasons);
  };

  const handleOtherReasonChange = event => {
    setOtherReasonError(false);
    setOtherReasonSelected(event.target.checked);
  };

  const handleOtherReasonTextChange = event => {
    setOtherReasonError(false);
    setOtherReasonText(event.target.value.trim());
  };

  const resetReasons = () => {
    setReasons(reasons.map(reason => ({...reason, selected: false})));
    setOtherReasonSelected(false);
    setOtherReasonText('');
  }

  const submit = () => {
    return new Promise((asyncBtnResolve, asyncBtnReject) => {
      const atLeastOneSelected = reasons.reduce((acc, value) => acc || value.selected, false);
      const mandatoryError = !atLeastOneSelected && !otherReasonSelected;
      const otherReasonError = otherReasonSelected && !otherReasonText;

      setMandatoryError(mandatoryError);
      setOtherReasonError(otherReasonError);

      if (!mandatoryError && !otherReasonError) {
        const approvalReasons = reasons
          .filter(reason => reason.selected)
          .map(data => ({
            approvalReasonId: data.approvalReasonId
          }));

        if (otherReasonSelected) {
          approvalReasons.push({approvalReasonId: OTHER_REASON_ID, otherReason: otherReasonText});
        }

        const approvalDecisionData = {
          pricingRequestId: pricingRequestId,
          deciderId: parentState.user.id,
          decisionDate: new Date(),
          reviewDate: otherInputs?.reviewDate,
          contingencyTerms: otherInputs?.contingencyTerms || null,
          approvalReasons: approvalReasons,
          firstName: parentState.user.firstName,
          lastName: parentState.user.lastName
        };

        onSubmit(approvalDecisionData).then(() => {
          resetReasons();
          asyncBtnResolve();
        }).catch(() => {
          asyncBtnReject();
        });
      } else {
        asyncBtnReject();
      }
    });
  };

  return (
    <Dialog disableBackdropClick fullWidth onClose={onClose} open={open}>
      <DialogTitle disableTypography>
        <ThumbUpIcon fontSize="inherit"/> <Box marginLeft={1}>APPROVE</Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h2">Reason(s) for Approval</Typography>
        <Typography className={classes.errorText}>*Reason(s) for Approval is mandatory</Typography>
        <FormControl error={hasMandatoryError || hasOtherReasonError}>
          {hasMandatoryError && <FormHelperText>{errorMessages.mandatory}</FormHelperText>}
          <FormGroup>
            {reasons?.map(reason => {
              if (reason.approvalReasonId === OTHER_REASON_ID) {
                return null;
              }
              return (
                <div key={reason.approvalReasonId}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reason.selected}
                        color="primary"
                        name={reason.approvalReasonDesc}
                        onChange={(e) => handleReasonChange(e, reason)}
                      />
                    }
                    label={reason.approvalReasonDesc}
                  />
                </div>
              );
            })}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={otherReasonSelected}
                    color="primary"
                    name="Others"
                    onChange={handleOtherReasonChange}
                  />
                }
                label="Others"
              />
              <div>
                {hasOtherReasonError && <FormHelperText>{errorMessages.otherReason}</FormHelperText>}
                <TextField
                  disabled={!otherReasonSelected}
                  fullWidth
                  helperText="(Limited to 500 characters)"
                  inputProps={{
                    maxLength: 500
                  }}
                  multiline
                  name="otherReason"
                  onChange={handleOtherReasonTextChange}
                  placeholder="Mandatory update here if Others is selected above."
                  rows={4}
                  variant="outlined"
                />
              </div>
            </div>
          </FormGroup>
        </FormControl>
        <Box marginTop={2}>
          <Typography variant="h2">Additional Requirements (Optional)</Typography>
          <Box display="flex" my={1}>
            <Grid container>
              <Grid item xs={4}>
                <Box alignItems="center" display="flex" height="100%">
                  1. Review Date
                </Box>
              </Grid>
              <Grid item xs={8}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Event/>
                        </InputAdornment>
                      )
                    }}
                    autoOk
                    className={classes.text}
                    disablePast
                    format="dd-MMM-yyyy"
                    id="date-picker-inline"
                    inputProps={{style: {textAlign: 'end'}}}
                    name="reviewDate"
                    onChange={date => handleChange({target: {name: 'reviewDate', value: date}})}
                    value={otherInputs?.reviewDate || null}
                    variant="inline"
                    views={['year', 'month', 'date']}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={1}>2. Contingent Term</Box>
          <TextField
            fullWidth
            helperText="(Limited to 500 characters)"
            inputProps={{
              maxLength: 500
            }}
            multiline
            name="contingencyTerms"
            onChange={handleChange}
            placeholder={contingentTermsPlaceholder}
            rows={4}
            variant="outlined"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <AsyncButton color="primary" onClick={submit} text="Submit"/>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

ApproveDialog.propTypes = {
  discountReasons: PropTypes.array,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  pricingRequestId: PropTypes.number
};
export default ApproveDialog;
