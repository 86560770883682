import React from 'react';
import PriceNumberFormat from '../../../../../Common/components/PriceNumberFormat';
import {CHF} from '../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {FID_CALL_MONEY} from '../../../../../../common/enums/transactionAssetSubLevel';

const LABEL = 'Liquidation Fee';
const KEY_PREFIX = 'transaction-fees-liquidation-fee';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const liquidationFee = ({subFund, handleValidationResultChange, handleValueChange, readOnly, specialRequest}) => {

  if (FID_CALL_MONEY.code !== subFund.assetSubLevel.code) {
    return null;
  }

  let result = {
    label: LABEL
  };

  if (subFund.currentCondition?.liquidationFee) {
    result = {
      ...result,
      currentCondition: getMappedOldCondition(subFund.currentCondition?.liquidationFee)
    };
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(subFund.newCondition?.liquidationFee, handleValidationResultChange, handleValueChange, readOnly, specialRequest)
  };

};

const getMappedOldCondition = (liquidationFee) => {

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={liquidationFee?.effectivePrice}
    />);


  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (liquidationFee, handleValidationResultChange, handleValueChange, readOnly, specialRequest) => {

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      key={`${KEY_PREFIX}-list-price`}
      type={CHF}
      value={liquidationFee?.listPrice}
    />);

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-editable`}
      isEditable
      key={`${KEY_PREFIX}-effective-price-editable`}
      max={specialRequest ? null : liquidationFee?.listPrice}
      onValidationResultChange={(vr) => handleValidationResultChange({
        ...vr,
        selectors: ['newCondition', 'liquidationFee', 'effectivePrice']
      })}
      onValueChange={(vr) => handleValueChange({
        ...vr,
        selectors: ['newCondition', 'liquidationFee', 'effectivePrice']
      })}
      type={CHF}
      value={liquidationFee?.effectivePrice}
    />);

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={liquidationFee?.effectivePrice}
    />);

  return {
    effectivePrice: readOnly ? effectivePriceReadonly : effectivePriceEditable,
    isEditable: !readOnly,
    listPrice: listPrice
  };
};
