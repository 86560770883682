import React from 'react';
import {FLAT_RATE_BPS, FLAT_RATE_PCT} from '../../../../../../common/enums/nmbFeeCondition';
import PriceNumberFormat from '../../../../../Common/components/PriceNumberFormat';
import {BPS, PERCENTAGE} from '../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';

const LABEL = 'Fee per Transaction';

export const nmbFeePerTransaction = ({newConditionCode, oldConditionCode, subFund, testPrefixId, handleValidationResultChange, handleValueChange, readOnly}) => {
  let result = {
    label: LABEL
  };

  if (subFund.currentCondition?.feePerTransaction) {
    result = {
      ...result,
      currentCondition: getMappedOldCondition(subFund.currentCondition?.feePerTransaction, oldConditionCode, testPrefixId)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(subFund.newCondition?.feePerTransaction, newConditionCode, handleValidationResultChange, handleValueChange, readOnly, testPrefixId)
  }

};

const getMappedOldCondition = (feePerTransaction, oldConditionCode, testPrefixId) => {
  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${testPrefixId}-effective-price-readonly`}
      key={`${testPrefixId}-effective-price-readonly`}
      type={oldConditionCode === FLAT_RATE_PCT.code?PERCENTAGE:BPS}
      value={feePerTransaction?.effectivePrice}
    />);

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (feePerTransaction, conditionCode, handleValidationResultChange, handleValueChange, readOnly, testPrefixId) => {
  const isEditable = (conditionCode === FLAT_RATE_PCT.code || conditionCode === FLAT_RATE_BPS.code) && !readOnly
  const listPrice = (
    <PriceNumberFormat
      data-testid={`${testPrefixId}-list-price`}
      key={`${testPrefixId}-list-price`}
      type={conditionCode === FLAT_RATE_PCT.code?PERCENTAGE:BPS}
      value={feePerTransaction?.listPrice}
    />);

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${testPrefixId}-effective-price-readonly`}
      fixedDecimal
      key={`${testPrefixId}-effective-price-readonly`}
      type={conditionCode === FLAT_RATE_PCT.code?PERCENTAGE:BPS}
      value={feePerTransaction?.effectivePrice}
    />);

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${testPrefixId}-effective-price-editable`}
      disableEmptyInput
      fixedDecimal={conditionCode === FLAT_RATE_PCT.code}
      isEditable
      key={`${testPrefixId}-effective-price-editable`}
      max={feePerTransaction?.listPrice}
      onValidationResultChange={(vr) => handleValidationResultChange({
        ...vr,
        selectors: ['newCondition', 'feePerTransaction', 'effectivePrice']
      })}
      onValueChange={(vr) => handleValueChange({
        ...vr,
        selectors: ['newCondition', 'feePerTransaction', 'effectivePrice']
      })}
      type={conditionCode === FLAT_RATE_PCT.code?PERCENTAGE:BPS}
      value={feePerTransaction?.effectivePrice}
    />);

  return {
    effectivePrice: isEditable ? effectivePriceEditable : effectivePriceReadonly,
    isEditable: isEditable,
    listPrice: listPrice,
  };
};



