export const ARM = {
  code: 'ARM',
  desc: 'Assistant Relationship Manager'
};

export const RM = {
  code: 'RM',
  desc: 'Relationship Manager'
};

export const RH = {
  code: 'RH',
  desc: 'Region Head'
};

export const SRH = {
  code: 'SRH',
  desc: 'Sub Region Head'
};

export const TH = {
  code: 'TH',
  desc: 'Team Head'
};

export const GH = {
  code: 'GH',
  desc: 'Group Head'
};

export const MH = {
  code: 'MH',
  desc: 'Group Head'
};

export const APP_SUPPORT = {
  code: 'APP_SUPPORT',
  desc: 'Application Support'
};

// Also referred to as IM
export const PORTFOLIO_MANAGER = {
  code: 'PM',
  desc: 'Investment Manager'
};

export const IA_HEAD = {
  code: 'IA',
  desc: 'IA Head'
};

export const PRICING_MANAGER = {
  code: 'PRICING_MANAGER',
  desc: 'Pricing Manager'
};

export const ACC_MGMT = {
  code: 'ACC_MGMT',
  desc: 'Account Management'
};

export const CSS = {
  code: 'CSS',
  desc: 'Client Support & Solutions'
};

export const CSS_HK = {
  code: 'CSS_HK',
  desc: 'Client Support & Solutions'
};

export const getAcmRoles = () => {
  return [ACC_MGMT, CSS, CSS_HK]
}

export const getApproverRoles = () => {
  return [TH, GH, IA_HEAD, PORTFOLIO_MANAGER, MH, RH, SRH];
};

export const getRequesterRoles = () => {
  return [ARM, RM, TH];
};

export const getRequesterRolesWhoCanSubmit = () => {
  return [RM, TH];
}

export const getAdminRoles = () => {
  return [APP_SUPPORT, PRICING_MANAGER];
};

export const getPdbRoles = () => {
  return [RM];
};

export const getAllPricingRequestRoles = () => {
  return [ARM, RM, TH, PRICING_MANAGER, MH, SRH, GH, PORTFOLIO_MANAGER, IA_HEAD, ...getAcmRoles()];
};

export const getAllRoles = () => {
  return [ARM, RM, RH, SRH, TH, GH, MH, APP_SUPPORT, PRICING_MANAGER, PORTFOLIO_MANAGER, IA_HEAD, ...getAcmRoles()];
};

export const getSpecialRequestRoles = () => {
  return [PRICING_MANAGER];
};

export const getRolesPortfolioChcBxEnable = () => {
  return ['RM','ARM','TH'];
};

export const getRolesPortfolioSubmitEnable = () => {
  return ['RM','TH'];
};

export const getResetRequesterRoles = () => {
  return [RM];
};

export const getCombinedApproverRoleString = userRoles => {
  const APPROVER_ROLES = getApproverRoles().reduce(
    (acc, value) => ({...acc, [value.code]: value}),
    {}
  );

  const fullRoleTitles = userRoles
    ?.map(roles => roles.role && APPROVER_ROLES[roles.role]?.desc)
    .filter(roles => !!roles);
  const uniqueRoles = [...new Set(fullRoleTitles)];
  return uniqueRoles.join(', ');
};

export const getCombinedRoleString = userRoles => {
  const filteredRoles = userRoles
    ?.filter(roles => roles.role && roles.role !== 'NONE')
    .map(roles => getAllRoles().find(allRole => allRole.code === roles.role)?.desc)
    .filter(roles => !!roles);

  const uniqueRoles = [...new Set(filteredRoles)];
  return uniqueRoles.slice(0, 4).join(', ');
};

export const getCombinedRoles = userRoles => {
  const filteredRoles = userRoles
    ?.filter(roles => roles.role && roles.role !== 'NONE')
    .map(roles => getAllRoles().find(allRole => allRole.code === roles.role))
    .filter(roles => !!roles);

  const uniqueRoles = [...new Set(filteredRoles)];
  return uniqueRoles;
};
