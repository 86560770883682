export const getDiscountLevelsSelectValues = (object) => {
  if(object){
    return object.map(p => ({
      'key': p.discountLevelId,
      'value': p.discountLevel
    }));
  }
  return [];
};

export const getDiscountLevelDescriptionById = (options, discountLevelId) => {
  if(discountLevelId && options && options.length > 0){
    const filtered = options.filter(option => option.discountLevelId === discountLevelId);
    if(filtered && filtered.length > 0){
      return filtered[0].discountLevel;
    }
  }
  return '-';
}
