import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {DATE_FORMAT} from '../../../../../../../../../common/dateFormat'

const DateCell = ({value}) => {
  return <Moment data-testid="test-date-cell" date={value} format={DATE_FORMAT}/>;
};

DateCell.propTypes = {
  value: PropTypes.string
};

export default DateCell;
