import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/styles';
import PriceNumberFormat, {BPS, CHF} from '../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {
  OVERVIEW_NNM_INFO_TEXT,
  OVERVIEW_PCI_I_INFO_TEXT,
  OVERVIEW_PCI_II_INFO_TEXT,
  OVERVIEW_ROA_INFO_TEXT
} from '../../../../common/enums/infoTexts';
import HoverInfo from '../../../Common/components/HoverInfo';

const TableHead = withStyles(() => ({
  root: {fontSize: '0.8125rem'}
}))(MuiTableHead);

const TableCell = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    fontSize: '0.8125rem'
  }
}))(MuiTableCell);

const useStyles = makeStyles(() => {
  return {
    cellHeader: {
      textAlign: 'center'
    },
    cellHeaderTitle: {
      textTransform: 'uppercase'
    },
    title: {
      textAlign: 'center',
      textTransform: 'uppercase',
      borderBottom: 'solid 2px',
    }
  };
});

const CurrentKpi = ({data, title}) => {
  const classes = useStyles();

  const rows = [
    {
      label: 'PC I',
      previous: data?.pc1PreviousYear,
      current: data?.pc1CurrentYear,
      type: CHF,
      infoText: OVERVIEW_PCI_I_INFO_TEXT
    },
    {
      label: 'PC II',
      previous: data?.pc2PreviousYear,
      current: data?.pc2CurrentYear,
      type: CHF,
      infoText: OVERVIEW_PCI_II_INFO_TEXT
    },
    {
      label: 'NNM',
      previous: data?.nnmPreviousYear,
      current: data?.nnmCurrentYear,
      type: CHF,
      infoText: OVERVIEW_NNM_INFO_TEXT
    },
    {label: 'AUM', previous: data?.aumPreviousYear, current: data?.aumCurrentYear, type: CHF},
    {
      label: 'NIM',
      previous: data?.nimPreviousYear,
      current: data?.nimCurrentYear,
      type: CHF
    },
    {
      label: 'ROA',
      previous: data?.roaPreviousYear,
      current: data?.roaCurrentYear,
      type: BPS,
      infoText: OVERVIEW_ROA_INFO_TEXT
    }
  ];

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography variant="h3">Current KPI</Typography>
      <Box border="solid 1px black" flexGrow="1" padding={2}>
        <Typography className={classes.title}>{title}</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{border: 'none'}}/>
              <TableCell className={classes.cellHeader}>Previous Year</TableCell>
              <TableCell className={classes.cellHeader}>Current Year</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.label}>
                <TableCell component="th">{row.label}</TableCell>
                <TableCell>
                  <PriceNumberFormat type={row.type} value={row.previous}/>
                </TableCell>
                <TableCell>
                  <HoverInfo text={row.infoText}><PriceNumberFormat type={row.type} value={row.current}/></HoverInfo>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

CurrentKpi.propTypes = {
  data: PropTypes.any,
  title: PropTypes.string
};

export default CurrentKpi;
