import React from 'react';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  AdminMain,
  CacheManager,
  FeeCommissionType,
  PricingRequest,
  T24RequestResponse,
  PriceCheckRequestResponse,
  PriceCheckMigration,
  PricingRequestDataPatch,
  PricingRequestPatch,
  WorkflowApproval,
  ClientAgreementGenStatus,
  FxGridManagement
} from './components';
import Authorized from '../../components/Authorized';
import { APP_SUPPORT, PRICING_MANAGER } from '../../common/enums/userRole';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '500px',
    padding: theme.spacing(4)
  }
}));

const Admin = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Route component={AdminMain} exact path="/admin"/>
      <Authorized allowedRoles={[APP_SUPPORT]}>
        <Route component={FeeCommissionType} path="/admin/feecommissiontype"/>
        <Route component={PricingRequest} path="/admin/pricingrequest"/>
        <Route component={T24RequestResponse} path="/admin/t24RequestResponse"/>
        <Route component={PriceCheckRequestResponse} path="/admin/priceCheckRequestResponse"/>
        <Route component={PriceCheckMigration} path="/admin/priceCheckMigration"/>
        <Route component={PricingRequestDataPatch} path="/admin/pricingRequestDataPatch"/>
        <Route component={ClientAgreementGenStatus} path="/admin/clientAgreementGenStatus"/>
        <Route component={WorkflowApproval} path="/admin/pendingWorkflow"/>
        <Route component={CacheManager} path="/admin/cache"/>
        <Route component={PricingRequestPatch} path="/admin/pricingRequestPatch"/>
      </Authorized>
      <Authorized allowedRoles={[PRICING_MANAGER]}>
        <Route component={FxGridManagement} path="/admin/fxGridManagement" />
      </Authorized>
    </div>
  );
};

export default Admin;
