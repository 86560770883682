import {getListOrEmpty} from './getListOrEmpty';

export const getSelectedPriceRequestErrors = (state) => {
  switch (state.selectedTabIndex) {
    case 0:
      return getListOrEmpty(state.selectedPricingRequest.summary?.errors);
    case 1:
      return getListOrEmpty(state.selectedPricingRequest.bankingFees?.errors);
    case 2:
      return getListOrEmpty(state.selectedPricingRequest.paymentFees?.errors);
    case 3:
      return getListOrEmpty(state.selectedPricingRequest.recurringFees?.errors)
    case 4:
      return getListOrEmpty(state.selectedPricingRequest.transactionFees?.errors);
    case 5:
      return getListOrEmpty(state.selectedPricingRequest.nmbTransactionFees?.errors);
    case 6:
      return getListOrEmpty(state.selectedPricingRequest.nmbFXProducts?.errors);
    case 7:
      return getListOrEmpty(state.selectedPricingRequest.overdraft?.errors);
    default:
      return [];
  }
}
