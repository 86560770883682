import React from 'react';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {BPS, CHF, PERCENTAGE, REGULAR} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {
  FIXED_AMOUNT,
  NON_STANDARD_CUMULATIVE,
  NON_STANDARD_NON_CUMULATIVE,
  PERCENTAGE_DISCOUNT
} from '../../../../../common/enums/recurringFeeCondition';
import Box from '@material-ui/core/Box';
import {getDiscountFromEffectivePrice} from '../../../../../common/getDiscountFromEffectivePrice';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {FormControl, FormHelperText, IconButton} from '@material-ui/core';

const LABEL = 'Portfolio Assets';
const KEY_PREFIX = 'recurring-fees-portfolio-assets-special';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const portfolioAssetsSpecialRequest = ({handleAddTier, handleRemoveTier, handleRangeStartChange, handleRangeEndChange, handleValidationResultChange, handleValueChange, readOnly, recurringFees, showDiff, specialRequestPm}) => {

  if (
    ![NON_STANDARD_NON_CUMULATIVE.code, NON_STANDARD_CUMULATIVE.code].includes(
      recurringFees?.newCondition?.condition?.code
    )
  ) {
    return [];
  }

  let results = [
    {
      key: 'portfolio-assets-special',
      label: LABEL,
      newCondition: <Box/>
    }
  ];

  const numberOfTiers = Math.max(recurringFees?.currentCondition?.portfolioAssets?.length || 0, recurringFees?.newCondition?.portfolioAssets?.length || 0);

  const hasOldConditionTiering = recurringFees?.currentCondition?.portfolioAssets?.length > 0;

  for (let i = 0; i < numberOfTiers; i++) {
    const applicableTierForLabel = hasOldConditionTiering ? recurringFees.currentCondition.portfolioAssets[i] : recurringFees.newCondition.portfolioAssets[i];

    const currentConditionPortfolioAssets = recurringFees?.currentCondition?.portfolioAssets[i];
    const newConditionPortfolioAssets = recurringFees?.newCondition?.portfolioAssets?.length > 0 ? recurringFees?.newCondition?.portfolioAssets[i] : null;

    // Take discount value from discountOnListPrice if the condition is % discount
    const isPercentageDiscountCondition = recurringFees?.newCondition?.condition?.code === PERCENTAGE_DISCOUNT.code
    const fixedDiscountValue = recurringFees?.newCondition?.discountOnListPrice;

    let result = {
      key: `portfolio-assets-special-${i}`, // it is safe to use index here as part of the key, as the number of visible rows and their associated data is constant.
      label: applicableTierForLabel
        ? hasOldConditionTiering || readOnly || !specialRequestPm
          ? getRangeLabel(applicableTierForLabel)
          : getRangeLabelWithIcon(
            handleAddTier,
            handleRemoveTier,
            handleRangeStartChange,
            handleRangeEndChange,
            applicableTierForLabel,
            i === recurringFees?.newCondition?.portfolioAssets?.length - 1,
            i
          )
        : ''
    };
    let isNewValueDifferent = false;

    if (hasOldConditionTiering && currentConditionPortfolioAssets) {
      if (!!readOnly && currentConditionPortfolioAssets && (currentConditionPortfolioAssets.effectivePrice !== newConditionPortfolioAssets?.effectivePrice
        || currentConditionPortfolioAssets?.rangeStart !== newConditionPortfolioAssets?.rangeStart
        || currentConditionPortfolioAssets?.rangeEnd !== newConditionPortfolioAssets?.rangeEnd) ) {
        isNewValueDifferent = true;
      }
      result = {
        ...result,
        oldCondition: getMappedOldCondition(currentConditionPortfolioAssets)
      };
    }

    if(!isNewValueDifferent && showDiff){
      continue;
    }

    if (hasOldConditionTiering && newConditionPortfolioAssets) {
      result = {
        ...result,
        newConditionTiering: !specialRequestPm || readOnly
          ? getRangeLabel(newConditionPortfolioAssets)
          : getRangeLabelWithIcon(
            handleAddTier,
            handleRemoveTier,
            handleRangeStartChange,
            handleRangeEndChange,
            newConditionPortfolioAssets,
            i === recurringFees?.newCondition?.portfolioAssets?.length - 1,
            i
          )
      };
    }

    const fixedDiscountCell = (
      <PriceNumberFormat
        data-testid={`${TEST_KEY_PREFIX}-discount`}
        key={`${KEY_PREFIX}-discount`}
        type={PERCENTAGE}
        value={fixedDiscountValue}
      />
    );

    if (newConditionPortfolioAssets) {
      if (
        !!readOnly &&
        recurringFees.currentCondition &&
        ((!currentConditionPortfolioAssets && newConditionPortfolioAssets.effectivePrice) ||
          (currentConditionPortfolioAssets.effectivePrice !== newConditionPortfolioAssets?.effectivePrice
            || currentConditionPortfolioAssets?.rangeStart !== newConditionPortfolioAssets?.rangeStart
            || currentConditionPortfolioAssets?.rangeEnd !== newConditionPortfolioAssets?.rangeEnd))
      ) {
        isNewValueDifferent = true;
      }

      result = {
        ...result,
        newCondition: getMappedNewCondition(
          newConditionPortfolioAssets,
          i,
          recurringFees?.newCondition?.condition?.code,
          handleValidationResultChange,
          handleValueChange,
          readOnly,
          isNewValueDifferent,
          specialRequestPm
        ),
        discount: isPercentageDiscountCondition
          ? fixedDiscountCell
          : getMappedDiscount(newConditionPortfolioAssets)
      };
    }

    results.push(result);
  }

  // Validation result
  const validateTiering = (tiers) => {
    const invalid = (errMsg) => {
      return {isValid: false, errMsg: errMsg};
    };
    tiers.sort();
    if (tiers[0].rangeStart !== 0) {
      return invalid('Range should start from 0');
    }
    let end = 0;
    for (let i = 0; i < tiers.length; i++) {
      if (!tiers[i].rangeStart && tiers[i].rangeStart !== 0) {
        return invalid('Start range cannot be empty');
      }
      if (!tiers[i].rangeEnd && i !== tiers.length - 1) {
        return invalid('End range cannot be empty');
      }
      if (tiers[i].rangeStart > tiers[i].rangeEnd) {
        return invalid('End range cannot be lower than start range');
      }
      if (tiers[i].rangeStart < end) {
        return invalid('Ranges should not overlap');
      }
      if (tiers[i].rangeStart > end) {
        return invalid('There should not be a gap between ranges');
      }
      if (tiers[i].effectivePrice === null) {
        return invalid('Effective price must have a value');
      }
      end = tiers[i].rangeEnd;
    }
    return {isValid: true};
  };

  let validationResult = validateTiering(recurringFees.newCondition.portfolioAssets);
  const selectors = ['portfolioAssets', 'customTiering'];
  if (!validationResult.isValid) {
    if (hasOldConditionTiering) {
      results.push({
        newConditionTiering: (
          <FormControl error>
            <FormHelperText>{validationResult.errMsg}</FormHelperText>
          </FormControl>
        ),
        newCondition: {}
      });
    } else {
      results.push({
        key: 'tier-error',
        label: (
          <FormControl error>
            <FormHelperText>{validationResult.errMsg}</FormHelperText>
          </FormControl>
        ),
        newCondition: {}
      });
    }

    if (!recurringFees.errors.includes(selectors.join())) {
      handleValidationResultChange({isValid: false, selectors: selectors});
    }
  } else if (recurringFees.errors.includes(selectors.join())) {
    handleValidationResultChange({isValid: true, selectors: selectors});
  }

  if (results.length > 1) {
    return results;
  }
  return [];
};

// eslint-disable-next-line react/no-multi-comp
const getRangeLabelWithIcon = (handleAddTier, handleRemoveTier, handleRangeStartChange, handleRangeEndChange, tier, lastRow, index) => {

  return (
    <Box
      data-testid={`${TEST_KEY_PREFIX}-label`}
      style={{display: 'flex', alignItems: 'center', paddingLeft: '5px', whiteSpace: 'nowrap'}}
    >
      {lastRow ? (
        <React.Fragment>
          <PriceNumberFormat
            disableMax
            isEditable
            onValueChange={({value}) => handleRangeStartChange(index, value)}
            placeHolderForZero="0"
            type={CHF}
            value={tier?.rangeStart}
          />
          <span>and above</span>
          <IconButton onClick={() => handleAddTier()}>
            <AddCircleOutlineIcon style={{cursor: 'pointer'}}/>
          </IconButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PriceNumberFormat
            disableMax
            isEditable
            onValueChange={({value}) => handleRangeStartChange(index, value)}
            placeHolderForZero="0"
            type={REGULAR}
            value={tier?.rangeStart}
          />
          <Box>
            <span>to</span>
          </Box>
          <PriceNumberFormat
            disableMax
            isEditable
            onValueChange={({value}) => handleRangeEndChange(index, value)}
            placeHolderForUndefined="above"
            type={CHF}
            value={tier?.rangeEnd}
          />
          <IconButton onClick={() => handleRemoveTier(index)}>
            <RemoveCircleOutlineIcon style={{cursor: 'pointer'}}/>
          </IconButton>
        </React.Fragment>
      )}
    </Box>
  );
};

// eslint-disable-next-line react/no-multi-comp
const getRangeLabel = (tier) => {
  return (
    <Box
      data-testid={`${TEST_KEY_PREFIX}-label`}
      style={{display: 'flex', alignItems: 'center', paddingLeft: '5px', whiteSpace: 'nowrap'}}
    >
      <PriceNumberFormat placeHolderForZero="0" type={REGULAR} value={tier?.rangeStart}/>
      <Box>
        <span>&nbsp;{tier.rangeEnd ? 'to' : 'CHF and'}&nbsp;</span>
      </Box>
      <PriceNumberFormat placeHolderForUndefined="above" type={CHF} value={tier?.rangeEnd}/>
    </Box>
  );
};

const getMappedOldCondition = (tier) => {
  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={BPS}
      value={tier?.effectivePrice}
    />
  );

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (tier, index, conditionCode, handleValidationResultChange, handleValueChange, readOnly, isNewValueDifferent,  specialRequestPm
) => {

  const isEditable =  specialRequestPm ? !readOnly : false;
  const isDisabled = conditionCode === FIXED_AMOUNT.code;

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={
        isDisabled
          ? `${TEST_KEY_PREFIX}-effective-price-disabled`
          : `${TEST_KEY_PREFIX}-effective-price-readonly`
      }
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={BPS}
      value={tier?.effectivePrice}
    />
  );

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-editable`}
      isEditable
      key={`${KEY_PREFIX}-effective-price-editable`}
      onValidationResultChange={(vr) =>
        handleValidationResultChange({
          ...vr,
          selectors: ['portfolioAssets', index, 'effectivePrice']
        })
      }
      onValueChange={(vr) =>
        handleValueChange({...vr, selectors: ['portfolioAssets', index, 'effectivePrice']})
      }
      type={BPS}
      value={tier?.effectivePrice}
    />
  );

  return {
    effectivePrice: isEditable && !isDisabled ? effectivePriceEditable : effectivePriceReadonly,
    isDisabled: isDisabled,
    isEditable: isEditable,
    isHighlighted: isNewValueDifferent,
  };
};

// eslint-disable-next-line react/no-multi-comp
const getMappedDiscount = (newCondition) => {
  const discount = parseFloat(getDiscountFromEffectivePrice(newCondition));

  return (
    !isNaN(discount) && (
      <PriceNumberFormat
        data-testid={`${TEST_KEY_PREFIX}-discount`}
        key={`${KEY_PREFIX}-discount`}
        type={PERCENTAGE}
        value={discount}
      />
    )
  );
};
