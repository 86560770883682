import React, {useContext, useEffect, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Skeleton from '@material-ui/lab/Skeleton';
import {Box, Paper} from '@material-ui/core';
import BoxHeader from '../../../../../Common/components/BoxHeader';
import {Store} from '../../../../../../Store';
import {acmDashboardColumnData} from '../columnData';
import api from '../../../../../../api/api';
import StatusFilter from '../StatusFilter';
import StatusTable from '../StatusTable';
import {useDebounce} from '../../../../../../common/useDebounce';

const AccountManagementTab = () => {
  const [priceRequests, setPriceRequests] = useState();
  const [paginationInfo, setPaginationInfo] = useState({page: 0});
  const [totalPages, setTotalPages] = useState();
  const {parentState} = useContext(Store);
  const [isLoading, setLoading] = useState(true);

  const mapBankingFees = data => ({
    ...data,
    bankingServicePackageFee: data?.bankingFees?.currentConditionCodeFees?.BANKING_SERVICE_FEE?.effectivePrice,
    specialMailingFee: data?.bankingFees?.currentConditionCodeFees?.SPECIAL_MAILING?.effectivePrice,
    reportingFee: data?.bankingFees?.currentConditionCodeFees?.REPORTING_FEE?.effectivePrice,
    codedAccountFee: data?.bankingFees?.currentConditionCodeFees?.CODED_ACCOUNT?.effectivePrice,
    retainedMailFee: data?.bankingFees?.currentConditionCodeFees?.RETAINED_MAIL?.effectivePrice
  });

  const getPricingRequestExceptThoseRaisedByCurrentUser = data =>
    data.map(mapBankingFees).filter(pr => pr.requestByUserId !== parentState.user?.id);

  const fetchData = async params => {
    setLoading(true);
    const response = await api.pricingRequest.listPendingAcm(params);
    console.log('ress', response);
    setPriceRequests(getPricingRequestExceptThoseRaisedByCurrentUser(response.data.content));
    setPaginationInfo({page: params?.page || 0});
    setTotalPages(response.data.totalPages);
    setLoading(false);
  };


  useEffect(() => {
    if (parentState.user?.id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentState.user?.id]);

  const [columnsData, setColumnsData] = useState(acmDashboardColumnData);
  const [groupBy, setGroupBy] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterCriteria, setFilterCriteria] = useState('');
  const debouncedFilterCriteria = useDebounce(filterCriteria, 500);

  const handleSelectGroup = e => setGroupBy(e.target.value);
  const handleSelectFilter = e => setFilterBy(e.target.value);
  const handleFilterCriteriaInput = e => setFilterCriteria(e.target.value);
  const handleSelectedColumns = value => {
    const newColumnsData = cloneDeep(columnsData);
    newColumnsData.forEach(column => {
      if (value[column.key] !== undefined) {
        column.hidden = !value[column.key];
      }
    });
    setColumnsData(newColumnsData);
  };

  const goToPage = page => {
    fetchData({page});
  };

  console.log('colum data', columnsData);
  console.log('priceRequests', priceRequests);

  return (
    <Paper
      elevation={1}
      style={{
        padding: '10px'
      }}
      variant="elevation"
    >
      <BoxHeader text={'Status'}/>
      {!isLoading ? (
        priceRequests ? (
          <>
            <StatusFilter
              columnsData={columnsData}
              filterCriteria={filterCriteria}
              handleFilterCriteriaInput={handleFilterCriteriaInput}
              handleSelectFilter={handleSelectFilter}
              handleSelectGroup={handleSelectGroup}
              handleSelectedColumns={handleSelectedColumns}
              selectedFilter={filterBy}
              selectedGroup={groupBy}
            />
            <StatusTable
              columnsData={columnsData}
              fetchData={fetchData}
              filterByKey={filterBy}
              filterCriteria={debouncedFilterCriteria}
              goToPage={goToPage}
              groupByKey={groupBy}
              paginationInfo={{...paginationInfo, totalPages}}
              priceRequests={priceRequests}
              setPriceRequests={setPriceRequests}
            />
          </>
        ) : (
          <Box>No entry found.</Box>
        )
      ) : (
        <Skeleton animation="wave"/>
      )}
    </Paper>
  );
};

export default AccountManagementTab;
