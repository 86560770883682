import {DPM, IA_DIRECT, IA_INDIRECT} from './serviceModel';

export const BONDS = Object.freeze({
  code: 'BONDS',
  desc: 'BONDS',
  sortOrder: 1
});
export const EQUITY_AND_EXCHANGE_TRADED_FUNDS = Object.freeze({
  code: 'EQUITY_AND_EXCHANGE_TRADED_FUNDS',
  desc: 'EQUITY AND EXCHANGE-TRADE FUNDS (ETF)',
  sortOrder: 0
});
export const EXCHANGE_TRADED_OPTIONS = Object.freeze({
  code: 'EXCHANGE_TRADED_OPTIONS',
  desc: 'EXCHANGE TRADE OPTIONS',
  sortOrder: 4
});
export const FIDUCIARY_INVESTMENTS = Object.freeze({
  code: 'FIDUCIARY_INVESTMENTS',
  desc: 'FIDUCIARY INVESTMENTS',
  sortOrder: 5
});
export const INVESTMENT_FUNDS = Object.freeze({
  code: 'INVESTMENT_FUNDS',
  desc: 'INVESTMENT FUNDS - PRIMARY MARKET',
  sortOrder: 2
});

export const getTransactionFeeFundTypeFromCode = code => {
  return getAllTransactionFeeFundTypes().filter(condition => condition.code === code)[0];
};

export const getTransactionFeeFundTypeDescriptionFromCode = code => {
  return getAllTransactionFeeFundTypes()
    .filter(condition => condition.code === code)
    .map(condition => condition.desc)[0];
};

export const getTransactionFeeFundTypeFromDescription = description => {
  const filtered = getAllTransactionFeeFundTypes().filter(condition => condition.desc === description);
  if(filtered){
    return filtered[0];
  }
  return {};
};

export const getAllTransactionFeeFundTypes = () => {
  return [
    BONDS,
    EQUITY_AND_EXCHANGE_TRADED_FUNDS,
    EXCHANGE_TRADED_OPTIONS,
    FIDUCIARY_INVESTMENTS,
    INVESTMENT_FUNDS
  ];
};

export const getFeeTypeDescription = serviceModel => {
  switch (serviceModel) {
    case DPM:
      return 'Management Fee';
    case IA_DIRECT:
    case IA_INDIRECT:
      return 'Advisory Fee';
    default:
      return 'Custody Fee';
  }
};
