import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import AgreementTypeIndicator from '../../Common/AgreementTypeIndicator';
import NMBFeeSubAssetConditionTable from './NMBFeeSubAssetConditionTable';
import {BILA} from '../../../../../common/enums/agreementType';
import {PENDING_PM} from '../../../../../common/statusTypes';

const NMBFeePanel = ({
  agentType, fundTypeCode, handleValidationResultChange, handleValueChange, pricingRequestStatus,
  readOnly, showAgreementIndicator, serviceModel, subFund
}) => {

  return (
    <Box padding={'10px'} width={1}>
      <AgreementTypeIndicator
        agreedWithClient={subFund?.newCondition?.agreementEnum?.code}
        handleChange={(val) => {
          handleValueChange({value: val ? {...BILA} : null, selectors: ['newCondition', 'agreementEnum']})
        }}
        other={{inputProps:{'data-testid': `test-agreement-type-indicator-${subFund.transactionAssetSubLevel.code}-checkbox`}}}
        readOnly={readOnly || pricingRequestStatus === PENDING_PM}
        show={showAgreementIndicator}
      />


      <NMBFeeSubAssetConditionTable
        agentType={agentType}
        fundTypeCode={fundTypeCode}
        handleValidationResultChange={handleValidationResultChange}
        handleValueChange={handleValueChange}
        newConditionCode={subFund?.newCondition?.transactionFeeCondition?.code}
        oldConditionCode={subFund?.currentCondition?.transactionFeeCondition?.code}
        pricingRequestStatus={pricingRequestStatus}
        readOnly={readOnly}
        serviceModel={serviceModel}
        subFund={subFund}
      />
    </Box>
  )
};

NMBFeePanel.propTypes = {
  agentType: PropTypes.string,
  fundTypeCode: PropTypes.string.isRequired,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  pricingRequestStatus: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  serviceModel: PropTypes.string.isRequired,
  showAgreementIndicator: PropTypes.bool,
  subFund: PropTypes.object.isRequired
};

export default React.memo(NMBFeePanel);
