import React from 'react';
import {CHF, PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {isNewBankingFeeDifferent} from '../isNewBankingFeeDifferent';

const LABEL = 'Coded Account';
const KEY_PREFIX = 'banking-fees-coded-account';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const codedAccount = ({fee, handleValidationResultChange, handleCodedAccount, readOnly, showDiff}) => {

  let result = {
    label: LABEL
  };
  let isNewValueDifferent = !!readOnly && isNewBankingFeeDifferent(fee, 'CODED_ACCOUNT');

  if(!isNewValueDifferent && showDiff){
    return null;
  }

  if (fee?.currentConditionCodeFees?.CODED_ACCOUNT) {
    result = {
      ...result,
      oldCondition: getMappedOldCondition(fee?.currentConditionCodeFees?.CODED_ACCOUNT)
    }
  }

  if (fee?.newConditionCodeFees?.CODED_ACCOUNT) {
    result = {
      ...result,
      newCondition: getMappedNewCondition(fee?.newConditionCodeFees?.CODED_ACCOUNT, handleValidationResultChange, handleCodedAccount, readOnly, isNewValueDifferent)
    }
  }

  return{ ...result}
};

const getMappedOldCondition = (fee) => {
  // console.log("getMappedOldCondition fee Coded Account ---->>>", fee);
  if (!fee) {
    return null;
  }

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (fee, handleValidationResultChange, handleCodedAccount, readOnly, isNewValueDifferent) => {
  // console.log("getMappedNewCondition fee Coded Account---->>>", fee);
  if (!fee) {
    return null;
  }

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-editable`}
      decimal={0}
      disableEmptyInput
      isEditable={!readOnly}
      max={fee?.listPrice}
      onValidationResultChange={({isValid}) => handleValidationResultChange && handleValidationResultChange('CODED_ACCOUNT', isValid)}
      onValueChange={({value, isValid}) => handleValidationResultChange && handleCodedAccount(value, isValid)}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={fee?.effectivePrice}
    />
  );

  const listPriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      type={CHF}
      value={fee?.listPrice}
    />
  );

  const discount = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-discount`}
      type={PERCENTAGE}
      value={fee?.discountPercentage}
    />
  );

  // New condition Coded Account should not have any value under normal circumstance.
  // Admin feature in the future might allow this value to be edited.
  return {
    discount: discount,
    effectivePrice: readOnly ? effectivePriceReadonly : effectivePriceEditable,
    isEditable: !readOnly,
    isHighlighted: isNewValueDifferent,
    listPrice: listPriceReadonly
  };
};
