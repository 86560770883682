import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Box, Grid, Typography} from '@material-ui/core';
import PricingRequestTable from './components/PricingRequestTable';
import PricingRequestFilter from './components/PricingRequestFilter';
import adminApi from '../../../../api/adminApi';
import {useNotification} from '../../../Common/components/Notification';
import {DESC} from '../../../../common/sortDirection';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import AsyncButton from '../../../Common/components/AsyncButton';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '500px',
    padding: theme.spacing(4)
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

const PricingRequest = () => {
  const CREATED_DATE_COLUMN = 'createdDate';

  const classes = useStyles();
  const notification = useNotification();

  const [pricingRequestData, setPricingRequestData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState();
  const [selectedPricingRequestIdFilter, setSelectedPricingRequestIdFilter] = useState();
  const [selectedPricingStatusFilter, setSelectedPricingStatusFilter] = useState();
  const [selectedT24StatusFilter, setSelectedT24StatusFilter] = useState();
  const [portfolioFilter, setPortfolioFilter] = useState('');
  const [requiresRefresh, setRequiresRefresh] = useState(false);

  const [isLoading, setIsLoading] = useState();

  const fetchData = params => {
    setIsLoading(true);
    return adminApi.pricingAdmin
      .getPricingRequests({
        ...params,
        pricingRequestStatus: selectedPricingStatusFilter,
        pricingRequestId: selectedPricingRequestIdFilter,
        t24SyncStatus: selectedT24StatusFilter,
        portfolioSearch: portfolioFilter,
        sortBy: params?.sortBy || CREATED_DATE_COLUMN,
        sortDirection: params?.sortDirection || DESC
      })
      .then(res => {
        setPricingRequestData(res.data?.elements);
        setPaginationInfo(res.data?.pagingMetaData);
        setIsLoading(false);
      })
      .catch((err) => {
        notification.error('Could not load data.\n' + getErrorMessage(err));
      });
  };

  const handleFilterPricingStatus = e => {
    setSelectedPricingStatusFilter(e.target.value);
  };

  const handleSelectedPricingRequestId = e => {
    setSelectedPricingRequestIdFilter(e.target.value);
  };

  const handleFilterT24Status = e => {
    setSelectedT24StatusFilter(e.target.value);
  };

  const handlePortfolioFilter = e => {
    setPortfolioFilter(e.target.value);
  };

  const handleFind = e => {
    setRequiresRefresh(e.target.value + Math.random())
  };

  const refresh = () => {
    return fetchData();
  }

  const goToPage = page => {
    fetchData({
      page,
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPricingStatusFilter, selectedT24StatusFilter, requiresRefresh]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h1">
            Pricing Requests
          </Typography>
          <AsyncButton onClick={refresh} text="Refresh"/>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <PricingRequestFilter
          currentPortfolioFilter={portfolioFilter}
          handleFilterPricingStatus={handleFilterPricingStatus}
          handleFilterT24Status={handleFilterT24Status}
          handleFind={handleFind}
          handlePortfolioFilter={handlePortfolioFilter}
          handlePricingRequestIdFilter={handleSelectedPricingRequestId}
          selectedPricingStatusFilter={selectedPricingStatusFilter}
          selectedT24StatusFilter={selectedT24StatusFilter}

        />
        {isLoading ? <LoadingSpinner/> : (
          <PricingRequestTable
            fetchData={fetchData}
            filterByPricingRequestStatus={selectedPricingStatusFilter}
            filterByT24Status={selectedT24StatusFilter}
            goToPage={goToPage}
            paginationInfo={paginationInfo}
            pricingRequestData={pricingRequestData}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PricingRequest;
