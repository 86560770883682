import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {getFormLabel} from '../../../../Common/components/form/formLabel'
import {getFormText} from '../../../../Common/components/form/formText'
import PriceSelect from '../../../../Common/components/PriceSelect';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {PERCENTAGE} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {FORM_BACKGROUND_COLOR} from '../../../../../theme/common/color';

const useStyles = makeStyles(() => ({
  rootForEditableField: {
    backgroundColor: FORM_BACKGROUND_COLOR
  },
  label: {
    ...getFormLabel()
  },
  text: {
    width: '100%',
    ...getFormText()
  }
}));

const OverdraftPricing = ({label, overdraft, operatorOptions, onOperatorChange, onValidationResultChange, onPricingPercentageChange, readOnly}) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.rootForEditableField}
      container
      spacing={1}
    >
      {
        label && (
          <Grid
            className={classes.label}
            item
            xs={4}
          >
            {label}
          </Grid>
        )
      }
      <Grid item xs={1}>
        <PriceSelect
          data-testid={'overdraft-operation-select'}
          inputProps={{name: 'overdraftOperator', id: 'uncontrolled-native'}}
          key={'overdraft-operation-select'}
          onValueChange={onOperatorChange}
          options={operatorOptions}
          readOnly={readOnly}
          value={overdraft.overdraftOperator}
        />
      </Grid>
      <Grid item xs={1}>
        <PriceNumberFormat
          data-testid={'overdraft-percentage'}
          isEditable={!readOnly}
          max={100}
          onValidationResultChange={({isValid}) => onValidationResultChange('OVERDRAFT_INVALID_PRICING_PERCENTAGE', isValid)}
          onValueChange={({value, isValid}) => onPricingPercentageChange(value, isValid)}
          type={PERCENTAGE}
          value={overdraft.overdraftPricingInPercentage}
        />
      </Grid>
    </Grid>
  );
};

OverdraftPricing.propTypes = {
  label: PropTypes.string.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  onPricingPercentageChange: PropTypes.func.isRequired,
  onValidationResultChange: PropTypes.func.isRequired,
  operatorOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  overdraft: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

export default OverdraftPricing;
