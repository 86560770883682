import React from 'react';
import PriceConditionTableColumn from './PriceConditionTableColumn';
import {EDITABLE, LABEL, TEXT} from './PriceConditionType';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../common/constants';
import NMBCalculationBasis from '../../../../views/PriceSimulation/components/NMBFees/panel/NMBCalculationBasis';
import PriceConditionTableRowSection from './PriceConditionTableRowSection';
import PropTypes from 'prop-types';

const PriceConditionCalculationBasisRow = ({ newConditionLabel, showOldCondition, showNewCondition, oldConditionValue, newConditionValue, onChange, readOnly, columnProps, testIdSuffix }) => {
  if(showNewCondition || showOldCondition) {
    return <PriceConditionTableRowSection data={[]}>
      <PriceConditionTableColumn field="label" type={LABEL}/>
      {showOldCondition && <>
        <PriceConditionTableColumn
          borderRight
          customStyles={OLD_CONDITION_CUSTOM_STYLE}
          field="label"
          label={oldConditionValue}
          type={TEXT}
        /></>}
      {showNewCondition && <>
        <PriceConditionTableColumn
          field="label"
          label={`${!readOnly ? newConditionLabel : ''}`}
          type={TEXT}
        />
        <PriceConditionTableColumn
          field="label"
          label={
            <NMBCalculationBasis
              calculationBasis={newConditionValue}
              editableProps={{ 'data-testid': `test-calculation-basis-${testIdSuffix}-editable` }}
              fullWidth
              handleChange={onChange}
              readOnly={readOnly}
              readonlyProps={{ 'data-testid': `test-calculation-basis-${testIdSuffix}-readonly` }}
            />}
          noBorder
          type={!readOnly ? EDITABLE : ''}
          {...columnProps}
        /></>}
    </PriceConditionTableRowSection>
  }
  return null;
}

PriceConditionCalculationBasisRow.propTypes = {
  columnProps: PropTypes.object,
  newConditionLabel: PropTypes.string,
  newConditionValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  oldConditionValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showNewCondition: PropTypes.bool.isRequired,
  showOldCondition: PropTypes.bool.isRequired,
  testIdSuffix: PropTypes.string
};

export default PriceConditionCalculationBasisRow;
