import {isNullOrUndefined} from '../../../../common/isNullOrUndefined';

export const showOverridden = ({specialRequest, readOnly, overriddenValue}) => {
  if (!specialRequest) {
    return false;
  }
  //PM view
  if (!readOnly) {
    return true;
  }
  // RM view
  if (isNullOrUndefined(overriddenValue)) {
    return false;
  }
  return true;
}