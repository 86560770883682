import React, { useEffect, useRef } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { getFormLabel } from '../formLabel';
import { getFormText } from '../formText';

const useStyles = makeStyles(() => ({
  label: {
    ...getFormLabel()
  },
  text: {
    ...getFormText()
  }
}));

const LabelText = ({ name, label, text, onInit, isCurrency, ...others }) => {
  const classes = useStyles();
  const onMount = useRef(true);
  useEffect(() => {
    if (onInit && onMount.current) {
      onInit({ target: { name: name, value: text } });
      onMount.current = false;
    }
  }, [text, name, onInit]);

  return (
    <Grid container spacing={1}>
      <Grid className={classes.label} item xs={4}>
        {label}
      </Grid>
      {isCurrency ? (
        <Grid className={classes.text} item xs={8}>
          <NumberFormat
            {...others}
            className={classes.text}
            displayType={'text'}
            suffix="  CHF"
            thousandSeparator
            value={text}
          />
        </Grid>
      ) : (
        <Grid className={classes.text} item xs={8} {...others}>
          {text}
        </Grid>
      )}
    </Grid>
  );
};

LabelText.propTypes = {
  isCurrency: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
  onInit: PropTypes.any,
  text: PropTypes.any
};

export default LabelText;
