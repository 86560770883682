import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import AsyncButton from '../AsyncButton';

const ServiceModelChangeDialog = ({onClose, onContinue, open, text}) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>ALERT</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {onContinue && (
          <AsyncButton color="primary" onClick={onContinue} text="Continue"/>
        )}
        <Button onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ServiceModelChangeDialog.propTypes = {
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  open: PropTypes.bool,
  text: PropTypes.string
}

export default ServiceModelChangeDialog;
