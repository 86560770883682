import {UPDATE_BANKING_SERVICE, UPDATE_BANKING_SERVICE_FEES_VALIDATION_RESULT} from './bankingServiceFeeActions';
import {LOAD_PRICING_REQUEST_RESPONSE} from '../pricingRequestActions';

export const bankingServiceFeeReducer = (state, action) => {
  switch (action.type) {
    case LOAD_PRICING_REQUEST_RESPONSE: {
      if (!action.payload.data.bankingFees) {
        return null;
      }

      return {
        ...action.payload.data.bankingFees,
        errors: []
      };
    }
    case UPDATE_BANKING_SERVICE: {
      return {
        ...state, ...action.data
      };
    }
    case UPDATE_BANKING_SERVICE_FEES_VALIDATION_RESULT: {
      if (!state) {
        return state;
      }
      let newErrors = (state.errors || []).filter(e => e !== action.selector);
      if (!action.isValid) {
        newErrors = [...state.errors, action.selector]
      }
      return {
        ...state,
        errors: newErrors
      };
    }
    default:
      return state;
  }
}
