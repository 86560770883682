import React from 'react';
import {CHF} from '../../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import PriceNumberFormat from '../../../../../Common/components/PriceNumberFormat';
import {TICKET_FEE} from '../../../../../../common/enums/transactionFeeCondition';
import {isNullOrUndefined} from '../../../../../../common/isNullOrUndefined';

const LABEL = 'Ticket Fee';
const KEY_PREFIX = 'transaction-fees-ticket-fee';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const ticketFee = ({oldConditionCode, newConditionCode, subFund, handleValidationResultChange, handleValueChange, readOnly}) => {

  let result = {
    label: LABEL
  };

  if (!isNullOrUndefined(subFund.currentCondition?.ticketFee)) {
    result = {
      ...result,
      currentCondition: getMappedOldCondition(subFund.currentCondition?.ticketFee, oldConditionCode)
    }
  }

  return {
    ...result,
    newCondition: getMappedNewCondition(subFund.newCondition?.ticketFee, newConditionCode, handleValidationResultChange, handleValueChange, readOnly)
  }

};

const getMappedOldCondition = (ticketFee, conditionCode) => {
  if (conditionCode !== TICKET_FEE.code) {
    return null;
  }

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={CHF}
      value={ticketFee}
    />);
  return {
    value: readonlyField
  };
};

const getMappedNewCondition = (ticketFee, conditionCode, handleValidationResultChange, handleValueChange, readOnly) => {
  if (conditionCode !== TICKET_FEE.code) {
    return null;
  }

  const editableField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-editable`}
      disableEmptyInput
      isEditable
      key={`${KEY_PREFIX}-editable`}
      onValidationResultChange={(vr) => handleValidationResultChange({...vr, selectors: ['newCondition', 'ticketFee']})}
      onValueChange={(vr) => handleValueChange({...vr, selectors: ['newCondition', 'ticketFee']})}
      type={CHF}
      value={ticketFee}
    />);

  const readonlyField = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-readonly`}
      key={`${KEY_PREFIX}-readonly`}
      type={CHF}
      value={ticketFee}
    />);

  return {
    value: readOnly ? readonlyField : editableField,
    isEditable: !readOnly
  };
};
