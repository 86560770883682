import moment from 'moment';
import {isEmpty} from 'underscore';
import {isNullOrUndefined} from './isNullOrUndefined';

const hasMaxItems = (max) => (items) => {
  const isValid = !items || items.length <= max;
  return createValidationResult(isValid, `Max ${max} items`);
}

const hasMaxLength = (max) => (value) => {
  const isValid = !value || value.length <= max;
  return createValidationResult(isValid, `Max ${max} characters`);
}

const hasMinLength = (min) => (value) => {
  const isValid = value && value.length >= min;
  return createValidationResult(isValid, `Min ${min} characters`);
}

const isMaxDecimalPlaces = (max) => (value) => {
  if (value && value.toString().includes('.')) {
    const isValid = value.toString().split('.')[1].length <= max;
    return createValidationResult(isValid, `No more than ${max} decimal places are allowed`);
  }
  return createValidationResult(true);
}

const isInteger = (value) => {
  const isValid = !value || (!isNaN(value) && Number.isInteger(+value));
  return createValidationResult(isValid, 'It must be a number');
}

const isNumber = (value) => {
  const isValid = !value || !isNaN(value);
  return createValidationResult(isValid, 'It must be a number');
}

const isMax = (max) => (value) => {
  if (value && max) {
    const isValid = parseFloat(value) <= parseFloat(max);
    return createValidationResult(isValid, `Number may not exceed ${max}`);
  }
  return createValidationResult(true);
}

const isNonNegative = (value) => {
  const isValid = !value || isNaN(value) || value >= 0;
  return createValidationResult(isValid, 'It must be a non-negative number');
}

const isNotBackDated = (value) => {
  const isValid = !value || moment(value).isAfter();
  return createValidationResult(isValid, 'No backdating allowed');
}

const isNotEmpty = (value) => {
  let isValid = true;
  if (value === '' || isNullOrUndefined(value) || (isNaN(value) && isEmpty(value))) {
    isValid = false;
  }

  return createValidationResult(isValid, 'Field should not be empty')
}

const isNotGreaterThanTwoYears = (value) => {
  const isValid = !value || moment(value).isSameOrBefore(moment().add(2, 'year'));
  return createValidationResult(isValid, 'Period of validity is greater than 2 years from current date');
}

const isPercentageValid = (percentage) => {
  const isValid = !percentage || isNaN(percentage) || (percentage >= 0 && percentage <= 100);
  return createValidationResult(isValid, 'Value must be between 0 to 100');
}

const createValidationResult = (isValid, errorMessage) => {
  if (isValid) {
    return {
      isValid: true
    };
  }
  return {
    isValid: false,
    errorMessage: errorMessage
  };
}

export default {
  hasMaxItems,
  hasMaxLength,
  hasMinLength,
  isInteger,
  isNumber,
  isMax,
  isMaxDecimalPlaces,
  isNonNegative,
  isNotBackDated,
  isNotEmpty,
  isNotGreaterThanTwoYears,
  isPercentageValid
};
