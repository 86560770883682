import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {useHistory} from 'react-router-dom';
import {Box, Grid, MenuItem, Paper, Select} from '@material-ui/core';
import {Sidebar, TopBar} from '../Common/components';
import {IntlProvider} from 'react-intl';
import {AdminNav} from './components';
import {MAIN_BACKGROUND_COLOR} from '../../theme/common/color';
import {CLIPP_WHITE} from '../../theme/common/bjbColor';
import {X_TENANT_ID} from '../../common/headers';
import {HONGKONG, SINGAPORE} from '../../common/enums/bookingCenter';

const useStyles = makeStyles(() => ({
  content: {
    minHeight: '500px',
    padding: '10px',
    boxShadow:
      'inset 0px 15px 15px -10px rgba(0,0,0,0.2), inset 0px -15px 15px -10px rgba(0,0,0,0.2)',
    backgroundColor: MAIN_BACKGROUND_COLOR
  },
  selectTenant: {
    color: CLIPP_WHITE,
  }
}));

const Admin = ({children}) => {
  const SG_ID = SINGAPORE.desc,
    HK_ID = HONGKONG.desc;

  const classes = useStyles();
  const history = useHistory();
  const currentId = sessionStorage.getItem(X_TENANT_ID) || SG_ID;

  const changeTenantId = e => {
    sessionStorage.setItem(X_TENANT_ID, e.target.value);
    history.push('/admin/');
  };

  return (
    <div>
      <TopBar logo/>
      <div className={classes.content}>
        <Grid
          alignItems="stretch"
          container
          direction="row"
          justify="center"
          spacing={1}
        >
          <Grid item lg={2} md={3} xs={12}>
            <Sidebar title="Admin Page">
              <Box marginBottom={1} padding={1}>
                <Select
                  className={classes.selectTenant}
                  onChange={changeTenantId}
                  value={currentId}
                >
                  <MenuItem value={SG_ID}>Singapore</MenuItem>
                  <MenuItem value={HK_ID}>Hong Kong</MenuItem>
                </Select>
              </Box>
              <Paper elevation={0} variant={'elevation'}>
                <AdminNav/>
              </Paper>
            </Sidebar>
          </Grid>
          <Grid item lg={10} md={9} xs={12}>
            <Paper>
              <IntlProvider locale={'en'}>
                <main>{children}</main>
              </IntlProvider>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Admin.propTypes = {
  children: PropTypes.node
};

export default Admin;
