import { jbColors } from '../jbColors';

const MuiCssBaseline = {
  '@global': {
    '*::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: '#F5F5F5',
    },
    '*::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: jbColors.white,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: jbColors.greenSmoke['80'],
      border: '2px solid',
      borderColor: jbColors.greenSmoke['80'],
    },
  },
};

export default MuiCssBaseline;
