import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {StackedHorizontalBarChart} from 'react-stacked-horizontal-bar-chart';
import PropTypes from 'prop-types';
import CustomMarker from './components/CustomMarker';
import {NEW_MARKER, OLD_MARKER} from './enum/MarkerType';

const useStyles = makeStyles(() => ({
  alleviated_marker: {
    transform: 'translate(-50%, 14px) !important',
  },
  container: {
    paddingTop: '115px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '5px',
    transform: 'scaleX(-1)'
  }
}));

const PeerComparisonHeatMap = ({peerData}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <StackedHorizontalBarChart
        backgroundColors={[
          'linear-gradient(to right, #1e9600, #fff200)',
          'linear-gradient(to right, #fff200, #ff8000)',
          'linear-gradient(to right, #ff8000, #ff8000)',
          'linear-gradient(to right, #ff8000, #ff0000)'
        ]}
        classes={{alleviated_marker: 'alleviated_marker'}}
        height={20}
        points={[
          {
            value: peerData?.newDiscMarker?.value,
            marker: <CustomMarker markerData={peerData?.newDiscMarker} markerType={NEW_MARKER}/>
          },
          {
            value: peerData?.oldDiscMarker?.value,
            marker: <CustomMarker markerData={peerData?.oldDiscMarker} markerType={OLD_MARKER}/>
          }
        ]}
        rangesPoints={peerData?.rangePoints ? [...peerData?.rangePoints] : []}
      />
      <br/>
    </div>
  );
};

PeerComparisonHeatMap.propTypes = {
  peerData: PropTypes.shape({
    KeyDate: PropTypes.object,
    rangePoints: PropTypes.arrayOf(PropTypes.number),
    errorCode: PropTypes.arrayOf(PropTypes.string),
    oldDiscMarker: PropTypes.object,
    newDiscMarker: PropTypes.object
  }),
};

export default PeerComparisonHeatMap;