import {Box, Button, Typography} from '@material-ui/core';
import {SELECT_PORTFOLIOS} from '../../../../../common/actionTypes';
import {getPortfoliosWithPortfolioDetails} from '../../../../../common/getPortfoliosWithPortfolioDetails';
import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Store} from '../../../../../Store';
import CommonDialog from '../../../../Common/components/Dialog/CommonDialog';
import {useNotification} from '../../../../Common/components/Notification';
import PriceSelect from '../../../../Common/components/PriceSelect';
import {PriceRequestStore} from '../../../PriceSimulationStore';
import api from '../../../../../api/api';
import {getErrorMessage} from '../../../../../common/getErrorMessage';

const CopyEamPortfolio = () => {
  const notification = useNotification();
  const history = useHistory();
  const {parentState, parentDispatch} = useContext(Store);
  const {state} = useContext(PriceRequestStore);
  const [portfolioNumberToCopy, setPortfolioNumberToCopy] = useState();
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);

  const currentPortfolioNumber = state.selectedPricingRequest.common.portfolioNumber;

  const copyPortfolio = () => {
    return new Promise((asyncBtnResolve, asyncBtnReject) => {
      api.pricingRequest
        .copy(state.selectedPricingRequest.common.pricingRequestId, portfolioNumberToCopy)
        .then(response => {
          // Update with new pricing request
          let oldPortfolioIndex = parentState.portfolios.findIndex(
            portfolio => portfolio.portfolioNumber === state.selectedPricingRequest.common.portfolioNumber
          );

          const newlySelectedPortfolios = [...parentState.portfolios];
          newlySelectedPortfolios[oldPortfolioIndex] = getPortfoliosWithPortfolioDetails(
            [response.data],
            parentState.portfolios
          )[0];
          parentDispatch({type: SELECT_PORTFOLIOS, payload: newlySelectedPortfolios});
          notification.success(`Special condition copied successfully from ${portfolioNumberToCopy}`);
          history.push('/simulation/' + response.data.pricingRequestId);
          setIsCopyDialogOpen(false);
          setPortfolioNumberToCopy(null);
          asyncBtnResolve();
        })
        .catch(err => {
          notification.error('Failed copying special condition\n' + getErrorMessage(err));
          asyncBtnReject();
        });
    });
  };

  const handleChangePortfolioToCopy = e => {
    setPortfolioNumberToCopy(e.value);
  };

  const options = parentState.eamPortfoliosToCopy
    ?.filter(portfolio => portfolio.portfolioNumber !== state.selectedPricingRequest.common.portfolioNumber)
    .map(portfolio => ({
      key: portfolio.portfolioNumber,
      value: portfolio.portfolioNumber
    }));

  return (
    <React.Fragment>
      <CommonDialog
        onClose={() => setIsCopyDialogOpen(false)}
        onContinue={copyPortfolio}
        open={isCopyDialogOpen}
        text={`You are going to copy the special condition from ${portfolioNumberToCopy} into ${currentPortfolioNumber}. All changes will be lost and a new pricing request will be created.`}
      />
      <Box display="flex" marginBottom={1} padding={1}>
        <Typography variant="h6">Duplicate special condition from:</Typography>
        {options.length > 0 ? (
          <PriceSelect
            inputProps={{id: 'uncontrolled-native'}}
            onValueChange={handleChangePortfolioToCopy}
            options={options}
            value={portfolioNumberToCopy || ''}
          />
        ) : (
          <PriceSelect options={[{key: 0, value: 'No portfolio available'}]} readOnly value={0}/>
        )}
        <Box marginLeft={2}>
          <Button disabled={!portfolioNumberToCopy} onClick={() => setIsCopyDialogOpen(true)}>
            Copy
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default CopyEamPortfolio;
