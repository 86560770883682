import React, {useContext} from 'react';
import {Paper, Typography} from '@material-ui/core';
import HomeTab from './components/HomeTab';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {Store} from '../../Store';

const useStyles = makeStyles(() => ({
  appTitle: {
    backgroundColor: '#141e55',
    padding: '10px',
    textAlign: 'center',
    marginBottom: '2px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    color: '#FFF',
    opacity: '0.8',
    fontFamily: '\'Verlag SSm 7r\''
  },
  homePanel: {
    boxShadow: '0px 30px 20px -15px #bbb',
    padding: '10px',
    backgroundColor: '#fff',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '650px',
    padding: '50px 20px 50px 20px',
  },
  item: {
    minWidth: '500px'
  }
}));

const Home = () => {
  const classes = useStyles();
  const {parentState} = useContext(Store);

  return (
    <div className={classes.container}>
      {parentState.user && (
        <Box className={classes.item}>
          <Typography className={classes.appTitle} variant="h4">
            CLIENT PRICING PORTAL (CliPP)
          </Typography>
          <Paper className={classes.homePanel} elevation={0}>
            <HomeTab/>
          </Paper>
        </Box>
      )}
    </div>
  );
};

export default Home;
