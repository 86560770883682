import React, {useEffect, useState} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {getFormLabel} from '../formLabel';
import {getFormText} from '../formText';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import {FORM_BACKGROUND_COLOR} from '../../../../../theme/common/color';
import HoverInfo from '../../HoverInfo';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  rootForEditableField: {
    backgroundColor: FORM_BACKGROUND_COLOR
  },
  label: {
    ...getFormLabel()
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%',
    ...getFormText()
  },
  editableText: {
    textAlign: 'end'
  },
  infoIcon: {
    marginLeft: theme.spacing(0.5)
  }
}));

const LabelSelect = ({infoText, label, options, value, onChange, readOnly, ...others}) => {
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    if (value) {
      setDefaultValue(value);
    }
  }, [value]);

  let menuItems = [];
  for (let option of options || []) {
    menuItems.push(
      <MenuItem key={option.key} value={option.key}>
        {option.desc}
      </MenuItem>
    );
  }

  const currentItemDescription = (options || []).filter(o => o.key === defaultValue)[0]?.desc;

  return (
    <Grid
      className={(menuItems?.length || 0) > 1 && !readOnly ? classes.rootForEditableField : ''}
      container
      spacing={1}
    >
      {label && (
        <Grid className={classes.label} item xs={4}>
          {label}

          {infoText &&
          <HoverInfo text={infoText}><InfoIcon className={classes.infoIcon} fontSize="small"/> </HoverInfo>}
        </Grid>
      )}
      <Grid item xs={8}>
        {(menuItems?.length || 0) > 1 && (
          <TextField
            className={classes.editableText}
            disabled={readOnly}
            fullWidth
            inputProps={{'data-testid': others['data-testid']}}
            onChange={onChange}
            select
            value={defaultValue}
          >
            {menuItems}
          </TextField>
        )}
        {(menuItems?.length || 0) <= 1 && (
          <Box className={classes.text} data-testid={others['data-testid']}>
            {currentItemDescription}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

LabelSelect.propTypes = {
  infoText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  value: PropTypes.any
};

export default LabelSelect;
