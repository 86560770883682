import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import LabelDropdown from '../../../../Common/components/tableFilter/LabelDropdown';
import LabelTextField from '../../../../Common/components/tableFilter/LabelTextField';

const T24RequestResponseFilter = ({handleFilterT24Status, selectedT24StatusFilter, handleReferenceFilter, selectedReferenceFilter, handleMessageIdFilter, selectedMessageIdFilter, handleFind}) => {

  const T24_SUBMISSION_STATUS = {
    PENDING: 'PENDING',
    SUBMITTED: 'SUBMITTED',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
    TIMEOUT: 'TIMEOUT'
  };

  const t24StatusFilterOptions = Object.entries(T24_SUBMISSION_STATUS).map(([key, value]) => ({
    key,
    label: value
  }));

  return (
    <Box alignItems="center" display="flex" marginBottom={2}>
      <LabelDropdown
        label="Filter T24 status"
        onChange={handleFilterT24Status}
        options={t24StatusFilterOptions}
        value={selectedT24StatusFilter}
      />
      &nbsp;
      <LabelTextField
        label="Reference"
        onChange={handleReferenceFilter}
        onClear={handleFind}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFind(e);
          }
        }}
        style={{width: '25ch'}}
        value={selectedReferenceFilter}
      />
      &nbsp;
      <LabelTextField
        label="Message Id"
        onChange={handleMessageIdFilter}
        onClear={handleFind}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFind(e);
          }
        }}
        style={{width: '25ch'}}
        value={selectedMessageIdFilter}
      />
    </Box>
  );
};

T24RequestResponseFilter.propTypes = {
  handleFilterT24Status: PropTypes.func,
  handleFind: PropTypes.func,
  handleMessageIdFilter: PropTypes.func,
  handleReferenceFilter: PropTypes.func,
  selectedMessageIdFilter: PropTypes.string,
  selectedReferenceFilter: PropTypes.string,
  selectedT24StatusFilter: PropTypes.string
};

export default T24RequestResponseFilter;
