import { jbColors } from '../jbColors';

const MuiIconButton = {
  root: {
    color: jbColors.greenSmoke['80'],
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
  },
};

export default MuiIconButton;
