export const MSG_START_RANGE_ZERO = 'Range should start from 0';
export const MSG_START_RANGE_EMPTY = 'Start range cannot be empty';
export const MSG_END_RANGE_EMPTY = 'End range cannot be empty';
export const MSG_END_RANGE_LOWER_THAN_START_RANGE = 'End range cannot be lower than start range';
export const MSG_RANGES_OVERLAP = 'Ranges should not overlap';
export const MSG_RANGE_GAP = 'There should not be a gap between ranges';
export const MSG_EFFECTIVE_PRICE_NO_VALUE = 'Effective price must have a value';

const invalid = (errMsg) => {
  return {isValid: false, errMsg: errMsg};
};

export const isTieringValid = (tiers) => {
  if (!tiers) {
    return {isValid: true};
  }

  tiers.sort();
  if (tiers[0].rangeStart !== 0) {
    return invalid(MSG_START_RANGE_ZERO);
  }

  let end = 0;
  for (let i = 0; i < tiers.length; i++) {
    if (!tiers[i].rangeStart && tiers[i].rangeStart !== 0) {
      return invalid(MSG_START_RANGE_EMPTY);
    }
    if (!tiers[i].rangeEnd && i !== tiers.length - 1) {
      return invalid(MSG_END_RANGE_EMPTY);
    }
    if (tiers[i].rangeStart > tiers[i].rangeEnd) {
      return invalid(MSG_END_RANGE_LOWER_THAN_START_RANGE);
    }
    if (tiers[i].rangeStart < end) {
      return invalid(MSG_RANGES_OVERLAP);
    }
    if (tiers[i].rangeStart > end) {
      return invalid(MSG_RANGE_GAP);
    }
    if (tiers[i].effectivePrice === null) {
      return invalid(MSG_EFFECTIVE_PRICE_NO_VALUE);
    }
    end = tiers[i].rangeEnd;
  }

  return {isValid: true, errMsg: ''};
};
