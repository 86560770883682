export const VARIABLE_DISCOUNT = Object.freeze({
  code: 'VARIABLE_DISCOUNT',
  desc: 'Variable discount'
});

const getAllDiscountType = () => {
  return [
    VARIABLE_DISCOUNT
  ]
}

export const getDiscountTypeFromCode = code => {
  return getAllDiscountType().filter(ptype => ptype.code === code)[0];
};

export const getDiscountTypeSelectValues = () => {
  return getAllDiscountType().map(p => ({
    'key': p.code,
    'value': p.desc
  }))
}
