import React from 'react';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import {NEW_MARKER, OLD_MARKER} from '../enum/MarkerType';

const useStyles = makeStyles(() => ({
  indicator_color_G: {
    borderLeft: '4px solid #1e9600'
  },
  indicator_color_R: {
    borderLeft: '4px solid #ff0000'
  },
  indicator_color_Y: {
    borderLeft: '4px solid #fff200'
  },
  indicator_color_O: {
    borderLeft: '4px solid #ff8000'
  },
  indicator_color_N: {
    borderLeft: '4px solid #eeeeee'
  },
  indicator_color_U: {
    borderLeft: '4px solid  #00ffff'
  },
  oldMarkerIndicatorInvisible: {
    opacity: 0,
    width: '0px',
    height: '0px',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: '10px solid #fff'
  },
  newMarkerIndicatorInvisible: {
    opacity: 0,
    width: '0px',
    height: '0px',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: '60px solid #fff'
  },
  oldMarkerIndicator: {
    height: '11px',
    position: 'absolute',
    left: '50%',
    marginLeft: '-2px',
    top: '39px'
  },
  newMarkerIndicator: {
    height: '61px',
    position: 'absolute',
    left: '50%',
    marginLeft: '-2px',
    top: '39px',
  },
  donutRing:{
  },
  donut:{
  }
}));

const CustomMarker = ({markerData, markerType}) => {
  const classes = useStyles();
  function getDonutColor(markerColor) {
    let donutColor;
    if (markerColor === 'R') {
      donutColor = '#ff0000'
    } else if (markerColor === 'G') {
      donutColor = '#1e9600'
    } else if (markerColor === 'Y') {
      donutColor = '#fff200'
    } else if (markerColor === 'O') {
      donutColor = '#ff8000'
    } else if (markerColor === 'N') {
      donutColor = '#eeeeee'
    } else {
      donutColor = '#eeeeee'
    }
    return donutColor;
  }
  function getMarkerStyle(markerColor) {
    let color;
    if (markerColor === 'R') {
      color = classes.indicator_color_R
    } else if (markerColor === 'G') {
      color = classes.indicator_color_G
    } else if (markerColor === 'Y') {
      color = classes.indicator_color_Y
    } else if (markerColor === 'O') {
      color = classes.indicator_color_O
    } else if (markerColor === 'N') {
      color = classes.indicator_color_N
    } else {
      color = classes.indicator_color_U
    }
    return ((markerType === OLD_MARKER) ? classes.oldMarkerIndicator : classes.newMarkerIndicator) + ' ' + color;
  }
  function getStrokeArray(markerValue) {
    return (100 - markerValue) + ' '+markerValue;
  }

  function getTitle(markerType) {
    if(markerType === OLD_MARKER){
      return 'OLD DISCOUNT';
    }else  if(markerType === NEW_MARKER){
      return 'NEW DISCOUNT';
    }else{
      return '';
    }
  }

  return (
    <div>
      <div style={{marginLeft:`${markerData?.value <= 20 ? '110px' : '0px'}`, transform:'scaleX(-1)', width:'0px', position:'absolute', fontSize:'9px', color:'red', fontWeight:'bold'}}>
        <span style={{whiteSpace: 'nowrap'}}>{getTitle(markerType)}</span> {markerData?.value}%
      </div>
      <svg className={classes.donut} height="100%" viewBox="0 0 42 42" width="100%">
        <circle className="donut-hole" cx="21" cy="21" fill="#fff" r="15.91549430918954"/>
        <circle
          className={classes.donutRing}
          cx="21"
          cy="21"
          fill="transparent"
          r="15.91549430918954"
          stroke={getDonutColor(markerData?.color)}
          strokeWidth="8"
        />
        <circle
          className="donut-segment"
          cx="21"
          cy="21"
          fill="transparent"
          r="15.91549430918954"
          stroke="#d2d3d4"
          strokeDasharray={getStrokeArray(markerData?.value)}
          strokeDashoffset="25"
          strokeWidth="8"
        />
      </svg>
      <div className={markerType === OLD_MARKER ? classes.oldMarkerIndicatorInvisible : classes.newMarkerIndicatorInvisible}/>
      <div className={getMarkerStyle(markerData?.color)} />
    </div>
  )
}

CustomMarker.propTypes = {

  markerData: PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.number,
    title: PropTypes.string,
  }),
  markerType:PropTypes.string,
};

export default CustomMarker;