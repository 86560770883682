import {isNewBankingFeeDifferent} from '../views/PriceSimulation/components/BankingAndOtherServices/isNewBankingFeeDifferent';
import {isNewRecurringFeeDifferent} from '../views/PriceSimulation/components/RecurringFees/isNewRecurringFeeDifferent';

export const isAcknowledgementRequired = (state) => {
  let recurringFees = state?.selectedPricingRequest?.recurringFees;
  let isBankingFeeDiff = false;
  let isRecurringFeeDiff = false;

  isBankingFeeDiff = ['BANKING_SERVICE_FEE','REPORTING_FEE','SPECIAL_MAILING','CODED_ACCOUNT','RETAINED_MAIL'].some(key =>{
    if(isNewBankingFeeDifferent(state?.selectedPricingRequest?.bankingFees, key))
      return true;
  });

  if(!isBankingFeeDiff){
    isRecurringFeeDiff = ['condition','calculationBasis','implementation','additionalInstructions'].some(key =>{
      if(isNewRecurringFeeDifferent(recurringFees, key))
        return true;
    });

    if(recurringFees?.currentCondition && !isRecurringFeeDiff){
      isRecurringFeeDiff = isNotEqualValues(recurringFees?.currentCondition?.feePerAnnum?.effectivePrice , recurringFees?.newCondition?.feePerAnnum?.effectivePrice)
        || isNotEqualValues(recurringFees?.currentCondition?.minimumFeePerQuarter?.effectivePrice , recurringFees?.newCondition?.minimumFeePerQuarter?.effectivePrice)
        || isNotEqualValues(recurringFees?.currentCondition?.annualFee , recurringFees?.newCondition?.annualFee)
        || isNotEqualValues(recurringFees?.currentCondition?.discountOnListPrice , recurringFees?.newCondition?.discountOnListPrice)
        || isNotEqualValues(recurringFees?.currentCondition?.discountOnSurcharge , recurringFees?.newCondition?.discountOnSurcharge)
        || isNotEqualValues(recurringFees?.currentCondition?.flatRateInBps , recurringFees?.newCondition?.flatRateInBps);
    }

    if(recurringFees?.currentCondition && !isRecurringFeeDiff){
      const oldTier = recurringFees?.currentCondition?.portfolioAssets;
      const newTier = recurringFees?.newCondition?.portfolioAssets;
      isRecurringFeeDiff = (oldTier && oldTier?.length  !== newTier?.length);
      if(!isRecurringFeeDiff && oldTier){
        for(let i=0; i<oldTier?.length || 0; i++){
          const oldT = oldTier[i];
          const newT = newTier[i];
          if(oldT?.rangeStart !== newT?.rangeStart || oldT?.rangeEnd !== newT?.rangeEnd || oldT?.effectivePrice !== newT?.effectivePrice){
            isRecurringFeeDiff = true;
            break;
          }
        }
      }
    }
  }

  return isBankingFeeDiff || isRecurringFeeDiff;
}

const isNotEqualValues = (previous,current) => {
  if ((previous === null || previous === undefined || previous === 0 ) && ( current === null || current === undefined || current === 0)){
    return false;
  } else if(current === previous){
    return false;
  } else {
    return true;
  }
};