import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import BankingFeeDialog from './components/BankingFeeDialog';
import CommonDialog from '../../../../../../../../../Common/components/Dialog/CommonDialog';
import api from '../../../../../../../../../../api/api';
import AsyncButton from '../../../../../../../../../Common/components/AsyncButton';
import PeriodicReviewDialog from '../PeriodicActionCell/components/PeriodicReviewDialog';
import { makeStyles } from '@material-ui/core';
import { getAcmRoles } from '../../../../../../../../../../common/enums/userRole';
import { Store } from '../../../../../../../../../../Store';

const TEST_PREFIX = 'test-deviation/periodic-action';
const useStyles = makeStyles(() => ({
  actionBox: {
    display: 'flex'
  }
}));
const AcmAndPeriodicActionCell = ({deviationRetainedMailEnabled, fetchData, row}) => {
  const [isBankingFeeDialogOpen, setIsBankingFeeDialogOpen] = useState(false);
  const [isNotAllowedDialogOpen, setIsNotAllowedDialogOpen] = useState(false);
  const [latestPricingRequestId, setLatestPricingRequestId] = useState(null);
  const [isPeriodicReviewDialogOpen, setPeriodicReviewDialogOpen] = useState(false);
  const [isACMRoles, setIsACMRoles] = useState(false);
  const classes = useStyles();
  const {parentState} = useContext(Store);

  const userRoles = parentState.user?.userRoles;

  const handleClose = () => {
    setIsBankingFeeDialogOpen(false);
  };

  const handleReviewClose = () => {
    setPeriodicReviewDialogOpen(false);
  };

  const handleSubmit = () => {
    setIsBankingFeeDialogOpen(false);
    setPeriodicReviewDialogOpen(false);
    fetchData();
  };

  const handleReviewSubmit = () => {
    setPeriodicReviewDialogOpen(false);
    fetchData();
  };

  const checkIfAllowed = async () => {
    const response = await api.portfolio.getLatestId(row.original.portfolioNumber, row.original.tenantId);
    const latestId = response.data;
    setLatestPricingRequestId(latestId);
    if (row.original.pricingRequestId === latestId) {
      setIsBankingFeeDialogOpen(true);
    } else {
      setIsNotAllowedDialogOpen(true);
    }
  };
  const checkIfReviewAllowed = async () => {
    const response = await api.portfolio.getLatestId(row.original.portfolioNumber, row.original.tenantId);
    const latestId = response.data;
    setLatestPricingRequestId(latestId);
    if (row.original.pricingRequestId === latestId) {
      setPeriodicReviewDialogOpen(true);
    } else {
      setIsNotAllowedDialogOpen(true);
    }
  };
  useEffect(() => {
    if (userRoles) {
      const roles = userRoles?.map(userRole => userRole.role);
      if (getAcmRoles().some(role => roles.includes(role.code))) {
        setIsACMRoles(true);
      } else {
        setIsACMRoles(false)
      }
    }
  }, [userRoles]);

  return (
    <div  className={classes.actionBox} data-testid={`${TEST_PREFIX}-${row.original.pricingRequestId}`} >
      <CommonDialog
        onClose={() => setIsNotAllowedDialogOpen(false)}
        open={isNotAllowedDialogOpen}
        text={`This is not the latest pricing request for portfolio number ${row.original.portfolioNumber}. Please edit from the latest pricing request ID: ${latestPricingRequestId}.`}
      />
      <BankingFeeDialog
        currentBankingFees={row.original.bankingFees?.currentConditionCodeFees}
        deviationRetainedMailEnabled={deviationRetainedMailEnabled}
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={isBankingFeeDialogOpen}
        portfolioNumber={row.original.portfolioNumber}
        pricingRequestId={row.original.pricingRequestId}
        serviceModal={row.original.serviceModel}
        tenantId={row.original.tenantId}
      />
      <PeriodicReviewDialog
        onClose={handleReviewClose}
        onSubmit={handleReviewSubmit}
        open={isPeriodicReviewDialogOpen}
        portfolioNumber={row.original.portfolioNumber}
        pricingRequestId={row.original.pricingRequestId}
        tenantId={row.original.tenantId}
      />
      { isACMRoles && row.original.deviationEditEnabled ? <AsyncButton data-testid={`${TEST_PREFIX}-${row.original.pricingRequestId}-edit-button`} onClick={checkIfAllowed} text="Edit"/> : null }
      { row.original.periodicReviewEnabled ? <AsyncButton data-testid={`${TEST_PREFIX}-${row.original.pricingRequestId}-periodic-review-button`} onClick={checkIfReviewAllowed} text="Periodic Review"/> : null }
    </div>
  );
};

AcmAndPeriodicActionCell.propTypes = {
  deviationRetainedMailEnabled: PropTypes.bool,
  fetchData: PropTypes.func,
  row: PropTypes.object
};

export default AcmAndPeriodicActionCell;
