export const PRICING_REQUEST_ID = {
  key: 'pricingRequestId',
  label: 'Request ID',
  canHide: false,
  canGroup: false,
  canFilter: false,
  hidden: false,
  canSort: true
};

export const ACCOUNT_NUMBER = {
  key: 'accountNumber',
  label: 'Account Number',
  canHide: true,
  canGroup: true,
  canFilter: true,
  hidden: false,
  canSort: true
};

export const PORTFOLIO_NUMBER = {
  key: 'portfolioNumber',
  label: 'Portfolio Number',
  canHide: true,
  canGroup: true,
  canFilter: true,
  hidden: false,
  canSort: true
};

export const SERVICE_MODEL = {
  key: 'serviceModel',
  label: 'Service Model',
  canHide: true,
  canGroup: true,
  canFilter: true,
  hidden: false,
  canSort: true
};

export const MANDATE_TYPE = {
  key: 'mandateType',
  label: 'Mandate Type',
  canHide: true,
  canGroup: true,
  canFilter: true,
  hidden: false,
  canSort: true
};

export const REQUEST_DATE = {
  key: 'requestDate',
  label: 'Date of Request',
  canHide: true,
  canGroup: false,
  canFilter: false,
  hidden: false,
  canSort: true
};

export const REQUEST_TYPE = {
  key: 'requestType',
  label: 'Request Type',
  canHide: true,
  canGroup: false,
  canFilter: true,
  hidden: false,
  canSort: true
};

export const REQUEST_BY = {
  key: 'requestBy',
  label: 'Requested By',
  canHide: true,
  canGroup: false,
  canFilter: true,
  hidden: false,
  canSort: true
};

export const INITIATED_BY = {
  key: 'initiatedBy',
  label: 'Initiated By',
  canHide: true,
  canGroup: false,
  canFilter: true,
  hidden: false,
  canSort: true
};

export const AUM_AC_GROUP = {
  key: 'aumGroup',
  label: 'AuM (A/C Group)',
  canHide: true,
  canGroup: false,
  canFilter: false,
  hidden: true,
  canSort: true
};

export const PCI_AC_GROUP = {
  key: 'pciGroup',
  label: 'PC I (A/C Group)',
  canHide: true,
  canGroup: false,
  canFilter: false,
  hidden: true,
  canSort: true
};

export const ROA_AC_GROUP = {
  key: 'roaGroup',
  label: 'RoA (A/C Group)',
  canHide: true,
  canGroup: false,
  canFilter: false,
  hidden: true,
  canSort: true
};

export const AUM_ACCOUNT = {
  key: 'aumAccount',
  label: 'AuM (Account)',
  canHide: true,
  canGroup: false,
  canFilter: false,
  hidden: true,
  canSort: true
};

export const PCI_ACCOUNT = {
  key: 'pciAccount',
  label: 'PC I (Account)',
  canHide: true,
  canGroup: false,
  canFilter: false,
  hidden: true,
  canSort: true
};

export const ROA_ACCOUNT = {
  key: 'roaAccount',
  label: 'RoA (Account)',
  canHide: true,
  canGroup: false,
  canFilter: false,
  hidden: true,
  canSort: true
};

export const REVENUE_IMPACT_CHF = {
  key: 'revenueImpact',
  label: 'Revenue Impact (CHF)',
  canHide: true,
  canGroup: false,
  canFilter: false,
  hidden: true,
  canSort: true
};
export const ROA_IMPACT = {
  key: 'roaImpact',
  label: 'ROA Impact',
  canHide: true,
  canGroup: false,
  canFilter: false,
  hidden: true,
  canSort: true
};

export const APPROVER = {
  key: 'approver',
  label: 'Approver',
  canHide: false,
  canGroup: true,
  canFilter: false,
  hidden: false,
  canSort: false
};

export const PRICING_REQUEST_STATUS = {
  key: 'pricingRequestStatus',
  label: 'Status',
  canHide: false,
  canGroup: true,
  canFilter: true,
  hidden: false,
  canSort: true
};

export const PERIOD_OF_VALIDITY = {
  key: 'periodOfValidity',
  label: 'Period of Validity',
  canHide: true,
  canGroup: false,
  canFilter: false,
  hidden: true,
  canSort: true
};

export const OLD_PEER_COMPARISON = {
  key: 'oldDiscMarker',
  label: 'Old Peer Comparison',
  canHide: false,
  canGroup: false,
  canFilter: false,
  hidden: false,
  canSort: true
};

export const NEW_PEER_COMPARISON = {
  key: 'newDiscMarker',
  label: 'New Peer Comparison',
  canHide: false,
  canGroup: false,
  canFilter: false,
  hidden: false,
  canSort: true
};

export const NEXT_REVIEW_DATE = {
  key: 'periodicReviewDate',
  label: 'Next Review Date',
  canHide: false,
  canGroup: false,
  canFilter: false,
  hidden: false,
  canSort: true
};

export const EAM_CODE = {
  key: 'eamCode',
  label: 'EAM Code',
  canHide: false,
  canGroup: false,
  canFilter: false,
  hidden: false,
  canSort: true
};

export const ACTION = {
  key: 'action',
  label: 'Action',
  canHide: false,
  canGroup: false,
  canFilter: false,
  hidden: false,
  canSort: false
};

export const SPECIAL_ACTION = {
  key: 'specialAction',
  label: 'Action',
  canHide: false,
  canGroup: false,
  canFilter: false,
  hidden: false,
  canSort: false
};

export const ACM_DEVIATION_ACTION = {
  key: 'acmDeviationAction',
  label: 'Action',
  canHide: false,
  canGroup: false,
  canFilter: false,
  hidden: false,
  canSort: false
};

export const T24_SYNC_STATUS = {
  key: 't24SyncStatus',
  label: 'T24 Status',
  canHide: false,
  canGroup: false,
  canFilter: false,
  hidden: false,
  canSort: true
};

export const SPECIAL_MAILING_FEE = {
  key: 'specialMailingFee',
  label: 'Special Mailing Fee'
};

export const REPORTING_FEE = {
  key: 'reportingFee',
  label: 'Reporting Fee'
};

export const RETAINED_MAIL_FEE = {
  key: 'retainedMailFee',
  label: 'Retained Mailing Fee'
};

export const ACM_APPROVAL_ACTION = {
  key: 'acmApprovalAction',
  label: 'Action'
};

export const BANKING_SERVICE_PACKAGE_FEE = {
  key: 'bankingServicePackageFee',
  label: 'Banking Service Package Fee'
};

export const CODED_ACCOUNT_FEE = {
  key: 'codedAccountFee',
  label: 'Coded Account Fee'
};

export const approvalColumnData = [
  ACTION,
  PRICING_REQUEST_ID,
  ACCOUNT_NUMBER,
  PORTFOLIO_NUMBER,
  SERVICE_MODEL,
  MANDATE_TYPE,
  REQUEST_DATE,
  REQUEST_TYPE,
  REQUEST_BY,
  AUM_AC_GROUP,
  PCI_AC_GROUP,
  ROA_AC_GROUP,
  AUM_ACCOUNT,
  PCI_ACCOUNT,
  ROA_ACCOUNT,
  REVENUE_IMPACT_CHF,
  ROA_IMPACT,
  APPROVER,
  PRICING_REQUEST_STATUS,
  T24_SYNC_STATUS,
  OLD_PEER_COMPARISON,
  NEW_PEER_COMPARISON
];

export const allPriceRequestColumnData = [
  PRICING_REQUEST_ID,
  ACCOUNT_NUMBER,
  PORTFOLIO_NUMBER,
  SERVICE_MODEL,
  MANDATE_TYPE,
  REQUEST_DATE,
  REQUEST_TYPE,
  REQUEST_BY,
  INITIATED_BY,
  AUM_AC_GROUP,
  PCI_AC_GROUP,
  ROA_AC_GROUP,
  AUM_ACCOUNT,
  PCI_ACCOUNT,
  ROA_ACCOUNT,
  REVENUE_IMPACT_CHF,
  ROA_IMPACT,
  APPROVER,
  PRICING_REQUEST_STATUS,
  T24_SYNC_STATUS,
  OLD_PEER_COMPARISON,
  NEW_PEER_COMPARISON,
  EAM_CODE,
  NEXT_REVIEW_DATE
];

export const acmAllPriceRequestColumnData = [ACM_DEVIATION_ACTION, ...allPriceRequestColumnData];

export const pmDashboardColumnData = [
  PRICING_REQUEST_ID,
  ACCOUNT_NUMBER,
  PORTFOLIO_NUMBER,
  REQUEST_DATE,
  REQUEST_TYPE,
  REQUEST_BY,
  APPROVER,
  PRICING_REQUEST_STATUS,
  SPECIAL_ACTION
];

export const acmDashboardColumnData = [
  ACM_APPROVAL_ACTION,
  PRICING_REQUEST_ID,
  PORTFOLIO_NUMBER,
  SERVICE_MODEL,
  MANDATE_TYPE,
  BANKING_SERVICE_PACKAGE_FEE,
  SPECIAL_MAILING_FEE,
  REPORTING_FEE,
  CODED_ACCOUNT_FEE,
  RETAINED_MAIL_FEE,
  REQUEST_DATE,
  REQUEST_BY,
  PRICING_REQUEST_STATUS,
  T24_SYNC_STATUS
];
