import React from 'react';
import PriceNumberFormat from '../../../../Common/components/PriceNumberFormat';
import {BPS, CHF, PERCENTAGE, REGULAR} from '../../../../Common/components/PriceNumberFormat/PriceNumberFormat';
import {
  FIXED_AMOUNT,
  NON_STANDARD_CUMULATIVE,
  NON_STANDARD_NON_CUMULATIVE,
  PERCENTAGE_DISCOUNT,
  TIERED_FEE_CUMULATIVE,
  TIERED_FEE_NON_CUMULATIVE
} from '../../../../../common/enums/recurringFeeCondition';
import Box from '@material-ui/core/Box';
import {DPM, IA_DIRECT, IA_INDIRECT} from '../../../../../common/enums/serviceModel';
import {getDiscountFromEffectivePrice} from '../../../../../common/getDiscountFromEffectivePrice';

const LABEL = 'Portfolio Assets';
const KEY_PREFIX = 'recurring-fees-portfolio-assets';
const TEST_KEY_PREFIX = `test-${KEY_PREFIX}`;

export const portfolioAssets = ({handleValidationResultChange, handleValueChange, readOnly, recurringFees, serviceModelCode, specialRequest, showDiff}) => {
  let oldPortfolioExists = recurringFees?.currentCondition?.portfolioAssets?.length > 0;
  let newPortfolioExists = recurringFees?.newCondition?.portfolioAssets?.length > 0;

  if (
    [NON_STANDARD_NON_CUMULATIVE.code, NON_STANDARD_CUMULATIVE.code].includes(recurringFees?.newCondition?.condition?.code) ||
    (!oldPortfolioExists && !newPortfolioExists)
  ) {
    return [];
  }

  let results = [{
    key: 'portfolio-assets',
    label: LABEL,
    newCondition: <Box/>
  }];

  // let results = [];

  const numberOfTiers = Math.max(recurringFees?.currentCondition?.portfolioAssets?.length || 0, recurringFees?.newCondition?.portfolioAssets?.length || 0);

  const hasOldConditionTiering = recurringFees?.currentCondition?.portfolioAssets?.length > 0;
  const hasDisjunctTiering = hasOldConditionTiering && recurringFees?.newCondition?.portfolioAssets?.length > 0 && (
    recurringFees.currentCondition.portfolioAssets.length !== recurringFees?.newCondition?.portfolioAssets?.length
    || recurringFees.currentCondition.portfolioAssets.some((value, index) => value.rangeStart !== recurringFees.newCondition.portfolioAssets[index].rangeStart)
    || recurringFees.currentCondition.portfolioAssets.some((value, index) => value.rangeEnd !== recurringFees.newCondition.portfolioAssets[index].rangeEnd)
  );

  for (let i = 0; i < numberOfTiers; i++) {
    const applicableTierForLabel = hasOldConditionTiering ? recurringFees.currentCondition.portfolioAssets[i] : recurringFees.newCondition.portfolioAssets[i];

    const currentConditionPortfolioAssets = recurringFees?.currentCondition?.portfolioAssets && recurringFees?.currentCondition?.portfolioAssets[i];
    const newConditionPortfolioAssets = recurringFees?.newCondition?.portfolioAssets && recurringFees?.newCondition?.portfolioAssets[i];

    // Take discount value from discountOnListPrice if the condition is % discount
    const isPercentageDiscountCondition = recurringFees?.newCondition?.condition?.code === PERCENTAGE_DISCOUNT.code
    const fixedDiscountValue = recurringFees?.newCondition?.discountOnListPrice;

    let result = {
      key: `portfolio-assets-${i}`, // it is safe to use index here as part of the key, as the number of visible rows and their associated data is constant.
      label: applicableTierForLabel ? getRangeLabel(applicableTierForLabel) : ''
    };
    let isNewValueDifferent = false;

    if(!isNewValueDifferent && showDiff){
      continue;
    }

    if (hasOldConditionTiering && currentConditionPortfolioAssets) {
      if (!!readOnly && currentConditionPortfolioAssets && currentConditionPortfolioAssets.effectivePrice !== newConditionPortfolioAssets?.effectivePrice) {
        isNewValueDifferent = true;
      }

      result = {
        ...result,
        oldCondition: getMappedOldCondition(currentConditionPortfolioAssets)
      }
    }
    if (hasDisjunctTiering && newConditionPortfolioAssets) {
      result = {
        ...result,
        newConditionTiering: getRangeLabel(newConditionPortfolioAssets)
      }
    }

    const fixedDiscountCell =
      <PriceNumberFormat
        data-testid={`${TEST_KEY_PREFIX}-discount`}
        key={`${KEY_PREFIX}-discount`}
        type={PERCENTAGE}
        value={fixedDiscountValue}
      />

    if (newConditionPortfolioAssets) {
      if (
        !!readOnly &&
        recurringFees.currentCondition &&
        ((!currentConditionPortfolioAssets && newConditionPortfolioAssets.effectivePrice) ||
          currentConditionPortfolioAssets?.effectivePrice !== newConditionPortfolioAssets?.effectivePrice)
      ) {
        isNewValueDifferent = true;
      }
    }

    result = {
      ...result,
      newCondition: getMappedNewCondition(newConditionPortfolioAssets, i, recurringFees?.newCondition?.condition?.code, serviceModelCode, handleValidationResultChange, handleValueChange, readOnly, specialRequest, isNewValueDifferent),
      discount: !newConditionPortfolioAssets ? null : isPercentageDiscountCondition ? fixedDiscountCell : getMappedDiscount(newConditionPortfolioAssets)
    }

    results.push(result);
  }
  if (results.length > 1) {
    return results;
  }
  return [];
};

const getRangeLabel = (tier) => {
  return (
    <Box data-testid={`${TEST_KEY_PREFIX}-label`} style={{display: 'flex', alignItems: 'center', paddingLeft: '5px'}}>
      <PriceNumberFormat
        placeHolderForZero="0"
        type={REGULAR}
        value={tier?.rangeStart}
      />
      <Box>
        <span>&nbsp;{tier.rangeEnd ? 'to' : 'CHF and'}&nbsp;</span>
      </Box>
      <PriceNumberFormat
        placeHolderForUndefined="above"
        type={CHF}
        value={tier?.rangeEnd}
      />
    </Box>
  );
}


const getMappedOldCondition = (tier) => {
  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={BPS}
      value={tier?.effectivePrice}
    />);

  return {
    effectivePrice: effectivePriceReadonly
  };
};

const getMappedNewCondition = (tier, index, conditionCode, serviceModelCode, handleValidationResultChange, handleValueChange, readOnly, specialRequest, isNewValueDifferent) => {

  const isEditable = [TIERED_FEE_CUMULATIVE.code, TIERED_FEE_NON_CUMULATIVE.code].includes(conditionCode) && [DPM, IA_DIRECT, IA_INDIRECT].includes(serviceModelCode) && !readOnly;
  const isDisabled = conditionCode === FIXED_AMOUNT.code;

  const listPrice = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-list-price`}
      key={`${KEY_PREFIX}-list-price`}
      type={BPS}
      value={tier?.listPrice}
    />);

  const effectivePriceReadonly = (
    <PriceNumberFormat
      data-testid={isDisabled ? `${TEST_KEY_PREFIX}-effective-price-disabled` : `${TEST_KEY_PREFIX}-effective-price-readonly`}
      key={`${KEY_PREFIX}-effective-price-readonly`}
      type={BPS}
      value={tier?.effectivePrice}
    />);

  const effectivePriceEditable = (
    <PriceNumberFormat
      data-testid={`${TEST_KEY_PREFIX}-effective-price-editable`}
      isEditable
      key={`${KEY_PREFIX}-effective-price-editable`}
      max={specialRequest ? null : tier?.listPrice}
      onValidationResultChange={(vr) => handleValidationResultChange({
        ...vr,
        selectors: ['portfolioAssets', index, 'effectivePrice']
      })}
      onValueChange={(vr) => handleValueChange({...vr, selectors: ['portfolioAssets', index, 'effectivePrice']})}
      type={BPS}
      value={tier?.effectivePrice}
    />);

  return {
    effectivePrice: (isEditable && !isDisabled) ? effectivePriceEditable : effectivePriceReadonly,
    isDisabled: isDisabled,
    isEditable: isEditable,
    isHighlighted: isNewValueDifferent,
    listPrice: listPrice
  };
};

// eslint-disable-next-line react/no-multi-comp
const getMappedDiscount = (newCondition) => {
  const discount = parseFloat(getDiscountFromEffectivePrice(newCondition));

  return (
    !isNaN(discount) && (
      <PriceNumberFormat
        data-testid={`${TEST_KEY_PREFIX}-discount`}
        key={`${KEY_PREFIX}-discount`}
        type={PERCENTAGE}
        value={discount}
      />
    )
  );
};
