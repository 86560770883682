export default {
  root: {
    backgroundColor: '#141E55',
    width: '100%',
    height: '30px',
    color: '#FFF!important',
    fontFamily: 'Verlag SSm 3r',
    fontSize: '13px',
    lineHeight: '30px',
    verticalAlign: 'middle',
    padding: 0,
    '& .MuiTypography-h6': {
      color: '#FFF!important'
    },
    '& .MuiTypography-h5': {
      color: '#FFF!important'
    },
    '& .MuiTypography-h4': {
      color: '#FFF!important'
    },
    '& .MuiTypography-h3': {
      color: '#FFF!important'
    },
    '& .MuiTypography-h2': {
      color: '#FFF!important'
    },
    '& .MuiTypography-h1': {
      color: '#FFF!important'
    },
    '& a': {
      textDecoration: 'none'
    },
    'a:link,a:active,a:visited,a:hover': {
      textDecoration: 'none'
    }
  }
};
