import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Box, Grid, Typography} from '@material-ui/core';
import {useNotification} from '../../../Common/components/Notification';
import adminApi from '../../../../api/adminApi';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import AsyncButton from '../../../Common/components/AsyncButton';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import CommonDialog from '../../../Common/components/Dialog/CommonDialog';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  inner: {
    maxWidth: '1500px',
    overflowX: 'auto'
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

const PricingRequestDataPatch = () => {

  const classes = useStyles();
  const notification = useNotification();
  const [enteredPricingRequestId, setEnteredPricingRequestId] = useState();
  const [dataPatchCount, setDataPatchCount] = useState(0);
  const [responseData, setResponseData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [responseDataByPricingRequestId, setResponseDataByPricingRequestId] = useState([]);
  const [isLoadingByPricingRequestId, setIsLoadingByPricingRequestId] = useState();
  const [isCancelDialogOpenByPricingRequestId, setIsCancelDialogOpenByPricingRequestId] = useState(false);

  const getDataPatchCount = () => {
    setIsLoading(true);
    return adminApi.dataPatch
      .getCount()
      .then(res => {
        setDataPatchCount(res.data);
        setIsLoading(false);
        setIsCancelDialogOpen(false);
      })
      .catch((err) => {
        notification.error('Could not get data patch count.\n' + getErrorMessage(err));
      });
  };

  const applyAll = () => {
    setIsLoading(true);
    return adminApi.dataPatch
      .applyAll()
      .then(res => {
        setResponseData(res.data);
        setIsLoading(false);
        setIsCancelDialogOpen(false);
      })
      .catch((err) => {
        notification.error('Could not load data.\n' + getErrorMessage(err));
      });
  };

  const applyByPricingRequestId = (pricingRequestId) => {
    setIsLoadingByPricingRequestId(true);
    return adminApi.dataPatch
      .applyByPricingRequestId(pricingRequestId)
      .then(res => {
        setResponseDataByPricingRequestId(res.data);
        setIsLoadingByPricingRequestId(false);
        setIsCancelDialogOpenByPricingRequestId(false);
        setEnteredPricingRequestId('')
      })
      .catch((err) => {
        notification.error('Could not load data.\n' + getErrorMessage(err));
        setIsLoadingByPricingRequestId(false);
      });
  };

  const initiateDataPatch = () => {
    return applyAll();
  };

  const initiateDataPatchByPricingRequestId = () => {
    return applyByPricingRequestId(enteredPricingRequestId);
  };

  useEffect(() => {
    getDataPatchCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Grid container>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h1">
            Pricing Request Data Patch - ALL
          </Typography>

          <CommonDialog
            onClose={() => setIsCancelDialogOpen(false)}
            onContinue={() => initiateDataPatch()}
            open={isCancelDialogOpen}
            text={`You are about to apply data patch for approved pricing request with OTC standard fees.
                Do you want to continue?.`}
          />

          <AsyncButton
            onClick={async () => {
              setIsCancelDialogOpen(true);
            }}
            text="Initiate Data Patch"
          />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h6">
            Total pricing request(s) available for data patch : <b>{dataPatchCount}</b>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {isLoading ? <LoadingSpinner/> :
            <Typography className={classes.title} variant="h6">
              {responseData}
            </Typography>}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h1">
            Pricing Request Data Patch - By Pricing Request Id
          </Typography>

          <CommonDialog
            onClose={() => setIsCancelDialogOpenByPricingRequestId(false)}
            onContinue={() => initiateDataPatchByPricingRequestId()}
            open={isCancelDialogOpenByPricingRequestId}
            text={`You are about to apply data patch by pricing request id for approved pricing request with OTC standard fees.
                Do you want to continue?.`}
          />

          <AsyncButton
            onClick={async () => {
              if(enteredPricingRequestId && enteredPricingRequestId.trim().length > 0) {
                setIsCancelDialogOpenByPricingRequestId(true);
              }
            }}
            text="Initiate Data Patch"
          />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h6">
            <TextField
              onChange={function (event) {
                if (event.target.value) {
                  setEnteredPricingRequestId(event.target.value);
                }
              }}
              placeholder="Enter pricing request Id"
              variant="outlined"
            />
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          {isLoadingByPricingRequestId ? <LoadingSpinner/> :
            <Typography className={classes.title} variant="h6">
              {responseDataByPricingRequestId}
            </Typography>}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PricingRequestDataPatch;
