import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button} from '@material-ui/core';
import {PENDING_PM} from '../../../../../../../../../../common/statusTypes';
import {useHistory} from 'react-router-dom';

const SpecialActionCell = ({row}) => {
  const history = useHistory();
  const {pricingRequestStatus, pricingRequestId, tenantId} = row.original;
  const tenantIdQuery = tenantId ? `?tenantId=${tenantId}` : '';

  const editPricingRequest = () => {
    history.push(`/simulation/${pricingRequestId}/${tenantIdQuery}`);
  };

  return (
    <Box data-testid="test-special-request-action-cell" display="flex">
      {pricingRequestStatus === PENDING_PM && (
        <React.Fragment>
          <Button onClick={editPricingRequest}>Edit</Button>
        </React.Fragment>
      )}
    </Box>
  );
};

SpecialActionCell.propTypes = {
  row: PropTypes.any
};

export default SpecialActionCell;
