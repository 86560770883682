export const LABEL = 'LABEL';
export const NEW_CONDITIONS = 'NEW_CONDITIONS';
export const OLD_CONDITIONS = 'OLD_CONDITIONS';
export const OLD_CONDITION_READONLY = 'OLD_CONDITION_READONLY';
export const NEW_CONDITION_EDITABLE = 'NEW_CONDITION_EDITABLE';
export const NEW_CONDITION_READONLY = 'NEW_CONDITION_READONLY';
export const TEXT = 'TEXT';
export const TEXT_LEFT = 'TEXT_LEFT';
export const EDITABLE = 'EDITABLE';
export const DISCLAIMER = 'DISCLAIMER';

// Summary
export const LABEL_SUMMARY = 'LABEL_SUMMARY';
export const OLD_CONDITIONS_SUMMARY = 'OLD_CONDITIONS_SUMMARY';
export const NEW_CONDITIONS_SUMMARY = 'NEW_CONDITIONS_SUMMARY';
export const DISCOUNT_SUMMARY = 'DISCOUNT_SUMMARY';
export const MINIMUM_FEE_SUMMARY = 'MINIMUM_FEE_SUMMARY';
