export const TIERED_FEE_CUMULATIVE = Object.freeze({
  code: 'TIERED_FEE_CUMULATIVE',
  desc: 'Tiered Fee (Cumulative)'
});
export const TIERED_FEE_NON_CUMULATIVE = Object.freeze({
  code: 'TIERED_FEE_NON_CUMULATIVE',
  desc: 'Tiered Fee (Non-Cumulative)'
});
export const FLAT_RATE = Object.freeze({
  code: 'FLAT_RATE',
  desc: 'Flat rate in bps'
});
export const FIXED_AMOUNT = Object.freeze({
  code: 'FIXED_AMOUNT',
  desc: 'Fixed Amount'
});
export const PERCENTAGE_DISCOUNT = Object.freeze({
  code: 'PERCENTAGE_DISCOUNT',
  desc: '% Discount'
});

export const NON_STANDARD_CUMULATIVE = Object.freeze({
  code: 'NON_STANDARD_CUMULATIVE',
  desc: 'Apply Non-Standard Price (Cumulative)',
})

export const NON_STANDARD_NON_CUMULATIVE = Object.freeze({
  code: 'NON_STANDARD_NON_CUMULATIVE',
  desc: 'Apply Non-Standard Price (Non-Cumulative)',
})

export const getRecurringFeeConditionDescriptionFromCode = (code) => {
  return getAllRecurringFeeConditions()
    .filter(condition => condition.code === code)
    .map(condition => condition.desc)[0];
}

export const getAllRecurringFeeConditions = () => {
  return [
    TIERED_FEE_CUMULATIVE,
    TIERED_FEE_NON_CUMULATIVE,
    FLAT_RATE,
    FIXED_AMOUNT,
    PERCENTAGE_DISCOUNT,
    NON_STANDARD_CUMULATIVE,
    NON_STANDARD_NON_CUMULATIVE
  ];
}

export const isNonStandardRecurringFeeCondition = (conditionCode) => [NON_STANDARD_CUMULATIVE.code, NON_STANDARD_NON_CUMULATIVE.code].includes(conditionCode);
