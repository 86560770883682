import React from 'react';
import PriceConditionTableColumn from './PriceConditionTableColumn';
import PropTypes from 'prop-types';
import {OLD_CONDITION_CUSTOM_STYLE} from '../../../../common/constants';
import NMBAcknowledgement from '../../../../views/PriceSimulation/components/NMBFees/panel/NMBAcknowledgement';
import {BILA} from '../../../../common/enums/agreementType';
import {isEAMorEFA} from '../../../../common/enums/serviceModel';
import {PENDING_PM} from '../../../../common/statusTypes';
import PriceConditionTableRowSection from './PriceConditionTableRowSection';

const PriceConditionAcknowledgementRow = ({currentCondition, declarationTxt, newCondition, testIdSuffix, columnProps, readOnly, serviceModel, agentType, pricingRequestStatus, handleValueChange, handleValidationResultChange}) => {
  return <PriceConditionTableRowSection data={[]}>
    {currentCondition && <>
      <PriceConditionTableColumn
        borderRight
        colSpan={2}
        customStyles={OLD_CONDITION_CUSTOM_STYLE}
        field="label"
        label={
          <NMBAcknowledgement
            acknowledged={currentCondition.acknowledged}
            additionalInstruction={currentCondition.additionalInstructions}
            agentType={agentType}
            agreementType={currentCondition?.agreementEnum?.code}
            declarationTxt={declarationTxt}
            disabled
            readOnly
            serviceModel={serviceModel}
            show
            testIdSuffix={`old-${testIdSuffix}`}
          />
        }
        style={{ padding: '0px' }}
      /></>}
    {!currentCondition && <PriceConditionTableColumn field="label"/>}
    {newCondition &&
    <PriceConditionTableColumn
      colSpan={2}
      field="label"
      label={
        <NMBAcknowledgement
          acknowledged={newCondition?.acknowledged}
          additionalInstruction={newCondition?.additionalInstructions}
          agentType={agentType}
          agreementType={newCondition?.agreementEnum?.code}
          declarationTxt={declarationTxt}
          disabled={(newCondition?.agreementEnum?.code !== BILA.code && !isEAMorEFA(serviceModel, agentType)) || pricingRequestStatus === PENDING_PM}
          handleChange={(val) => handleValueChange({ ...val })}
          handleValidationResultChange={handleValidationResultChange}
          readOnly={readOnly}
          serviceModel={serviceModel}
          show
          testIdSuffix={`new-${testIdSuffix}`}
        />
      }
      style={{ padding: '0px' }}
      {...columnProps}
    />
    }
  </PriceConditionTableRowSection>
}

PriceConditionAcknowledgementRow.propTypes = {
  agentType: PropTypes.string,
  columnProps: PropTypes.object,
  currentCondition: PropTypes.object,
  declarationTxt: PropTypes.string,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  newCondition: PropTypes.object,
  pricingRequestStatus: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  serviceModel: PropTypes.string.isRequired,
  showNewCondition: PropTypes.bool,
  showOldCondition: PropTypes.bool,
  testIdSuffix: PropTypes.string
};

export default PriceConditionAcknowledgementRow;
