import React, {useContext, useEffect, useRef} from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {ThemeProvider} from '@material-ui/styles';
import validate from 'validate.js';
import jwt_decode from 'jwt-decode';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import validator from './common/validator';
import Routes from './Routes';
import {Store} from './Store';
import api from './api/api';
import GlobalStyle from './GlobalStyle';
import withNotificationProvider from './views/Common/components/Notification/Notification';
import {FETCH_USER} from './common/actionTypes';
import {useNotification} from './views/Common/components/Notification';
import {getErrorMessage} from './common/getErrorMessage';
import BackdropProgress from './views/Common/components/BackdropProgress';
import {AUTHORIZATION} from './common/headers';
import {setIsLoading} from './Services';

const browserHistory = createBrowserHistory();

validate.validator = {
  ...validate.validator,
  ...validator
};

const App = () => {
  const {parentState, parentDispatch} = useContext(Store);
  const notification = useNotification();
  const userIdRef = useRef();

  useEffect(() => {
    getUser();
    window.addEventListener('storage', reloadPageIfUserDifferent);

    return () => {
      window.removeEventListener('storage', reloadPageIfUserDifferent);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async () => {
    setIsLoading(true, parentDispatch);
    try {
      const currentUserResponse = await api.user.getCurrent();
      userIdRef.current = currentUserResponse.data.id;
      let teamHeadIds = currentUserResponse.data.teamHeads.map(teamHead => teamHead.id);
      let teamInfos = await getTeamInfo(teamHeadIds);

      parentDispatch({
        type: FETCH_USER,
        payload: {...currentUserResponse.data, teams: teamInfos}
      });
    } catch (err) {
      notification.error('Failed getting user \n' + getErrorMessage(err));
    }
    setIsLoading(false, parentDispatch);
  };

  const getTeamInfo = async (teamHeadIds, location) => {
    return new Promise(resolve => {
      let userRequests = [];
      teamHeadIds.forEach(teamHead => userRequests.push(api.user.get(teamHead, location)));
      Promise.all(userRequests)
        .then(users => {
          resolve(users);
        })
        .catch(() => resolve());
    });
  };

  const reloadPageIfUserDifferent = () => {
    const localAuthToken = localStorage.getItem(AUTHORIZATION);
    if (localAuthToken) {
      const decodedToken = jwt_decode(localAuthToken);
      if (decodedToken?.sub !== userIdRef.current) {
        window.location.href = '/';
      }
    }
  };

  return (
    <>
      <GlobalStyle/>
      <ThemeProvider theme={theme}>
        <BackdropProgress open={parentState.isLoading || false}/>
        <Router history={browserHistory}>
          <Routes/>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default withNotificationProvider(App);
