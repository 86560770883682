import {
  BJB_BLUE_60,
  BJB_BLUE_80,
  BJB_GREEN_SMOKE_100,
  BJB_GREEN_SMOKE_20,
  BJB_GREEN_SMOKE_40,
  BJB_GREEN_SMOKE_60,
  BJB_GREEN_SMOKE_80,
  CLIPP_BLACK,
  CLIPP_DARK_BLUE,
  CLIPP_LIGHT_BLUE,
  CLIPP_LIGHT_GREY,
  CLIPP_WHITE
} from './bjbColor';

export const DEFAULT_COLOR = CLIPP_BLACK;
export const ICON_COLOR = CLIPP_BLACK;
export const CHECKBOX_COLOR = CLIPP_BLACK;
export const ICON_BUTTON_COLOR = CLIPP_BLACK;
export const FORM_LABEL_BACKGROUND_COLOR = CLIPP_WHITE;
export const FORM_LABEL_COLOR = CLIPP_BLACK;
export const FORM_BACKGROUND_COLOR = CLIPP_LIGHT_BLUE;
export const FORM_RADIO_COLOR = CLIPP_DARK_BLUE;
export const TEXT_COLOR = CLIPP_BLACK;
export const ERROR_BACKGROUND_PRIMARY_COLOR = '#971B2FF5';
export const ERROR_BACKGROUND_SECONDARY_COLOR = '#971B2F59';

export const MAIN_BACKGROUND_COLOR = '#F3F3EC';

export const WARNING_COLOR = 'orange';
export const BUTTON_PRIMARY_BACKGROUND_COLOR = CLIPP_WHITE;
export const BUTTON_PRIMARY_COLOR = CLIPP_DARK_BLUE;

export const HEADER_COLOR = '#141E55';

export const TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR = '#141E55';
export const TABLE_APPROVAL_STATUS_BORDER_COLOR = '#CCCCCC';

export const TABLE_REVENUE_HEADER_BACKGROUND_COLOR = CLIPP_WHITE;
export const TABLE_REVENUE_HEADER_COLOR = CLIPP_BLACK;
export const TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_BACKGROUND_COLOR = CLIPP_LIGHT_GREY;
export const TABLE_REVENUE_SUB_HEADER_NEW_CONDITION_COLOR = CLIPP_BLACK;
export const TABLE_REVENUE_CURRENT_CONDITION_BACKGROUND_COLOR = CLIPP_WHITE;
export const TABLE_REVENUE_CURRENT_CONDITION_COLOR = CLIPP_BLACK;
export const TABLE_REVENUE_NEW_CONDITION_BACKGROUND_COLOR = CLIPP_WHITE;
export const TABLE_REVENUE_NEW_CONDITION_COLOR = CLIPP_DARK_BLUE;

export const TABLE_HEADER_BACKGROUND_COLOR = CLIPP_WHITE;
export const TABLE_HEADER_COLOR = CLIPP_DARK_BLUE;

export const TABLE_SUB_HEADER_LABEL_BACKGROUND_COLOR = CLIPP_WHITE;
export const TABLE_SUB_HEADER_LABEL_COLOR = CLIPP_BLACK;
export const TABLE_SUB_HEADER_CURRENT_CONDITION_BACKGROUND_COLOR = '#ECEDF3';
export const TABLE_SUB_HEADER_CURRENT_CONDITION_COLOR = CLIPP_BLACK;
export const TABLE_SUB_HEADER_NEW_CONDITION_READONLY_BACKGROUND_COLOR = CLIPP_LIGHT_GREY;
export const TABLE_SUB_HEADER_NEW_CONDITION_READONLY_COLOR = CLIPP_BLACK;
export const TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_BACKGROUND_COLOR = CLIPP_DARK_BLUE;
export const TABLE_SUB_HEADER_NEW_CONDITION_EDITABLE_COLOR = CLIPP_WHITE;

export const TABLE_BODY_EDITABLE_CELL_BACKGROUND_COLOR = CLIPP_LIGHT_BLUE;
export const TABLE_BODY_READONLY_CELL_BACKGROUND_COLOR = CLIPP_WHITE;
export const TABLE_BODY_DISABLED_CELL_BACKGROUND_COLOR = CLIPP_LIGHT_GREY;
export const TABLE_BODY_HIGHLIGHTED_CELL_BACKGROUND_COLOR = ERROR_BACKGROUND_SECONDARY_COLOR;

export const TABLE_BODY_DISABLED_TEXT_COLOR = BJB_GREEN_SMOKE_100;

export const TABLE_FOOTER_BACKGROUND_COLOR = '#ECEDF3';
export const TABLE_FOOTER_COLOR = CLIPP_BLACK;

export const EXPANSION_PANEL_PRIMARY_BACKGROUND_COLOR = BJB_GREEN_SMOKE_100;
export const EXPANSION_PANEL_PRIMARY_COLOR = CLIPP_WHITE;
export const EXPANSION_PANEL_PRIMARY_FOCUS_BACKGROUND_COLOR = BJB_GREEN_SMOKE_100;
export const EXPANSION_PANEL_PRIMARY_FOCUS_COLOR = CLIPP_WHITE;
export const EXPANSION_PANEL_PRIMARY_HOVER_BACKGROUND_COLOR = BJB_GREEN_SMOKE_80;
export const EXPANSION_PANEL_PRIMARY_HOVER_COLOR = CLIPP_WHITE;
export const EXPANSION_PANEL_SECONDARY_BACKGROUND_COLOR = BJB_GREEN_SMOKE_20;
export const EXPANSION_PANEL_SECONDARY_COLOR = CLIPP_BLACK;
export const EXPANSION_PANEL_SECONDARY_FOCUS_BACKGROUND_COLOR = BJB_GREEN_SMOKE_60;
export const EXPANSION_PANEL_SECONDARY_FOCUS_COLOR = CLIPP_BLACK;
export const EXPANSION_PANEL_SECONDARY_HOVER_BACKGROUND_COLOR = BJB_GREEN_SMOKE_40;
export const EXPANSION_PANEL_SECONDARY_HOVER_COLOR = CLIPP_BLACK;

export const SIDEBAR_BACKGROUND_COLOR = BJB_BLUE_60;
export const SIDEBAR_HEADER_COLOR = BJB_BLUE_80;
export const SIDEBAR_TITLE_COLOR = CLIPP_WHITE;
export const SIDEBAR_NAVIGATION_HOVER_COLOR = BJB_BLUE_60;

export const DEBUG_BACKGROUND = 'repeating-linear-gradient(45deg, #606dbc, #606dbc 10px, #465298 10px, #465298 20px)';
