export default {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN PROGRESS',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  PENDING_PM: 'PENDING PM'
};
