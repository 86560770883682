export const OVERVIEW_PCI_I_INFO_TEXT = 'Year to date PC I, annualized';
export const OVERVIEW_PCI_II_INFO_TEXT = 'Year to date PC II, annualized';
export const OVERVIEW_NNM_INFO_TEXT = 'Year to date NNM, annualized';
export const OVERVIEW_ROA_INFO_TEXT = 'Annualized PC I results relative to average AuM';
export const PORTFOLIO_OTHER_FEES_INFO_TEXT = 'Other fees = PCI - Total Core fees (above)';
export const PORTFOLIO_ROA_INFO_TEXT = 'Simulated ROA = current ROA + (revenue change / average AUM)';
export const SUMMARY_TOTAL_ASSET_INFO_TEXT =
  'Average of last available quarter. This will serve as a basis of simulation for recurring fees.';
export const SUMMARY_PERIOD_OF_VALIDITY_INFO_TEXT =
  'According to Asia Pricing Governance policy, permanent discounts are valid for up to 2 years.';
export const SUMMARY_PRICE_MODEL_INFO_TEXT = 'Standard price model disclosed in the official Price List';
export const BANKING_SPECIAL_MAILING_INFO_TEXT =
  'Please only input an effective price if the portfolio is subscribed to the duplicated mailing service';
export const BANKING_REPORTING_FEE_INFO_TEXT =
  'Please only input an effective price if the portfolio is subscribed to the reporting service (monthly or quarterly)';
export const TRANSACTION_NO_OF_TRADE_INFO_TEXT =
  'Total number of transactions over the last 12 months - This will serve as a basis of simulation for transaction fees';
export const TRANSACTION_VOLUME_INFO_TEXT =
  'Total transaction volume over the last 12 months - This will serve as a basis of simulation for transaction fees';
export const TRANSACTION_FEE_CONDITION_INFO_TEXT = 'This applies to all asset categories below';
export const TRANSACTION_FEE_FOOT_NOTE_TEXT =
  '*Only products in "Transaction fees - exchange-traded products" are included in this simulation.';
export const NMB_FEE_DISCLAIMER = '*Subject to the applicable maximum fee as stated in the Notice of Monetary Benefits';
export const FX_PRECIOUS_METALS_DISCLAIMER = '*Subject to the applicable maximum fee as stated in the Notice of Monetary Benefits and minimum price in Markets Pricing Framework';
export const FX_PRECIOUS_METALS_ACCUMULATOR_DISCLAIMER = '\nPlease note, CLIPP is not able to distinguish between leveraged and unleveraged orders.\nPlease check the correct maximum premium applicable at the time of order entry.';
export const NMB_FEE_NON_EAM_DECLARATION_TEXT = 'This is a special structure which has been discussed with the client. I understand that the entry in the free text field below will be included in the client communication. I acknowledge that this special structure will be flagged to my Line Management and will be submitted quarterly to the management committee.'+
  '\n\nIf you have entered a special structure, kindly send this request to your Pricing Manager using the “Send to Pricing Team” button.';
export const NMB_FEE_EAM_EFA_DECLARATION_TEXT =   'This Special Structure has been agreed with the Intermediary. I acknowledge that this special structure will be flagged to my Line Management and will be submitted quarterly to the management committee.' +
  '\n\nIf you have entered a special structure, kindly send this request to your Pricing Manager using the “Send to Pricing Team” button.';
export const FX_PRECIOUS_METALS_DECLARATION_TEXT = 'This is a customized structure discussed with the client and I understand'+
  'that this will be included in the client communication (if any). \n'+
  'I also acknowledge that this customized structure will be flagged to my SRH and \n' +
  'also submitted quarterly to the management committee.';
export const HEADER_RM_PDB_INFO_TEXT = 'Client Book pricing compared \n'+
  'to Peers (based on current \n'+
  'book excl. delegation access)';
export const NMB_FEE_NON_EAM_RECURRING_TRANSACTION_TAB_DECLARATION_TEXT = 'This is a special structure which has been discussed with the client. I acknowledge that this special structure will be flagged to my Line Management and will be submitted quarterly to the management committee.'+
  '\n\nIf you have entered a special structure, kindly send this request to your Pricing Manager using the “Send to Pricing Team” button.';
export const NMB_FEE_EAM_EFA_DECLARATION_OTC_FX_TAB_TEXT =   'This Special Structure has been agreed with the Intermediary. I understand that the entry in the Additional Instructions below will be included in the Intermediary communication. I acknowledge that this special structure will be flagged to my Line Management and will be submitted quarterly to the management committee.' +
  '\n\nIf you have entered a special structure, kindly send this request to your Pricing Manager using the “Send to Pricing Team” button.';
