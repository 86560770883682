import {TEXT_COLOR} from '../common/color';

export default {
  root: {
    color: TEXT_COLOR,
    fontSize: '0.8125rem',
    fontFamily: '\'Verlag SSm 3r\'',
    fontWeight: 'normal',
    textTransform: 'none',
  },
  h1: {
    fontFamily: '\'Verlag SSm 7r\'',
    fontSize: '1.25rem'
  },
  h2: {
    fontFamily: '\'Verlag SSm 7r\'',
    fontSize: '1rem'
  },
  h3: {
    fontFamily: '\'Verlag SSm 7r\'',
    fontSize: '0.8125rem'
  },
  h4: {
    fontFamily: '\'Verlag SSm 7r\'',
    fontSize: '0.8125rem'
  },
  h5: {
    fontFamily: '\'Verlag SSm 7r\'',
    fontSize: '0.8125rem'
  },
  h6: {
    fontSize: '0.8125rem',
    textTransform: 'none',
  },
  body2: {
    fontFamily: '\'Verlag SSm 7r\'',
    fontSize: '0.8125rem',
  }
};
