import {
  SELECT_PORTFOLIO,
  SELECT_PORTFOLIO_FOR_SUBMISSION,
  SELECT_PORTFOLIO_NUMBERS_FOR_SUBMISSION
} from '../../../common/actionTypes';
import {LOAD_SUPPORTING_DOCUMENTS, UPDATE_SUMMARY} from './Summary/summaryActions';
import {getPricingModelIdFromSummary} from '../../../helpers/pricingModelHelper';
import {LOAD_PRICING_REQUEST_RESPONSE} from './pricingRequestActions';

export const commonReducer = (state, action) => {
  switch (action.type) {
    case SELECT_PORTFOLIO: {
      const {
        portfolioNumber,
        accountOpenDate,
        pricingRequestId,
        pricingRequestStatus,
        specialPricingRequest,
        eamCode,
        accessRoleType,
        accountOwnerUId,
        bookingCenter,
      } = action.selected;
      const {serviceModel, mandateType} = action.selected.summary;
      return {
        ...state,
        portfolioNumber,
        accountOpenDate,
        serviceModel,
        mandateType,
        pricingRequestId,
        pricingRequestStatus,
        specialPricingRequest,
        eamCode,
        accessRoleType,
        accountOwnerUId,
        bookingCenter
      };
    }
    case LOAD_PRICING_REQUEST_RESPONSE: {
      const approvalStatus = action.payload.data.decisionUserInfoDtoList;
      if (action.payload?.data?.summary) {
        const response = action.payload.data.summary;
        const pricingModelId = getPricingModelIdFromSummary(response);
        const {owner, agentType} = action.payload.data;
        return {...state, approvalStatus, pricingModelId, owner, agentType};
      }
      return {...state, approvalStatus: approvalStatus};
    }
    case UPDATE_SUMMARY:
      return {...state, pricingModelId: action.data.pricingModelId};
    case SELECT_PORTFOLIO_FOR_SUBMISSION:
      return {...state, portfoliosForSubmission: action.payload};
    case SELECT_PORTFOLIO_NUMBERS_FOR_SUBMISSION:
      return {...state, portfolioNumbersForSubmission: action.payload};
    case LOAD_SUPPORTING_DOCUMENTS: {
      return {...state, supportingDocuments: action.payload};
    }
    default:
      return state;
  }
};
