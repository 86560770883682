import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import LabelDropdown from '../../../../Common/components/tableFilter/LabelDropdown';
import LabelTextField from '../../../../Common/components/tableFilter/LabelTextField';

const PriceCheckRequestResponseFilter = ({handleFilterPriceCheckStatus, selectedPriceCheckStatusFilter, handleReferenceFilter, selectedReferenceFilter, handleMessageIdFilter, selectedMessageIdFilter, handleFind}) => {

  const PRICE_CHECK_SUBMISSION_STATUS = {
    PENDING: 'PENDING',
    SUBMITTED: 'SUBMITTED',
    CONFIRMED: 'CONFIRMED',
    ERROR: 'ERROR'
  };

  const priceCheckStatusFilterOptions = Object.entries(PRICE_CHECK_SUBMISSION_STATUS).map(([key, value]) => ({
    key,
    label: value
  }));

  return (
    <Box alignItems="center" display="flex" marginBottom={2}>
      <LabelDropdown
        label="Status"
        onChange={handleFilterPriceCheckStatus}
        options={priceCheckStatusFilterOptions}
        value={selectedPriceCheckStatusFilter}
      />
      &nbsp;
      <LabelTextField
        label="Portfolio Number"
        onChange={handleReferenceFilter}
        onClear={handleFind}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFind(e);
          }
        }}
        style={{width: '25ch'}}
        value={selectedReferenceFilter}
      />
      &nbsp;
      <LabelTextField
        label="Message Id"
        onChange={handleMessageIdFilter}
        onClear={handleFind}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFind(e);
          }
        }}
        style={{width: '25ch'}}
        value={selectedMessageIdFilter}
      />
    </Box>
  );
};

PriceCheckRequestResponseFilter.propTypes = {
  handleFilterPriceCheckStatus: PropTypes.func,
  handleFind: PropTypes.func,
  handleMessageIdFilter: PropTypes.func,
  handleReferenceFilter: PropTypes.func,
  selectedMessageIdFilter: PropTypes.string,
  selectedPriceCheckStatusFilter: PropTypes.string,
  selectedReferenceFilter: PropTypes.string
};

export default PriceCheckRequestResponseFilter;
