import {RESET, SET_LOADING_INDICATOR} from './common/actionTypes';

export const setIsLoading = (value, dispatch) => {
  dispatch({type: SET_LOADING_INDICATOR, payload: value});
};

export const returnToHome = (history, parentDispatch) => {
  parentDispatch({type: RESET});
  history.push('/');
};
