import React, {useContext} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import PropTypes from 'prop-types';
import {
  ERROR_BACKGROUND_PRIMARY_COLOR,
  EXPANSION_PANEL_SECONDARY_COLOR,
  EXPANSION_PANEL_SECONDARY_FOCUS_COLOR,
  EXPANSION_PANEL_SECONDARY_HOVER_COLOR
} from '../../../../../theme/common/color';
import Box from '@material-ui/core/Box';
import {PriceRequestStore} from '../../../PriceSimulationStore';
import {BJB_STONE_20, BJB_STONE_40} from '../../../../../theme/common/bjbColor';
import NMBFeePanel from './NMBFeePanel';
import {BILA} from '../../../../../common/enums/agreementType';
import AgreementTypeIndicator from '../../Common/AgreementTypeIndicator';
import {UPDATE_NMB_FEES} from '../nmbFeesActions';
import {cloneDeep} from 'lodash';
import {PENDING_PM} from '../../../../../common/statusTypes';


const useStyles = makeStyles(() => ({

  displayFlexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  headerEffectivePrice: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '200px'
  },
  innerExpansionPanel: {
    width: '100%'
  },
  innerExpansionPanelSummary: {
    backgroundColor: BJB_STONE_20,
    color: EXPANSION_PANEL_SECONDARY_COLOR,
    fontSize: '0.8125rem',
    height: '36px',
    minHeight: '36px!important',
    '&:focus': {
      backgroundColor: BJB_STONE_40,
      color: EXPANSION_PANEL_SECONDARY_FOCUS_COLOR
    },
    '&:hover': {
      backgroundColor: BJB_STONE_40,
      color: EXPANSION_PANEL_SECONDARY_HOVER_COLOR
    }
  },
  innerExpansionPanelSummaryTitle: {
    fontFamily: '\'Verlag SSm 7r\''
  },
  error: {
    backgroundColor: ERROR_BACKGROUND_PRIMARY_COLOR
  }
}));

const NMBFeeTenorSubTypes = ({childSubTypes, fundTypeCode, handleValidationResultChange, handleValueChange, showAgreementIndicator, subFund, readOnly}) => {
  const classes = useStyles();
  const {state, dispatch} = useContext(PriceRequestStore);
  const {agentType, serviceModel, pricingRequestStatus} = state.selectedPricingRequest?.common;

  const hasError = (subFundTypeCode) => {
    const errorStrings = state.selectedPricingRequest.nmbTransactionFees?.errors;
    if (errorStrings?.length > 0) {
      const subFundErrors = errorStrings.map(errorString => errorString.split(',').map(x => x.trim())[1]);
      if (subFundErrors.includes(subFundTypeCode)) {
        return 'error'
      }
    }
    return '';
  }

  const updateAgreementIndicatorForChilds = (value, subFund) => {
    let tempTransactionFees = cloneDeep(state.selectedPricingRequest.nmbTransactionFees);
    let errors = tempTransactionFees.errors || [];
    const childs = tempTransactionFees.transactionAssetTypes[fundTypeCode].filter(s=>s.nmbParentAssetType && (s.nmbParentAssetType.code === subFund.nmbParentAssetType.code));

    for(let i = 0;i < childs.length; i++) {
      const subType = childs[i];
      subType.newCondition.agreementEnum = value ? BILA: null;
      if (value !== BILA.code) {
        subType.newCondition.additionalInstructions = '';
        subType.newCondition.acknowledged = false;
        const updateSelector = [subType.nmbParentAssetType?.code, 'newCondition', 'additionalInstructions'];
        errors = (state.errors || []).filter((e) => e !== updateSelector.join());
      }

      if (!subType.newCondition.acknowledged) {
        subType.newCondition.additionalInstructions = '';
      }
    }
    dispatch({type: UPDATE_NMB_FEES, data: tempTransactionFees, errors: errors});
  }

  const handleValueChangeChildType = (selectors, transactionAssetSubLevel, others) =>{
    handleValueChange({
      ...others,
      transactionAssetSubLevel: transactionAssetSubLevel,
      selectors: [...selectors]
    })
  }

  const handleValidationResultChildType = (selectors, transactionAssetSubLevel, isValid) =>{
    handleValidationResultChange({
      isValid,
      transactionAssetSubLevel: transactionAssetSubLevel,
      selectors: [...selectors]
    })
  }

  return (<div style={{flexGrow: 1}}>
    <AgreementTypeIndicator
      agreedWithClient={subFund?.newCondition?.agreementEnum?.code}
      handleChange={(val) => {
        updateAgreementIndicatorForChilds(val, subFund);
      }}
      other={{inputProps:{'data-testid': `test-agreement-type-indicator-${subFund.nmbParentAssetType.code}-checkbox`}}}
      readOnly={readOnly || state.selectedPricingRequest?.common?.pricingRequestStatus === PENDING_PM}
      show={showAgreementIndicator}
    />
    {childSubTypes && childSubTypes.map(subType =>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        className={classes.innerExpansionPanel}
        defaultExpanded
        key={`expansion-${subType?.transactionAssetSubLevel?.code}`}
      >
        <AccordionSummary
          className={`${classes.innerExpansionPanelSummary} ${hasError(subType?.transactionAssetSubLevel?.code)}`}
          data-testid={`test-accordion-summary-${subType.transactionAssetSubLevel?.code}-id`}
          expandIcon={<ExpandMoreIcon/>}
        >
          <Box
            className={classes.displayFlexSpaceBetween}
            data-testid={`test-tenor-${subType.transactionAssetSubLevel?.code}-id`}
          >
            <Box className={classes.innerExpansionPanelSummaryTitle}>{subType.transactionAssetSubLevel?.desc}</Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <NMBFeePanel
            agentType={agentType}
            fundTypeCode={fundTypeCode}
            handleValidationResultChange={({selectors, isValid})=>handleValidationResultChildType(selectors, subType.transactionAssetSubLevel, isValid)}
            handleValueChange={({selectors, ...others})=>handleValueChangeChildType(selectors, subType.transactionAssetSubLevel, others)}
            key={`nmb-fee-panel-${fundTypeCode}-${subFund.transactionAssetSubLevel.code}`}
            pricingRequestStatus={pricingRequestStatus}
            readOnly={readOnly}
            serviceModel={serviceModel?.code}
            showAgreementIndicator={false}
            subFund={subType}
          />
        </AccordionDetails>
      </Accordion>
    )}
  </div>);
}

NMBFeeTenorSubTypes.propTypes = {
  childSubTypes: PropTypes.any.isRequired,
  fundTypeCode: PropTypes.string.isRequired,
  handleValidationResultChange: PropTypes.func,
  handleValueChange: PropTypes.func,
  readOnly: PropTypes.bool,
  showAgreementIndicator: PropTypes.bool,
  subFund: PropTypes.object.isRequired
};

export default React.memo(NMBFeeTenorSubTypes);
