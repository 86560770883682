import {HONGKONG, SINGAPORE} from './bookingCenter';

export const SPREAD = Object.freeze({
  code: 'SPREAD',
  desc: 'Spread',
  display: '% of notional amount',
  sortOrder: 0
});
export const COMMISSION = Object.freeze({
  code: 'COMMISSION',
  desc: 'Commission',
  display: '% of investment amount',
  sortOrder: 1
});

export const getAllNMBPricingTypes = () => {
  return [
    SPREAD,
    COMMISSION
  ];
};

export const getAllNMBPricingTypesBaseOnRMLocationAndBookingCenter = (rmOwnerLocation, bookingCenter) => {
  if(!bookingCenter || !rmOwnerLocation){
    return [];
  }
  if (rmOwnerLocation === HONGKONG.code && (bookingCenter === HONGKONG.code || bookingCenter === HONGKONG.desc)) {
    return [SPREAD];
  }
  if (rmOwnerLocation === HONGKONG.code && (bookingCenter === SINGAPORE.code || bookingCenter === SINGAPORE.desc)) {
    return [SPREAD];
  }
  if (rmOwnerLocation === SINGAPORE.code && (bookingCenter === HONGKONG.code || bookingCenter === HONGKONG.desc)) {
    return [SPREAD];
  }

  return [
    SPREAD,
    COMMISSION
  ];
};

export const getPricingTypeDisplayFromCode = code => {
  const pricingType = getAllNMBPricingTypes().filter(ptype => ptype.code === code);
  if (pricingType[0]) {
    return pricingType[0].display;
  }
  return '';
};

export const getPricingTypeFromCode = code => {
  return getAllNMBPricingTypes().filter(ptype => ptype.code === code)[0];
};

export const getPricingTypeSelectValues = (rmLocation, bookingCenter) => {
  return getAllNMBPricingTypesBaseOnRMLocationAndBookingCenter(rmLocation, bookingCenter).map(p => ({
    'key': p.code,
    'value': p.desc
  }))
}
