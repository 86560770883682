import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  BUTTON_PRIMARY_BACKGROUND_COLOR,
  BUTTON_PRIMARY_COLOR,
  WARNING_COLOR
} from '../../../../../../theme/common/color';
import AsyncButton from '../../../AsyncButton';

const useStyles = makeStyles({
  warning: {
    backgroundColor: WARNING_COLOR,
    fontFamily: '\'Verlag SSm 7r\'',
  },
  cancel: {
    backgroundColor: BUTTON_PRIMARY_BACKGROUND_COLOR,
    color: BUTTON_PRIMARY_COLOR
  }
});

const CancelConfirmationDialog = ({onClose, onContinueCancel, open, portfolioNumber}) => {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      className={classes.root}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
    >
      <DialogTitle className={classes.warning} disableTypography id="confirmation-dialog-title">
        Warning: Are you sure you want to cancel the pricing request for {portfolioNumber}?
      </DialogTitle>
      <DialogContent className={classes.content} dividers>
        You will lose all your changes in all tabs.
      </DialogContent>
      <DialogActions>
        <AsyncButton
          color="primary"
          data-testid="test-cancel-request-continue-button"
          onClick={onContinueCancel}
          text="Continue"
        />
        <Button
          className={classes.cancel}
          color="primary"
          data-testid="test-cancel-request-back-button"
          onClick={onClose}
        >
          Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CancelConfirmationDialog.propTypes = {
  onClose: PropTypes.any,
  onContinueCancel: PropTypes.any,
  open: PropTypes.any,
  portfolioNumber: PropTypes.string
};

export default CancelConfirmationDialog;
