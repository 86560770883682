import React from 'react';
import Moment from 'react-moment';
import {DATE_FORMAT, DATE_TIME_FORMAT} from '../../../../../common/dateFormat';
import DialogCell from './DialogCell';
import {
  CREATED_DATE_COLUMN,
  RUN_DATE_COLUMN,
  LOG_INFO_COLUMN,
  REQUEST_BODY_COLUMN,
  UPDATED_DATE_COLUMN
} from '../columnsData';

const ClientAgreementCustomCell = ({column, row, value}) => {
  if ([UPDATED_DATE_COLUMN.key, CREATED_DATE_COLUMN.key].includes(column.id)) {
    return value ? <Moment date={value} format={DATE_TIME_FORMAT}/> : null;
  }if ([RUN_DATE_COLUMN.key].includes(column.id)) {
    return value ? <Moment date={value} format={DATE_FORMAT}/> : null;
  } else if ([REQUEST_BODY_COLUMN.key, LOG_INFO_COLUMN.key].includes(column.id)) {
    return value ? <DialogCell title={row.original.id} value={value}/> : null;
  } else {
    return value ? value : null;
  }
};

export default ClientAgreementCustomCell;
