import React, {useEffect, useMemo, useState} from 'react';
import adminApi from '../../../../api/adminApi';
import {makeStyles} from '@material-ui/styles';
import {Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {useSortBy, useTable} from 'react-table';
import {CLIPP_WHITE} from '../../../../theme/common/bjbColor';
import {
  TABLE_APPROVAL_STATUS_BORDER_COLOR,
  TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR
} from '../../../../theme/common/color';
import PaginationControl from '../../../Common/components/PaginationControl';
import ClientAgreementCustomCell from './components/ClientAgreementCustomCell';
import {DESC} from '../../../../common/sortDirection';
import {columnsData, CLIENT_AGREEMENT_GEN_ID_COLUMN} from './columnsData';
import {useNotification} from '../../../Common/components/Notification';
import LoadingSpinner from '../../../Common/components/LoadingSpinner';
import {getErrorMessage} from '../../../../common/getErrorMessage';
import AsyncButton from '../../../Common/components/AsyncButton';
import ClientAgreementGenStatusFilter from './components/ClientAgreementGenStatusFilter';

const useStyles = makeStyles(theme => ({
  inner: {
    maxWidth: '1500px',
    overflowX: 'auto'
  },
  table: {
    border: '1px solid',
    borderColor: TABLE_APPROVAL_STATUS_BORDER_COLOR,
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  tableCellHeader: {
    backgroundColor: TABLE_APPROVAL_STATUS_HEADER_BACKGROUND_COLOR,
    color: CLIPP_WHITE,
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '20px',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  tableCellBody: {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '25px',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

const ClientAgreementGenStatus = () => {
  const tableColumns = columnsData.map(colData => {
    return {accessor: colData.key, Header: colData.label, Cell: ClientAgreementCustomCell};
  });

  const classes = useStyles();
  const notification = useNotification();

  const [clientAgreementRequestResponses, setClientAgreementRequestResponses] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState();
  const [selectedLetterTypeFilter, setSelectedLetterTypeFilter] = useState();
  const [selectedClientAgreementStatusFilter, setSelectedClientAgreementStatusFilter] = useState();
  const [selectedReferenceFilter, setSelectedReferenceFilter] = useState('');
  const [selectedRunDateFilter, setSelectedRunDateFilter] = useState('');
  const [requiresRefresh, setRequiresRefresh] = useState(false);

  const [isLoading, setIsLoading] = useState();

  const memoizedData = useMemo(() => clientAgreementRequestResponses, [clientAgreementRequestResponses]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedColumns = useMemo(() => tableColumns, []);

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData
    },
    useSortBy
  );

  const fetchData = async params => {
    setIsLoading(true);
    try {
      const res = await adminApi.clientAgreementWorkflow.getAllClientAgreementStatuses({
        ...params,
        letterType: selectedLetterTypeFilter,
        status: selectedClientAgreementStatusFilter,
        reference: selectedReferenceFilter,
        runDate: selectedRunDateFilter,
        sortBy: params?.sortBy || CLIENT_AGREEMENT_GEN_ID_COLUMN.key,
        sortDirection: params?.sortDirection || DESC
      });
      setClientAgreementRequestResponses(res.data.elements);
      setPaginationInfo(res.data.pagingMetaData);
      setIsLoading(false);
    } catch (err) {
      notification.error('Could not load data.\n' + getErrorMessage(err));
    }
  };

  const handleLetterTypeFilter = e => {
    setSelectedLetterTypeFilter(e.target.value);
  };

  const handleStatusFilter = e => {
    setSelectedClientAgreementStatusFilter(e.target.value);
  };

  const handleReferenceFilter = e => {
    setSelectedReferenceFilter(e.target.value);
  };

  const handleRunDateFilter = e => {
    setSelectedRunDateFilter(e.target.value);
  };

  const handleFind = e => {
    setRequiresRefresh(e.target.value + Math.random())
  };

  const refresh = () => {
    return fetchData();
  }

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientAgreementStatusFilter, requiresRefresh]);

  const goToPage = page => {
    fetchData({page});
  };

  return (
    <Grid container>
      <Grid className={classes.title} item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h1">
            All Client Agreements
          </Typography>
          <AsyncButton onClick={refresh} text="Refresh"/>
        </Box>

        <ClientAgreementGenStatusFilter
          handleFilterClientAgreementStatus={handleStatusFilter}
          handleFilterLetterType={handleLetterTypeFilter}
          handleFind={handleFind}
          handleReferenceFilter={handleReferenceFilter}
          handleRunDateFilter={handleRunDateFilter}
          selectedClientAgreementStatusFilter={selectedClientAgreementStatusFilter}
          selectedLetterTypeFilter={selectedLetterTypeFilter}
          selectedReferenceFilter={selectedReferenceFilter}
          selectedRunDateFilter={selectedRunDateFilter}
        />

      </Grid>

      <Grid item xs={12}>
        {isLoading ? <LoadingSpinner/> : (
          <React.Fragment>

            <div className={classes.inner}>

              <Table size="small" {...getTableProps()} className={classes.table}>
                <TableHead>
                  {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <TableCell
                          {...column.getHeaderProps()}
                          className={classes.tableCellHeader}
                        >
                          {column.render('Header')}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <TableRow key={i}>
                          {row.cells.map(cell => (
                            <TableCell
                              {...cell.getCellProps()}
                              className={classes.tableCellBody}
                            >
                              {cell.render('Cell')}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={tableColumns.length}>
                        No entry found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

              </Table>
            </div>
            {paginationInfo && (
              <PaginationControl goToPage={goToPage} paginationInfo={paginationInfo}/>
            )}

          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default ClientAgreementGenStatus;
