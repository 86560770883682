import React, {useCallback, useEffect, useState} from 'react';
import {getDefaultFontSize} from '../../../../../theme/common/font';
import PriceSelect from '../../../../Common/components/PriceSelect';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Grid, Typography} from '@material-ui/core';
import api from '../../../../../api/api';

const useStyles = makeStyles((theme) => ({
  text: {
    ...getDefaultFontSize(theme),
    textAlign: 'right'
  }
}));

const NMBCalculationBasis = ({calculationBasis, editableProps, fullWidth, handleChange, readOnly, readonlyProps }) => {
  const classes = useStyles();
  const [options, setOptions] = useState();
  const [payload, setPayload] = useState();
  const calculationBasisCallback = useCallback(
    () => {
      return api.pricingRequest.nmbFees.getCalculationBasis();
    },
    []
  );

  useEffect(() => {
    const loadOptions = async () => {
      let payload = await calculationBasisCallback();
      if (payload && payload.data) {
        setPayload(payload.data);
        setOptions(payload.data.map(p => ({
          'key': p.code,
          'value': p.desc
        })));
      }
    }
    loadOptions();
  }, [calculationBasisCallback]);

  return (
    <Grid
      container
      data-testid={'test-calculation-basis-container'}
      justify={'flex-end'}
      spacing={1}
    >
      <Grid item xs={fullWidth ? 12 : 8}>
        {!readOnly && options && <PriceSelect inputProps={{name: 'calculationBasis', id: 'uncontrolled-native',...editableProps}} key={'fee-calculation-basis-select'} onValueChange={(e) => {handleChange({value: payload.filter(option=>option.code === e.value)[0], selectors: ['newCondition', 'calculationBasis'], isFeeChanged: true})}} options={options} readOnly={readOnly} value={calculationBasis?.code} />}
        {readOnly &&
        <Typography className={classes.text} {...readonlyProps}>{calculationBasis?.desc}</Typography>}
      </Grid>
    </Grid>
  );
};

NMBCalculationBasis.propTypes = {
  calculationBasis: PropTypes.object.isRequired,
  editableProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  readonlyProps: PropTypes.object
};

export default React.memo(NMBCalculationBasis);
