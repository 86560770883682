import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/styles';
import {
  EXPANSION_PANEL_PRIMARY_BACKGROUND_COLOR,
  EXPANSION_PANEL_PRIMARY_COLOR,
  EXPANSION_PANEL_PRIMARY_FOCUS_BACKGROUND_COLOR,
  EXPANSION_PANEL_PRIMARY_FOCUS_COLOR,
  EXPANSION_PANEL_PRIMARY_HOVER_BACKGROUND_COLOR,
  EXPANSION_PANEL_PRIMARY_HOVER_COLOR
} from '../../../../../theme/common/color';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {getFXProductTypeDescriptionFromCode} from '../../../../../common/enums/fxProductTypes';
import FxPreciousMetalsProductSubTypes from './FxPreciousMetalsProductSubTypes';

const useStyles = makeStyles(() => ({
  outerExpansionPanelDetails: {
    padding: 0
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  outerExpansionPanelSummary: {
    backgroundColor: EXPANSION_PANEL_PRIMARY_BACKGROUND_COLOR,
    color: EXPANSION_PANEL_PRIMARY_COLOR,
    fontSize: '0.8125rem',
    height: '36px',
    minHeight: '36px!important',
    '&:focus': {
      backgroundColor: EXPANSION_PANEL_PRIMARY_FOCUS_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_PRIMARY_FOCUS_COLOR
    },
    '&:hover': {
      backgroundColor: EXPANSION_PANEL_PRIMARY_HOVER_BACKGROUND_COLOR,
      color: EXPANSION_PANEL_PRIMARY_HOVER_COLOR
    }
  },
  outerExpansionPanelSummaryTitle: {
    fontFamily: '\'Verlag SSm 7r\''
  }
}));

const FxPreciousMetalsProductType = ({fxProductTypeCode, handleValueChange, handleValidationResultChange, readOnly, showAgreementIndicator, fxSubTypes}) => {
  const classes = useStyles();

  const [expandedPanels, setExpandedPanels] = useState([]);

  const handlePanelExpansionToggle = (panelId) => {
    const currentlyExpanded = expandedPanels.some(pId => pId === panelId);
    if (currentlyExpanded) {
      setExpandedPanels(expandedPanels.filter(pId => pId !== panelId));
    } else {
      setExpandedPanels([...expandedPanels, panelId]);
    }
  };

  const currentlyAllSubCategoryPanelsExpanded = fxSubTypes.length === expandedPanels.length;

  const toggleAllExpandedPanels = () => {
    if (currentlyAllSubCategoryPanelsExpanded) {
      setExpandedPanels([]);
    } else {
      setExpandedPanels(fxSubTypes.map(subType => subType.transactionAssetSubLevel.code));
    }
  };

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} expanded key={`expansion-${fxProductTypeCode}`} onChange={toggleAllExpandedPanels}>
      <AccordionSummary className={classes.outerExpansionPanelSummary}>
        <Box className={classes.displayFlex}>
          <Box
            className={classes.outerExpansionPanelSummaryTitle}
          >{getFXProductTypeDescriptionFromCode(fxProductTypeCode)}</Box>
          <Box
            data-testid={`fx-product-show-detail-${fxProductTypeCode}-test-id`}
          >{currentlyAllSubCategoryPanelsExpanded ? 'Hide Details' : 'Show Details'}</Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.outerExpansionPanelDetails}>
        <FxPreciousMetalsProductSubTypes
          expandedPanels={expandedPanels}
          fxProductTypeCode={fxProductTypeCode}
          fxSubTypes={fxSubTypes}
          handlePanelExpansionToggle={handlePanelExpansionToggle}
          handleValidationResultChange={handleValidationResultChange}
          handleValueChange={handleValueChange}
          readOnly={readOnly}
          showAgreementIndicator={showAgreementIndicator}
        />
      </AccordionDetails>
    </Accordion>
  );
};

FxPreciousMetalsProductType.propTypes = {
  fxProductTypeCode: PropTypes.string.isRequired,
  fxSubTypes: PropTypes.array.isRequired,
  handleValidationResultChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showAgreementIndicator: PropTypes.bool
};

export default React.memo(FxPreciousMetalsProductType);
